import { AvenueRoute } from 'constants/routes';

const getLibraryPathFromUrlPath = (urlPath: string) => {
  const isLibrary = urlPath.startsWith(AvenueRoute.Library);
  const isGroupLibrary = urlPath.startsWith(AvenueRoute.GroupLibrary);
  if (!isLibrary && !isGroupLibrary) {
    return null;
  }
  let libraryPath = isLibrary
    ? urlPath.slice(AvenueRoute.Library.length)
    : urlPath;
  libraryPath = libraryPath.replace('/create', '').replace('/edit', '');
  return libraryPath;
};

const getPathParts = (menuPath: string) => {
  const pathParts = menuPath.split('/').filter(Boolean);

  if (!pathParts.length) {
    return [];
  }
  const activePaths: string[] = [];

  pathParts.reduce((prevPath, pathPart) => {
    if (!pathPart) {
      return prevPath;
    }
    const nextTreePath = `${prevPath}/${pathPart}`;
    activePaths.push(nextTreePath);
    return nextTreePath;
  }, '');

  return activePaths;
};

const getParentPath = (path: string) => {
  const lastSlashIndex = path.lastIndexOf('/');
  if (lastSlashIndex <= 0) {
    return null;
  }
  return path.slice(0, lastSlashIndex);
};

const addLibraryRoutePrefix = (path: string) => {
  if (path.startsWith(AvenueRoute.GroupLibrary)) {
    return path;
  }
  return `${AvenueRoute.Library}${path}`;
};

export {
  addLibraryRoutePrefix,
  getLibraryPathFromUrlPath,
  getParentPath,
  getPathParts,
};
