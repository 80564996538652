import Text from 'components/Editable/Text';
import { Component } from 'react';

import { Consumer } from '..';

class SourceLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholderName: props.placeholderName,
      placeholderUrl: props.placeholderUrl,
      name: null,
      url: null,
      source: null,
    };
  }

  updateArticleSource(onChangeArticle, { name, url, source }) {
    let sourceConsumer = {
      name: '',
      url: '',
    };

    if (source) {
      sourceConsumer = source;
    }

    if (!this.state.url && !this.state.name && !this.state.source) {
      this.setState({
        name: sourceConsumer.name,
        url: sourceConsumer.url,
        source: 'set from Consumer',
      });
    }

    if (name || name === '') {
      this.setState({ name: name, source: 'set by User' });
    } else if (url || url === '') {
      this.setState({ url: url, source: 'set by User' });
    }

    if (
      (this.state.url && name) ||
      (sourceConsumer.url && name && this.state.source !== 'set by User')
    ) {
      source = {
        name: name,
        url: this.state.url || sourceConsumer.url,
      };
    } else if (
      (this.state.name && url) ||
      (sourceConsumer.name && url && this.state.source !== 'set by User')
    ) {
      source = {
        name: this.state.name || sourceConsumer.name,
        url: url,
      };
    } else {
      source = null;
    }

    onChangeArticle({ source });
  }

  render() {
    const { placeholderName, placeholderUrl } = this.state;

    return (
      <Consumer>
        {({
          article: { source },
          validationParameters: {
            source: { maxLength },
          },
          onChangeArticle,
        }) => (
          <div className="source-link">
            <p>Specify Source</p>
            <div className="max-width">
              <Text
                wrapper={{
                  element: 'div',
                  className: 'source-link__name',
                }}
                content={source && source.name}
                onBlur={(name) =>
                  this.updateArticleSource(onChangeArticle, { name, source })
                }
                placeholder={placeholderName}
                maxLength={maxLength}
              />
              <Text
                wrapper={{
                  element: 'div',
                  className: 'source-link__url',
                }}
                content={source && source.url}
                onBlur={(url) =>
                  this.updateArticleSource(onChangeArticle, { url, source })
                }
                placeholder={placeholderUrl}
                maxLength={maxLength}
              />
            </div>
            <hr className="m-t-6 m-b-5" />
          </div>
        )}
      </Consumer>
    );
  }
}

export default SourceLink;
