import './UserFindSelector.scss';

import SearchSelector from 'components/SearchSelector';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { getSuggestions, splitSuggestion } from 'utils/misc/autoSuggest';

const getPersonName = (value) => {
  const { firstName, lastName } = value.attributes;
  return `${firstName} ${lastName}`;
};

const onSuggestionsFetchRequested = (value) => {
  const filter = {
    type: 'person',
  };
  return getSuggestions(value, filter, false).then((suggestions) =>
    suggestions
      ? suggestions.map((suggestion) => {
          return {
            suggestionInstance: suggestion,
            name: getPersonName(suggestion),
            value: suggestions.id,
          };
        })
      : []
  );
};

const renderSearchSuggestions = (searchHit, { query }) => {
  if (!searchHit) {
    return null;
  }
  const parts = splitSuggestion(searchHit.name, query);
  return (
    <div className="container">
      <span className="text">
        {parts.map((part, index) => {
          const className = part.highlight ? 'highlight' : null;
          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </span>
      <span className="text email">
        {searchHit.suggestionInstance.get('email')}
      </span>
    </div>
  );
};

class UserFindSelector extends Component {
  render() {
    const {
      alwaysDoubleLine,
      className,
      defaultLabel,
      onChange,
      showError,
      selectedUsers,
    } = this.props;

    return (
      <SearchSelector
        alwaysDoubleLine={alwaysDoubleLine}
        className={`user-find-selector ${className}`}
        defaultLabel={defaultLabel}
        disabled={false}
        fetchSearchSuggestions={onSuggestionsFetchRequested}
        id="userFindSelector"
        multiple={false}
        selectedOptions={selectedUsers}
        onChange={(selected) => onChange(selected.suggestionInstance)}
        placeholder="Search employees"
        renderSearchSuggestions={renderSearchSuggestions}
        showError={showError}
      />
    );
  }
}

UserFindSelector.propTypes = {
  alwaysDoubleLine: PropTypes.bool,
  className: PropTypes.string,
  defaultLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  selectedUsers: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ]),
};

export default UserFindSelector;
