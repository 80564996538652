import './HorizontalDividerList.scss';

import classNames from 'classnames';
import React from 'react';

interface HorizontalDividerListProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const HorizontalDividerList = ({
  className,
  children,
}: HorizontalDividerListProps) => {
  const nonNullChildren = React.Children.toArray(children).filter(Boolean);

  if (!nonNullChildren.length) {
    return null;
  }

  return (
    <div className={classNames('horizontal-divider-list', className)}>
      {nonNullChildren.map((child, index) => (
        <React.Fragment key={`horizontal-divider-list-item-${index}`}>
          {child}
          <div className="horizontal-divider-list__divider" />
        </React.Fragment>
      ))}
    </div>
  );
};

export { HorizontalDividerList };
