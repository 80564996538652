import './FileManager.scss';

import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { createEmptyFolderEntry } from '../../parseFolderEntries';
import useComponentDidMount from '../../useComponentDidMount';
import useFileManagerModals from '../../useFileManagerModals';
import FolderView from '../FolderView';
import MiniSnackbar from '../MiniSnackbar';
import {
  onChangeCurrentFolderAndEntriesAction,
  onClickCreateNewFolder,
  onClickUploadFile,
  onDeleteFileAction,
  onNavigateToFolderAction,
  onNavigateToFolderInPathAction,
  onNavigateToRootFolderAction,
  onRenameFileAction,
} from './fileActionCallbacks';

const FileManager = ({
  folderName,
  editMode = false,
  rootName,
  onChange,
  onDownload,
  onPreview,
  topic,
}) => {
  const componentDidMount = useComponentDidMount();
  const [folderEntries, setFolderEntries] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(createEmptyFolderEntry());
  const [messages, setMessages] = useState([]);
  const { showModal, modalRenderData } = useFileManagerModals();
  const [loadingFailed, setLoadingFailed] = useState(false);
  const onAddError = useCallback(
    (message, loadingFailed = true) => {
      setMessages([
        ...messages,
        {
          id: messages.reduce((max, { id }) => (id >= max ? id + 1 : max), 0),
          content: message,
          status: 'error',
        },
      ]);
      setLoadingFailed(loadingFailed);
    },
    [messages]
  );
  const changeCurrentFolderAndEntries = useCallback(
    (rootFolderName, folder) =>
      onChangeCurrentFolderAndEntriesAction(
        rootFolderName,
        folder,
        onChange,
        onAddError,
        setCurrentFolder,
        setLoadingFailed,
        setFolderEntries
      ),
    [onChange, onAddError, setCurrentFolder, setLoadingFailed, setFolderEntries]
  );
  useEffect(() => {
    // react-hooks/exhaustive-deps requires all dependencies of an effect to be declared,
    // causing this useEffect to be called whenever the "messages" is changed.
    // This would lead to an infinite loop of re-fetching the current folder which ius why
    // the component did mount-value is used.
    if (componentDidMount) {
      changeCurrentFolderAndEntries(folderName, createEmptyFolderEntry());
    }
  }, [
    changeCurrentFolderAndEntries,
    componentDidMount,
    folderName,
    onChange,
    onAddError,
  ]);
  const onClick = (_, option) => {
    const { type, item } = option;
    if (type === 'root') {
      onNavigateToRootFolderAction(changeCurrentFolderAndEntries, folderName);
    } else if (type === 'folder') {
      onNavigateToFolderInPathAction(
        changeCurrentFolderAndEntries,
        folderName,
        item
      );
    } else if (type === 'entry' && item.type === 'folder') {
      onNavigateToFolderAction(
        setCurrentFolder,
        setLoadingFailed,
        setFolderEntries,
        onAddError,
        folderName,
        item
      );
    } else if (type === 'entry' && item.type === 'file') {
      onPreview(item.name);
      window.open(item.url);
    } else if (type === 'menu-item' && option.action === 'download') {
      onDownload(item.name);
      window.open(item.downloadUrl);
    } else if (type === 'menu-item' && option.action === 'preview') {
      onPreview(item.name);
      window.open(item.url);
    } else if (type === 'menu-item' && option.action === 'delete') {
      onDeleteFileAction(
        showModal,
        setFolderEntries,
        onChange,
        onAddError,
        folderName,
        currentFolder,
        item
      );
    } else if (type === 'menu-item' && option.action === 'rename') {
      onRenameFileAction(
        showModal,
        setFolderEntries,
        onChange,
        onAddError,
        folderName,
        currentFolder,
        item
      );
    }
  };
  return (
    <>
      <div className="file-manager__container">
        <MiniSnackbar messages={messages} onChange={setMessages} />
        <FolderView
          isEditing={editMode}
          items={folderEntries}
          loadingFailed={loadingFailed}
          folderPath={currentFolder.getInclusiveNodePathObjects()}
          rootName={rootName}
          onClick={onClick}
          topic={topic}
        />
        {modalRenderData.map(({ component: ModalComponent, props }, index) => (
          <ModalComponent {...props} key={index} />
        ))}
      </div>
      {editMode ? (
        <div className="file-manager__buttons-row">
          <button
            className="file-manager__button"
            onClick={() =>
              onClickUploadFile(
                showModal,
                changeCurrentFolderAndEntries,
                folderName,
                currentFolder
              )
            }
          >
            Upload files...
          </button>
          <button
            className="file-manager__button"
            onClick={() =>
              onClickCreateNewFolder(
                showModal,
                setFolderEntries,
                onChange,
                onAddError,
                folderName,
                currentFolder
              )
            }
          >
            Add folder
          </button>
        </div>
      ) : null}
    </>
  );
};

FileManager.propTypes = {
  editMode: PropTypes.bool,
  folderName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDownload: PropTypes.func,
  onPreview: PropTypes.func,
  rootName: PropTypes.string.isRequired,
};

export default FileManager;
