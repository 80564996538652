import _SortIndex from 'components/SortIndex';

import { Consumer } from '..';

const SortIndex = () => (
  <Consumer>
    {({ article: { sortIndex }, onChangeArticle }) => (
      <_SortIndex
        sortIndex={sortIndex}
        onBlur={(value) => {
          onChangeArticle({ sortIndex: value });
        }}
      />
    )}
  </Consumer>
);

export default SortIndex;
