import { Column, Container } from 'components/Grid';
import PageTitle from 'components/PageTitle';
import { useEffect } from 'react';
import { GAonGlobalFeedClick } from 'utils/analytics';

import FeedContent from '../common/FeedContent';
import GlobalFeedHeader from './components/GlobalFeedHeader';

const GlobalFeed = () => {
  useEffect(() => {
    GAonGlobalFeedClick();
  }, []);
  const title = ['Global Feed'];
  return (
    <>
      <>
        <PageTitle titles={title} />
        <Container>
          <Column>
            <GlobalFeedHeader />
            <FeedContent isGlobalFeed={true} />
          </Column>
        </Container>
      </>
    </>
  );
};

export default GlobalFeed;
