import './Error.scss';

import { ReactComponent as Icon } from 'components/Icon/Danger.svg';
import { Redirect } from 'react-router-dom';

interface ErrorProps {
  title?: string;
  message?: string;
  status?: number;
  redirectOn404?: boolean;
  small?: boolean;
}

const Error = ({
  title = 'Something went wrong',
  message = 'No contact with API, please contact support for assistance.',
  status,
  redirectOn404,
  small,
}: ErrorProps) => {
  if (redirectOn404 && status === 404) {
    const isPublicPage = /^\/public/.test(window.location.pathname);

    if (isPublicPage) {
      return <Redirect to="public/404" />;
    }

    return <Redirect to="/404" />;
  }

  return (
    <span className={`error-message ${small ? 'error-message--small' : ''}`}>
      <Icon />
      <div className="error-message__text-wrapper">
        <h3 className="error-message__title">{title}</h3>
        <p className="error-message__text">{message}</p>
      </div>
    </span>
  );
};

export default Error;
