import * as alertApi from 'api/cms/alert';
import { Alert } from 'api/cms/alert/types';
import { useUser } from 'components/Context/User';
import React, { useContext, useEffect, useMemo, useState } from 'react';

const AlertsContext = React.createContext<{
  visibleAlerts: Alert[];
  setVisibleAlerts: (value: React.SetStateAction<Alert[]>) => void;
} | null>(null);

type AlertsProviderProps = {
  children: React.ReactNode;
};

const AlertsProvider = ({ children }: AlertsProviderProps) => {
  const [visibleAlerts, setVisibleAlerts] = useState<Alert[]>([]);

  const user = useUser();

  useEffect(() => {
    if (user.isLoading) {
      return;
    }
    async function fetchAlerts() {
      try {
        const response = await alertApi.getActiveAlerts({
          divisionId: user.divisionId,
          countryId: user.countryId,
        });
        const result: Alert[] = response?.data?.data || [];

        if (!result.length) {
          return;
        }
        setVisibleAlerts(result);
      } catch {}
    }
    fetchAlerts();
  }, [user.countryId, user.divisionId, user.isLoading]);

  const contextValue = useMemo(
    () => ({
      visibleAlerts,
      setVisibleAlerts,
    }),
    [visibleAlerts, setVisibleAlerts]
  );

  return (
    <AlertsContext.Provider value={contextValue}>
      {children}
    </AlertsContext.Provider>
  );
};

const useAlerts = () => {
  const context = useContext(AlertsContext);
  if (!context) {
    throw new Error('AlertsContext is not defined');
  }
  return context;
};

export { AlertsProvider, useAlerts };
