import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { cms } = settings.api;
const route = `${cms}/surveypopup`;

export const getSurveyPopup = () =>
  createRequest({
    method: 'get',
    url: `${route}`,
    errorMessages: {
      '*': 'Failed to get Survey popup, please contact support for assistance.',
    },
  });
