import Box from 'components/Box';
import { Column, Container } from 'components/Grid';
import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Content = ({ width, height = 770 }) => (
  <ContentLoader
    className=""
    width={width}
    height={height}
    preserveAspectRatio="none"
    style={{ height: `${height}px` }}
  >
    <rect x={0} y={6} width={getRandomNumberBetween(350, 500)} height={36} />

    <rect x={0} y={70} width={width} height={400} />

    <rect x={0} y={520} width={getRandomNumberBetween(550, 670)} height={18} />
    <rect x={0} y={550} width={getRandomNumberBetween(550, 670)} height={18} />
    <rect x={0} y={580} width={getRandomNumberBetween(400, 670)} height={18} />

    <rect x={0} y={620} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={640} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={660} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={680} width={getRandomNumberBetween(400, 670)} height={12} />
  </ContentLoader>
);

const Loader = () => (
  <Container className="m-t-6" mobileColumnSpacing={true}>
    <Column span={2}>
      <Box>
        <Resize>{({ width }) => <Content width={width} />}</Resize>
      </Box>
    </Column>
    <Column />
  </Container>
);

export default Loader;
