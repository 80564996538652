import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { cms } = settings.api;

export const getRedirects = () =>
  createRequest({
    method: 'get',
    url: `${cms}/redirects`,
    errorMessages: {
      '*': 'Failed to get redicects, please contact support for assistance.',
      '404': 'Sorry, no redirects to load right now.',
    },
  });
