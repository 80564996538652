import PortalNode from 'api/models/PortalNode';
import ChildPageMenu from 'components/Portal/ChildPageMenu';
import InsightsListingBodyBox from 'components/Portal/InsightsListing/BodyBox';
import InsightsListingHeaderBox from 'components/Portal/InsightsListing/HeaderBox';
import PropTypes from 'prop-types';

const InsightsContent = ({ page }) => {
  const { template } = page.attributes;
  const isInsightsArticle = template === 'insights-content';

  return (
    <>
      {isInsightsArticle && (
        <>
          <InsightsListingHeaderBox align="left" page={page}>
            <ChildPageMenu page={page} />
          </InsightsListingHeaderBox>
          <InsightsListingBodyBox page={page} />
        </>
      )}
    </>
  );
};

InsightsContent.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
};

export default InsightsContent;
