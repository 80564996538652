import Typography from '@mui/material/Typography';

type FieldNameProps = {
  children: string;
};

const FieldName = ({ children }: FieldNameProps) => {
  return (
    <Typography
      sx={(theme) => ({
        width: '8rem',
        flexShrink: 0,
        color: theme.colors.text.tertiary,
      })}
    >
      {children}
    </Typography>
  );
};

export { FieldName };
