import './component.scss';

import { ReactComponent as ChevronDown } from 'components/Icon/ChevronDown.svg';
import { ReactComponent as ChevronUp } from 'components/Icon/ChevronUp.svg';
import { ReactComponent as Pencil } from 'components/Icon/Pencil.svg';
import Accordion from 'components/Modals/Accordion/index';
import { FC, useState } from 'react';
import getSettings from 'settings';
import { formatAccordionTitle } from 'utils/misc/formatAccordionTitle';

interface AccordionInput {
  title: string;
  body: string;
  entityKey?: string;
}

interface AtomicAccordionComponentProps {
  data: AccordionInput;
  onSubmit: (accordion: object) => void;
  disableEditor?: () => void;
  enableEditor?: () => void;
}

const AtomicAccordionComponent: FC<AtomicAccordionComponentProps> = ({
  data,
  onSubmit,
  disableEditor,
  enableEditor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [accordion, setAccordion] = useState(
    data
      ? data
      : {
          entityKey: '',
          title: '',
          body: '',
        }
  );
  const [dontAllowMultipleToggles, setDontAllowMultipleToggles] =
    useState(true);
  const accordionModalTitle = 'Edit accordion';

  const onAccordionUpperRowClick = () => {
    if (!disableEditor) return;
    setIsOpen(!isOpen);
  };

  const formatBody = (body: any) => {
    const cmsBasePath = getSettings.cmsBaseImagePath.cms;

    if (body.includes(cmsBasePath)) return body;

    return body.replaceAll('/static/node/', `${cmsBasePath}/static/node/`);
  };

  const onAccordionEditClick = () => {
    if (disableEditor && dontAllowMultipleToggles) {
      disableEditor();
      setDontAllowMultipleToggles(false);
    }
  };

  return (
    <div className="accordion-container">
      <div className="accordion-container-upper-row">
        <div
          className="accordion-container-upper-row--clickable"
          onClick={() => onAccordionUpperRowClick()}
        >
          <div className="accordion-container-upper-row--wrapper">
            {formatAccordionTitle(accordion.title)}
            <div className="accordion-container-upper-row--icon">
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
          </div>
        </div>
        {isOpen && (
          <div
            className="accordion-container__body content"
            dangerouslySetInnerHTML={{
              __html: formatBody(accordion.body),
            }}
          ></div>
        )}
      </div>

      <div
        onClick={() => onAccordionEditClick()}
        className="accordion-container--edit"
      >
        <Accordion
          onSubmit={onSubmit}
          defaultAccordion={data}
          enableEditor={enableEditor}
          setParentAccordion={setAccordion}
          setDontAllowMultipleToggles={setDontAllowMultipleToggles}
          accordionModalTitle={accordionModalTitle}
        >
          <div>
            <Pencil className="accordion-container--edit--icon" />
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default AtomicAccordionComponent;
