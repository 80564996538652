import './ChildPageMenu.scss';

import PortalNode from 'api/models/PortalNode';
import OnlyWithRole from 'components/OnlyWithRole';
import MosaicLink from 'components/Portal/MosaicLink';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ChildPageMenu = ({ page }) => {
  const childPages = page
    .getChildPages()
    .filter((page) => !page.shouldHideFromNavigation());

  return (
    <div className="child-page-menu">
      <ul className="child-page-menu__mosaic-links">
        {childPages.map((cPage) => (
          <MosaicLink
            key={cPage.id}
            toPage={cPage}
            useCardStyle={page.getUseCompactChildPageListing()}
          />
        ))}
      </ul>
      <OnlyWithRole viewableByRole="AvenuePortalEditor">
        <div className="child-page-menu__actions">
          <Link className="child-page-menu__edit-page" to={page.getEditPath()}>
            Edit
          </Link>
          <Link
            className="child-page-menu__add-page"
            to={page.getCreateChildPath()}
          >
            Add page
          </Link>
          <Link
            className="child-page-menu__add-block"
            to={`${page.getCreateChildPath()}-block`}
          >
            Add block
          </Link>
        </div>
      </OnlyWithRole>
    </div>
  );
};

ChildPageMenu.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
};

export default ChildPageMenu;
