import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ProductOfTheWeekCardSkeleton = () => (
  <Stack
    sx={(theme) => ({
      border: `1px solid ${theme.colors.border.surfacePrimary}`,
      borderRadius: theme.border.radius.md,
      overflow: 'hidden',
    })}
  >
    <Box
      sx={{
        width: '100%',
        aspectRatio: 1.1,
      }}
    >
      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
    <Stack
      sx={(theme) => ({
        gap: theme.spacing('xs'),
        padding: theme.spacing('sm'),
      })}
    >
      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={{
          width: '100%',
          height: '1.5rem',
        }}
      />
      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={{
          width: '100%',
          height: '4rem',
        }}
      />
    </Stack>
  </Stack>
);

export { ProductOfTheWeekCardSkeleton };
