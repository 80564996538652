import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ProfileCardSkeleton = () => (
  <Stack
    sx={(theme) => ({
      backgroundColor: theme.colors.surface.informative,
      borderRadius: theme.border.radius.md,
      padding: theme.spacing('md'),
      alignItems: 'center',
      textAlign: 'center',
      rowGap: theme.spacing('sm'),
    })}
  >
    <Stack
      sx={(theme) => ({
        maxWidth: '9.75rem',
        width: '100%',
        marginBottom: theme.spacing('xs'),
        flexShrink: 0,
      })}
    >
      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={() => ({
          width: '100%',
          paddingTop: '100%',
          borderRadius: '50%',
        })}
      />
    </Stack>
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{ height: '3.25rem', width: '100%' }}
    />
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{ height: '1.125rem', width: '100%' }}
    />
  </Stack>
);

export { ProfileCardSkeleton };
