import './Documents.scss';

import * as API from 'api/sharepointdocuments';
import { ReactComponent as Plus } from 'components/Icon/Plus.svg';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { GAonDownloadDocument, GAOnPreviewDocument } from 'utils/analytics';

import FileManager from './components/FileManager';

const ROOT_FOLDER_NAME = 'Article folder';

const createFolder = async () => {
  try {
    const response = await API.createNewArticleFolder();
    return response.data.data.name;
  } catch (e) {
    return this.asyncSetState({
      error: true,
      errorMessage: e.message,
    });
  }
};

const Documents = ({ folderName, edit, onChange, topic }) => {
  const setSharepointFolderName = (folderName) => {
    onChange({ sharepointFolderName: folderName, count: 0 });
  };
  if (edit && !folderName) {
    return (
      <div className="documents">
        <div
          className="documents__add"
          onClick={() => {
            (async () => {
              setSharepointFolderName(await createFolder());
            })();
          }}
        >
          <Plus />
          <p>Add documents</p>
        </div>
      </div>
    );
  }
  return folderName ? (
    <div className="documents">
      <Suspense fallback={<div />}>
        <FileManager
          rootName={ROOT_FOLDER_NAME}
          editMode={edit}
          folderName={folderName}
          onChange={onChange}
          onDownload={(fileName) => GAonDownloadDocument({ name: fileName })}
          onPreview={(fileName) => GAOnPreviewDocument({ name: fileName })}
          topic={topic}
        />
      </Suspense>
    </div>
  ) : null;
};

Documents.propTypes = {
  folderName: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Documents;
