import './careerItem.scss';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface CareerItemProps {
  className?: string;
  jobsPage?: boolean;
}

const CareerItem = ({ className, jobsPage }: CareerItemProps) => {
  const articleCareer = classNames(className, 'career-item', {
    'career-item--jobs-page': jobsPage,
  });

  return (
    <li className={articleCareer}>
      <div className="career-item__title">Your next role with ASSA ABLOY</div>
      <div className="career-item__text-link-wrapper">
        <div className="career-item__text">
          If you want to explore your career potential in the ASSA ABLOY Group
          then we are here to support you.
        </div>
        <Link
          className="career-item__link"
          to="/group-library/human-resources-health-safety/human-resources/recruitment-in-assa-abloy/your-next-role-with-assa-abloy"
        >
          <div className="career-item__button-wrapper">Read more</div>
        </Link>
      </div>
    </li>
  );
};

export default CareerItem;
