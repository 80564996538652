import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

const SubscriptionsSkeleton = () => {
  const theme = useTheme();

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing('md'),
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <Grid
        container
        spacing={{ xs: theme.spacing('lg'), md: theme.spacing('sm') }}
        flexWrap="wrap-reverse"
      >
        <Grid xs={12} md={7}>
          <Stack
            sx={(theme) => ({
              rowGap: theme.spacing('sm'),
            })}
          >
            <Skeleton
              variant="rounded"
              animation="pulse"
              sx={{ height: '2.75rem' }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export { SubscriptionsSkeleton };
