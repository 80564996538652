import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const DisplayAsInfoGraphic = () => (
  <Consumer>
    {({ onToggleArticleValue, article: { displayAsInfographic } }) => (
      <>
        <div className="m-t-4">
          <Flex>
            <Checkbox
              defaultChecked={displayAsInfographic}
              id="displayAsInfographic"
              onChange={() => onToggleArticleValue('displayAsInfographic')}
            />
            <label htmlFor="displayAsInfographic" className="m-l-3">
              Display As Infographic
            </label>
          </Flex>
        </div>
      </>
    )}
  </Consumer>
);

export default DisplayAsInfoGraphic;
