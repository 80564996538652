import { FilterParam } from './JobFilter/JobFilter';

export type JobsRequestParams = {
  user: {
    countryName?: string;
    departmentName?: string;
  };
  pagination: {
    page: number;
    rowsPerPage: number;
  };
  filter: FilterParam;
};

const getRequestQueryString = (params: JobsRequestParams) => {
  const { user, filter, pagination } = params;

  let str = '';
  str = str.concat(`?page[limit]=${pagination.rowsPerPage}`);
  str = str.concat(`&page[offset]=${pagination.page - 1}`);

  if (user.countryName) {
    str = str.concat(`&user[countryname]=${user.countryName}`);
  }
  if (user.departmentName) {
    str = str.concat(`&user[departmentname]=${user.departmentName}`);
  }
  if (filter.locations?.length) {
    str = str.concat(
      `&filter[location.id]=${JSON.stringify(filter.locations)}`
    );
  }
  if (filter.states?.length) {
    str = str.concat(`&filter[state.id]=${JSON.stringify(filter.states)}`);
  }
  if (filter.categories?.length) {
    str = str.concat(
      `&filter[category.id]=${JSON.stringify(filter.categories)}`
    );
  }
  if (filter.seniorityLevels?.length) {
    str = str.concat(
      `&filter[senioritylevel.id]=${JSON.stringify(filter.seniorityLevels)}`
    );
  }

  return str;
};

export { getRequestQueryString };
