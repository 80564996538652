import './AuthorList.scss';

import BlogAuthor from 'api/models/BlogAuthor';
import UserImage from 'components/UserImage';
import PropTypes from 'prop-types';

const AuthorList = ({ onSetBlogAuthor, blogAuthors }) => {
  return (
    <ul className="author-list">
      {blogAuthors.map((blogAuthor) => (
        <li className="author-list__author" key={blogAuthor.id}>
          <button
            onClick={() => onSetBlogAuthor(blogAuthor)}
            className="author-list__click"
          >
            <UserImage
              name={blogAuthor.getPersonName()}
              src={blogAuthor.get('imageUrl')}
            />
            <span className="author-list__name">
              {blogAuthor.getPersonName()}
            </span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default AuthorList;

AuthorList.propTypes = {
  onSetBlogAuthor: PropTypes.func.isRequired,
  blogAuthors: PropTypes.arrayOf(PropTypes.instanceOf(BlogAuthor)),
};
