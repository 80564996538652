import PublishSelector from 'components/PublishSelector';
import PropTypes from 'prop-types';

import { Consumer } from '..';

const DEFAULT_DIVISION_ID = 'division-34';
const DEFAULT_DIVISION_NAME = 'Global (all employees)';

const getDefaultValue = (
  publishedInGlobal,
  publishedInDivisions,
  publishedInSegments,
  publishedInCountries,
  publishedInDepartments,
  publishedInSites,
  publishedInCompanies
) => {
  const mapFields = (items) =>
    items && items.length
      ? items.map((c) => ({
          value: c.id,
          name: c.name,
        }))
      : [];
  // A null value means we have published in global, but internally the publish selector needs a value
  // Only Global, No Country
  let defaultValue = {};
  if (publishedInGlobal) {
    defaultValue = {
      divisions: [{ name: DEFAULT_DIVISION_NAME, value: DEFAULT_DIVISION_ID }],
      countries: [],
    };
  } else {
    defaultValue = {
      divisions: mapFields(publishedInDivisions),
      countries: mapFields(publishedInCountries),
    };
  }
  defaultValue.departments = mapFields(publishedInDepartments);
  defaultValue.sites = mapFields(publishedInSites);
  defaultValue.segments = mapFields(publishedInSegments);
  defaultValue.companies = mapFields(publishedInCompanies);
  return defaultValue;
};

const onChange =
  (onChangeArticle) =>
  ({ divisions, segments, countries, departments, sites, companies }) => {
    const mapFields = (items) =>
      items && items.length
        ? items.map((c) => ({
            id: c.value,
            name: c.name,
          }))
        : null;
    const publishedInGlobal =
      divisions &&
      divisions.some((division) => division.name === DEFAULT_DIVISION_NAME);
    const publishedInDivisions = !publishedInGlobal
      ? mapFields(divisions)
      : null;
    const publishedInCountries = publishedInDivisions
      ? mapFields(countries)
      : null;
    onChangeArticle({
      publishedInGlobal,
      publishedInDivisions,
      publishedInSegments: mapFields(segments),
      publishedInCountries,
      publishedInDepartments: mapFields(departments),
      publishedInSites: mapFields(sites),
      publishedInCompanies: mapFields(companies),
    });
  };

const PublishSelectorComponent = ({
  publishWithSegments = false,
  publishWithSite,
  publishWithCompany,
  publishWithDepartment,
}) => (
  <Consumer>
    {({
      article: {
        publishedInGlobal,
        publishedInDivisions,
        publishedInSegments,
        publishedInCountries,
        publishedInDepartments,
        publishedInSites,
        publishedInCompanies,
      },
      showInitialError,
      onChangeArticle,
    }) => (
      <>
        <p>Select where the items should be published</p>
        <PublishSelector
          publishWithDepartment={publishWithDepartment ?? true}
          publishWithSite={publishWithSite ?? true}
          publishWithSegments={publishWithSegments}
          publishWithCompany={publishWithCompany ?? true}
          onChange={onChange(onChangeArticle)}
          showInitialError={showInitialError}
          defaultDivisionName={DEFAULT_DIVISION_NAME}
          defaultValue={getDefaultValue(
            publishedInGlobal,
            publishedInDivisions,
            publishedInSegments,
            publishedInCountries,
            publishedInDepartments,
            publishedInSites,
            publishedInCompanies
          )}
        />
        <hr className="m-t-6 m-b-5" />
      </>
    )}
  </Consumer>
);

PublishSelectorComponent.propTypes = {
  publishWithSegments: PropTypes.bool,
};

export default PublishSelectorComponent;
