import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { GetContentManagementLibraryArticlesResponse } from 'api/cms/contentManagement/types';
import * as feedbackApi from 'api/feedback';
import { useUser } from 'components/Context/User';
import { TablePagination } from 'componentsNew';
import { useSnackbar } from 'contextNew/Snackbar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';

import * as helpers from './helpers';
import { ManageLibraryActions } from './ManageLibraryActions';
import { ArticleStatus, ManageLibraryFilter } from './ManageLibraryFilter';
import {
  ManageLibraryTable,
  ManageLibraryTableField,
  ManageLibraryTableItem,
  SortOrder,
} from './ManageLibraryTable';

const elementId = 'profile-manage-library';

const INITIAL_FILTER = {
  isAdmin: false,
  articleStatus: ArticleStatus.Published,
};
const INITIAL_SORT = {
  field: ManageLibraryTableField.ExpiryDate,
  order: SortOrder.Asc,
};
const INITIAL_PAGINATION = {
  page: 1,
  rowsPerPage: 10,
};
const INITIAL_SELECTION = {
  byId: {},
  isAllSelected: false,
  isSomeSelected: false,
};

const ManageLibrary = () => {
  const [filter, setFilter] = useState<{
    isAdmin: boolean;
    articleStatus: ArticleStatus;
  }>(INITIAL_FILTER);

  const [sort, setSort] = useState<{
    field: ManageLibraryTableField;
    order: SortOrder;
  }>(INITIAL_SORT);

  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>(INITIAL_PAGINATION);

  const [selection, setSelection] = useState<{
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  }>(INITIAL_SELECTION);

  const [tableContent, setTableContent] = useState<{
    items: ManageLibraryTableItem[];
    total: number;
  }>({ items: [], total: 0 });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUser();
  const { showSnackbar } = useSnackbar();

  const fetchItems = useCallback(
    async (
      filter: { isAdmin: boolean; articleStatus: ArticleStatus },
      sort: { field: ManageLibraryTableField; order: SortOrder },
      pagination: { page: number; rowsPerPage: number }
    ) => {
      let items: ManageLibraryTableItem[] = [];
      let total = 0;

      const fetchItemsRequest = helpers.getFetchItemsRequest(
        filter,
        sort,
        pagination
      );
      if (!fetchItemsRequest) {
        return { items, total };
      }
      try {
        setIsLoading(true);
        const response = (await fetchItemsRequest.request(
          fetchItemsRequest.queryParams
        )) as GetContentManagementLibraryArticlesResponse;

        total = response.data.data.totalRows;
        items = response.data.data.articles.map((cmsArticle) => {
          const item: ManageLibraryTableItem = {
            id: cmsArticle.id,
            title: cmsArticle.title,
            expiryDate: cmsArticle.expiryDate,
            deletionDate: cmsArticle.deletionDate,
            hasSubPages: cmsArticle.hasSubPages,
            brokenLinksCount: cmsArticle.brokenLinksCount,
            modifiedDate: cmsArticle.modifiedDate,
            informationOwner: cmsArticle.informationOwner,
            linkPath: `/library-redirect/${cmsArticle.id}`,
          };
          return item;
        });
      } catch {
        showSnackbar({
          type: 'error',
          text: translations.manageContentFetchError,
        });
        setIsLoading(false);
        return { items, total };
      }
      if (filter.articleStatus !== ArticleStatus.Published || !items.length) {
        setIsLoading(false);
        return { items, total };
      }
      try {
        const itemIds = items.map((item) => item.id);
        const response = await feedbackApi.getFeedbackList(itemIds);

        const feedbackCountByItemId =
          helpers.getFeedbackCountByItemId(response);
        items = items.map((item) => {
          const feedbackCount = feedbackCountByItemId[item.id];
          if (!feedbackCount) {
            return item;
          }
          return {
            ...item,
            feedback: {
              helpfulCount: feedbackCount.helpfulCount,
              notHelpfulCount: feedbackCount.notHelpfulCount,
              neutralCount: feedbackCount.neutralCount,
            },
          };
        });
      } catch {
        setIsLoading(false);
        showSnackbar({
          type: 'error',
          text: translations.manageContentFetchError,
        });
        return { items, total };
      }
      setIsLoading(false);
      return { items, total };
    },
    [showSnackbar]
  );

  const handleFilterChange = useCallback(
    async (newFilter: { isAdmin: boolean; articleStatus: ArticleStatus }) => {
      let newSort = INITIAL_SORT;

      switch (newFilter.articleStatus) {
        case ArticleStatus.Published:
          newSort = {
            field: ManageLibraryTableField.ExpiryDate,
            order: SortOrder.Asc,
          };
          break;
        case ArticleStatus.Unpublished:
          newSort = {
            field: ManageLibraryTableField.DeletionDate,
            order: SortOrder.Asc,
          };
          break;
        case ArticleStatus.Drafts:
          newSort = {
            field: ManageLibraryTableField.ModifiedDate,
            order: SortOrder.Asc,
          };
          break;
        case ArticleStatus.Ownerless:
          newSort = {
            field: ManageLibraryTableField.ExpiryDate,
            order: SortOrder.Asc,
          };
          break;
      }
      const newPagination = {
        ...pagination,
        page: 1,
      };
      setFilter(newFilter);
      setSort(newSort);
      setPagination(newPagination);
      setSelection(INITIAL_SELECTION);
      const newTableContent = await fetchItems(
        newFilter,
        newSort,
        newPagination
      );
      setTableContent(newTableContent);
    },
    [pagination, fetchItems]
  );

  const handleSortChange = useCallback(
    async (newSort: { field: ManageLibraryTableField; order: SortOrder }) => {
      setSort(newSort);

      if (tableContent.items.length === tableContent.total) {
        const newTableContent = {
          ...tableContent,
          items: helpers.sortTableItems(
            tableContent.items,
            newSort.field,
            newSort.order
          ),
        };
        setTableContent(newTableContent);
        return;
      }
      const newPagination = { ...pagination, page: 1 };
      const newTableContent = await fetchItems(filter, newSort, newPagination);
      setTableContent(newTableContent);
      setPagination(newPagination);
      setSelection(INITIAL_SELECTION);
    },
    [tableContent, pagination, fetchItems, filter]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; rowsPerPage: number }) => {
      setPagination(newPagination);
      setSelection(INITIAL_SELECTION);
      if (
        pagination.page === 1 &&
        newPagination.page === 1 &&
        newPagination.rowsPerPage < pagination.rowsPerPage
      ) {
        setTableContent({
          ...tableContent,
          items: tableContent.items.slice(0, newPagination.rowsPerPage),
        });
        return;
      }
      const newTableContent = await fetchItems(filter, sort, newPagination);
      setTableContent(newTableContent);
    },
    [fetchItems, tableContent, filter, pagination, sort]
  );

  const handleActionsComplete = useCallback(
    async (result: { message: string; isSuccess: boolean }) => {
      showSnackbar({
        type: result.isSuccess ? 'success' : 'error',
        text: result.message,
      });
      const newTableContent = await fetchItems(filter, sort, pagination);
      setTableContent(newTableContent);
      setSelection(INITIAL_SELECTION);
    },
    [fetchItems, filter, pagination, showSnackbar, sort]
  );

  const handleActionsCancel = useCallback(
    () => setSelection(INITIAL_SELECTION),
    []
  );

  const selectedIds = useMemo(() => {
    return Object.keys(selection.byId).filter((id) =>
      Boolean(selection.byId[id])
    );
  }, [selection.byId]);

  useEffect(() => {
    async function fetchInitialItems() {
      const newTableContent = await fetchItems(
        INITIAL_FILTER,
        INITIAL_SORT,
        INITIAL_PAGINATION
      );
      setTableContent(newTableContent);
    }
    fetchInitialItems();
  }, [fetchItems]);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <ManageLibraryFilter
        isLoading={isLoading || user.isLoading}
        filter={filter}
        onFilterChange={handleFilterChange}
      />
      <TransitionGroup>
        {!isLoading && !user.isLoading && selection.isSomeSelected && (
          <Collapse>
            <ManageLibraryActions
              selectedIds={selectedIds}
              filterArticleStatus={filter.articleStatus}
              onCancel={handleActionsCancel}
              onComplete={handleActionsComplete}
              sx={(theme) => ({
                borderTopLeftRadius: theme.border.radius.md,
                borderTopRightRadius: theme.border.radius.md,
              })}
            />
          </Collapse>
        )}
      </TransitionGroup>
      <ManageLibraryTable
        isLoading={isLoading || user.isLoading}
        items={tableContent.items}
        filter={filter}
        sort={sort}
        selection={selection}
        onSortChange={handleSortChange}
        onSelectionChange={setSelection}
        {...(!selection.isSomeSelected && {
          sx: (theme) => ({
            borderTopLeftRadius: theme.border.radius.md,
            borderTopRightRadius: theme.border.radius.md,
          }),
        })}
      />
      {tableContent.total > INITIAL_PAGINATION.rowsPerPage && (
        <TablePagination
          disabled={isLoading || user.isLoading}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={
            tableContent.total % pagination.rowsPerPage > 0
              ? Math.trunc(tableContent.total / pagination.rowsPerPage) + 1
              : tableContent.total / pagination.rowsPerPage
          }
          onPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: pagination.rowsPerPage,
              page: value,
            })
          }
          onRowsPerPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: value,
              page: 1,
            })
          }
        />
      )}
    </Stack>
  );
};

export { ManageLibrary };
