import './MenuItem.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const MenuItem = ({
  className,
  page,
  isActive,
  isHovered,
  isMegaMenuOpened,
  label,
  onClick,
  onMouseOver,
  hasArrow,
  href,
  homeIcon,
}) => {
  const menuLinkClassName = classNames('portal-menu-item', {
    'portal-menu-item--active': isActive,
    'portal-menu-item--hovered': isHovered,
    'portal-menu-item--mega-menu-opened': isMegaMenuOpened,
    'portal-menu-item--with-arrow': hasArrow,
  });

  const Component = href ? Link : 'div';

  return (
    <li className={className}>
      <Component
        className={menuLinkClassName}
        to={href || ''}
        onClick={onClick}
        onMouseOver={onMouseOver}
      >
        {homeIcon && homeIcon}
        {label ? label : page.get('title')}
      </Component>
    </li>
  );
};

MenuItem.propTypes = {
  page: PropTypes.object,
  isActive: PropTypes.bool,
  isHovered: PropTypes.bool,
  isMegaMenuOpened: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  hasArrow: PropTypes.bool,
  href: PropTypes.string,
  homeIcon: PropTypes.object,
};

export default MenuItem;
