import { Consumer } from 'components/CreateOrEditArticle';
import LinkButtonText from 'components/CreateOrEditArticle/components/LinkButtonText';
import LinkUrl from 'components/CreateOrEditArticle/components/LinkUrl';
import MaxArticleCount from 'components/CreateOrEditArticle/components/MaxArticleCount';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import Title from 'components/CreateOrEditArticle/components/Title';
import Topic from 'components/CreateOrEditArticle/components/Topic';

const AvenueFeedListingBlock = () => {
  return (
    <Consumer>
      {({
        validationParameters: {
          title: { maxLength },
        },
      }) => {
        const titlePlaceholder = `Title (max ${maxLength} characters)`;
        return (
          <>
            <Title placeholder={titlePlaceholder} />
            <Topic />
            <MaxArticleCount />
            <LinkButtonText
              color={false}
              editable={false}
              placeholder="Link Button Text"
            />
            <LinkUrl editable={false} placeholder="Link URL" />
            <SortIndex />
          </>
        );
      }}
    </Consumer>
  );
};

export default AvenueFeedListingBlock;
