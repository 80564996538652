import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const Pinned = () => (
  <Consumer>
    {({ onToggleArticleValue, article: { pinned } }) => (
      <>
        <Flex>
          <Checkbox
            defaultChecked={pinned}
            id="pinToNavigation"
            onChange={() => onToggleArticleValue('pinned')}
          />
          <label htmlFor="pinToNavigation" className="m-l-3">
            Pin to navigation
          </label>
        </Flex>
        <hr className="m-t-6 m-b-6" />
      </>
    )}
  </Consumer>
);

export default Pinned;
