import Box from 'components/Box';
import { useUser } from 'components/Context/User';
import Flex from 'components/Flex';
import { useLibraryMenu } from 'contextNew/LibraryMenu';
import { addLibraryRoutePrefix } from 'contextNew/LibraryMenu/helpers';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { GAonLibraryCreatePage } from 'utils/analytics';

import Tree from './Tree';

const NavigationContainer = ({ isEditOrCreate }) => {
  const user = useUser();
  const libraryMenu = useLibraryMenu();

  /*
  The Add Page link appears only
  1. If not in Create / Edit Mode
  2. If the depth is less than 5
  3. For the AvenueSuperUser Role from Depth 0 onwards for Global, Division, Region and Country
  4. For the Global Editor Role 
     from Depth 0 onwards for Region and Country
     and Depth 1 onwards in Global and Division
  */
  const showAddPage = useMemo(() => {
    if (
      isEditOrCreate ||
      libraryMenu.selectedDepth < 1 ||
      libraryMenu.selectedDepth > 5
    ) {
      return false;
    }
    if (user.isSuperUser) {
      return true;
    }
    if (user.isGlobalEditor) {
      return (
        libraryMenu.selectedRoot.folderType === 'country' ||
        libraryMenu.selectedRoot.folderType === 'region' ||
        libraryMenu.selectedDepth > 1
      );
    }
    return false;
  }, [
    isEditOrCreate,
    libraryMenu.selectedDepth,
    libraryMenu.selectedRoot,
    user.isGlobalEditor,
    user.isSuperUser,
  ]);

  if (!libraryMenu.selectedRoot) {
    return null;
  }

  return (
    <>
      <Box className="m-b-4 sm-m-b-0" padding={false}>
        <Tree />
      </Box>
      {showAddPage && (
        <Flex type="flex-end" className="m-b-6 sm-bg-blue">
          <Link
            to={`${addLibraryRoutePrefix(libraryMenu.selectedPath)}/create`}
            className="color-blue sm-color-white sm-p--2"
            onClick={GAonLibraryCreatePage()}
          >
            Add page
          </Link>
        </Flex>
      )}
    </>
  );
};

NavigationContainer.propTypes = {
  isEditOrCreate: PropTypes.bool.isRequired,
};

export default NavigationContainer;
