import './index.scss';

import MuiBox from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { getArticle } from 'api/cms/library';
import classNames from 'classnames';
import Box from 'components/Box';
import Documents from 'components/Documents';
import Error from 'components/Error';
import Flex from 'components/Flex';
import PageTitle from 'components/PageTitle';
import Parser from 'components/Parser';
import RelatedContentViewer from 'components/RelatedContent/Viewer';
import RelatedContentWrapper from 'components/RelatedContent/Wrapper';
import Title from 'components/Title';
import EditLink from 'components/ViewArticle/EditLink';
import {
  AlertBar,
  ArticleFeedback,
  ArticleMetaLabels,
  PageViews,
} from 'componentsNew';
import { addLibraryRoutePrefix } from 'contextNew/LibraryMenu/helpers';
import { ArticleEditedBy } from 'pagesNew/Article';
import { Component, Suspense } from 'react';
import { Redirect } from 'react-router-dom';
import { translations } from 'translations';
import { GAitemNotFound, GAonLibraryEditPage } from 'utils/analytics';
import * as libraryArticleUtils from 'utils/cms/libraryArticle';

import ContentLoader from '../ContentLoader';

const getTitles = (type, division, title) => {
  // Titles should be of the format Avenue | Library | {Group or division.name} | title
  const titles = ['Library'];
  if (division) {
    titles.push(division.name);
  } else if (type !== 'aadw:division') {
    titles.pop('Library');
    if (title !== 'Group Library') {
      titles.push('Group Library');
    }
  }

  if (type !== 'n:root' && type !== 'aadw:division') {
    titles.push(title);
  }

  return titles;
};

class ViewLibraryArticle extends Component {
  state = {
    article: {},
    isLoading: true,
    error: null,
    errorMessage: null,
    docCount: null,
  };

  componentDidMount() {
    this._isMounted = true;

    this.fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  asyncSetState = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  fetch = () => {
    getArticle(this.props.id)
      .then((response) => {
        const article = response.data.data;

        this.asyncSetState({
          article,
          isLoading: false,
        });
      })
      .catch((errorObject) => {
        this.asyncSetState({
          error: true,
          isLoading: false,
          errorObject: errorObject || {},
        });
      });
  };

  renderError() {
    const {
      errorObject: { message, response },
    } = this.state;

    if (response) {
      if (response.redirectTo) {
        return <Redirect to={`/library${response.redirectTo}`} />;
      }
      if (response.data && response.data.path) {
        return <Redirect to={`/library${response.data.path}`} />;
      }

      GAitemNotFound(response.status, message, 'library');
    }

    return (
      <Error message={message} status={response?.status} redirectOn404={true} />
    );
  }

  countChangeHandler = (docs) => {
    this.asyncSetState({ docCount: docs.count });
  };

  render() {
    const { isLoading, error, docCount } = this.state;
    const documentWrapperClassName = classNames('body-box__documents-wrapper', {
      loaded: docCount > 0,
    });

    if (isLoading) {
      return <ContentLoader />;
    }

    if (error) {
      return this.renderError();
    }

    const {
      title,
      country,
      division,
      preamble,
      body,
      lastModifiedBy,
      modifiedOn,
      createdOn,
      sharepointFolderName,
      _doc,
      type,
      informationOwner,
      relatedContent,
    } = this.state.article;
    return (
      <>
        <Box className="cms__article m-b-6">
          <PageTitle titles={getTitles(type, division, title)} />
          <AlertBar
            type="warning"
            open={libraryArticleUtils.isExpired(this.state.article)}
            text="This page has not been updated for two years. Please contact the information owner of this page for further information."
            sx={(theme) => ({ marginBottom: theme.spacing('sm') })}
          />
          <Title>{title}</Title>
          <Flex type="space-between m-b-4">
            <ArticleMetaLabels
              modifiedDate={lastModifiedBy?.date}
              countries={country ? [country] : []}
              divisions={division ? [division] : []}
            />
            <EditLink
              viewableByRole="AvenueGlobalEditor"
              url={addLibraryRoutePrefix(this.props.id)}
              onClick={GAonLibraryEditPage(division, country)}
            />
          </Flex>
          <div className="content">
            <p className="preamble">{preamble}</p>
            <div className="body">
              <Parser
                html={body}
                type="Library"
                country={country}
                division={division}
              />
            </div>
          </div>
          <MuiBox
            sx={(theme) => ({
              borderRadius: theme.border.radius.lg,
              backgroundColor: theme.colors.surface.secondary,
              padding: {
                xs: theme.spacing('sm'),
                md: theme.spacing('md'),
              },
            })}
          >
            <ArticleFeedback
              articleId={_doc}
              articleTitle={title}
              createdDate={createdOn}
              modifiedDate={modifiedOn}
              informationOwnerId={informationOwner.oid}
              informationOwnerName={informationOwner.name}
              editorId={lastModifiedBy?.oid}
            />
          </MuiBox>
          <Stack
            direction="row"
            sx={(theme) => ({
              marginTop: theme.spacing('sm'),
            })}
          >
            {lastModifiedBy?.date &&
              lastModifiedBy?.oid &&
              lastModifiedBy?.name && (
                <ArticleEditedBy
                  date={lastModifiedBy.date}
                  editorId={lastModifiedBy.oid}
                  editorName={lastModifiedBy.name}
                />
              )}
            <PageViews
              id="page-views"
              tooltip={translations.pageViewLibraryToolTipText}
              sx={{ marginLeft: 'auto' }}
            />
          </Stack>
        </Box>
        {sharepointFolderName && (
          <div className={documentWrapperClassName}>
            <Title size="small">Documents</Title>
            <Box className="m-b-6">
              <Suspense fallback={<div />}>
                <Documents
                  folderName={sharepointFolderName}
                  edit={false}
                  onChange={this.countChangeHandler}
                />
              </Suspense>
            </Box>
          </div>
        )}
        {relatedContent &&
          relatedContent.length > 0 &&
          (this.props.screenWidth > 1499 ? (
            <RelatedContentWrapper
              destinationElementId="library-related-content-placeholder"
              loadingDelay={400}
            >
              <RelatedContentViewer
                contents={relatedContent}
                stackVertically={true}
              />
            </RelatedContentWrapper>
          ) : (
            <RelatedContentViewer contents={relatedContent} />
          ))}
      </>
    );
  }
}

export default ViewLibraryArticle;
