import { UserConsumer } from 'components/Context/User';
import withConsumer from 'components/Context/withConsumer';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { GAonPageTitleChange } from 'utils/analytics';

const setTitle = ({ titles, userContext }) => {
  const title = ['Avenue', ...titles].filter(Boolean).join(' | ');

  if (document.title !== title) {
    document.title = title;

    if (!userContext || userContext.isLoading) {
      return;
    }
    GAonPageTitleChange(title, window.location.pathname);
  }
};

class PageTitle extends PureComponent {
  componentDidMount() {
    setTitle(this.props);
  }

  componentDidUpdate() {
    setTitle(this.props);
  }

  render() {
    return null;
  }
}

PageTitle.propTypes = {
  titles: PropTypes.array.isRequired,
  track: PropTypes.bool,
};

PageTitle.defaultProps = {
  track: true,
};

export default withConsumer(UserConsumer, PageTitle, 'userContext');
