import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { translations } from 'translations';

type JobContentHiringManagerProps = {
  elementId: string;
  hiringManager?: string;
};

const JobContentHiringManager = ({
  elementId,
  hiringManager,
}: JobContentHiringManagerProps) => {
  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: 'row',
        gap: theme.spacing('xxxs'),
      })}
    >
      <Icon type="briefcase" color="secondary" size={20} />
      <Typography
        variant="caption"
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          color: theme.colors.text.tertiary,
        })}
      >
        {hiringManager || translations.notAvailable}
      </Typography>
    </Stack>
  );
};

export { JobContentHiringManager };
