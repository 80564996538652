import ChannelSelector from 'components/ChannelSelector';

import { Consumer as SnackbarConsumer } from '../..';

interface ChannelsProps {
  isCreating?: boolean;
  titleClass?: string;
}

const Channels = ({ isCreating, titleClass }: ChannelsProps) => {
  return (
    <SnackbarConsumer>
      {({ onChangeArticle, article: { channels } }) => (
        <div className="channels">
          <p className={titleClass}>Select a topic (mandatory, maximum two)</p>
          <ChannelSelector
            onChange={(value: any) => onChangeArticle({ channels: value })}
            defaultSelected={channels}
            isCreating={isCreating}
          />
        </div>
      )}
    </SnackbarConsumer>
  );
};

export default Channels;
