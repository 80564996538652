import { translations } from 'translations';

export type ValidationResult = {
  valid: boolean;
  messages: string[];
};

const mandatory = (value: any): ValidationResult => {
  const valid = value !== null && value !== undefined && value !== '';
  if (valid) {
    return { valid, messages: [] };
  }

  return { valid, messages: [translations.formValidationRequiredGeneric] };
};

export const validators = { mandatory } as const;

export type ValidatorName = keyof typeof validators;

export const validateField = (
  value: any,
  validatorNames: ValidatorName[]
): ValidationResult => {
  const result: ValidationResult = { valid: true, messages: [] };

  validatorNames.forEach((validatorName) => {
    const validatorFunction = validators[validatorName];
    const validatorResult = validatorFunction(value);
    if (!validatorResult.valid) {
      result.valid = false;
      result.messages = [...result.messages, ...validatorResult.messages];
    }
  });
  return result;
};
