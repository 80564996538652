import confetti from 'canvas-confetti';

const CONFETTI_SHAPE_1 = confetti.shapeFromPath({
  path: 'M0 10.2015C4.97719 13.67 9.17871 19.5189 10.9886 27C13.3156 24.4156 15.384 21.3552 17 17.7506C13.2509 9.3854 7.11027 3.26448 0.129276 0C0.711025 3.60453 0.581749 7.00503 0 10.1335',
});
const CONFETTI_SHAPE_2 = confetti.shapeFromPath({
  path: 'M0 1.74581L13.5389 25L25 23.2542L11.4611 0L0 1.74581Z',
});
const CONFETTI_SHAPE_3 = confetti.shapeFromPath({
  path: 'M20.9014 0C20.9014 0 27.1777 4.12529 30.1764 4.48716C33.1751 4.84903 14.5553 15.4879 6.46583 13.8234C6.46583 13.8234 -0.43815 4.41479 0.607905 3.8358C1.65396 3.25681 9.11582 7.02024 20.9014 0Z',
});
const CONFETTI_SHAPE_4 = confetti.shapeFromPath({
  path: 'M9 0L2.48507 3.35294L0 19L6.44775 15.6471L9 0Z',
});
const CONFETTI_SHAPE_5 = confetti.shapeFromPath({
  path: 'M10.9615 0L0.5 5.20164L23.9696 16L34.5 10.7325L10.9615 0Z',
});
const CONFETTI_SHAPE_6 = confetti.shapeFromPath({
  path: 'M11.9571 0.0343151C11.9571 0.0343151 9.2449 -0.439099 7.39268 2.13089C5.54047 4.70087 1.90219 5.44481 0.248431 7.13559C-1.40533 8.82638 5.73892 21 5.73892 21C5.73892 21 9.44335 17.2127 11.031 12.9519C12.6186 8.69112 15 7.87954 15 7.87954L11.8909 0.10195L11.9571 0.0343151Z',
});
const CONFETTI_SHAPE_7 = confetti.shapeFromPath({
  path: 'M2.73658 0C2.34564 1.71053 1.69408 3.21579 0 3.96842C3.97456 6.97895 8.01428 9.98947 11.9888 13C14.2693 10.4684 11.9889 4.44737 11.9237 0.0684252H2.73658V0Z',
});
const CONFETTI_SHAPE_8 = confetti.shapeFromPath({
  path: 'M0 3.34328C5.47297 9.61194 13.0405 13.4428 21.1487 14C22.8378 11.2836 24.1216 8.42787 25 5.50249C18.9865 5.9204 13.1081 3.9005 8.51352 0C5.94595 1.46269 3.04054 2.57711 0 3.34328Z',
});

const CONFETTI_SHAPES = [
  CONFETTI_SHAPE_1,
  CONFETTI_SHAPE_2,
  CONFETTI_SHAPE_3,
  CONFETTI_SHAPE_4,
  CONFETTI_SHAPE_5,
  CONFETTI_SHAPE_6,
  CONFETTI_SHAPE_7,
  CONFETTI_SHAPE_8,
];

const COLORS = ['#FFFFFF', '#00A0D0', '#7CC1DE', '#C8E0EA', '#E6EFF4'];

export const getRandomColor = () => {
  return COLORS[Math.floor(Math.random() * COLORS.length)];
};

export const getRandomConfettiShape = () => {
  return CONFETTI_SHAPES[Math.floor(Math.random() * CONFETTI_SHAPES.length)];
};

export const randomInRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};
