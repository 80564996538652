import Box from 'components/Box';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import Resize from 'components/Resize';
import Title from 'components/Title';
import UserImage from 'components/UserImage';
import { GAonContactClick } from 'utils/analytics';

import ContentLoader from './ContentLoader';

const getDivision = (response) => {
  if (response.divisionName) {
    return response.divisionName;
  }

  if (response.division) {
    return response.division.name || response.division;
  }

  return null;
};

const ProfileTable = ({ isLoading, response, isProfile }) => (
  <>
    <PageTitle titles={[isProfile ? 'My Profile' : 'Profile']} />
    <Title color="white">{isProfile ? 'My Profile' : 'Profile'}</Title>
    <Box className="profile">
      {isLoading ? (
        <Resize>{({ width }) => <ContentLoader width={width} />}</Resize>
      ) : (
        <>
          <div className="profile__user-wrapper">
            <UserImage
              src={response.imageUrl}
              size="medium"
              name={`${response.firstName} ${response.lastName}`}
              initials={`${response.firstName[0]}${response.lastName[0]}`}
            />
            <span className="profile__user-text">
              {response.firstName} {response.lastName}
            </span>
          </div>
          <table>
            <tbody>
              <tr className="profile__row">
                <td>Job title:</td>
                <td>{response.title}</td>
              </tr>

              <tr className="profile__row">
                <td>Company:</td>
                <td>{response.companyName}</td>
              </tr>

              <tr className="profile__row">
                <td>Division:</td>
                <td>{getDivision(response)}</td>
              </tr>

              {response.division && response.division.id === 'division-26' && (
                <tr className="profile__row">
                  <td>Segment:</td>
                  <td>{response.segmentName}</td>
                </tr>
              )}

              <tr className="profile__row">
                <td>Country:</td>
                <td>
                  {response.country &&
                    (response.country.name || response.country)}
                </td>
              </tr>
              <>
                <tr className="profile__row">
                  <td>E-Mail:</td>
                  <td>
                    {response.email && (
                      <a
                        href={`mailto:${response.email}`}
                        data-category="Contact"
                        data-action="mailto"
                        data-label="n/a"
                        data-value={response.email}
                        onClick={GAonContactClick}
                      >
                        {response.email}
                      </a>
                    )}
                  </td>
                </tr>
                <tr className="profile__row">
                  <td>Mobile phone:</td>
                  <td>
                    {response.mobilePhoneNo && (
                      <a
                        href={`tel:${response.mobilePhoneNo}`}
                        data-category="Contact"
                        data-action="tel"
                        data-label="n/a"
                        data-value={response.mobilePhoneNo}
                        onClick={GAonContactClick}
                      >
                        {response.mobilePhoneNo}
                      </a>
                    )}
                  </td>
                </tr>
                <tr className="profile__row">
                  <td>Office phone:</td>
                  <td>
                    {response.phoneNo && (
                      <a
                        href={`tel:${response.phoneNo}`}
                        data-category="Contact"
                        data-action="tel"
                        data-label="n/a"
                        data-value={response.phoneNo}
                        onClick={GAonContactClick}
                      >
                        {response.phoneNo}
                      </a>
                    )}
                  </td>
                </tr>
              </>
            </tbody>
          </table>
        </>
      )}

      {isProfile && (
        <div className="profile__button-wrapper">
          <Button
            outbound={true}
            analyticsAction="Click"
            analyticsLabel="Edit profile"
            to="https://adx.assaabloy.net/SelfService#/MyProperties"
          >
            Edit profile
          </Button>
        </div>
      )}
    </Box>
  </>
);

export default ProfileTable;
