import './InnovationStart.scss';

import PortalNode from 'api/models/PortalNode';
import OnlyWithRole from 'components/OnlyWithRole';
import BodyBox from 'components/Portal/BodyBox';
import GatewayProcess from 'components/Portal/GatewayProcess';
import HeaderBox from 'components/Portal/HeaderBox';
import TextMediaBoxList from 'components/Portal/TextMediaBox/List';
import InnovationPortalSearch from 'pages/Portals/Search';
import SearchResults from 'pages/Portals/Search/Result/SearchResults';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import settings from 'settings';

const { portals } = settings;

const InnovationStart = ({ page }) => {
  const firstChild = page.getChildren('listing')[0];

  const history = useHistory();

  const getSearchFromUrl = () => {
    if (!history.location.search) return null;
    return history.location.search.replace('?search=', '');
  };

  const [searchQuery, setSearchQuery] = useState(getSearchFromUrl());

  useEffect(() => {
    const startPageId = portals.find((p) => p.url === 'innovation').id;
    if (searchQuery && searchQuery.length < 2) {
      history.push({
        pathname: `/portals/innovation/${startPageId}`,
      });
    }
  }, [history, searchQuery]);

  const submitQuery = (query) => {
    setSearchQuery(query);
  };
  const resultListShouldRender = () => searchQuery && searchQuery.length > 2;
  const HomeComponent = () => (
    <>
      <BodyBox page={page} />
      <TextMediaBoxList page={page} />
      <GatewayProcess gatewaysChild={firstChild} />
    </>
  );

  return (
    <div className="portal-home">
      <OnlyWithRole viewableByRole="AvenuePortalEditor">
        <div className="portal-home__actions">
          <Link className="portal-home__edit-page" to={page.getEditPath()}>
            Edit
          </Link>
          <Link
            className="portal-home__add-page"
            to={page.getCreateChildPath()}
          >
            Add page
          </Link>
          <Link
            className="portal-home__add-block"
            to={`${page.getCreateChildPath()}-block`}
          >
            Add block
          </Link>
        </div>
      </OnlyWithRole>
      <HeaderBox
        page={page}
        showHero={true}
        searching={searchQuery && searchQuery.length > 2}
      >
        <>
          <div className="relative">
            <InnovationPortalSearch
              portalType={page.attributes.portalType}
              portalId={page.id}
              submitQuery={submitQuery}
              history={history}
            />
          </div>
        </>
      </HeaderBox>
      {resultListShouldRender() ? (
        <SearchResults
          searchQuery={searchQuery}
          portalType={page.attributes.portalType}
        />
      ) : (
        <HomeComponent />
      )}
    </div>
  );
};

InnovationStart.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
};

export default InnovationStart;
