import './CommentAndLike.scss';

import { deleteLike, getLikes, sendLike } from 'api/feedback';
import classNames from 'classnames';
import Button from 'components/Button';
import { ReactComponent as AvenueHeart } from 'components/Icon/AvenueGreyHeart.svg';
import { Tooltip } from 'componentsNew';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { GAonLikeSent } from 'utils/analytics';

class LikeButton extends Component {
  state = {
    error: false,
    likedByMe: this.props.likedByMe || false,
    likesCount: this.props.likesCount || 0,
    likeInProgress: false,
    updated: false,
    targetEntityId: this.props.targetEntityId,
  };

  doAction = (action) => {
    const { targetEntityId } = this.state;

    if (this.state.loading) {
      return;
    }

    this.asyncSetState({ loading: true });

    action(targetEntityId)
      .then(({ data }) => {
        this.asyncSetState({
          loading: false,
          likeInProgress: false,
          likesCount: data.meta.total,
          likedByMe: data.meta.likedByMe,
        });
      })
      .catch((error) => {
        console.warn(error);

        this.asyncSetState({
          error: true,
          loading: false,
          likeInProgress: false,
        });
      });
  };

  componentDidMount() {
    this._isMounted = true;
    if (typeof this.props.likesCount !== 'number') {
      this.doAction(getLikes);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.targetEntityId !== prevProps.targetEntityId) {
      this.setState({ targetEntityId: prevProps.targetEntityId }, () => {
        this.doAction(getLikes);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  asyncSetState = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  sendLike = () => {
    this.setState({ likeInProgress: true });
    const { articleTitle, clickInFeed, isCommentLiked } = this.props;
    GAonLikeSent(articleTitle, clickInFeed, isCommentLiked);

    this.doAction(sendLike);
  };

  deleteLike = () => {
    this.setState({ likeInProgress: true });

    this.doAction(deleteLike);
  };

  render() {
    const { loading, error, likedByMe, likesCount, likeInProgress } =
      this.state;

    if (error) {
      return null;
    }

    const buttonClassName = classNames('comment-like-button', 'like-button', {
      'comment-like-button--liked-by-me': likedByMe,
      'comment-like-button--liked-by-others': !likedByMe && likesCount > 0,
    });

    return (
      <Button
        onClick={likedByMe ? this.deleteLike : this.sendLike}
        disabled={likeInProgress || loading}
        size="small"
        color="none"
        className={buttonClassName}
        width="narrow"
      >
        <Tooltip title="Like">
          <AvenueHeart />
        </Tooltip>
        <span className="comment-like-button__text">{likesCount}</span>
      </Button>
    );
  }
}

LikeButton.propTypes = {
  id: PropTypes.string,
  targetEntityId: PropTypes.string.isRequired,
  clickInFeed: PropTypes.bool,
  articleTitle: PropTypes.string,
  isCommentLiked: PropTypes.bool,
};

export default LikeButton;
