import './Hamburger.scss';

import classNames from 'classnames';
import { forwardRef } from 'react';

interface HamburgerProps {
  onClick: () => void;
  label: string;
  isOpen: boolean;
  className?: string;
  color?: string;
}

const Hamburger = forwardRef<HTMLButtonElement, HamburgerProps>(
  ({ onClick, label, isOpen, className, color }, ref) => (
    <button
      className={classNames(className, 'hamburger', {
        'hamburger--open': isOpen,
        [`hamburger--${color}`]: color,
      })}
      onClick={onClick}
      aria-haspopup="true"
      aria-label={label}
      aria-expanded={isOpen}
      ref={ref}
    >
      <span className="hamburger-line" />
      <span className="hamburger-line" />
      <span className="hamburger-line" />
      <span className="hamburger-line" />
    </button>
  )
);

Hamburger.displayName = 'Hamburger';

export default Hamburger;
