import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useUser } from 'components/Context/User';
import { ProfileAvatar } from 'componentsNew';
import { useMemo } from 'react';
import { translations } from 'translations';

import { ProfileCardSkeleton } from './ProfileCardSkeleton';

const elementId = 'profile-card';

const ProfileCard = () => {
  const user = useUser();

  const fullName = useMemo(
    () => `${user?.firstName || ''} ${user?.lastName || ''}`.trim(),
    [user?.firstName, user?.lastName]
  );

  if (user.isLoading) {
    return <ProfileCardSkeleton />;
  }

  return (
    <Stack
      id={elementId}
      component="section"
      aria-label={translations.profileOverview}
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: theme.colors.surface.informative,
        borderRadius: theme.border.radius.md,
        alignItems: 'center',
        textAlign: 'center',
        rowGap: theme.spacing('sm'),
        maxWidth: '20rem',
      })}
    >
      <Stack
        sx={(theme) => ({
          width: '100%',
          maxWidth: '9.75rem',
          marginBottom: theme.spacing('xs'),
          flexShrink: 0,
        })}
      >
        <ProfileAvatar src={user.imageUrl} size="lg" fullWidth />
      </Stack>
      {fullName && (
        <Typography
          variant="h1"
          sx={(theme) => ({
            color: theme.colors.text.brand,
            fontSize: { md: theme.typography.display4.fontSize },
            wordBreak: 'break-word',
            fontWeight: 'bold',
          })}
        >
          {fullName}
        </Typography>
      )}
      {user.title && (
        <Typography
          sx={(theme) => ({
            color: theme.colors.text.brand,
            wordBreak: 'break-word',
          })}
        >
          {user.title}
        </Typography>
      )}
    </Stack>
  );
};

export { ProfileCard };
