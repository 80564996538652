import './Button.scss';

import classNames from 'classnames';
import { forwardRef, memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { GAonOutboundButtonClick } from 'utils/analytics';

interface ButtonProps {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  to?: string;
  analyticsEventLabel?: string;
  outbound?: boolean;
  color?:
    | 'black'
    | 'white'
    | 'blue'
    | 'orient'
    | 'transparent'
    | 'transparent-white'
    | 'transparent-orient'
    | 'none';
  align?: 'right';
  ghost?: boolean;
  size?: 'small' | 'medium' | 'large';
  width?: 'narrow' | 'wide' | 'block';
  selected?: boolean;
  analyticsAction?: string;
  analyticsLabel?: string;
  disabled?: boolean;
}

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      outbound,
      analyticsEventLabel,
      onClick,
      color = 'black',
      to,
      ghost,
      size = 'medium',
      width = 'wide',
      selected = false,
      align,
      analyticsAction,
      analyticsLabel,
      ...props
    },
    ref
  ) => {
    const buttonClassNames = classNames(className, 'button', {
      [`button--${color}`]: color,
      [`button--${size}`]: size,
      [`button--${width}`]: width,
      [`button--align-${align}`]: align,
      'button--ghost': ghost,
      'button--selected': selected,
    });

    if (outbound) {
      return (
        <a
          ref={ref as React.LegacyRef<HTMLAnchorElement>}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          className={buttonClassNames}
          onClick={() => {
            GAonOutboundButtonClick(analyticsAction, analyticsLabel);
            if (onClick) onClick();
          }}
          {...props}
        >
          {children}
        </a>
      );
    }

    if (onClick) {
      return (
        <button
          ref={ref as React.LegacyRef<HTMLButtonElement>}
          className={buttonClassNames}
          onClick={onClick}
          {...props}
        >
          {children}
        </button>
      );
    }
    if (to) {
      return (
        <Link
          ref={ref as React.Ref<HTMLAnchorElement>}
          to={to}
          className={buttonClassNames}
          {...props}
        >
          {children}
        </Link>
      );
    }

    return null;
  }
);

export default memo(Button);
