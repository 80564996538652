import { Border } from './theme';

const createMuiBorder = () => {
  const borderOptions: Border = {
    radius: {
      xs: '0.125rem', // 2px
      sm: '0.25rem', // 4px
      md: '0.375rem', // 6px
      lg: '0.5rem', // 8px
      xl: '0.75rem', // 12px
    },
    thickness: {
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
    },
  };
  return borderOptions;
};

export { createMuiBorder };
