import { getImageSrc } from 'utils/cms';

const injectFullImageUrl = (body) => {
  if (body) {
    const regex = /<img src="(.+?)"/gm;
    let m;
    while ((m = regex.exec(body)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      const src = m[1];

      body = body.replace(
        new RegExp(`<img src="${src}"`),
        `<img src="${getImageSrc(src)}"`
      );
    }
    return body;
  }
};

export default injectFullImageUrl;
