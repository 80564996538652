import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const httpPattern = /^((http|https|ftp):\/\/)/;
const mailtoPattern = /^(mailto:)/;

const EnhancedLink = ({
  to = '',
  className,
  children,
  style,
  onClick = null,
}) => {
  const isInternalRegExp = new RegExp(`^${window.location.origin}`);
  const isInternal = isInternalRegExp.test(to);

  if (isInternal) {
    const href = to.replace(isInternalRegExp, '');
    return (
      <Link to={href} className={className} style={style} onClick={onClick}>
        {children}
      </Link>
    );
  } else {
    if (!httpPattern.test(to) && !mailtoPattern.test(to)) {
      to = `http://${to}`;
    }
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        style={style}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
};

EnhancedLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default EnhancedLink;
