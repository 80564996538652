import { getNext } from 'api';
import { modelFactory } from 'api/models';
import { mapRelationships } from 'utils/misc/mapRelationships';

const extractRelationships = (item, included) => {
  const { relationships, ...rest } = item;
  return {
    ...rest,
    ...mapRelationships(relationships, included),
  };
};

const flatten = (item) => {
  const { attributes, ...rest } = item;
  return { ...rest, ...attributes };
};

const reduceFormattedData = (reducer, response) => {
  let { data, meta, links } = modelFactory(response);

  const formattedData = reducer ? reducer(data, meta) : data;

  return {
    meta,
    links,
    formattedData,
  };
};

const formatResponseData = (reducer, useModelFactory) => (response) => {
  if (useModelFactory) {
    return reduceFormattedData(reducer, response);
  }

  let { data, included, meta, links } = response.data;

  data = Array.isArray(data) ? data.map(flatten) : flatten(data);

  if (included) {
    data = Array.isArray(data)
      ? data.map((item) => extractRelationships(item, included))
      : extractRelationships(data, included);
  }

  const formattedData = reducer ? reducer(data, meta) : data;

  return {
    meta,
    links,
    formattedData,
  };
};

const noError = {
  errorMessage: null,
  errorStatus: null,
  errorFromAPI: null,
  error: false,
};

const getLoadMoreCount = (data) => {
  const {
    meta: {
      page: { limit, offset, total },
    },
    links: { next },
  } = data;

  if (typeof total === 'undefined' && next) {
    return limit;
  }

  const loadedItems = (offset + 1) * limit;
  const newTotal = total - loadedItems;
  const loadMoreCount = newTotal > limit ? limit : newTotal > 0 ? newTotal : 0;

  return loadMoreCount;
};

const createLoadingResponse = (createLoading, dataState, loadingCount) => {
  if (!createLoading) {
    return;
  }

  const response = [];

  const count = loadingCount ? loadingCount : dataState.response.length;

  for (let i = 0; i < count; ++i) {
    response.push(createLoading());
  }

  dataState.response = response;
};

export const getDefaultState = (createLoading, loadingCount) => {
  const dataState = {
    meta: {},
    links: {},
    response: [],
    loadMoreCount: null,
    isLoading: false,
    isLoadingMore: false,
    ...noError,
  };

  createLoadingResponse(createLoading, dataState, loadingCount);

  return dataState;
};

const catchError = (error) => {
  return {
    ...getDefaultState(),
    error: true,
    errorMessage: error.message,
    errorStatus: error.response ? error.response.status : 500,
    errorFromAPI: error.response ? error.response.data : null,
  };
};

export const loadData = ({
  apiCall,
  headers,
  dataState,
  param,
  reducer,
  useModelFactory,
  createLoading,
}) => {
  const promise = apiCall(param, headers)
    .then(formatResponseData(reducer, useModelFactory))
    .then(({ meta, links, formattedData }) => ({
      ...dataState,
      meta,
      links,
      response: formattedData,
      isLoading: false,
    }))
    .catch(catchError);

  const out = {
    newState: {
      ...dataState,
      ...noError,
      isLoading: true,
    },
    promise,
  };

  createLoadingResponse(createLoading, out.newState);

  return out;
};

export const loadMoreData = ({
  headers,
  dataState,
  reducer,
  useModelFactory,
}) => {
  const { links, response: previousData } = dataState;

  const loadMoreCount = getLoadMoreCount(dataState);

  if (!loadMoreCount) {
    return {
      newState: {
        ...dataState,
        loadMoreCount: 0,
        showLoadMore: false,
      },
      promise: null,
    };
  }

  const promise = getNext(links.next, headers)
    .then(formatResponseData(reducer, useModelFactory))
    .then(({ meta, links, formattedData }) => ({
      ...dataState,
      meta,
      links,
      response: Array.isArray(previousData)
        ? previousData.concat(formattedData)
        : formattedData,
      isLoadingMore: false,
    }))
    .catch(catchError);

  return {
    newState: {
      ...dataState,
      ...noError,
      isLoadingMore: true,
      loadMoreCount,
    },
    promise,
  };
};
