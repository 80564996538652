import Author from 'components/Author';
import Box from 'components/Box';
import { Column } from 'components/Grid';

import { Consumer } from '..';

const BlogAuthor = () => (
  <Consumer>
    {({
      article: { blogAuthor, guestAuthor },
      showInitialError,
      onChangeArticle,
      validationParameters: {
        blogAuthor: { maxLength: blogAuthorMaxLength },
        guestAuthor: { maxLength: guestAuthorMaxLength },
      },
    }) => (
      <Column>
        <Box>
          <Author
            setAuthor={(author) => {
              if (!author) {
                onChangeArticle({ blogAuthor: null, guestAuthor: null });
                return;
              }
              if (author.isGuest()) {
                onChangeArticle({
                  blogAuthor: null,
                  guestAuthor: author.getData(),
                });
                return;
              }
              onChangeArticle({
                blogAuthor: author.getData(),
                guestAuthor: null,
              });
            }}
            showInitialError={showInitialError}
            defaultAuthor={guestAuthor || blogAuthor}
            edit={true}
            maxLength={blogAuthor ? blogAuthorMaxLength : guestAuthorMaxLength}
          />
        </Box>
      </Column>
    )}
  </Consumer>
);

export default BlogAuthor;
