import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { TypographyTypeMap } from '@mui/material/Typography';
import { ElementType } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

type ArticleTitleProps = {
  title: string;
  to?: string;
  href?: string;
  variant?: TypographyTypeMap['props']['variant'];
  component?: ElementType;
  color?: string;
  onClick?: () => void;
};

const ArticleTitle = ({
  title,
  to,
  href,
  variant = 'h3',
  component = 'h3',
  color,
  onClick,
}: ArticleTitleProps) => {
  return (
    <Link
      {...(to ? { to: to, component: ReactRouterLink } : { href: href })}
      onClick={onClick}
      sx={(theme) => ({
        color: color || theme.colors.text.secondary,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          color: color || theme.colors.text.actionHover,
        },
      })}
    >
      <Typography
        variant={variant}
        component={component}
        sx={(theme) => ({
          lineHeight: 1.2,
          fontWeight: 'bold',
          marginBottom: theme.spacing('xxs'),
        })}
      >
        {title}
      </Typography>
    </Link>
  );
};

export { ArticleTitle };
