import * as attachmentApi from 'api/cms/attachment';

type ImageRaw = {
  blob: Blob;
  height: number;
  width: number;
};

const uploadImage = async (data: ImageRaw) => {
  const formData = new FormData();
  formData.append('file', data.blob);
  formData.append('width', `${data.width}`);
  formData.append('height', `${data.height}`);
  const response = await attachmentApi.createAttachment(formData);
  const src = response?.data?.data?.url;
  if (typeof src !== 'string') {
    throw Error();
  }
  return src;
};

const deleteImage = async (imageSrc: string) => {
  const imageId = imageSrc.replace('/static/node/', '');
  await attachmentApi.deleteAttachment(imageId);
};

export { deleteImage, uploadImage };
