export const mapIncluded = (item, included) => {
  const relation = included.find((i) => i.id === item.id);
  return {
    ...(relation ? relation.attributes : {}),
    ...item,
  };
};

export const mapRelationships = (relationships, included = []) => {
  if (!relationships) return {};

  return Object.entries(relationships).reduce((acc, [key, value]) => {
    acc[key] = acc[key] || [];
    if (Array.isArray(value.data)) {
      acc[key] = value.data.map((item) => mapIncluded(item, included));
    } else {
      acc[key] = value.data ? mapIncluded(value.data, included) : [];
    }
    return acc;
  }, {});
};
