import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const CreateOrEditProductOfTheWeekSkeleton = () => (
  <>
    <Box
      sx={(theme) => ({
        padding: theme.spacing('sm'),
        boxShadow: '0 2px 8px rgba(9, 88, 112, 0.08)',
        height: '100%',
        background: theme.colors.surface.primary,
      })}
    >
      <Stack
        sx={(theme) => ({
          height: 'auto',
          marginTop: '2rem',
          gap: theme.spacing('md'),
          flexDirection: 'column',
        })}
      >
        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '2rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />
        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '46rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />
        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '1.5rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />

        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '8rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />

        <Stack flexDirection="row">
          <Skeleton
            variant="rounded"
            animation="pulse"
            sx={{
              height: '2rem',
              maxWidth: '5rem',
              flexGrow: 1,
              marginRight: '6rem',
            }}
          />
          <Skeleton
            variant="rounded"
            animation="pulse"
            sx={{
              height: '2rem',
              maxWidth: '5rem',
              flexGrow: 1,
            }}
          />
        </Stack>

        <Stack flexDirection="row">
          <Skeleton
            variant="rounded"
            animation="pulse"
            sx={{
              height: '2rem',
              maxWidth: '8rem',
              flexGrow: 1,
              marginRight: '3rem',
            }}
          />
          <Skeleton
            variant="rounded"
            animation="pulse"
            sx={{
              height: '2rem',
              maxWidth: '8rem',
              flexGrow: 1,
            }}
          />
        </Stack>

        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '1.5rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />

        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '1.5rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />

        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '1.5rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />

        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '1.5rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />

        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '1.5rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />
      </Stack>
    </Box>
    <Stack
      sx={(theme) => ({
        gap: theme.spacing('md'),
        flexDirection: 'row',
        padding: '1.5rem 0',
      })}
    >
      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={{
          height: '3rem',
          maxWidth: '12rem',
          flexGrow: 1,
        }}
      />

      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={{
          height: '3rem',
          maxWidth: '12rem',
          flexGrow: 1,
        }}
      />

      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={{
          height: '3rem',
          maxWidth: '12rem',
          flexGrow: 1,
          marginLeft: 'auto',
        }}
      />
    </Stack>
  </>
);

export { CreateOrEditProductOfTheWeekSkeleton };
