import './TextMediaBox.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import EnhancedLink from 'components/EnhancedLink';
import Iframe from 'components/Iframe';
import Parser from 'components/Parser';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { createEmbedUrl } from 'utils/cms/parseVideoUrls';
import { anchorLinkFormatter, jumpToAnchor } from 'utils/misc/jumpToAnchor';

class TextMediaBox extends Component {
  boxElementRef = null;

  componentDidMount() {
    const { hash } = window.location;
    if (hash.length > 1 && hash.substring(1) === this.props.block.id) {
      jumpToAnchor(this.boxElementRef);
    }
  }

  render() {
    const {
      block,
      block: { attributes },
    } = this.props;
    const isDisplayInfoGraphic = attributes.displayAsInfographic;
    const linkButtonText = attributes.linkButtonText;

    const sectionClassNames = classNames('text-media-box', {
      'text-media-box--reversed': attributes.reverse,
    });

    const linkStyles = {
      color: attributes.textColor,
      border: `1px solid ${attributes.textColor}`,
    };

    const imageClassNames = classNames('text-media-box__image-col', {
      'text-media-box__video-bg': Boolean(attributes.heroVideo),
      'text-media-box--inset-image': attributes.isImageFullyVisible,
    });

    const displayAsInfographicClassNames = classNames({
      'text-media-box__display-infographic-title': isDisplayInfoGraphic,
      'text-media-box__title': !isDisplayInfoGraphic,
    });

    const displayContentClassNames = classNames({
      'text-media-box__display-infographic-content': isDisplayInfoGraphic,
      content: !isDisplayInfoGraphic,
    });

    return (
      <div
        ref={(ref) => (this.boxElementRef = ref)}
        className={sectionClassNames}
        style={{ backgroundColor: attributes.backgroundColor }}
      >
        <div className="text-media-box__content-col">
          <div
            className="text-media-box__content-wrapper"
            style={{ color: attributes.textColor }}
          >
            <h2 className={displayAsInfographicClassNames}>
              <a href={anchorLinkFormatter(window.location, block.id)}>
                {attributes.title}
              </a>
            </h2>
            <div className={displayContentClassNames}>
              <Parser html={attributes.body} type="Portal" />
            </div>
            {attributes.linkUrl && (
              <EnhancedLink
                to={attributes.linkUrl}
                style={linkStyles}
                className="text-media-box__link"
              >
                {linkButtonText && linkButtonText.trim() !== ''
                  ? linkButtonText
                  : 'Learn More'}
              </EnhancedLink>
            )}
          </div>
        </div>

        <div className={imageClassNames}>
          <img
            src={block.getHeroImage(700)}
            alt={attributes.heroAltText}
            title={attributes.heroAltText}
          />
          {attributes.heroVideo && (
            <Iframe title="media" src={createEmbedUrl(attributes.heroVideo)} />
          )}
        </div>
      </div>
    );
  }
}

TextMediaBox.propTypes = {
  block: PropTypes.instanceOf(PortalNode),
};

export default TextMediaBox;
