import * as API from 'api/cms/library';
import Box from 'components/Box';
import { UserConsumer } from 'components/Context/User';
import withConsumer from 'components/Context/withConsumer';
import CreateOrEdit from 'components/CreateOrEditArticle';
import ArticleMeta from 'components/CreateOrEditArticle/components/ArticleMeta';
import Body from 'components/CreateOrEditArticle/components/Body';
import ContentLabels from 'components/CreateOrEditArticle/components/ContentLabels';
import DisplayOnHome from 'components/CreateOrEditArticle/components/DisplayOnHome';
import Documents from 'components/CreateOrEditArticle/components/Documents';
import InformationOwner from 'components/CreateOrEditArticle/components/InformationOwner';
import MenuItemSelect from 'components/CreateOrEditArticle/components/MenuItemSelect';
import PinnedTab from 'components/CreateOrEditArticle/components/PinnedTab';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import PublishOptions from 'components/CreateOrEditArticle/components/PublishOptions';
import RelatedContent from 'components/CreateOrEditArticle/components/RelatedContent';
import { SearchKeywords } from 'components/CreateOrEditArticle/components/SearchKeywords';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import Title from 'components/CreateOrEditArticle/components/Title';
import {
  maxLengthValidator,
  relatedContentValidator,
} from 'components/CreateOrEditArticle/validators';
import Error from 'components/Error';
import { Column, Container } from 'components/Grid';
import OnlyWithRole from 'components/OnlyWithRole';
import { LibraryMenuConsumer } from 'contextNew/LibraryMenu';
import {
  addLibraryRoutePrefix,
  getParentPath,
} from 'contextNew/LibraryMenu/helpers';
import { LibraryRootConsumer } from 'contextNew/LibraryRoot';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ContentLoader from '../ContentLoader';

const getFields = (defaultInformationOwner, parent) => {
  return [
    { key: 'title', required: true, displayName: 'Title', maxLength: 90 },
    {
      key: 'preamble',
      fieldValidator: maxLengthValidator(9999),
      maxLength: 9999,
    },
    { key: 'body' },
    { key: 'sharepointFolderName', defaultValue: null },
    { key: 'sortIndex', defaultValue: 50 },
    {
      key: 'informationOwner',
      defaultValue: defaultInformationOwner,
      required: true,
      displayName: 'Information owner',
    },
    { key: 'parent', defaultValue: parent },
    { key: 'isDraft', defaultValue: false },
    { key: 'isPreselected', defaultValue: false },
    { key: 'relatedContent', fieldValidator: relatedContentValidator },
    { key: 'menuItemId', defaultValue: null },
    { key: 'searchKeywords' },
    { key: 'displayOnHome', defaultValue: true, defaultValueOnEdit: true },
  ];
};

class LibraryEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: /\/create\/?$/.test(props.location.pathname),
      error: null,
      hideDelete: true, // hide until we know # of child articles
      fields: null,
      isDraft: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { isCreating } = this.state;
    const { id } = this.props;

    let response;

    try {
      response = await API.getArticle(id);
    } catch (error) {
      this.setState({ error });
      return;
    }

    const article = response.data.data;
    if (article.isDraft) {
      this.asyncSetState({ isDraft: article.isDraft });
    }

    let defaultInformationOwner;
    let parent;

    if (isCreating) {
      const { userContext } = this.props;
      defaultInformationOwner = {
        name: userContext.displayName,
        oid: userContext.userId,
      };

      parent = article._doc;
    }

    this.asyncSetState({
      fields: getFields(defaultInformationOwner, parent),
    });

    if (!isCreating) {
      API.getChildArticleCount(article._doc)
        .then((res) => {
          const { total } = res.data.meta;

          this.asyncSetState({
            hideDelete: false,
            deleteModalChildren:
              total > 0 ? (
                <p>{`Unpublishing this article will also unpublish ${total} sub-articles.`}</p>
              ) : null,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  asyncSetState = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  onAfterCreate = (article) => {
    const { history, libraryMenuContext } = this.props;
    if (!article.isDraft) {
      libraryMenuContext.onAfterCreate(article.path);
    }
    history.push(addLibraryRoutePrefix(article.path));
  };

  onAfterDelete = (_response, article) => {
    const { history, libraryMenuContext } = this.props;
    libraryMenuContext.onAfterDelete(article.path);
    const redirectTo = getParentPath(article.path);
    history.push(addLibraryRoutePrefix(redirectTo || ''));
  };

  onAfterCancel = (article) => {
    const { id, history } = this.props;
    const { isCreating } = this.state;
    const redirectTo = isCreating
      ? addLibraryRoutePrefix(id)
      : addLibraryRoutePrefix(article.path);
    history.push(redirectTo);
  };

  titleEditable = (article) => {
    const { isCreating } = this.state;
    return isCreating || article.type === 'aadw:article';
  };

  render() {
    const {
      isCreating,
      fields,
      error,
      hideDelete,
      deleteModalChildren,
      isDraft,
    } = this.state;

    const { id, location } = this.props;

    const history = this.props.history;

    if (error) {
      return (
        <Error
          errorMessage={error.message}
          status={error.response.status}
          redirectOn404={true}
        />
      );
    }

    if (!fields) {
      return <ContentLoader />;
    }

    return (
      <CreateOrEdit
        isCreating={isCreating}
        viewableByRole="AvenueGlobalEditor"
        pageTitle="Library"
        ContentLoader={ContentLoader}
        location={location}
        fields={fields}
        onFetch={() => API.getArticle(id)}
        onDelete={API.deleteArticle}
        onCreate={API.createArticle}
        onEdit={API.updateArticle}
        onAfterCreate={this.onAfterCreate}
        onAfterDelete={this.onAfterDelete}
        onAfterCancel={this.onAfterCancel}
        deleteLoadingText="Unpublishing article..."
        deleteSuccessText="Article has been unpublished"
        deleteFailureText="Could not unpublish article"
      >
        <Container className="m-t-2 ie-flex-fix">
          <Column>
            <Box className="m-t-6 lg-m-t-0">
              <Title
                placeholder="Article title (max 90 characters)"
                editable={this.titleEditable}
              />
              <ContentLabels
                isLibrary={true}
                departments={this.props.libraryRootContext.nodes}
                history={history}
              />
              <div className="max-width">
                <Preamble placeholder="Article preamble" />
                <Body placeholder="Article body" form="library" />
                <SortIndex />
              </div>
              <ArticleMeta />
            </Box>
          </Column>
        </Container>

        <Container className="m-t-2 ie-flex-fix">
          <Column>
            <Documents />
          </Column>
        </Container>

        <Container>
          <Column>
            <RelatedContent />
            <SearchKeywords />
            <PublishOptions
              onCancel={`/library${id}`}
              subject="article"
              hideDelete={hideDelete}
              deleteModalChildren={deleteModalChildren}
              deleteButtonText="Unpublish"
              showDraftButton={isCreating || isDraft}
            >
              <PinnedTab />
              <InformationOwner />
              <OnlyWithRole viewableByRole="AvenueAdmin">
                <MenuItemSelect />
              </OnlyWithRole>
              {!isCreating && (
                <>
                  <hr className="m-t-6 m-b-6" />
                  <DisplayOnHome label="Indicate as updated on Avenue home" />
                </>
              )}
            </PublishOptions>
          </Column>
        </Container>
      </CreateOrEdit>
    );
  }
}

const WithLibraryRootContext = withConsumer(
  LibraryRootConsumer,
  LibraryEdit,
  'libraryRootContext'
);

const WithLibraryContext = withConsumer(
  LibraryMenuConsumer,
  WithLibraryRootContext,
  'libraryMenuContext'
);

const WithLibraryAndUserContext = withConsumer(
  UserConsumer,
  WithLibraryContext,
  'userContext'
);

export default withRouter(WithLibraryAndUserContext);
