import { getChannels } from 'api/cms/topics';
import { UserConsumer } from 'components/Context/User';
import Fetcher from 'components/Fetcher';
import Select from 'components/Select';
import React, { FC, useState } from 'react';
import makeQueryString from 'utils/misc/makeQueryString';

interface TopicSelectorProps {
  onChange: React.Dispatch<React.SetStateAction<object>>;
  defaultTopic: string;
}

const values: any = {};

const TopicSelector: FC<TopicSelectorProps> = ({ onChange, defaultTopic }) => {
  const defaultSelected = { isChannel: true, title: defaultTopic };

  const mapItem = ({
    title,
    isChannel,
  }: {
    title: string;
    isChannel: boolean;
  }) => {
    if (!title) return undefined;

    const item = {
      value: JSON.stringify({ isChannel, title }),
      isChannel,
      originalName: title,
      name: isChannel ? `#${title}` : title,
    };

    values[item.value] = item;
    return item;
  };

  const [selectedTopic, setSelectedTopic] = useState(
    defaultSelected ? mapItem(defaultSelected) : {}
  );

  const getData = async (param: string) => {
    const channels = await getChannels(param).then((res) =>
      res.data.data.map(({ title, _doc }: { title: string; _doc: string }) => ({
        title,
        isChannel: true,
        _doc,
      }))
    );
    const mappedData = channels.map(mapItem);
    return { data: { data: mappedData } };
  };

  const changeSelectedTopic = (selected: any) => {
    setSelectedTopic(selected);
    onChange(selected ? values[selected.value] : undefined);
  };

  return (
    <div className="tag-or-channel-selector">
      <UserConsumer>
        {({ divisionId }) => (
          <Fetcher source={getData} param={makeQueryString({ divisionId })}>
            {({ response }: any) => {
              return (
                <Select
                  data={response}
                  placeholder="Select topic"
                  selected={selectedTopic}
                  onChange={changeSelectedTopic}
                  multiple={false}
                />
              );
            }}
          </Fetcher>
        )}
      </UserConsumer>
    </div>
  );
};

export default TopicSelector;
