import * as alertApi from 'api/cms/alert';
import withConsumer from 'components/Context/withConsumer';
import { AvenueRoute } from 'constants/routes';
import { SnackbarConsumer } from 'contextNew/Snackbar';
import { Component, createContext } from 'react';
import { withRouter } from 'react-router-dom';

const { Consumer: AlertConsumer, Provider } = createContext();

const DEFAULT_DIVISION_NAME = 'Global (all employees)';
const DEFAULT_DIVISION_ID = 'division-34';

class AlertProvider extends Component {
  state = {
    notificationStartDate: '',
    notificationEndDate: '',
    values: {},
    title: '',
    notification: '',
    data: [],
    isCreating: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChangeStartDate = (startDate) => {
    if (this._isMounted) {
      if (startDate && startDate !== '') {
        this.setState({ notificationStartDate: startDate });
      } else {
        this.setState({ notificationStartDate: '' });
      }
    }
  };

  onChangeEndDate = (endDate) => {
    if (this._isMounted) {
      if (endDate && endDate !== '') {
        this.setState({ notificationEndDate: endDate });
      } else {
        this.setState({ notificationEndDate: '' });
      }
    }
  };

  getDefaultValue = (
    publishedInGlobal,
    publishedInDivisions,
    publishedInCountries
  ) => {
    // a null value means we have published in global, but internally
    // the publish selector needs a value
    if (publishedInGlobal) {
      return {
        divisions: [
          { name: DEFAULT_DIVISION_NAME, value: DEFAULT_DIVISION_ID },
        ],
        segments: [],
        countries: [],
        departments: [],
        sites: [],
        companies: [],
      };
    }

    return {
      divisions:
        publishedInDivisions && publishedInDivisions.length
          ? publishedInDivisions.map((division) => ({
              value: division.id,
              name: division.name,
            }))
          : [],
      segments: [],
      countries:
        publishedInCountries && publishedInCountries.length
          ? publishedInCountries.map((country) => ({
              value: country.id,
              name: country.name,
            }))
          : [],
      departments: [],
      sites: [],
      companies: [],
    };
  };

  onChange =
    (onChangeArticle) =>
    ({ divisions, countries }) => {
      let publishedInGlobal = false;
      let publishedInDivisions = null;
      let publishedInCountries = null;

      if (
        divisions &&
        divisions.some((division) => division === DEFAULT_DIVISION_NAME)
      ) {
        publishedInGlobal = true;
      } else {
        if (divisions && divisions.length) {
          publishedInDivisions = divisions.map((division) => ({
            id: division.value,
            name: division.name,
          }));
        }

        if (countries && countries.length) {
          publishedInCountries = countries.map((country) => ({
            id: country.value,
            name: country.name,
          }));
        }
      }

      onChangeArticle({
        publishedInGlobal,
        publishedInDivisions,
        publishedInCountries,
      });
    };

  onChangeArticle = (values) => {
    if (this._isMounted) {
      this.setState({ values });
    }
  };

  changeTextValue = (title) => {
    if (this._isMounted) {
      this.setState({ title });
    }
  };

  changeBodyTextValue = (notification) => {
    if (this._isMounted) {
      this.setState({ notification });
    }
  };

  saveAlert = async () => {
    if (this._isMounted) {
      const { snackbarContext } = this.props;
      const {
        title,
        notification,
        notificationStartDate,
        notificationEndDate,
      } = this.state;

      if (
        !title ||
        !notification ||
        !notificationStartDate ||
        !notificationEndDate
      ) {
        snackbarContext.showSnackbar({
          text: 'Some fields appear to be missing',
          type: 'error',
        });
        return;
      }

      const startDate = new Date(notificationStartDate);
      const startDateUTC = new Date(
        Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate(),
          0,
          0,
          0
        )
      );

      const endDate = new Date(notificationEndDate);
      const endDateUTC = new Date(
        Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate(),
          24,
          0,
          -1
        )
      );

      const publishedInCountries = this.state.values.publishedInCountries || [];

      const publishedInDivisions = (
        this.state.values.publishedInDivisions || []
      ).filter((division) => division.id !== DEFAULT_DIVISION_ID);

      const data = {
        title: title,
        notification: notification,
        startDate: startDateUTC.toISOString(),
        endDate: endDateUTC.toISOString(),
        publishedInDivisions: publishedInDivisions,
        publishedInCountries: publishedInCountries,
      };

      try {
        this.setState({ isCreating: true });
        await alertApi.createAlert(data);
        snackbarContext.showSnackbar({
          text: 'Alert has been created',
          type: 'success',
        });
      } catch {
        snackbarContext.showSnackbar({
          text: 'Something went wrong when creating alert',
          type: 'error',
        });
      } finally {
        this.setState({ isCreating: false });
      }
      this.props.history.push(AvenueRoute.ProfileManageAlerts);
    }
  };

  onAfterCancel = () => {
    this.props.history.push('/');
  };

  render() {
    const { notificationStartDate, notificationEndDate } = this.state;
    return (
      <>
        <Provider
          value={{
            ...this.state,
            onChangeStartDate: this.onChangeStartDate,
            onChangeEndDate: this.onChangeEndDate,
            saveData: this.saveAlert,
            onChange: this.onChange,
            getDefaultValue: this.getDefaultValue,
            onChangeArticle: this.onChangeArticle,
            changeTextValue: this.changeTextValue,
            changeBodyTextValue: this.changeBodyTextValue,
            onCancel: this.onAfterCancel,
            DEFAULT_DIVISION_NAME: DEFAULT_DIVISION_NAME,
            startDate: notificationStartDate,
            endDate: notificationEndDate,
          }}
        >
          {this.props.children}
        </Provider>
      </>
    );
  }
}

export { AlertConsumer };

export default withConsumer(
  SnackbarConsumer,
  withRouter(AlertProvider),
  'snackbarContext'
);
