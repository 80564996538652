import './CommentActions.scss';

import Comment, { Action } from 'api/models/Comment';
import { ActionMenu } from 'components/ActionMenu/ActionMenu';
import AttachedModal from 'components/AttachedModal';
import { ReactComponent as DotsCircleIcon } from 'components/Icon/DotsCircle.svg';
import Modal from 'components/Modal';
import React, { useCallback, useMemo, useState } from 'react';

interface CommentActionsProps {
  comment: Comment;
  enabledActions: Action[];
  onDelete: (comment: Comment) => void;
  onUnpublish: (comment: Comment) => void;
  onRepublish: (comment: Comment) => void;
}

const CommentActions = ({
  comment,
  enabledActions,
  onDelete,
  onUnpublish,
  onRepublish,
}: CommentActionsProps) => {
  const [activeAction, setActiveAction] = useState<Action | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const actionMap = useMemo(() => {
    const map: Record<
      Action,
      {
        name: string;
        title: string;
        text: string;
        handler: (comment: Comment) => void;
      }
    > = {
      [Action.Delete]: {
        name: 'Delete',
        title: 'Are you sure you want to delete this comment?',
        text: "The action can't be undone",
        handler: onDelete,
      },
      [Action.Unpublish]: {
        name: 'Unpublish',
        title: 'Are you sure you want to unpublish this comment?',
        text: 'The comment will be hidden from users but still exist in the database',
        handler: onUnpublish,
      },
      [Action.Republish]: {
        name: 'Republish',
        title: 'Are you sure you want to republish this comment?',
        text: 'The comment will again be shown to all users',
        handler: onRepublish,
      },
    };
    return map;
  }, [onDelete, onRepublish, onUnpublish]);

  const onConfirm = useCallback(async () => {
    if (!activeAction) {
      return;
    }
    setIsLoading(true);
    const actionHandler = actionMap[activeAction].handler;
    await actionHandler(comment);
    setIsLoading(false);
    setActiveAction(null);
  }, [activeAction, actionMap, comment]);

  return (
    <>
      {activeAction && (
        <Modal
          title={actionMap[activeAction].title}
          onClose={() => setActiveAction(null)}
          buttons={[
            {
              color: 'white',
              title: 'Cancel',
              onClick: () => setActiveAction(null),
              disabled: isLoading,
            },
            {
              color: 'black',
              title: isLoading ? '...' : 'OK',
              onClick: onConfirm,
              disabled: isLoading,
            },
          ]}
          disableClickOutside={isLoading}
          focus={false}
        >
          <p>{actionMap[activeAction].text}</p>
        </Modal>
      )}
      <AttachedModal
        className="comment-actions"
        buttonContent={<DotsCircleIcon />}
        buttonClassName="comment-actions__menu-button"
        ariaControls="comment-actions-menu"
      >
        <ActionMenu
          id="comment-actions-menu"
          ariaLabel="Comment action menu"
          items={enabledActions.map((action) => {
            return {
              label: actionMap[action].name,
              type: action === Action.Delete ? 'critical' : 'default',
              onClick: () => setActiveAction(action),
            };
          })}
        />
      </AttachedModal>
    </>
  );
};

export { CommentActions };
