import { getAuthor, getAuthors } from 'api/cms/blog/';
import BlogAuthor from 'api/models/BlogAuthor';
import AuthorAdd from 'components/Author/AuthorAdd';
import AuthorList from 'components/Author/AuthorList';
import Fetcher from 'components/Fetcher';
import Modal from 'components/Modal';
import UserFindSelector from 'components/UserFindSelector';
import PropTypes from 'prop-types';
import { Component } from 'react';

import AuthorDisplay from './AuthorDisplay';
import ContentLoader from './ContentLoader';

const AuthorSelector = ({
  onClose,
  open,
  onSetBlogAuthor,
  onSetGuestAuthor,
}) => {
  if (!open) {
    return null;
  }

  // Note that IE11 and autofocus of the userFindSelector do not
  // work. So we must disable focus for the modal
  return (
    <Modal
      onClose={onClose}
      title="Select author"
      closeButton={true}
      focus={false}
    >
      <Fetcher source={getAuthors} useModelFactory={true}>
        {({ response }) => (
          <AuthorList
            blogAuthors={response}
            onSetBlogAuthor={onSetBlogAuthor}
          />
        )}
      </Fetcher>
      <hr className="m-t-3 m-b-5" />
      <h4>Select guest author</h4>
      <UserFindSelector
        className="m-t-5 m-b-5"
        alwaysDoubleLine={true}
        onChange={onSetGuestAuthor}
      />
    </Modal>
  );
};

class Author extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      author: BlogAuthor.create(props.defaultAuthor),
    };
  }

  onSetBlogAuthor = (author) => {
    this.setState({ author });
    this.props.setAuthor(author);
    this.closeModal();
  };

  onSetGuestAuthor = (searchHit) => {
    const author = BlogAuthor.createFromSearchHit(searchHit);

    this.closeModal();

    this.setState({ author });
    this.props.setAuthor(author);
  };

  onSetAuthorDescription = (description) => {
    const { author } = this.state;

    author.set('description', description);

    this.setState({ author });
    this.props.setAuthor(author);
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { edit, showInitialError, maxLength } = this.props;
    const { modalOpen, author } = this.state;

    const showError = !author && showInitialError;

    return (
      <div className="author">
        <AuthorSelector
          onClose={this.closeModal}
          onSetBlogAuthor={this.onSetBlogAuthor}
          onSetGuestAuthor={this.onSetGuestAuthor}
          open={modalOpen}
          edit={edit}
        />
        {!author ? (
          <AuthorAdd openModal={this.openModal} showError={showError} />
        ) : author.isGuest() ? (
          <AuthorDisplay
            author={author}
            showInitialError={showInitialError}
            edit={edit}
            openModal={this.openModal}
            onSetAuthorDescription={this.onSetAuthorDescription}
            maxLength={maxLength}
          />
        ) : (
          <Fetcher
            source={getAuthor}
            param={author.id}
            useModelFactory={true}
            onLoading={ContentLoader}
          >
            {({ response }) => (
              <AuthorDisplay
                author={response}
                edit={edit}
                openModal={this.openModal}
                maxLength={maxLength}
              />
            )}
          </Fetcher>
        )}
      </div>
    );
  }
}

Author.propTypes = {
  author: PropTypes.object,
  showInitialError: PropTypes.bool,
  setBlogAuthor: PropTypes.func,
  edit: PropTypes.bool,
};

export default Author;
