import RichText from 'components/Editable/RichText';

import { Consumer } from '..';

const GoodToKnow = ({
  placeholder,
  fieldName = 'goodToKnowBody',
  showSideButtons = false,
}) => (
  <Consumer>
    {({ article: { [fieldName]: goodToKnowBody }, onChangeArticle }) => (
      <RichText
        wrapper={{ element: 'div', className: 'good-to-know' }}
        onBlur={(value) => onChangeArticle({ [fieldName]: value })}
        content={goodToKnowBody}
        placeholder={placeholder}
        showSideButtons={showSideButtons}
      />
    )}
  </Consumer>
);

export default GoodToKnow;
