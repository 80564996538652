export const formatSites = (response) => {
  let result = [];
  if (response.data && response.data.length > 0) {
    result = response.data.map((site) => ({
      value: site.id,
      name: site.attributes.name,
      country: site.attributes.country,
    }));
  }
  return result;
};
