import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import * as embedHelpers from 'componentsNew/Embed/helpers';
import { cloneElement, useCallback, useState } from 'react';
import URL from 'url';

type MSFormsModalProps = {
  onSubmit: (url: string) => void;
  children: React.ReactElement;
};

const MSFormsModal = ({ onSubmit, children }: MSFormsModalProps) => {
  const [input, setInput] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSubmit = useCallback(() => {
    if (!input) {
      setError('Please enter a URL.');
      return;
    }

    if (!embedHelpers.isMSFormsEmbedUrl(input)) {
      setError(
        'Not a valid embed link from MS Forms. Please check link and try again. It should be only the URL, no iframe tags.'
      );
      return;
    }

    const url = URL.parse(input, true);

    const href = !Boolean(url.query['embed'])
      ? `${url.href}&embed=true`
      : url.href;

    onSubmit(href);
  }, [input, onSubmit]);

  const childElement = cloneElement(children, {
    onClick: handleSubmit,
    'aria-controls': 'ms-forms-modal',
  });

  return (
    <ButtonModalWrapper
      id="ms-forms-modal"
      title="Enter an embed link from MS Forms"
      yesButton={{
        color: 'black',
        title: 'Submit',
        awaitTitle: 'Submit',
      }}
      noButton={{
        color: 'white',
        title: 'Close',
      }}
      errorMessage={error}
      modalChildren={
        <input
          className="modal__input"
          onChange={(e) => setInput(e.target.value)}
        />
      }
      awaitYes={true}
      onNoButtonClick={() => {
        setInput('');
        setError('');
      }}
    >
      {childElement}
    </ButtonModalWrapper>
  );
};

export default MSFormsModal;
