import * as dateFns from 'date-fns';

const transformLocalDayToUTCDate = (
  date: Date,
  time: 'startOfDay' | 'endOfDay' | undefined = 'startOfDay'
) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const utcMs =
    time === 'startOfDay'
      ? Date.UTC(year, month, day, 0, 0, 0, 0)
      : Date.UTC(year, month, day, 23, 59, 59, 0);

  return new Date(utcMs);
};

const transformUTCDayToLocalDate = (
  date: Date,
  time: 'startOfDay' | 'endOfDay' | undefined = 'startOfDay'
) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();

  return time === 'startOfDay'
    ? new Date(year, month, day, 0, 0, 0, 0)
    : new Date(year, month, day, 23, 59, 59, 0);
};

const endOfUTCDay = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const utcMs = Date.UTC(year, month, day, 23, 59, 59, 0);
  return new Date(utcMs);
};

const startOfUTCDay = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const utcMs = Date.UTC(year, month, day, 0, 0, 0, 0);
  return new Date(utcMs);
};

const addDays = (date: Date, days: number) => {
  return dateFns.addDays(date, days);
};

export {
  addDays,
  endOfUTCDay,
  startOfUTCDay,
  transformLocalDayToUTCDate,
  transformUTCDayToLocalDate,
};
