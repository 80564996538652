export const jumpToAnchor = (targetElement) => {
  if (!targetElement) {
    return;
  }
  let preventNativeScrollPositionRestoration = true;
  let gotOnLoadCallback = false;
  let gotOnScrollCallbacks = 0;
  const onLoad = () => {
    gotOnLoadCallback = true;
    window.removeEventListener('load', onLoad);
    // If the scroll callbacks counter is less than zero, the event listener is
    // already unregistered.
    gotOnScrollCallbacks >= 0 && window.removeEventListener('scroll', onScroll);
    setTimeout(() => {
      preventNativeScrollPositionRestoration && targetElement.scrollIntoView();
    }, 0);
  };
  const onScroll = (e) => {
    gotOnScrollCallbacks++;
    // Ignore first and second scroll-event since that is likely
    // the browser trying to restore scroll-position.
    if (gotOnScrollCallbacks === 1 || gotOnScrollCallbacks === 2) {
      e.preventDefault();
      e.stopPropagation();
      return;
    } else {
      preventNativeScrollPositionRestoration = false;
      gotOnScrollCallbacks = -1;
      !gotOnLoadCallback && window.removeEventListener('load', onLoad);
      window.removeEventListener('scroll', onScroll);
    }
  };
  targetElement.scrollIntoView();

  // The browser will try to restore a previous
  // scroll-position when the page load finishes,
  // prevent this.
  window.addEventListener('load', onLoad);

  // If the viewer starts scrolling before the page
  // finishes loading (but the page content is already
  // loaded), then cancel the scroll-position intervention.
  // This yields a single (or two) scroll event to the page.
  window.addEventListener('scroll', onScroll);
};

export const anchorLinkFormatter = ({ href }, anchor) =>
  `${href.replace(/#[\S]+$/, '')}#${anchor}`;
