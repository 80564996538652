import './Responsive.scss';

export const Mobile = ({ children, className, tag: Tag = 'div' }) => (
  <Tag className={`mobile-only ${className ? className : ''}`}>{children}</Tag>
);
export const MobileAndTablet = ({ children, className, tag: Tag = 'div' }) => (
  <Tag className={`mobile-tablet-only ${className ? className : ''}`}>
    {children}
  </Tag>
);
export const Desktop = ({ children, className, tag: Tag = 'div' }) => (
  <Tag className={`desktop-only ${className ? className : ''}`}>{children}</Tag>
);
