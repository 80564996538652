import Stack from '@mui/material/Stack';
import { CmsContentType } from 'api/cms/types';
import * as feedbackApi from 'api/feedback';
import {
  LikesAndCommentsMeta,
  LikesAndCommentsResponse,
  UpdateLikeResponse,
} from 'api/feedback/transformers';
import * as feedbackTransformers from 'api/feedback/transformers';
import Comments from 'components/Comments';
import { ArticleFooter } from 'pagesNew/Article';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useUser } from './Context/User';

type ArticleFooterAndCommentsProps = {
  articleId: string;
  articleIdForLikesCommentsAndViews: string;
  title: string;
  type?: CmsContentType;
  author?: {
    oid: string;
    name: string;
  };
  editor?: {
    oid: string;
    name: string;
    date: string;
  };
  informationOwner?: {
    oid: string;
    name: string;
  };
  created?: string;
  updated?: string;
  disableComments?: boolean;
  disablePageViews?: boolean;
  additionalFeedbackReceivers?: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  }[];
};

const ArticleFooterAndComments = ({
  articleId,
  articleIdForLikesCommentsAndViews,
  title,
  type,
  author,
  editor,
  informationOwner,
  created,
  updated,
  disableComments,
  disablePageViews,
  additionalFeedbackReceivers,
}: ArticleFooterAndCommentsProps) => {
  const [pageViews, setPageViews] = useState<number | null>(null);

  const [likesAndCommentsMeta, setLikesAndCommentsMeta] =
    useState<LikesAndCommentsMeta | null>(null);

  const user = useUser();
  const location = useLocation();
  const commentInputRef = useRef<HTMLInputElement | null>(null);

  const fetchPageViews = useCallback(async (pathname: string) => {
    try {
      const response = await feedbackApi.getPageViews(pathname);
      const result = Number(response?.data?.data?.pageViews) || null;
      return result;
    } catch {
      return null;
    }
  }, []);

  const fetchLikesAndCommentsMeta = useCallback(
    async (targetId: string, userId: string) => {
      try {
        const response = (await feedbackApi.getLikesAndComments([
          targetId,
        ])) as LikesAndCommentsResponse;

        const result =
          feedbackTransformers.likesAndCommentsResponseToLikesAndCommentsMeta(
            response,
            userId,
            targetId
          );
        return result;
      } catch {
        return null;
      }
    },
    []
  );

  const fetchLikesMeta = useCallback(async (targetId: string) => {
    try {
      const response = await feedbackApi.getLikes(targetId);
      if (!response?.data?.meta) {
        return null;
      }
      const result = {
        isLikedByMe: response.data.meta.likedByMe,
        likesCount: response.data.meta.total,
      };
      return result;
    } catch {
      return null;
    }
  }, []);

  const updateLike = useCallback(async (targetId: string, like: boolean) => {
    try {
      const response = like
        ? ((await feedbackApi.sendLike(
            targetId.replace('cms-', '')
          )) as UpdateLikeResponse)
        : ((await feedbackApi.deleteLike(
            targetId.replace('cms-', '')
          )) as UpdateLikeResponse);

      const result = {
        isLikedByMe: response.data.meta.likedByMe,
        likesCount: response.data.meta.total,
      };
      return result;
    } catch {
      return null;
    }
  }, []);

  const handleLikeClick = useCallback(async () => {
    if (!likesAndCommentsMeta) {
      return;
    }
    const like = !likesAndCommentsMeta.isLikedByMe;
    const result = await updateLike(articleIdForLikesCommentsAndViews, like);
    if (!result) {
      return;
    }
    setLikesAndCommentsMeta({
      ...likesAndCommentsMeta,
      isLikedByMe: result.isLikedByMe,
      likesCount: result.likesCount,
    });
  }, [articleIdForLikesCommentsAndViews, likesAndCommentsMeta, updateLike]);

  const handleCommentClick = useCallback(() => {
    if (!commentInputRef.current) {
      return;
    }
    commentInputRef.current.focus();
    commentInputRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  const handleCommentsUpdated = useCallback(
    async (commentsCount: number) => {
      if (
        !user.userId ||
        !likesAndCommentsMeta ||
        likesAndCommentsMeta.commentsCount === commentsCount
      ) {
        return;
      }
      const result = await fetchLikesAndCommentsMeta(
        articleIdForLikesCommentsAndViews,
        user.userId
      );
      if (!result) {
        return;
      }
      setLikesAndCommentsMeta(result);
    },
    [
      articleIdForLikesCommentsAndViews,
      fetchLikesAndCommentsMeta,
      likesAndCommentsMeta,
      user.userId,
    ]
  );

  useEffect(() => {
    async function fetchInitialLikesAndCommentsMeta() {
      if (!user.userId) {
        return;
      }
      const result = await fetchLikesAndCommentsMeta(
        articleIdForLikesCommentsAndViews,
        user.userId
      );
      if (!result) {
        return;
      }
      setLikesAndCommentsMeta(result);
    }
    async function fetchInitialLikesMeta() {
      const result = await fetchLikesMeta(articleIdForLikesCommentsAndViews);
      if (!result) {
        return;
      }
      setLikesAndCommentsMeta({
        targetEntityId: articleIdForLikesCommentsAndViews,
        isLikedByMe: result.isLikedByMe,
        likesCount: result.likesCount,
        isCommentedByMe: false,
        commentsCount: 0,
      });
    }
    if (disableComments) {
      fetchInitialLikesMeta();
    } else {
      fetchInitialLikesAndCommentsMeta();
    }
  }, [
    articleIdForLikesCommentsAndViews,
    disableComments,
    fetchLikesAndCommentsMeta,
    fetchLikesMeta,
    user.userId,
  ]);

  useEffect(() => {
    async function fetchInitialPageViews() {
      const pageViewsPath = location.pathname.replace(
        articleId,
        articleIdForLikesCommentsAndViews
      );
      const result = await fetchPageViews(pageViewsPath);
      if (!result) {
        return;
      }
      setPageViews(result);
    }
    fetchInitialPageViews();
  }, [
    articleId,
    articleIdForLikesCommentsAndViews,
    fetchPageViews,
    location.pathname,
  ]);

  return (
    <Stack
      sx={(theme) => ({
        maxWidth: '670px',
        marginTop: theme.spacing('sm'),
      })}
    >
      <ArticleFooter
        article={{
          title: title,
          informationOwner: informationOwner && {
            id: informationOwner.oid,
            name: informationOwner.name,
          },
          author: author && {
            id: author.oid,
            name: author.name,
          },
          editor: editor && {
            id: editor.oid,
            name: editor.name,
          },
          createdDate: created,
          editedDate: updated,
        }}
        hidePageViews={disablePageViews}
        hideCommentButton={disableComments}
        likesAndCommentsMeta={likesAndCommentsMeta}
        pageViews={pageViews}
        onLikeClick={handleLikeClick}
        onCommentClick={handleCommentClick}
      />
      {!disableComments && (
        <Comments
          articleId={articleIdForLikesCommentsAndViews}
          articleTitle={title}
          type={type}
          author={author}
          editor={editor}
          additionalFeedbackReceivers={additionalFeedbackReceivers}
          commentInputRef={commentInputRef}
          onCommentsCountUpdated={handleCommentsUpdated}
        />
      )}
    </Stack>
  );
};

export { ArticleFooterAndComments };
