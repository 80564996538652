import './SearchSelector.scss';

import classNames from 'classnames';
import Flex from 'components/Flex';
import { ReactComponent as CancelIcon } from 'components/Icon/CancelSmall.svg';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { splitSuggestion } from 'utils/misc/autoSuggest';

const THEME = {
  container: 'search-selector__autosuggest__container',
  input: 'search-selector__autosuggest__input',
  inputFocused: 'search-selector__autosuggest__input--focused',
  suggestionsContainer: 'search-selector__autosuggest__suggestions-container',
  suggestionsContainerOpen:
    'search-selector__autosuggest__suggestions-container--open',
  suggestionsList: 'search-selector__autosuggest__suggestions-list',
  suggestion: 'search-selector__autosuggest__suggestion',
  suggestionHighlighted:
    'search-selector__autosuggest__suggestion--highlighted',
};

const renderSearchSuggestions = (site, { query }) => {
  const parts = splitSuggestion(site.name, query);

  return (
    <Flex className="p-l-2">
      <Flex direction="column" align="start">
        <div className="text">
          {parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;
            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </div>
        <div className="text">{site.country}</div>
      </Flex>
    </Flex>
  );
};

class SearchSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      selectedOptions: [],
      options: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      selectedOptions: this.props.selectedOptions,
    });
  }

  componentDidUpdate(prevProps) {
    // For Reset Button
    if (this.props.selectedOptions && prevProps.selectedOptions) {
      if (
        this.props.selectedOptions.length !== prevProps.selectedOptions.length
      ) {
        this.setState({
          selectedOptions: this.props.selectedOptions,
        });
      }
    } else if (
      (this.props.selectedOptions && !prevProps.selectedOptions) ||
      (!this.props.selectedOptions && prevProps.selectedOptions)
    ) {
      this.setState({
        selectedOptions: this.props.selectedOptions,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isSelected = (currentSearch) => {
    const { selectedOptions } = this.state;
    if (selectedOptions && typeof selectedOptions === 'object') {
      if (Array.isArray(selectedOptions) && selectedOptions.length) {
        return selectedOptions.find((s) => s.value === currentSearch.value);
      } else {
        return selectedOptions.value === currentSearch.value;
      }
    }
    return false;
  };

  onSearchesFetchRequested = debounce(({ value }) => {
    const { disabled } = this.props;
    if (!disabled) {
      this.props.fetchSearchSuggestions(value).then((options) => {
        if (this._isMounted && options && this.state.value) {
          this.setState({
            options: options.map((site) => ({
              ...site,
              isSelected: this.isSelected(site),
            })),
          });
        }
      });
    }
  }, 100);

  onSearchSelected = (_e, { suggestion }) => {
    const { disabled, multiple } = this.props;
    if (this._isMounted && !disabled) {
      let { selectedOptions } = this.state;
      if (multiple) {
        if (!Array.isArray(selectedOptions)) {
          selectedOptions = [selectedOptions];
        }
        const isItemAlreadySelected = selectedOptions.filter(
          (obj) => obj.value === suggestion.value
        );
        if (isItemAlreadySelected && isItemAlreadySelected.length > 0) {
          selectedOptions = selectedOptions.filter(
            (obj) => obj.value !== suggestion.value
          );
        } else {
          selectedOptions.push({
            name: suggestion.name,
            value: suggestion.value,
          });
        }
        this.setState({
          selectedOptions,
          value: '',
        });
        this.props.onChange(selectedOptions);
      } else {
        this.setState({
          selectedOptions: { ...suggestion },
          value: '',
        });
        this.props.onChange({ ...suggestion });
      }
    }
  };

  onSearchesClearRequested = () => {
    const { disabled } = this.props;
    if (this._isMounted && !disabled) {
      this.setState({
        options: [],
        value: '',
      });
    }
  };

  removeSelection = (siteItem) => {
    const { disabled } = this.props;
    if (this._isMounted && !disabled) {
      let { selectedOptions } = this.state;

      if (Array.isArray(selectedOptions)) {
        selectedOptions = selectedOptions.filter(
          (obj) => obj.value !== siteItem.value
        );
        this.setState({
          selectedOptions,
        });
        this.props.onChange(selectedOptions);
      } else {
        this.setState({
          selectedOptions: [],
        });
        this.props.onChange(null);
      }
      // setTimeout is used to ensure rendereding is completed
      // once so there is something to focus on.
      setTimeout(() => {
        this.inputLabel.focus();
      });
    }
  };

  onChange = (_e, { newValue }) => {
    const { disabled } = this.props;
    if (this._isMounted && !disabled && typeof newValue !== 'undefined') {
      this.setState({
        value: newValue,
      });
    }
  };

  displaySelectedSearches = () => {
    let selectedSearchMarkup = null;
    const { selectedOptions } = this.state;
    const { multiple } = this.props;
    const wrapperClass = classNames('search-selector__selection', {
      'search-selector__selection--single': !multiple,
    });

    if (this._isMounted && selectedOptions) {
      if (Array.isArray(selectedOptions) && selectedOptions.length) {
        selectedSearchMarkup = selectedOptions.map((item) => (
          <div key={item.value} className="search-selector__selection">
            <span>{item.name}</span>
            <CancelIcon onClick={() => this.removeSelection(item)} />
          </div>
        ));
      } else if (!Array.isArray(selectedOptions)) {
        selectedSearchMarkup = (
          <div className={wrapperClass}>
            <span>{selectedOptions.name}</span>
          </div>
        );
      }
    }
    return selectedSearchMarkup;
  };

  render() {
    const {
      alwaysDoubleLine,
      className,
      disabled,
      id,
      multiple,
      placeholder,
      showError,
      identifier,
    } = this.props;
    const { value, options } = this.state;

    const displaySelectedSearches = this.displaySelectedSearches();

    const inputProps = {
      value,
      onChange: this.onChange,
      type: 'search',
      id,
      placeholder,
    };

    const theme = { ...THEME };

    if (!alwaysDoubleLine) {
      theme.suggestionsList = `${theme.suggestionsList} ${theme.suggestionsList}--large`;
    }

    const wrapperClass = classNames('search-selector', {
      [className]: className,
      'search-selector--disabled': disabled,
      'search-selector--error': showError,
    });

    return (
      <>
        <div className={wrapperClass} id={identifier}>
          <label
            htmlFor={id}
            ref={(inputLabel) => (this.inputLabel = inputLabel)}
            id="site--selector"
          >
            <Autosuggest
              suggestions={options}
              onSuggestionsFetchRequested={this.onSearchesFetchRequested}
              onSuggestionSelected={this.onSearchSelected}
              onSuggestionsClearRequested={this.onSearchesClearRequested}
              getSuggestionValue={(s) => s.name}
              renderSuggestion={
                this.props.renderSearchSuggestions || renderSearchSuggestions
              }
              inputProps={inputProps}
              alwaysRenderSuggestions={multiple}
              theme={theme}
              disabled={disabled}
            />
          </label>
        </div>
        <div className="search-selector__result">{displaySelectedSearches}</div>
      </>
    );
  }
}

SearchSelector.propTypes = {
  alwaysDoubleLine: PropTypes.bool,
  className: PropTypes.string,
  defaultLabel: PropTypes.string,
  disabled: PropTypes.bool,
  fetchSearchSuggestions: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  renderSearchSuggestions: PropTypes.func,
  selectedOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ]),
  showError: PropTypes.bool,
};

SearchSelector.defaultProps = {
  disabled: false,
  multiple: false,
};

export default SearchSelector;
