import {
  DivisionWithSegmentsAndCountries,
  UpdateProfileSettingsPayload,
} from 'api/organization/types';
import { translations } from 'translations';

import { ValidationResult, ValidatorName } from './validation';

export type PreferencesForm = {
  division: { id: string; name: string } | null;
  segment: { id: string; name: string } | null;
  country: { id: string; name: string } | null;
  department: { id: string; name: string } | null;
  site: { id: string; name: string } | null;
};

export type PreferencesFormFieldName = keyof PreferencesForm;

export type PreferencesFormValidation = Record<
  PreferencesFormFieldName,
  ValidationResult
>;

export const PREFERENCES_FORM_FIELD_SETTINGS: Record<
  PreferencesFormFieldName,
  { validators: Array<ValidatorName>; displayName: string }
> = {
  division: {
    validators: ['mandatory'],
    displayName: translations.division,
  },
  segment: {
    validators: [],
    displayName: translations.segment,
  },
  country: {
    validators: ['mandatory'],
    displayName: translations.country,
  },
  department: {
    validators: ['mandatory'],
    displayName: translations.department,
  },
  site: {
    validators: ['mandatory'],
    displayName: translations.site,
  },
};

export const PREFERENCES_FORM_FIELD_NAMES = Object.keys(
  PREFERENCES_FORM_FIELD_SETTINGS
) as PreferencesFormFieldName[];

const getInitialFormInput = (user?: any) => {
  const formInput: PreferencesForm = {
    division: null,
    segment: null,
    country: null,
    department: null,
    site: null,
  };
  if (!user) {
    return formInput;
  }
  if (user.divisionId && user.divisionName) {
    formInput.division = {
      id: user.divisionId,
      name: user.divisionName,
    };
  }
  if (user.segmentId && user.segmentName) {
    formInput.segment = {
      id: user.segmentId,
      name: user.segmentName,
    };
  }
  if (user.countryId && user.countryName) {
    formInput.country = {
      id: user.countryId,
      name: user.countryName,
    };
  }
  if (user.departmentId && user.departmentName) {
    formInput.department = {
      id: user.departmentId,
      name: user.departmentName,
    };
  }
  if (user.siteId && user.siteName) {
    formInput.site = {
      id: user.siteId,
      name: user.siteName,
    };
  }
  return formInput;
};

const getInitialFormValidation = () => {
  const formValidation: PreferencesFormValidation = {
    division: { valid: true, messages: [] },
    segment: { valid: true, messages: [] },
    country: { valid: true, messages: [] },
    department: { valid: true, messages: [] },
    site: { valid: true, messages: [] },
  };
  return formValidation;
};

const didUserMakeAnyChanges = (formInput: PreferencesForm, user: any) => {
  return !(
    (formInput.division?.id || null) === (user.divisionId || null) &&
    (formInput.division?.name || null) === (user.divisionName || null) &&
    (formInput.segment?.id || null) === (user.segmentId || null) &&
    (formInput.segment?.name || null) === (user.segmentName || null) &&
    (formInput.country?.id || null) === (user.countryId || null) &&
    (formInput.country?.name || null) === (user.countryName || null) &&
    (formInput.department?.id || null) === (user.departmentId || null) &&
    (formInput.department?.name || null) === (user.departmentName || null) &&
    (formInput.site?.id || null) === (user.siteId || null) &&
    (formInput.site?.name || null) === (user.siteName || null)
  );
};

const transformFormInputToUpdateProfileSettingsPayload = (
  formInput: PreferencesForm
) => {
  const payload: UpdateProfileSettingsPayload = {};
  const { division, segment, country, department, site } = formInput;

  if (division) {
    payload.division = {
      data: {
        type: 'division',
        id: division.id,
        name: division.name,
      },
    };
  }
  if (segment) {
    payload.segment = {
      data: {
        type: 'segment',
        id: segment.id,
        name: segment.name,
      },
    };
  }
  if (country) {
    payload.country = {
      data: {
        type: 'country',
        id: country.id,
        name: country.name,
      },
    };
  }
  if (department) {
    payload.department = {
      data: {
        type: 'department',
        id: department.id,
        name: department.name,
      },
    };
  }
  if (site) {
    payload.site = {
      data: {
        type: 'site',
        id: site.id,
        name: site.name,
      },
    };
  }
  return payload;
};

const getCustomSegmentLabel = (divisionId: string | undefined) => {
  switch (divisionId) {
    // Americas division & Entreance systems
    case 'division-26':
    case 'division-29':
      return translations.segment;
    // EMEIA division & Global solutions
    case 'division-31':
    case 'division-32':
      return translations.businessArea;
    default:
      return translations.businessUnit;
  }
};

const isCountryValidForDivision = (
  countryId: string,
  divisionId: string,
  divisionWithSegmentsAndCountries: DivisionWithSegmentsAndCountries[]
) => {
  const validCountries =
    divisionWithSegmentsAndCountries.find(
      (division) => division.id === divisionId
    )?.countries || [];
  return validCountries.some((country) => country.id === countryId);
};

export {
  didUserMakeAnyChanges,
  getCustomSegmentLabel,
  getInitialFormInput,
  getInitialFormValidation,
  isCountryValidForDivision,
  transformFormInputToUpdateProfileSettingsPayload,
};
