import './ScreenSelector.scss';

import { useState } from 'react';

import { Consumer as SnackbarConsumer } from '../..';
import EnableScreen from './EnableScreen';
import ScreenSummary from './ScreenSummary';

const ScreenSelector = () => {
  const [screenEnabledState, setScreenEnabledState] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  return (
    <SnackbarConsumer>
      {({ onChangeArticle, article: { screenEnabled, screenSummary } }) => {
        if (isInitialLoad) {
          setScreenEnabledState(screenEnabled);
          setIsInitialLoad(false);
        }

        return (
          <div className="screen-selector">
            <EnableScreen
              onChange={(screenEnabled: boolean) => {
                setScreenEnabledState(screenEnabled);
                onChangeArticle({ screenEnabled });
              }}
              screenEnabled={screenEnabled}
            />
            {screenEnabledState ? (
              <ScreenSummary
                onChangeSummary={(screenSummary: string) => {
                  onChangeArticle({ screenSummary });
                }}
                screenSummary={screenSummary}
              />
            ) : null}
          </div>
        );
      }}
    </SnackbarConsumer>
  );
};

export default ScreenSelector;
