import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import React, { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';
import * as timeUtils from 'utils/misc/time';

type ArticleEditedByProps = {
  id?: string;
  date: string;
  editorId: string;
  editorName: string;
  sx?: SxProps<Theme>;
};

const ArticleEditedBy = ({
  id,
  date,
  editorId,
  editorName,
  sx,
}: ArticleEditedByProps) => {
  const elements = useMemo(
    () =>
      textUtils.replaceTranslationAliasesWithElements(
        translations.articlePageEditedBy,
        {
          date: timeUtils.getDateOrDaysAgo(date),
          name: (
            <Link
              variant="caption"
              to={`/people/${editorId}`}
              component={ReactRouterLink}
              sx={(theme) => ({
                color: theme.colors.text.tertiary,
                textDecorationColor: theme.colors.text.tertiary,
              })}
            >
              {editorName}
            </Link>
          ),
        }
      ),
    [date, editorId, editorName]
  );

  return (
    <Typography
      id={id}
      variant="caption"
      sx={[
        (theme) => ({ color: theme.colors.text.tertiary }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {elements.map((element, index) => [
        <React.Fragment key={`article-last-edited-by-${index}`}>
          {element}
        </React.Fragment>,
        ' ',
      ])}
    </Typography>
  );
};

export { ArticleEditedBy };
