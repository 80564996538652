import './PersonItem.scss';

import SearchHit from 'api/models/SearchHit';
import { ReactComponent as TeamsIcon } from 'components/Icon/TeamsIcon.svg';
import { saveScrollPosition } from 'components/ScrollRestoration';
import UserImage from 'components/UserImage';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  GAonClickAvenueOverlaySearchResult,
  GAonContactClick,
  GAonSearchResultClick,
} from 'utils/analytics';

const notAvailable = 'n/a';

const PersonItem = ({ isFullList, searchHit }) => {
  const url = searchHit.getUrl();

  if (!url) {
    return null;
  }

  const name = searchHit.getPersonName();

  const {
    imageUrl,
    email,
    title = notAvailable,
    country = notAvailable,
    divisionName = notAvailable,
    mobilePhoneNo,
  } = searchHit.getAttributes();

  return (
    <div className="person-item">
      <div className="person-item__container">
        <Link
          className="person-item__link"
          to={url}
          onClick={() => {
            GAonClickAvenueOverlaySearchResult(name);
            GAonSearchResultClick('Person');
            isFullList && saveScrollPosition('find');
          }}
        >
          <UserImage
            className="person-item__user-image m-r-3"
            name={name}
            src={imageUrl}
            size="large"
          />

          <div>
            <span className="person-item__name">{name}</span>
            <span className="person-item__title">{title}</span>
            <span className="person-item__division">
              {`${country} | ${divisionName}`}
            </span>
          </div>
        </Link>

        <div className="person-item__contact-teams">
          {email ? (
            <a
              href={`msteams://teams.microsoft.com/l/chat/0/0?users=${email}`}
              data-label="n/a"
              data-value={email}
              className="person-item__teams-link"
            >
              <TeamsIcon />
            </a>
          ) : (
            <span>{notAvailable}</span>
          )}
        </div>
      </div>

      <div>
        <div className="person-item__contact-wrapper">
          {email ? (
            <a
              href={`mailto:${email}`}
              data-category="Contact"
              data-action="mailto"
              data-label="n/a"
              data-value={email}
              onClick={GAonContactClick}
              className="person-item__email"
            >
              {email}
            </a>
          ) : (
            <span className="person-item__email">{notAvailable}</span>
          )}
          {mobilePhoneNo ? (
            <a
              href={`tel:${mobilePhoneNo}`}
              data-category="Contact"
              data-action="tel"
              data-label="n/a"
              onClick={GAonContactClick}
              className="person-item__phone"
            >
              {mobilePhoneNo}
            </a>
          ) : (
            <span className="person-item__phone">{notAvailable}</span>
          )}
        </div>
      </div>
    </div>
  );
};

PersonItem.propTypes = {
  searchHit: PropTypes.instanceOf(SearchHit).isRequired,
  isFullList: PropTypes.bool,
};

export default PersonItem;
