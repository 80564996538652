import _Breadcrumbs from 'components/Breadcrumbs';
import { Column, Container } from 'components/Grid';

import { Consumer } from '../';

const Breadcrumbs = ({ subject }) => (
  <Consumer>
    {({ article: { title } }) => (
      <Container>
        <Column>
          <_Breadcrumbs
            path="/feed"
            includeHome={true}
            items={[{ title: 'Feed' }, { title: title || `New ${subject}` }]}
          />
        </Column>
      </Container>
    )}
  </Consumer>
);

export default Breadcrumbs;
