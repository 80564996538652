import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getPressArticle } from 'api/news';
import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Error from 'components/Error';
import Fetcher from 'components/Fetcher';
import { Column, Container } from 'components/Grid';
import Img from 'components/Image';
import PageTitle from 'components/PageTitle';
import Parser from 'components/Parser';
import Title from 'components/Title';
import { PageViews } from 'componentsNew';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import { GAitemNotFound } from 'utils/analytics';
import { getDateOrDaysAgo } from 'utils/misc/time';

import ContentLoader from './ContentLoader';

const onError = (status, message) => {
  GAitemNotFound(status, message, 'press');
  return <Error message={message} status={status} redirectOn404={true} />;
};

const PressArticle = ({ match }) => (
  <Container>
    <Column>
      <Fetcher
        source={getPressArticle}
        param={match.params.id}
        onLoading={ContentLoader}
        onError={onError}
      >
        {({ response }) => (
          <>
            <Breadcrumbs
              path="/feed"
              includeHome
              items={[{ title: 'Feed' }, { title: response.title }]}
            />
            <Box className="m-t-6">
              <PageTitle titles={['Press', response.title]} />
              <Title>{response.title}</Title>
              {response.imageUrl && (
                <Img
                  src={response.imageUrl}
                  className="m-b-4"
                  alt={response.title}
                />
              )}
              <Container padding={false}>
                <Column span={2}>
                  <div className="m-b-4">
                    <Stack
                      sx={[
                        {
                          flexDirection: 'row',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        },
                      ]}
                    >
                      {response.publishedUtc && (
                        <>
                          <Typography
                            variant="body2"
                            sx={(theme) => ({
                              color: theme.colors.text.tertiary,
                              lineHeight: 1.2,
                            })}
                          >
                            {getDateOrDaysAgo(response.publishedUtc)}
                          </Typography>
                          <Divider
                            orientation="vertical"
                            sx={(theme) => ({
                              margin: `auto ${theme.spacing('xxs')}`,
                              height: theme.typography.body2.fontSize,
                              borderColor: theme.colors.border.surfacePrimary,
                              opacity: 0.7,
                            })}
                          />
                        </>
                      )}
                      <Link
                        to="/feed?type=press"
                        variant="body2"
                        component={ReactRouterLink}
                        sx={(theme) => ({
                          lineHeight: 1.2,
                          color: theme.colors.text.tertiary,
                          textDecoration: 'none',
                          '&:hover': {
                            color: theme.colors.text.tertiary,
                            textDecoration: 'underline',
                          },
                        })}
                      >
                        Press
                      </Link>
                    </Stack>
                  </div>
                  <div className="cms__article content content--press">
                    <p className="preamble">{response.preamble}</p>
                    <Parser
                      html={response.body}
                      type="Feed"
                      country={response.country}
                      division={response.division}
                    />
                  </div>
                </Column>
                <Column className="desktop-text-right">
                  <Button
                    outbound={true}
                    analyticsAction="Download as PDF"
                    analyticsLabel={response.title}
                    to={response.fileUrl}
                    className="m-t-0"
                  >
                    Download as PDF
                  </Button>
                </Column>
              </Container>
              <PageViews
                id="page-views"
                tooltip={translations.pageViewFeedToolTipText}
                sx={{ justifyContent: 'end' }}
              />
            </Box>
          </>
        )}
      </Fetcher>
    </Column>
  </Container>
);

export default PressArticle;
