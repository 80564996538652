import classNames from 'classnames';
import Button from 'components/Button';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import { ReactNode } from 'react';

interface DeleteButtonProps {
  onDeleteArticle?: () => void;
  subject?: string;
  modalChildren?: ReactNode;
  disabled?: boolean;
  isCreating?: boolean;
  onCancel?: string;
  buttonStyle?: string;
  text?: 'Unpublish' | 'Delete';
}

const yesButton = {
  color: 'black',
  title: 'Yes',
};

const noButton = {
  color: 'white',
  title: 'No',
};

const DeleteButton = ({
  onDeleteArticle,
  subject,
  modalChildren,
  disabled,
  isCreating,
  buttonStyle,
  text = 'Delete',
}: DeleteButtonProps) => {
  const deleteButtonClass = classNames('sm-m-b-2', {
    'article-button--wrapper--delete': buttonStyle,
  });
  if (!isCreating) {
    return (
      <ButtonModalWrapper
        title={`Are you sure you want to ${text.toLowerCase()} this ${subject}?`}
        modalChildren={modalChildren}
        yesButton={yesButton}
        noButton={noButton}
      >
        <Button
          className={deleteButtonClass}
          color="transparent"
          disabled={disabled}
          onClick={onDeleteArticle}
          align="right"
        >
          {text}
        </Button>
      </ButtonModalWrapper>
    );
  } else {
    return null;
  }
};

export default DeleteButton;
