import * as API from 'api/cms/topics';
import Box from 'components/Box';
import { UserConsumer } from 'components/Context/User';
import CreateOrEdit from 'components/CreateOrEditArticle';
import ArticleDivision from 'components/CreateOrEditArticle/components/ArticleDivision';
import Body from 'components/CreateOrEditArticle/components/Body';
import ContentLabels from 'components/CreateOrEditArticle/components/ContentLabels';
import Documents from 'components/CreateOrEditArticle/components/Documents';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import Pinned from 'components/CreateOrEditArticle/components/Pinned';
import Playlist from 'components/CreateOrEditArticle/components/Playlist';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import PublishDate from 'components/CreateOrEditArticle/components/PublishDate';
import PublishOptions from 'components/CreateOrEditArticle/components/PublishOptions';
import TargetingOptions from 'components/CreateOrEditArticle/components/TargetingOptions';
import Title from 'components/CreateOrEditArticle/components/Title';
import { maxLengthValidator } from 'components/CreateOrEditArticle/validators';
import { Column, Container } from 'components/Grid';
import { getChannelUrl } from 'utils/api/urls';

import ContentLoader from '../ContentLoader';

const validateDivision = ({ publishedInDivisions, publishedInGlobal }) => {
  if (publishedInDivisions?.length || publishedInGlobal) {
    return null;
  }
  return 'Division';
};

const fields = [
  { key: 'title', required: true, displayName: 'Title', maxLength: 21 },
  { key: 'preamble', fieldValidator: maxLengthValidator(300), maxLength: 300 },
  { key: 'body' },
  { key: 'sharepointFolderName', defaultValue: null },
  {
    key: 'publishedInGlobal',
    intermediate: true,
    defaultValue: false,
    getIntermediateDefault: ({ publishedInDivisions }) =>
      !publishedInDivisions || !publishedInDivisions.length,
  },
  { key: 'publishedInDivisions', validator: validateDivision },
  { key: 'publishedDate', defaultValue: new Date().toISOString() },
  { key: 'heroImage' },
  { key: 'heroImageWidth' },
  { key: 'heroVideo' },
  { key: 'pinned' },
  { key: 'videoPlaylistId' },
];

const ChannelEdit = ({ match, history, location }) => {
  const isCreating = match.params.type === 'create';
  return (
    <UserConsumer>
      {({ refreshNavigation }) => (
        <CreateOrEdit
          isCreating={isCreating}
          viewableByRole="AvenueBlogEditor"
          pageTitle="Topics"
          location={location}
          fields={fields}
          ContentLoader={ContentLoader}
          onFetch={() => API.getChannel(match.params.id)}
          onCreate={API.createChannel}
          onEdit={API.updateChannel}
          onAfterCreate={(channel) => {
            refreshNavigation();
            history.push(getChannelUrl(channel));
          }}
          onAfterDelete={() => {
            refreshNavigation();
            history.push(getChannelUrl());
          }}
          onAfterCancel={(channel) => {
            isCreating
              ? history.push(getChannelUrl())
              : history.push(`/topics/${channel.title}`);
          }}
        >
          <Container className="m-t-6 ie-flex-fix">
            <Column span={2}>
              <Box className="m-t-6 lg-m-t-0">
                <Title
                  placeholder="channelTitle (max 21 characters)"
                  hashtag={true}
                  allowedCharacters="A-Za-z0-9" // double escape for special characters
                />
                <Hero useVideo={true} useAltText={true} />
                <ContentLabels />
                <div className="max-width">
                  <Preamble placeholder="Channel preamble (max 300 characters)" />
                  <Body placeholder="Channel body" form="topic" />
                </div>
              </Box>
            </Column>
            <Column>
              <Playlist />
            </Column>
          </Container>
          <Container>
            <Column span={2}>
              <Documents />
              <TargetingOptions>
                <ArticleDivision
                  title="Select where the topic should be available"
                  multiSelect={true}
                  deSelectEnabled={true}
                />
              </TargetingOptions>
              <PublishOptions onCancel="/" subject="channel" hideDelete={true}>
                <Pinned />
                <PublishDate />
              </PublishOptions>
            </Column>
            <Column />
          </Container>
        </CreateOrEdit>
      )}
    </UserConsumer>
  );
};

export default ChannelEdit;
