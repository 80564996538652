import './SurveyPopup.scss';

import { getSurveyPopup } from 'api/cms/surveypopup';
import Button from 'components/Button';
import { ReactComponent as FaceFrownIcon } from 'components/Icon/FaceFrown.svg';
import { ReactComponent as FaceSmileIcon } from 'components/Icon/FaceSmile.svg';
import { ReactComponent as HeartIcon } from 'components/Icon/Heart.svg';
import { ReactComponent as LightBulbIcon } from 'components/Icon/LightBulb.svg';
import { FC, useEffect, useState } from 'react';
import { GAonSurveyPopupClick } from 'utils/analytics';

const LOCAL_STORAGE_KEY = 'popup_survey_latest_handled';

enum SurveyPopupOption {
  Accept = 'Yes',
  Decline = 'No',
}

interface SurveyPopupData {
  title: string;
  body: string;
  linkUrl: string;
  published: boolean;
  publishedDate: string;
  _doc: string;
}

const SurveyPopup: FC = () => {
  const [visible, setVisible] = useState(false);
  const [surveyPopup, setSurveyPopup] = useState<SurveyPopupData | null>(null);

  // Fetch survey popup content
  useEffect(() => {
    (async () => {
      const data = await getSurveyPopup();
      if (data.data.data._doc) {
        setSurveyPopup(data.data.data as SurveyPopupData);
      }
    })();
  }, []);

  // Show survey popup if it was not already handled by user
  useEffect(() => {
    if (!surveyPopup) return;
    if (localStorage.getItem(LOCAL_STORAGE_KEY) === surveyPopup._doc) return;
    setTimeout(() => setVisible(true), 3000);
  }, [surveyPopup]);

  const onSurveyPopupClick = (option: SurveyPopupOption) => {
    if (!surveyPopup) return;
    setVisible(false);
    localStorage.setItem(LOCAL_STORAGE_KEY, surveyPopup._doc);
    GAonSurveyPopupClick(option);
  };

  if (!visible || !surveyPopup) {
    return null;
  }

  return (
    <div className="survey-popup">
      <div className="survey-popup__icon-container">
        <HeartIcon />
        <FaceFrownIcon />
        <FaceSmileIcon />
        <LightBulbIcon />
      </div>
      <h2>{surveyPopup.title}</h2>
      <p>{surveyPopup.body}</p>
      <div className="survey-popup__button-container">
        <Button
          color="transparent-orient"
          width="narrow"
          onClick={() => onSurveyPopupClick(SurveyPopupOption.Decline)}
        >
          No, Thanks
        </Button>
        <Button
          color="orient"
          width="narrow"
          onClick={() => onSurveyPopupClick(SurveyPopupOption.Accept)}
          to={surveyPopup.linkUrl}
          outbound
        >
          Take the survey
        </Button>
      </div>
    </div>
  );
};

export default SurveyPopup;
