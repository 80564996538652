import './BodyBox.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import Box from 'components/Box';
import Documents from 'components/Documents';
import OnlyWithRole from 'components/OnlyWithRole';
import Parser from 'components/Parser';
import Title from 'components/Title';
import PropTypes from 'prop-types';
import { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { anchorLinkFormatter, jumpToAnchor } from 'utils/misc/jumpToAnchor';

import RelatedArticlesList from './RelatedArticles/index';

class BodyBox extends Component {
  state = {
    docCount: null,
    rerenderDocs: true,
    searchRequest: null,
    topicId: null,
  };

  bodyBoxElementRef = null;

  componentDidUpdate(prevProps) {
    const { page } = this.props;

    if (prevProps.page !== page) {
      this.setState({ rerenderDocs: false }, () => {
        this.setState({ rerenderDocs: true });
      });

      this.setRelatedTopicSearchString(page);
    }
  }

  countChangeHandler = (docs) => {
    this.setState({ docCount: docs.count });
  };

  setRelatedTopicSearchString = (page) => {
    const insightsDatabaseTopic = page.get('insightsDatabaseTopic');
    if (insightsDatabaseTopic) {
      this.setState({ topicId: insightsDatabaseTopic.value });
      this.getRelatedTopicSearchString(insightsDatabaseTopic.value);
    } else {
      this.setState({ searchRequest: null });
      this.setState({ topicId: null });
    }
  };

  getRelatedTopicSearchString = (topicId) => {
    const searchString =
      `/search?` +
      `filter[query]=` +
      `&sort=-published` +
      `&page[limit]=3` +
      `&filter[filtertypes]=[portalarticle]` +
      `&filter[portaltypes]=[innovation]` +
      `&filter[template]=insights-content` +
      `&filter[insightstype.id]=` +
      `&filter[verticalmarket.id]=` +
      `&filter[publishedyear]=` +
      `&filter[topic.id]=${topicId}` +
      `&filter[productgroup.id]=` +
      `&filter[geographicmarket.id]=`;

    this.setState({ searchRequest: searchString });
  };

  componentDidMount() {
    const { hash } = window.location;
    if (hash.length > 1 && hash.substring(1) === this.props.page.id) {
      jumpToAnchor(this.bodyBoxElementRef);
    }

    this.setRelatedTopicSearchString(this.props.page);
  }

  renderInfoHighlightBox(page) {
    return (
      <div className="body-box__highligt-box">
        <Parser html={page.get('infoHighlightBox')} type="Feed" />
      </div>
    );
  }

  render() {
    const {
      page,
      showTitleAndPreamble,
      color,
      hideDocuments,
      className,
      showEditLink,
    } = this.props;

    const { docCount, rerenderDocs, searchRequest, topicId } = this.state;
    const bodyBoxClassName = classNames('body-box', {
      [className]: className,
      [`body-box--${color}`]: typeof color !== 'undefined',
    });

    const documentWrapperClassName = classNames('body-box__documents-wrapper', {
      loaded: docCount > 0,
    });

    const sharepointFolderName = page.get('sharepointFolderName');
    //complicated check if there's totally nothing in page body so we can hide it
    const isPageEmpty =
      !showTitleAndPreamble &&
      (page.get('title') || page.get('preamble')) &&
      !(!hideDocuments && sharepointFolderName) &&
      !page.get('body') &&
      !showEditLink;

    if (isPageEmpty) {
      return <></>;
    }

    return (
      <div
        ref={(ref) => (this.bodyBoxElementRef = ref)}
        className={bodyBoxClassName}
        style={{ backgroundColor: page.get('backgroundColor') }}
      >
        {searchRequest ? (
          <div
            className="body-box__content_withrelated content"
            style={{ color: page.get('textColor') }}
          >
            {showTitleAndPreamble && (
              <h2>
                <a href={anchorLinkFormatter(window.location, page.id)}>
                  {page.get('title')}
                </a>
              </h2>
            )}
            {showTitleAndPreamble && (
              <p className="body-box__preamble">{page.get('preamble')}</p>
            )}
            <div className="body-box__content_withrelated__main-text-related-documents">
              <div className="body-box__content_withrelated__main-text-related">
                <div className="body-box__content_withrelated__main-text">
                  <Parser html={page.get('body')} type="Portal" />
                  {showEditLink && (
                    <OnlyWithRole viewableByRole="AvenuePortalEditor">
                      <Link className="body-box__link" to={page.getEditPath()}>
                        Edit
                      </Link>
                    </OnlyWithRole>
                  )}
                </div>
                {(page.get('template') === 'content' ||
                  page.get('template') === 'listing') &&
                page.get('infoHighlightBox') ? (
                  <div>
                    {this.renderInfoHighlightBox(page)}
                    <RelatedArticlesList
                      searchRequest={searchRequest}
                      topicId={topicId}
                    />
                  </div>
                ) : (
                  <RelatedArticlesList
                    searchRequest={searchRequest}
                    topicId={topicId}
                  />
                )}
              </div>
              {!hideDocuments && sharepointFolderName && (
                <div className={documentWrapperClassName}>
                  <Title size="small" border="portal-related">
                    Documents
                  </Title>
                  {rerenderDocs && (
                    <Box
                      className="m-b-6"
                      color="transparent"
                      noShadow={true}
                      padding={false}
                    >
                      <Suspense fallback={<div />}>
                        <Documents
                          folderName={sharepointFolderName}
                          edit={false}
                          onChange={this.countChangeHandler}
                        />
                      </Suspense>
                    </Box>
                  )}
                </div>
              )}
            </div>
            {showEditLink && (
              <OnlyWithRole viewableByRole="AvenuePortalEditor">
                <Link className="body-box__link" to={page.getEditPath()}>
                  Edit
                </Link>
              </OnlyWithRole>
            )}
          </div>
        ) : (
          <div
            className={
              page.get('infoHighlightBox')
                ? 'body-box__content body-box__highligt-body-without-tag-container'
                : 'body-box__content content'
            }
            style={{ color: page.get('textColor') }}
          >
            {showTitleAndPreamble && (
              <h2>
                <a href={anchorLinkFormatter(window.location, page.id)}>
                  {page.get('title')}
                </a>
              </h2>
            )}
            {showTitleAndPreamble && (
              <p className="body-box__preamble">{page.get('preamble')}</p>
            )}

            {(page.get('template') === 'content' ||
              page.get('template') === 'listing') &&
            page.get('infoHighlightBox') ? (
              <div className="body-box__highligt-body-wrapper-without-tag">
                <div>
                  <Parser html={page.get('body')} type="Portal" />
                </div>
                {this.renderInfoHighlightBox(page)}
              </div>
            ) : (
              <Parser html={page.get('body')} type="Portal" />
            )}

            {!hideDocuments && sharepointFolderName && (
              <div className={documentWrapperClassName}>
                <Title size="small" border="portal-related">
                  Documents
                </Title>
                {rerenderDocs && (
                  <Box
                    className="m-b-6"
                    color="transparent"
                    noShadow={true}
                    padding={false}
                  >
                    <Suspense fallback={<div />}>
                      <Documents
                        folderName={sharepointFolderName}
                        edit={false}
                        onChange={this.countChangeHandler}
                      />
                    </Suspense>
                  </Box>
                )}
              </div>
            )}
            {showEditLink && (
              <OnlyWithRole viewableByRole="AvenuePortalEditor">
                <Link className="body-box__link" to={page.getEditPath()}>
                  Edit
                </Link>
              </OnlyWithRole>
            )}
          </div>
        )}
      </div>
    );
  }
}

BodyBox.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
  showTitleAndPreamble: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'blue']),
  hideDocuments: PropTypes.bool,
  className: PropTypes.string,
};

BodyBox.defaultProps = {
  color: 'white',
};

export default BodyBox;
