import Stack from '@mui/material/Stack';

type FieldWrapperProps = {
  children: React.ReactNode;
};

const FieldWrapper = ({ children }: FieldWrapperProps) => {
  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        rowGap: theme.spacing('xxxs'),
      })}
    >
      {children}
    </Stack>
  );
};

export { FieldWrapper };
