import { Column, Container } from 'components/Grid';
import PageTitle from 'components/PageTitle';

import FeedContent from '../common/FeedContent';
import MyFeedHeader from './components/MyFeedHeader';

const title = ['Feed'];

const MyFeed = () => {
  return (
    <>
      <PageTitle titles={title} />
      <Container>
        <Column>
          <MyFeedHeader />
          <FeedContent />
        </Column>
      </Container>
    </>
  );
};

export default MyFeed;
