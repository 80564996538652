import './CommentContent.scss';

import Comment, { Action } from 'api/models/Comment';
import classNames from 'classnames';
import LikeButton from 'components/Button/CommentAndLike/LikeButton';
import { UserImage } from 'components/UserImage';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { formatCommentBody } from 'utils/misc/formatCommentBody';
import { getDateOrDaysAgo } from 'utils/misc/time';

import { CommentActions } from './CommentActions';

interface CommentContentProps {
  comment: Comment;
  userContext: React.Context<any>;
  onDelete: (comment: Comment) => void;
  onUnpublish: (comment: Comment) => void;
  onRepublish: (comment: Comment) => void;
  articleTitle: string;
  onReply: () => void;
  isReplyActive: boolean;
  isReplyEnabled: boolean;
}

const CommentContent = ({
  comment,
  userContext,
  onDelete,
  onUnpublish,
  onRepublish,
  articleTitle,
  onReply,
  isReplyActive,
  isReplyEnabled,
}: CommentContentProps) => {
  const { text, date, published } = comment.getAttributes();

  const enabledActions = useMemo(() => {
    const userActions = comment.getUserActions(userContext);
    if (!userActions) {
      return [];
    }
    const userActionKeys = Object.keys(userActions) as Action[];
    const enabledUserActions = userActionKeys.filter((key) =>
      Boolean(userActions[key])
    );
    return enabledUserActions;
  }, [comment, userContext]);

  if (published === false) {
    return (
      <div className="comment-content">
        <UserImage />
        <div className="comment-content__content">
          <div className="comment-content__content-top">
            <i>Unpublished by administrator</i>
          </div>
          <div className="comment-content__content-bottom">
            {enabledActions.length > 0 && (
              <>
                <CommentActions
                  comment={comment}
                  enabledActions={enabledActions}
                  onDelete={onDelete}
                  onUnpublish={onUnpublish}
                  onRepublish={onRepublish}
                />
                <div className="comment-content__divider" />
              </>
            )}
            <div className="comment-content__date">
              {getDateOrDaysAgo(date)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const user = comment.getUser();

  const { imageUrl, title } = user
    ? user.getAttributes()
    : { title: 'Former Employee', imageUrl: undefined };

  return (
    <div className="comment-content">
      <UserImage src={imageUrl} />
      <div className="comment-content__content">
        <div className="comment-content__content-top">
          {user && (
            <Link to={user.getUrl()} className="comment-content__name">
              {user.getPersonName()}
            </Link>
          )}
          {title && <div className="comment-content__title">{title}</div>}
        </div>
        <div className="comment-content__text">{formatCommentBody(text)}</div>
        <div className="comment-content__content-bottom">
          <LikeButton
            targetEntityId={comment.id}
            articleTitle={articleTitle}
            isCommentLiked={true}
          />
          <div className="comment-content__divider" />
          {isReplyEnabled && (
            <>
              <button
                onClick={onReply}
                className={classNames('comment-content__reply-button', {
                  'comment-content__reply-button-show': isReplyActive,
                })}
              >
                Reply
              </button>
              <div className="comment-content__divider" />
            </>
          )}
          {enabledActions.length > 0 && (
            <>
              <CommentActions
                comment={comment}
                enabledActions={enabledActions}
                onDelete={onDelete}
                onUnpublish={onUnpublish}
                onRepublish={onRepublish}
              />
              <div className="comment-content__divider" />
            </>
          )}
          <div className="comment-content__date">{getDateOrDaysAgo(date)}</div>
        </div>
      </div>
    </div>
  );
};

export { CommentContent };
