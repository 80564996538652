import { Block } from 'medium-draft/lib/util/constants';

export const htmlToBlock = (nodeName, node) => {
  if (nodeName === 'h1') {
    return {
      type: Block.H1,
      data: {},
    };
  } else if (nodeName === 'h2') {
    return {
      type: Block.H2,
      data: {},
    };
  } else if (
    nodeName === 'h3' &&
    !node.parentElement.className.includes('accordion')
  ) {
    return {
      type: Block.H3,
      data: {},
    };
  } else if (nodeName === 'h4') {
    return {
      type: Block.H4,
      data: {},
    };
  } else if (nodeName === 'h5') {
    return {
      type: Block.H5,
      data: {},
    };
  } else if (nodeName === 'h6') {
    return {
      type: Block.H6,
      data: {},
    };
  } else if (
    nodeName === 'p' &&
    (node.className === `md-block-${Block.CAPTION.toLowerCase()}` ||
      node.className === `md-block-${Block.BLOCKQUOTE_CAPTION.toLowerCase()}`)
  ) {
    return {
      type: Block.BLOCKQUOTE_CAPTION,
      data: {},
    };
  } else if (nodeName === 'figure') {
    const imageNode = node.querySelector('img');
    const iframe = node.querySelector('iframe');

    if (imageNode) {
      const width = Number(imageNode.getAttribute('data-original-width') || 0);
      const height = Number(
        imageNode.getAttribute('data-original-height') || 0
      );
      const altText = imageNode.getAttribute('alt');
      const orientation = width > height ? 'landscape' : 'portrait';
      const expandable = imageNode.hasAttribute('expandable');
      return {
        type: Block.IMAGE,
        data: {
          src: imageNode.src,
          width,
          height,
          altText,
          orientation,
          expandable,
        },
      };
    } else if (iframe) {
      return {
        type: Block.ATOMIC,
        data: {
          url: iframe.src,
        },
      };
    }
    return undefined;
  } else if (
    nodeName === 'div' &&
    node.className &&
    node.className.match(/^md-block-todo/)
  ) {
    const inputNode = node.querySelector('input');
    return {
      type: Block.TODO,
      data: {
        checked: inputNode && inputNode.checked,
      },
    };
  } else if (nodeName === 'hr') {
    return {
      type: Block.BREAK,
      data: {},
    };
  } else if (nodeName === 'blockquote') {
    return {
      type: Block.BLOCKQUOTE,
      data: {},
    };
  } else if (nodeName === 'p') {
    return {
      type: Block.UNSTYLED,
      data: {},
    };
  } else if (nodeName === 'iframe') {
    return {
      type: 'atomic',
      data: {},
    };
  } else if (
    nodeName === 'div' &&
    node.className &&
    node.className.match(/^accordion-container$/)
  ) {
    node.innerHTML = 'accordion';

    return {
      type: 'atomic',
    };
  }

  return undefined;
};

export const htmlToEntity = (nodeName, node, createEntity) => {
  if (nodeName === 'a') {
    return createEntity('LINK', 'MUTABLE', { url: node.href });
  }

  if (nodeName === 'iframe') {
    return createEntity('embed', 'MUTABLE', {
      url: node.src,
    });
  }

  if (
    nodeName === 'div' &&
    node.className &&
    node.className.match(/^accordion-container$/)
  ) {
    const title = node.querySelector(
      '.accordion-container-header-row__title'
    ).innerHTML;
    let titleNumber = '';

    if (node.querySelector('.accordion-container-header-row__number')) {
      titleNumber = node.querySelector(
        '.accordion-container-header-row__number'
      ).innerHTML;
    }
    const body = node.querySelector('.accordion-container__body').innerHTML;

    return createEntity('accordion', 'IMMUTABLE', {
      title: titleNumber + title,
      body: body,
    });
  }

  return undefined;
};
