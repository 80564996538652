import './RelatedArticleItem.scss';

import Img from 'components/Image';
import { Link } from 'react-router-dom';
// TODO: Should not use untyped packages in typescript files
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TextClamp from 'react-string-clamp';
import { getNormalFormattedDate } from 'utils/misc/time';

const RelatedArticleItem = ({ data }: any) => {
  const articleId = data.id.replace('cms-', '');

  return (
    <div className="related-article--item">
      <Link to={`/portals/${data.portalType}/${articleId}`}>
        <div className="related-article--item--container">
          <Img
            className="related-article--item--image"
            alt={data.imageAltText}
            src={data.imageUrl}
          />
          <div className="related-article--item--wrapper">
            <div className="related-article--item--date">
              {getNormalFormattedDate(data.published)}
            </div>
            <div className="related-article--item--title">
              <TextClamp lines={2} text={data.title ? data.title : ''} />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default RelatedArticleItem;
