import './TagSelector.scss';

import { getTags } from 'api/cms/tags';
import Fetcher from 'components/Fetcher';
import Select from 'components/Select';
import PropTypes from 'prop-types';
import { Component } from 'react';

class TagSelector extends Component {
  constructor(props) {
    super();
    const { defaultSelected } = props;
    this.state = {
      selected: Array.isArray(defaultSelected)
        ? defaultSelected.map((r) => ({
            value: r,
            name: r,
          }))
        : defaultSelected
        ? [{ value: defaultSelected, name: defaultSelected }]
        : [],
    };
  }

  getOnChangeHandler = (multiple) => (selected) => {
    this.setState({ selected });
    if (multiple) {
      this.props.onChange(selected.map((t) => t.name));
    } else {
      this.props.onChange(selected.name);
    }
  };

  render() {
    const { multiple, deSelectEnabled } = this.props;
    const { selected } = this.state;
    return (
      <div className="tag-selector">
        <Fetcher source={getTags}>
          {({ response }) => (
            <Select
              data={response.map(({ title }) => ({
                value: title,
                name: title,
              }))}
              placeholder="Select tag"
              selected={selected}
              onChange={this.getOnChangeHandler(multiple)}
              multiple={multiple}
              deSelectEnabled={deSelectEnabled}
            />
          )}
        </Fetcher>
      </div>
    );
  }
}

TagSelector.propTypes = {
  defaultSelected: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  deSelectEnabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default TagSelector;
