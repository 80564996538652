import './Profile.scss';

import {
  getDepartments,
  getDivision,
  updateProfileSettings,
} from 'api/organization';
import Button from 'components/Button';
import withConsumer from 'components/Context/withConsumer';
import Flex from 'components/Flex';
import { Column, Container } from 'components/Grid';
import Modal from 'components/Modal';
import Resize from 'components/Resize';
import Select from 'components/Select';
import SiteSelector from 'components/SiteSelector';
import UserImage from 'components/UserImage';
import { PROFILE_GUID } from 'constants/consents';
import { SnackbarConsumer } from 'contextNew/Snackbar';
import { Component } from 'react';
import { loadData } from 'utils/api/handleAPIData';

import Loader from './ContentLoader';

class ProfileModal extends Component {
  state = {
    isLoading: false,
    selected: {},
    missingFields: [],
    countries: [],
    departments: [],
  };

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ isLoading: true });
    await this.getCountries();
    await this.getDepartments();
    await this.selectSite();
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onDropDownChange = (selectedItem) => {
    const { selected, missingFields } = this.state;
    const { name, value } = selectedItem;
    selected[`selected${selectedItem.type}`] = { name, value };

    const updatedMissingFields = missingFields.filter(
      (item) => item !== selectedItem.type
    );
    if (this._isMounted) {
      this.setState({
        selected,
        missingFields: updatedMissingFields,
      });
    }
  };

  onChangeSite = (site) => {
    const { selected, missingFields } = this.state;
    selected.selectedSite = site;
    const updatedMissingFields = missingFields.filter(
      (item) => item !== 'Site'
    );
    if (this._isMounted) {
      this.setState({
        selected,
        missingFields: updatedMissingFields,
      });
    }
  };

  onSubmit = async () => {
    const { userId, updateUser, snackbarContext, fetchUser } = this.props;
    const requestPayload = this.prepareUpdatePayload();
    if (this._isMounted && this.validateSelection()) {
      try {
        await updateProfileSettings(userId, requestPayload);
        updateUser(({ consents }) => ({
          consents: consents
            ? consents.concat({ id: PROFILE_GUID })
            : [{ id: PROFILE_GUID }],
          profileUpdated: true,
        }));
        fetchUser();
        snackbarContext.showSnackbar({
          text: 'Profile changes has been updated.',
          type: 'success',
        });
      } catch (e) {
        this.showError('An error occured updating profile information.');
      }
    }
  };

  showError = (errorMessage) => {
    const { snackbarContext } = this.props;
    snackbarContext.showSnackbar({ text: errorMessage, type: 'error' });
  };

  getCountries = async () => {
    if (this._isMounted) {
      const { divisionId, countryId } = this.props;
      const { selected } = this.state;
      try {
        const { response } = await loadData({
          apiCall: getDivision,
          param: divisionId,
        }).promise;
        const countries = response.childCountries.map((c) => ({
          name: c.name,
          value: c.id,
        }));
        const selectedCountry = countries.find(
          (item) => item.value === countryId
        );
        selected.selectedCountry = selectedCountry;
        this.setState({ countries, selected });
      } catch (e) {
        this.showError('Error occured while populating countries.');
      }
    }
  };

  getDepartments = async () => {
    if (this._isMounted) {
      try {
        const { departmentId } = this.props;
        const { selected } = this.state;
        const { response } = await loadData({
          apiCall: getDepartments,
        }).promise;
        const departments = response.map((c) => ({
          name: c.name,
          value: c.id,
          type: 'department',
        }));
        const selectedDepartment = departments.find(
          (item) => item.value === departmentId
        );
        selected.selectedDepartment = selectedDepartment;
        this.setState({ departments, selected });
      } catch (e) {
        this.showError('Error occured while populating departments.');
      }
    }
  };

  selectSite = () => {
    const { siteId, siteName } = this.props;
    const { selected } = this.state;
    selected.selectedSite = { name: siteName, value: siteId };
    if (this._isMounted) {
      this.setState({ selected });
    }
  };

  validateSelection = () => {
    const fieldTextToDisplayTextMapping = {
      Country: 'Country',
      Department: 'Function',
      Site: 'Site',
    };
    const missingFields = [];
    const { selected } = this.state;

    if (!selected.selectedCountry) {
      missingFields.push('Country');
    }
    if (!selected.selectedDepartment) {
      missingFields.push('Department');
    }

    if (!selected.selectedSite) {
      missingFields.push('Site');
    } else if (!selected.selectedSite.name || !selected.selectedSite.value) {
      missingFields.push('Site');
    }

    if (missingFields.length) {
      // Mapping from department to function for error message display
      const missingFieldsDisplay = missingFields.map(
        (item) => fieldTextToDisplayTextMapping[item]
      );
      this.showError(
        `The following fields needs to be selected: ${missingFieldsDisplay.join(
          ', '
        )}`
      );
    }
    if (this._isMounted) {
      this.setState({ missingFields });
    }
    return missingFields.length === 0;
  };

  prepareUpdatePayload = () => {
    const { selected } = this.state;
    const { divisionId, divisionName } = this.props;
    const dataToUpdate = {};

    dataToUpdate.consents = {
      data: [
        {
          type: 'consents',
          id: PROFILE_GUID,
        },
      ],
    };
    dataToUpdate.division = {
      data: {
        type: 'division',
        id: divisionId,
        name: divisionName,
      },
    };
    if (selected.selectedCountry) {
      dataToUpdate.country = {
        data: {
          type: 'country',
          id: selected.selectedCountry.value,
          name: selected.selectedCountry.name,
        },
      };
    }
    if (selected.selectedDepartment) {
      dataToUpdate.department = {
        data: {
          type: 'department',
          id: selected.selectedDepartment.value,
          name: selected.selectedDepartment.name,
        },
      };
    }
    if (selected.selectedSite) {
      dataToUpdate.site = {
        data: {
          type: 'site',
          id: selected.selectedSite.value,
          name: selected.selectedSite.name,
        },
      };
    }
    return dataToUpdate;
  };

  render() {
    const {
      firstName,
      lastName,
      title,
      company,
      divisionName,
      email,
      imageUrl,
      mobilePhoneNo,
      phoneNo,
    } = this.props;

    const { countries, departments, selected, isLoading, missingFields } =
      this.state;

    return (
      <Modal size="md" blur={true} focus={false} animation="slow">
        <Modal.Header />
        <Flex type="space-between" direction="column" className="p-t-8 center">
          <div className="profile-modal">
            <h1>So it’s your first time on Avenue?</h1>
            <p className="profile-modal__preamble">
              Avenue retrieves your profile from our HR systems. Please check
              and validate your profile information here to make sure you get
              the right local information. To make further changes, please reach
              out to your local HR.
            </p>
            {isLoading ? (
              <Resize>{({ width }) => <Loader width={width} />}</Resize>
            ) : (
              <>
                <UserImage
                  className="profile-modal__user-image"
                  size="large"
                  desktopSize="x-large"
                  src={imageUrl}
                />
                <Container className="m-b-4">
                  <Column className="profile-modal__user-label">{`${firstName} ${lastName}`}</Column>
                </Container>
                <table className="profile-modal__info">
                  <tbody>
                    <tr>
                      <td>Job title</td>
                      <td>{title}</td>
                    </tr>
                    <tr>
                      <td>Company</td>
                      <td>{company}</td>
                    </tr>
                    <tr>
                      <td>E-mail</td>
                      <td>{email}</td>
                    </tr>
                    <tr>
                      <td>Mobile phone</td>
                      <td>{mobilePhoneNo}</td>
                    </tr>
                    <tr>
                      <td>Office phone</td>
                      <td>{phoneNo}</td>
                    </tr>
                    <tr>
                      <td>Division</td>
                      <td>{divisionName}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="profile-modal__settings m-b-4">
                  <tbody>
                    <tr>
                      <td>
                        <Container padding={false} className="m-t-4">
                          <Column className="sm-m-b-4">
                            <div className="profile-modal__label m-b-1">
                              Country
                            </div>
                            <div className="profile-modal__control">
                              <Select
                                showError={missingFields.includes('Country')}
                                data={countries}
                                selected={selected.selectedCountry}
                                placeholder="Select country"
                                onChange={(item) => {
                                  item.type = 'Country';
                                  this.onDropDownChange(item);
                                }}
                              />
                            </div>
                          </Column>
                        </Container>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Container padding={false} className="m-t-4">
                          <Column className="sm-m-b-4">
                            <div className="profile-modal__label m-b-1">
                              Function
                            </div>
                            <div className="profile-modal__control">
                              <Select
                                showError={missingFields.includes('Department')}
                                data={departments}
                                selected={selected.selectedDepartment}
                                placeholder="Select Function"
                                onChange={(item) => {
                                  item.type = 'Department';
                                  this.onDropDownChange(item);
                                }}
                              />
                            </div>
                          </Column>
                        </Container>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Container padding={false} className="m-t-4">
                          <Column className="sm-m-b-4">
                            <div className="profile-modal__label m-b-1">
                              Site
                            </div>
                            <div className="profile-modal__control">
                              <SiteSelector
                                selectedSites={selected.selectedSite}
                                onChange={this.onChangeSite}
                              />
                            </div>
                          </Column>
                        </Container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
          <Modal.Footer>
            <p>
              Please verify your profile information before moving on to the
              next step.
            </p>
            <Button
              size="large"
              width="block"
              className="m-t-4 m-b-4"
              disabled={isLoading}
              onClick={this.onSubmit}
            >
              Verify and continue
            </Button>
          </Modal.Footer>
        </Flex>
      </Modal>
    );
  }
}

export default withConsumer(SnackbarConsumer, ProfileModal, 'snackbarContext');
