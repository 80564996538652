import { ReactElement } from 'react';

export const formatAccordionTitle = (title: string): ReactElement => {
  const titleRegex = new RegExp(/^\d+\s/);
  const firstTwoTitleLetters = title.match(titleRegex);

  if (firstTwoTitleLetters && titleRegex.test(firstTwoTitleLetters[0])) {
    const formattedTitle = title.replace(titleRegex, '');

    return (
      <div className="accordion-container-header-row">
        <h3 className="accordion-container-header-row__number">
          {firstTwoTitleLetters.toString().trim()}
        </h3>
        <h3 className="accordion-container-header-row__title">
          {formattedTitle}
        </h3>
      </div>
    );
  } else {
    return (
      <div className="accordion-container-header-row">
        <h3 className="accordion-container-header-row__title">{title}</h3>
      </div>
    );
  }
};
