import Text from 'components/Editable/Text';

import { Consumer } from '../';

const onChange = (onChangeArticle, fieldName) => (linkUrl) => {
  onChangeArticle({ [fieldName]: linkUrl });
};

const LinkUrl = ({
  editable,
  color = 'black',
  placeholder,
  fieldName = 'linkUrl',
}) => (
  <Consumer>
    {({
      article,
      onChangeArticle,
      validationParameters: {
        [fieldName]: { maxLength },
      },
    }) => {
      if (!editable || editable(article)) {
        return (
          <Text
            wrapper={{
              element: 'h1',
              className: 'preamble',
            }}
            placeholder={placeholder}
            onBlur={onChange(onChangeArticle, fieldName)}
            content={article[fieldName]}
            maxLength={maxLength}
            color={color}
          />
        );
      }

      return <div>{article[fieldName]}</div>;
    }}
  </Consumer>
);

export default LinkUrl;
