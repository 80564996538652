import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CelebrationBannerBackground from './BannerThirtyYearCelebrationAssets/celebration_banner_background.png';

const CELEBRATION_BANNER_URL =
  'https://avenue.assaabloy.net/group-library/about-us/assa-abloy-30-years';

const BannerThirtyYearCelebration = () => {
  return (
    <Link
      href={CELEBRATION_BANNER_URL}
      sx={(theme) => ({
        '&:hover > div > img': {
          filter: 'brightness(1.05)',
        },
        '&:focus > div': {
          outline: `solid 1px ${theme.colors.icon.inversePrimary}`,
        },
      })}
    >
      <Stack
        sx={(theme) => ({
          boxShadow: theme.elevation.sm,
          backgroundColor: theme.colors.icon.brandBase,
          overflow: 'hidden',
          borderRadius: theme.border.radius.lg,
          position: 'relative',
          alignItems: 'end',
        })}
      >
        <Box
          component="img"
          sx={() => ({
            flexShrink: 0,
            minHeight: {
              xs: '70px',
              sm: '100px',
              md: '130px',
            },
            minWidth: {
              xs: '689px',
              sm: '984px',
              md: '1280px',
            },
          })}
          src={CelebrationBannerBackground}
        />
        <Stack
          sx={(theme) => ({
            position: 'absolute',
            paddingX: theme.spacing('md'),
            height: '100%',
            justifyContent: 'center',
            top: 0,
            left: 0,
          })}
        >
          <Typography
            component="h2"
            sx={(theme) => ({
              color: theme.colors.text.inversePrimary,
              fontWeight: 'bold',
              fontSize: {
                xs: theme.typography.h4.fontSize,
                sm: theme.typography.h2.fontSize,
                md: theme.typography.h1.fontSize,
              },
            })}
          >
            Happy Birthday
          </Typography>
          <Typography
            component="h2"
            sx={(theme) => ({
              color: theme.colors.text.inversePrimary,
              fontWeight: 'bold',
              fontSize: {
                xs: theme.typography.h4.fontSize,
                sm: theme.typography.h2.fontSize,
                md: theme.typography.h1.fontSize,
              },
            })}
          >
            ASSA ABLOY!
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
};

export { BannerThirtyYearCelebration };
