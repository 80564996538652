import { translations } from 'translations';

import {
  ManageAlertsTableField,
  ManageAlertsTableItem,
  SortOrder,
} from './ManageAlertsTable';

const getFormattedDate = (dateStr: string, includeTime?: boolean) => {
  const date = new Date(dateStr);
  const isValid = !Number.isNaN(date.getTime());
  if (!isValid) {
    return translations.notAvailable;
  }

  return includeTime
    ? date.toLocaleString(undefined, {
        dateStyle: 'short',
        timeStyle: 'short',
      })
    : date.toLocaleDateString();
};

const sortTableItems = (
  items: ManageAlertsTableItem[],
  sortField: ManageAlertsTableField,
  sortOrder: SortOrder
) => {
  switch (sortField) {
    case ManageAlertsTableField.Title:
      return items.sort((a, b) => {
        const _a = a.title || '';
        const _b = b.title || '';
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageAlertsTableField.StartDate:
      return items.sort((a, b) => {
        const _a = new Date(a.startDate || '');
        const _b = new Date(b.startDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });
    case ManageAlertsTableField.EndDate:
      return items.sort((a, b) => {
        const _a = new Date(a.endDate || '');
        const _b = new Date(b.endDate || '');
        if (sortOrder === SortOrder.Asc) {
          return _a < _b ? -1 : 1;
        }
        return _a < _b ? 1 : -1;
      });

    default:
      return items;
  }
};

export { getFormattedDate, sortTableItems };
