import './Select.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import Checkbox from 'components/Checkbox';
import { ReactComponent as Arrow } from 'components/Icon/Arrow.svg';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

class Select extends PureComponent {
  state = {
    open: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static Option = ({
    children,
    value,
    multiple,
    checked,
    depth,
    deSelectEnabled,
    globalSelected,
    disabled,
  }) => {
    const optionClassNames = classNames('select__option', {
      'select__option--depth1': depth === 1,
      [`select__option--global-selected`]: globalSelected,
      'select__option--disabled': disabled,
    });
    return (
      <li className={optionClassNames}>
        <Checkbox
          type={multiple ? 'checkbox' : deSelectEnabled ? 'checkbox' : 'radio'}
          value={value}
          name={multiple ? children : 'option'}
          data-name={children}
          defaultChecked={checked}
          disabled={disabled}
        >
          {children}
        </Checkbox>
      </li>
    );
  };

  onChange = (e) => {
    let selected = [];
    const {
      dataset: { name },
      value,
      checked,
    } = e.target;

    const { deSelectEnabled, disabled, multiple } = this.props;

    if (disabled) return;

    if (!multiple) {
      this.close();
      if (deSelectEnabled) {
        if (value !== '' && value !== null && checked) {
          this.props.onChange({ name, value });
        } else {
          this.props.onChange(null);
        }
      } else {
        if (value === '') {
          this.props.onChange(null);
        } else {
          this.props.onChange({ name, value });
        }
      }
    } else {
      if (checked) {
        selected = this.props.selected?.concat({ name, value });
      } else {
        selected =
          !deSelectEnabled && this.props.selected.length === 1
            ? this.props.selected
            : this.props.selected.filter((obj) => obj.value !== value);
      }
      this.props.onChange(selected);
    }
  };

  toggle = () => this.setState(({ open }) => ({ open: !open }));

  close = () => {
    // fixes issue in edge where navigating away from pages triggers close
    if (this._isMounted) {
      this.setState({ open: false });
    }
  };

  renderLabel = () => {
    const { selected, placeholder } = this.props;
    if (selected && typeof selected === 'object') {
      if (Array.isArray(selected) && selected.length) {
        return selected.map((s) => s.name).join(', ');
      } else {
        return selected.name ? selected.name : placeholder;
      }
    }

    return selected || placeholder || '';
  };

  isChecked = (option) => {
    const { selected } = this.props;
    if (selected && typeof selected === 'object') {
      if (Array.isArray(selected) && selected.length) {
        return selected.some((s) => s.value === option.value);
      } else {
        return selected.value === option.value;
      }
    } else {
      return false;
    }
  };

  render() {
    const { open } = this.state;
    const {
      data,
      label,
      disabled,
      className,
      ghost,
      color,
      multiple,
      includeNone,
      selected,
      showError,
      deSelectEnabled,
      elementId,
      globalSelected,
      divisionSelected,
    } = this.props;

    const selectClassNames = classNames('select', {
      'select--disabled': disabled,
      'select--open': open,
      'select--ghost': ghost,
      'select--error': showError,
      'select--global-selected': globalSelected,
      'select--division-selected': divisionSelected,
      [`select--${color}`]: color,
      [className]: className,
    });
    return (
      <ClickAwayListener onClickAway={this.close}>
        <div className={selectClassNames} id={elementId ? elementId : null}>
          <p className="select__title">{label}</p>
          <div className="select__inner">
            <p className="select__label" onClick={this.toggle}>
              {this.renderLabel()}
              <span>
                <Arrow />
              </span>
            </p>
            {open && !disabled && (
              <div className="select__options">
                <form onChange={this.onChange}>
                  {includeNone && (
                    <Select.Option
                      value=""
                      multiple={multiple}
                      checked={selected === null}
                      deSelectEnabled={deSelectEnabled}
                    >
                      None
                    </Select.Option>
                  )}
                  {data.map((option, index) => {
                    return (
                      <Select.Option
                        key={`option-${index}-${option.name}`}
                        value={option.value}
                        depth={option.depth}
                        multiple={multiple}
                        checked={this.isChecked(option)}
                        deSelectEnabled={deSelectEnabled}
                        globalSelected={globalSelected}
                        disabled={option.disabled}
                      >
                        {option.name}
                      </Select.Option>
                    );
                  })}
                </form>
              </div>
            )}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

Select.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  ghost: PropTypes.bool,
  onChange: PropTypes.func,
  includeNone: PropTypes.bool,
  showError: PropTypes.bool,
  deSelectEnabled: PropTypes.bool,
  elementId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      depth: PropTypes.number,
      disabled: PropTypes.bool,
    })
  ),
};

Select.defaultProps = {
  disabled: false,
  ghost: false,
  multiple: false,
  includeNone: false,
  depth: 0,
  deSelectEnabled: false,
  data: [],
};

export default Select;
