import ci from './ci.json';
import development from './development.json';
//portals
import pCI from './portals/ci.js';
import pDevelopment from './portals/development.js';
import pProd from './portals/prod.js';
import pProdBlue from './portals/prodBlue.js';
import pQA from './portals/qa.js';
import pUat from './portals/uat.js';
import pUatBlue from './portals/uatBlue.js';
import prod from './prod.json';
import prodBlue from './prodBlue.json';
import qa from './qa.json';
import uat from './uat.json';
import uatBlue from './uatBlue.json';

const { environment } = window.avenueConfig;

if (!environment) {
  throw new Error('No environment set');
}

// replace the dw-client.local with whatever the current hostname is
// enables us to test with localhost and ip addresses
// to make this work however the given IP must be added to azures reply urls
const devstr = JSON.stringify(development);
const replacestr = devstr.replace(/dw-client.local/g, window.location.hostname);

const developmentFixed = JSON.parse(replacestr);

const getSettings = () => {
  switch (environment) {
    case 'Development':
      return { ...developmentFixed, ...pDevelopment };
    case 'CI':
      return { ...ci, ...pCI };
    case 'QA':
      return { ...qa, ...pQA };
    case 'UAT':
      return { ...uat, ...pUat };
    case 'UAT_BLUE':
      return { ...uatBlue, ...pUatBlue };
    case 'PROD':
      return { ...prod, ...pProd };
    case 'PROD_BLUE':
      return { ...prodBlue, ...pProdBlue };
    default:
      throw new Error('Unaccepted environment value.');
  }
};

export default getSettings();
