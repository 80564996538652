import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const ImageContainedSwitch = ({ fieldName = 'isImageFullyVisible' }) => (
  <Consumer>
    {({
      onToggleArticleValue,
      article: { [fieldName]: isImageFullyVisible },
    }) => (
      <>
        <Flex className="m-b-4">
          <Checkbox
            defaultChecked={isImageFullyVisible}
            id={fieldName}
            onChange={() => onToggleArticleValue(fieldName)}
          />
          <label htmlFor={fieldName} className="m-l-3">
            Fit image without cropping
          </label>
        </Flex>
      </>
    )}
  </Consumer>
);

export default ImageContainedSwitch;
