import Avatar from '@mui/material/Avatar';
import { useMemo } from 'react';

import profileAvatarPlaceholderLargeImage from './profile-avatar-placeholder-lg.png';
import profileAvatarPlaceholderMediumImage from './profile-avatar-placeholder-md.png';
import profileAvatarPlaceholderSmallImage from './profile-avatar-placeholder-sm.png';

const SIZE_TO_PX = {
  sm: '32px',
  md: '40px',
  lg: '56px',
};

type ProfileAvatarProps = {
  src?: string;
  alt?: string;
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
};

const ProfileAvatar = ({
  src,
  alt,
  size = 'sm',
  fullWidth,
}: ProfileAvatarProps) => {
  const fallbackSrc = useMemo(
    () =>
      size === 'sm'
        ? profileAvatarPlaceholderSmallImage
        : size === 'md'
        ? profileAvatarPlaceholderMediumImage
        : profileAvatarPlaceholderLargeImage,
    [size]
  );

  return (
    <Avatar
      alt={alt}
      src={src || fallbackSrc}
      sx={
        fullWidth
          ? { width: '100%', height: 'unset', aspectRatio: 1 }
          : { width: SIZE_TO_PX[size], height: SIZE_TO_PX[size] }
      }
    />
  );
};

export { ProfileAvatar };
