import isProduction from 'utils/misc/isProduction';

export const GLOBAL_WEBCAST_MOCK_URL_PRE =
  'https://assaabloy-internal.creomediamanager.com/3b9af4f6-78a8-4614-87db-215afbbcde50';

export const GLOBAL_WEBCAST_MOCK_URL_LIVE =
  'https://assaabloy-internal.creomediamanager.com/66fe4eca-5613-4935-ac2c-04c4b7987c15';

export const GLOBAL_WEBCAST_MOCK_URL_POST =
  'https://assaabloy-internal.creomediamanager.com/54ca7e41-986b-45f4-94de-827eb73fe961';

enum GlobalWebcastTestParams {
  GlobalWebcastTestEnabled = 'global_webcast_test_enabled',
  GlobalWebcastIframeUrl = 'global_webcast_iframe_url',
}

const getTestParamsFromUrl = () => {
  if (isProduction()) {
    return { enabled: false, iframeUrl: null };
  }
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const enabled =
    params.get(GlobalWebcastTestParams.GlobalWebcastTestEnabled) === 'true';

  const iframeUrl = params.get(GlobalWebcastTestParams.GlobalWebcastIframeUrl);

  const isValidIframeUrl =
    iframeUrl && new URL(iframeUrl).host.endsWith('creomediamanager.com');

  return {
    enabled,
    iframeUrl: isValidIframeUrl ? iframeUrl : null,
  };
};

export { getTestParamsFromUrl };
