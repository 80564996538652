import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { translations } from 'translations';

const READ_MORE_LINK =
  'https://avenue.assaabloy.net/group-library/human-resources/recruitment-in-assa-abloy/your-next-role-with-assa-abloy';

const SUBSCRIBE_LINK =
  'https://performancemanager.successfactors.eu/sf/careers/jobalerts?bplte_company=assaabloya&_s.crb=P1cvdVsWM%2bP%2bYxjxDNPAexNRHZjvi4FeYzroRjojpdw%3d';

type JobContentInfoBoxProps = {
  elementId: string;
};

const JobContentInfoBox = ({ elementId }: JobContentInfoBoxProps) => {
  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: 'row',
        gap: theme.spacing('xs'),
        padding: theme.spacing('xs'),
        backgroundColor: theme.colors.surface.tertiary,
        color: theme.colors.text.secondary,
      })}
    >
      <Icon type="informationCircleFilled" color="brandBase" />
      <Stack>
        <Typography variant="caption" lineHeight={1.5}>
          {translations.jobsInfo}
        </Typography>
        <Stack flexDirection="row">
          <Link
            id={`${elementId}-read-more`}
            variant="caption"
            lineHeight={1.5}
            href={READ_MORE_LINK}
          >
            {translations.learnMore}
          </Link>
          <Typography variant="caption" lineHeight={1.5}>
            &nbsp;-&nbsp;
          </Typography>
          <Link
            id={`${elementId}-subscribe`}
            variant="caption"
            lineHeight={1.5}
            href={SUBSCRIBE_LINK}
          >
            {translations.jobsSubscribe}
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { JobContentInfoBox };
