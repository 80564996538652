import { Consumer } from 'components/CreateOrEditArticle';
import BackgroundColor from 'components/CreateOrEditArticle/components/BackgroundColor';
import Body from 'components/CreateOrEditArticle/components/Body';
import DisplayAsInfoGraphic from 'components/CreateOrEditArticle/components/DisplayAsInfoGraphic';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import ImageContainedSwitch from 'components/CreateOrEditArticle/components/ImageContainedSwitch';
import LinkButtonText from 'components/CreateOrEditArticle/components/LinkButtonText';
import LinkUrl from 'components/CreateOrEditArticle/components/LinkUrl';
import ReverseBlockSwitch from 'components/CreateOrEditArticle/components/ReverseBlockSwitch';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import TextColor from 'components/CreateOrEditArticle/components/TextColor';
import Title from 'components/CreateOrEditArticle/components/Title';

const HeroBlockEditor = () => (
  <Consumer>
    {({
      validationParameters: {
        title: { maxLength },
      },
    }) => {
      const titlePlaceholder = `Title (max ${maxLength} characters)`;
      return (
        <>
          <Title placeholder={titlePlaceholder} />
          <Body placeholder="Body" />
          <Hero useVideo={true} useAltText={true} minWidth={1050} />
          <LinkUrl placeholder="Link URL" />
          <BackgroundColor placeholder="Background color (#hex)" />
          <TextColor placeholder="Text color (#hex)" />
          <ReverseBlockSwitch />
          <ImageContainedSwitch />
          <SortIndex />
          <DisplayAsInfoGraphic />
          <LinkButtonText placeholder="Link Button Text" />
        </>
      );
    }}
  </Consumer>
);

export default HeroBlockEditor;
