import { InnovationArticleResult } from 'types/Portal';

import ResultItem from './ResultItem';

interface ResultListProps {
  articles: InnovationArticleResult[];
  portalType: string;
}

const ResultList = ({ articles, portalType }: ResultListProps) => (
  <>
    {articles.map((article, index) => (
      <ResultItem key={index} {...article} portalType={portalType} />
    ))}
  </>
);

export default ResultList;
