import Box from '@mui/material/Box';
import { TypographyWithLink } from 'componentsNew/TypographyWithLink';
import { translations } from 'translations';

const NoUpdatedLibraryPages = () => {
  return (
    <Box marginTop={1} marginBottom={1}>
      <TypographyWithLink
        to="/group-library"
        variant="body2"
        sx={(theme) => ({ paddingTop: theme.spacing('xxs') })}
      >
        {translations.updatedLibraryArticlesNotAvailable}
      </TypographyWithLink>
    </Box>
  );
};

export { NoUpdatedLibraryPages };
