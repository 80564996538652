import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import OnlyWithRole from 'components/OnlyWithRole';
import { TypographyWithLink } from 'componentsNew/TypographyWithLink';
import { translations } from 'translations';

const NoArticles = () => {
  return (
    <Box maxWidth="30rem" marginTop={2}>
      <Typography variant="body2" fontWeight="bold">
        {translations.articleFeedNoItemsTitle}
      </Typography>
      <TypographyWithLink
        to="/feed"
        variant="body2"
        sx={(theme) => ({ paddingTop: theme.spacing('xxs') })}
      >
        {translations.articleFeedNoItemsTextWithLink}
      </TypographyWithLink>
      <OnlyWithRole viewableByRole="AvenueGlobalEditor">
        <TypographyWithLink
          to="/feed/news/create"
          variant="body2"
          sx={(theme) => ({ paddingTop: theme.spacing('xxs') })}
        >
          {translations.articleFeedNoItemsTextWithLinkEditor}
        </TypographyWithLink>
      </OnlyWithRole>
    </Box>
  );
};

export { NoArticles };
