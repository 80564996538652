import './DividerBox.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import OnlyWithRole from 'components/OnlyWithRole';
import Parser from 'components/Parser';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { anchorLinkFormatter, jumpToAnchor } from 'utils/misc/jumpToAnchor';

const grayBackgroundColors = ['#7e7e7e', '#4c4c4c'];

const findBackgroundColorClass = (color) => {
  if (grayBackgroundColors.indexOf(color) >= 0) {
    return 'divider-box--gray-bg';
  }
  // This is intended as a default.
  return 'divider-box--blue-bg';
};

class DividerBox extends Component {
  bodyElementRef = null;

  componentDidMount() {
    const blockId = this.props.block.id;
    const { hash } = window.location;
    if (hash.length > 1 && hash.substring(1) === blockId) {
      jumpToAnchor(this.bodyElementRef);
    }
  }

  render() {
    const { block, color, className, showEditLink } = this.props;

    // If there's nothing in the block body it can be hidden.
    const isBlockEmpty =
      !block.get('title') && !block.get('body') && !showEditLink;

    if (isBlockEmpty) {
      return <></>;
    }

    const bodyBoxBackgroundClass = findBackgroundColorClass(
      block.get('backgroundColor')
    );

    const bodyBoxClassName = classNames('divider-box', {
      [className]: className,
      [`divider-box--${color}`]: typeof color !== 'undefined',
      [bodyBoxBackgroundClass]: bodyBoxBackgroundClass,
    });

    const showBodyText = block.get('body');

    return (
      <div
        ref={(ref) => (this.bodyElementRef = ref)}
        className={bodyBoxClassName}
        style={{ backgroundColor: block.get('backgroundColor') }}
      >
        <div
          className="divider-box__content content"
          style={{ color: block.get('textColor') }}
        >
          <h1>
            <a href={anchorLinkFormatter(window.location, block.id)}>
              {block.get('title')}
            </a>
          </h1>
          {showBodyText && (
            <div className="divider-box__main-text">
              <Parser html={block.get('body')} type="Portal" />
            </div>
          )}
          {showEditLink && (
            <OnlyWithRole viewableByRole="AvenuePortalEditor">
              <Link className="divider-box__link" to={block.getEditPath()}>
                Edit
              </Link>
            </OnlyWithRole>
          )}
        </div>
      </div>
    );
  }
}

DividerBox.propTypes = {
  block: PropTypes.instanceOf(PortalNode).isRequired,
  color: PropTypes.oneOf(['white', 'blue']),
  hideDocuments: PropTypes.bool,
  className: PropTypes.string,
};

DividerBox.defaultProps = {
  color: 'white',
};

export default DividerBox;
