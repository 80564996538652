import { Palette } from '@mui/material/styles';

import { SemanticColors } from './theme';

const createMuiColors = (muiPalette: Palette) => {
  const colors: SemanticColors = {
    text: {
      primary: muiPalette.common.black,
      secondary: muiPalette.neutral[800],
      tertiary: muiPalette.neutral[700],
      disabled: muiPalette.neutral[600],
      brand: muiPalette.primary.main,
      inversePrimary: muiPalette.common.white,
      inverseSecondary: muiPalette.neutral[300],
      inverseTertiary: muiPalette.neutral[400],
      actionDefault: muiPalette.primary[600],
      actionHover: muiPalette.secondary.main,
      actionPress: muiPalette.primary.main,
      informative: muiPalette.primary[600],
      success: muiPalette.success[700],
      warning: muiPalette.neutral[900],
      critical: muiPalette.error[700],
    },
    icon: {
      primary: muiPalette.neutral[900],
      secondary: muiPalette.neutral[600],
      inversePrimary: muiPalette.common.white,
      inverseSecondary: muiPalette.neutral[300],
      brandLighter: muiPalette.secondary[100],
      brandLight: muiPalette.secondary[300],
      brandBase: muiPalette.secondary.main,
      success: muiPalette.success.main,
      warning: muiPalette.warning.main,
      critical: muiPalette.error.main,
    },
    surface: {
      primary: muiPalette.common.white,
      secondary: muiPalette.neutral[100],
      tertiary: muiPalette.neutral[200],
      inversePrimary: muiPalette.neutral[900],
      inverseSecondary: muiPalette.neutral[800],
      overlay: '#1A1A1A80',
      highlight: muiPalette.secondary[200],
      selected: muiPalette.secondary[200],
      disabled: muiPalette.neutral[400],
      informative: muiPalette.secondary[100],
      success: muiPalette.success[100],
      warning: muiPalette.warning[100],
      warningDark: muiPalette.warning.main,
      critical: muiPalette.error[100],
      progressBase: muiPalette.secondary.main,
      progressLight: muiPalette.neutral[300],
      actionPrimaryDefault: muiPalette.primary.main,
      actionPrimaryHover: muiPalette.secondary.main,
      actionPrimaryPress: muiPalette.primary[600],
      actionSecondaryDefault: muiPalette.secondary[100],
      actionSecondaryHover: muiPalette.common.white,
      actionSecondaryPress: muiPalette.secondary[200],
      actionTertiaryDefault: muiPalette.neutral[200],
      actionTertiaryHover: muiPalette.secondary[100],
      actionTertiaryPress: muiPalette.secondary[200],
      actionDestructiveDefault: muiPalette.error[100],
      actionDestructiveHover: muiPalette.error[200],
      actionDestructivePress: muiPalette.error[300],
    },
    border: {
      input: muiPalette.neutral[500],
      disabled: muiPalette.neutral[400],
      focus: muiPalette.secondary.main,
      brandBase: muiPalette.secondary.main,
      critical: muiPalette.error.main,
      success: muiPalette.success.main,
      surfacePrimary: muiPalette.neutral[300],
      surfaceSecondary: muiPalette.neutral[400],
      surfaceInformative: muiPalette.secondary[300],
      surfaceInversePrimary: muiPalette.neutral[800],
      surfaceSuccess: muiPalette.success[300],
      surfaceWarning: muiPalette.warning[300],
      surfaceCritical: muiPalette.error[200],
      actionSecondaryDefault: muiPalette.primary.main,
      actionSecondaryHover: muiPalette.secondary.main,
      actionTertiaryDefault: muiPalette.neutral[400],
      actionTertiaryHover: muiPalette.secondary.main,
    },
  };
  return colors;
};

export { createMuiColors };
