import DataModel from './DataModel';

export const enumToLabel = {
  person: 'People',
  pressrelease: 'Press',
  newsarticle: 'News',
  storyarticle: 'Story',
  position: 'Jobs',
  article: 'Library',
  glossary: 'Glossary',
  blogpost: 'Blog',
  document: 'Document',
  innovationportal: 'Innovation Portal',
  strategyportal: 'Strategy Portal',
  codeofconductportal: 'Code of Conduct Portal',
  tool: 'Tools',
};

class Suggestion extends DataModel {
  constructor(id, attributes, includes, type) {
    super('Suggestion', id, attributes, includes);
    this.type = type.substr(0, type.indexOf('suggestions'));
    this.portalType = attributes.portalType;
  }

  getUrl() {
    return super.getUrl() || '#';
  }

  getTypeLabel() {
    if (this.portalType) {
      return enumToLabel[`${this.portalType}portal`];
    }
    return enumToLabel[this.type];
  }
}

export default Suggestion;
