import './NextArticle.scss';

import Box from 'components/Box';
import { NextArticleConsumer } from 'components/Context/NextArticle';
import ListWrapper from 'components/Feed/ListWrapper';
import Flex from 'components/Flex';
import { Column, Container } from 'components/Grid';
import { ReactComponent as ArrowRight } from 'components/Icon/ArrowRight.svg';

const NextArticle = () => {
  return (
    <NextArticleConsumer>
      {({ isNextArticleVisible, nextArticleIndex, currentFilter }) =>
        isNextArticleVisible && nextArticleIndex !== null ? (
          <Container className="m-t-9">
            <Column>
              <Flex className="m-b-5">
                <ArrowRight className="next-article__icon" />
                <span className="next-article__label p-t-1 m-l-2">
                  Next in your feed
                </span>
              </Flex>
              <Box className="p-t-3 p-b-3">
                <ListWrapper
                  limit={1}
                  offset={nextArticleIndex}
                  forNextArticle={true}
                  useLoadMore={false}
                  showNoResult={false}
                  previousFilter={currentFilter}
                  isGlobalFeed={currentFilter.isGlobalFeed}
                  hideLastDivider={true}
                />
              </Box>
            </Column>
          </Container>
        ) : null
      }
    </NextArticleConsumer>
  );
};

export default NextArticle;
