import './ToolsItem.scss';

import SearchHit from 'api/models/SearchHit';
import { ReactComponent as IconBackground } from 'components/Icon/IconBackground.svg';
import { ReactComponent as ToolsIcon } from 'components/Icon/ToolsIcon.svg';
import { saveScrollPosition } from 'components/ScrollRestoration';
import PropTypes from 'prop-types';
import {
  GAonClickAvenueOverlaySearchResult,
  GAonSearchResultClick,
} from 'utils/analytics';

const ToolsLinkWrapper = ({ children, label, url, isFullList, title }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => {
      GAonSearchResultClick(label);
      GAonClickAvenueOverlaySearchResult(title);
      isFullList && saveScrollPosition('find');
    }}
    className="tools-box__link"
  >
    {children}
  </a>
);

const ToolsItem = ({ isFullList, searchHit }) => {
  const url = searchHit.getUrl();
  if (!url) {
    return null;
  }

  const { title } = searchHit.getAttributes();
  const label = searchHit.getTypeLabel();
  return (
    <div className="tools-box">
      <div className="tools-box__section">
        <div className="tools-box__outer">
          <IconBackground className="tools-box__icon-background" />
          <ToolsIcon className="tools-box__icon" />
        </div>
        <h2 className="tools-box__header">
          <ToolsLinkWrapper
            label={label}
            url={url}
            isFullList={isFullList}
            title={title}
          >
            <span className="tools-box__header-text">{title}</span>
          </ToolsLinkWrapper>
        </h2>
      </div>
    </div>
  );
};

ToolsItem.propTypes = {
  searchHit: PropTypes.instanceOf(SearchHit).isRequired,
};

export default ToolsItem;
