const withConsumer = (Consumer, WrappedComponent, targetProp) => {
  const WrappedComponentWithConsumer = (inputProps) => {
    if (targetProp) {
      return (
        <Consumer>
          {(props) => {
            const newProps = { ...inputProps, [targetProp]: props };

            return <WrappedComponent {...newProps} />;
          }}
        </Consumer>
      );
    }
    return (
      <Consumer>
        {(props) => <WrappedComponent {...inputProps} {...props} />}
      </Consumer>
    );
  };
  return WrappedComponentWithConsumer;
};

export default withConsumer;
