import 'components/JobsList/JobsList.scss';

import Box from 'components/Box';
import Button from 'components/Button';
import Center from 'components/Center';
import Item from 'components/JobsList/Item';
import { LoadingList, onError } from 'components/JobsList/JobsListStates';
import ScrollRestoration from 'components/ScrollRestoration';
import PropTypes from 'prop-types';
import { GAonShowMoreClick } from 'utils/analytics';
import animateScroll from 'utils/misc/animateScroll';

const JobsList = ({
  availableJobs,
  isLoading,
  limit,
  isLoadingMore,
  loadMoreCount,
  showLoadMore,
  error,
  errorStatus,
  errorMessage,
  onLoadMore,
}) => {
  if (error) {
    return onError(errorStatus, errorMessage);
  }

  // Set contentloader count to same number of jobs, to prevent page jumping. Or else load default limit
  const loaderLimit = availableJobs.length > 0 ? availableJobs.length : limit;

  if (isLoading) {
    return (
      <Box className="p-t-0 p-b-0">
        <ul className="jobslist">
          <LoadingList limit={loaderLimit} />
        </ul>
      </Box>
    );
  }

  if (availableJobs.length === 0) {
    return (
      <Box className="p-t-0 p-b-0">
        <p className="m-b-0">
          There are no available jobs with this combination of filters.
        </p>
      </Box>
    );
  }

  return (
    <>
      <Box className="p-t-0 p-b-0">
        <ul className="jobslist">
          {availableJobs.map((job, i) => (
            <Item key={i} {...job} />
          ))}
          {isLoadingMore ? (
            <LoadingList limit={loadMoreCount} />
          ) : (
            <ScrollRestoration storageKey="jobs" />
          )}
        </ul>
      </Box>

      {showLoadMore && (
        <Center className="m-t-4 m-b-4">
          <Button
            onClick={() => {
              onLoadMore();
              GAonShowMoreClick('Jobs');
              animateScroll(220, 50);
            }}
            disabled={availableJobs.length === 0}
          >
            Load more
          </Button>
        </Center>
      )}
    </>
  );
};

JobsList.propTypes = {
  availableJobs: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  isLoadingMore: PropTypes.bool,
  loadMoreCount: PropTypes.number,
  showLoadMore: PropTypes.bool,
  error: PropTypes.bool,
  errorStatus: PropTypes.number,
  errorMessage: PropTypes.string,
  onLoadMore: PropTypes.func.isRequired,
};

export default JobsList;
