import { indexDocuments } from 'api/cms/admin';
import Box from 'components/Box';
import Button from 'components/Button';
import withConsumer from 'components/Context/withConsumer';
import Flex from 'components/Flex';
import Title from 'components/Title';
import { SnackbarConsumer } from 'contextNew/Snackbar';
import { Component } from 'react';

import Loader from './ContentLoader';
class DocumentsIndex extends Component {
  state = { isLoading: false };

  onSubmit = () => {
    this.setState({ isLoading: true });
    const { snackbarContext } = this.props;

    snackbarContext.showSnackbar({
      text: 'Indexing documents. Please wait...',
    });

    indexDocuments()
      .then(
        ({
          data: {
            data,
            data: {
              attributes: { message },
            },
          },
        }) => {
          this.setState({ isLoading: false });
          snackbarContext.showSnackbar({
            text: message || data,
            type: 'success',
          });
        }
      )
      .catch((err) => {
        this.setState({ isLoading: false });
        snackbarContext.showSnackbar({ text: err.message, type: 'error' });
      });
  };
  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <Box className="p-b-0 b-b-2">
        <Flex type="space-between m-b-6">
          <Title tag="h2" className="p-0">
            Index all attached documents to Search
          </Title>
          <div>
            <Flex type="flex-end" align="end">
              <div>
                <Button
                  disabled={false}
                  onClick={this.onSubmit}
                  color="blue"
                  width="wide"
                  size="large"
                  align="right"
                >
                  (Re)Index Documents
                </Button>
              </div>
            </Flex>
          </div>
        </Flex>
      </Box>
    );
  }
}

export default withConsumer(
  SnackbarConsumer,
  DocumentsIndex,
  'snackbarContext'
);
