import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { useGlobalWebcast } from 'components/Context/GlobalWebcast';

export const GLOBAL_WEBCAST_IFRAME_TRANSITION = 'height 0.5s ease-in';

const GlobalWebcast = () => {
  const theme = useTheme();

  const {
    isGlobalWebcastActive,
    iframeUrl,
    iframeHeight,
    iframeWrapperHeight,
  } = useGlobalWebcast();

  if (!isGlobalWebcastActive || !iframeUrl) {
    return null;
  }

  return (
    <Box
      sx={() => ({
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
        maxWidth: '1024px',
        width: '100%',
        height: `${iframeWrapperHeight}px`,
        transition: GLOBAL_WEBCAST_IFRAME_TRANSITION,
        padding: '1rem',
      })}
    >
      {!iframeHeight && (
        <CircularProgress
          size={32}
          sx={{
            position: 'absolute',
            color: 'white',
            flexShrink: 0,
            margin: 'auto',
            left: 'calc(50% - 12px)',
          }}
        />
      )}
      <iframe
        title="Avenue Global Webcast"
        allowFullScreen={true}
        src={iframeUrl}
        style={{
          border: 'none',
          minWidth: '1px',
          width: '100%',
          backgroundColor: theme.colors.surface.actionPrimaryDefault,
          boxShadow: `0 0 16px 20px ${theme.colors.surface.actionPrimaryDefault}`,
          height: iframeHeight ? `${iframeHeight + 30}px` : '0px',
          transition: GLOBAL_WEBCAST_IFRAME_TRANSITION,
        }}
      />
    </Box>
  );
};

export { GlobalWebcast };
