import { getPositions, headers } from 'api/jobs';
import { UserConsumer } from 'components/Context/User';
import Fetcher from 'components/Fetcher';
import CareerItem from 'components/JobsList/careerItem/creerItem';
import RecommendedListItem from 'components/JobsList/RecommendedItem';
import RecommendedNojobs from 'components/JobsList/RecommendedJobs/recommendedNoJobs/recommendedNoJobs';
import PropTypes from 'prop-types';
import { GAonRecommendedJobsListItemClick } from 'utils/analytics';
import makeQueryString from 'utils/misc/makeQueryString';

const injectDepartmentCountry = (departmentName, countryName, limit) =>
  makeQueryString({
    filter: {
      categoryname: departmentName,
      locationname: countryName,
    },
    page: {
      limit,
    },
  });

const RecommendedJobs = ({ limit = 3 }) => (
  <UserConsumer>
    {({ departmentName, originalCountryName, isLoading }) =>
      isLoading ? null : !departmentName ? (
        <ul className="jobslist joblist--recommended no-list-style">
          <RecommendedNojobs />
          <CareerItem />
        </ul>
      ) : departmentName || originalCountryName ? (
        <Fetcher
          source={getPositions}
          headers={headers}
          param={injectDepartmentCountry(
            departmentName,
            originalCountryName,
            limit
          )}
        >
          {({ response }) => (
            <ul className="jobslist joblist--recommended no-list-style">
              {response.length > 0 &&
                response.map((job, i) => (
                  <RecommendedListItem
                    key={i}
                    {...job}
                    trackingEvent={GAonRecommendedJobsListItemClick}
                    isRecommendedJob={true}
                  />
                ))}
              <CareerItem />
            </ul>
          )}
        </Fetcher>
      ) : null
    }
  </UserConsumer>
);

RecommendedJobs.propTypes = {
  limit: PropTypes.number,
};

export default RecommendedJobs;
