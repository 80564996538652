import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ProfileInformationSkeleton = () => (
  <Stack
    sx={(theme) => ({
      rowGap: theme.spacing('sm'),
      padding: theme.spacing('md'),
    })}
  >
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        maxWidth: '30rem',
        height: '1.5rem',
      }}
    />
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        maxWidth: '30rem',
        height: '1.5rem',
      }}
    />
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        maxWidth: '30rem',
        height: '1.5rem',
      }}
    />
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        maxWidth: '30rem',
        height: '1.5rem',
      }}
    />
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        maxWidth: '30rem',
        height: '1.5rem',
      }}
    />
  </Stack>
);

export { ProfileInformationSkeleton };
