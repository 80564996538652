import Box from 'components/Box';
import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Content = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={570}
    style={{ height: '570px' }}
    preserveAspectRatio="none"
  >
    <rect x={0} y={6} width={getRandomNumberBetween(350, 500)} height={36} />

    <rect x={0} y={72} width={52} height={28} rx={4} ry={4} />
    <rect x={width - 60} y={80} width={60} height={12} />

    <rect x={0} y={140} width={getRandomNumberBetween(550, 670)} height={18} />
    <rect x={0} y={170} width={getRandomNumberBetween(550, 670)} height={18} />
    <rect x={0} y={200} width={getRandomNumberBetween(400, 670)} height={18} />

    <rect x={0} y={240} width={getRandomNumberBetween(500, 670)} height={12} />
    <rect x={0} y={260} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={280} width={getRandomNumberBetween(400, 670)} height={12} />

    <rect x={0} y={320} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={340} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={360} width={getRandomNumberBetween(400, 670)} height={12} />

    <rect x={0} y={400} width={getRandomNumberBetween(550, 660)} height={12} />
    <rect x={0} y={420} width={getRandomNumberBetween(550, 660)} height={12} />
    <rect x={0} y={440} width={getRandomNumberBetween(550, 660)} height={12} />
    <rect x={0} y={460} width={getRandomNumberBetween(550, 660)} height={12} />
    <rect x={0} y={480} width={getRandomNumberBetween(400, 660)} height={12} />

    <rect x={0} y={533} width={255} height={12} />
    <rect x={width - 200} y={520} width={200} height={46} rx={4} ry={4} />
  </ContentLoader>
);

const Loader = () => {
  return (
    <Box className="m-t-2">
      <Resize>{({ width }) => <Content width={width} />}</Resize>
    </Box>
  );
};

export default Loader;
