import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ProfileMiniSkeleton = () => (
  <Stack flexDirection="row">
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        height: '2.5rem',
        width: '2.5rem',
        marginRight: '0.5rem',
        borderRadius: '50%',
      }}
    />
    <Stack flexGrow={1}>
      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={{
          maxWidth: '100%',
          height: '1.125rem',
        }}
      />
      <Skeleton
        variant="rounded"
        animation="pulse"
        sx={{
          maxWidth: '100%',
          height: '1.125rem',
          marginTop: '0.25rem',
        }}
      />
    </Stack>
  </Stack>
);

export { ProfileMiniSkeleton };
