import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

const JobOpeningsNoJobs = () => (
  <Box maxWidth="30rem" marginBottom={1} marginTop={1}>
    <Typography
      variant="body2"
      sx={(theme) => ({
        color: theme.colors.text.tertiary,
        marginBottom: theme.spacing('xxxs'),
      })}
    >
      {translations.jobOpeningsNoJobs}
    </Typography>
    <Link
      variant="body2"
      component={ReactRouterLink}
      to="/group-library/human-resources-health-safety/human-resources/recruitment-in-assa-abloy/your-next-role-with-assa-abloy"
    >
      {translations.readMore}
    </Link>
  </Box>
);

export { JobOpeningsNoJobs };
