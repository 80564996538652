import './ListItem.scss';

import classNames from 'classnames';
import { saveScrollPosition } from 'components/ScrollRestoration';
import { Link } from 'react-router-dom';
import { getDaysAgo } from 'utils/misc/time';

import ContentRows from '../ContentRows';
import ContentLoader from './ContentLoader';

const RenderDate = ({ due, created }) => {
  const daysDue = getDaysAgo(due);
  const daysCreated = getDaysAgo(created);

  const isSoonDue = daysDue >= -10 && daysDue < 0;
  const isNew = daysCreated >= 0 && daysCreated < 4;

  const dateClassnames = classNames('joblist-item__date', {
    'joblist-item__date--green': isNew,
    'joblist-item__date--blue': isSoonDue,
  });

  let text;
  if (isNew) {
    text = 'New';
  }
  if (isSoonDue) {
    const day = daysDue === -1 ? 'day' : 'days';
    text = `${Math.abs(daysDue)} ${day} left to apply`;
  }

  return text ? <span className={dateClassnames}>{text}</span> : null;
};

const ListItem = ({
  name,
  id,
  seniorityLevel,
  applicationDueUtc,
  createdUtc,
  locations,
  category,
  department,
  trackingEvent,
  hiringManager,
}) => {
  return (
    <li className="joblist-item">
      <Link
        to={`/jobs/${id}`}
        onClick={() => {
          if (trackingEvent) {
            trackingEvent(id, name);
          }
          saveScrollPosition('jobs');
        }}
        className="joblist-item__link"
      >
        <ContentRows
          name={name}
          senioritylevel={seniorityLevel}
          category={category}
          locations={locations}
          department={department}
          hiringManager={hiringManager}
        />
        {applicationDueUtc && (
          <RenderDate due={applicationDueUtc} created={createdUtc} />
        )}
      </Link>
    </li>
  );
};

export default ListItem;
export { ContentLoader };
