import '../../../Feed.scss';

import Box from 'components/Box';
import { FeedConsumer } from 'components/Context/Feed';
import Filter from 'components/Feed/Filter';
import Flex from 'components/Flex';
import Resize from 'components/Resize';
import { Desktop } from 'components/Responsive';

import FeedLanguageSelector from '../../common/FeedLanguageSelector';

const filterButtonClasses = (disabled, count) => {
  if (!disabled || count > 0) {
    return 'filter-button filter-button--active';
  } else {
    return 'filter-button';
  }
};

const MyFeedHeader = () => {
  return (
    <FeedConsumer>
      {({ disableReset, resetFilters, filterCount }) => (
        <Desktop>
          <Box className="p-t-4 p-r-6 p-b-4 p-l-6 m-b-3">
            <Flex type="space-between">
              <p className="filter-text">Filter your news feed.</p>
              <p
                onClick={resetFilters}
                className={filterButtonClasses(disableReset, filterCount)}
              >
                Clear Filters {filterCount > 0 && `(${filterCount})`}
              </p>
            </Flex>
            <Filter />
          </Box>
          <Resize>
            {({ width }) => {
              return (
                width > 800 && (
                  <FeedLanguageSelector languageSelectorColor="white" />
                )
              );
            }}
          </Resize>
        </Desktop>
      )}
    </FeedConsumer>
  );
};

export default MyFeedHeader;
