import _ContentLabels from 'components/ContentLabels';
import Flex from 'components/Flex';
import { History } from 'history';
import { createLabels } from 'utils/misc/createLabels';

import { Consumer } from '..';

interface ContentLabelProps {
  isBlog?: boolean;
  isLibrary?: boolean;
  departments?: object[] | undefined;
  history?: History;
}
interface Department {
  [path: string]: any;
}

const createLabelObject = (location: any) => {
  const labelsObject: any = {};

  if (location.length > 1) {
    let counter = 0;

    location.forEach((x: any) => {
      const count = x.path.match(/\//g).length;

      if (count > counter) {
        labelsObject['id'] = x.id;
        labelsObject['name'] = x.title;
      }

      counter = count;
    });
    return labelsObject;
  } else {
    labelsObject['id'] = location[0].id;
    labelsObject['name'] = location[0].title;
    return labelsObject;
  }
};

const ContentLabels = ({
  isBlog,
  isLibrary,
  departments,
  history,
}: ContentLabelProps) => (
  <Consumer>
    {({
      article: { country, divisions },
      updatedArticle: { publishedInDivisions, publishedInCountries },
    }) => {
      const getLabels = () => {
        if (isLibrary) {
          const isCreating = history?.location.pathname.includes('create');

          if (!country && !divisions && isCreating) {
            const filteredOnLocation = departments?.filter(
              (department: Department) => {
                return history?.location.pathname.includes(department['path']);
              }
            );

            const labelObject = createLabelObject(filteredOnLocation);

            return createLabels(labelObject);
          }
          return createLabels(country, divisions);
        } else if (isBlog) {
          return [{ name: 'Blog', linkType: 'blog' }];
        } else {
          // Could be multiple countries (ex: news)
          return createLabels(publishedInCountries, publishedInDivisions);
        }
      };

      return (
        <Flex type="space-between" className="m-b-4">
          <div>
            <_ContentLabels labels={getLabels()} />
          </div>
        </Flex>
      );
    }}
  </Consumer>
);

export default ContentLabels;
