import Text from 'components/Editable/Text';

import { Consumer } from '..';

const onChange = (onChangeArticle, fieldName) => (market) => {
  onChangeArticle({ [fieldName]: market });
};

const Market = ({ placeholder, fieldName = 'market' }) => {
  return (
    <Consumer>
      {({
        article: { [fieldName]: market },
        validationParameters: {
          [fieldName]: { maxLength },
        },
        validators: {
          [fieldName]: { fieldValidator },
        },
        onChangeArticle,
      }) => (
        <Text
          wrapper={{
            element: 'div',
            className: 'market',
          }}
          truncateAtMaxLength={false}
          validators={[fieldValidator]}
          content={market}
          onBlur={onChange(onChangeArticle, fieldName)}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      )}
    </Consumer>
  );
};

export default Market;
