import List from '@mui/material/List';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { JobListingItem } from 'api/jobs/types';
import { useMemo } from 'react';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

import { JobListError } from './JobListError';
import { JobListItem } from './JobListItem';
import { JobListSkeleton } from './JobListSkeleton';

const elementId = 'job-list';

type JobListProps = {
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  isError?: boolean;
  items: JobListingItem[];
  total: number;
  selectedItemId?: number;
  pagination: {
    page: number;
    rowsPerPage: number;
  };
  onItemClick: (id: number) => void;
  onPaginationChange: (newPagination: {
    page: number;
    rowsPerPage: number;
  }) => void;
};

const JobList = ({
  sx,
  isLoading,
  isError,
  items,
  total,
  selectedItemId,
  pagination,
  onItemClick,
  onPaginationChange,
}: JobListProps) => {
  const paginationPageCount = useMemo(() => {
    return total % pagination.rowsPerPage > 0
      ? Math.trunc(total / pagination.rowsPerPage) + 1
      : total / pagination.rowsPerPage;
  }, [pagination.rowsPerPage, total]);

  const elements = useMemo(() => {
    if (isError) {
      return <JobListError />;
    }
    if (isLoading) {
      return <JobListSkeleton />;
    }

    return (
      <>
        <Stack flexDirection="row" alignItems="center" height="2rem">
          <Typography
            variant="body2"
            sx={(theme) => ({
              fontWeight: 'bold',
              color: theme.colors.text.tertiary,
            })}
          >
            {textUtils.replaceTranslationAliases(
              total === 1
                ? translations.jobsTotalOne
                : translations.jobsTotalMany,
              {
                count: total,
              }
            )}
          </Typography>
        </Stack>
        <List
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing('xxs'),
          })}
        >
          {items.map((item, index) => (
            <JobListItem
              key={`${elementId}-item-${index}`}
              elementId={`${elementId}-item-${index}`}
              isSelected={item.id === selectedItemId}
              item={item}
              onClick={() => onItemClick(item.id)}
            />
          ))}
        </List>
      </>
    );
  }, [selectedItemId, isError, isLoading, items, onItemClick, total]);

  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({ width: '100%', gap: theme.spacing('xxs') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {elements}
      {total > pagination.rowsPerPage && (
        <Pagination
          variant="outlined"
          sx={(theme) => ({ margin: `${theme.spacing('md')} auto` })}
          count={paginationPageCount}
          page={pagination.page}
          disabled={isLoading}
          siblingCount={0}
          hidePrevButton={pagination.page === 1}
          hideNextButton={pagination.page === paginationPageCount}
          onChange={(_e, page) =>
            onPaginationChange({ page, rowsPerPage: pagination.rowsPerPage })
          }
        />
      )}
    </Stack>
  );
};

export { JobList };
