import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as RobotIcon } from 'componentsNew/Icon/featured/Robot.svg';
import { useMemo, useRef } from 'react';

import { AIChatActionCopyResult } from './AIChatActionCopyResult';
import { AIChatActionDislikeDisclaimer } from './AIChatActionDislikeDisclaimer';
import { AIChatActionDislikeResult } from './AIChatActionDislikeResult';
import { AIChatActionLikeResult } from './AIChatActionLikeResult';
import { AIChatLoadingDots } from './AIChatLoadingDots';
import { ChatItemResult } from './AIChatSheet';
import { AIChatSourceList } from './AIChatSourceList';

type AIChatBubbleResultProps = {
  id: string;
  chatItem?: ChatItemResult;
  loading?: boolean;
  onDisliked?: () => void;
  onLiked?: () => void;
};

const AIChatBubbleResult = ({
  id,
  chatItem,
  loading,
  onDisliked,
  onLiked,
}: AIChatBubbleResultProps) => {
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const bubbleContent = useMemo(() => {
    if (loading) {
      return <AIChatLoadingDots />;
    }
    if (!chatItem) {
      return null;
    }
    return (
      <>
        <Stack sx={(theme) => ({ gap: theme.spacing('xs') })}>
          <Typography
            dangerouslySetInnerHTML={{ __html: chatItem.result.text }}
          />
          {chatItem.result.sources && (
            <AIChatSourceList
              id={`${id}-source-list`}
              sources={chatItem.result.sources}
            />
          )}
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              borderRadius: theme.border.radius.md,
              backgroundColor: `${theme.colors.surface.secondary}B3`,
              padding: theme.spacing('xxxxs'),
              gap: theme.spacing('xxxxs'),
            })}
          >
            <AIChatActionCopyResult
              id={`${id}-copy`}
              result={chatItem.result}
            />
            <AIChatActionDislikeResult
              id={`${id}-dislike`}
              chatItem={chatItem}
              onDisliked={() => {
                setTimeout(() => {
                  if (bottomRef.current) {
                    bottomRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'nearest',
                    });
                  }
                }, 500);
                onDisliked && onDisliked();
              }}
            />
            <AIChatActionLikeResult
              id={`${id}-like`}
              chatItem={chatItem}
              onLiked={onLiked}
            />
          </Stack>
        </Stack>
        <Collapse in={chatItem?.isDisliked}>
          <AIChatActionDislikeDisclaimer
            sx={(theme) => ({ marginTop: theme.spacing('xs') })}
          />
        </Collapse>
      </>
    );
  }, [id, loading, chatItem, onDisliked, onLiked]);

  return (
    <Stack>
      <Stack
        id={id}
        sx={(theme) => ({
          flexDirection: 'row',
          gap: theme.spacing('xs'),
          '> svg': { flexShrink: 0 },
        })}
      >
        <RobotIcon height={32} width={32} />
        <Stack
          sx={(theme) => ({
            padding: loading ? theme.spacing('xxs') : theme.spacing('sm'),
            boxShadow: theme.elevation.sm,
            borderRadius: theme.border.radius.lg,
            border: `1px solid ${theme.colors.border.surfaceInformative}`,
            backgroundColor: theme.colors.surface.informative,
            typography: theme.typography.body1,
            wordBreak: 'break-word',
          })}
        >
          {bubbleContent}
        </Stack>
      </Stack>
      <Box ref={bottomRef} />
    </Stack>
  );
};

export { AIChatBubbleResult };
