import Stack from '@mui/material/Stack';
import Box from 'components/Box';
import { AlertConsumer } from 'components/Context/Alert';
import AlertProvider from 'components/Context/Alert';
import { UserConsumer } from 'components/Context/User';
import RichText from 'components/Editable/RichText';
import Text from 'components/Editable/Text';
import { Column, Container } from 'components/Grid';
import PageTitle from 'components/PageTitle';
import PublishSelector from 'components/PublishSelector';
import { DatePicker } from 'componentsNew';
import { FormFieldWrapper } from 'layoutNew';
import { Component } from 'react';

import FormButtons from './copmponents/FormButtons';
import Loader from './copmponents/Loader';

class AlertEdit extends Component {
  onChangeText = (onChangeArticle) => (preamble) => {
    onChangeArticle({ preamble });
  };

  render() {
    return (
      <UserConsumer>
        {({ divisionId, divisionName, countryId, countryName }) => (
          <AlertProvider
            divisionId={divisionId}
            divisionName={divisionName}
            countryId={countryId}
            countryName={countryName}
          >
            <PageTitle titles={['Alert | Create']} />
            <Container>
              <Column>
                <Box>
                  <AlertConsumer>
                    {({
                      isCreating,
                      onChangeStartDate,
                      onChangeEndDate,
                      startDate,
                      endDate,
                      saveData,
                      onChangeArticle,
                      onChange,
                      changeTextValue,
                      getDefaultValue,
                      DEFAULT_DIVISION_NAME,
                      onCancel,
                      changeBodyTextValue,
                    }) => {
                      const startDateAsDate = Boolean(startDate)
                        ? new Date(startDate)
                        : null;

                      const endDateAsDate = Boolean(endDate)
                        ? new Date(endDate)
                        : null;

                      if (isCreating) {
                        return <Loader />;
                      }
                      return (
                        <>
                          <div className="alert-edit">
                            <div>
                              <Text
                                wrapper={{
                                  element: 'div',
                                  className: 'preamble',
                                }}
                                onBlur={onChange(onChangeArticle)}
                                placeholder="Alert Title"
                                maxLength={300}
                                onChange={changeTextValue}
                              />
                              <hr className="m-t-6 m-b-6" />

                              <RichText
                                wrapper={{
                                  element: 'div',
                                  className: 'body',
                                }}
                                onBlur={onChange(onChangeArticle)}
                                placeholder="Alert Body (Maximum 300 Characters)"
                                changeBodyTextValue={changeBodyTextValue}
                                form="alert"
                              />
                              <hr className="m-t-6 m-b-6" />
                            </div>

                            <div>
                              <p>Select where the alert should be published</p>
                              <PublishSelector
                                publishWithDepartment={false}
                                publishWithSite={false}
                                publishWithCompany={false}
                                onChange={onChange(onChangeArticle)}
                                defaultDivisionName={DEFAULT_DIVISION_NAME}
                                defaultValue={getDefaultValue()}
                              />
                              <hr className="m-t-6 m-b-6" />
                            </div>
                            <Stack
                              sx={(theme) => ({
                                gap: theme.spacing('sm'),
                                flexDirection: { xs: 'column', md: 'row' },
                              })}
                            >
                              <FormFieldWrapper
                                id="alert-form-start-date"
                                label="Start date"
                                labelSize="small"
                              >
                                <DatePicker
                                  value={startDateAsDate}
                                  disablePast={true}
                                  onChange={(value) => {
                                    if (
                                      value &&
                                      endDateAsDate &&
                                      value.getTime() > endDateAsDate.getTime()
                                    ) {
                                      onChangeEndDate(null);
                                    }
                                    onChangeStartDate(
                                      value ? value.toISOString() : null
                                    );
                                  }}
                                />
                              </FormFieldWrapper>
                              <FormFieldWrapper
                                id="alert-form-end-date"
                                label="End date"
                                labelSize="small"
                              >
                                <DatePicker
                                  value={endDateAsDate}
                                  disablePast={true}
                                  minDate={startDateAsDate}
                                  onChange={(value) => {
                                    onChangeEndDate(
                                      value ? value.toISOString() : null
                                    );
                                  }}
                                />
                              </FormFieldWrapper>
                            </Stack>
                            <hr className="m-t-6 m-b-6" />
                            <div className="alert-edit__button-wrapper">
                              <FormButtons
                                submit={saveData}
                                cancel={onCancel}
                              />
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </AlertConsumer>
                </Box>
              </Column>
            </Container>
          </AlertProvider>
        )}
      </UserConsumer>
    );
  }
}

export default AlertEdit;
