import createRequest from 'utils/api/requestFactory';

export const getNext = (url, headers) => {
  const reqObject = {
    method: 'get',
    url,
    errorMessages: {
      '*': 'Failed to load more, please contact support for assistance.',
    },
  };

  if (headers) {
    reqObject.headers = headers;
  }

  return createRequest(reqObject);
};
