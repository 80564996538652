import UserFindSelector from 'components/UserFindSelector';

import { Consumer } from '..';

const fixOid = (owner) => {
  return owner.id.substring(owner.id.indexOf('-') + 1);
};

const getInformationOwner = (owner) => {
  return {
    oid: fixOid(owner),
    name: `${owner.attributes.firstName} ${owner.attributes.lastName}`,
  };
};

const InformationOwner = () => (
  <Consumer>
    {({
      updatedArticle: { informationOwner },
      onChangeArticle,
      showInitialError,
    }) => (
      <>
        <p>Select information owner</p>
        <UserFindSelector
          selectedUsers={
            informationOwner
              ? { name: informationOwner.name, value: informationOwner.oid }
              : undefined
          }
          onChange={(value) => {
            const informationOwner = value ? getInformationOwner(value) : null;
            onChangeArticle({ informationOwner });
          }}
          showError={!informationOwner && showInitialError}
        />
        <hr className="m-t-6 m-b-5" />
      </>
    )}
  </Consumer>
);

export default InformationOwner;
