import './Group.scss';

import Box from 'components/Box';
import Title from 'components/Title';
import PropTypes from 'prop-types';
import { Component } from 'react';
import AnimateHeight from 'react-animate-height';

import Question from '../Question';

const BEFORE_READ_MORE = 3;

class QuestionGroup extends Component {
  state = {
    open: false,
  };

  toggle = () => this.setState(({ open }) => ({ open: !open }));

  renderToggle = () => {
    const { open } = this.state;
    return (
      <div className="faq__toggle-container">
        <button
          className="faq__group-toggle-button"
          onClick={this.toggle}
          aria-label={`Toggle all ${this.props.name} questions`}
          aria-expanded={open}
        >
          {open ? 'Show less' : 'Show all'} <span>{open ? '-' : '+'}</span>
        </button>
      </div>
    );
  };

  sortQuestions = (a, b) => {
    if (a.sortIndex > b.sortIndex) {
      return 1;
    } else if (a.sortIndex < b.sortIndex) {
      return -1;
    } else {
      return 0;
    }
  };

  renderInitialQuestions = () => {
    const questions = this.props.questions.sort(this.sortQuestions);
    return questions
      .slice(0, BEFORE_READ_MORE)
      .map((q, i) => <Question {...q} parent={this.props.name} key={i} />);
  };

  renderRestOfQuestions = () => {
    const questions = this.props.questions.sort(this.sortQuestions);
    return questions
      .slice(BEFORE_READ_MORE)
      .map((q, i) => <Question {...q} parent={this.props.name} key={i} />);
  };

  render() {
    const { open } = this.state;
    const { questions, name, isFirstItem } = this.props;
    const hasMoreItems = questions.length > BEFORE_READ_MORE;

    return (
      <>
        <Title color={isFirstItem ? 'white' : 'black'}>{name}</Title>
        <Box>
          <section className="faq__group">
            <div className="faq__group-container">
              {this.renderInitialQuestions()}
            </div>
            {hasMoreItems ? (
              <div>
                <AnimateHeight duration={200} height={open ? 'auto' : 0}>
                  {this.renderRestOfQuestions()}
                </AnimateHeight>
                {this.renderToggle()}
              </div>
            ) : null}
          </section>
        </Box>
      </>
    );
  }
}

QuestionGroup.propTypes = {
  name: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
};

export default QuestionGroup;
