import BoxHeader from '../../common/BoxHeader';

const TopicsHeader = () => {
  const title = 'Topics';
  const description = `Are you interested in news, blogs or stories on a specific topic?
  Click on the topic of your interest below to explore!`;
  return <BoxHeader title={title} description={description} />;
};

export default TopicsHeader;
