import './SuggestionsList.scss';

import SuggestionItem from 'pages/Portals/Search/Suggestions/SuggestionItem/SuggestionItem';
import { InnovationSearchSuggestionsListProps } from 'types/Portal';

const SuggestionsList = ({
  suggestions,
  searchQuery,
  justSelectedSuggestionIndex,
  history,
  hoverSearchResult,
}: InnovationSearchSuggestionsListProps) => {
  return (
    <ul className="innovation-search-bar__suggestion-list">
      {Boolean(suggestions.length) &&
        suggestions.map((suggestion, index) => (
          <SuggestionItem
            key={index}
            suggestion={suggestion}
            searchQuery={searchQuery}
            justSelectedSuggestionIndex={justSelectedSuggestionIndex}
            index={index}
            history={history}
            hoverSearchResult={hoverSearchResult}
          />
        ))}
    </ul>
  );
};

export default SuggestionsList;
