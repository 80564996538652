import SearchSelector from 'components/SearchSelector';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { getCompanySuggestions } from 'utils/misc/autoSuggest';

class CompanySelector extends Component {
  render() {
    const {
      alwaysDoubleLine,
      className,
      disabled,
      multiple,
      onChange,
      showError,
      selectedCompanies,
      defaultLabel,
      identifier,
    } = this.props;
    return (
      <SearchSelector
        alwaysDoubleLine={alwaysDoubleLine}
        className={className}
        defaultLabel={defaultLabel}
        disabled={disabled}
        fetchSearchSuggestions={getCompanySuggestions}
        id="companieselector"
        multiple={multiple}
        onChange={onChange}
        placeholder={multiple ? 'Search companies' : 'Search company'}
        selectedOptions={selectedCompanies}
        showError={showError}
        identifier={identifier}
      />
    );
  }
}

CompanySelector.propTypes = {
  alwaysDoubleLine: PropTypes.bool,
  className: PropTypes.string,
  defaultLabel: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  selectedCompanies: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ]),
  showError: PropTypes.bool,
};

CompanySelector.defaultProps = {
  disabled: false,
  multiple: false,
};

export default CompanySelector;
