import * as cmsLibraryApi from 'api/cms/library';
import { LibraryRootNode, LibraryRootType } from 'api/cms/library/types';
import { useUser } from 'components/Context/User';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const LibraryRootContext = React.createContext<{
  isLoading: boolean;
  nodes: LibraryRootNode[];
  myLibraryPath: string | null;
} | null>(null);

type LibraryRootProviderProps = {
  children: React.ReactNode;
};

const LibraryRootProvider = ({ children }: LibraryRootProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [myLibraryPath, setMyLibraryPath] = useState<string | null>(null);

  const [nodes, setNodes] = useState<LibraryRootNode[]>([
    {
      title: 'Global',
      path: '/group-library',
      folderType: LibraryRootType.Global,
    },
  ]);

  const user = useUser();

  const fetchLibraryRootNodes = useCallback(
    async (user: {
      divisionId: string;
      countryId?: string;
      regionId?: string;
    }) => {
      try {
        setIsLoading(true);
        const response = await cmsLibraryApi.getHomeNode({
          divisionId: user.divisionId,
          countryId: user.countryId,
          regionId: user.regionId,
        });
        const data = response?.data?.data;

        if (data?.departments) {
          setNodes(data.departments as LibraryRootNode[]);
        }
        if (data?.path) {
          setMyLibraryPath(data.path as string);
        }
      } catch {
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (!user.divisionId || user.isGlobalOnlyUser) {
      return;
    }
    fetchLibraryRootNodes({
      divisionId: user.divisionId,
      countryId: user.countryId,
      regionId: user.regionId,
    });
  }, [
    fetchLibraryRootNodes,
    user.countryId,
    user.divisionId,
    user.isGlobalOnlyUser,
    user.regionId,
  ]);

  const contextValue = useMemo(
    () => ({
      myLibraryPath,
      nodes,
      isLoading: isLoading || user.isLoading,
    }),
    [myLibraryPath, nodes, isLoading, user.isLoading]
  );

  return (
    <LibraryRootContext.Provider value={contextValue}>
      {children}
    </LibraryRootContext.Provider>
  );
};

const useLibraryRoot = () => {
  const context = useContext(LibraryRootContext);
  if (!context) {
    throw new Error('LibraryRootContext is not defined');
  }
  return context;
};

const LibraryRootConsumer = LibraryRootContext.Consumer;

export { LibraryRootConsumer, LibraryRootProvider, useLibraryRoot };
