import classNames from 'classnames';
import Button from 'components/Button';
import { Tooltip } from 'componentsNew';
import PropTypes from 'prop-types';

export const getPublishButtonTooltipText = (
  divisions,
  segments,
  countries,
  departments,
  sites,
  companies
) => {
  const tooltipContent = [];

  if (divisions && divisions.length) {
    divisions.forEach((division) => tooltipContent.push(division.name));
  } else {
    tooltipContent.push('Global');
  }
  if (segments && segments.length > 0) {
    segments.forEach((segment) => tooltipContent.push(segment.name));
  }
  if (countries && countries.length > 0) {
    countries.forEach((country) => tooltipContent.push(country.name));
  }
  if (departments && departments.length > 0) {
    departments.forEach((department) => tooltipContent.push(department.name));
  }
  if (sites && sites.length > 0) {
    sites.forEach((site) => tooltipContent.push(site.name));
  }
  if (companies && companies.length > 0) {
    companies.forEach((company) => tooltipContent.push(company.name));
  }
  return tooltipContent.join(', ').replace(/, (?=[^, ]+$)/, ' and ');
};

const PublishButton = ({
  countries,
  divisions,
  segments,
  sites,
  departments,
  companies,
  onSaveArticle,
  isCreating,
  buttonStyle,
}) => {
  const publishButton = classNames('m-r-2 sm-m-b-2', {
    'article-button--wrapper--publish': buttonStyle,
  });

  const tooltipText = getPublishButtonTooltipText(
    divisions,
    segments,
    countries,
    departments,
    sites,
    companies
  );

  return (
    <Tooltip
      title="Your article will be published here:"
      text={tooltipText}
      size="large"
    >
      <Button className={publishButton} onClick={onSaveArticle}>
        {isCreating ? 'Publish' : 'Update'}
      </Button>
    </Tooltip>
  );
};

PublishButton.propTypes = {
  countries: PropTypes.array,
  companies: PropTypes.array,
  departments: PropTypes.array,
  divisions: PropTypes.array,
  segments: PropTypes.array,
  isCreating: PropTypes.bool,
  onSaveArticle: PropTypes.func,
  sites: PropTypes.array,
};

export default PublishButton;
