import './GatewayProcess.scss';

import PortalNode from 'api/models/PortalNode';
import GatewayList from 'components/Portal/GatewayList';
import PropTypes from 'prop-types';

const GatewayProcess = ({ gatewaysChild }) => {
  let childrenSubtree = [];
  if (gatewaysChild) {
    childrenSubtree = gatewaysChild.getChildPages();
  }

  return (
    <>
      <GatewayList gateways={childrenSubtree}></GatewayList>
    </>
  );
};

GatewayProcess.propTypes = {
  gatewaysChild: PropTypes.instanceOf(PortalNode),
};

export default GatewayProcess;
