import { entityToHTML as oldEntityToHTML } from 'medium-draft/lib/exporter';
import { Block } from 'medium-draft/lib/util/constants';
import { getCMSImageId } from 'utils/cms';
import { getId, getService } from 'utils/cms/parseVideoUrls';

export const blockToHTML = (block) => {
  const blockType = block.type;
  switch (blockType) {
    case Block.H1:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return <h1 />;
    case Block.H2:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return <h2 />;
    case Block.H3:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return <h3 />;
    case Block.H4:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return <h4 />;
    case Block.H5:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return <h5 />;
    case Block.H6:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return <h6 />;
    case Block.BLOCKQUOTE_CAPTION:
    case Block.CAPTION:
      return {
        start: `<p><caption>`,
        end: '</caption></p>',
      };
    case Block.IMAGE: {
      let imgData = block.data;
      const text = block.text.trim();
      const extraClass = text.length > 0 ? 'image-has-caption' : '';
      const widthClass = imgData.width > 1050 ? 'image-full-bleed' : '';

      const imageId = getCMSImageId(imgData.src);
      return {
        start:
          `<figure class="${extraClass} ${widthClass} image-${
            imgData.orientation
          }" >
          <img src="${imageId}" alt="${
            imgData.altText || ''
          }" data-original-width="${imgData.width}"` +
          ` data-original-height="${imgData.height}" data-orientation="${imgData.orientation}"` +
          `${imgData.expandable ? ' expandable' : ''} />
          <figcaption>`,
        end: '</figcaption></figure>',
      };
    }
    case Block.ATOMIC:
      return {
        start: `<div class="embed-container">`,
        end: '</div>',
      };
    case Block.TODO: {
      const checked = block.data.checked || false;
      let inp = '';
      if (checked) {
        inp = '<input type=checkbox disabled checked="checked" />';
      } else {
        inp = '<input type=checkbox disabled />';
      }
      return {
        start: `<div>${inp}<p>`,
        end: '</p></div>',
      };
    }
    case Block.BREAK:
      return <hr />;
    case Block.BLOCKQUOTE:
      return <blockquote />;
    case Block.OL:
      return {
        element: <li />,
        nest: <ol />,
      };
    case Block.UL:
      return {
        element: <li />,
        nest: <ul />,
      };
    default:
      return null;
  }
};

const htmlDecode = (input) => {
  let e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

let accordionCounter = 0;

export const entityToHTML = (entity, originalText) => {
  let url = entity.data.url;
  if (typeof url !== 'string' && entity.type !== 'accordion') {
    url = `${getService(entity.data.url)}${getId(entity.data.url)}`;
  }

  if (entity.type === 'LINK') {
    originalText = htmlDecode(originalText);
  }

  if (entity.type === 'embed') {
    return (
      <iframe
        title="Embedded content"
        src={url}
        frameBorder="0"
        allowFullScreen
      />
    );
  }

  if (entity.type === 'accordion') {
    const titleRegex = new RegExp(/^\d+\s/);
    let formattedTitle;
    const firstTwoTitleLetters = entity.data.title.match(titleRegex);

    if (firstTwoTitleLetters && titleRegex.test(firstTwoTitleLetters[0])) {
      formattedTitle = entity.data.title.replace(titleRegex, '');
    }

    accordionCounter++;
    return (
      <div className="accordion-container">
        <input type="checkbox" id={`accordion-input--${accordionCounter}`} />
        {firstTwoTitleLetters && (
          <span className="accordion-container-header-row__number">
            {firstTwoTitleLetters}
          </span>
        )}
        {formattedTitle ? (
          <label
            className="accordion-container-header-row__title"
            htmlFor={`accordion-input--${accordionCounter}`}
          >
            {formattedTitle}
          </label>
        ) : (
          <label
            className="accordion-container-header-row__title"
            htmlFor={`accordion-input--${accordionCounter}`}
          >
            {entity.data.title}
          </label>
        )}
        <svg
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="accordion-container__open-icon"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.70711 6.20711C9.31658 6.59763 8.68342 6.59763 8.29289 6.20711L5 2.91421L1.70711 6.20711C1.31658 6.59763 0.683417 6.59763 0.292894 6.20711C-0.0976312 5.81658 -0.0976312 5.18342 0.292894 4.79289L4.29289 0.792893C4.68342 0.402369 5.31658 0.402369 5.70711 0.792893L9.70711 4.79289C10.0976 5.18342 10.0976 5.81658 9.70711 6.20711Z"
            fill="#125468"
          />
        </svg>
        <div
          className="accordion-container__body"
          dangerouslySetInnerHTML={{
            __html: entity.data.body,
          }}
        />
      </div>
    );
  }

  return oldEntityToHTML(entity, originalText);
};
