import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { GLOBAL_DIVISION_ID } from 'api/organization/transformers';
import { useOrganizationData } from 'components/Context/OrganizationData';
import OnlyWithRole from 'components/OnlyWithRole';
import { Radio, SegmentedControl, Select } from 'componentsNew';
import { SelectItem } from 'componentsNew/Select/Select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'translations';
import { Division } from 'types';
import { GAonChangeManageFeedFilter } from 'utils/analytics';

const elementId = 'profile-manage-feed-filter';

export enum ArticleStatus {
  Published = 'Published',
  Drafts = 'Drafts',
  Scheduled = 'Scheduled',
}

type ManageFeedFilterProps = {
  isLoading: boolean;
  filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
    divisions: Division[];
  };
  onFilterChange: (filter: {
    isAdmin: boolean;
    articleStatus: ArticleStatus;
    divisions: Division[];
  }) => void;
};

const GLOBAL_DIVISION_SELECT_ITEM = {
  name: translations.global,
  value: GLOBAL_DIVISION_ID,
};

const ManageFeedFilter = ({
  isLoading,
  filter,
  onFilterChange,
}: ManageFeedFilterProps) => {
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [selectedDivisions, setSelectedDivisions] = useState<SelectItem[]>([]);

  const { getDivisionsTree } = useOrganizationData();

  useEffect(() => {
    const getDivisions = async () => {
      const divisionsTree = await getDivisionsTree();

      if (!divisionsTree) return;

      const allDivisions: Division[] = divisionsTree.divisions.map(
        (division) => {
          return {
            id: division.id,
            name: division.name,
          };
        }
      );

      setDivisions(allDivisions);
    };
    getDivisions();
  }, [getDivisionsTree]);

  const divisionsFilterSelectItems = useMemo(() => {
    if (!divisions) {
      return [GLOBAL_DIVISION_SELECT_ITEM];
    }

    const divisionSelectItems: SelectItem[] = divisions
      .map((division) => ({
        name: division.name,
        value: division.id,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

    return [GLOBAL_DIVISION_SELECT_ITEM, ...divisionSelectItems];
  }, [divisions]);

  const onDivisionFilterChange = useCallback(
    (items: SelectItem[]) => {
      const newFilter = {
        ...filter,
        divisions: items.map((item) => ({
          id: item.value,
          name: item.name,
        })),
      };

      onFilterChange(newFilter);
      setSelectedDivisions(items);
    },
    [filter, onFilterChange]
  );

  const onAdminChange = useCallback(
    (value: boolean) => {
      const newFilter = {
        ...filter,
        isAdmin: value,
        articleStatus: ArticleStatus.Published,
        divisions: [],
      };
      onFilterChange(newFilter);
      setSelectedDivisions([]);
      GAonChangeManageFeedFilter(
        newFilter.isAdmin
          ? `${newFilter.articleStatus}, Admin`
          : `${newFilter.articleStatus}, Non-Admin`
      );
    },
    [filter, onFilterChange]
  );

  const onArticleStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newFilter = {
        ...filter,
        articleStatus: e.target.value as ArticleStatus,
      };
      onFilterChange(newFilter);
      GAonChangeManageFeedFilter(
        newFilter.isAdmin
          ? `${newFilter.articleStatus}, Admin`
          : `${newFilter.articleStatus}, Non-Admin`
      );
    },
    [filter, onFilterChange]
  );

  return (
    <Stack
      sx={(theme) => ({
        gap: theme.spacing('sm'),
        padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
      })}
    >
      <OnlyWithRole viewableByRole="AvenueSuperuser">
        <SegmentedControl
          id={`${elementId}-admin`}
          value={filter.isAdmin}
          disabled={isLoading}
          isDeselectable={false}
          sx={{ alignSelf: 'flex-end' }}
          size="small"
          items={[
            {
              name: translations.manageContentFilterMyContent,
              value: false,
            },
            {
              name: translations.manageContentFilterAllContent,
              value: true,
            },
          ]}
          onChange={onAdminChange}
        />
        <Divider />
      </OnlyWithRole>
      <Stack
        sx={() => ({
          flexDirection: 'row',
          justifyContent: 'space-between',
        })}
      >
        <RadioGroup
          value={filter.articleStatus}
          sx={(theme) => ({
            flexDirection: 'row',
            gap: theme.spacing('xs'),
            margin: `${theme.spacing('xxxs')} 0`,
          })}
          onChange={onArticleStatusChange}
        >
          <FormControlLabel
            label={translations.manageContentPublished}
            value={ArticleStatus.Published}
            control={
              <Radio id={`${elementId}-published`} disabled={isLoading} />
            }
          />
          {!filter.isAdmin && (
            <>
              <FormControlLabel
                label={translations.manageContentDrafts}
                value={ArticleStatus.Drafts}
                control={
                  <Radio id={`${elementId}-drafts`} disabled={isLoading} />
                }
              />
              <FormControlLabel
                label={translations.manageContentScheduled}
                value={ArticleStatus.Scheduled}
                control={
                  <Radio id={`${elementId}-scheduled`} disabled={isLoading} />
                }
              />
            </>
          )}
        </RadioGroup>
        {filter.isAdmin && (
          <Select
            multiple
            sx={(theme) => ({
              width: '17.5rem',
              backgroundColor: theme.colors.surface.secondary,
            })}
            items={divisionsFilterSelectItems}
            placeholder={`${translations.allDivisions}`}
            disabled={isLoading}
            value={selectedDivisions}
            onChange={onDivisionFilterChange}
          />
        )}
      </Stack>
    </Stack>
  );
};

export { ManageFeedFilter };
