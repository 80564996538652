import './FeedTab.scss';

import classNames from 'classnames';
import { Column, Container } from 'components/Grid';

const FeedTab = ({ children, color, selected, setSelected, tabs }) => {
  const tabColorClass = classNames('feed-tab', {
    'feed-tab--grey': color === 'grey',
  });
  return (
    <Container>
      <Column>
        <ul className={tabColorClass}>
          {tabs.map((tab) => {
            const activeClassNames = classNames('feed-tab__link', {
              'feed-tab__link--active': tab.value === selected.value,
            });
            return (
              <li className="feed-tab__item" key={tab.value}>
                <div
                  className={activeClassNames}
                  onClick={() => setSelected(tab)}
                >
                  {tab.text}
                </div>
              </li>
            );
          })}
        </ul>
        {children}
      </Column>
    </Container>
  );
};
export default FeedTab;
