import './FeedLanguageSelector.scss';

import { FeedConsumer } from 'components/Context/Feed';
import Flex from 'components/Flex';
import LanguageBanner from 'components/LanguageBanner';
import LanguageSelector from 'components/LanguageSelector';

const FeedLanugageSelector = ({ languageSelectorColor }) => {
  return (
    <FeedConsumer>
      {({
        filter: { locale },
        onChangeLocale,
        toggleIsAutoTranslateMessageVisible,
        isAutoTranslateMessageVisible,
      }) =>
        locale === 'en' ? (
          <Flex
            direction="row"
            type="flex-end"
            className="m-b-1 language-select-flex-container"
          >
            <LanguageSelector
              {...{ color: languageSelectorColor }}
              label="Translate feed"
              currentLocale={locale}
              onLanguageChange={(updatedLanguage) => {
                onChangeLocale(updatedLanguage.locale);
                toggleIsAutoTranslateMessageVisible(true);
              }}
            />
          </Flex>
        ) : (
          isAutoTranslateMessageVisible && (
            <LanguageBanner
              currentLocale={locale}
              onLanguageReset={() => onChangeLocale('en')}
              onClose={() => {
                toggleIsAutoTranslateMessageVisible(false);
                onChangeLocale('en');
              }}
            />
          )
        )
      }
    </FeedConsumer>
  );
};

export default FeedLanugageSelector;
