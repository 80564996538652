import Box from 'components/Box';
import Text from 'components/Editable/Text';
import Title from 'components/Title';
import { translations } from 'translations';

import { Consumer } from '..';

const SearchKeywords = ({ fieldName = 'searchKeywords' }) => {
  return (
    <Consumer>
      {({ article: { [fieldName]: searchKeywords }, onChangeArticle }) => (
        <>
          <Title size="small">{translations.searchKeywordsTitle}</Title>
          <Box className="p-b-2">
            <p>{translations.searchKeywordsInfoText}</p>
            <Text
              wrapper={{
                element: 'p',
              }}
              placeholder={translations.searchKeywordsTitle}
              maxLength={300}
              onBlur={(value: string) =>
                onChangeArticle({ [fieldName]: value })
              }
              content={searchKeywords}
            ></Text>
          </Box>
        </>
      )}
    </Consumer>
  );
};

export { SearchKeywords };
