export const formatCompanies = (response) => {
  let result = [];
  if (response.data && response.data.length > 0) {
    result = response.data.map((company) => ({
      value: company.id,
      name: company.attributes.name,
    }));
  }
  return result;
};
