export const maxLengthValidator =
  (
    maxLength,
    message = 'You have exceeded the maximum number of characters for ' +
      `the preamble.\nMaximum number of characters is ${maxLength}.`,
    cleanHtml = false
  ) =>
  (value) => {
    if (value && cleanHtml) {
      let cleantext = value.replace(/(<([^>]+)>)/gi, '');

      if (maxLength > 0 && cleantext && cleantext.length > maxLength) {
        return message;
      }
      return null;
    }

    if (maxLength > 0 && value && value.length > maxLength) {
      return message;
    }
    return null;
  };

export const relatedContentValidator = (value) => {
  const message = 'Both Title and URL fields are required for Related Content.';
  if (value && value.length) {
    for (let index = 0; index < value.length; index++) {
      if (!value[index].title || !value[index].url) {
        return message;
      }
      if (!value[index].url.startsWith('http')) {
        value[index].url = `https://${value[index].url}`;
      }
    }
  }
  return null;
};

export const vivaEngageValidator = (value) => {
  if (!value.startsWith('https://web.yammer.com/embed/groups')) {
    return 'The inserted link is not a Viva Engage link.';
  }

  return null;
};
