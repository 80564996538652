import TopicSelector from 'components/TopicSelector';

import { Consumer } from '..';

const Topic = () => {
  return (
    <Consumer>
      {({ onChangeArticle, article: { channel } }) => {
        return (
          <>
            <p>Select topic</p>
            <TopicSelector
              onChange={({ originalName }: any) =>
                onChangeArticle({ channel: originalName, tag: null })
              }
              defaultTopic={channel}
            />
            <hr className="m-t-6 m-b-6" />
          </>
        );
      }}
    </Consumer>
  );
};

export default Topic;
