import _MaxArticleCount from 'components/MaxArticleCount';

import { Consumer } from '..';

const MaxArticleCount = () => (
  <Consumer>
    {({ article: { maxListedCount }, onChangeArticle, updatedArticle }) => {
      const { showAllEvents } = updatedArticle;
      return (
        <_MaxArticleCount
          maxListedCount={maxListedCount}
          onBlur={(value) => onChangeArticle({ maxListedCount: value })}
          showAllEvents={showAllEvents}
        />
      );
    }}
  </Consumer>
);

export default MaxArticleCount;
