export const articleListBlockValidator = (updatedArticle, { fields }) => {
  const validationErrors = [];
  const getDisplayName = (name) =>
    fields.find(({ key }) => key === name).displayName;
  const oneOf = ['tag', 'channel'];
  if (oneOf.filter((fieldName) => !!updatedArticle[fieldName]).length !== 1) {
    validationErrors.push(
      oneOf.map((field) => getDisplayName(field)).join(' or ')
    );
  }
  return validationErrors.length === 0 ? null : validationErrors;
};

export const customBlockFieldValidator =
  (side) =>
  (updatedArticle, { fields }) => {
    const fieldSuffix =
      side === 'left' ? 'Left' : side === 'right' ? 'Right' : side;
    const validatedFields = {
      text: [],
      media: [],
      infographic: [],
    };
    const validatedSubstitutingFields = {
      media: [['heroVideo', 'heroImage']],
    };
    const validationErrors = [];
    if (side === 'left' && !updatedArticle.displayLeft) {
      validationErrors.push('Display type for left side');
    }
    if (side === 'right' && !updatedArticle.displayRight) {
      validationErrors.push('Display type for right side');
    }
    if (validationErrors.length > 0) {
      return validationErrors;
    }
    const getKey = (name) => `${name}${fieldSuffix}`;
    const getDisplayName = (name) => {
      const field = getKey(name);
      const fieldDefinition = fields.find(({ key }) => key === field);
      return fieldDefinition && fieldDefinition.displayName;
    };
    const getValue = (name) => updatedArticle[getKey(name)];
    if (getValue('display') !== 'media' && !getValue('title')) {
      validationErrors.push(`${fieldSuffix} title`);
    }
    for (const fieldName of validatedFields[getValue('display')]) {
      if (!getValue(fieldName)) {
        validationErrors.push(getDisplayName(fieldName));
      }
    }
    for (const validSubstitution of validatedSubstitutingFields[
      getValue('display')
    ] || []) {
      const validSubstitutionFound = validSubstitution.some(
        (fieldName) => !!updatedArticle[getKey(fieldName)]
      );
      if (!validSubstitutionFound) {
        validationErrors.push(
          validSubstitution.map((field) => getDisplayName(field)).join(' or ')
        );
      }
    }
    return validationErrors.length === 0 ? null : validationErrors;
  };

const fieldNameLookup = {
  text: ['title', 'preamble', 'body', 'backgroundColor', 'textColor'],
  media: [
    'heroVideo',
    'heroImage',
    'heroImageWidth',
    'isImageFullyVisible',
    'backgroundColor',
  ],
  infographic: [
    'title',
    'body',
    'linkUrl',
    'backgroundColor',
    'textColor',
    'linkButtonText',
  ],
};

const commonFieldNames = [
  'title',
  'portalType',
  'displayLeft',
  'displayRight',
  'reverse',
  'sortIndex',
  'template',
];

export const customBlockFieldTransform = (updatedArticle, { fields }) => {
  // An article can only have the fields that are defined in the field lookup, plus the common fields.
  const getKey = (name, fieldSuffix) => `${name}${fieldSuffix}`;
  const retainedFieldNames = {};
  for (const fieldName of fieldNameLookup[updatedArticle.displayLeft]) {
    retainedFieldNames[getKey(fieldName, 'Left')] = true;
  }
  for (const fieldName of fieldNameLookup[updatedArticle.displayRight]) {
    retainedFieldNames[getKey(fieldName, 'Right')] = true;
  }
  for (const fieldName of commonFieldNames) {
    retainedFieldNames[fieldName] = true;
  }
  for (const { key } of fields) {
    if (retainedFieldNames[key] === undefined) {
      delete updatedArticle[key];
    }
  }
};
