import TagSelector from 'components/TagSelector';

import { Consumer } from '..';

const Tags = () => {
  return (
    <Consumer>
      {({ onChangeArticle, article: { tags } }) => {
        return (
          <>
            <p>Select tags (optional)</p>
            <TagSelector
              onChange={(value) => {
                onChangeArticle({ tags: value });
              }}
              defaultSelected={tags}
              multiple={true}
              deSelectEnabled={true}
            />
            <hr className="m-t-6 m-b-6" />
          </>
        );
      }}
    </Consumer>
  );
};

export default Tags;
