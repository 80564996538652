import './LinkItem.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LinkItem = ({ href, text, className, outbound, onClick }) => {
  const footerLinkClassName = classNames('link-item', {
    [className]: className,
  });

  if (outbound) {
    return (
      <li className={footerLinkClassName}>
        <a
          className="link-item__link"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {text}
        </a>
      </li>
    );
  }

  return (
    <li className={footerLinkClassName}>
      <Link className="link-item__link" to={href} onClick={onClick}>
        {text}
      </Link>
    </li>
  );
};

LinkItem.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  outbound: PropTypes.bool,
  onClick: PropTypes.func,
};

export default LinkItem;
