import Box from '@mui/material/Box';
import { translations } from 'translations';

type EmbedMSFormProps = {
  src: string;
};

const EmbedMSForm = ({ src }: EmbedMSFormProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        aspectRatio: { xs: 4 / 6, sm: 4 / 3 },
        marginBottom: '1rem',
        overflow: 'hidden',
        '> iframe': {
          width: '100%',
          height: '100%',
          border: 'none',
        },
      }}
    >
      <iframe
        title={translations.embedMsFormIframeTitle}
        src={src}
        allowFullScreen
      />
    </Box>
  );
};

export { EmbedMSForm };
