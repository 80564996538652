import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const UseCompactPageListing = () => (
  <Consumer>
    {({ onToggleArticleValue, article: { useCompactChildPageListing } }) => (
      <Flex>
        <Checkbox
          defaultChecked={useCompactChildPageListing}
          id="useCompactChildPageListing"
          onChange={() => onToggleArticleValue('useCompactChildPageListing')}
        />
        <label htmlFor="useCompactChildPageListing" className="m-l-3">
          Use compact child page listing
        </label>
      </Flex>
    )}
  </Consumer>
);

export default UseCompactPageListing;
