import Box from 'components/Box';
import Select from 'components/Select';
import { Component } from 'react';

import { Consumer } from '../';

const options = [
  { value: 'text', name: 'Text content' },
  { value: 'media', name: 'Image or video content' },
  { value: 'infographic', name: 'Infographic content' },
];

class ContainerChildBlockSelector extends Component {
  onChange = (onChangeArticle, side) => (option) => {
    onChangeArticle({ [`display${side}`]: option && option.value });
  };

  render() {
    const { children } = this.props;
    let { side } = this.props;
    side = side === 'left' ? 'Left' : side === 'right' ? 'Right' : side;
    return (
      <Consumer>
        {({
          onChangeArticle,
          updatedArticle: { [`display${side}`]: template },
        }) => {
          const selectedOption = options.find(
            (option) => option.value === template
          );
          return (
            <>
              <Select
                data={options}
                placeholder="Select..."
                selected={selectedOption}
                onChange={this.onChange(onChangeArticle, side)}
              />
              <Box noShadow>{children}</Box>
            </>
          );
        }}
      </Consumer>
    );
  }
}

export default ContainerChildBlockSelector;
