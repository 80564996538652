import './MaxArticleCount.scss';

import Editable from 'components/Editable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

class MaxArticleCount extends PureComponent {
  state = {
    maxListedCount: this.props.maxListedCount,
  };

  updateValue = (e) => {
    const {
      value,
      validity: { valid },
    } = e.target;
    if (valid) {
      if (value <= this.props.max && value >= this.props.min) {
        this.setState({ maxListedCount: Number(value) });
      } else if (value === '') {
        this.setState({ maxListedCount: '' });
      }
    }
  };

  onInputBlur = () => {
    const { onBlur } = this.props;
    onBlur(this.state.maxListedCount);
  };

  render() {
    const { showAllEvents } = this.props;
    return (
      <div className="max-article-count m-b-4">
        <Editable showAllEvents={showAllEvents}>
          <p>Maximum article count in listing: </p>
          <input
            disabled={showAllEvents}
            type="text"
            pattern="\d*"
            onBlur={this.onInputBlur}
            onChange={this.updateValue}
            value={this.state.maxListedCount}
          />
        </Editable>
        <span>{this.props.description}</span>
      </div>
    );
  }
}

MaxArticleCount.propTypes = {
  description: PropTypes.string,
  maxListedCount: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onBlur: PropTypes.func.isRequired,
};

MaxArticleCount.defaultProps = {
  description:
    'The largest number of articles that will be shown in the listing.',
  maxListedCount: 4,
  min: 1,
  max: 100,
};

export default MaxArticleCount;
