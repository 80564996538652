import Hero from 'components/Hero';
import PropTypes from 'prop-types';

import { Consumer } from '..';

const onChange = (onChangeArticle, fieldNames) => (values) =>
  onChangeArticle({
    [fieldNames.imageFieldName]: values.heroImage,
    [fieldNames.videoFieldName]: values.heroVideo,
    [fieldNames.imageWidthFieldName]: values.heroImageWidth,
    [fieldNames.imageHeightFieldName]: values.heroImageHeight,
    [fieldNames.altTextFieldName]: values.heroAltText,
  });

const HeroWrapper = ({
  allowPortraitMode,
  useVideo,
  aspectRatio,
  minWidth,
  isHero = true,
  imageFieldName = 'heroImage',
  videoFieldName = 'heroVideo',
  imageWidthFieldName = 'heroImageWidth',
  imageHeightFieldName = 'heroImageHeight',
  altTextFieldName = 'heroAltText',
  displayType,
  useAltText,
}) => (
  <Consumer>
    {({
      article: {
        [imageFieldName]: heroImage,
        [videoFieldName]: heroVideo,
        [imageWidthFieldName]: heroImageWidth,
        [imageHeightFieldName]: heroImageHeight,
        [altTextFieldName]: heroAltText,
      },
      onChangeArticle,
    }) => (
      <Hero
        allowPortraitMode={allowPortraitMode}
        heroImage={heroImage}
        heroVideo={heroVideo}
        heroImageWidth={heroImageWidth}
        heroImageHeight={heroImageHeight}
        heroAltText={heroAltText}
        onChange={onChange(onChangeArticle, {
          imageFieldName,
          videoFieldName,
          imageWidthFieldName,
          imageHeightFieldName,
          altTextFieldName,
        })}
        useVideo={useVideo}
        aspectRatio={aspectRatio}
        minWidth={minWidth}
        isHero={isHero}
        displayType={displayType}
        useAltText={useAltText}
      />
    )}
  </Consumer>
);

HeroWrapper.propTypes = {
  allowPortraitMode: PropTypes.bool,
  useVideo: PropTypes.bool,
  aspectRatio: PropTypes.number,
  minWidth: PropTypes.number,
  isHero: PropTypes.bool,
  imageFieldName: PropTypes.string,
  videoFieldName: PropTypes.string,
  imageWidthFieldName: PropTypes.string,
  imageHeightFieldName: PropTypes.string,
  altTextFieldName: PropTypes.string,
  displayType: PropTypes.string,
  useAltText: PropTypes.bool,
};

export default HeroWrapper;
