import './Radio.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Radio = ({
  label,
  value,
  checked = false,
  onChange,
  name,
  tag: Tag = 'span',
}) => {
  const id = `radio-${name}-${value}`;

  const labelClass = classNames('radio__label', {
    'radio__label--checked': checked,
  });

  return (
    <Tag className="radio">
      <input
        className="radio__input"
        type="radio"
        name={name}
        value={value}
        id={id}
        onChange={() => {
          onChange({
            label,
            value,
            name,
          });
        }}
        checked={checked}
      />{' '}
      <label className={labelClass} htmlFor={id} id={`label-${id}`}>
        {label}
      </label>
    </Tag>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
};

export default Radio;
