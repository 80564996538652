import settings from 'settings';

const { cms, cdn } = settings.api;
const { cdnTokenKey } = settings.misc;
const basePath = cdn ? cdn : cms ? cms.replace('/api', '') : undefined;

const heroMinWidth = 1050;
const portraitHeroMinWidth = 400;

export const landscapeHeroAspectRatio = 16 / 9;
export const portraitHeroAspectRatio = 200 / 225;

export const PORTRAIT_IMAGE_SIZES = [450, 610];
export const LANDSCAPE_IMAGE_SIZES = [300, 480, 768, 1000];

export const mediumImageWidth = LANDSCAPE_IMAGE_SIZES[2];
export const largeImageWidth = LANDSCAPE_IMAGE_SIZES[3];

export const mediumImageHeight = PORTRAIT_IMAGE_SIZES[0];
export const largeImageHeight = PORTRAIT_IMAGE_SIZES[1];

export const getHeroImageUploadConstraints = (
  isPortrait,
  requiredMinWidth = null,
  requiredAspectRatio = null
) => {
  let aspectRatio = requiredAspectRatio;
  if (requiredAspectRatio === null) {
    aspectRatio = landscapeHeroAspectRatio;
  }
  let minWidth = requiredMinWidth;
  if (requiredMinWidth === null) {
    minWidth = heroMinWidth;
  }
  let minHeight = Math.floor(minWidth / aspectRatio);
  if (isPortrait) {
    if (requiredAspectRatio === null) {
      aspectRatio = portraitHeroAspectRatio;
    }
    minWidth = portraitHeroMinWidth;
    minHeight = Math.ceil(minWidth / aspectRatio);
  }
  return {
    aspectRatio,
    minWidth,
    minHeight,
  };
};

export const getIsPortraitHeroImage = (article) => {
  const { heroImage, heroImageWidth, heroImageHeight } = article;
  return (
    heroImage &&
    typeof heroImageWidth === 'number' &&
    typeof heroImageHeight === 'number' &&
    heroImageHeight !== 0 &&
    heroImageWidth <= heroImageHeight
  );
};

export const isCMSImage = (imageSrcOrId) => {
  // small optimization to not test entire images in data form
  const isData = /^(data:image)/.test(imageSrcOrId);

  if (isData) {
    return false;
  }

  return /\/static\/node\//.test(imageSrcOrId);
};

export const getCMSImageId = (imageSrc) => {
  const match = /(\/static\/node\/.+?)(\?|$)/.exec(imageSrc);

  if (match) {
    return match[1];
  }

  return null;
};

export const getImageSrc = (imageSrcOrId, size, withoutToken) => {
  if (!imageSrcOrId) {
    return null;
  }

  const isLocalImage = /^\/static\/media\//.test(imageSrcOrId);

  if (isLocalImage) {
    return imageSrcOrId;
  }

  const isData = /^(data:image)/.test(imageSrcOrId);

  if (isData) {
    return imageSrcOrId;
  }

  let imageSrc = /^(https?:\/\/)/.test(imageSrcOrId)
    ? imageSrcOrId
    : `${basePath}${imageSrcOrId}`;

  if (size) {
    imageSrc = `${imageSrc}/${size}`;
  }

  if (cdn && cdnTokenKey && !withoutToken && isCMSImage(imageSrc)) {
    imageSrc = `${imageSrc}?${cdnTokenKey}`;
  }

  return imageSrc;
};
