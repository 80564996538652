import './Hero.scss';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Button from 'components/Button';
import Editable from 'components/Editable';
import ImageModal from 'components/Editable/Files/ImageModal';
import VideoModal from 'components/Editable/Files/VideoModal';
import { ReactComponent as Plus } from 'components/Icon/Plus.svg';
import Iframe from 'components/Iframe';
import Img from 'components/Image';
import Radio from 'components/Radio';
import Resize from 'components/Resize';
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  getHeroImageUploadConstraints,
  getIsPortraitHeroImage,
  largeImageWidth,
  mediumImageHeight,
} from 'utils/cms';
import { createEmbedUrl } from 'utils/cms/parseVideoUrls';

import ContentLoader from './ContentLoader';

class Hero extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      hasMedia: Boolean(this.props.heroImage) || Boolean(this.props.heroVideo),
      videoUrl: this.props.heroVideo,
      newImageData: null,
      newImageAltText: null,
      loading: false,
      isPortraitOrientation: false,
      showAltTextInput: false,
      altTextInput: null,
    };
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.heroImage !== this.props.heroImage) {
      this.setState({ ...this.getInitialState() });
    }
  };

  onSubmitVideo = (videoUrl) => {
    this.setState({
      videoUrl: videoUrl ? videoUrl.href : null,
      newImageData: null,
      // for video the actual loading starts once the url has been submitted
      // then we wait for the iframe to finish
      loading: true,
      hasMedia: true,
    });

    this.props.onChange({
      heroVideo: videoUrl ? videoUrl.href : null,
      heroImage: null,
      heroImageWidth: null,
      heroImageHeight: null,
      heroAltText: null,
    });
  };

  onVideoLoaded = () => {
    this.setState({
      loading: false,
    });
  };

  onSubmitImage = (newImageData) => {
    this.props.onChange({
      heroVideo: null,
      heroImage: newImageData,
      heroImageWidth: newImageData.width,
      heroImageHeight: newImageData.height,
      heroAltText: newImageData.altText,
    });
    this.setState({
      videoUrl: null,
      newImageData,
      loading: false,
      hasMedia: true,
      newImageAltText: newImageData.altText,
    });
  };

  onSubmitImageBegin = () => {
    this.setState({ loading: true });
  };

  onSubmitImageCancel = () => {
    this.setState({ loading: false });
  };

  onChangeMedia = () => {
    this.setState({
      videoUrl: null,
      newImageData: null,
      heroImage: null,
      loading: false,
      hasMedia: false,
      heroAltText: null,
    });

    this.props.onChange({
      heroVideo: null,
      heroImage: null,
      heroImageWidth: this.props.heroImageWidth,
      heroImageHeight: this.props.heroImageHeight,
      heroAltText: null,
    });
  };

  onSaveAltText = () => {
    if (this.state.newImageData) {
      this.props.onChange({
        heroVideo: null,
        heroImage: this.state.newImageData,
        heroImageWidth: this.state.newImageData.width,
        heroImageHeight: this.state.newImageData.height,
        heroAltText: this.state.altTextInput,
      });
    } else {
      this.props.onChange({
        heroVideo: null,
        heroImage: this.props.heroImage,
        heroImageWidth: this.props.heroImageWidth,
        heroImageHeight: this.props.heroImageHeight,
        heroAltText: this.state.altTextInput,
      });
    }
    this.setState({
      showAltTextInput: false,
      newImageAltText: this.state.altTextInput || this.state.newImageAltText,
      altTextInput: null,
    });
  };

  onSetPortraitMode = ({ value }) => {
    this.setState({ isPortraitOrientation: value === 'portrait' });
  };

  renderInput() {
    const {
      minWidth: defaultMinWidth,
      aspectRatio,
      isHero = true,
      useAltText,
    } = this.props;
    const { isPortraitOrientation, loading, hasMedia } = this.state;
    const {
      aspectRatio: aspectRatioConstraint,
      minWidth,
      minHeight,
    } = getHeroImageUploadConstraints(
      isPortraitOrientation,
      defaultMinWidth,
      aspectRatio
    );
    return (
      <div
        className="hero"
        style={{ display: loading || hasMedia ? 'none' : undefined }}
      >
        <div className="hero__button-container">
          <ImageModal
            onSubmit={this.onSubmitImage}
            onBegin={this.onSubmitImageBegin}
            onCancel={this.onSubmitImageCancel}
            aspectRatio={aspectRatioConstraint}
            minWidth={minWidth}
            minHeight={minHeight}
            useAltText={useAltText}
          >
            <span role="button" className="hero__button">
              <Plus /> Add {isHero ? 'hero ' : ''}image
            </span>
          </ImageModal>
          {this.props.useVideo && (
            <VideoModal onSubmit={this.onSubmitVideo}>
              <span role="button" className="hero__button">
                <Plus /> Add {isHero ? 'hero ' : ''} video
              </span>
            </VideoModal>
          )}
        </div>
      </div>
    );
  }

  renderDisplay() {
    const {
      newImageData,
      videoUrl,
      loading,
      showAltTextInput,
      altTextInput,
      newImageAltText,
    } = this.state;
    const { heroImage, useVideo, displayType, heroAltText, useAltText } =
      this.props;

    const isPortrait = getIsPortraitHeroImage(this.props);
    const maxWidth = !isPortrait ? largeImageWidth : undefined;
    const maxHeight = isPortrait ? mediumImageHeight : undefined;
    return (
      <div className="hero__image-container">
        <Box
          sx={() => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '1',
            width: '100%',
            padding: '1rem',
          })}
        >
          {showAltTextInput ? (
            <>
              <Stack
                flexDirection="column"
                gap="0.5rem"
                width="100%"
                maxWidth="37.5rem"
                alignItems="center"
                margin="0 auto"
              >
                <OutlinedInput
                  sx={() => ({
                    display: 'block',
                    width: '100%',
                    margin: '0.5rem auto 0',
                  })}
                  id="image__alt-text"
                  multiline
                  size="small"
                  inputProps={{ maxLength: 125 }}
                  placeholder="Please describe the image for people who use screen readers to see images (max 125 characters)"
                  value={
                    altTextInput !== null
                      ? altTextInput
                      : newImageAltText || heroAltText || ''
                  }
                  onChange={(e) =>
                    this.setState({ altTextInput: e.target.value })
                  }
                />
                <Stack flexDirection="row" gap="0.5rem">
                  <Button
                    size="small"
                    width="narrow"
                    color="white"
                    onClick={() => {
                      this.setState({
                        showAltTextInput: false,
                        altTextInput: null,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    width="narrow"
                    disabled={altTextInput === ''}
                    size="small"
                    color="black"
                    onClick={this.onSaveAltText}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </>
          ) : (
            <Stack
              sx={() => ({
                flexDirection: 'column',
                gap: '0.5rem',
                maxWidth: '24rem',
                margin: '0 auto',
              })}
            >
              <Button
                className="hero__image-container--editbutton"
                tabIndex={0}
                size="small"
                color="white"
                onClick={this.onChangeMedia}
              >
                {useVideo ? 'Use another image or video' : 'Use another image'}
              </Button>
              {useAltText && !videoUrl && (
                <Button
                  className="hero__image-container--editbutton"
                  tabIndex={0}
                  size="small"
                  color="white"
                  onClick={() => this.setState({ showAltTextInput: true })}
                >
                  Edit image description
                </Button>
              )}
            </Stack>
          )}
        </Box>
        {newImageData && (
          <Img src={newImageData.file} alt={newImageAltText || 'Hero'} />
        )}
        {videoUrl && (
          <div style={{ display: loading ? 'none' : 'block' }}>
            <Iframe
              src={createEmbedUrl(videoUrl)}
              title={heroAltText || 'Hero'}
              onLoad={this.onVideoLoaded}
            />
          </div>
        )}
        {heroImage && !videoUrl && !newImageData && (
          <Img
            src={heroImage}
            className="hero__image"
            type={
              displayType ? displayType : isPortrait ? 'heroPortrait' : 'hero'
            }
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            alt={newImageAltText || heroAltText || 'Hero'}
          />
        )}
      </div>
    );
  }

  renderImageOrientationOptions() {
    const { minWidth: defaultMinWidth } = this.props;
    const { isPortraitOrientation } = this.state;
    const getMinSizeText = (isPortraitOrientation) => {
      const { minWidth, minHeight } = getHeroImageUploadConstraints(
        isPortraitOrientation,
        defaultMinWidth
      );
      return `(min size: ${minWidth}px x ${minHeight}px)`;
    };
    return (
      <div className="hero__image-orientation-select-container">
        <h2 className="hero__image-orientation-select-title">
          Choose hero image orientation
        </h2>
        <Radio
          checked={!isPortraitOrientation}
          id="toggle-portrait-mode-option-1"
          onChange={this.onSetPortraitMode}
          label={`Landscape ${getMinSizeText(false)}`}
          value="landscape"
          name="hero-image-orientation"
        />
        <Radio
          checked={isPortraitOrientation}
          id="toggle-portrait-mode-option-2"
          onChange={this.onSetPortraitMode}
          label={`Portrait ${getMinSizeText(true)}`}
          value="portrait"
          name="hero-image-orientation"
        />
      </div>
    );
  }

  render() {
    const { allowPortraitMode } = this.props;
    const { hasMedia, loading } = this.state;

    return (
      <>
        {allowPortraitMode && !hasMedia && this.renderImageOrientationOptions()}
        <Editable className="editable__hero m-b-4">
          {hasMedia ? this.renderDisplay() : this.renderInput()}
          {loading && (
            <Resize>{({ width }) => <ContentLoader width={width} />}</Resize>
          )}
        </Editable>
      </>
    );
  }
}

Hero.propTypes = {
  heroImage: PropTypes.string,
  heroVideo: PropTypes.string,
  useVideo: PropTypes.bool,
  heroImageWidth: PropTypes.number,
  heroImageHeight: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  minWidth: PropTypes.number,
  allowPortraitMode: PropTypes.bool,
  displayType: PropTypes.string,
  heroAltText: PropTypes.string,
  useAltText: PropTypes.bool,
};

Hero.defaultProps = {
  allowPortraitMode: false,
};

export default Hero;
