import './ImagePlaceholder.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const ImagePlaceholder = ({ children, className }) => {
  const imagePlaceholderClassname = classNames('image-placeholder', {
    [className]: className,
  });
  return <div className={imagePlaceholderClassname}>{children}</div>;
};

ImagePlaceholder.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node]),
  className: PropTypes.string,
};

export default ImagePlaceholder;
