export type MenuItemResponse = {
  data: {
    data: Array<{
      _doc: string;
      title: string;
    }>;
  };
};

export type MenuItem = {
  _doc: string;
  title: string;
};

export enum LibraryRootType {
  Global = 'global',
  Division = 'division',
  Region = 'region',
  Country = 'country',
}

export type LibraryRootNode = {
  id?: string;
  title: string;
  path: string;
  folderType: LibraryRootType;
};

export type LibraryMenuItem = {
  id: string;
  title: string;
  url: string;
  type: string;
  container: boolean;
};
