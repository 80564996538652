import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';

type PageContentHeaderProps = {
  text: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode | React.ReactNode[];
};

const PageContentHeader = ({ text, sx, children }: PageContentHeaderProps) => {
  return (
    <Box
      sx={[
        (theme) => ({
          position: 'relative',
          paddingBottom: theme.spacing('sm'),
          borderBottom: `${theme.border.thickness[1]} solid ${theme.colors.border.surfacePrimary}`,
          ':after': {
            content: '""',
            position: 'absolute',
            top: 0,
            display: 'block',
            borderTop: `0.375rem solid ${theme.colors.border.focus}`,
            width: '2rem',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={(theme) => ({
          paddingTop: theme.spacing('sm'),
          color: theme.colors.text.brand,
        })}
      >
        {text}
      </Typography>
      {children}
    </Box>
  );
};

export { PageContentHeader };
