import Text from 'components/Editable/Text';

import { Consumer } from '..';

const onChange = (onChangeArticle, fieldName) => (preamble) => {
  onChangeArticle({ [fieldName]: preamble });
};

const Preamble = ({ placeholder, fieldName = 'preamble' }) => {
  return (
    <Consumer>
      {({
        article: { [fieldName]: preamble },
        validationParameters: {
          [fieldName]: { maxLength },
        },
        validators: {
          [fieldName]: { fieldValidator },
        },
        onChangeArticle,
      }) => (
        <Text
          wrapper={{
            element: 'div',
            className: 'preamble',
          }}
          truncateAtMaxLength={false}
          validators={[fieldValidator]}
          content={preamble}
          onBlur={onChange(onChangeArticle, fieldName)}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      )}
    </Consumer>
  );
};

export default Preamble;
