import { UserConsumer } from 'components/Context/User';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Redirect } from 'react-router-dom';

const OnlyWithRole = ({
  redirectTo,
  children,
  viewableByRole,
  isAllRolesRequired = true,
}) => {
  const hasPermission = useCallback(
    (roles) => {
      if (!roles) {
        return false;
      }

      if (typeof viewableByRole === 'string') {
        return roles.includes(viewableByRole);
      }

      if (typeof viewableByRole === 'object') {
        if (isAllRolesRequired) {
          return !viewableByRole.some((role) => !roles.includes(role));
        }
        return viewableByRole.some((role) => roles.includes(role));
      }

      return false;
    },
    [isAllRolesRequired, viewableByRole]
  );

  return (
    <UserConsumer>
      {({ roles }) => {
        return hasPermission(roles) ? (
          <>{children}</>
        ) : redirectTo ? (
          <Redirect to={redirectTo} />
        ) : null;
      }}
    </UserConsumer>
  );
};

OnlyWithRole.propTypes = {
  redirectTo: PropTypes.string,
  children: PropTypes.node.isRequired,
  viewableByRole: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
  isAllRolesRequired: PropTypes.bool,
};

export default OnlyWithRole;
