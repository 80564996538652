export const cleanSegmentLabelName = (labelName) => {
  switch (labelName) {
    case 'Business Segment Perimeter Security':
      return labelName.replace(labelName, 'BSPS');
    case 'Business Segment Residential':
      return labelName.replace(labelName, 'BSR');
    case 'Business Segment Pedestrian':
      return labelName.replace(labelName, 'BSP');
    case 'Business Segment Industrial':
      return labelName.replace(labelName, 'BSI');
    case 'ESD Central Units':
      return labelName.replace(labelName, 'Central Units');
    default:
      return labelName;
  }
};
