import './PortalSearch.scss';

import { ReactComponent as SearchIcon } from 'components/Icon/Search.svg';
import { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';

class PortalSearch extends Component {
  queryInputRef = createRef();

  handleSubmit = (e) => {
    e.preventDefault();

    const query = this.queryInputRef.current.value;
    if (!query.length) {
      return;
    }

    const portalUrl = this.props.match.params.portalUrl;
    const filters = [`${portalUrl}portal`];

    const searchParams = new URLSearchParams();
    searchParams.set('query', query);
    searchParams.set('filters', JSON.stringify(filters));

    this.props.history.push(`/find?${searchParams.toString()}`);
  };

  render() {
    const portalUrl = this.props.match.params.portalUrl;

    return (
      <form className="portal-search-form" onSubmit={this.handleSubmit}>
        <input
          type="text"
          className="portal-search-form__query"
          placeholder={`Search in ${
            portalUrl !== 'codeofconduct' ? portalUrl : 'Code of Conduct'
          } portal`}
          ref={this.queryInputRef}
        />
        <SearchIcon
          className="portal-search-form__submit"
          onClick={this.handleSubmit}
        />
      </form>
    );
  }
}

export default withRouter(PortalSearch);
