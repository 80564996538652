import './NewSortingControl.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import { ReactComponent as SortDescending } from 'components/Icon/SortDescending.svg';
import { FC, useState } from 'react';

interface SortOption {
  label: string;
  sortType: string;
  sortOrder: string;
}

interface NewSortingControlProps {
  sortOptions: SortOption[];
  selectedSortOption: SortOption;
  handleSortChange: (option: SortOption) => void;
}

const NewSortingControl: FC<NewSortingControlProps> = ({
  sortOptions,
  selectedSortOption,
  handleSortChange,
}) => {
  const [isOptionsShown, setIsOptionsShown] = useState<boolean>(false);

  const hideOptions = () => {
    setIsOptionsShown(false);
  };

  const selectOption = (option: SortOption) => {
    handleSortChange(option);
    hideOptions();
  };

  const isActiveOption = (option: any, selectedSortOption: any) => {
    return classNames({
      active: option.label === selectedSortOption.label,
    });
  };

  return (
    <>
      <div className="new-sorting-control">
        <span
          className="new-sorting-control__order"
          onClick={() => setIsOptionsShown(true)}
        >
          <SortDescending />
          {isOptionsShown && (
            <ClickAwayListener onClickAway={hideOptions}>
              <ul className="new-sorting-control__options">
                {sortOptions.map((option) => (
                  <li
                    key={option.label}
                    onClick={() => selectOption(option)}
                    className={isActiveOption(option, selectedSortOption)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </ClickAwayListener>
          )}
        </span>
        <span className="new-sorting-control__type">
          {selectedSortOption.label}
        </span>
      </div>
    </>
  );
};

export default NewSortingControl;
