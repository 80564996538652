import ImageIcon from 'components/Icon/ImageIcon.svg';
import PlayIcon from 'components/Icon/PlayIcon.svg';

import BackupDocumentImage from '../../pages/Find/components/DocumentItem/DocumentIcon/images/shape.svg';

export const getDocumentIcon = (fileExtension: string) => {
  if (fileExtension === 'PDF') {
    return { icon: 'PDF', color: 'red' };
  }
  if (fileExtension === 'PPTX') {
    return { icon: 'P', color: 'orange' };
  }
  if (fileExtension === 'XLSX') {
    return { icon: 'X', color: 'green' };
  }
  if (fileExtension === 'DOCX') {
    return { icon: 'W', color: 'blue' };
  }
  if (
    fileExtension === 'PNG' ||
    fileExtension === 'SVG' ||
    fileExtension === 'JPEG' ||
    fileExtension === 'APNG' ||
    fileExtension === 'AVIF' ||
    fileExtension === 'GIF' ||
    fileExtension === 'JPG' ||
    fileExtension === 'JFIF' ||
    fileExtension === 'PJPEG' ||
    fileExtension === 'PJP' ||
    fileExtension === 'WEBP' ||
    fileExtension === 'BMP' ||
    fileExtension === 'TIF' ||
    fileExtension === 'TIFF'
  ) {
    return { icon: ImageIcon, color: 'grey' };
  }
  if (
    fileExtension === 'MP4' ||
    fileExtension === 'MOV' ||
    fileExtension === 'WMV' ||
    fileExtension === 'AVI' ||
    fileExtension === 'AVIF' ||
    fileExtension === 'AVCHD' ||
    fileExtension === 'FLV' ||
    fileExtension === 'F4V' ||
    fileExtension === 'SWF' ||
    fileExtension === 'MKV' ||
    fileExtension === 'WEBM' ||
    fileExtension === 'HTML5' ||
    fileExtension === 'MPEG2'
  ) {
    return { icon: PlayIcon, color: 'grey' };
  } else {
    return { icon: BackupDocumentImage, color: 'backup' };
  }
};
