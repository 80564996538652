import { ENTRANCE_SYSTEMS_DIVISION_ID } from 'constants/consents';
import queryString from 'query-string';
import { createContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getEndOfDay, getStartOfDay } from 'utils/misc/time';

const FeedContext = createContext();
const { Consumer: FeedConsumer, Provider } = FeedContext;

const FeedProvider = (props) => {
  const [publishedStartDate, setPublishedStartDate] = useState('');
  const [publishedEndDate, setPublishedEndDate] = useState('');
  const [locale, setLocale] = useState('en');
  const [isAutoTranslateMessageVisible, setIsAutoTranslateMessageVisible] =
    useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const onChangeStartDate = (startDate) => {
    const startofDay = getStartOfDay(startDate);
    setPublishedStartDate(startofDay.toISOString());

    if (publishedStartDate) {
      return;
    }

    setFilterCount(filterCount + 1);
  };

  const onChangeEndDate = (endDate) => {
    const endofDay = getEndOfDay(endDate);
    setPublishedEndDate(endofDay.toISOString());

    if (publishedEndDate) {
      return;
    }

    setFilterCount(filterCount + 1);
  };

  const onChangeLocale = (locale) => {
    setLocale(locale);
  };

  const toggleIsAutoTranslateMessageVisible = (open) => {
    setIsAutoTranslateMessageVisible(open);
  };

  const onFilterUpdated = ({ type, selected, increaseCounter = true }) => {
    const query = queryString.parse(props.location.search);
    let newState;
    let newLocalization;
    const localizationFields = [
      'global',
      'country',
      'division',
      'segment',
      'department',
      'site',
      'company',
    ];
    const queryLocalizationIsValidType = localizationFields.includes(type);
    const queryHasLocalizations = Array.isArray(query.localization);
    const getLocalizationWithTypeRemoved = (localization, removeType) =>
      queryLocalizationIsValidType
        ? queryHasLocalizations
          ? localization.filter((key) => key !== removeType)
          : []
        : localization;
    const getLocalizationWithAddedType = (localization, addType) =>
      queryLocalizationIsValidType
        ? queryHasLocalizations
          ? localization.concat(addType)
          : [localization, addType]
        : localization;
    if (selected) {
      newState = localizationFields.includes(type) ? query.type : [];
      newLocalization = getLocalizationWithTypeRemoved(
        query.localization,
        type
      );
      setFilterCount(filterCount - 1);
    } else {
      if (type !== 'all') {
        newState = localizationFields.includes(type) ? query.type : type;
      }
      newLocalization = getLocalizationWithAddedType(query.localization, type);
      if (newLocalization && increaseCounter) {
        setFilterCount(filterCount + 1);
      }
    }
    const pathName = props.history.location.pathname;

    props.history.replace(
      `${pathName}?${queryString.stringify({
        type: newState,
        localization: newLocalization,
      })}`
    );
  };

  const resetFilters = ({ includeType = false }) => {
    const query = queryString.parse(props.location.search);
    query.localization = undefined;
    query.type = includeType ? undefined : query.type;
    props.history.replace(`/feed?${queryString.stringify(query)}`);
    setPublishedStartDate('');
    setPublishedEndDate('');
    setFilterCount(0);
  };

  const setPagination = (p) => {
    const query = queryString.parse(props.location.search);
    const pathName = props.history.location.pathname;

    props.history.replace(
      `${pathName}?${queryString.stringify({ ...query, pagination: p })}`
    );
  };

  const getUrlState = () => {
    const {
      location,
      divisionId,
      segmentId,
      countryId,
      departmentId,
      siteId,
      companyId,
    } = props;
    const query = queryString.parse(location.search);
    query.type = query.type ? query.type : [];
    query.localization = query.localization ? query.localization : [];
    const includeTopNewsOnly = query.type.includes('topItem');
    const commonFilter = {
      topNewsOnly: includeTopNewsOnly,
      news: query.type.includes('news') || includeTopNewsOnly,
      press: query.type.includes('press'),
      blog: query.type.includes('blog') || includeTopNewsOnly,
      story: query.type.includes('story') || includeTopNewsOnly,
      divisionId,
      segmentId:
        divisionId === ENTRANCE_SYSTEMS_DIVISION_ID ? segmentId : undefined,
      countryId,
      departmentId,
      siteId,
      companyId,
      locale,
      onlyGlobalItems: query.localization.includes('global'),
      onlyMatchingDivision: query.localization.includes('division'),
      onlyMatchingSegment:
        divisionId === ENTRANCE_SYSTEMS_DIVISION_ID &&
        query.localization.includes('segment'),
      onlyMatchingCountry: query.localization.includes('country'),
      onlyMatchingDepartment: query.localization.includes('department'),
      onlyMatchingSite: query.localization.includes('site'),
      onlyMatchingCompany: query.localization.includes('company'),
      publishedStartDate: publishedStartDate.toString(),
      publishedEndDate: publishedEndDate.toString(),
    };
    if (query.type && !query.localization) {
      return {
        active: Array.isArray(query.type) ? query.type : [query.type],
        disableReset: true,
        filter: {
          ...commonFilter,
          onlyGlobalItems: false,
          onlyMatchingDivision: false,
          onlyMatchingSegment: false,
          onlyMatchingCountry: false,
          onlyMatchingDepartment: false,
          onlyMatchingSite: false,
          onlyMatchingCompany: false,
        },
        pagination: query.pagination,
      };
    } else if (!query.type && query.localization) {
      return {
        active: Array.isArray(query.localization)
          ? query.localization
          : [query.localization],
        disableReset: false,
        filter: {
          ...commonFilter,
          news: true,
          press: true,
          blog: true,
          story: true,
        },
        pagination: query.pagination,
      };
    } else if (query.type && query.localization) {
      return {
        active: [].concat(query.type).concat(query.localization),
        disableReset: false,
        filter: commonFilter,
        pagination: query.pagination,
      };
    } else {
      const disableReset =
        (publishedStartDate === undefined || publishedStartDate === '') &&
        (publishedEndDate === undefined || publishedEndDate === '');
      return {
        active: [],
        disableReset,
        pagination: query.pagination,
        filter: {
          ...commonFilter,
          news: true,
          press: true,
          blog: true,
          story: true,
          onlyGlobalItems: true,
          onlyMatchingDivision: false,
          onlyMatchingSegment: false,
          onlyMatchingCountry: false,
          onlyMatchingDepartment: false,
          onlyMatchingSite: false,
          onlyMatchingCompany: false,
        },
      };
    }
  };

  return (
    <>
      <Provider
        value={{
          ...getUrlState(),
          ...props,
          startDate: publishedStartDate,
          endDate: publishedEndDate,
          filterCount,
          onFilterUpdated,
          setPagination,
          resetFilters,
          onChangeStartDate,
          onChangeEndDate,
          onChangeLocale,
          toggleIsAutoTranslateMessageVisible,
          isAutoTranslateMessageVisible,
        }}
      >
        {props.children}
      </Provider>
    </>
  );
};

export default withRouter(FeedProvider);
export { FeedConsumer };
export { FeedContext };
