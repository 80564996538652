import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const MyMomentSkeleton = () => (
  <>
    <Box
      sx={(theme) => ({
        height: '100%',
        maxWidth: '100%',
        width: '1140px',
        margin: '0 auto',
        padding: { xs: theme.spacing('sm'), md: theme.spacing('md') },
      })}
    >
      <Stack
        sx={(theme) => ({
          padding: theme.spacing('md'),
          gap: theme.spacing('md'),
          backgroundColor: theme.colors.surface.primary,
          boxShadow: theme.elevation.sm,
        })}
      >
        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '4rem',
            maxWidth: '20rem',
          }}
        />
        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '26rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />
        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '4rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />

        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            margin: '5rem  0 0 0',
            height: '3rem',
            maxWidth: '15rem',
          }}
        />

        <Skeleton
          variant="rounded"
          animation="pulse"
          sx={{
            height: '2rem',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />
      </Stack>
    </Box>
  </>
);

export { MyMomentSkeleton };
