import makeQueryString from 'utils/misc/makeQueryString';

class PageHandler {
  constructor({
    limit = 10,
    initialPages = 1,
    offset = 0,
    total = 0,
    totalChildren = 0,
    totalPublished = 0,
    loadedItems = 0,
  }) {
    this.limit = limit;
    this.initialPages = initialPages;
    this.offset = offset;
    this.total = total;
    this.totalChildren = totalChildren;
    this.totalPublished = totalPublished;
    this.loadedItems = loadedItems;
  }

  getQueryParams() {
    // intial load
    if (this.offset === 0) {
      return { limit: this.limit * this.initialPages };
    }
    return { limit: this.limit, offset: this.offset };
  }

  getQuery() {
    const queryParams = this.getQueryParams();

    return makeQueryString({ page: queryParams });
  }

  createFromResponse(response) {
    const {
      meta: {
        page: { limit, offset, total, totalPublished, totalChildren },
      },
    } = response;

    const updatedOffset = (offset + 1) * (limit / this.limit);

    return new PageHandler({
      limit: this.limit,
      initialPages: this.initialPages,
      offset: updatedOffset,
      total: total,
      totalChildren,
      totalPublished,
      loadedItems: Math.min(total, this.limit * updatedOffset),
    });
  }

  getLoadMoreCount() {
    // no data has been loaded, so the count is unknown, return the limit
    if (this.offset === 0) {
      return this.limit * this.initialPages;
    }

    const remaining = this.total - this.totalChildren - this.loadedItems;

    // if more than limit is remaining return limit so load more buttons
    // show correct amount
    if (remaining > this.limit) {
      return this.limit;
    }

    return remaining;
  }

  hasMore() {
    return this.getLoadMoreCount() > 0;
  }
}

export default PageHandler;
