import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Consumer } from 'components/CreateOrEditArticle';
import { UserSearchSelect } from 'componentsNew';
import { translations } from 'translations';

const AdditionalFeedbackReceivers = () => {
  return (
    <Consumer>
      {({ onChangeArticle, article, updatedArticle }) => {
        return (
          <Stack maxWidth="42.5rem" gap="0.5rem">
            <Typography variant="body1" fontWeight="bold">
              {translations.additionalFeedbackReceiversHeaderText}
            </Typography>
            <Typography variant="body2" marginBottom="0.5rem">
              {translations.additionalFeedbackReceiversInfoText}
            </Typography>
            <UserSearchSelect
              multiple
              limit={5}
              selectedUsers={
                updatedArticle.additionalFeedbackReceivers
                  ? updatedArticle.additionalFeedbackReceivers
                  : article.additionalFeedbackReceivers || []
              }
              onChange={(users) => {
                onChangeArticle({ additionalFeedbackReceivers: users });
              }}
            />
          </Stack>
        );
      }}
    </Consumer>
  );
};

export { AdditionalFeedbackReceivers };
