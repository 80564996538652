import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';
import { useMemo } from 'react';

type TooltipProps = {
  title: string;
  text?: string;
  size?: 'small' | 'large';
  color?: 'primary' | 'inversePrimary';
} & Omit<MuiTooltipProps, 'title'>;

const Tooltip = ({
  title,
  text,
  size = 'small',
  color = 'primary',
  ...muiTooltipProps
}: TooltipProps) => {
  const theme = useTheme();

  const colorSettings: {
    titleColor: string;
    textColor: string;
    backgroundColor: string;
  } = useMemo(() => {
    switch (color) {
      case 'inversePrimary':
        return {
          titleColor: theme.colors.text.inversePrimary,
          textColor: theme.colors.text.inverseTertiary,
          backgroundColor: theme.colors.surface.inversePrimary,
        };
      default:
        return {
          titleColor: theme.colors.text.primary,
          textColor: theme.colors.text.tertiary,
          backgroundColor: theme.colors.surface.primary,
        };
    }
  }, [color, theme.colors]);

  const sizeSettings: {
    padding: string;
    typographyVariant: TypographyTypeMap['props']['variant'];
    typographyGap: string;
  } = useMemo(() => {
    switch (size) {
      case 'large':
        return {
          padding: `${theme.spacing('xs')} ${theme.spacing('sm')}`,
          typographyVariant: 'body2',
          typographyGap: theme.spacing('xxxs'),
        };
      default:
        return {
          padding: `${theme.spacing('xxxs')} ${theme.spacing('xxs')}`,
          typographyVariant: 'caption',
          typographyGap: theme.spacing('xxxxs'),
        };
    }
  }, [size, theme]);

  return (
    <MuiTooltip
      title={
        <Stack flexDirection="column" gap={sizeSettings.typographyGap}>
          <Typography
            variant={sizeSettings.typographyVariant}
            sx={{
              color: colorSettings.titleColor,
              fontWeight: Boolean(text) ? 600 : 400,
            }}
          >
            {title}
          </Typography>
          {text && (
            <Typography
              variant={sizeSettings.typographyVariant}
              sx={{ color: colorSettings.textColor }}
            >
              {text}
            </Typography>
          )}
        </Stack>
      }
      slotProps={{
        tooltip: {
          sx: (theme) => ({
            color: colorSettings.titleColor,
            backgroundColor: colorSettings.backgroundColor,
            borderRadius: theme.border.radius.sm,
            boxShadow: theme.elevation.sm,
            padding: sizeSettings.padding,
          }),
        },
        arrow: {
          sx: () => ({
            color: colorSettings.backgroundColor,
          }),
        },
      }}
      arrow={true}
      describeChild={true}
      placement="top"
      {...muiTooltipProps}
    >
      {muiTooltipProps.children}
    </MuiTooltip>
  );
};

export { Tooltip };
