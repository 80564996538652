import './NotFound.scss';

import Box from 'components/Box';
import { Container } from 'components/Grid';
import Img from 'components/Image';
import PageTitle from 'components/PageTitle';

import notFoundImage from './avenue-sign-broken.png';

const BlockedUser = () => {
  return (
    <Container>
      <Box className="not-found__content">
        <PageTitle titles={['Page not found']} />
        <div className="not-found__wrapper">
          <h1>Oh no!</h1>
          <h3>The page you are trying to reach does not exist...</h3>
          <div className="not-found__image">
            <Img
              alt="The page you're trying to reach does not exist."
              src={notFoundImage}
              animate={true}
            />
          </div>
          <p>
            Please use the navigation above to go to another page on Avenue or
            use the search in the top right corner to find what you are looking
            for.
          </p>
        </div>
      </Box>
    </Container>
  );
};

export default BlockedUser;
