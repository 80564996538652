import './Feed.scss';

import Box from 'components/Box';

interface BoxHeaderProps {
  title?: string;
  description?: string;
  isGlobalFeed?: boolean;
}

const BoxHeader = ({ title, description, isGlobalFeed }: BoxHeaderProps) => {
  return (
    <>
      <Box className="p-t-8 p-b-5 b-b-2 m-b-2">
        {title && <p className="header-title">{title}</p>}
        {description && (
          <p
            className={`header-description${
              isGlobalFeed ? '__global-feed' : ''
            }`}
          >
            {description}
          </p>
        )}
      </Box>
    </>
  );
};

export default BoxHeader;
