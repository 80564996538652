import Checkbox from 'components/Checkbox';
import { Consumer } from 'components/CreateOrEditArticle';
import LinkButtonText from 'components/CreateOrEditArticle/components/LinkButtonText';
import LinkUrl from 'components/CreateOrEditArticle/components/LinkUrl';
import MaxArticleCount from 'components/CreateOrEditArticle/components/MaxArticleCount';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import Title from 'components/CreateOrEditArticle/components/Title';
import Flex from 'components/Flex';

const EventsListBlockEditor = () => {
  return (
    <Consumer>
      {({
        validationParameters: {
          title: { maxLength },
        },
        onToggleArticleValue,
        article: { showAllEvents },
      }) => {
        const titlePlaceholder = `Title (max ${maxLength} characters)`;
        return (
          <>
            <Title placeholder={titlePlaceholder} />
            <MaxArticleCount />
            <Flex className="m-b-4">
              <Checkbox
                defaultChecked={showAllEvents}
                id="show-all-events"
                onChange={() => onToggleArticleValue('showAllEvents')}
              />
              <label htmlFor="disableComments" className="m-l-3">
                Show all events.
              </label>
            </Flex>
            <LinkButtonText placeholder="Link Button Text" />
            <LinkUrl placeholder="Link URL" />
            <SortIndex />
          </>
        );
      }}
    </Consumer>
  );
};

export default EventsListBlockEditor;
