import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { news } = settings.api;

export const getNewsFlashes = (query = '') =>
  createRequest({
    method: 'get',
    url: `${news}/news-flashes${query}`,
    errorMessages: {
      '*': 'Failed to get news, please contact support for assistance.',
    },
  });

export const getPressArticle = (id) =>
  createRequest({
    method: 'get',
    url: `${news}/press-releases/${id}`,
    errorMessages: {
      '*': 'Failed to get press release, please contact support for assistance.',
    },
  });
