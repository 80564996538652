import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import * as cmsContentManagementApi from 'api/cms/contentManagement';
import { GetContentManagementAlertsResponse } from 'api/cms/contentManagement/types';
import { GLOBAL_DIVISION_ID } from 'api/organization/transformers';
import { useUser } from 'components/Context/User';
import { TablePagination } from 'componentsNew';
import { AvenueRoute } from 'constants/routes';
import { useSnackbar } from 'contextNew/Snackbar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';

import * as helpers from './helpers';
import { ManageAlertsActions } from './ManageAlertsActions';
import {
  ManageAlertsTable,
  ManageAlertsTableField,
  ManageAlertsTableItem,
  SortOrder,
} from './ManageAlertsTable';

const elementId = 'profile-manage-alerts';

const INITIAL_SORT = {
  field: ManageAlertsTableField.StartDate,
  order: SortOrder.Desc,
};
const INITIAL_PAGINATION = {
  page: 1,
  rowsPerPage: 10,
};
const INITIAL_SELECTION = {
  byId: {},
  isAllSelected: false,
  isSomeSelected: false,
};

const ManageAlerts = () => {
  const [sort, setSort] = useState<{
    field: ManageAlertsTableField;
    order: SortOrder;
  }>(INITIAL_SORT);

  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>(INITIAL_PAGINATION);

  const [selection, setSelection] = useState<{
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  }>(INITIAL_SELECTION);

  const [tableContent, setTableContent] = useState<{
    items: ManageAlertsTableItem[];
    total: number;
  }>({ items: [], total: 0 });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUser();
  const { showSnackbar } = useSnackbar();

  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    let items: ManageAlertsTableItem[] = [];
    let total = 0;

    try {
      const response =
        (await cmsContentManagementApi.getAlerts()) as GetContentManagementAlertsResponse;

      total = response.data.data.length;
      items = response.data.data.map((alert) => {
        const item: ManageAlertsTableItem = {
          id: alert.id,
          title: alert.title,
          notification: alert.notification,
          startDate: alert.startDate,
          endDate: alert.endDate,
          divisions: (alert.publishedInDivisions || [])
            .filter((division) => division.id !== GLOBAL_DIVISION_ID)
            .map((division) => division.name)
            .join(', '),
          ...(alert.publishedInCountries && {
            countries: alert.publishedInCountries
              .map((country) => country.name)
              .join(', '),
          }),
        };
        return item;
      });
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.manageContentFetchError,
      });
    } finally {
      setIsLoading(false);
      return { items, total };
    }
  }, [showSnackbar]);

  const handleSortChange = useCallback(
    (newSort: { field: ManageAlertsTableField; order: SortOrder }) => {
      setSort(newSort);
      if (selection.isSomeSelected) {
        setSelection(INITIAL_SELECTION);
      }
      if (pagination.page !== 1) {
        setPagination({ ...pagination, page: 1 });
      }
    },
    [selection.isSomeSelected, pagination]
  );

  const handlePaginationChange = useCallback(
    (newPagination: { page: number; rowsPerPage: number }) => {
      setPagination(newPagination);
      if (selection.isSomeSelected) {
        setSelection(INITIAL_SELECTION);
      }
    },
    [selection.isSomeSelected]
  );

  const handleActionsComplete = useCallback(
    async (result: { message: string; isSuccess: boolean }) => {
      showSnackbar({
        type: result.isSuccess ? 'success' : 'error',
        text: result.message,
      });
      const newTableContent = await fetchItems();
      setTableContent(newTableContent);
      setSelection(INITIAL_SELECTION);
      setPagination(INITIAL_PAGINATION);
    },
    [fetchItems, showSnackbar]
  );

  const handleActionsCancel = useCallback(
    () => setSelection(INITIAL_SELECTION),
    []
  );

  const selectedIds = useMemo(() => {
    return Object.keys(selection.byId).filter((id) =>
      Boolean(selection.byId[id])
    );
  }, [selection.byId]);

  const visibleTableItems = useMemo(() => {
    const sorted = helpers.sortTableItems(
      tableContent.items,
      sort.field,
      sort.order
    );
    const paginated = sorted.slice(
      (pagination.page - 1) * pagination.rowsPerPage,
      pagination.page * pagination.rowsPerPage
    );
    return paginated;
  }, [
    pagination.page,
    pagination.rowsPerPage,
    sort.field,
    sort.order,
    tableContent.items,
  ]);

  useEffect(() => {
    async function fetchInitialItems() {
      const newTableContent = await fetchItems();
      setTableContent(newTableContent);
    }
    fetchInitialItems();
  }, [fetchItems]);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
        })}
      >
        <Button
          variant="linkButton"
          component={ReactRouterLink}
          to={AvenueRoute.AlertCreate}
        >
          {translations.manageContentCreateAlert}
        </Button>
      </Stack>
      <TransitionGroup>
        {!isLoading && !user.isLoading && selection.isSomeSelected && (
          <Collapse>
            <ManageAlertsActions
              selectedIds={selectedIds}
              onCancel={handleActionsCancel}
              onComplete={handleActionsComplete}
              sx={(theme) => ({
                borderTopLeftRadius: theme.border.radius.md,
                borderTopRightRadius: theme.border.radius.md,
              })}
            />
          </Collapse>
        )}
      </TransitionGroup>
      <ManageAlertsTable
        isLoading={isLoading}
        items={visibleTableItems}
        sort={sort}
        selection={selection}
        onSortChange={handleSortChange}
        onSelectionChange={setSelection}
        {...(!selection.isSomeSelected && {
          sx: (theme) => ({
            borderTopLeftRadius: theme.border.radius.md,
            borderTopRightRadius: theme.border.radius.md,
          }),
        })}
      />
      {tableContent.total > INITIAL_PAGINATION.rowsPerPage && (
        <TablePagination
          disabled={isLoading}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={
            tableContent.total % pagination.rowsPerPage > 0
              ? Math.trunc(tableContent.total / pagination.rowsPerPage) + 1
              : tableContent.total / pagination.rowsPerPage
          }
          onPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: pagination.rowsPerPage,
              page: value,
            })
          }
          onRowsPerPageChange={(value: number) =>
            handlePaginationChange({
              rowsPerPage: value,
              page: 1,
            })
          }
        />
      )}
    </Stack>
  );
};

export { ManageAlerts };
