import TagOrChannelSelector from 'components/TagOrChannelSelector';

import { Consumer } from '..';

const onChange =
  (onChangeArticle) =>
  ({ isChannel, originalName }) => {
    if (isChannel) {
      onChangeArticle({ channel: originalName, tag: null });
    } else {
      onChangeArticle({ channel: null, tag: originalName });
    }
  };

const TagOrChannel = () => {
  return (
    <Consumer>
      {({ onChangeArticle, article: { tag, channel } }) => {
        return (
          <>
            <p>Select tag or channel</p>
            <TagOrChannelSelector
              onChange={onChange(onChangeArticle)}
              defaultSelectedTag={tag}
              defaultSelectedChannel={channel}
              multiple={false}
            />
            <hr className="m-t-6 m-b-6" />
          </>
        );
      }}
    </Consumer>
  );
};

export default TagOrChannel;
