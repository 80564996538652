import {
  maxLengthValidator,
  vivaEngageValidator,
} from 'components/CreateOrEditArticle/validators';
import {
  articleListBlockValidator,
  customBlockFieldTransform,
  customBlockFieldValidator,
} from 'pages/Portals/TemplateBlockEditors/CustomBlockEditor/validateAndTransformFields';

export const getFields = (template, defaultInformationOwner) => {
  switch (template) {
    case 'heroblock':
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 90,
        },
        {
          key: 'preamble',
          fieldValidator: maxLengthValidator(99999),
          maxLength: 99999,
        },
        { key: 'heroImage' },
        { key: 'heroAltText' },
        { key: 'heroImageWidth' },
        { key: 'linkUrl', maxLength: 999 },
        { key: 'reverse' },
        { key: 'isImageFullyVisible' },
        { key: 'textColor', maxLength: 90 },
        { key: 'backgroundColor', maxLength: 90 },
        { key: 'body' },
        { key: 'sharepointFolderName', defaultValue: null },
        { key: 'portalType' },
        { key: 'sortIndex', defaultValue: 50 },
        { key: 'template', defaultValue: template },
        { key: 'linkButtonText', maxLength: 25 },
        { key: 'displayAsInfographic' },
      ];
    case 'bodyblock':
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 90,
        },
        {
          key: 'preamble',
          fieldValidator: maxLengthValidator(99999),
          maxLength: 99999,
        },
        { key: 'textColor', maxLength: 90 },
        { key: 'backgroundColor', maxLength: 90 },
        { key: 'body' },
        { key: 'portalType' },
        { key: 'sortIndex', defaultValue: 50 },
        { key: 'template', defaultValue: template },
      ];
    case 'dividerblock':
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 200,
        },
        { key: 'backgroundColor', maxLength: 90 },
        { key: 'body' },
        { key: 'portalType' },
        { key: 'sortIndex', defaultValue: 50 },
        { key: 'template', defaultValue: template },
      ];
    case 'customblock':
      return [
        { key: 'portalType' },
        { key: 'title' },
        {
          key: 'displayLeft',
          validator: customBlockFieldValidator('left'),
        },
        {
          key: 'displayRight',
          validator: customBlockFieldValidator('right'),
        },
        { key: 'titleLeft', displayName: 'Left title', maxLength: 200 },
        {
          key: 'preambleLeft',
          displayName: 'Left preamble',
          fieldValidator: maxLengthValidator(99999),
          maxLength: 99999,
        },
        { key: 'bodyLeft', displayName: 'Left body' },
        { key: 'heroVideoLeft', displayName: 'Video for left side' },
        { key: 'heroImageLeft', displayName: 'Image for left side' },
        { key: 'heroImageWidthLeft' },
        { key: 'linkButtonTextLeft', maxLength: 25 },
        { key: 'linkUrlLeft', maxLength: 999 },
        { key: 'backgroundColorLeft', maxLength: 90 },
        { key: 'textColorLeft', maxLength: 90 },
        { key: 'isImageFullyVisibleLeft' },
        { key: 'titleRight', displayName: 'Right title', maxLength: 200 },
        {
          key: 'preambleRight',
          displayName: 'Right preamble',
          fieldValidator: maxLengthValidator(99999),
          maxLength: 99999,
        },
        { key: 'bodyRight', displayName: 'Right body' },
        { key: 'heroVideoRight', displayName: 'Video for right side' },
        { key: 'heroImageRight', displayName: 'Image for right side' },
        { key: 'heroImageWidthRight' },
        { key: 'linkButtonTextRight', maxLength: 25 },
        { key: 'linkUrlRight', maxLength: 999 },
        { key: 'backgroundColorRight', maxLength: 90 },
        { key: 'textColorRight', maxLength: 90 },
        { key: 'isImageFullyVisibleRight' },
        { key: 'reverse' },
        { key: 'sortIndex', defaultValue: 50 },
        {
          key: 'template',
          defaultValue: template,
          transform: customBlockFieldTransform,
        },
      ];
    case 'article-list-block':
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 200,
        },
        {
          key: 'tag',
          validator: articleListBlockValidator,
          displayName: 'Tag',
        },
        { key: 'channel', displayName: 'Channel' },
        { key: 'maxListedCount', required: true, defaultValue: 4 },
        { key: 'portalType' },
        { key: 'linkUrl', maxLength: 999 },
        { key: 'linkButtonText', maxLength: 25 },
        { key: 'sortIndex', defaultValue: 50 },
        { key: 'template', defaultValue: template },
      ];
    case 'insights-listing':
      return [
        { key: 'title', required: true, displayName: 'Title', maxLength: 90 },
        {
          key: 'preamble',
          fieldValidator: maxLengthValidator(99999),
          maxLength: 99999,
        },
        { key: 'portalType' },
        { key: 'sortIndex', defaultValue: 50 },
        {
          key: 'informationOwner',
          defaultValue: defaultInformationOwner,
          required: true,
          displayName: 'Information owner',
        },
        { key: 'template', defaultValue: template },
        { key: 'hidePageFromNavigation' },
      ];
    case 'insights-content':
      return [
        { key: 'title', required: true, displayName: 'Title', maxLength: 90 },
        {
          key: 'preamble',
          fieldValidator: maxLengthValidator(99999),
          maxLength: 99999,
        },
        { key: 'body' },
        { key: 'sharepointFolderName', defaultValue: null },
        { key: 'portalType' },
        { key: 'sortIndex', defaultValue: 50 },
        {
          key: 'informationOwner',
          defaultValue: defaultInformationOwner,
          required: true,
          displayName: 'Information owner',
        },
        { key: 'heroImage' },
        { key: 'heroAltText' },
        { key: 'heroImageWidth' },
        { key: 'template', defaultValue: template },
        { key: 'types', required: true, displayName: 'Types' },
        { key: 'verticalMarkets' },
        { key: 'productGroups' },
        { key: 'geographicMarkets' },
        { key: 'topics' },
        { key: 'targetGroups' },
        { key: 'keywords' },
        { key: 'languages', required: true, displayName: 'Languages' },
        { key: 'publishedDate', defaultValue: new Date().toISOString() },
        { key: 'isDraft', defaultValue: false },
        { key: 'disableComments', defaultValue: false },
        { key: 'displayOnHome', defaultValue: true },
        { key: 'source', maxLength: 99999 },
        { key: 'hidePageFromNavigation' },
      ];
    case 'gradientblock':
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 90,
        },
        { key: 'body' },
        { key: 'heroImage' },
        { key: 'heroAltText' },
        { key: 'heroImageWidth' },
        { key: 'linkUrl', maxLength: 999 },
        { key: 'linkButtonText', maxLength: 25 },
        { key: 'sortIndex', defaultValue: 50 },
        { key: 'template', defaultValue: template },
      ];
    case 'events-list-block':
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 90,
        },
        { key: 'maxListedCount', required: true, defaultValue: 4 },
        { key: 'showAllEvents', required: false, defaultValue: false },
        { key: 'portalType' },
        { key: 'linkUrl', maxLength: 999 },
        { key: 'linkButtonText', maxLength: 25 },
        { key: 'sortIndex', defaultValue: 50 },
        { key: 'template', defaultValue: template },
      ];
    case 'avenue-feed-listing-block':
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 200,
        },
        { key: 'channel', displayName: 'Topic', required: true },
        { key: 'maxListedCount', required: true, defaultValue: 4 },
        { key: 'portalType' },
        { key: 'linkUrl', maxLength: 999 },
        { key: 'linkButtonText', maxLength: 25 },
        { key: 'sortIndex', defaultValue: 50 },
        { key: 'template', defaultValue: template },
      ];
    case 'viva-engage-embed-block':
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 200,
        },
        { key: 'body' },
        { key: 'portalType' },
        {
          key: 'linkUrl',
          fieldValidator: vivaEngageValidator,
          maxLength: 999,
        },
        { key: 'sortIndex', defaultValue: 50 },
        { key: 'template', defaultValue: template },
      ];
    default:
      return [
        {
          key: 'title',
          required: true,
          displayName: 'Title',
          maxLength: 90,
        },
        {
          key: 'preamble',
          fieldValidator: maxLengthValidator(99999),
          maxLength: 99999,
        },
        { key: 'body' },
        { key: 'sharepointFolderName', defaultValue: null },
        { key: 'portalType' },
        { key: 'sortIndex', defaultValue: 50 },
        {
          key: 'informationOwner',
          defaultValue: defaultInformationOwner,
          required: true,
          displayName: 'Information owner',
        },
        { key: 'heroImage' },
        { key: 'heroAltText' },
        { key: 'heroImageWidth' },
        { key: 'template', defaultValue: template },
        { key: 'hidePageFromNavigation' },
        { key: 'tags' },
        { key: 'useCompactChildPageListing' },
        { key: 'insightsDatabaseTopic' },
        { key: 'infoHighlightBox' },
      ];
  }
};
