import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material/Checkbox';

import { ReactComponent as CheckedIcon } from './Checked.svg';
import { ReactComponent as IndeterminateIcon } from './Indeterminate.svg';
import { ReactComponent as UncheckedIcon } from './Unchecked.svg';

type CheckboxProps = MuiCheckboxProps;

const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      indeterminateIcon={<IndeterminateIcon />}
      {...props}
    />
  );
};

export { Checkbox };
