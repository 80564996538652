import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

export const getShareArticleEmailLink = (args: {
  title: string;
  link: string;
  senderName: string;
}) => {
  const subject = textUtils.replaceTranslationAliases(
    translations.shareEmailTemplateArticleSubject,
    { title: args.title }
  );

  const body = textUtils.replaceTranslationAliases(
    translations.shareEmailTemplateArticleBody,
    { link: args.link, name: args.senderName }
  );

  return (
    `mailto:?subject=${encodeURIComponent(subject)}` +
    `&body=${encodeURIComponent(body)}`
  );
};

export const getShareJobEmailLink = (args: {
  title: string;
  link: string;
  senderName: string;
}) => {
  const subject = textUtils.replaceTranslationAliases(
    translations.shareEmailTemplateJobSubject,
    { title: args.title }
  );

  const body = textUtils.replaceTranslationAliases(
    translations.shareEmailTemplateJobBody,
    { link: args.link, name: args.senderName }
  );

  return (
    `mailto:?subject=${encodeURIComponent(subject)}` +
    `&body=${encodeURIComponent(body)}`
  );
};
