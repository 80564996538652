import classNames from 'classnames';
import Button from 'components/Button';
import { Tooltip } from 'componentsNew';

import { getPublishButtonTooltipText } from './PublishButton';

const SaveAsDraftButton = ({
  countries,
  divisions,
  segments,
  sites,
  departments,
  companies,
  onSaveArticle,
  buttonStyle,
}) => {
  const saveAsDraftButton = classNames('sm-m-b-2', {
    'article-button--wrapper--saveasdraft': buttonStyle,
  });

  const tooltipText = getPublishButtonTooltipText(
    divisions,
    segments,
    countries,
    departments,
    sites,
    companies
  );

  return (
    <Tooltip
      title="Your article will be published here:"
      text={tooltipText}
      size="large"
    >
      <Button
        className={saveAsDraftButton}
        onClick={onSaveArticle}
        align="right"
      >
        Save as a draft
      </Button>
    </Tooltip>
  );
};

export default SaveAsDraftButton;
