import './Carousel.scss';

import classNames from 'classnames';
import { ReactComponent as Arrow } from 'components/Icon/ThinArrow.svg';
import PropTypes from 'prop-types';
import { Children, Component } from 'react';

const renderChild = (i, x) => x === i || x === i + 1 || x === i - 1;

class Carousel extends Component {
  state = {
    currentIndex: this.props.defaultIndex,
    itemCount: Children.count(this.props.children),
  };

  visited = [this.props.currentIndex || this.props.defaultIndex];

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    this.carousel.addEventListener('touchstart', this.handleTouchStart, false);
    this.carousel.addEventListener('touchend', this.handleTouchEnd, false);
  }

  componentDidUpdate = () => {
    const { currentIndex } = this.isControlled() ? this.props : this.state;

    if (this.visited.includes(currentIndex) === false) {
      this.visited = [...this.visited, currentIndex];
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.carousel.removeEventListener(
      'touchstart',
      this.handleTouchStart,
      false
    );
    this.carousel.removeEventListener('touchend', this.handleTouchEnd, false);
  }

  handleTouchStart = (event) => {
    this.touchstartX = event.changedTouches[0].screenX;
  };

  handleTouchEnd = (event) => {
    this.touchendX = event.changedTouches[0].screenX;

    const { itemCount } = this.state;
    const { currentIndex } = this.isControlled() ? this.props : this.state;

    if (this.touchendX < this.touchstartX && currentIndex + 1 !== itemCount) {
      this.goTo(currentIndex + 1);
    }

    if (this.touchendX > this.touchstartX && currentIndex !== 0) {
      this.goTo(currentIndex - 1);
    }
  };

  handleKeyDown = (event) => {
    const { itemCount } = this.state;
    const { currentIndex } = this.isControlled() ? this.props : this.state;

    if (event.keyCode === 37 && currentIndex !== 0) {
      this.goTo(currentIndex - 1);
    }

    if (event.keyCode === 39 && currentIndex + 1 !== itemCount) {
      this.goTo(currentIndex + 1);
    }
  };

  isControlled = () => this.props.currentIndex != null;

  renderControls = () => {
    const { itemCount } = this.state;
    const { currentIndex } = this.isControlled() ? this.props : this.state;

    return (
      <div className="carousel__controls">
        <button
          disabled={currentIndex === 0}
          className="carousel__control carousel__control--prev"
          onClick={() => this.goTo(currentIndex - 1)}
        >
          <Arrow />
        </button>
        <button
          disabled={currentIndex + 1 === itemCount}
          className="carousel__control carousel__control--next"
          onClick={() => this.goTo(currentIndex + 1)}
        >
          <Arrow />
        </button>
      </div>
    );
  };

  renderLegend = () => {
    const { itemCount } = this.state;
    const { currentIndex } = this.isControlled() ? this.props : this.state;

    const legendClassName = (i) =>
      classNames('carousel__legend-item', {
        'carousel__legend-item--prev': i < currentIndex,
        'carousel__legend-item--current': currentIndex === i,
        'carousel__legend-item--next': currentIndex < i,
        'carousel__legend-item--visited': this.visited.includes(i),
      });

    return (
      <div className="carousel__legend">
        {[...Array(itemCount)].map((_, i) => (
          <button
            className={legendClassName(i)}
            onClick={() => {
              if (currentIndex >= i || this.visited.includes(i)) {
                this.goTo(i);
              }
            }}
            key={i}
          />
        ))}
      </div>
    );
  };

  goTo = (newIndex) => {
    if (this.isControlled()) {
      this.callbacks(newIndex);
    } else {
      this.setState(
        {
          currentIndex: newIndex,
        },
        () => this.callbacks(newIndex)
      );
    }
  };

  callbacks = (newIndex) => {
    if (this.props.onChange) {
      this.props.onChange(newIndex);
    }
    if (this.props.onEnd) {
      if (newIndex + 1 === this.state.itemCount) {
        this.props.onEnd();
      }
    }
  };

  render() {
    const { itemCount } = this.state;
    const { useLegend, useButtons, children, className } = this.props;
    const { currentIndex } = this.isControlled() ? this.props : this.state;

    const carouselClassName = classNames('carousel', {
      [className]: className,
    });

    return (
      <div
        className={carouselClassName}
        ref={(carousel) => {
          this.carousel = carousel;
        }}
      >
        <div className="carousel__stage">
          <div
            className="carousel__items"
            style={{
              width: `${itemCount * 100}%`,
              transform: `translateX(-${(currentIndex / itemCount) * 100}%)`,
            }}
          >
            {children.map((child, i) => (
              <div
                className="carousel__item"
                key={i}
                style={{ width: `${100 / itemCount}%` }}
              >
                <div className="carousel__item-inner">
                  {renderChild(i, currentIndex) ? child : null}
                </div>
              </div>
            ))}
          </div>
        </div>
        {useButtons && this.renderControls()}
        {useLegend && this.renderLegend()}
      </div>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  defaultIndex: PropTypes.number.isRequired,
  useLegend: PropTypes.bool,
  useButtons: PropTypes.bool,
  transitionDuration: PropTypes.number,
};

Carousel.defaultProps = {
  defaultIndex: 0,
  useLegend: true,
  useButtons: true,
  transitionDuration: 400,
};

export default Carousel;
