import './AvenueFeedListingBlock.scss';

import { getNewsFlashes } from 'api/news';
import { UserConsumer } from 'components/Context/User';
import EnhancedLink from 'components/EnhancedLink';
import Fetcher from 'components/Fetcher';
import ArticleListLink from 'components/Portal/ArticleListLink';
import { FC, useRef } from 'react';
import { anchorLinkFormatter, jumpToAnchor } from 'utils/misc/jumpToAnchor';
import makeQueryString from 'utils/misc/makeQueryString';

interface AvenueFeedListingBlockProps {
  title: string;
  linkUrl: string;
  linkButtonText: string;
  maxListedCount: number;
  id: string;
  topic: string;
}

const AvenueFeedListingBlock: FC<AvenueFeedListingBlockProps> = ({
  title,
  linkUrl,
  linkButtonText,
  maxListedCount,
  id,
  topic,
}) => {
  const anchorRef = useRef(null);

  const runAchorRef = (node: any) => {
    anchorRef.current = node;
    const { hash } = window.location;
    if (hash.length > 1 && hash.substring(1) === id) {
      jumpToAnchor(anchorRef.current);
    }
  };

  if (!title) {
    return <></>;
  }
  return (
    <UserConsumer>
      {({
        divisionId,
        countryId,
        departmentId,
        segmentId,
        siteId,
        companyId,
      }) => {
        return (
          <Fetcher
            source={getNewsFlashes}
            param={makeQueryString({
              filter: {
                division: divisionId,
                country: countryId,
                department: departmentId,
                segment: segmentId,
                site: siteId,
                company: companyId,
                type: {
                  pressrelease: false,
                  newsarticle: false,
                  blogpost: false,
                  storyarticle: false,
                },
                localization: {
                  global: false,
                  division: false,
                  segment: false,
                  country: false,
                  department: false,
                  site: false,
                  company: false,
                },
                tag: topic,
                topNewsOnly: false,
                isGlobalFeed: false,
                locale: 'en',
              },
              page: {
                limit: maxListedCount,
                offset: 0,
              },
            })}
          >
            {({ response }: any) => {
              return (
                <div
                  ref={(node) => runAchorRef(node)}
                  className="avenue-feed-listing-block"
                >
                  <div className="avenue-feed-listing-block__content">
                    <div className="avenue-feed-listing-block__title-container">
                      <h1 className="avenue-feed-listing-block__title">
                        <a href={anchorLinkFormatter(window.location, id)}>
                          {title}
                        </a>
                      </h1>
                    </div>
                    <ul className="avenue-feed-listing-block__links">
                      {response.map((item: any) => (
                        <ArticleListLink key={item.id} to={item} />
                      ))}
                    </ul>
                    <div className="avenue-feed-listing-block__link-button-container">
                      <EnhancedLink
                        to={linkUrl}
                        className="avenue-feed-listing-block__link-button"
                      >
                        {linkButtonText && linkButtonText.trim() !== ''
                          ? linkButtonText
                          : 'Learn More'}
                      </EnhancedLink>
                    </div>
                  </div>
                </div>
              );
            }}
          </Fetcher>
        );
      }}
    </UserConsumer>
  );
};

export default AvenueFeedListingBlock;
