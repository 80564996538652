import './LanguageBanner.scss';

import Flex from 'components/Flex';
import { ReactComponent as Close } from 'components/Icon/Cancel.svg';
import PropTypes from 'prop-types';
import { GAonClickBackToOriginal } from 'utils/analytics';

const supportedLanguages = [
  { locale: 'en', text: 'Original' },
  { locale: 'zh', text: 'Simplified Chinese' },
  { locale: 'es', text: 'Spanish' },
  { locale: 'fr', text: 'French' },
  { locale: 'nb', text: 'Norwegian' },
  { locale: 'it', text: 'Italian' },
  { locale: 'nl', text: 'Dutch' },
  { locale: 'de', text: 'German' },
  { locale: 'sv', text: 'Swedish' },
  { locale: 'ko', text: 'Korean' },
  { locale: 'pt', text: 'Portuguese' },
  { locale: 'cs', text: 'Czech' },
  { locale: 'ja', text: 'Japanese' },
  { locale: 'pl', text: 'Polish' },
  { locale: 'fi', text: 'Finnish' },
  { locale: 'da', text: 'Danish', isAvailable: false },
  { locale: 'en_GB', text: 'English (International)', isAvailable: false },
];

const getCurrentLanguageText = (currentLocale) => {
  const currentLanguage = supportedLanguages.find(
    (item) => item.locale === currentLocale
  );
  return currentLanguage ? currentLanguage.text : '';
};

const onResetLanguage = (onLanguageReset) => {
  GAonClickBackToOriginal();
  onLanguageReset();
};

const LanguageBanner = ({ id, currentLocale, onClose, onLanguageReset }) => {
  const currentLanguage = getCurrentLanguageText(currentLocale);
  return (
    <Flex id={id} type="center" className="language-banner">
      <div className="language-banner__wrapper p-t-3 p-b-3">
        <Close className="language-banner__close-button" onClick={onClose} />
        <p className="language-banner__description m-b-0">
          Your news feed has been auto translated to {currentLanguage}. Click
          here to
          <span
            className="language-banner__link"
            onClick={() => onResetLanguage(onLanguageReset)}
          >
            return to the original.
          </span>
        </p>
      </div>
    </Flex>
  );
};

LanguageBanner.propTypes = {
  id: PropTypes.string,
  onLanguageReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentLocale: PropTypes.string,
};

export default LanguageBanner;
