import { CmsContentType } from 'api/cms/types';

export const getUrlLink = (type: string, id: string) => {
  if (type === 'NewsArticle' || type === CmsContentType.News) {
    return `/feed/news/${id.replace('cms-', '')}`;
  }
  if (type === 'PressRelease') {
    return `/feed/press/${id.replace('pressrelease-', '')}`;
  }
  if (type === 'BlogPost' || type === CmsContentType.Blog) {
    return `/feed/blog/${id.replace('cms-', '')}`;
  }
  if (type === 'StoryArticle' || type === CmsContentType.Story) {
    return `/feed/story/${id.replace('cms-', '')}`;
  }
  if (type === 'portalarticlesearchhits') {
    return `/portals/innovation/${id.replace('cms-', '')}`;
  }
  return undefined;
};
