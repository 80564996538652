import RelatedContentEditor from 'components/RelatedContent/Editor';
import Title from 'components/Title';

import { Consumer } from '..';

const onRelatedContentChange =
  (onChangeArticle, fieldName) => (relatedContent) => {
    onChangeArticle({ [fieldName]: relatedContent });
  };

const RelatedContent = ({ fieldName = 'relatedContent' }) => (
  <Consumer>
    {({
      article: { [fieldName]: relatedContent },
      validators: {
        [fieldName]: { fieldValidator },
      },
      onChangeArticle,
    }) => (
      <>
        <Title size="small">Related Content</Title>
        <RelatedContentEditor
          contents={relatedContent}
          onChange={onRelatedContentChange(onChangeArticle, fieldName)}
          validators={[fieldValidator]}
        />
      </>
    )}
  </Consumer>
);

export default RelatedContent;
