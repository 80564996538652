import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

type AppContentCardProps = {
  sx?: SxProps<Theme>;
  variant?: 'outlined' | 'elevated';
  children: React.ReactNode | React.ReactNode[];
};

const PageContentCard = ({
  sx,
  variant = 'outlined',
  children,
}: AppContentCardProps) => {
  if (variant === 'outlined') {
    return (
      <Box
        sx={[
          (theme) => ({
            padding: theme.spacing('sm'),
            border: `${theme.border.thickness[1]} solid ${theme.colors.border.surfacePrimary}`,
            borderRadius: theme.border.radius.md,
            backgroundColor: theme.colors.surface.primary,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children}
      </Box>
    );
  }

  if (variant === 'elevated') {
    return (
      <Box
        sx={[
          (theme) => ({
            width: '100%',
            position: 'relative',
            padding: theme.spacing('md'),
            boxShadow: theme.elevation.sm,
            borderRadius: theme.border.radius.md,
            backgroundColor: theme.colors.surface.primary,
            overflow: 'hidden',
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children}
      </Box>
    );
  }

  return <Box sx={sx}>{children}</Box>;
};

export { PageContentCard };
