import './TextMediaBoxList.scss';

import OnlyWithRole from 'components/OnlyWithRole';
import ArticleListBlockBox from 'components/Portal/ArticleListBlockBox';
import AvenueFeedListingBlock from 'components/Portal/AvenueFeedListingBlock';
import BodyBox from 'components/Portal/BodyBox';
import CustomBlockBox from 'components/Portal/CustomBlockBox';
import DividerBox from 'components/Portal/DividerBox';
import EventsListBlockBox from 'components/Portal/EventsListBlockBox';
import GradientBlockBox from 'components/Portal/GradientBlockBox';
import TextMediaBox from 'components/Portal/TextMediaBox';
import { VivaEngageEmbedBlock } from 'components/Portal/VivaEngageEmbedBlock';
import { Component } from 'react';
import { Link } from 'react-router-dom';

class TextMediaBoxList extends Component {
  constructor(props) {
    super(props);

    const { page, renderPageTypes } = props;
    this.state = {
      textMediaBlocksList: page.getChildBlocks(renderPageTypes),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setState({
        textMediaBlocksList: this.props.page.getChildBlocks(
          this.props.renderPageTypes
        ),
      });
    }
  }

  getTemplateForBlockType(block, index) {
    const {
      textColor,
      title,
      body,
      linkUrl,
      linkButtonText,
      showAllEvents,
      maxListedCount,
    } = block.attributes;
    const { id, getHeroImage } = block;

    if (block.matchBlockType('bodyblock')) {
      return <BodyBox page={block} showTitleAndPreamble={true} />;
    }
    if (block.matchBlockType('item')) {
      return (
        <div ref={(ref) => (this.props.scrollRefs[block.id] = ref)}>
          <BodyBox
            page={block}
            className="body-box--relative"
            color={index % 2 === 0 ? 'blue' : 'white'}
            showTitleAndPreamble={true}
          />
        </div>
      );
    }
    if (block.matchBlockType('dividerblock')) {
      return <DividerBox block={block} />;
    }
    if (block.matchBlockType('customblock')) {
      return <CustomBlockBox block={block} />;
    }
    if (block.matchBlockType('article-list-block')) {
      return <ArticleListBlockBox block={block} />;
    }
    if (block.matchBlockType('gradientblock')) {
      return (
        <GradientBlockBox
          textColor={textColor}
          title={title}
          body={body}
          linkUrl={linkUrl}
          linkButtonText={linkButtonText}
          id={id}
          getHeroImage={getHeroImage}
        />
      );
    }
    if (block.matchBlockType('events-list-block')) {
      return (
        <EventsListBlockBox
          title={title}
          linkUrl={linkUrl}
          linkButtonText={linkButtonText}
          id={id}
          showAllEvents={showAllEvents}
          maxListedCount={`${maxListedCount}`}
        />
      );
    }
    if (block.matchBlockType('avenue-feed-listing-block')) {
      const maxListedCount = block.get('maxListedCount');
      const topic = block.get('channel');

      return (
        <AvenueFeedListingBlock
          title={title}
          linkUrl={linkUrl}
          linkButtonText={linkButtonText}
          maxListedCount={maxListedCount}
          id={id}
          topic={topic}
        />
      );
    }
    if (block.matchBlockType('viva-engage-embed-block')) {
      return (
        <VivaEngageEmbedBlock title={title} body={body} linkUrl={linkUrl} />
      );
    }
    return <TextMediaBox block={block} />;
  }

  render() {
    const { textMediaBlocksList } = this.state;

    return (
      <div className="text-media-box-list">
        {Boolean(textMediaBlocksList.length) &&
          textMediaBlocksList
            .sort((blockA, blockB) => {
              return (
                (blockA.attributes.sortIndex - blockB.attributes.sortIndex) /
                Math.abs(
                  blockA.attributes.sortIndex - blockB.attributes.sortIndex
                )
              );
            })
            .map((block, index) => {
              return (
                <div className="text-media-box-list__item" key={block.id}>
                  {this.getTemplateForBlockType(block, index)}
                  <OnlyWithRole viewableByRole="AvenuePortalEditor">
                    <div className="text-media-box-list__cta-overlay">
                      <Link
                        className="text-media-box-list__cta-link"
                        to={block.getEditPath()}
                      >
                        Edit
                      </Link>
                    </div>
                  </OnlyWithRole>
                </div>
              );
            })}
      </div>
    );
  }
}

export default TextMediaBoxList;
