import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Job } from 'api/jobs/types';

type JobContentMetaLabelsProps = {
  job: Job;
  elementId: string;
};

const JobContentMetaLabels = ({
  job,
  elementId,
}: JobContentMetaLabelsProps) => {
  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: theme.spacing('xxs'),
        typography: theme.typography.body2,
        color: theme.colors.text.tertiary,
      })}
      divider={
        <Divider
          orientation="vertical"
          sx={(theme) => ({
            margin: `auto ${theme.spacing('xxs')}`,
            height: theme.typography.body1.fontSize,
            borderColor: theme.colors.border.surfaceSecondary,
          })}
        />
      }
    >
      {job.category && <Typography>{job.category.name}</Typography>}
      {job.seniorityLevel && <Typography>{job.seniorityLevel.name}</Typography>}
      {job.department && <Typography>{job.department.name}</Typography>}
      <Typography>ID: {job.id}</Typography>
    </Stack>
  );
};

export { JobContentMetaLabels };
