import { Component, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import {
  GAonJobsResetFilters,
  GAonJobsUpdateSelectFilter,
  GAonJobsUpdateSeniorityFilter,
} from 'utils/analytics';
import makeQueryString from 'utils/misc/makeQueryString';

const { Consumer: JobsConsumer, Provider } = createContext();

const LIMIT = 20;

const getInitialState = () => {
  const initialState = {
    queryObject: {
      filter: {},
      page: {
        limit: LIMIT,
      },
    },
    limit: LIMIT,
    pagination: 0,
    disableReset: true,
    filterValues: {
      seniority: null,
      regions: [],
      locations: [],
      categories: [],
      states: [],
    },
    updatedFilterKey: null,
  };

  initialState.query = makeQueryString(initialState.queryObject);

  return initialState;
};

class JobsProvider extends Component {
  state = getInitialState();
  initialQuery = this.state.query;

  setFilters = (filterKey, selected) => {
    const queryObject = {
      ...this.state.queryObject,
      filter: {
        ...this.state.queryObject.filter,
        [filterKey]: selected.map((s) => s.value),
      },
    };

    const filterValues = {
      ...this.state.filterValues,
      [filterKey]: selected.map((s) => ({
        value: s.value,
        // remove the numbers from the labels in the selected values
        // Europe (6) becomes Europe
        name: s.name.replace(/ \([0-9]*\)/g, ''),
      })),
    };

    const query = makeQueryString(queryObject);

    this.setState({
      filterValues,
      queryObject,
      query,
      pagination: 0,
      disableReset: this.initialQuery === query,
      updatedFilterKey: filterKey,
    });
  };

  updateSelectFilters = (key, selected) => {
    // Get selected filters and compare them to current chosen filter
    // And only send GA event if we add more filters (check a checkbox)
    const { filterValues } = this.state;
    const currentSelected = filterValues[key];
    if (currentSelected.length < selected.length) {
      GAonJobsUpdateSelectFilter(selected);
    }

    this.setFilters(key, selected);
  };

  updateSeniority = (values, names) => {
    this.setFilters('seniority', values);

    GAonJobsUpdateSeniorityFilter(names);
  };

  resetFilters = () => {
    const state = getInitialState();
    this.setState(state);

    GAonJobsResetFilters();
  };

  incrementPagination = () => {
    const { pagination } = this.state;
    this.setState({ pagination: pagination + 1 });
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          updateSelectFilters: this.updateSelectFilters,
          updateSeniority: this.updateSeniority,
          resetFilters: this.resetFilters,
          incrementPagination: this.incrementPagination,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export default withRouter(JobsProvider);
export { JobsConsumer };
