import './JobItem.scss';

import SearchHit from 'api/models/SearchHit';
import ContentLabels from 'components/ContentLabels';
import { saveScrollPosition } from 'components/ScrollRestoration';
import PropTypes from 'prop-types';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { GAonClickAvenueOverlaySearchResult } from 'utils/analytics';

interface LinkWrapperProps {
  children: ReactNode;
  url: string;
  isFullList?: boolean;
  title: string;
}

const LinkWrapper = (props: LinkWrapperProps) => {
  const { children, url, isFullList, title } = props;
  return (
    <Link
      className="job-item__link"
      to={url}
      onClick={() => {
        isFullList && saveScrollPosition('find');
        GAonClickAvenueOverlaySearchResult(title);
      }}
    >
      {children}
    </Link>
  );
};

interface JobItemProps {
  searchHit: SearchHit;
}

const JobItem = ({ searchHit }: JobItemProps) => {
  const { title, category, department, seniorityLevel } =
    searchHit.getAttributes();
  const url = searchHit.getUrl();

  if (!url) {
    return null;
  }

  return (
    <div className="job-item">
      <div className="job-item__body">
        <p className="job-item__jobs">Jobs</p>
        <h2 className="job-item__header">
          <LinkWrapper url={url} title={title}>
            {title}
          </LinkWrapper>
        </h2>
        <div className="job-item__meta-label-container">
          <div className="job-item__meta">
            {seniorityLevel && (
              <span className="job-item__info">
                {seniorityLevel.displayName}
              </span>
            )}
            {category && <span className="job-item__info">{category}</span>}
            {department && (
              <span className="job-item__department">{department}</span>
            )}
          </div>
          <div className="job-item__label">
            <ContentLabels
              className="label job-search-label label-no-margin"
              labels={searchHit.getJobLabelsForSearchResult()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

JobItem.propTypes = {
  searchHit: PropTypes.instanceOf(SearchHit).isRequired,
};

export default JobItem;
