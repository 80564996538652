import './Accordion.scss';

import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import RichText from 'components/Editable/RichText';
// TODO: Should not use untyped packages in typescript files
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { setRenderOptions, styleToHTML } from 'medium-draft/lib/exporter';
import {
  cloneElement,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { blockToHTML, entityToHTML } from '../../Editable/RichText/export';

const yesButton = {
  color: 'black',
  title: 'Save',
};

const noButton = {
  color: 'white',
  title: 'Close',
};

const exporter = setRenderOptions({
  styleToHTML,
  blockToHTML,
  entityToHTML,
});

interface AccordionInput {
  title: string;
  body: string;
  entityKey?: string;
}

interface EmbedAccordionButtonProps {
  onSubmit: (accordion: AccordionInput, entityKey?: string) => void;
  accordionModalTitle: string;
  children: JSX.Element;
  defaultAccordion?: AccordionInput;
  enableEditor?: () => void;
  setParentAccordion?: Dispatch<SetStateAction<AccordionInput>>;
  setDontAllowMultipleToggles?: Dispatch<SetStateAction<boolean>>;
}

const Accordion: FC<EmbedAccordionButtonProps> = ({
  onSubmit,
  children,
  defaultAccordion,
  enableEditor,
  setParentAccordion,
  setDontAllowMultipleToggles,
  accordionModalTitle,
}) => {
  const [modalErrorMessage, setModalErrorMessage] = useState<string | null>(
    null
  );
  const [accordion, setAccordion] = useState(
    defaultAccordion
      ? defaultAccordion
      : {
          entityKey: '',
          title: '',
          body: '',
        }
  );

  useEffect(() => {
    if (defaultAccordion) setAccordion(defaultAccordion);
  }, [defaultAccordion]);

  const onChangeAccordionTitle = (e: any) => {
    setAccordion({ ...accordion, title: e.target.value });
  };

  const onChangeAccordionRichTextBody = (editorState: any) => {
    setAccordion({ ...accordion, body: exporter(editorState.editorState) });
  };

  const onNoButtonClick = () => {
    setModalErrorMessage(null);
    if (setDontAllowMultipleToggles) setDontAllowMultipleToggles(true);

    if (!defaultAccordion) {
      setAccordion({
        entityKey: '',
        title: '',
        body: '',
      });
    } else {
      setAccordion(defaultAccordion);
    }

    if (enableEditor) enableEditor();
  };

  const onSubmitAccordion = () => {
    setModalErrorMessage(null);

    if (
      accordion.title.length !== 0 &&
      accordion.body.length !== 0 &&
      accordion.body !== '<p></p>'
    ) {
      if (setParentAccordion) setParentAccordion(accordion);
      if (setDontAllowMultipleToggles) setDontAllowMultipleToggles(true);
      onSubmit(accordion);
      return true;
    }

    if (accordion.title.length === 0) {
      setModalErrorMessage('Accordion title cannot be empty');
      return false;
    }
    if (accordion.body.length === 0 || accordion.body === '<p></p>') {
      setModalErrorMessage('Accordion body cannot be empty');
      return false;
    }

    return false;
  };

  const renderAccordionModalBody = () => {
    return (
      <div className="accordion-modal__container">
        <input
          id="accordion-sub-title"
          className="accordion-modal__title"
          placeholder="Title"
          onChange={(e) => onChangeAccordionTitle(e)}
          value={accordion.title}
        />
        <RichText
          id="accordion-sub-body"
          onChange={onChangeAccordionRichTextBody}
          isRaw={false}
          showSideButtons={true}
          placeholder="Body"
          content={accordion.body}
        />
      </div>
    );
  };

  const childElement = cloneElement(children, {
    onClick: onSubmitAccordion,
    'aria-controls': 'addaccordion',
  });

  return (
    <ButtonModalWrapper
      title={accordionModalTitle}
      yesButton={yesButton}
      noButton={noButton}
      onNoButtonClick={onNoButtonClick}
      errorMessage={modalErrorMessage}
      disableClickOutside={true}
      modalChildren={renderAccordionModalBody()}
      size="md"
      accordionPadding="accordionPadding"
    >
      {childElement}
    </ButtonModalWrapper>
  );
};

export default Accordion;
