import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { events } = settings.api;

export const getCalendarEvents = (listedCount: any) =>
  createRequest({
    method: 'post',
    url: `${events}/GetCalenderEvents`,
    errorMessages: {
      '*': 'Failed to get Events, please contact support for assistance.',
      '404': 'Sorry, no events to display right now.',
    },
    data: {
      data: {
        limit: listedCount,
        offset: 0,
      },
    },
  });
