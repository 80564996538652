import './EmbedAccordionButton.scss';

import { ReactComponent as AccordionIcon } from 'components/Icon/Accordion.svg';
import Accordion from 'components/Modals/Accordion/index';
import { FC } from 'react';

interface EmbedAccordionButtonProps {
  onAddAccordion: (accordion: object) => void;
  title: string;
  close: () => void;
}

const EmbedAccordionButton: FC<EmbedAccordionButtonProps> = ({
  onAddAccordion,
  title,
  close,
}) => {
  const accordionModalTitle = 'Add accordion';
  return (
    <Accordion
      onSubmit={(data) => {
        onAddAccordion(data);
        close();
      }}
      accordionModalTitle={accordionModalTitle}
    >
      <div className="embed-accordion__container">
        <button className="embed-accordion__button" type="button">
          <AccordionIcon className="embed-accordion__button--icon" />
        </button>
        <p className="embed-accordion__label">{title}</p>
      </div>
    </Accordion>
  );
};

export default EmbedAccordionButton;
