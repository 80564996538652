import './SearchCount.scss';

const SearchCount = ({ searchString, total }) => (
  <p className="search-count">
    <i>
      {`${total} search ${total === 1 ? 'result' : 'results'} for `}{' '}
      <strong>{searchString}</strong>
    </i>
  </p>
);
export default SearchCount;
