import './DocumentItem.scss';

import SearchHit from 'api/models/SearchHit';
import EnhancedLink from 'components/EnhancedLink';
import { ReactComponent as DownloadIcon } from 'components/Icon/DownloadIcon.svg';
import { ReactComponent as ShareIcon } from 'components/Icon/ShareIcon.svg';
import { saveScrollPosition } from 'components/ScrollRestoration';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  GAonClickAvenueOverlaySearchResult,
  GAonSearchResultClick,
} from 'utils/analytics';
import { getDocumentIcon } from 'utils/misc/getDocumentIcon';

import DocumentIcon from './DocumentIcon';

const DocumentLinkWrapper = ({
  children,
  label,
  url,
  isFullList,
  fileName,
}) => (
  <EnhancedLink
    to={url}
    onClick={() => {
      GAonSearchResultClick(label);
      GAonClickAvenueOverlaySearchResult(fileName);
      isFullList && saveScrollPosition('find');
    }}
    className="document-item__link"
  >
    {children}
  </EnhancedLink>
);

const DocumentItem = ({ isFullList, searchHit }) => {
  const url = searchHit.getUrl();

  if (!url) {
    return null;
  }

  const formatDocumentContent = () => {
    const { content, searchPhrase } = searchHit.attributes;

    if (content) {
      const replace = `(${searchPhrase})`;
      const re = new RegExp(replace, 'i');

      const formattedContent = content.replace(re, '<strong>$1</strong>');

      return `${formattedContent} ...`;
    }
  };

  const getDocumentLocation = (url) => {
    url = url.toLowerCase();

    if (url.startsWith('library')) {
      return (
        <Link className="document-item__location-link" to="/library">
          <span className="document-item__location-text">Library</span>
        </Link>
      );
    }
    if (url.startsWith('group-library')) {
      return (
        <Link className="document-item__location-link" to="/group-library">
          <span className="document-item__location-text">Group</span>
        </Link>
      );
    }
    if (url.startsWith('feed/news')) {
      return (
        <Link className="document-item__location-link" to="/feed?type=news">
          <span className="document-item__location-text">News</span>
        </Link>
      );
    }
    if (url.startsWith('portals/innovation')) {
      return (
        <Link className="document-item__location-link" to="/portals/innovation">
          <span className="document-item__location-text">Innovation</span>
        </Link>
      );
    }
    if (url.startsWith('portals/codeofconduct')) {
      return (
        <Link
          className="document-item__location-link"
          to="/portals/codeofconduct"
        >
          <span className="document-item__location-text">Code of Conduct</span>
        </Link>
      );
    }
    if (url.startsWith('portals/strategy')) {
      return (
        <Link className="document-item__location-link" to="/portals/strategy">
          <span className="document-item__location-text">Our Strategy</span>
        </Link>
      );
    } else {
      return '';
    }
  };

  const formatDisplayLinkUrl = (displayLinkUrl) => {
    const splitDisplayLinkUrl = displayLinkUrl.split('/');
    const lastWordInDisplayLinkUrl = splitDisplayLinkUrl.pop();
    return `${splitDisplayLinkUrl.join(
      ' / '
    )} / <strong>${lastWordInDisplayLinkUrl}</strong>`;
  };

  const label = searchHit.getTypeLabel();
  const { fileName, displayLinkUrl, linkUrl, downloadUrl, previewUrl } =
    searchHit.getAttributes();

  const updatedFileName = fileName.toUpperCase().split('.').pop();

  const documentIcon = getDocumentIcon(updatedFileName);
  const origin = window.location.origin;
  const updatedLinkUrl = linkUrl.replace(origin, '');
  const documentLocation = getDocumentLocation(displayLinkUrl);

  return (
    <div className="document-item">
      <div className="document-item__body m-t-2">
        <div className="document-item__section">
          <div className="document-item__inner">
            <div className="document-item__outer">
              <DocumentIcon
                icon={documentIcon.icon}
                color={documentIcon.color}
              />
            </div>
            <div className="document-item__content-wrapper">
              <Link className="document-item__content-link" to={updatedLinkUrl}>
                {' '}
                <div
                  className="document-item__content-link-text"
                  dangerouslySetInnerHTML={{
                    __html: formatDisplayLinkUrl(displayLinkUrl),
                  }}
                ></div>
              </Link>

              <h2 className="document-item__header">
                <DocumentLinkWrapper
                  label={label}
                  url={url}
                  isFullList={isFullList}
                  fileName={fileName}
                >
                  <span className="document-item__header-text">{fileName}</span>
                </DocumentLinkWrapper>
              </h2>
              <p
                className="document-item__content"
                dangerouslySetInnerHTML={{ __html: formatDocumentContent() }}
              />
            </div>
          </div>
          <div className="document-item__location-share-wrapper">
            <div className="document-item__location">{documentLocation}</div>
            <div className="document-item__share-download-wrapper">
              <a
                href={`mailto:?subject=${fileName}&body=${encodeURIComponent(
                  previewUrl
                )}`}
                className="document-item__share"
              >
                <ShareIcon />
              </a>
              <a href={downloadUrl} className="document-item__download">
                <DownloadIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentItem.propTypes = {
  searchHit: PropTypes.instanceOf(SearchHit).isRequired,
  isFullList: PropTypes.bool,
};

export default DocumentItem;
