import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
import makeQueryString from 'utils/misc/makeQueryString';
import { v1 as uuidv1 } from 'uuid';

const { documents } = settings.api;

const createFolder = (folderName, path = null, newFolderName = null) => {
  const query = makeQueryString({
    folderId: folderName,
    path,
    newFolderName,
  });
  return createRequest({
    method: 'post',
    url: `${documents}/msgraphdocuments/folder${query}`,
    data: null,
    headers: {
      'Content-Type': 'application/json',
    },
    errorMessages: {
      '*': 'Failed to create folder, please contact support for assistance.',
    },
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 409,
  });
};

export const createNewArticleFolder = () => createFolder(uuidv1());

export const addFolder = (folderName, path, newFolderName) =>
  createFolder(folderName, path, newFolderName);

export const uploadFile = (
  folderName,
  path,
  formData,
  onUploadProgress,
  cancelToken
) => {
  const query = makeQueryString({
    folderId: folderName,
    path,
  });
  return createRequest({
    method: 'post',
    cancelToken,
    url: `${documents}/msgraphdocuments/item/${query}`,
    data: formData,
    onUploadProgress,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    errorMessages: {
      '*': 'Failed to upload file, please contact support for assistance.',
    },
  });
};

export const readFolder = (folderName, path) => {
  const query = makeQueryString({
    folderId: folderName,
    path,
  });
  return createRequest({
    method: 'get',
    url: `${documents}/msgraphdocuments/items${query}`,
    headers: {
      'Content-Type': 'application/json',
    },
    errorMessages: {
      '*': 'Failed to read directory, please contact support for assistance.',
    },
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 409,
  });
};

export const updateItem = (folderName, path, newName) => {
  const query = makeQueryString({
    folderId: folderName,
    path,
    newName,
  });
  return createRequest({
    method: 'patch',
    url: `${documents}/msgraphdocuments/item/${query}`,
    data: null,
    headers: {
      'Content-Type': 'application/json',
    },
    errorMessages: {
      '*': 'Failed to update item, please contact support for assistance.',
    },
  });
};

export const deleteItem = (folderName, path, id, type) => {
  const query = makeQueryString({
    folderId: folderName,
    path,
    id,
    type,
    recursive: true,
  });
  return createRequest({
    method: 'delete',
    url: `${documents}/msgraphdocuments/item/${query}`,
    errorMessages: {
      '*': 'Failed to delete item, please contact support for assistance.',
    },
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 404,
  });
};
