import './ProgressBar.scss';

import PropTypes from 'prop-types';

const ProgressBar = ({ value }) => {
  return (
    <progress
      max="100"
      value={`${Math.round(value * 100)}`}
      className="progress-bar__bar"
    ></progress>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ProgressBar;
