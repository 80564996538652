import './AuthorDisplay.scss';

import BlogAuthor from 'api/models/BlogAuthor';
import Button from 'components/Button';
import Text from 'components/Editable/Text';
import UserImage from 'components/UserImage';
import PropTypes from 'prop-types';

const validateDescription = (value) => {
  if (!value) {
    return 'Guest authors must have a description';
  }

  return null;
};

const AuthorDisplay = ({
  author,
  edit,
  maxLength,
  showInitialError,
  openModal,
  onSetAuthorDescription,
}) => {
  return (
    <>
      <div className="author-display__portrait-wrapper">
        {edit && (
          <Button
            tabIndex={0}
            size="small"
            color="white"
            className="author-display__button"
            onClick={openModal}
          >
            Select another author
          </Button>
        )}
        <UserImage
          name={author.getPersonName()}
          src={author.get('imageUrl')}
          className="author-display__portrait"
          desktopSize="x-large"
        />
        <div className="author-display__info-wrapper">
          <span className="author-display__name">{author.getPersonName()}</span>
          <span className="author-display__title">{author.get('title')}</span>
        </div>
      </div>
      <div className="author-display__description">
        <strong>About the author</strong>
        {author.isGuest() && edit ? (
          <Text
            wrapper={{
              element: 'div',
              className: 'author-display__description__input',
            }}
            key={author.get('userId')}
            className="m-t-3"
            placeholder="Author description"
            validators={[validateDescription]}
            showInitialError={showInitialError}
            maxLength={maxLength}
            content={author.get('description')}
            onBlur={onSetAuthorDescription}
            multiline={true}
          />
        ) : (
          <p className="author-display__description-text">
            {author.get('description')}
          </p>
        )}
      </div>
    </>
  );
};

AuthorDisplay.propTypes = {
  author: PropTypes.instanceOf(BlogAuthor).isRequired,
  edit: PropTypes.bool,
  maxLength: PropTypes.number,
  showInitialError: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  onSetAuthorDescription: PropTypes.func,
};

export default AuthorDisplay;
