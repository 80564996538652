import Box from 'components/Box';
import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Loader = () => (
  <div className="innovation-portal-results">
    <div className="innovation-portal-results__content-loader-title">
      <span color="white">Searching...</span>
    </div>
    <div className="innovation-portal-results__content-loader-content">
      <Box>
        <Resize>
          {({ width }: { width: number }) => (
            <ContentLoader
              className="person-item__contentloader"
              width={width}
              height={150}
              preserveAspectRatio="none"
            >
              <rect
                x={0}
                y={0}
                width={getRandomNumberBetween(400, 900)}
                height={15}
              />
              <rect
                x={0}
                y={38}
                width={getRandomNumberBetween(200, 300)}
                height={18}
              />
              <rect
                x={0}
                y={63}
                width={getRandomNumberBetween(400, 800)}
                height={15}
              />
              <rect x={0} y={88} rx={4} ry={4} width={54} height={28} />
            </ContentLoader>
          )}
        </Resize>
      </Box>
    </div>
  </div>
);

export default Loader;
