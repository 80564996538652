import './FileUploader.scss';

import CircularProgress from '@mui/material/CircularProgress';
import * as API from 'api/sharepointdocuments';
import { CancelToken } from 'axios';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';

import MiniSnackbar from '../MiniSnackbar';
import UploadEntry from '../UploadEntry';

const availableStates = {
  'adding-files': true,
  uploading: true,
  cancelled: true,
  success: true,
};

const uploadFile = async (
  folderName,
  path,
  formData,
  onProgress,
  cancelToken
) => {
  const onUploadProgress = (progressEvent) => {
    const uploadPercentage = parseInt(
      Math.round((progressEvent.loaded / progressEvent.total) * 100)
    );
    onProgress(uploadPercentage);
  };
  await API.uploadFile(
    folderName,
    path,
    formData,
    onUploadProgress,
    cancelToken
  );
};

const renderSuccessMessage = () => (
  <div className="file-uploader__success-message-container">
    <p>Success! The uploads are completed.</p>
  </div>
);

const renderDropzone = (
  selectedFiles,
  fileProgress,
  changeHandler,
  onClickRemove
) => (
  <Dropzone onDrop={changeHandler}>
    {({ getRootProps, getInputProps }) => (
      <>
        <div className="file-uploader__drop-area" {...getRootProps()}>
          <input {...getInputProps()} />

          <div className="file-uploader__drag-n-drop-files-message">
            <p>Drop files or click here to continue...</p>
          </div>
        </div>
        <p className="file-uploader__info-message">
          Avenue should only hold documents classified as public or internal.
          Documents classified as restricted, confidential or secret should not
          be published on Avenue.
        </p>
        <div className="file-uploader__files-area">
          {selectedFiles.length > 0
            ? selectedFiles.map(({ name, size, lastModifiedDate }) => {
                const progress = fileProgress[name];
                return (
                  <UploadEntry
                    key={`${name}${lastModifiedDate}`}
                    item={{
                      name,
                      type: 'file',
                      size,
                    }}
                    progressValue={progress === undefined ? null : progress}
                    onClick={() => {}}
                    onCancel={() => onClickRemove(name)}
                  />
                );
              })
            : null}
        </div>
      </>
    )}
  </Dropzone>
);

const FileUploader = ({ folderName, path, onClosed }) => {
  const [currentState, setCurrentState] = useState('adding-files');
  const [messages, setMessages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileProgress, setFileProgress] = useState({});
  const cancelTokenRef = useRef(null);
  const setCurrentStateSafely = (state) => {
    if (!availableStates[state]) {
      throw new Error('Invalid component state selected');
    }
    setCurrentState(state);
  };
  const changeHandler = (files) => {
    const errors = [];
    // checks in files added to the list is greater than 250MB.
    // If so they are not added.
    files.reduceRight((acc, curr, index, obj) => {
      if (curr.size > 262144000) {
        errors.push(curr.name);
        obj.splice(index, 1);
      }
      return acc;
    }, []);

    if (errors.length > 0) {
      const errorFilesInText = errors.join(', ');
      onAddError(`${errorFilesInText} reaches the file size limit of 250 MB.`);
    }

    files = files.filter(
      (file) => !selectedFiles.find((selFile) => selFile.name === file.name)
    );

    setSelectedFiles(selectedFiles.concat(files));
  };
  const resetState = (target = 'success', clearFiles = true) => {
    const { current } = cancelTokenRef;
    if (target === 'cancelled' && current !== null) {
      current.cancel();
    }
    cancelTokenRef.current = null;
    setFileProgress({});
    setCurrentStateSafely(target);
    if (clearFiles) {
      setSelectedFiles([]);
    }
  };

  const onUploadProgress = (file, value) => {
    setFileProgress((progress) =>
      progress ? { ...progress, [file]: value } : { [file]: value }
    );
  };
  const onClickUpload = () => {
    const formDataEntries = [];
    setCurrentStateSafely('uploading');
    const fileProgress = {};

    for (const file of selectedFiles) {
      const formData = new FormData();
      formData.append('files', file, file.name);

      formDataEntries.push([file.name, formData]);
      fileProgress[file.name] = 0;
    }
    setFileProgress(fileProgress);
    (async () => {
      try {
        cancelTokenRef.current = CancelToken.source();
        await Promise.all(
          formDataEntries.map(([fileName, formData]) => {
            return uploadFile(
              folderName,
              path,
              formData,
              (progress) => onUploadProgress(fileName, progress),
              cancelTokenRef.current.token
            ).then(() => {
              // Completed a file
            });
          })
        );
        resetState();
      } catch (error) {
        if (cancelTokenRef.current === null) {
          return;
        }
        onAddError(`Could not upload all files: ${error.message}`);
        resetState('adding-files', false);
      }
    })();
  };
  const onClickRemove = (name) => {
    setSelectedFiles((selected) =>
      selected.filter(({ name: selectedName }) => name !== selectedName)
    );
  };
  const onAddError = (message) => {
    setMessages([
      ...messages,
      {
        id: messages.reduce((max, { id }) => (id >= max ? id + 1 : max), 0),
        content: message,
        status: 'error',
      },
    ]);
  };

  const isFileUploadButtonEnabled =
    selectedFiles.length !== 0 &&
    (currentState === 'adding-files' || currentState === 'cancelled');
  const fileUploadButtonClassNames = classNames('file-uploader__button', {
    'file-uploader__button--disabled': !isFileUploadButtonEnabled,
  });
  const clearFilesButtonClassNames = classNames('file-uploader__button', {
    'file-uploader__button--disabled': selectedFiles.length === 0,
    'file-uploader__button--light': true,
  });
  const closeButtonClassNames = classNames('file-uploader__button ', {
    'file-uploader__button--disabled': selectedFiles.length !== 0,
    'file-uploader__button--light': true,
    'file-uploader__button--align-left': true,
  });
  return (
    <div className="file-uploader__container">
      <MiniSnackbar messages={messages} onChange={setMessages} />
      {currentState === 'success'
        ? renderSuccessMessage()
        : renderDropzone(
            selectedFiles,
            fileProgress,
            changeHandler,
            onClickRemove
          )}
      <div className="file-uploader__buttons-container">
        <button
          className={closeButtonClassNames}
          onClick={onClosed.bind(null, currentState === 'success')}
        >
          Close
        </button>
        {selectedFiles.length ? (
          <button
            className={clearFilesButtonClassNames}
            onClick={() => resetState('cancelled')}
          >
            Cancel upload
          </button>
        ) : null}
        {currentState === 'success' ? null : (
          <button
            disabled={!isFileUploadButtonEnabled}
            className={fileUploadButtonClassNames}
            onClick={onClickUpload}
          >
            {currentState === 'uploading' ? (
              <div className="file-uploader__button--spinner">
                <CircularProgress size={24} sx={{ color: 'white' }} />
              </div>
            ) : (
              'Upload'
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
