import './MobileMenu.scss';

import PortalNode from 'api/models/PortalNode';
import NavigationItem from 'components/Navigation/NavigationItem';
import TreeItem from 'components/Navigation/TreeItem';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { logout } from 'utils/auth';

class MobileMenu extends Component {
  constructor(props) {
    super(props);

    const { selectionPath } = props;

    const openPages = selectionPath.reduce((acc, page) => {
      acc[page.id] = true;

      return acc;
    }, {});

    this.state = { openPages };
  }

  setItemOpen = (page, isOpen) => {
    this.setState(({ openPages: oldOpenPages }) => {
      const openPages = { ...oldOpenPages, [page.id]: isOpen };
      return { openPages };
    });
  };

  getPageList(childPages, depth) {
    const { openPages } = this.state;
    const { page, selectionPath } = this.props;
    const portal = true;

    return childPages
      .filter((page) => !page.shouldHideFromNavigation())
      .reduce((acc, childPage) => {
        const isOpen = openPages[childPage.id];
        const hasChildren =
          childPage.getChildPages(null, 'insights-content').length > 0;

        acc.push(
          <TreeItem
            portal={portal}
            key={childPage.id}
            isOpen={isOpen}
            depth={depth}
            hasChildren={hasChildren}
            setOpen={this.setItemOpen}
            openKey={childPage}
            to={childPage.getPath()}
            isActive={childPage === page}
            inActivePath={selectionPath.includes(childPage)}
          >
            {childPage.get('title')}
          </TreeItem>
        );

        if (isOpen && hasChildren) {
          acc.push(
            <ul
              key={`${childPage.id}list`}
              className="portal-mobile-menu__list portal-mobile-menu__list--children"
            >
              {this.getPageList(
                childPage.getChildPages(null, 'insights-content'),
                depth + 1
              )}
            </ul>
          );
        }

        return acc;
      }, []);
  }

  render() {
    const { page, selectionPath } = this.props;

    const rootNode = selectionPath[0];

    if (!rootNode) {
      return null;
    }

    const pageList = this.getPageList(
      rootNode.getChildPages(null, 'insights-content'),
      1
    );

    return (
      <ul className="portal-mobile-menu">
        <TreeItem
          depth={1}
          portal={true}
          to={rootNode.getPath()}
          isActive={rootNode === page}
          inActivePath={rootNode === page}
        >
          Home
        </TreeItem>
        <ul className="portal-mobile-menu__list">{pageList}</ul>
        <ul className="navigation__items navigation__items--bottom-group">
          <NavigationItem to="/ " color="white" onClick={logout}>
            Log Out
          </NavigationItem>
        </ul>
      </ul>
    );
  }
}

MobileMenu.propTypes = {
  page: PropTypes.instanceOf(PortalNode),
  selectionPath: PropTypes.arrayOf(PropTypes.instanceOf(PortalNode)),
  navigationOpen: PropTypes.bool,
};

export default MobileMenu;
