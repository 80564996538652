import 'styles/global.scss';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ThemeProvider } from '@mui/material/styles';
import { getMenu } from 'api/cms/public';
import Error from 'components/Error';
import PublicMenuContent from 'components/Navigation/PublicMenuContent';
import PageHeader from 'components/PageHeader';
import Hotjar from 'components/Plugins/Hotjar';
import BlockedUser from 'pages/Public/BlockedUser';
import FAQ from 'pages/Public/FAQ';
import NotFound from 'pages/Public/NotFound';
import Page from 'pages/Public/Page';
import { Component } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import settings from 'settings';
import { createTheme } from 'theme';
const theme = createTheme();

const { applicationInsightsConnectionString } = settings.misc;
if (
  applicationInsightsConnectionString &&
  process.env.NODE_ENV !== 'development'
) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: applicationInsightsConnectionString,
    },
  });

  appInsights.loadAppInsights();
}

class PublicRoot extends Component {
  state = {
    pages: [],
    isLoading: true,
    error: null,
  };

  async componentDidMount() {
    try {
      const response = await getMenu();
      if (response && response.data) {
        this.setState({
          isLoading: false,
          pages: response.data.data,
        });
      }
    } catch (e) {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage: e.message,
      });
    }
  }
  render() {
    const { error, pages, isLoading } = this.state;
    const { location } = this.props;

    let emptyNav =
      !pages.length || //use !pages.length to prevent faq appearing on navbar for a fraction of a second
      pages.some(
        (page) =>
          page.hideFromNavigation && `/public/${page.url}` === location.pathname
      );

    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/public/unauthorized-user" component={BlockedUser} />
            <Route path="/public">
              <>
                <PageHeader
                  signedIn={false}
                  showBlueBackground={true}
                  hideHamburger={emptyNav}
                >
                  <PublicMenuContent pages={pages} showNavItems={emptyNav} />
                </PageHeader>
                {error && <Error message="Error loading public pages" />}
                <Switch>
                  <Route path="/public/faq" component={FAQ} />
                  {pages.map((page) => (
                    <Route
                      key={page._doc}
                      path={`/public/${page.url}`}
                      render={(props) => <Page {...props} {...page} />}
                    />
                  ))}
                  <Route exact path="/public/404" component={NotFound} />
                  {!isLoading && <Redirect to="/public/404" />}
                </Switch>
                <Hotjar />
              </>
            </Route>
            <Route
              path="/"
              render={({ location }) => {
                window.location.href =
                  window.location.origin + location.pathname;
                return <div style={{ minHeight: '100vh' }} />;
              }}
            />
          </Switch>
        </Router>
      </ThemeProvider>
    );
  }
}

export default withRouter(PublicRoot);
