import './SearchBox.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import classnames from 'classnames';
import { ReactComponent as CancelIcon } from 'components/Icon/Cancel.svg';
import { ReactComponent as SearchIcon } from 'components/Icon/SearchSmall.svg';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GAonInsightsDatabaseSearch } from 'utils/analytics';
import { formatSearchQuery } from 'utils/misc/autoSuggest';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    const searchQuery = queryString.parse(this.props.history.location.search)[
      'search'
    ];
    this.state = {
      searchQuery: this.props.searchQuery || searchQuery || '',
      justSelectedSuggestionIndex: null,
      suggestions: [],
      searchString: searchQuery
        ? `search=${encodeURIComponent(searchQuery)}`
        : '',
    };
  }

  componentDidMount() {
    const searchValue = {
      type: 'search',
      value: this.state.searchQuery,
    };

    this.props.onMount(searchValue);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search !== this.props.location.search &&
      this.props.location.search === ''
    ) {
      const searchString = queryString.parse(
        this.props.history.location.search
      )['search'];
      this.setState({
        searchQuery: '',
        justSelectedSuggestionIndex: null,
        suggestions: [],
        searchString: searchString
          ? `search=${encodeURIComponent(searchString)}`
          : '',
      });
    }
  }

  handleInputChange = () => {
    this.setState({ searchQuery: this.searchInput.value });
  };

  handleInputBlur = () => {
    this.setState({ suggestions: [], justSelectedSuggestionIndex: null });
  };

  setUrlOnSearchUpdate = (value) => {
    const path = this.props.history.location.pathname;
    const urlSearch = this.props.history.location.search;
    const query = queryString.parse(urlSearch);
    const filtersPresent =
      Object.keys(query).length > 1 ||
      (Object.keys(query).length > 0 && !query.search);

    const urlHasFiltersWithSearch = filtersPresent && !!this.state.searchString;
    const urlHasFiltersWithoutSearch =
      filtersPresent && !this.state.searchString;
    const urlHasOnlySearch = !filtersPresent && !!this.state.searchString;
    const urlEmpty = !urlSearch && !this.state.searchString;

    const prefix = urlEmpty || urlHasOnlySearch ? '?' : '&';

    if (!value) {
      // remove search from url
      if (filtersPresent) {
        let string = '';
        for (let key in query) {
          if (key === 'search') continue;
          string += key;
          string += `=${query[key]}&`;
        }
        string = string.slice(0, -1);
        this.props.history.replace(`${path}?${string}`);
      } else {
        this.props.history.replace(path);
      }
      return;
    }

    if (urlHasFiltersWithSearch) {
      let string = '';
      for (let key in query) {
        if (key === 'search') continue;
        string += key;
        string += `=${query[key]}&`;
      }
      string = string.slice(0, -1);
      this.props.history.replace(`${path}?${string + prefix + value}`);
    } else if (urlHasFiltersWithoutSearch) {
      this.props.history.replace(`${path}${urlSearch + prefix + value}`);
    } else if (urlHasOnlySearch || urlEmpty) {
      this.props.history.replace(`${path + prefix}${value}`);
    }
  };

  makeSearch = () => {
    const { searchQuery, justSelectedSuggestionIndex, suggestions } =
      this.state;
    this.props.resetSortOptions();

    const queryString = suggestions[justSelectedSuggestionIndex]
      ? suggestions[justSelectedSuggestionIndex].attributes.title
      : searchQuery;

    const strippedValue = formatSearchQuery(queryString);
    const encodedValue = encodeURIComponent(strippedValue);

    GAonInsightsDatabaseSearch(strippedValue);

    this.setState({ suggestions: [] });

    const value = `search=${encodedValue}`;
    const searchValue = {
      type: 'search',
      value: encodedValue,
    };

    if (encodedValue.length === 0) {
      this.setState({ searchString: '' });
      this.props.onChange(searchValue);
      this.setUrlOnSearchUpdate('');
    } else {
      this.setState({ searchString: `${value}` });
      this.props.onChange(searchValue);
      this.setUrlOnSearchUpdate(value);
    }
  };

  resetSearch = () => {
    this.setState({ searchQuery: '', suggestions: [] }, () => {
      this.searchInput.focus();
    });
  };

  SearchWhenHittingReturn = (e) => {
    if (e.keyCode === 13) {
      this.makeSearch();
    }
  };

  render() {
    const { searchQuery, suggestions, justSelectedSuggestionIndex } =
      this.state;
    const cancelBtnClasses = classnames('search-box__cancel-btn', {
      'search-box__cancel-btn--active': searchQuery.length,
    });
    const inputValue = suggestions[justSelectedSuggestionIndex]
      ? suggestions[justSelectedSuggestionIndex].attributes.title
      : searchQuery;

    return (
      <ClickAwayListener onClickAway={this.handleInputBlur}>
        <div className="search-box__holder">
          <input
            type="text"
            name="search"
            id="search"
            autoComplete="off"
            className="search-box__field"
            placeholder="Search for keywords eg. AI, Security"
            ref={(e) => (this.searchInput = e)}
            value={inputValue}
            onChange={this.handleInputChange}
            onKeyDown={(e) => this.SearchWhenHittingReturn(e)}
          />
          <span className={cancelBtnClasses} onClick={this.resetSearch}>
            <CancelIcon />
          </span>
          <button className="search-box__button" onClick={this.makeSearch}>
            <SearchIcon className="search-box__icon" />
          </button>
        </div>
      </ClickAwayListener>
    );
  }
}

SearchBox.propTypes = {
  onChange: PropTypes.func,
  onMount: PropTypes.func,
};

export default withRouter(SearchBox);
