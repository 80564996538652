import * as API from 'api/sharepointdocuments';

import {
  createEmptyFolderEntry,
  createFolderEntryFromPathObject,
  parseFolderEntries,
} from '../../parseFolderEntries';

const validationMessage =
  'We appreciate your creativity, but these characters are not allowed in document or folder names: " * : < > ? / \\ |';

const createFolder = async (folderName, path, name) => {
  const response = await API.addFolder(folderName, path, name);
  return response.data.data.name;
};

const readFolder = async (folderName, path) => {
  const response = await API.readFolder(folderName, path);
  return response.data.data;
};

const renameItem = async (folderName, path, newName) => {
  const response = await API.updateItem(folderName, path, newName);
  return response.data.data.name;
};

const deleteItem = async (folderName, path, id, type) => {
  const response = await API.deleteItem(folderName, path, id, type);
  return response.data.data;
};

const validateCharacter = (name) => {
  const regex = /["*:<>?/\\|]/;
  return regex.test(name);
};

const onConfirmNewFileName = async (
  setFolderEntries,
  onChange,
  onAddError,
  rootFolderName,
  item,
  containingFolder,
  name
) => {
  try {
    const isCharacterInvalid = validateCharacter(name);

    if (isCharacterInvalid) {
      onAddError(validationMessage, false);
      return;
    }

    await renameItem(rootFolderName, item.getInclusiveNodePath(), name);
    const result = await readFolder(rootFolderName, item.getNodePath());
    setFolderEntries(
      parseFolderEntries(result, containingFolder, rootFolderName, true)
    );
    onChange({ count: result.length });
  } catch (e) {
    if (e.response?.status === 423) {
      onAddError(
        "The folder or a file inside the folder you're trying to rename is open in another program. Close it and try again.",
        false
      );
      return;
    }
    onAddError(e.message, false);
  }
};

const onConfirmFileDeletion = async (
  setFolderEntries,
  onChange,
  onAddError,
  rootFolderName,
  containingFolder,
  item
) => {
  try {
    await deleteItem(
      rootFolderName,
      item.getInclusiveNodePath(),
      item.id,
      item.type
    );
    const result = await readFolder(rootFolderName, item.getNodePath());
    setFolderEntries(
      parseFolderEntries(result, containingFolder, rootFolderName, true)
    );
    onChange({ count: result.length });
  } catch (e) {
    if (e.response?.status === 423 || e.response?.status === 409) {
      onAddError(
        "The folder or a file inside the folder you're trying to delete is open in another program. Close it and try again.",
        false
      );
      return;
    }
    onAddError(e.message, false);
  }
};

const onConfirmCreateNewFolder = (
  setFolderEntries,
  onChange,
  onAddError,
  rootFolderName,
  containingFolder,
  name
) =>
  (async () => {
    try {
      const isCharacterInvalid = validateCharacter(name);

      if (isCharacterInvalid) {
        onAddError(validationMessage, false);
        return;
      }

      await createFolder(
        rootFolderName,
        containingFolder.getInclusiveNodePath(),
        name
      );
      const result = await readFolder(
        rootFolderName,
        containingFolder.getInclusiveNodePath()
      );
      setFolderEntries(
        parseFolderEntries(result, containingFolder, rootFolderName, true)
      );
      onChange({ count: result.length });
    } catch (e) {
      onAddError(e.message);
    }
  })();

export const onNavigateToFolderAction = (
  setCurrentFolder,
  setLoadingFailed,
  setFolderEntries,
  onAddError,
  rootFolderName,
  folder
) =>
  (async () => {
    setCurrentFolder(folder);
    setLoadingFailed(false);
    setFolderEntries(null);
    const newPath = folder.createAppendedPathName(folder.name);
    try {
      const result = await readFolder(rootFolderName, newPath);
      setFolderEntries(
        parseFolderEntries(result, folder, rootFolderName, true)
      );
    } catch (e) {
      onAddError(e.message);
    }
  })();

export const onNavigateToRootFolderAction = (
  changeCurrentFolderAndEntries,
  rootFolderName
) => changeCurrentFolderAndEntries(rootFolderName, createEmptyFolderEntry());

export const onNavigateToFolderInPathAction = (
  changeCurrentFolderAndEntries,
  rootFolderName,
  folder
) =>
  changeCurrentFolderAndEntries(
    rootFolderName,
    createFolderEntryFromPathObject(folder)
  );

export const onDeleteFileAction = (
  showModal,
  setFolderEntries,
  onChange,
  onAddError,
  rootFolderName,
  containingFolder,
  item
) =>
  showModal(
    'confirmFileAction',
    `Delete ${item.type} with name ${item.name}?`,
    () =>
      onConfirmFileDeletion(
        setFolderEntries,
        onChange,
        onAddError,
        rootFolderName,
        containingFolder,
        item
      )
  );

export const onRenameFileAction = (
  showModal,
  setFolderEntries,
  onChange,
  onAddError,
  rootFolderName,
  containingFolder,
  item
) =>
  showModal(
    'fileNameEntry',
    {
      buttonLabel: 'Rename',
      fileName: item.name,
      message: `Enter new name for ${item.type} ${item.name}`,
    },
    (name) =>
      onConfirmNewFileName(
        setFolderEntries,
        onChange,
        onAddError,
        rootFolderName,
        item,
        containingFolder,
        name
      )
  );

export const onClickUploadFile = (
  showModal,
  changeCurrentFolderAndEntries,
  rootFolderName,
  folder
) =>
  showModal(
    'fileUpload',
    {
      message: 'Upload a new file',
      folderName: rootFolderName,
      path: folder.getInclusiveNodePath(),
    },
    () => {
      changeCurrentFolderAndEntries(rootFolderName, folder);
    }
  );

export const onClickCreateNewFolder = (
  showModal,
  setFolderEntries,
  onChange,
  onAddError,
  rootFolderName,
  containingFolder
) =>
  showModal(
    'fileNameEntry',
    {
      buttonLabel: 'Add',
      message: 'Create a new folder',
    },
    (name) =>
      onConfirmCreateNewFolder(
        setFolderEntries,
        onChange,
        onAddError,
        rootFolderName,
        containingFolder,
        name
      )
  );

export const onChangeCurrentFolderAndEntriesAction = async (
  rootFolderName,
  folder,
  onChange,
  onAddError,
  setCurrentFolder,
  setLoadingFailed,
  setFolderEntries
) => {
  setCurrentFolder(folder);
  setLoadingFailed(false);
  setFolderEntries(null);
  try {
    const result = await readFolder(
      rootFolderName,
      folder.getInclusiveNodePath()
    );
    setFolderEntries(parseFolderEntries(result, folder, rootFolderName, true));
    onChange({ count: result.length });
  } catch (e) {
    onAddError(e.message);
  }
};
