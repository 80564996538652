import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const getArticle = (id) =>
  createRequest({
    method: 'get',
    url: `${cms}/news/article/${id}`,
    errorMessages: {
      '*': 'Failed to get article, please contact support for assistance.',
    },
  });

export const updateArticle = (id, data) =>
  createRequest({
    method: 'put',
    url: `${cms}/news/article/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update article, please contact support for assistance.',
    },
  });

export const createArticle = (data) =>
  createRequest({
    method: 'post',
    url: `${cms}/news/article`,
    data,
    errorMessages: {
      '*': 'Failed to create article, please contact support for assistance.',
    },
  });

export const deleteArticle = (id) =>
  createRequest({
    method: 'delete',
    url: `${cms}/news/article/${id}`,
    errorMessages: {
      '*': 'Failed to delete article, please contact support for assistance.',
    },
  });
