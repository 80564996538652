import './InfoBox.scss';

import { ReactComponent as Icon } from 'components/Icon/Info.svg';
import { useState } from 'react';

const InfoIconButton = ({ onClick }: InfoIconButtonProps) => {
  return (
    <div onMouseEnter={() => onClick(true)} onMouseLeave={() => onClick(false)}>
      <Icon color="black" width={15} height={15} />
    </div>
  );
};

const InfoMessageBox = ({ display, message }: InfoMessageBoxProps) => {
  if (!display) return null;
  return <div className="info-box__message-box">{message}</div>;
};

const InfoBox = ({ message }: InfoBoxProps) => {
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  return (
    <div className="info-box">
      <InfoIconButton onClick={setDisplayMessage} />
      <InfoMessageBox display={displayMessage} message={message} />
    </div>
  );
};

export default InfoBox;
