import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import React from 'react';

type FormButtonsProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode | React.ReactNode[];
};

const FormButtons = ({ sx, children }: FormButtonsProps) => {
  return (
    <Stack
      sx={[
        (theme) => ({
          flexDirection: { xs: 'column', sm: 'row' },
          marginTop: theme.spacing('md'),
          gap: theme.spacing('sm'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Stack>
  );
};

export { FormButtons };
