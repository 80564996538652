import './ExpandableImage.scss';

import classNames from 'classnames';
import { ReactComponent as CancelIcon } from 'components/Icon/Cancel.svg';
import { ReactComponent as EnlargeImageIcon } from 'components/Icon/EnlargeImage.svg';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

const states = {
  INITIAL: 'INITIAL',
  IMAGE_LOADED: 'IMAGE_LOADED',
  LIGHTBOX_LOADING: 'LIGHTBOX_LOADING',
  OPENED: 'OPENED',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
  START_FADE_IN: 'START_FADE_IN',
};

const fadeoutPeriodMs = 200;

class ExpandableImage extends PureComponent {
  state = {
    error: false,
    lightboxLoaded: false,
    displayState: states.INITIAL,
  };

  imageContainerRef = null;
  imageRef = null;

  setImageWidth = () => {
    const { maxWidth, maxHeight, measureContentWidth } = this.props;
    if (this.imageContainerRef && maxWidth) {
      const measuredBodyWidth = measureContentWidth();
      let useWidth = measuredBodyWidth;
      const maxStyledImageHeight = 610;
      if (maxHeight > maxStyledImageHeight) {
        useWidth = maxStyledImageHeight * (maxWidth / maxHeight);
      }
      if (maxWidth !== null && measuredBodyWidth !== null) {
        useWidth = Math.min(maxWidth, useWidth, measuredBodyWidth);
      }
      this.imageContainerRef.setAttribute('style', `width: ${useWidth}px;`);
      this.imageRef &&
        this.imageRef.setAttribute('style', `width: ${useWidth}px;`);
    }
  };

  componentDidUpdate() {
    this.setImageWidth();
  }

  onLoad = (...e) => {
    const { onLoad } = this.props;
    this.setState({ displayState: states.IMAGE_LOADED });
    onLoad && onLoad(...e);
    this.setImageWidth();
  };

  onLightboxLoad = () => {
    this.setState(() => ({ displayState: states.OPENED }));
  };

  onError = (...e) => {
    const { onError } = this.props;
    this.setState({ error: true });
    onError && onError(...e);
  };

  openLightboxOnClickHandler = () => {
    let newState = null;
    this.setState(
      ({ lightboxLoaded }) => {
        newState = lightboxLoaded
          ? states.START_FADE_IN
          : states.LIGHTBOX_LOADING;
        return {
          displayState: newState,
          lightboxLoaded: true,
        };
      },
      () =>
        setTimeout(() => {
          if (newState === states.START_FADE_IN) {
            this.setState(() => ({ displayState: states.OPENED }));
          }
        }, 0)
    );
  };

  closeLightboxOnClickHandler = () =>
    this.setState(
      () => ({
        displayState: states.CLOSING,
      }),
      () =>
        setTimeout(
          () => this.setState({ displayState: states.CLOSED }),
          fadeoutPeriodMs
        )
    );

  renderImage(extraClassName, onLoad, refName) {
    const { alt, src, className } = this.props;
    return (
      <img
        onLoad={onLoad}
        onError={this.onError}
        src={src}
        alt={alt}
        title={alt}
        className={`${className} ${extraClassName}`}
        ref={(ref) => refName && (this[refName] = ref)}
      />
    );
  }

  isLightboxLoading = ({ displayState: state }) =>
    state === states.LIGHTBOX_LOADING || state === states.START_FADE_IN;

  isClosing = ({ displayState: state }) => state === states.CLOSING;

  isDisplayHidden = ({ displayState: state }) =>
    state !== states.LIGHTBOX_LOADING &&
    state !== states.OPENED &&
    state !== states.CLOSING &&
    state !== states.START_FADE_IN;

  isImageClickable = ({ displayState: state }) =>
    state === states.INITIAL ||
    state === states.IMAGE_LOADED ||
    state === states.CLOSED;

  isImageLoaded = ({ displayState: state }) => state !== states.INITIAL;

  shouldRenderLightbox = ({ displayState: state }) =>
    state !== states.INITIAL && state !== states.IMAGE_LOADED;

  render() {
    const lightboxClassNames = classNames(
      'expandable-image__lightbox-background',
      {
        'expandable-image__lightbox-background--lightbox-loading':
          this.isLightboxLoading(this.state),
        'expandable-image__lightbox-background--fade-out': this.isClosing(
          this.state
        ),
        'expandable-image__lightbox-background--hidden': this.isDisplayHidden(
          this.state
        ),
      }
    );
    return (
      <div
        className="expandable-image__original-image-container"
        ref={(ref) => (this.imageContainerRef = ref)}
        onClick={
          this.isImageClickable(this.state)
            ? this.openLightboxOnClickHandler
            : undefined
        }
      >
        {this.renderImage('', this.onLoad, 'imageRef')}
        {this.isImageLoaded(this.state) && (
          <EnlargeImageIcon className="expandable-image__icon" />
        )}
        {this.shouldRenderLightbox(this.state) && (
          <div className={lightboxClassNames}>
            <div className="expandable-image__lightbox-frame">
              <CancelIcon
                className="expandable-image__close-button"
                onClick={this.closeLightboxOnClickHandler}
              />
              {this.renderImage(
                'expandable-image__lightbox-image',
                this.onLightboxLoad
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ExpandableImage.propTypes = {
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  measureContentWidth: PropTypes.func.isRequired,
};

export default ExpandableImage;
