import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const DisplayOnHome = ({ label = 'Display on Avenue home' }) => (
  <Consumer>
    {({ onToggleArticleValue, article: { displayOnHome } }) => (
      <>
        <Flex>
          <Checkbox
            defaultChecked={displayOnHome}
            id="displayOnHome"
            onChange={() => onToggleArticleValue('displayOnHome')}
          />
          <label htmlFor="displayOnHome" className="m-l-3">
            {label}
          </label>
        </Flex>
      </>
    )}
  </Consumer>
);

export default DisplayOnHome;
