import Text from 'components/Editable/Text';

import { Consumer } from '..';

interface CommentsTargetIdProps {
  color?: string;
  placeholder?: string;
  hashtag?: string;
  allowedCharacters?: string;
  fieldName?: string;
}

const fieldValidator = (value?: string) => {
  const lcValue = (value ? value : '').toLowerCase();

  if (lcValue === '') {
    return 'Comments Target Id cannot be empty';
  }

  return null;
};

const CommentsTargetId = ({
  color,
  placeholder,
  hashtag,
  allowedCharacters,
  fieldName = 'commentsTargetId',
}: CommentsTargetIdProps) => (
  <Consumer>
    {({
      article,
      onChangeArticle,
      validationParameters: {
        [fieldName]: { maxLength },
      },
      showInitialError,
    }) => (
      <Text
        wrapper={{
          element: 'div',
          className: 'preamble',
        }}
        placeholder={placeholder}
        onBlur={(value: any) => onChangeArticle({ [fieldName]: value })}
        validators={[fieldValidator]}
        showInitialError={showInitialError}
        content={article[fieldName]}
        maxLength={maxLength}
        allowedCharacters={allowedCharacters}
        color={color}
        hashtag={hashtag}
      />
    )}
  </Consumer>
);

export default CommentsTargetId;
