import './Title.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

const Title = ({
  tag: Tag = 'h1',
  children,
  border = 'blue',
  className,
  size = 'medium',
  spacing,
  color = 'black',
  mobileColor,
}) => {
  const titleClassName = classNames('title', {
    [`title--${border}`]: border,
    [`title__size--${size}`]: size,
    [`title__spacing--${spacing}`]: spacing,
    [`title--color-${color}`]: color,
    [`title--mobile-color-${mobileColor}`]: mobileColor,
    [className]: className,
  });
  return (
    <div className={titleClassName}>
      <Tag>{children}</Tag>
    </div>
  );
};

Title.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  border: PropTypes.oneOf([
    'blue',
    'green',
    'red',
    'orange',
    'portal-related',
    'none',
  ]),
  color: PropTypes.oneOf(['white', 'black']),
  mobileColor: PropTypes.oneOf(['black']),
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  spacing: PropTypes.oneOf(['no-top-margin']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export { Title };
export default memo(Title);
