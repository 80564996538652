import './GradientBlock.scss';

import EnhancedLink from 'components/EnhancedLink';
import Parser from 'components/Parser';
import { FC } from 'react';
import { GradientBlockProps } from 'types/Portal';
import { anchorLinkFormatter } from 'utils/misc/jumpToAnchor';

const GradientBlock: FC<GradientBlockProps> = ({
  textColor,
  title,
  body,
  linkUrl,
  linkButtonText,
  id,
  getHeroImage,
}) => {
  const linkStyles = {
    color: textColor,
    border: `1px solid ${textColor}`,
  };

  const getButtonText = (linkButtonText: string): string => {
    return linkButtonText ? linkButtonText : 'Learn more';
  };

  return (
    <div className="gradient-block">
      <div className="gradient-block__inner-content-holder">
        <div className="gradient-block__inner-content-box">
          <div
            style={{ color: textColor }}
            className="gradient-block__inner-content"
          >
            <strong className="gradient-block__title">
              <a href={anchorLinkFormatter(window.location, id)}>{title}</a>
            </strong>
            <div className="gradient-block__description">
              <Parser html={body} type="Portal" />
            </div>
            <EnhancedLink
              style={linkStyles}
              className="gradient-block__link"
              to={linkUrl}
            >
              {getButtonText(linkButtonText)}
            </EnhancedLink>
          </div>
        </div>
      </div>
      <div
        className="gradient-block__inner-image"
        style={{ backgroundImage: `url(${getHeroImage(700)})` }}
      >
        <div className="gradient-block__inner-image-gradient"></div>
      </div>
    </div>
  );
};

export default GradientBlock;
