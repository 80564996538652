import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const PriorityField = () => (
  <Consumer>
    {({ onToggleArticleValue, article: { isPriority } }) => (
      <>
        <Flex>
          <Checkbox
            defaultChecked={isPriority}
            id="priorityItem"
            onChange={() => onToggleArticleValue('isPriority')}
          />
          <label htmlFor="priorityItem" className="m-l-3">
            Featured article
          </label>
        </Flex>
      </>
    )}
  </Consumer>
);

export default PriorityField;
