import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useState } from 'react';

export type TabItem = {
  id: string;
  label: string;
  tooltip?: string;
  content: React.ReactNode;
};

type ButtonTabsProps = {
  items: TabItem[];
  ariaLabel?: string;
  sx?: SxProps<Theme>;
  onChange?: (
    e: React.SyntheticEvent<Element, Event>,
    tabIndex: number
  ) => void;
};

const ButtonTabs = ({ items, ariaLabel, sx, onChange }: ButtonTabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  return (
    <Box sx={sx}>
      <Tabs
        value={activeTabIndex}
        aria-label={ariaLabel}
        variant="scrollable"
        allowScrollButtonsMobile={true}
        selectionFollowsFocus={true}
        onChange={(
          e: React.SyntheticEvent<Element, Event>,
          tabIndex: number
        ) => {
          setActiveTabIndex(tabIndex);
          onChange && onChange(e, tabIndex);
        }}
        sx={(theme) => ({
          minHeight: 0,
          '.MuiButtonBase-root': {
            height: 'unset',
            fontSize: theme.typography.body2.fontSize,
          },
          '.MuiTabs-indicator': {
            display: 'none',
          },
        })}
      >
        {items.map((item, index) => (
          <Tab
            id={item.id}
            key={`tab-${index}`}
            aria-label={item.label}
            aria-controls={`tab-panel-${index}`}
            title={item.tooltip}
            label={item.label}
            sx={(theme) => ({
              color: theme.colors.text.disabled,
              backgroundColor: theme.colors.surface.primary,
              transition: 'all 0.2s ease',
              fontWeight: 600,
              fontSize: theme.typography.pxToRem(14),
              padding: `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
              marginRight: theme.spacing('xxxs'),
              minWidth: 0,
              minHeight: 0,
              borderRadius: '4px',
              ':last-of-type': {
                marginRight: 0,
              },
              ':hover': {
                color: theme.colors.text.inversePrimary,
                backgroundColor: theme.colors.surface.actionPrimaryHover,
              },
              '&.Mui-selected': {
                color: theme.colors.text.inversePrimary,
                backgroundColor: theme.colors.surface.actionPrimaryDefault,
              },
            })}
          />
        ))}
      </Tabs>
      {items.map((item, index) => (
        <Box
          role="tabpanel"
          id={`tab-panel-${index}`}
          key={`tab-panel-${index}`}
          aria-labelledby={item.id}
          hidden={index !== activeTabIndex}
          sx={(theme) => ({
            marginTop: theme.spacing('sm'),
            marginBottom: theme.spacing('sm'),
          })}
        >
          {index === activeTabIndex && item.content}
        </Box>
      ))}
    </Box>
  );
};

export { ButtonTabs };
