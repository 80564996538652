import Box from '@mui/material/Box';
import confetti, { CreateTypes } from 'canvas-confetti';
import { useCallback, useEffect, useRef } from 'react';

import {
  getRandomColor,
  getRandomConfettiShape,
  randomInRange,
} from './helpers';

const CONFETTI_DELAY = 65;

type ConfettiCanvasProps = { height: number };

const ConfettiCanvas = ({ height }: ConfettiCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const confettiRef = useRef<CreateTypes | null>(null);
  const lastAnimationTimestampRef = useRef<number>(0);
  const lastAnimationFrameIdRef = useRef<number | null>(0);

  const tickAnimation = useCallback(() => {
    if (!confettiRef.current) return;

    confettiRef.current({
      particleCount: 1,
      startVelocity: 0,
      ticks: 800,
      origin: {
        x: Math.random(),
        y: -0.1,
      },
      shapes: [getRandomConfettiShape()],
      colors: [getRandomColor()],
      gravity: randomInRange(0.5, 0.7),
      scalar: randomInRange(1.3, 3),
      drift: randomInRange(-1, 1),
    });
  }, []);

  const confettiAnimation = useCallback(
    (timestamp: number) => {
      if (timestamp - lastAnimationTimestampRef.current >= CONFETTI_DELAY) {
        tickAnimation();

        lastAnimationTimestampRef.current = timestamp;
      }

      if (!lastAnimationFrameIdRef.current) return;
      lastAnimationFrameIdRef.current =
        requestAnimationFrame(confettiAnimation);
    },
    [tickAnimation]
  );

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    confettiRef.current = confetti.create(canvasRef.current, {
      resize: true,
      useWorker: true,
      disableForReducedMotion: true,
    });

    lastAnimationFrameIdRef.current = requestAnimationFrame(confettiAnimation);

    return () => {
      if (confettiRef.current) {
        confettiRef.current.reset();
      }
      if (lastAnimationFrameIdRef.current) {
        cancelAnimationFrame(lastAnimationFrameIdRef.current);
        lastAnimationFrameIdRef.current = null;
      }
    };
  }, [confettiAnimation]);

  if (!height) {
    return null;
  }

  return (
    <Box
      id="confetti-canvas"
      component="canvas"
      ref={canvasRef}
      height={height}
      sx={{ width: '100%' }}
    />
  );
};

export { ConfettiCanvas };
