import { AlertDialog } from 'componentsNew';
import { translations } from 'translations';

type DialogUnpublishProps = {
  id: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DialogUnpublish = ({
  id,
  open,
  loading,
  onClose,
  onConfirm,
}: DialogUnpublishProps) => {
  return (
    <AlertDialog
      id={id}
      open={open}
      type="warning"
      title={translations.manageContentActionsUnpublishDialogTitle}
      paragraphs={[translations.manageContentActionsUnpublishDialogText]}
      primaryButton={{
        loading: loading,
        disabled: loading,
        text: translations.manageContentActionsUnpublish,
        onClick: onConfirm,
      }}
      secondaryButton={{
        disabled: loading,
        text: translations.cancel,
        onClick: onClose,
      }}
      disableClose={loading}
      onClose={onClose}
    />
  );
};
export { DialogUnpublish };
