import { InputProps } from '@mui/material/Input';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useMemo } from 'react';

import { ReactComponent as SelectIcon } from './SelectIcon.svg';

const EMPTY_VALUE = 'none' as const;

export type SelectItem = { name: string; value: string; disabled?: boolean };

type SingleSelectProps = {
  id?: string;
  multiple?: false;
  value: SelectItem | null;
  items: SelectItem[];
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  // "allowEmpty" toggle only works for single select, since
  // multiple select depends on user being able to unselect items.
  allowEmpty?: boolean;
  placeholder?: string;
  inputProps?: InputProps['inputProps'];
  sx?: SxProps<Theme>;
  onChange: (value: SelectItem | null) => void;
  onBlur?: () => void;
};

type MultipleSelectProps = {
  multiple: true;
  value: SelectItem[];
  onChange: (value: SelectItem[]) => void;
} & Omit<SingleSelectProps, 'multiple' | 'value' | 'onChange'>;

export type SelectProps = SingleSelectProps | MultipleSelectProps;

const Select = ({
  id,
  value,
  items,
  error,
  disabled,
  fullWidth,
  allowEmpty = false,
  placeholder,
  inputProps,
  sx,
  onChange,
  onBlur,
  multiple,
}: SelectProps) => {
  const selectValue = useMemo(() => {
    if (multiple) {
      return !value.length ? [EMPTY_VALUE] : value.map((item) => item.value);
    }
    return value?.value || EMPTY_VALUE;
  }, [value, multiple]);

  const isSelectValueEmpty = useMemo(() => {
    if (multiple) {
      return selectValue[0] === EMPTY_VALUE;
    }
    return selectValue === EMPTY_VALUE;
  }, [multiple, selectValue]);

  return (
    <MuiSelect
      id={id}
      size="small"
      variant="outlined"
      fullWidth={fullWidth}
      value={selectValue}
      error={error}
      disabled={disabled}
      multiple={multiple}
      inputProps={inputProps}
      MenuProps={{ disablePortal: true }}
      IconComponent={SelectIcon}
      renderValue={
        placeholder && isSelectValueEmpty ? () => placeholder : undefined
      }
      sx={[
        (theme) => ({
          ...(!isSelectValueEmpty && { color: theme.colors.text.tertiary }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onChange={(e) => {
        if (!multiple) {
          const targetValue = e.target.value as string;
          const newValue =
            items.find((item) => item.value === targetValue) || null;
          onChange(newValue);
          return;
        }
        const targetValues = e.target.value as string[];

        const selectedEmpty =
          targetValues.some((value) => value === EMPTY_VALUE) &&
          !isSelectValueEmpty;

        if (selectedEmpty) {
          onChange([]);
          return;
        }
        const newValues = items.filter((item) =>
          targetValues.some((targetValue) => targetValue === item.value)
        );
        onChange(newValues);
        return;
      }}
      onBlur={onBlur}
    >
      {placeholder && (
        <MuiMenuItem
          disabled={!allowEmpty}
          value={EMPTY_VALUE}
          sx={(theme) => ({
            color: theme.colors.text.tertiary,
            textWrapMode: 'wrap',
          })}
        >
          {placeholder}
        </MuiMenuItem>
      )}
      {items.map((item, index) => (
        <MuiMenuItem
          key={item.value || `select-${index}`}
          value={item.value}
          disabled={item.disabled}
          sx={{ textWrapMode: 'wrap' }}
        >
          {item.name}
        </MuiMenuItem>
      ))}
    </MuiSelect>
  );
};

export { Select };
