import './CustomBlockBox.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import EnhancedLink from 'components/EnhancedLink';
import Iframe from 'components/Iframe';
import Parser from 'components/Parser';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { createEmbedUrl } from 'utils/cms/parseVideoUrls';
import { anchorLinkFormatter, jumpToAnchor } from 'utils/misc/jumpToAnchor';

class CustomBlockBox extends Component {
  boxElementRef = null;

  componentDidMount() {
    const { hash } = window.location;
    if (hash.length > 1 && hash.substring(1) === this.props.block.id) {
      jumpToAnchor(this.boxElementRef);
    }
  }

  getBlockFields(side, block) {
    const keys = [
      'display',
      'title',
      'preamble',
      'body',
      'heroVideo',
      'heroImage',
      'heroAltText',
      'heroImageWidth',
      'linkButtonText',
      'linkUrl',
      'backgroundColor',
      'textColor',
      'isImageFullyVisible',
    ];
    const commonKeys = ['reverse', 'template'];
    const attributes = {};
    const nkotb = { ...block, attributes };
    for (let key of keys) {
      if (side === 'left') {
        attributes[key] = block.attributes[`${key}Left`];
      } else if (side === 'right') {
        attributes[key] = block.attributes[`${key}Right`];
      }
    }
    for (let key of commonKeys) {
      attributes[key] = block.attributes[key];
    }
    return nkotb;
  }

  render() {
    const { block } = this.props;

    const { attributes } = block;

    const sectionClassNames = classNames('custom-block-box', {
      'custom-block-box--reversed': attributes.reverse,
    });

    return (
      <div
        ref={(ref) => (this.boxElementRef = ref)}
        className={sectionClassNames}
      >
        {this.renderSide('left', this.getBlockFields('left', block))}
        {this.renderSide('right', this.getBlockFields('right', block))}
      </div>
    );
  }

  renderSide(side, block) {
    const { attributes } = block;
    const isDisplayInfoGraphic =
      attributes.display === 'infographic' || attributes.display === 'media';

    const linkButtonText = attributes.linkButtonText;

    const linkStyles = {
      color: attributes.textColor,
      border: `1px solid ${attributes.textColor}`,
    };

    const imageClassNames = classNames('custom-block-box__image-col', {
      'custom-block-box__video-bg': Boolean(attributes.heroVideo),
      'custom-block-box--inset-image': attributes.isImageFullyVisible,
    });

    const displayAsInfographicClassNames = classNames({
      'custom-block-box__display-infographic-title': isDisplayInfoGraphic,
      'custom-block-box__title': !isDisplayInfoGraphic,
    });

    const displayContentClassNames = classNames({
      'custom-block-box__display-infographic-content': isDisplayInfoGraphic,
      content: !isDisplayInfoGraphic,
    });

    return attributes.display === 'media' ? (
      <div
        className={imageClassNames}
        style={{ backgroundColor: attributes.backgroundColor }}
      >
        <img
          src={block.getHeroImage(700, side)}
          alt={attributes.heroAltText}
          title={attributes.heroAltText}
        />
        {attributes.heroVideo && (
          <Iframe title="media" src={createEmbedUrl(attributes.heroVideo)} />
        )}
      </div>
    ) : (
      <div
        className="custom-block-box__content-col"
        style={{
          color: attributes.textColor,
          backgroundColor: attributes.backgroundColor,
        }}
      >
        <div
          className="custom-block-box__content-wrapper"
          style={{
            color: attributes.textColor,
          }}
        >
          <h2 className={displayAsInfographicClassNames}>
            <a href={anchorLinkFormatter(window.location, block.id)}>
              {attributes.title}
            </a>
          </h2>
          {!isDisplayInfoGraphic && (
            <p className="custom-block-box__preamble">
              {block.attributes.preamble}
            </p>
          )}
          <div className={displayContentClassNames}>
            <Parser html={attributes.body} type="Portal" />
          </div>
          {attributes.linkUrl && (
            <EnhancedLink
              to={attributes.linkUrl}
              style={linkStyles}
              className="custom-block-box__link"
            >
              {linkButtonText && linkButtonText.trim() !== ''
                ? linkButtonText
                : 'Learn More'}
            </EnhancedLink>
          )}
        </div>
      </div>
    );
  }
}

CustomBlockBox.propTypes = {
  block: PropTypes.instanceOf(PortalNode),
};

export default CustomBlockBox;
