import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';

const ProductOfTheWeekGalleryGridSkeleton = () => {
  return (
    <Grid
      container
      spacing={1}
      sx={(theme) => ({ padding: theme.spacing('md') })}
    >
      {Array(12)
        .fill(0)
        .map((_, index) => (
          <Grid
            key={`product-of-the-week-gallery-skeleton-${index}`}
            xs={12}
            sm={6}
            md={4}
            lg={3}
          >
            <Skeleton
              variant="rounded"
              animation="pulse"
              sx={{
                width: '100%',
                aspectRatio: 0.825,
                height: 'unset',
              }}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export { ProductOfTheWeekGalleryGridSkeleton };
