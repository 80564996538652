import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Content = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={570}
    preserveAspectRatio="none"
    style={{ height: '570px' }}
  >
    <rect x={0} y={6} width={getRandomNumberBetween(350, 500)} height={36} />
    <rect x={65} y={80} width={290} height={12} />

    <rect x={0} y={140} width={getRandomNumberBetween(550, 670)} height={30} />

    <rect x={0} y={300} width={getRandomNumberBetween(400, 670)} height={60} />

    <rect x={0} y={240} width={getRandomNumberBetween(500, 670)} height={12} />
    <rect x={0} y={260} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={280} width={getRandomNumberBetween(400, 670)} height={12} />

    <rect x={0} y={400} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={420} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={440} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={460} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={480} width={getRandomNumberBetween(400, 670)} height={12} />
  </ContentLoader>
);

const Loader = () => (
  <Resize>{({ width }) => <Content width={width} />}</Resize>
);

export default Loader;
