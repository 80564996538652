import { en } from './en';
import { Language, Translations } from './translations';

/**
 * Currently we do not offer users the possibility to change language
 * on the whole application. But in order to be prepared for such feature
 * in the future, we gather all our labels here, in one place.
 */

const activeLanguage: Language = 'en';

const translationsByLanguage: Record<Language, Translations> = {
  en,
};

const translations = translationsByLanguage[activeLanguage];

export { translations };
