import { createContext, useContext, useState } from 'react';

const NextArticleContext = createContext();

const { Consumer: NextArticleConsumer, Provider } = NextArticleContext;

const NextArticleProvider = (props) => {
  const [nextArticleIndex, setNextArticleIndex] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [isNextArticleVisible, setIsNextArticleVisible] = useState(true);

  const updateArticleIndex = (currentIndex) => {
    // Increment index for the next article
    setNextArticleIndex(parseInt(currentIndex) + 1);
  };

  const updateCurrentFilter = (filter) => {
    // Remove the pagination
    setCurrentFilter({ ...filter, pagination: undefined });
  };

  return (
    <Provider
      value={{
        nextArticleIndex,
        currentFilter,
        isNextArticleVisible,
        updateArticleIndex,
        updateCurrentFilter,
        setIsNextArticleVisible,
      }}
    >
      {props.children}
    </Provider>
  );
};

export const useNextArticle = () => {
  const context = useContext(NextArticleContext);
  if (!context) {
    throw new Error('NextArticleContext is not defined');
  }
  return context;
};

export default NextArticleProvider;
export { NextArticleConsumer };
