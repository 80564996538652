import axios from 'axios';

const makeRequest = ({ method = 'get', url, errorMessages = {}, ...props }) => {
  return axios({
    method,
    url,
    transformResponse: [
      (data) => {
        try {
          const parsed = JSON.parse(data);
          parsed.error = errorMessages[parsed.status] || errorMessages['*'];
          return parsed;
        } catch (error) {
          return {
            data,
            error: errorMessages,
          };
        }
      },
    ],
    ...props,
    headers: Object.assign(
      {
        // make sure IE11 does not cache results, this creates a lot of problems
        // especially in the library when the navigation has updated.
        'Cache-control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: 0,
      },
      props.headers
    ),
  });
};

const populateIncluded = (included, links) => {
  if (!links) {
    return included;
  }

  const out = included || [];

  const linksArray = Object.values(links);

  const promises = [];

  linksArray.forEach((link) => {
    if (link.meta && link.meta.containsRelationshipData === true && link.href) {
      const promise = makeRequest({
        url: link.href,
        method: 'get',
      }).then((response) => {
        return {
          type: link.meta.type,
          data: response.data.data,
        };
      });

      promises.push(promise);
    }
  });

  return Promise.all(promises).then((responses) => {
    responses.forEach(({ data, type }) => {
      if (data && Array.isArray(data)) {
        data.forEach((item) => {
          out.push({
            type,
            id: item.id,
            attributes: { ...item.attributes, id: item.id },
          });
        });
      }
    });

    return out;
  });
};

const createRequest = async (params) => {
  const response = await makeRequest(params);

  if (!response || !response.data || response.status !== 200) {
    return response;
  }

  let data, included, meta, links;

  // Normalize non-standard responses from the cms
  if (Array.isArray(response.data)) {
    // cms sends an array as data
    data = response.data;
  } else if (
    !response.data.data &&
    !response.data.meta &&
    !response.data.included &&
    !response.data.links
  ) {
    // cms sends data directly in data object
    data = response.data;
  } else {
    // the way it should work
    data = response.data.data;
    included = response.data.included;
    meta = response.data.meta;
    links = response.data.links;
  }

  const populatedIncludes = await populateIncluded(included, links);

  const out = {
    ...response,
    data: {
      data,
      included: populatedIncludes,
      meta,
      links,
    },
  };

  return out;
};

export default createRequest;
