import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { cms } = settings.api;
const route = `${cms}/startpagehero`;

export const createHeroItem = (data) =>
  createRequest({
    method: 'post',
    url: `${route}`,
    data,
    errorMessages: {
      '*': 'Failed to get Hero Item, please contact support for assistance.',
    },
  });

export const getHomeBanner = (id) =>
  createRequest({
    method: 'get',
    url: `${route}/banner/${id}`,
    errorMessages: {
      '*': 'Failed to get Hero Item, please contact support for assistance.',
    },
  });

export const getHeroItem = (id) =>
  createRequest({
    method: 'get',
    url: `${route}/${id}`,
    errorMessages: {
      '*': 'Failed to get Hero Item, please contact support for assistance.',
    },
  });

export const getAllHeroItem = () =>
  createRequest({
    method: 'get',
    url: `${route}`,
    errorMessages: {
      '*': 'Failed to get Hero Item, please contact support for assistance.',
    },
  });

export const updateHeroItem = (id, data) =>
  createRequest({
    method: 'put',
    url: `${route}/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update Hero Item, please contact support for assistance.',
    },
  });

export const deleteHeroItem = (id) =>
  createRequest({
    method: 'delete',
    url: `${route}/${id}`,
    errorMessages: {
      '*': 'Failed to delete Hero Item, please contact support for assistance.',
    },
  });
