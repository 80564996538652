import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Loader = ({ width }) => (
  <div>
    <ContentLoader
      width={width}
      preserveAspectRatio="none"
      className="folder-view__content-loader"
      height={150}
    >
      <rect x={0} y={0} width={getRandomNumberBetween(300, 600)} height={10} />
      <rect x={800} y={0} width={50} height={10} />
      <rect x={870} y={0} width={80} height={10} />
      <rect x={1000} y={0} width={50} height={10} />
      <rect x={0} y={30} width={getRandomNumberBetween(300, 600)} height={10} />
      <rect x={800} y={30} width={50} height={10} />
      <rect x={870} y={30} width={80} height={10} />
      <rect x={1000} y={30} width={50} height={10} />
      <rect x={0} y={60} width={getRandomNumberBetween(300, 600)} height={10} />
      <rect x={800} y={60} width={50} height={10} />
      <rect x={870} y={60} width={80} height={10} />
      <rect x={1000} y={60} width={50} height={10} />
      <rect x={0} y={90} width={getRandomNumberBetween(300, 600)} height={10} />
      <rect x={800} y={90} width={50} height={10} />
      <rect x={870} y={90} width={80} height={10} />
      <rect x={1000} y={90} width={50} height={10} />
      <rect
        x={0}
        y={120}
        width={getRandomNumberBetween(300, 600)}
        height={10}
      />
      <rect x={800} y={120} width={50} height={10} />
      <rect x={870} y={120} width={80} height={10} />
      <rect x={1000} y={120} width={50} height={10} />
    </ContentLoader>
  </div>
);

export default Loader;
