import Error from 'components/Error';
import { ContentLoader } from 'components/JobsList/Item';
import Resize from 'components/Resize';
import { GAfailedToLoad } from 'utils/analytics';

export const LoadingList = ({ limit }) => (
  <Resize>
    {({ width }) =>
      [...Array(limit)].map((_, i) => <ContentLoader key={i} width={width} />)
    }
  </Resize>
);

export const onError = (status, message) => {
  GAfailedToLoad(status, message, 'jobs');
  return <Error message={message} />;
};
