import { AxiosResponse } from 'axios';
import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const getStory = (id: string): Promise<AxiosResponse> =>
  createRequest({
    method: 'get',
    url: `${cms}/story/${id}`,
    errorMessages: {
      '*': 'Failed to get article, please contact support for assistance.',
    },
  });

export const updateStory = (id: string, data: object): Promise<AxiosResponse> =>
  createRequest({
    method: 'put',
    url: `${cms}/story/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update article, please contact support for assistance.',
    },
  });

export const createStory = (data: object): Promise<AxiosResponse> =>
  createRequest({
    method: 'post',
    url: `${cms}/story/`,
    data,
    errorMessages: {
      '*': 'Failed to create article, please contact support for assistance.',
    },
  });

export const deleteStory = (id: string): Promise<AxiosResponse> =>
  createRequest({
    method: 'delete',
    url: `${cms}/story/${id}`,
    errorMessages: {
      '*': 'Failed to delete article, please contact support for assistance.',
    },
  });
