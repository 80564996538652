import './MiniSnackbarItem.scss';

import { ReactComponent as Cancel } from 'components/Icon/Cancel.svg';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const MiniSnackbarItem = ({ removed, content, status, onRemoved, onClose }) => {
  return (
    <Transition
      in={!removed}
      timeout={300}
      appear={!removed}
      onExited={onRemoved}
    >
      {(transitionStatus) => {
        const itemClassName =
          `mini-snackbar-item__message mini-snackbar-item__message--${transitionStatus} ` +
          `mini-snackbar-item__message--${status}`;
        const cancelButtonClassName =
          'mini-snackbar-item__close-button ' +
          `mini-snackbar-item__close-button--${status}`;
        return (
          <div className={itemClassName}>
            {content}
            <Cancel className={cancelButtonClassName} onClick={onClose} />
          </div>
        );
      }}
    </Transition>
  );
};

MiniSnackbarItem.propTypes = {
  content: PropTypes.string.isRequired,
  removed: PropTypes.bool.isRequired,
  status: PropTypes.oneOf(['success', 'error']).isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoved: PropTypes.func.isRequired,
};

export default MiniSnackbarItem;
