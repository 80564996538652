import * as heroBannerApi from 'api/cms/HeroBanner';
import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import { useUser } from 'components/Context/User';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FeatureToggle, featureToggle } from 'utils/misc/featureToggle';

import { isThirtyYearCelebrationBannerActive } from './helpers';

export type BannerData = {
  size?: HeroBannerSize;
  title?: string;
  description?: string;
  linkUrl?: string;
  linkButtonText?: string;
  heroImage?: string;
  heroAltText?: string;
  published?: boolean;
  publishedDate?: string;
};

const HomeBannerContext = React.createContext<{
  bannerData: BannerData | null;
  isCelebrationBannerActive: boolean;
  isError: boolean;
  isLoading: boolean;
} | null>(null);

const { Consumer: HomeBannerConsumer } = HomeBannerContext;

type HomeBannerProviderProps = {
  children: React.ReactNode;
};

export function HomeBannerProvider({ children }: HomeBannerProviderProps) {
  const user = useUser();

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bannerData, setBannerData] = useState<BannerData | null>(null);

  const isCelebrationBannerActive = useMemo(
    () =>
      isThirtyYearCelebrationBannerActive() ||
      featureToggle(FeatureToggle.ThirtyYearCeletebrationSkin),
    []
  );

  const fetchHomeBanner = useCallback(async () => {
    if (user.isLoading) return;
    try {
      const response = await heroBannerApi.getHomeBanner(user.divisionId);
      const data = response?.data?.data;
      const isBanner = Boolean(data?.title);

      if (isBanner) {
        setBannerData(data);
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [user.divisionId, user.isLoading]);

  useEffect(() => {
    if (isCelebrationBannerActive) return;
    fetchHomeBanner();
  }, [fetchHomeBanner, isCelebrationBannerActive]);

  const contextValue = useMemo(
    () => ({
      bannerData,
      isCelebrationBannerActive,
      isError,
      isLoading,
    }),
    [bannerData, isCelebrationBannerActive, isError, isLoading]
  );

  return (
    <HomeBannerContext.Provider value={contextValue}>
      {children}
    </HomeBannerContext.Provider>
  );
}

export const useHomeBanner = () => {
  const context = useContext(HomeBannerContext);
  if (!context) {
    throw new Error('HomeBannerContext is not defined');
  }
  return context;
};

export { HomeBannerConsumer };
