import './NoResults.scss';

const NoResults = () => (
  <div className="no-result">
    <p className="no-result__info-title">Tips to get the most out of search:</p>
    <ul className="no-result__items custom-ul">
      <li className="no-result__item">
        Please make sure your keywords are spelled correctly
      </li>
      <li className="no-result__item">
        Try different keywords for names or titles
      </li>
      <li className="no-result__item">Try fewer and less specific keywords</li>
    </ul>
  </div>
);

export default NoResults;
