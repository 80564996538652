import './TagOrChannelSelector.scss';

import { getTags } from 'api/cms/tags';
import { getChannels } from 'api/cms/topics';
import { UserContext } from 'components/Context/User';
import Fetcher from 'components/Fetcher';
import Select from 'components/Select';
import PropTypes from 'prop-types';
import { Component } from 'react';
import makeQueryString from 'utils/misc/makeQueryString';

class TagOrChannelSelector extends Component {
  constructor(props) {
    super();
    const { defaultSelectedChannel, defaultSelectedTag } = props;
    let defaultSelected = null;
    if (defaultSelectedTag) {
      defaultSelected = { isChannel: false, title: defaultSelectedTag };
    } else if (defaultSelectedChannel) {
      defaultSelected = { isChannel: true, title: defaultSelectedChannel };
    }

    this.values = {};
    this.state = {
      selected: defaultSelected ? this.mapItem(defaultSelected) : [],
    };
  }

  mapItem = ({ title, isChannel }) => {
    const item = {
      value: JSON.stringify({ isChannel, title }),
      isChannel,
      originalName: title,
      name: isChannel ? `#${title}` : title,
    };
    this.values[item.value] = item;
    return item;
  };

  onChangeHandler = (selected) => {
    this.setState({ selected });
    this.props.onChange(selected ? this.values[selected.value] : undefined);
  };

  getData = async (param) => {
    const channels = getChannels(param).then((res) =>
      res.data.data.map(({ title, _doc }) => ({
        title,
        isChannel: true,
        _doc,
      }))
    );
    const tags = getTags().then((res) => res.data.data);
    return await Promise.all([channels, tags]).then(([channels, tags]) => {
      const data = tags.concat(channels);
      const mappedData = data.map(this.mapItem);
      return { data: { data: mappedData } };
    });
  };

  render() {
    const { selected } = this.state;
    return (
      <div className="tag-or-channel-selector">
        <UserContext>
          {({ divisionId }) => (
            <Fetcher
              source={this.getData}
              param={makeQueryString({ divisionId })}
            >
              {({ response }) => {
                return (
                  <Select
                    data={response}
                    placeholder="Select tag or channel"
                    selected={selected}
                    onChange={this.onChangeHandler}
                    multiple={false}
                  />
                );
              }}
            </Fetcher>
          )}
        </UserContext>
      </div>
    );
  }
}

TagOrChannelSelector.propTypes = {
  defaultSelectedChannel: PropTypes.string,
  defaultSelectedTag: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TagOrChannelSelector;
