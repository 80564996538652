import './Text.scss';

import Editable from 'components/Editable';
import {
  ContentState,
  convertToRaw,
  Editor,
  EditorState,
  Modifier,
} from 'draft-js';
import PropTypes from 'prop-types';
import { Component } from 'react';

class TextEdit extends Component {
  constructor(props) {
    super(props);

    const { content, showInitialError } = props;

    this.state = {
      editorState: content
        ? EditorState.createWithContent(ContentState.createFromText(content))
        : EditorState.createEmpty(),
      error: showInitialError ? this.validate(content) : null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.showInitialError && this.props.showInitialError) {
      // debugger;
      const output = this.getContent(this.state.editorState);
      const error = this.validate(output);

      this.setState({ error });
    }
  }

  componentDidMount() {
    this.setState({
      editorState: this.props.content
        ? EditorState.createWithContent(
            ContentState.createFromText(this.props.content)
          )
        : EditorState.createEmpty(),
    });
  }

  onChange = (editorState) => {
    const { onChange } = this.props;
    this.setState({ editorState });

    if (!onChange) {
      return;
    }

    const output = this.getContent(editorState);

    onChange(output);
  };

  handlePaste = (text) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const { truncateAtMaxLength, maxLength, allowedCharacters, multiline } =
      this.props;

    let formattedText = text;

    if (!multiline) {
      formattedText = text.replace(/\n/g, ' ');
    }

    if (allowedCharacters) {
      formattedText = formattedText.replace(
        new RegExp(`[^${allowedCharacters}]*`, 'g'),
        ''
      );
    }

    if (truncateAtMaxLength && formattedText.length > maxLength) {
      formattedText = formattedText.substr(0, maxLength);
    }

    const selectionState = this.state.editorState.getSelection();
    const selectionLength =
      selectionState.getEndOffset() - selectionState.getStartOffset();

    // Compensate for selection length as the selected part will be overwritten.
    if (
      truncateAtMaxLength &&
      currentContentLength + formattedText.length + selectionLength > maxLength
    ) {
      formattedText = formattedText.substr(
        0,
        maxLength - currentContentLength + selectionLength
      );
    }

    if (
      !truncateAtMaxLength ||
      currentContentLength + formattedText.length - selectionLength <= maxLength
    ) {
      this.onChange(
        EditorState.push(
          this.state.editorState,
          Modifier.replaceText(
            currentContent,
            this.state.editorState.getSelection(),
            formattedText
          )
        )
      );
    }
    return 'handled';
  };

  getContent = (editorState) => {
    const { multiline } = this.props;

    const rawContent = convertToRaw(editorState.getCurrentContent());

    let content;

    if (multiline) {
      content = rawContent.blocks.map((block) => block.text.trim()).join('\n');
    } else {
      content = rawContent.blocks[0].text.trim();
    }

    return content;
  };

  validate = (content) => {
    const { validators } = this.props;

    if (!validators) {
      return null;
    }

    for (let i = 0; i < validators.length; ++i) {
      const validator = validators[i];
      if (validator) {
        const error = validator(content);

        if (error) {
          return error;
        }
      }
    }

    return null;
  };

  onBlur = () => {
    const { onBlur } = this.props;

    const output = this.getContent(this.state.editorState);
    const error = this.validate(output);

    this.setState({ error });

    if (onBlur) {
      onBlur(output);
    }
  };

  handleBeforeInput = (char) => {
    const { allowedCharacters, maxLength, truncateAtMaxLength } = this.props;
    const { editorState } = this.state;

    if (
      allowedCharacters &&
      char.search(new RegExp(`[${allowedCharacters}]`)) === -1
    ) {
      return 'handled';
    }

    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    if (truncateAtMaxLength && currentContentLength > maxLength - 1) {
      return 'handled';
    }
  };

  handleReturn = () => {
    const { multiline } = this.props;

    if (multiline) {
      return null;
    }

    return 'handled';
  };

  focusInput = () => {
    if (this.editor) {
      this.editor.focus();
    }
  };

  render() {
    const { className, wrapper, placeholder } = this.props;
    const { error } = this.state;

    return (
      <Editable
        color={this.props.color}
        hashtag={this.props.hashtag}
        className={className}
        error={error}
        roundedCorners={this.props.roundedCorners}
      >
        <wrapper.element
          className={wrapper.className}
          onClick={this.focusInput}
        >
          <Editor
            ref={(e) => (this.editor = e)}
            editorState={this.state.editorState}
            placeholder={placeholder}
            onChange={this.onChange}
            onBlur={this.onBlur}
            handleBeforeInput={this.handleBeforeInput}
            handlePastedText={this.handlePaste}
            handleReturn={this.handleReturn}
          />
        </wrapper.element>
      </Editable>
    );
  }
}

TextEdit.propTypes = {
  wrapper: PropTypes.shape({
    element: PropTypes.string.isRequired,
    className: PropTypes.string,
  }),
  className: PropTypes.string,
  maxLength: PropTypes.number,
  hashtag: PropTypes.bool,
  showInitialError: PropTypes.bool,
  placeholder: PropTypes.string,
  content: PropTypes.string,
  onBlur: PropTypes.func,
  multiline: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.func),
  allowedCharacters: PropTypes.string,
  color: PropTypes.string,
  truncateAtMaxLength: PropTypes.bool,
};

TextEdit.defaultProps = {
  wrapper: {
    element: 'div',
    className: '',
  },
  maxLength: 10,
  truncateAtMaxLength: true,
};

export default TextEdit;
