import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import notFoundImage from 'assets/images/not-found.png';
import { translations } from 'translations';

const JobContentError = () => (
  <Stack alignItems="center">
    <Box component="img" src={notFoundImage} maxWidth="20rem" />
    <Typography>{translations.jobContentNotFound}</Typography>
  </Stack>
);

export { JobContentError };
