import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import { Icon, IconType } from './Icon/Icon';

type SegmentedControlItem<T> = {
  name: string;
  value: T;
  startIconType?: IconType;
  endIconType?: IconType;
};

type SegmentedControlProps<T extends {}> = {
  value: T;
  items: SegmentedControlItem<T>[];
  isDeselectable?: boolean;
  onChange: (value: T) => void;
} & Pick<
  ToggleButtonGroupProps,
  'id' | 'sx' | 'size' | 'disabled' | 'aria-label'
>;

const SegmentedControl = <T extends {}>({
  id,
  value,
  items,
  isDeselectable = true,
  onChange,
  sx,
  ...toggleButtonGroupProps
}: SegmentedControlProps<T>) => {
  const isSizeSmall = toggleButtonGroupProps.size === 'small';

  return (
    <ToggleButtonGroup
      id={id}
      color="primary"
      exclusive
      value={value}
      onChange={(_e, newValue) => {
        if (!isDeselectable && newValue === null) {
          return;
        }
        onChange(newValue);
      }}
      sx={[
        (theme) => ({
          width: 'fit-content',
          border: `1px solid ${theme.colors.border.surfacePrimary}`,
          backgroundColor: theme.colors.surface.secondary,
          borderRadius: theme.border.radius.md,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...toggleButtonGroupProps}
    >
      {items.map((item, index) => (
        <ToggleButton
          id={id ? `${id}-${index}` : undefined}
          key={`toggle-button-${index}`}
          value={item.value}
          sx={(theme) => ({
            textTransform: 'none',
            color: theme.colors.text.secondary,
            fontWeight: theme.typography.fontWeightRegular,
            backgroundColor: theme.colors.surface.secondary,
            alignItems: 'unset',
            border: 'none',
            '&.MuiToggleButton-sizeSmall': {
              padding: '0.375rem 0.875rem',
            },
            '&.MuiToggleButton-sizeMedium': {
              padding: '0.875rem 1.25rem',
            },
            '&.Mui-selected': {
              color: theme.colors.text.brand,
              outline: `1px solid ${theme.colors.border.focus}`,
              borderColor: theme.colors.surface.primary,
              borderRadius: theme.border.radius.md,
              backgroundColor: theme.colors.surface.primary,
              boxShadow: theme.elevation.sm,
              zIndex: 1,
            },
            '&.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 2px ${theme.colors.border.focus}`,
              zIndex: 2,
            },
            '&.Mui-disabled': {
              color: theme.colors.text.disabled,
              backgroundColor: theme.colors.surface.tertiary,
              border: 'none',
            },
            '&.Mui-selected.Mui-disabled': {
              color: theme.colors.text.disabled,
              backgroundColor: theme.colors.surface.tertiary,
              borderColor: theme.colors.border.disabled,
              outline: `1px solid ${theme.colors.border.disabled}`,
            },
            '&:hover, &.Mui-selected:hover': {
              color: theme.colors.text.actionHover,
              backgroundColor: theme.colors.surface.actionTertiaryHover,
            },
            '&:active, &.Mui-selected:active': {
              color: theme.colors.text.actionPress,
              backgroundColor: theme.colors.surface.actionTertiaryPress,
            },
          })}
        >
          {item.startIconType && (
            <Icon
              type={item.startIconType}
              size={isSizeSmall ? 16 : 20}
              color="brandBase"
            />
          )}
          <Typography
            component="span"
            variant={isSizeSmall ? 'body2' : 'body1'}
            sx={{
              margin: 'auto',
              marginLeft: item.startIconType ? '0.5rem' : 0,
              marginRight: item.endIconType ? '0.5rem' : 0,
              lineHeight: 1.2,
              height: '100%',
            }}
          >
            {item.name}
          </Typography>
          {item.endIconType && (
            <Icon
              type={item.endIconType}
              size={isSizeSmall ? 16 : 20}
              color="brandBase"
            />
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export { SegmentedControl };
