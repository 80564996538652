import './editLink.scss';

import classNames from 'classnames';
import OnlyWithRole from 'components/OnlyWithRole';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import appendToUrl from 'utils/misc/appendToUrl';

interface EditLinkProps {
  viewableByRole?: string;
  url?: string;
  onClick?: () => void;
  children?: ReactNode;
  className?: string;
}

const EditLink = ({
  viewableByRole,
  url = window.location.href,
  onClick,
  children = 'Edit page',
  className,
}: EditLinkProps) => {
  const editLinkClassNames = classNames('size-small', 'edit-link', className);

  const linkElement = (
    <Link
      to={appendToUrl(url, 'edit')}
      className={editLinkClassNames}
      onClick={onClick}
    >
      {children}
    </Link>
  );

  if (viewableByRole) {
    return (
      <OnlyWithRole viewableByRole={viewableByRole}>{linkElement}</OnlyWithRole>
    );
  }
  return linkElement;
};

export default EditLink;
