import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const HidePagesFromNavigation = () => (
  <Consumer>
    {({ onToggleArticleValue, article: { hidePageFromNavigation } }) => (
      <>
        <Flex>
          <Checkbox
            defaultChecked={hidePageFromNavigation}
            id="hidePageFromNavigation"
            onChange={() => onToggleArticleValue('hidePageFromNavigation')}
          />
          <label htmlFor="hidePageFromNavigation" className="m-l-3">
            Hide Page From Navigation
          </label>
        </Flex>
        <hr className="m-t-6 m-b-6" />
      </>
    )}
  </Consumer>
);

export default HidePagesFromNavigation;
