import { EmbedMSForm, EmbedPowerBI } from 'componentsNew';
import * as embedHelpers from 'componentsNew/Embed/helpers';
import { getId, getService } from 'utils/cms/parseVideoUrls';

export const AtomicEmbedComponent = ({ data }) => {
  let { url } = data;
  if (typeof url !== 'string') {
    url = `${getService(url)}${getId(url)}`;
  }

  if (embedHelpers.isMSFormsEmbedUrl(url)) {
    return <EmbedMSForm src={url} />;
  }

  if (embedHelpers.isPowerBIEmbedUrl(url)) {
    return <EmbedPowerBI src={url} />;
  }

  const className = embedHelpers.isPlayerenceEmbedUrl(url)
    ? 'embed-container embed-container-playerence'
    : 'embed-container';

  return (
    <div className={className}>
      <iframe
        title="Embedded content"
        src={url}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};
