import './MiniSnackbar.scss';

import PropTypes from 'prop-types';
import { useState } from 'react';

import MiniSnackbarItem from './MiniSnackbarItem';

const MiniSnackbar = ({ messages, onChange }) => {
  const [removedIds, setRemovedIds] = useState({});
  const onMessageHidden = (id) => {
    setRemovedIds((removedIds) => ({ ...removedIds, [id]: true }));
  };
  const onShouldRemoveMessage = (id) => {
    setRemovedIds((removedIds) => {
      const ids = { ...removedIds };
      delete ids[id];
      return ids;
    });
    onChange(messages.filter((message) => message.id !== id));
  };
  return (
    <div className="mini-snackbar__list">
      {messages.map(({ content, id, status }) => (
        <MiniSnackbarItem
          id={id}
          content={content}
          key={id}
          removed={!!removedIds[id]}
          status={status}
          onClose={() => onMessageHidden(id)}
          onRemoved={() => onShouldRemoveMessage(id)}
        />
      ))}
    </div>
  );
};

MiniSnackbar.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
      status: PropTypes.oneOf(['success', 'error']).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MiniSnackbar;
