import './Outline.scss';

import { Component } from 'react';

class Outline extends Component {
  componentDidMount() {
    document.addEventListener(
      'keydown',
      (e) => {
        if (!e.metaKey) {
          document.documentElement.dataset.outline = 'keyboard';
        }
      },
      false
    );
    document.addEventListener(
      'mousedown',
      () => {
        document.documentElement.dataset.outline = 'mouse';
      },
      false
    );
  }

  render() {
    return null;
  }
}

export default Outline;
