import RichText from 'components/Editable/RichText';

import { Consumer } from '..';

const InfoHighlightBox = ({
  placeholder,
  fieldName = 'infoHighlightBox',
  showSideButtons = false,
}) => (
  <Consumer>
    {({ article: { [fieldName]: infoHighlightBox }, onChangeArticle }) => (
      <RichText
        wrapper={{
          element: 'div',
        }}
        onBlur={(value) => onChangeArticle({ [fieldName]: value })}
        content={infoHighlightBox}
        placeholder={placeholder}
        showSideButtons={showSideButtons}
      />
    )}
  </Consumer>
);

export default InfoHighlightBox;
