import './Question.scss';

import { ReactComponent as Arrow } from 'components/Icon/Arrow.svg';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { GAonFAQOpen } from 'utils/analytics';

class Question extends Component {
  state = {
    open: false,
  };

  toggle = (e) => {
    e.stopPropagation();
    this.setState(
      ({ open }) => ({ open: !open }),
      () => {
        if (this.state.open) {
          const { parent, question } = this.props;

          GAonFAQOpen(parent, question);
        }
      }
    );
  };

  renderIcon = () => {
    const { open } = this.state;
    return (
      <button
        className="faq__toggle-button"
        onClick={this.toggle}
        aria-label="Toggle FAQ Question"
        aria-expanded={open}
      >
        <Arrow />
      </button>
    );
  };

  render() {
    const { open } = this.state;
    const { question, answer } = this.props;
    return (
      <div className={`faq__question ${open ? 'open' : 'closed'}`}>
        <div
          className="faq__question-heading"
          onClick={(e) => (open ? null : this.toggle(e))}
        >
          <h3 className="h4">{question}</h3>
          {this.renderIcon()}
        </div>
        <AnimateHeight duration={200} height={open ? 'auto' : 0}>
          <div className="faq__question-text">{Parser(answer)}</div>
        </AnimateHeight>
      </div>
    );
  }
}

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default Question;
