import './EventsListLink.scss';

import { Desktop, Mobile } from 'components/Responsive';
import { Link } from 'react-router-dom';
// TODO: Should not use untyped packages in typescript files
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TextClamp from 'react-string-clamp';
import settings from 'settings';
const { redirectUri } = settings.auth;

const monthNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const formatAmPm = (date: any) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, '0');
  return `${hours}:${minutes} ${ampm}`;
};

const EventsListLink = (data: any) => {
  const { EventDate, Description, Location, DetailUrl, EndDate } =
    data.data.fieldValues;
  const { displayName } = data.data;

  const getDateString = (date: any) => {
    return new Date(date);
  };

  const getDay = (eventDate: any) => {
    return getDateString(eventDate).getDate();
  };

  const isAllDayEvent = (eventDate: string, endDate: string) => {
    const endDateHours = new Date(endDate).getUTCHours();
    const endDateMinutes = new Date(endDate).getUTCMinutes();
    const eventDateHours = new Date(eventDate).getUTCHours();
    const eventDateMinutes = new Date(eventDate).getUTCMinutes();

    return (
      eventDateHours === 0 &&
      eventDateMinutes === 0 &&
      endDateHours === 23 &&
      endDateMinutes === 59
    );
  };

  const getTime = (eventDate: any) => {
    return formatAmPm(new Date(eventDate));
  };

  const getMonth = (eventDate: any) => {
    return monthNames[getDateString(eventDate).getMonth()];
  };

  const getDisplayName = () => {
    return <TextClamp lines={2} text={displayName ? displayName : ''} />;
  };

  return (
    <div className="events-list-link">
      {DetailUrl.includes(redirectUri) ? (
        <Link
          to={DetailUrl}
          target="_blank"
          className="events-list-link__container"
        >
          <div className="events-list-link__wrapper">
            <div className="events-list-link__date">
              <div className="events-list-link__day">{getDay(EventDate)}</div>
              <div className="events-list-link__month">
                {getMonth(EventDate)}
              </div>
            </div>
            <div className="events-list-link__divider"></div>
            <div className="events-list-link__text-wrapper">
              <div className="events-list-link__title">{getDisplayName()}</div>
              <div className="events-list-link__time-site">
                {!isAllDayEvent(EventDate, EndDate)
                  ? `${getTime(EventDate)}, `
                  : ''}
                {Location}
              </div>
              <Desktop className="events-list-link__desktop">
                <div className="events-list-link__description">
                  <TextClamp lines={3} text={Description ? Description : ''} />
                </div>
              </Desktop>
              <Mobile className="events-list-link__mobile">
                <div className="events-list-link__description">
                  <TextClamp lines={4} text={Description ? Description : ''} />
                </div>
              </Mobile>
            </div>
          </div>
        </Link>
      ) : (
        <a
          href={DetailUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="events-list-link__container"
        >
          <div className="events-list-link__wrapper">
            <div className="events-list-link__date">
              <div className="events-list-link__day">{getDay(EventDate)}</div>
              <div className="events-list-link__month">
                {getMonth(EventDate)}
              </div>
            </div>
            <div className="events-list-link__divider"></div>
            <div className="events-list-link__text-wrapper">
              <div className="events-list-link__title">{getDisplayName()}</div>
              <div className="events-list-link__time-site">
                {!isAllDayEvent(EventDate, EndDate)
                  ? `${getTime(EventDate)}, `
                  : ''}
                {Location}
              </div>
              <Desktop className="events-list-link__desktop">
                <div className="events-list-link__description">
                  <TextClamp lines={3} text={Description ? Description : ''} />
                </div>
              </Desktop>
              <Mobile className="events-list-link__mobile">
                <div className="events-list-link__description">
                  <TextClamp lines={4} text={Description ? Description : ''} />
                </div>
              </Mobile>
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

export default EventsListLink;
