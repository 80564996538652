import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TypographyWithLink } from 'componentsNew/TypographyWithLink';
import { AvenueRoute } from 'constants/routes';
import { translations } from 'translations';

const NoDepartment = () => {
  return (
    <Box maxWidth="30rem" marginTop={2}>
      <Typography variant="body2" fontWeight="bold">
        {translations.articleFeedNoDepartmentTitle}
      </Typography>
      <TypographyWithLink
        to={AvenueRoute.ProfilePreferences}
        variant="body2"
        sx={(theme) => ({ paddingTop: theme.spacing('xxs') })}
      >
        {translations.articleFeedNoDepartmentTextWithLink}
      </TypographyWithLink>
    </Box>
  );
};

export { NoDepartment };
