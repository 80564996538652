import ContentLabels from 'components/ContentLabels';
import Parser from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { InnovationArticleResult } from 'types/Portal';
import {
  GAonClickPortalSearchResultSuggestion,
  getPortalType,
} from 'utils/analytics';
import { getImageSrc } from 'utils/cms';

const formatDate = (date: string) => {
  if (!date) return null;
  const convertedDate = new Date(date);
  const day = convertedDate.getDate();
  const month = convertedDate.getMonth();
  const year = convertedDate.getFullYear();
  return `${day}/${month}/${year}`;
};

const ResultItem = ({
  linkUrl,
  published,
  title,
  summary,
  imageUrl,
  imageAltText,
  types,
  portalType,
}: InnovationArticleResult) => {
  const history = useHistory();

  const redirectToLink = (url: string) => {
    const portal = getPortalType(portalType);

    GAonClickPortalSearchResultSuggestion(title, portal);
    if (url && Boolean(~url.indexOf(window.location.origin))) {
      history.push(url.replace(window.location.origin, ''));
    } else {
      window.location.href = url;
    }
  };

  const formattedPublished = formatDate(published);
  return (
    <li className="innovation-portal-results__item">
      <div className="innovation-portal-results__item-link">
        <div className="innovation-portal-results__item-content">
          <span className="innovation-portal-results__item-time">
            {formattedPublished}
          </span>
          <h2
            onClick={() => redirectToLink(linkUrl)}
            className="innovation-portal-results__item-title"
          >
            {title}
          </h2>
          <p className="innovation-portal-results__item-summary">
            {summary && Parser(summary)}
          </p>
          <ContentLabels className="m-t-2" labels={types} channels={[]} />
        </div>
        <div className="innovation-portal-results__item-image">
          <img
            onClick={() => redirectToLink(linkUrl)}
            src={getImageSrc(imageUrl, 350)}
            alt={imageAltText}
            title={imageAltText}
          />
        </div>
      </div>
    </li>
  );
};

export default ResultItem;
