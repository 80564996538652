import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'componentsNew';
import { useMemo, useState } from 'react';
import { translations } from 'translations';

const LIMIT = 5;

type JobContentLocationsProps = {
  elementId: string;
  locations: { id: number; name: string }[];
  isExpandable?: boolean;
};

const JobContentLocations = ({
  elementId,
  locations,
  isExpandable = false,
}: JobContentLocationsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const content = useMemo(() => {
    if (!locations.length) {
      return { text: translations.notAvailable };
    }
    if (!isExpandable && locations.length === 1) {
      return { text: locations[0].name };
    }
    if (!isExpandable && locations.length > 1) {
      return { text: translations.jobsMultipleCountries };
    }
    const names = locations.map((location) => location.name);
    const defaultNames = names.slice(0, LIMIT);
    const overflowNames = names.slice(LIMIT, names.length - 1);
    const hasOverflow = overflowNames.length > 0;

    if (isOpen) {
      return { text: names.join(', '), hasOverflow };
    }
    return {
      text: `${defaultNames.join(', ')}${hasOverflow ? '...' : ''}`,
      hasOverflow,
    };
  }, [locations, isExpandable, isOpen]);

  return (
    <Stack
      id={elementId}
      sx={(theme) => ({
        flexDirection: 'row',
        gap: theme.spacing('xxxs'),
        ...(content.hasOverflow && {
          cursor: 'pointer',
        }),
      })}
      {...(content.hasOverflow && {
        onClick: () => setIsOpen((prevIsOpen) => !prevIsOpen),
      })}
    >
      <Icon type="mapPin" color="secondary" size={20} />
      <Typography
        variant="caption"
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          color: theme.colors.text.tertiary,
          maxWidth: '40rem',
        })}
      >
        {content.text}
      </Typography>
      {content.hasOverflow && (
        <IconButton
          id={`${elementId}-expand`}
          aria-label={translations.jobsExpandCountries}
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
          sx={{
            alignSelf: 'baseline',
            marginLeft: 'auto',
            padding: 0,
          }}
        >
          <Icon
            type="chevronDown"
            color="brandBase"
            size={20}
            sx={{
              transform: isOpen ? 'rotate(-180deg)' : 'none',
              transition: 'transform 100ms linear',
            }}
          />
        </IconButton>
      )}
    </Stack>
  );
};

export { JobContentLocations };
