import ImageCrop from 'components/Editable/Files/ImageCrop';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';

const getDefaultState = () => ({
  imageUrl: null,
  showModal: false,
  error: false,
});

class ImageModal extends Component {
  state = getDefaultState();

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  asyncSetState = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  onAddImageClick = (e) => {
    e.stopPropagation();

    if (this.input) {
      this.input.click();
    }
  };

  onChangeImage = (e) => {
    const file = e.target.files.item(0);
    e.target.value = null;

    if (file) {
      if (file.type === 'image/tiff' || file.type === 'image/tif') {
        this.setState({
          showModal: true,
          error:
            'Sorry, it is not possible to upload images in TIF/TIFF format. Please upload an image in JPG, JPEG, PNG, GIF or BMP format instead.',
        });

        return;
      }

      const reader = new FileReader();
      const { minHeight, minWidth, onBegin } = this.props;

      reader.onloadstart = () => {
        this.asyncSetState({ showModal: true });
      };

      if (onBegin) {
        onBegin();
      }

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const greaterThanMin =
            img.width >= minWidth && img.height >= minHeight;

          if (greaterThanMin) {
            this.asyncSetState({
              imageUrl: e.target.result,
            });
          } else {
            this.asyncSetState({
              error: `Please upload an image that is wider than ${minWidth} px and taller than ${minHeight} px.`,
            });
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  onSubmit = (data) => {
    this.setState(getDefaultState());

    this.props.onSubmit(data);
  };

  hideModal = () => {
    this.setState(getDefaultState());

    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
  };

  renderModal() {
    const { imageUrl, error } = this.state;
    const { minHeight, minWidth, aspectRatio, handleCroppedData, useAltText } =
      this.props;

    if (error) {
      return (
        <Modal
          onClose={this.hideModal}
          buttons={[
            {
              title: 'Ok',
              color: 'black',
              onClick: this.hideModal,
            },
          ]}
          errorMessage={error}
        />
      );
    }

    return (
      <ImageCrop
        src={imageUrl}
        onCrop={this.onSubmit}
        onCancel={this.hideModal}
        handleCroppedData={handleCroppedData}
        minWidth={minWidth}
        minHeight={minHeight}
        aspectRatio={aspectRatio}
        useAltText={useAltText}
      ></ImageCrop>
    );
  }

  render() {
    const { children } = this.props;
    const { showModal } = this.state;

    const childElement = cloneElement(children, {
      onClick: this.onAddImageClick,
      'aria-controls': 'fileupload',
    });

    return (
      <>
        <label htmlFor="fileupload">{childElement}</label>
        <input
          type="file"
          onChange={this.onChangeImage}
          ref={(input) => (this.input = input)}
          accept={'image/*, .tif, .tiff'}
          style={{ display: 'none' }}
        />
        {showModal && this.renderModal()}
      </>
    );
  }
}

ImageModal.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBegin: PropTypes.func,
  onCancel: PropTypes.func,
  minWidth: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  handleCroppedData: PropTypes.func,
  aspectRatio: PropTypes.number,
  useAltText: PropTypes.bool,
};

export default ImageModal;
