const development = {
  portals: [
    {
      id: '3ad0c4b1dea670e0e808',
      name: 'Code of Conduct',
      url: 'codeofconduct',
      homePath: '/portals/codeofconduct',
      roleRestriction: { isRestricted: false, role: '' },
    },
    {
      id: '919d03cb302ca18ce87e',
      name: 'Our Strategy',
      url: 'strategy',
      homePath: '/portals/strategy',
      roleRestriction: { isRestricted: false, role: '' },
    },
    {
      id: '939cd8990eaf211370c1',
      name: 'Innovation',
      url: 'innovation',
      homePath: '/portals/innovation',
      roleRestriction: { isRestricted: false, role: '' },
      insightsDatabaseId: '042b37fe95ed0bf531b1',
    },
  ],
};

export default development;
