import './AttachedModal.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';

class AttachedModal extends Component {
  state = { showMenu: false };
  menuRef = createRef();

  render() {
    const {
      buttonClassName,
      buttonContent,
      children,
      className,
      wrapperClassName,
      ariaControls,
    } = this.props;
    const { showMenu } = this.state;

    return (
      <div className={classNames('attached-modal', className)}>
        {showMenu && (
          <ClickAwayListener
            onClickAway={(e) => {
              this.setState({ ...this.state, showMenu: false });
              if (this.props.onClosed instanceof Function) {
                this.props.onClosed(e);
              }
            }}
          >
            <div ref={this.menuRef} className={wrapperClassName}>
              {children}
            </div>
          </ClickAwayListener>
        )}
        <button
          onClick={() => this.setState({ ...this.state, showMenu: true })}
          className={buttonClassName}
          aria-controls={ariaControls}
          aria-expanded={showMenu}
        >
          {buttonContent}
        </button>
      </div>
    );
  }
}

AttachedModal.propTypes = {
  buttonClassName: PropTypes.string,
  buttonContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClosed: PropTypes.func,
  wrapperClassName: PropTypes.string,
  ariaControls: PropTypes.string,
};

export default AttachedModal;
