import { translations } from 'translations';

import { ImageGalleryItem, UnsavedImage } from './ImageGalleryEdit';

export const validateFiles = (
  files: FileList,
  maxBytesPerFile: number,
  validMimeTypes: string[]
) => {
  const imageGalleryItems: ImageGalleryItem[] = [];
  for (const file of files) {
    const isSizeValid = file.size <= maxBytesPerFile;
    const isTypeValid = validMimeTypes.includes(file.type);

    const image: UnsavedImage = {
      file: file,
      error: getErrorMessage(isSizeValid, isTypeValid),
    };
    imageGalleryItems.push({ isFile: true, image });
  }
  return imageGalleryItems;
};

const getErrorMessage = (isSizeValid: boolean, isTypeValid: boolean) => {
  if (!isSizeValid && !isTypeValid) {
    return `${translations.imageGalleryInvalidFileSize}, ${translations.imageGalleryInvalidMimeType}`;
  }
  if (!isSizeValid) {
    return translations.imageGalleryInvalidFileSize;
  }
  if (!isTypeValid) {
    return translations.imageGalleryInvalidMimeType;
  }
  return;
};
