import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import settings from 'settings';
import * as timeUtils from 'utils/misc/time';

import * as helpers from './helpers';

const FALLBACK_IFRAME_HEIGHT = 1000;

const GlobalWebcastContext = React.createContext<{
  iframeUrl?: string;
  iframeHeight: number | null;
  iframeWrapperHeight: number;
  isGlobalWebcastActive: boolean;
} | null>(null);

const { Consumer: GlobalWebcastConsumer } = GlobalWebcastContext;

type GlobalWebcastProviderProps = {
  children: React.ReactNode;
};

export function GlobalWebcastProvider({
  children,
}: GlobalWebcastProviderProps) {
  const [iframeHeight, setIframeHeight] = useState<number | null>(null);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const testParams = useMemo(() => helpers.getTestParamsFromUrl(), []);

  const isGlobalWebcastActive = useMemo(
    () =>
      testParams.enabled ||
      (timeUtils.isBeforeNow(settings.globalWebcast.timeForActivation) &&
        timeUtils.isAfterNow(settings.globalWebcast.timeForDeactivation)),
    [testParams.enabled]
  );

  const iframeUrl = useMemo(() => {
    if (testParams.enabled && testParams.iframeUrl) {
      return testParams.iframeUrl;
    }
    return timeUtils.isAfterNow(settings.globalWebcast.timeForIframeSwitch)
      ? settings.globalWebcast.iframeUrlAM
      : settings.globalWebcast.iframeUrlPM;
  }, [testParams.enabled, testParams.iframeUrl]);

  const iframeWrapperHeight = useMemo(() => {
    const minHeight = isLargeScreen ? 370 : 405;
    const wrapperHeight = iframeHeight ? iframeHeight + 100 : 0;
    return wrapperHeight < minHeight ? minHeight : wrapperHeight;
  }, [iframeHeight, isLargeScreen]);

  // Sets a listener for messages broadcasted from the global webcast
  // iframe (these will include information about the iframe height)
  useEffect(() => {
    if (!isGlobalWebcastActive) {
      return;
    }
    const iframeMessageListener = (message: any) => {
      if (message.data.type !== 'contentChange') {
        return;
      }
      const height = message.data.height;
      const isValidHeight = Number.isInteger(height);
      if (isValidHeight) {
        setIframeHeight(height ? height + 30 : height);
      }
    };
    window.addEventListener('message', iframeMessageListener);
    return () => window.removeEventListener('message', iframeMessageListener);
  }, [isGlobalWebcastActive]);

  // Sets a default iframe height after 30 seconds in case we didn't
  // get any broadcast message from the global webcast iframe
  useEffect(() => {
    if (!isGlobalWebcastActive) {
      return;
    }
    const fallbackIframeHeightSetter = () => {
      setIframeHeight(
        (prevIframeHeight) => prevIframeHeight || FALLBACK_IFRAME_HEIGHT
      );
    };
    const timerId = setTimeout(fallbackIframeHeightSetter, 30000);
    return () => clearTimeout(timerId);
  }, [isGlobalWebcastActive]);

  const contextValue = useMemo(
    () => ({
      iframeUrl,
      iframeHeight,
      iframeWrapperHeight,
      isGlobalWebcastActive,
    }),
    [isGlobalWebcastActive, iframeUrl, iframeHeight, iframeWrapperHeight]
  );

  return (
    <GlobalWebcastContext.Provider value={contextValue}>
      {children}
    </GlobalWebcastContext.Provider>
  );
}

export const useGlobalWebcast = () => {
  const context = useContext(GlobalWebcastContext);
  if (!context) {
    throw new Error('GlobalWebcastContext is not defined');
  }
  return context;
};

export { GlobalWebcastConsumer };
