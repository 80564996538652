import { Consumer } from 'components/CreateOrEditArticle';
import LinkButtonText from 'components/CreateOrEditArticle/components/LinkButtonText';
import LinkUrl from 'components/CreateOrEditArticle/components/LinkUrl';
import MaxArticleCount from 'components/CreateOrEditArticle/components/MaxArticleCount';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import TagOrChannel from 'components/CreateOrEditArticle/components/TagOrChannel';
import Title from 'components/CreateOrEditArticle/components/Title';

const ArticleListBlockEditor = () => (
  <Consumer>
    {({
      validationParameters: {
        title: { maxLength },
      },
    }) => {
      const titlePlaceholder = `Title (max ${maxLength} characters)`;
      return (
        <>
          <Title placeholder={titlePlaceholder} />
          <TagOrChannel />
          <MaxArticleCount />
          <LinkButtonText placeholder="Link Button Text" />
          <LinkUrl placeholder="Link URL" />
          <SortIndex />
        </>
      );
    }}
  </Consumer>
);

export default ArticleListBlockEditor;
