import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import { Column, Container } from 'components/Grid';
import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Loader = () => (
  <Container>
    <Column span={2}>
      <Breadcrumbs ghost={true} />
      <Box className="m-t-6">
        <Resize>
          {({ width }) => (
            <ContentLoader
              className=""
              width={width}
              height={700}
              preserveAspectRatio="none"
              style={{ height: '700px' }}
            >
              <rect
                x={0}
                y={6}
                width={getRandomNumberBetween(350, 500)}
                height={36}
              />
              <rect x={830} y={0} width={221} height={48} />
              <rect
                x={0}
                y={100}
                width={getRandomNumberBetween(250, 400)}
                height={12}
              />

              <rect
                x={0}
                y={160}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={180}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={200}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={220}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={240}
                width={getRandomNumberBetween(400, 670)}
                height={12}
              />

              <rect
                x={0}
                y={280}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={300}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={320}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={340}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={360}
                width={getRandomNumberBetween(400, 670)}
                height={12}
              />

              <rect
                x={0}
                y={400}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={420}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={440}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={460}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={480}
                width={getRandomNumberBetween(400, 670)}
                height={12}
              />

              <rect
                x={0}
                y={520}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={540}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={560}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={580}
                width={getRandomNumberBetween(550, 670)}
                height={12}
              />
              <rect
                x={0}
                y={600}
                width={getRandomNumberBetween(400, 670)}
                height={12}
              />

              <rect x={0} y={640} width={221} height={48} />
            </ContentLoader>
          )}
        </Resize>
      </Box>
    </Column>
    <Column />
  </Container>
);

export default Loader;
