import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { cms, find } = settings.api;
const route = `${cms}/nodes`;

let descId = 1;

const types = {
  Gateway: { count: 5, child: 'Deliverable', template: 'listing' },
  Deliverable: { child: 'Item', template: 'content' },
  Item: { template: 'item' },
};

const getChildren = (type) => {
  let { count, child, template } = types[type];

  if (!count) {
    count = Math.ceil(Math.random() * 7) + 1;
  }

  let out = [];

  for (let num = 0; num < count; ++num) {
    out.push({
      type: 'node',
      id: `id${descId++}`,
      attributes: {
        title: `${type} ${num}`,
        preamble: `${type} ${num} preamble`,
        body: `${type} ${num} body`,
        template,
        children: child ? getChildren(child) : [],
      },
    });
  }

  return out;
};

const innovationDescendants = {
  id: `id${descId++}`,
  type: 'node',
  attributes: {
    title: 'Innovation Portal',
    preamble: 'Welcome to our',
    body: 'Innovation is too important to be left to chance ...',
    template: 'portal',
    children: [
      {
        id: `id${descId++}`,
        type: 'node',
        attributes: {
          title: 'Gateways',
          preamble: 'New products bring visions to life',
          body: 'Gateway body',
          template: 'listing',
          children: getChildren('Gateway'),
        },
      },
    ],
  },
};

export const getSmartHomesArticles = (searchRequest) =>
  createRequest({
    method: 'get',
    url: `${find}${searchRequest}`,
    errorMessages: {
      '*': 'Failed to get portal data, please contact support for assistance.',
    },
  });

export const getInternetOfThingsArticles = (searchRequest) =>
  createRequest({
    method: 'get',
    url: `${find}${searchRequest}`,
    errorMessages: {
      '*': 'Failed to get portal data, please contact support for assistance.',
    },
  });

export const getInsightsArticles = (searchRequest) =>
  createRequest({
    method: 'get',
    url: `${find}${searchRequest}`,
    errorMessages: {
      '*': 'Failed to get portal data, please contact support for assistance.',
    },
  });

export const testListDescendants = () => {
  return new Promise((res) => {
    setTimeout(() => res({ data: { data: innovationDescendants } }), 1000);
  });
};

export const listDescendants = (portalId) =>
  createRequest({
    method: 'get',
    url: `${route}/${portalId}/descendants`,
    errorMessages: {
      '*': 'Failed to get portal data, please contact support for assistance.',
    },
  });

export const listCategories = (categoryFolder) =>
  createRequest({
    method: 'get',
    url: `${route}/folder/${categoryFolder}/descendants`,
    errorMessages: {
      '*': 'Failed to get portal data, please contact support for assistance.',
    },
  });

export const getNode = (id) =>
  createRequest({
    method: 'get',
    url: `${route}/${id}`,
    errorMessages: {
      '*': 'Failed to get portal data, please contact support for assistance.',
    },
  });

export const createNode = (type, parentId, attributes) =>
  createRequest({
    method: 'post',
    url: route,
    data: {
      data: {
        type,
        attributes: {
          parentId,
          ...attributes,
        },
      },
    },
    errorMessages: {
      '*': 'Failed to create portal article, please contact support for assistance.',
    },
  });

export const updateNode = (id, attributes) =>
  createRequest({
    method: 'put',
    url: route,
    data: {
      data: {
        id,
        attributes,
      },
    },
    errorMessages: {
      '*': 'Failed to update portal article, please contact support for assistance.',
    },
  });

export const deleteNode = (id) =>
  createRequest({
    method: 'delete',
    url: `${route}/${id}`,
    errorMessages: {
      '*': 'Failed to delete portal article, please contact support for assistance.',
    },
  });
