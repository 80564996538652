import Skeleton from '@mui/material/Skeleton';

const UpdatedLibraryPagesSkeleton = () => (
  <>
    <Skeleton
      variant="rounded"
      animation="wave"
      sx={(theme) => ({
        height: '3rem',
        marginBottom: theme.spacing('xxs'),
        marginTop: theme.spacing('sm'),
      })}
    />
    <Skeleton
      variant="rounded"
      animation="wave"
      sx={(theme) => ({
        height: '3rem',
        marginBottom: theme.spacing('xxs'),
      })}
    />
    <Skeleton
      variant="rounded"
      animation="wave"
      sx={(theme) => ({
        height: '3rem',
        marginBottom: theme.spacing('xxs'),
      })}
    />
  </>
);

export { UpdatedLibraryPagesSkeleton };
