import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

export type FormProps = {
  id?: string;
  sx?: SxProps<Theme>;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  children: React.ReactNode | React.ReactNode[];
};

const Form = ({ id, sx, onSubmit, children }: FormProps) => {
  return (
    <Box id={id} sx={sx} component="form" onSubmit={onSubmit}>
      {children}
    </Box>
  );
};

export { Form };
