import DataModel from './DataModel';

class BlogAuthor extends DataModel {
  constructor(id, attributes, includes) {
    super('BlogAuthor', id, attributes, includes);
  }

  getOid() {
    return this.attributes.userId;
  }

  getPersonName() {
    return this.attributes.name;
  }

  hasDescription() {
    return Boolean(this.attributes.description);
  }

  isGuest() {
    return !this.id;
  }

  getData() {
    if (this.id) {
      return {
        _doc: this.id,
        name: this.attributes.name,
        oid: this.attributes.oid,
      };
    }

    return { ...this.attributes };
  }

  static createFromSearchHit(searchHit) {
    const id = searchHit.id.substring(searchHit.id.indexOf('-') + 1);
    const personName = `${searchHit.attributes.firstName} ${searchHit.attributes.lastName}`;
    const attributes = {
      userId: id,
      name: personName,
      imageUrl: searchHit.get('imageUrl'),
      title: searchHit.attributes.title,
    };

    return BlogAuthor.create(attributes);
  }

  static create(attributes) {
    if (!attributes) {
      return null;
    }

    return new BlogAuthor(attributes._doc, attributes);
  }
}

export default BlogAuthor;
