import Box from 'components/Box';
import Button from 'components/Button';
import Flex from 'components/Flex';
import { Column, Container } from 'components/Grid';
import { ReactComponent as NewPage } from 'components/Icon/NewPage.svg';
import Range from 'components/Range';
import Select from 'components/Select';
import Title from 'components/Title';
import PropTypes from 'prop-types';

const SelectFilter = ({
  onChange,
  placeholder,
  selected,
  options = [],
  disabled = false,
}) => {
  return (
    <Select
      data={options.map((o) => ({
        name: `${o.name} (${o.positionCount})`,
        value: o.value,
        depth: o.depth,
        childLocations: o.childLocations,
      }))}
      onChange={onChange}
      placeholder={placeholder}
      selected={selected}
      color="black"
      multiple={true}
      deSelectEnabled={true}
      disabled={disabled}
    />
  );
};

const getSeniorityRange = (seniorityOptions, senioritySelection) => {
  return senioritySelection
    ? [
        senioritySelection[0].value,
        senioritySelection[senioritySelection.length - 1].value,
      ]
    : [0, seniorityOptions.length - 1];
};

const RangeFilter = ({
  selected,
  options = [
    { name: '', value: 0 },
    { name: '', value: 1 },
  ], // Display empty range slider on loading
  onChange,
}) => {
  const selectedRange = getSeniorityRange(options, selected);

  return <Range onChange={onChange} selected={selectedRange} steps={options} />;
};

const JobsFilter = ({
  filterOptions,
  error,
  filterValues,
  disableReset,
  onResetFilters,
  onUpdateFilters,
  onUpdateSeniority,
}) => {
  if (error) {
    return null;
  }

  const isLoading = !filterOptions;

  // GPS are sending same category name with different category id.
  // Therefore we have need to group categories on name below.
  // if GPS stops sending different ids for same category name the below code can be removed.

  if (!isLoading) {
    filterOptions.categories = (filterOptions.categories || []).reduce(
      (acc, curr) => {
        const found = acc.find((category) => curr.name === category.name);
        found ? (found.positionCount += curr.positionCount) : acc.push(curr);
        return acc;
      },
      []
    );
  }

  return (
    <Box className="p-b-0 b-b-2">
      <Flex type="space-between m-b-6">
        <Title className="p-0" tag="h3">
          Filter jobs
        </Title>
        <Button
          disabled={disableReset}
          size="small"
          width="narrow"
          onClick={onResetFilters}
        >
          Reset
        </Button>
      </Flex>
      <Container padding={false} className="sm-m-b-4 m-b-8">
        <Column className="sm-m-b-4">
          <SelectFilter
            isLoading={isLoading}
            options={filterOptions && filterOptions.regions}
            placeholder="All regions"
            onChange={(selected) => onUpdateFilters('regions', selected)}
            selected={filterValues.regions}
            disabled={filterOptions && !(filterOptions.regions || []).length}
          />
        </Column>
        <Column className="sm-m-b-4">
          <SelectFilter
            isLoading={isLoading}
            options={filterOptions && filterOptions.locations}
            placeholder="All locations"
            onChange={(selected) => onUpdateFilters('locations', selected)}
            selected={filterValues.locations}
            disabled={filterOptions && !(filterOptions.locations || []).length}
          />
        </Column>
        <Column className="sm-m-b-4">
          <SelectFilter
            isLoading={isLoading}
            options={filterOptions && filterOptions.states}
            placeholder="All states and cities"
            onChange={(selected) => onUpdateFilters('states', selected)}
            selected={filterValues.states}
            disabled={filterOptions && !(filterOptions.states || []).length}
            deselectEnabled={true}
          />
        </Column>
        <Column className="sm-m-b-4">
          <SelectFilter
            isLoading={isLoading}
            options={filterOptions && filterOptions.categories}
            placeholder="All functions"
            onChange={(selected) => onUpdateFilters('categories', selected)}
            selected={filterValues.categories}
            disabled={filterOptions && !filterOptions.categories.length}
          />
        </Column>
      </Container>

      <div className="p-b-6">
        <RangeFilter
          isLoading={isLoading}
          onChange={onUpdateSeniority}
          options={filterOptions && filterOptions.seniority}
          selected={filterValues.seniority}
        />
      </div>
      <Flex type="flex-end m-t-6 p-t-4 m-b-6">
        <a
          className="joblist__advanced-job-search-link"
          href="https://performancemanager.successfactors.eu/sf/careers/jobalerts?bplte_company=assaabloya&_s.crb=P1cvdVsWM%2bP%2bYxjxDNPAexNRHZjvi4FeYzroRjojpdw%3d"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Flex type="flex-end" align="center">
            <span className="m-r-3">Subscribe for job alerts in GPS</span>{' '}
            <NewPage />
          </Flex>
        </a>
      </Flex>
    </Box>
  );
};

const selectFilterOptionShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    positionCount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    depth: PropTypes.number,
  })
).isRequired;

const selectFilterValueShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })
).isRequired;

const rangeFilterShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })
);

JobsFilter.propTypes = {
  filterOptions: PropTypes.shape({
    regions: selectFilterOptionShape,
    locations: selectFilterOptionShape,
    states: selectFilterOptionShape,
    categories: selectFilterOptionShape,
    seniority: rangeFilterShape.isRequired,
  }),
  filterValues: PropTypes.shape({
    regions: selectFilterValueShape,
    locations: selectFilterValueShape,
    states: selectFilterValueShape,
    categories: selectFilterValueShape,
    seniority: rangeFilterShape,
  }).isRequired,

  error: PropTypes.bool,
  disableReset: PropTypes.bool,
  onResetFilters: PropTypes.func.isRequired,
  onUpdateFilters: PropTypes.func.isRequired,
  onUpdateSeniority: PropTypes.func.isRequired,
  analyticsEvent: PropTypes.func,
};

export default JobsFilter;
