import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const BoxLoader = () => (
  <>
    <rect x={0} y={5} width={640} height={166} />
    <rect x={0} y={190} rx={4} ry={4} width={70} height={15} />
    <rect x={0} y={225} rx={4} ry={4} width={400} height={15} />
    <rect
      x={0}
      y={250}
      rx={4}
      ry={4}
      width={getRandomNumberBetween(200, 370)}
      height={30}
    />
    <rect x={0} y={295} rx={4} ry={4} width={400} height={15} />
    <rect x={550} y={5} width={640} height={166} />
    <rect x={550} y={200} rx={4} ry={4} width={70} height={15} />
    <rect x={550} y={225} rx={4} ry={4} width={400} height={15} />
    <rect
      x={550}
      y={250}
      rx={4}
      ry={4}
      width={getRandomNumberBetween(300, 400)}
      height={30}
    />
    <rect x={550} y={295} rx={4} ry={4} width={400} height={15} />
  </>
);

const Loader = () => (
  <Resize>
    {() => (
      <div className="insights-database-topics-box__content-loader">
        <ContentLoader
          className="insights-database-topics-box__content-loader__item"
          height={332}
          preserveAspectRatio="none"
        >
          <BoxLoader />
        </ContentLoader>
        <ContentLoader
          className="insights-database-topics-box__content-loader__item"
          height={332}
          preserveAspectRatio="none"
        >
          <BoxLoader />
        </ContentLoader>
        <ContentLoader
          className="insights-database-topics-box__content-loader__item"
          height={332}
          preserveAspectRatio="none"
        >
          <BoxLoader />
        </ContentLoader>
      </div>
    )}
  </Resize>
);

export default Loader;
