import { TEMPLATES } from 'api/models/PortalNode';
import { Redirect } from 'react-router-dom';

import Content from './Templates/Content';
import InnovationStart from './Templates/InnovationStart';
import InsightsContent from './Templates/InsightsContent';
import InsightsListing from './Templates/InsightsListing';
import Listing from './Templates/Listing';
// Put StrategyStart back here [TEMPLATES.STRATEGY_START]: when Strategy House campaign ends.
import StrategyStart from './Templates/StrategyStart';

const viewMap = {
  [TEMPLATES.LISTING]: Listing,
  [TEMPLATES.CONTENT]: Content,
  [TEMPLATES.ITEM]: Content,
  //deprecate soon
  [TEMPLATES.PORTAL]: InnovationStart,
  //==============
  [TEMPLATES.INNOVATION_START]: InnovationStart,
  [TEMPLATES.STRATEGY_START]: StrategyStart,
  [TEMPLATES.INSIGHTS_LISTING]: InsightsListing,
  [TEMPLATES.INSIGHTS_CONTENT]: InsightsContent,
};

const ViewPage = ({ location, page, isLoading, isCodeOfConduct }) => {
  if (isLoading) {
    return <div />;
  }

  if (!page) {
    return <Redirect to="/404" />;
  }

  const Component = viewMap[page.get('template')];

  if (!Component) {
    return <div>{`Error template not found for ${page.get('template')}`}</div>;
  }

  return (
    <Component
      page={page}
      location={location}
      isCodeOfConduct={isCodeOfConduct}
    />
  );
};

export default ViewPage;
