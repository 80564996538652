import './PortalSearch.scss';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import SearchField from './SearchField';

interface PortalSearchProps extends RouteComponentProps {
  submitQuery: (searchQuery: string) => void;
  portalType: string;
}

const PortalSearch = ({
  submitQuery,
  history,
  portalType,
}: PortalSearchProps) => {
  return (
    <div className="portal-search__innovation-search-bar">
      <SearchField
        submitQuery={submitQuery}
        history={history}
        portalType={portalType}
      />
    </div>
  );
};

export default withRouter(PortalSearch);
