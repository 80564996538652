import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Parser from 'components/Parser';

interface VivaEngageEmbedBlockProp {
  title: string;
  body?: string;
  linkUrl: string;
}

export const VivaEngageEmbedBlock = ({
  title,
  body,
  linkUrl,
}: VivaEngageEmbedBlockProp) => {
  return (
    <Box
      sx={() => ({
        backgroundColor: 'rgb(250, 249, 248)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      })}
    >
      <Box
        sx={() => ({
          maxWidth: '750px',
          margin: '0 auto',
        })}
      >
        <Box
          sx={() => ({
            padding: '0 1.5rem',
          })}
        >
          <Typography
            sx={() => ({
              margin: '1rem 0',
            })}
            variant="h2"
            component="h2"
          >
            {title}
          </Typography>
          <Parser html={body} type="Feed" />
          <Divider
            sx={() => ({
              margin: '1rem 0',
            })}
          />
        </Box>
        <iframe
          name="embed-feed"
          title="Viva Engage"
          src={`${linkUrl}?header=false&footer=true&theme=light&includeFeedInformation=true`}
          style={{
            border: '0px',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            minHeight: '850px',
          }}
        ></iframe>
      </Box>
    </Box>
  );
};
