import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Loader = ({ width }) => (
  <li className="joblist-item">
    <ContentLoader
      width={width}
      height={73}
      className="joblist-item__contentloader"
      preserveAspectRatio="none"
    >
      <rect x={0} y={0} width={getRandomNumberBetween(300, 600)} height={10} />
      <rect x={0} y={30} width={getRandomNumberBetween(40, 100)} height={10} />
      <rect x={0} y={61} width={10} height={10} />
      <rect
        x={16}
        y={61}
        width={getRandomNumberBetween(140, 300)}
        height={10}
      />
    </ContentLoader>
  </li>
);

export default Loader;
