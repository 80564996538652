import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Comments from 'components/Comments';
import { useMyMoment } from 'components/Context/MyMoment';
import PageTitle from 'components/PageTitle';
import Parser from 'components/Parser';
import { VideoList } from 'componentsNew/VideoList/VideoList';
import { useEffect } from 'react';
import settings from 'settings';
import { translations } from 'translations';
import { createEmbedUrl } from 'utils/cms/parseVideoUrls';

import { MyMomentSkeleton } from './MyMomentSkeleton';

const { cdnTokenKey } = settings.misc;
const { cms } = settings.cmsBaseImagePath;

const MyMoment = () => {
  const { myMoment, isError, isLoading, fetchMyMoment } = useMyMoment();

  useEffect(() => {
    fetchMyMoment();
  }, [fetchMyMoment]);

  if (isLoading) {
    return (
      <>
        <PageTitle titles={[translations.myMoment]} />
        <MyMomentSkeleton />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PageTitle titles={[translations.myMoment]} />
        <Box
          sx={(theme) => ({
            width: '720px',
            maxWidth: '100%',
            padding: { xs: theme.spacing('sm'), md: theme.spacing('md') },
            margin: `${theme.spacing('md')} auto`,
            backgroundColor: theme.colors.surface.primary,
            boxShadow: theme.elevation.sm,
          })}
        >
          {translations.myMomentFetchError}
        </Box>
      </>
    );
  }

  if (!myMoment) {
    return null;
  }

  return (
    <>
      <PageTitle titles={[translations.myMoment]} />
      <Stack
        sx={(theme) => ({
          height: '100%',
          maxWidth: '100%',
          width: '1140px',
          margin: '0 auto',
          gap: theme.spacing('md'),
          padding: { xs: theme.spacing('sm'), md: theme.spacing('md') },
        })}
      >
        <Stack
          sx={(theme) => ({
            flexDirection: { xs: 'column', md: 'row' },
            gap: theme.spacing('md'),
          })}
        >
          <Stack
            sx={(theme) => ({
              padding: theme.spacing('md'),
              backgroundColor: theme.colors.surface.primary,
              gap: theme.spacing('md'),
              flexGrow: 1,
              maxWidth: { xs: '100%', md: '675px' },
              minHeight: '44rem',
              boxShadow: theme.elevation.sm,
            })}
          >
            {myMoment.title && (
              <Typography variant="h1" fontWeight="normal">
                {myMoment.title}
              </Typography>
            )}

            {myMoment.heroImage && (
              <Box
                id="my-moment-image"
                component="img"
                src={`${cms}${myMoment.heroImage}/768?${
                  cdnTokenKey ? cdnTokenKey : ''
                }`}
                alt={myMoment.heroAltText || ''}
                title={myMoment.heroAltText || ''}
              />
            )}

            {myMoment.heroVideo && (
              <Box
                sx={() => ({
                  width: '100%',
                  height: '23rem',
                  border: 0,
                })}
                id="my-moment-video"
                component="iframe"
                src={createEmbedUrl(myMoment.heroVideo)}
                title={translations.myMomentIframeTitle}
              />
            )}

            {myMoment.body && (
              <Typography variant="body1" component="div">
                <Parser html={myMoment.body} type="MyMoment" />
              </Typography>
            )}
          </Stack>
          {myMoment.videoPlaylistId && (
            <VideoList
              id="my-moment-videos"
              title={translations.myMomentVideos}
              videoListId={myMoment.videoPlaylistId.value}
              sx={(theme) => ({
                boxShadow: theme.elevation.sm,
                width: { xs: '100%', md: '380px' },
                maxHeight: '44rem',
                flexShrink: 0,
              })}
            />
          )}
        </Stack>
        {myMoment.commentsTargetId && (
          <Stack
            sx={(theme) => ({
              padding: theme.spacing('md'),
              backgroundColor: theme.colors.surface.primary,
              boxShadow: theme.elevation.sm,
            })}
          >
            <Comments
              articleId={myMoment.commentsTargetId}
              articleTitle={myMoment.title}
              type={myMoment.type}
              limit={5}
              hideTopDivider={true}
              commentInputMaxLength={1000}
              commentInputTexts={{
                title: translations.myMomentInputTitle,
                placeholder: translations.myMomentInputPlaceHolder,
                submitButton: translations.myMomentInputSubmit,
                submitModalTitle: translations.myMomentInputSubmitModalTitle,
                submitModalText: translations.myMomentInputSubmitModalText,
                loadMore: translations.myMomentLoadMore,
              }}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export { MyMoment };
