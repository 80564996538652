import { default as iconDocx } from './icon-assets/icon-docx.svg';
import { default as iconFile } from './icon-assets/icon-file.svg';
import { default as iconFolder } from './icon-assets/icon-folder.svg';
import { default as iconImage } from './icon-assets/icon-image-file.svg';
import { default as iconPdf } from './icon-assets/icon-pdf.svg';
import { default as iconPptx } from './icon-assets/icon-pptx.svg';
import { default as iconXlsx } from './icon-assets/icon-xlsx.svg';

const assets = {
  bmp: iconImage,
  docx: iconDocx,
  gif: iconImage,
  jpeg: iconImage,
  jpg: iconImage,
  odf: iconDocx,
  pdf: iconPdf,
  png: iconImage,
  ppt: iconPptx,
  pptx: iconPptx,
  svg: iconImage,
  xls: iconXlsx,
  xlsx: iconXlsx,
};

const parseExtension = (filename) => {
  const mixedMatch = /\.([^/.]+)$|([^/]+)\/$/.exec(filename);
  if (!mixedMatch) {
    return { type: null };
  }
  if (mixedMatch[2]) {
    return { type: 'folder' };
  }
  return {
    type: 'file',
    iconBmp: null,
    extension: mixedMatch[1].toLowerCase(),
  };
};

const getIconAsset = (filename, returnTypeName = false) => {
  const { type, extension } = parseExtension(filename);
  const extensionAsset = assets[extension];
  if (!type || (type === 'file' && !extensionAsset)) {
    return returnTypeName ? 'file' : iconFile;
  }
  if (type === 'file' && extensionAsset) {
    return returnTypeName ? extension : extensionAsset;
  }
  if (type === 'folder') {
    return returnTypeName ? 'folder' : iconFolder;
  }
};

export default getIconAsset;
