import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import { GAonClickHomeFeedReadMore } from 'utils/analytics';

type ReadMoreProps = {
  id: string;
  to: string;
};

const ReadMore = ({ id, to }: ReadMoreProps) => {
  return (
    <Box
      sx={(theme) => ({
        float: 'right',
        marginTop: theme.spacing('xs'),
      })}
    >
      <Button
        id={id}
        to={to}
        variant="linkButton"
        component={ReactRouterLink}
        onClick={GAonClickHomeFeedReadMore}
      >
        {translations.articleFeedReadMore}
      </Button>
    </Box>
  );
};

export { ReadMore };
