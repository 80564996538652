import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const getTags = () =>
  createRequest({
    method: 'get',
    url: `${cms}/tag/`,
    errorMessages: {
      '*': 'Failed to get tag, please contact support for assistance.',
    },
  });

export const getTag = (name) =>
  createRequest({
    method: 'get',
    url: `${cms}/tag/${name}`,
    errorMessages: {
      '*': 'Failed to get tag, please contact support for assistance.',
    },
  });
