import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { find } = settings.api;

export const getSearchResults = (query) =>
  createRequest({
    method: 'get',
    url: `${find}/search${query}`,
    errorMessages: {
      '*': 'Failed to get search results, please contact support for assistance.',
    },
  });

export const getAutoSuggestions = (query) =>
  createRequest({
    method: 'get',
    url: `${find}/suggestions${query}`,
    errorMessages: {
      '*': 'Failed to get search suggestions, please contact support for assistance.',
    },
  });

export const getRecentSearches = () =>
  createRequest({
    method: 'get',
    url: `${find}/recentsearches`,
    errorMessages: {
      '*': 'Failed to get recent searches, please contact support for assistance.',
    },
  });

export const addRecentSearch = (query) =>
  createRequest({
    method: 'post',
    url: `${find}/recentsearches${query}`,
    errorMessages: {
      '*': 'Failed to add recent searches, please contact support for assistance.',
    },
  });
