import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const DisableComments = () => (
  <Consumer>
    {({ onToggleArticleValue, article: { disableComments } }) => (
      <>
        <Flex>
          <Checkbox
            defaultChecked={disableComments}
            id="disableComments"
            onChange={() => onToggleArticleValue('disableComments')}
          />
          <label htmlFor="disableComments" className="m-l-3">
            Disable comments
          </label>
        </Flex>
      </>
    )}
  </Consumer>
);

export default DisableComments;
