import './PlayButton.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const PlayButton = ({ onClick, size = 'sm' }) => {
  const playButtonClassName = classNames('play-button', {
    [`play-button--${size}`]: size,
  });

  return (
    <div className={playButtonClassName} onClick={onClick}>
      <div className="play-button-symbol" />
    </div>
  );
};

PlayButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default PlayButton;
