import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Loader = ({ width = 1050 }) => (
  <>
    {Array(3)
      .fill(0)
      .map((_, i) => (
        <ContentLoader
          className="faq__contentloader"
          width={width}
          height={100}
          preserveAspectRatio="none"
          key={i}
        >
          <rect
            x={0}
            y={0}
            width={getRandomNumberBetween(200, 300)}
            height={18}
          />
          <rect
            x={0}
            y={38}
            width={getRandomNumberBetween(400, 800)}
            height={15}
          />
          <rect
            x={0}
            y={63}
            width={getRandomNumberBetween(400, 800)}
            height={15}
          />
        </ContentLoader>
      ))}
  </>
);

export default Loader;
