import './MainMenu.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import PortalNode, { TEMPLATES } from 'api/models/PortalNode';
import classNames from 'classnames';
import { Column, Container } from 'components/Grid';
import { ReactComponent as ChevronLeft } from 'components/Icon/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'components/Icon/ChevronRight.svg';
import MegaMenu from 'components/Portal/MegaMenu';
import MenuItem from 'components/Portal/MenuItem';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Overflow from 'react-overflow-indicator';
import { scrollByHelper } from 'utils/misc/scrollByPonyfillHelper';
import useWindowDimensions from 'utils/misc/useWindowDimensions';

const MAX_WIDTH = 1280;

const MainMenu = ({ page, selectionPath }) => {
  const [hoveredPage, setHoveredPage] = useState(null);
  const [megaMenuPage, setMegaMenuPage] = useState(null);
  const [viewportl1, setviewportl1] = useState(null);
  const [viewportl2, setviewportl2] = useState(null);

  // empty useState needed to get updated values for useWindowDimensions and useRef  - width
  const [, setForceComponentRerender] = useState();

  const { width } = useWindowDimensions();

  // Creates references for first level menu items and Overflow indikator
  const l1PagesUl = useRef(null);

  // Creates a reference for second level menu items
  let l2PagesUl = useRef(null);

  const [rootNode, selectedL1Page, selectedL2Page] = selectionPath;

  useEffect(() => {
    if (rootNode && page) {
      const l1Pages = rootNode
        .getChildPages()
        .filter((page) => !page.shouldHideFromNavigation());

      setForceComponentRerender(l1Pages);
    }
  }, [page, rootNode]);

  if (!page) {
    return null;
  }

  const l1Pages = rootNode
    .getChildPages()
    .filter((page) => !page.shouldHideFromNavigation());

  let l2Pages;

  if (
    selectedL1Page &&
    selectedL1Page.attributes.template !== TEMPLATES.CONTENT
  ) {
    l2Pages = selectedL1Page
      .getChildPages(null, ['insights-content', 'insights-listing'])
      .filter((page) => !page.shouldHideFromNavigation());
  }

  const menuClassName = classNames('portal-main-menu', {
    'portal-main-menu--has-second-level': l2Pages,
  });

  const submenuClassName = classNames(
    'portal-main-menu__level portal-main-menu__level--second',
    {
      'portal-main-menu__level--underlined': l2Pages && l2Pages.length,
      'portal-main-menu__level--megamenu-opened': megaMenuPage,
    }
  );

  const closeMegaMenu = () => {
    setMegaMenuPage(null);
  };

  const updateHoveredPage = (hoveredPage) => {
    setHoveredPage(hoveredPage);
  };

  const setMegaMenuContents = (page) => {
    if (megaMenuPage !== page) {
      if (page && page.getChildPageCount(true)) {
        setMegaMenuPage(page);
      } else {
        setMegaMenuPage(null);
      }
    }
  };

  const handleFirstLevelScrollRef = (refs, canScroll, upperMenuClicked) => {
    if (upperMenuClicked && viewportl1 && l1PagesUl && l1PagesUl.current) {
      scrollByHelper(viewportl1, {
        left: -l1PagesUl.current.clientWidth,
        behavior: 'smooth',
      });
      return;
    }

    if (
      !refs ||
      !refs.viewport ||
      !refs.viewport.current ||
      !l1PagesUl ||
      !l1PagesUl.current
    ) {
      return;
    }

    scrollByHelper(refs.viewport.current, {
      left: canScroll
        ? refs.viewport.current.clientWidth
        : -l1PagesUl.current.clientWidth,
      behavior: 'smooth',
    });

    setviewportl1(refs.viewport.current);
  };

  const handleSecondLevelScrollRef = (refs, canScroll, upperMenuClicked) => {
    if (upperMenuClicked && viewportl2 && l2PagesUl && l2PagesUl.current) {
      scrollByHelper(viewportl2, {
        left: -l2PagesUl.current.clientWidth,
        behavior: 'smooth',
      });
      return;
    }

    if (
      !refs ||
      !refs.viewport ||
      !refs.viewport.current ||
      !l2PagesUl ||
      !l2PagesUl.current
    ) {
      return;
    }

    scrollByHelper(refs.viewport.current, {
      left: canScroll
        ? refs.viewport.current.clientWidth
        : -l2PagesUl.current.clientWidth,
      behavior: 'smooth',
    });

    setviewportl2(refs.viewport.current);
  };

  return (
    <div
      onMouseLeave={() => {
        setMegaMenuContents(null);
        updateHoveredPage(null);
      }}
    >
      <nav className={menuClassName}>
        <div className="portal-main-menu__level portal-main-menu__level--first new-design-page-spacing">
          <Column noPaddingLeft={true}>
            <Overflow className="portal-main-menu__overflow">
              <Overflow.Content>
                <ul className="portal-main-menu__items" ref={l1PagesUl}>
                  <MenuItem
                    className="portal-main-menu__item"
                    label="Home"
                    page={rootNode}
                    isActive={page === rootNode}
                    href={rootNode.getPath()}
                  />
                  {l1Pages.map((l1Page) => (
                    <MenuItem
                      className="portal-main-menu__item"
                      key={l1Page.id}
                      page={l1Page}
                      isActive={l1Page === selectedL1Page}
                      onClick={() => {
                        closeMegaMenu();
                        handleSecondLevelScrollRef(null, null, true);
                      }}
                      href={l1Page.getPath()}
                    />
                  ))}

                  <li
                    className="portal-main-menu__before-hover-overlay"
                    onMouseOver={() => {
                      setMegaMenuContents(null);
                      updateHoveredPage(null);
                    }}
                  ></li>
                  <li
                    className="portal-main-menu__after-hover-overlay"
                    onMouseOver={() => {
                      setMegaMenuContents(null);
                      updateHoveredPage(null);
                    }}
                  ></li>
                </ul>
              </Overflow.Content>
              {l1PagesUl && l1PagesUl.current && (
                <>
                  {l1PagesUl.current.clientWidth > MAX_WIDTH ? (
                    <Overflow.Indicator direction="right">
                      {(canScroll, refs) => (
                        <button
                          type="button"
                          onClick={() => {
                            handleFirstLevelScrollRef(refs, canScroll);
                          }}
                          className="portal-main-menu__scroll-button"
                        >
                          {canScroll ? <ChevronRight /> : <ChevronLeft />}
                        </button>
                      )}
                    </Overflow.Indicator>
                  ) : (
                    <>{handleFirstLevelScrollRef(null, null, true)}</>
                  )}
                </>
              )}
            </Overflow>
          </Column>
        </div>
        <ClickAwayListener onClickAway={closeMegaMenu}>
          <div className={submenuClassName}>
            <Container>
              <Column>
                {l2Pages && l2Pages.length > 0 ? (
                  <Overflow className="portal-main-menu__overflow portal-main-menu__overflow--second-level">
                    <Overflow.Content>
                      <ul className="portal-main-menu__items" ref={l2PagesUl}>
                        {l2Pages.map((l2Page) => (
                          <MenuItem
                            className="portal-main-menu__item"
                            key={l2Page.id}
                            page={l2Page}
                            isActive={l2Page === selectedL2Page}
                            isHovered={l2Page === hoveredPage}
                            isMegaMenuOpened={l2Page === megaMenuPage}
                            hasArrow={l2Page.getChildPageCount(true) > 0}
                            href={l2Page.getPath()}
                            onClick={closeMegaMenu}
                            onMouseOver={() => {
                              setMegaMenuContents(l2Page);
                              updateHoveredPage(l2Page);
                            }}
                          />
                        ))}

                        <li
                          className="portal-main-menu__before-hover-overlay"
                          onMouseOver={() => {
                            setMegaMenuContents(null);
                            updateHoveredPage(null);
                          }}
                        ></li>
                        <li
                          className="portal-main-menu__after-hover-overlay"
                          onMouseOver={() => {
                            setMegaMenuContents(null);
                            updateHoveredPage(null);
                          }}
                        ></li>
                      </ul>
                    </Overflow.Content>
                    {l2PagesUl && l2PagesUl.current && (
                      <>
                        {l2PagesUl.current.clientWidth > width ||
                        l2PagesUl.current.clientWidth > MAX_WIDTH ||
                        l2PagesUl.current.clientWidth > 1110 ? (
                          <Overflow.Indicator direction="right">
                            {(canScroll, refs) => (
                              <button
                                type="button"
                                onClick={() =>
                                  handleSecondLevelScrollRef(refs, canScroll)
                                }
                                className="portal-main-menu__scroll-button portal-main-menu__scroll-button--second-level"
                              >
                                {canScroll ? <ChevronRight /> : <ChevronLeft />}
                              </button>
                            )}
                          </Overflow.Indicator>
                        ) : (
                          <>{handleSecondLevelScrollRef(null, null, true)}</>
                        )}
                      </>
                    )}
                  </Overflow>
                ) : null}
              </Column>
            </Container>
          </div>
        </ClickAwayListener>
      </nav>
      <MegaMenu
        onClose={closeMegaMenu}
        menuPage={megaMenuPage}
        selectionPath={selectionPath}
      />
    </div>
  );
};

MainMenu.propTypes = {
  page: PropTypes.instanceOf(PortalNode),
  megaMenuPage: PropTypes.instanceOf(PortalNode),
  selectionPath: PropTypes.arrayOf(PropTypes.instanceOf(PortalNode)),
  toggleMegaMenu: PropTypes.func.isRequired,
};

export default MainMenu;
