import { getPage } from 'api/cms/public';
import Box from 'components/Box';
import Fetcher from 'components/Fetcher';
import Footer from 'components/Footer';
import { Column, Container } from 'components/Grid';
import PageTitle from 'components/PageTitle';
import Resize from 'components/Resize';
import Title from 'components/Title';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';

import ContentLoader from './ContentLoader';

const onLoading = () => (
  <>
    <Title color="white">Loading...</Title>
    <Box>
      <Resize>{({ width }) => <ContentLoader width={width} />}</Resize>
    </Box>
  </>
);

const Page = ({ url, title }) => (
  <>
    <PageTitle titles={[title]} />
    <div className="main-content">
      <Container width="thin">
        <Column>
          <Fetcher source={getPage} param={url} onLoading={onLoading}>
            {({ response }) => (
              <>
                <Title color="white">{title}</Title>
                <Box>
                  <div className="content">{Parser(response.body)}</div>
                </Box>
              </>
            )}
          </Fetcher>
        </Column>
      </Container>
    </div>
    <Footer />
  </>
);

Page.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Page;
