import Box from 'components/Box';
import Title from 'components/Title';

const TargetingOptions = ({ children }: any) => (
  <>
    <Title size="small">Targeting</Title>
    <Box>{children}</Box>
  </>
);

export default TargetingOptions;
