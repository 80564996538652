import './TemplateSelector.scss';

import Button from 'components/Button';
import PropTypes from 'prop-types';

const TemplateSelector = ({ templates, onSelect }) => {
  return (
    <div>
      {templates.map((template) => (
        <Button
          className="template-selector__button"
          onClick={() => onSelect(template)}
          key={template}
        >
          {template}
        </Button>
      ))}
    </div>
  );
};

TemplateSelector.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default TemplateSelector;
