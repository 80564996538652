import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

export type FormStackProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode | React.ReactNode[];
};

const FormStack = ({ sx, children }: FormStackProps) => {
  return (
    <Stack
      sx={[
        (theme) => ({ gap: theme.spacing('md') }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Stack>
  );
};

export { FormStack };
