import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const StoriesSkeleton = () => (
  <Stack
    sx={(theme) => ({
      height: '25rem',
      gap: theme.spacing('md'),
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
    })}
  >
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        height: '100%',
        maxWidth: 'unset',
        flexGrow: 1,
      }}
    />
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        height: '100%',
        maxWidth: 'unset',
        flexGrow: 1,
      }}
    />
    <Skeleton
      variant="rounded"
      animation="pulse"
      sx={{
        height: '100%',
        maxWidth: 'unset',
        flexGrow: 1,
      }}
    />
  </Stack>
);

export { StoriesSkeleton };
