import { Consumer } from 'components/CreateOrEditArticle';
import Body from 'components/CreateOrEditArticle/components/Body';
import LinkUrl from 'components/CreateOrEditArticle/components/LinkUrl';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import Title from 'components/CreateOrEditArticle/components/Title';

const VivaEngageEmbedBlockEditor = () => {
  return (
    <Consumer>
      {({
        validationParameters: {
          title: { maxLength },
        },
      }) => {
        const titlePlaceholder = `Title (max ${maxLength} characters)`;
        return (
          <>
            <Title placeholder={titlePlaceholder} />
            <Body showSideButtons={false} placeholder="Body" />
            <LinkUrl editable={false} placeholder="Viva Engage embed Url" />
            <SortIndex />
          </>
        );
      }}
    </Consumer>
  );
};

export default VivaEngageEmbedBlockEditor;
