import './ArticleListBlockBox.scss';

import PortalNode from 'api/models/PortalNode';
import EnhancedLink from 'components/EnhancedLink';
import OnlyWithRole from 'components/OnlyWithRole';
import ArticleListLink from 'components/Portal/ArticleListLink';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { anchorLinkFormatter, jumpToAnchor } from 'utils/misc/jumpToAnchor';

class ArticleListBlockBox extends Component {
  bodyElementRef = null;

  componentDidMount() {
    const blockId = this.props.block.id;
    const { hash } = window.location;
    if (hash.length > 1 && hash.substring(1) === blockId) {
      jumpToAnchor(this.bodyElementRef);
    }
  }

  render() {
    const { block, className, showEditLink } = this.props;
    // If there's nothing in the block body it can be hidden.
    const isBlockEmpty = !block.get('title') && !showEditLink;
    if (isBlockEmpty) {
      return <></>;
    }
    const maxListedCount = block.get('maxListedCount');
    const listedArticles = block
      .get('articlesForListing')
      .filter(
        (_, index) => maxListedCount === undefined || index < maxListedCount
      );
    const linkButtonText = block.get('linkButtonText');
    return (
      <div
        ref={(ref) => (this.bodyElementRef = ref)}
        className={`article-list-block-box ${className || ''}`}
      >
        <div className="article-list-block-box__content">
          <div className="article-list-block-box__title-container">
            <h1 className="article-list-block-box__title">
              <a href={anchorLinkFormatter(window.location, block.id)}>
                {block.get('title')}
              </a>
            </h1>
          </div>
          <ul className="article-list-block-box__links">
            {listedArticles.map((item) => (
              <ArticleListLink key={item.id} to={item} />
            ))}
          </ul>
          {block.get('linkUrl') && (
            <div className="article-list-block-box__link-button-container">
              <EnhancedLink
                to={block.get('linkUrl')}
                className="article-list-block-box__link-button"
              >
                {linkButtonText && linkButtonText.trim() !== ''
                  ? linkButtonText
                  : 'Learn More'}
              </EnhancedLink>
            </div>
          )}
        </div>
        {showEditLink && (
          <OnlyWithRole viewableByRole="AvenuePortalEditor">
            <Link
              className="article-list-block-box__link"
              to={block.getEditPath()}
            >
              Edit
            </Link>
          </OnlyWithRole>
        )}
      </div>
    );
  }
}

ArticleListBlockBox.propTypes = {
  block: PropTypes.instanceOf(PortalNode).isRequired,
  color: PropTypes.oneOf(['white', 'blue']),
  hideDocuments: PropTypes.bool,
  className: PropTypes.string,
};

ArticleListBlockBox.defaultProps = {
  color: 'white',
};

export default ArticleListBlockBox;
