import Flex from 'components/Flex';
import LanguageSelector from 'components/LanguageSelector';
import { Desktop, Mobile } from 'components/Responsive';
import EditLink from 'components/ViewArticle/EditLink';
import PropTypes from 'prop-types';

const BlogNewsHeader = ({
  url,
  viewableByRole,
  response,
  onLanguageChange,
}) => {
  return (
    <>
      <Desktop>
        <Flex type="flex-end">
          <EditLink viewableByRole={viewableByRole} url={url} />
        </Flex>
      </Desktop>
      <Flex type="flex-end">
        <Mobile>
          <LanguageSelector
            className="sm-m-t-2 sm-m-b-2"
            availableTranslations={response.translations}
            currentLocale={response.currentLocale}
            onLanguageChange={(updatedLanguage) =>
              onLanguageChange(updatedLanguage, response)
            }
          />
        </Mobile>
      </Flex>
    </>
  );
};

BlogNewsHeader.propTypes = {
  url: PropTypes.string.isRequired,
  viewableByRole: PropTypes.string.isRequired,
  response: PropTypes.object.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
};

export default BlogNewsHeader;
