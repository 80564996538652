import './RelatedArticles.scss';

import { getInsightsArticles } from 'api/cms/portals/';
import Fetcher from 'components/Fetcher';
import { Link } from 'react-router-dom';
import settings from 'settings';

import RelatedArticleItem from './RelatedArticleItem/RelatedArticleItem';

const insightsDatabaseId = settings.portals[2].insightsDatabaseId;

const RelatedArticlesList = ({ searchRequest, topicId }: any) => {
  return (
    <div className="related-articles">
      <div className="related-articles--header">Related</div>
      <Fetcher
        source={getInsightsArticles}
        param={searchRequest}
        useLoadMore={false}
      >
        {({ response, meta }: any) =>
          meta.total > 0 ? (
            <>
              {response.map((item: any, i: number) => (
                <RelatedArticleItem key={`related-article-${i}`} data={item} />
              ))}
              <div className="related-articles--link">
                <Link
                  to={`/portals/innovation/${insightsDatabaseId}/?topic=${topicId}`}
                >
                  More insights
                </Link>
              </div>
            </>
          ) : (
            <div>No topic related articles from Insights database found.</div>
          )
        }
      </Fetcher>
    </div>
  );
};

export default RelatedArticlesList;
