import './EmbedVideoButton.scss';

import VideoModal from 'components/Editable/Files/VideoModal';
import { ReactComponent as VideoIcon } from 'components/Icon/Video.svg';

const EmbedVideoButton = ({ onEmbedURL, title, close }) => {
  return (
    <VideoModal
      onSubmit={(data) => {
        onEmbedURL(data);
        close();
      }}
    >
      <div className="embed-video-button__container">
        <button className="md-sb-button" type="button">
          <VideoIcon className="embed-video-button__icon" />
        </button>
        <p className="embed-video-button__label">{title}</p>
      </div>
    </VideoModal>
  );
};

export default EmbedVideoButton;
