export const createLabels = (countries, divisions) => {
  if (Array.isArray(countries)) {
    const countriesArr = countries.map((name) => {
      return { ...name };
    });
    return countriesArr;
  }
  if (Array.isArray(divisions)) {
    const divisionArr = divisions.map((name) => {
      return { ...name };
    });
    return divisionArr;
  } else if (countries && countries.name) {
    return [{ name: countries.name, linkType: 'country' }];
  } else if (divisions && divisions.name) {
    return [{ name: divisions.name, linkType: 'division' }];
  } else {
    return [{ name: 'Global', linkType: 'global' }];
  }
};
