import './CommentInput.scss';

import OutlinedInput from '@mui/material/OutlinedInput';
import classNames from 'classnames';
import Button from 'components/Button';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import Title from 'components/Title';
import { UserImage } from 'components/UserImage';
import { forwardRef, useState } from 'react';
import { translations } from 'translations';

interface CommentInputTexts {
  title: string;
  placeholder: string;
  submitButton: string;
  submitModalTitle: string;
  submitModalText: string;
  loadMore: string;
}

interface CommentInputProps {
  userContext: any;
  isCreating: boolean;
  maxLength?: number;
  onSubmit?: (value: string) => Promise<boolean>;
  isReply?: boolean;
  commentInputTexts?: CommentInputTexts;
}

export const CommentInput = forwardRef<typeof OutlinedInput, CommentInputProps>(
  (
    {
      userContext,
      isCreating,
      maxLength = 500,
      onSubmit,
      isReply = false,
      commentInputTexts = {
        title: translations.commentInputTitle,
        placeholder: translations.commentInputPlaceHolder,
        submitButton: translations.commentInputSubmit,
        submitModalTitle: translations.commentInputSubmitModalTitle,
        submitModalText: translations.commentInputSubmitModalText,
        loadMore: translations.loadMoreComments,
      },
    },
    ref
  ) => {
    const {
      title,
      placeholder,
      submitButton,
      submitModalTitle,
      submitModalText,
    } = commentInputTexts;

    const [value, setValue] = useState<string>('');

    const { imageUrl } = userContext;

    return (
      <div
        className={classNames(
          'comment-input',
          `comment-input__${isReply ? 'reply' : 'main'}`
        )}
      >
        {!isReply && (
          <Title tag="h2" border="none" spacing="no-top-margin">
            {title}
          </Title>
        )}
        <div className="comment-input__input-wrapper">
          <UserImage src={imageUrl} />
          <OutlinedInput
            inputRef={ref}
            sx={(theme) => ({
              marginLeft: theme.spacing('sm'),
              marginBottom: theme.spacing('sm'),
            })}
            autoFocus={isReply}
            placeholder={isReply ? 'Reply' : `${placeholder}`}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            inputProps={{ maxLength }}
            multiline={true}
            size="small"
            fullWidth={true}
            minRows={!isReply ? 3 : 1}
          />
        </div>
        <ButtonModalWrapper
          title={submitModalTitle}
          modalChildren={<p>{submitModalText}</p>}
          awaitYes={true}
          yesButton={{
            color: 'black',
            title: 'OK',
            awaitTitle: '...',
          }}
          noButton={{
            color: 'white',
            title: 'Cancel',
          }}
        >
          <Button
            size="small"
            color="orient"
            width="narrow"
            className="comment-input__button"
            disabled={isCreating || !value}
            onClick={async () => {
              if (!onSubmit) return;
              const onSubmitResponse = await onSubmit(value);
              setValue('');
              return onSubmitResponse;
            }}
          >
            {isReply ? 'Reply' : `${submitButton}`}
          </Button>
        </ButtonModalWrapper>
      </div>
    );
  }
);
