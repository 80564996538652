import { Column, Container } from 'components/Grid';
import PageTitle from 'components/PageTitle';

import TopicsContent from './components/TopicsContent';
import TopicsHeader from './components/TopicsHeader';

const title = ['#Topics'];

const Topics = () => {
  return (
    <>
      <Container>
        <Column>
          <PageTitle titles={title} />
          <TopicsHeader />
          <TopicsContent />
        </Column>
      </Container>
    </>
  );
};

export default Topics;
