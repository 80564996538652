import './Content.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import Box from 'components/Box';
import Documents from 'components/Documents';
import OnlyWithRole from 'components/OnlyWithRole';
import BodyBox from 'components/Portal/BodyBox';
import HeaderBox from 'components/Portal/HeaderBox';
import TextMediaBoxList from 'components/Portal/TextMediaBox/List';
import Title from 'components/Title';
import PropTypes from 'prop-types';
import { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';

const getPageState = (page) => {
  if (page.isItemTemplate()) {
    return {
      page: page.getParent(),
      itemPage: page,
    };
  }

  return {
    page,
    itemPage: null,
  };
};

const scrollToElement = (element) => {
  const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
  const header = document.getElementsByClassName('sticky-menu__content')[0];
  const yOffset = header ? header.offsetHeight : 0;

  const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style;

  if (supportsNativeSmoothScroll) {
    window.scrollTo({
      top: parseInt(yCoordinate - yOffset),
      behavior: 'smooth',
    });
  } else {
    (document.scrollingElement || document.documentElement).scrollTop =
      parseInt(yCoordinate - yOffset);
  }
};

class Content extends Component {
  constructor(props) {
    super(props);

    this.scrollRefs = {};

    // use timeout to solve scrollToItem issue
    this.timeoutIds = [];

    this.state = {
      ...getPageState(props.page),
      docCount: null,
      rerenderDocs: true,
    };
  }

  componentDidMount() {
    this.scrollToItem(this.state);
  }

  componentWillUnmount() {
    this.timeoutIds.forEach((id) => {
      clearTimeout(id);
    });
  }

  componentDidUpdate(prevProps) {
    const { page } = this.props;

    if (prevProps.page !== page) {
      const newState = getPageState(page);

      this.setState({ ...newState, rerenderDocs: false }, () => {
        this.scrollToItem(newState);
        this.setState({ rerenderDocs: true });
      });
    }
  }

  countChangeHandler = (docs) => {
    this.setState({ docCount: docs.count });
  };

  scrollToItem = ({ itemPage }) => {
    if (!itemPage) {
      return;
    }

    this.timeoutIds.push(
      setTimeout(() => {
        const ref = this.scrollRefs[itemPage.id];

        if (ref) {
          scrollToElement(ref);
        }
      }, 1)
    );
  };

  render() {
    const { page, docCount, rerenderDocs } = this.state;

    const sharepointFolderName = page.get('sharepointFolderName');

    const documentWrapperClassName = classNames(
      'body-box__documents-wrapper',
      'doc-box',
      {
        loaded: docCount > 0,
      }
    );

    return (
      <div>
        <HeaderBox page={page}>
          {sharepointFolderName && (
            <div className={documentWrapperClassName}>
              <Title
                size="medium"
                color="white"
                border="portal-related"
                className="align-left"
              >
                Documents
              </Title>
              {rerenderDocs && (
                <Box className="m-b-6" color="white" noShadow={true}>
                  <Suspense fallback={<div />}>
                    <Documents
                      folderName={sharepointFolderName}
                      edit={false}
                      onChange={this.countChangeHandler}
                    />
                  </Suspense>
                </Box>
              )}
            </div>
          )}
          <OnlyWithRole viewableByRole="AvenuePortalEditor">
            <div className="editor-controls">
              <Link to={page.getEditPath()}>Edit</Link>
              <Link to={page.getCreateChildPath()}>Add page</Link>
              <Link to={`${page.getCreateChildPath()}-block`}>Add block</Link>
            </div>
          </OnlyWithRole>
        </HeaderBox>
        <BodyBox page={page} hideDocuments={true} />
        <TextMediaBoxList
          page={page}
          scrollRefs={this.scrollRefs}
          renderPageTypes={['item']}
        />
      </div>
    );
  }
}

Content.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
  location: PropTypes.object.isRequired,
};

export default Content;
