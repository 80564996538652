import './MegaMenu.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import { Column, Container } from 'components/Grid';
import Masonry from 'masonry-layout';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';

// loops over all possible heights of the menu from 0 to total number of items
// finds the height that gives the smallest maximum column size at a max of 3
// columns
const findOptimalHeight = (subPages, maxCount) => {
  let optimalSize = maxCount;
  let smallestColumn = maxCount;

  for (let columnSize = 0; columnSize <= maxCount; columnSize += 1) {
    let localLargest = 0;
    let count = 0;
    let columns = 0;

    subPages.forEach((subPage) => {
      const diff = subPage.getChildPageCount(true) + 3;

      if (count + diff >= columnSize) {
        localLargest = Math.max(localLargest, count);
        count = diff;
        columns += 1;
      } else {
        count += diff;
      }
    });

    if (localLargest < smallestColumn && columns < 3) {
      optimalSize = columnSize;
      smallestColumn = localLargest;
    }
  }

  return optimalSize;
};

const getColumns = (menuPage) => {
  // get total items we will display, count extra 3 for each column for
  // an approximate size
  const maxCount =
    menuPage.getSubChildPageCount(true) + menuPage.getChildPageCount(true) * 3;
  const subPages = menuPage
    .getChildPages()
    .filter((page) => !page.shouldHideFromNavigation());
  const columnSize = findOptimalHeight(subPages, maxCount);

  const data = [[], [], []];
  let count = 0;
  let column = 0;

  // fill columns from left to right
  subPages.forEach((subPage) => {
    // plus three for the page itself
    const diff = subPage.getChildPageCount(true) + 3;

    if (count + diff >= columnSize) {
      count = diff;
      column += 1;
    } else {
      count += diff;
    }

    data[column].push(subPage);
  });

  return data;
};

const MenuItem = ({ page, isLarge, isActive, onClick }) => {
  const menuItemClassName = classNames('portal-mega-menu__item', {
    'portal-mega-menu__item--active': isActive,
    'portal-mega-menu__item--large': isLarge,
  });

  return (
    <Link className={menuItemClassName} to={page.getPath()} onClick={onClick}>
      {page.get('title')}
    </Link>
  );
};

const SubMenu = ({ subPage, selectionPath, onClick }) => {
  const childPages = subPage.getChildPages();

  const selectedL3Page = selectionPath[3];
  const selectedL4Page = selectionPath[4];

  return (
    <div className="portal-mega-menu__submenu">
      <MenuItem
        page={subPage}
        isLarge={true}
        onClick={onClick}
        isActive={subPage === selectedL3Page}
      />
      <ul className="portal-mega-menu__items">
        {childPages.map((itemPage) => (
          <li key={itemPage.id}>
            <MenuItem
              page={itemPage}
              onClick={onClick}
              isActive={itemPage === selectedL4Page}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

class MegaMenu extends Component {
  componentDidUpdate() {
    if (this.props.menuPage) {
      if (this.masonry) {
        this.masonry.destroy();
      }

      let masonryElement = document.querySelector(
        '.portal-mega-menu__links-wrapper'
      );

      if (!masonryElement) {
        return;
      }

      this.masonry = new Masonry(masonryElement, {
        itemSelector: '.portal-mega-menu__submenu',
        gutter: 40,
        horizontalOrder: true,
      });
    }
  }

  render() {
    const { menuPage, selectionPath, onClose } = this.props;
    if (!menuPage || !menuPage.children || !menuPage.children.length) {
      return null;
    }

    const columns = getColumns(menuPage);
    return (
      <div style={{ position: 'relative' }}>
        <Container className="portal-mega-menu">
          <Column className="portal-mega-menu__container">
            <div className="portal-mega-menu__col-wrapper">
              <div className="portal-mega-menu__links-wrapper">
                {columns.map((column) =>
                  column.map((subPage) => (
                    <SubMenu
                      key={subPage.id}
                      subPage={subPage}
                      selectionPath={selectionPath}
                      onClick={onClose}
                    />
                  ))
                )}
              </div>
            </div>
          </Column>
        </Container>
      </div>
    );
  }
}

MegaMenu.propTypes = {
  menuPage: PropTypes.instanceOf(PortalNode),
  selectionPath: PropTypes.arrayOf(PropTypes.instanceOf(PortalNode)),
  onClose: PropTypes.func.isRequired,
};

export default MegaMenu;
