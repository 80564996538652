import './StrategyStart.scss';

import PortalNode from 'api/models/PortalNode';
import OnlyWithRole from 'components/OnlyWithRole';
import BodyBox from 'components/Portal/BodyBox';
import HeaderBox from 'components/Portal/HeaderBox';
import PortalSearch from 'components/Portal/PortalSearch';
import TextMediaBoxList from 'components/Portal/TextMediaBox/List';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StrategyHouse from '../StrategyHouse/index';

const StrategyStart = ({ page, isCodeOfConduct }) => {
  return (
    <div className="portal-home">
      <OnlyWithRole viewableByRole="AvenuePortalEditor">
        <div className="portal-home__actions">
          <Link className="portal-home__edit-page" to={page.getEditPath()}>
            Edit
          </Link>
          <Link
            className="portal-home__add-page"
            to={page.getCreateChildPath()}
          >
            Add page
          </Link>
          <Link
            className="portal-home__add-block"
            to={`${page.getCreateChildPath()}-block`}
          >
            Add block
          </Link>
        </div>
      </OnlyWithRole>
      {isCodeOfConduct ? (
        <HeaderBox page={page} showHero={true}>
          <>
            <div className="relative">
              <PortalSearch portalId={page.id} />
            </div>
          </>
        </HeaderBox>
      ) : (
        <StrategyHouse />
      )}
      {isCodeOfConduct ? <BodyBox page={page} /> : <></>}
      <TextMediaBoxList page={page} />
    </div>
  );
};

StrategyStart.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
};

export default StrategyStart;
