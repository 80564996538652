import _ArticleMeta from 'components/ArticleMeta';
import { UserConsumer } from 'components/Context/User';

import { Consumer } from '..';

// Get chosen author to update label with
const getCreatedAuthor = (user, blogAuthor) => {
  if (!blogAuthor) {
    return {
      name: user.displayName,
      oid: user.userId,
    };
  }
  return blogAuthor;
};

const ArticleMeta = ({ isBlog }) => (
  <Consumer>
    {({
      article: { author, createdOn, modifiedOn, lastModifiedBy },
      updatedArticle: { blogAuthor, guestAuthor },
      isCreating,
    }) => {
      if (isCreating) {
        return (
          <UserConsumer>
            {(user) => (
              <_ArticleMeta
                author={getCreatedAuthor(user, author)}
                customCreateDateText="Not yet created"
              />
            )}
          </UserConsumer>
        );
      }

      let authorData = author;
      let editorData = lastModifiedBy;

      if (isBlog) {
        if (blogAuthor) {
          authorData = blogAuthor;
          editorData = blogAuthor;
        }
        if (guestAuthor) {
          authorData = guestAuthor;
          editorData = guestAuthor;
        }
      }

      return (
        <_ArticleMeta
          created={createdOn}
          author={authorData}
          editor={editorData}
          updated={modifiedOn}
        />
      );
    }}
  </Consumer>
);

export default ArticleMeta;
