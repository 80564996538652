import Text from 'components/Editable/Text';
import _Title from 'components/Title';

import { Consumer } from '..';

interface TitleProps {
  editable?: (article: string) => boolean;
  color?: string;
  placeholder?: string;
  hashtag?: string;
  allowedCharacters?: string;
  fieldName?: string;
}

const fieldValidator = (value: any) => {
  const lcValue = (value ? value : '').toLowerCase();

  if (lcValue === '') {
    return 'Article title cannot be empty';
  }
  if (lcValue === 'edit' || lcValue === 'create') {
    return 'Title cannot be a reserved keyword';
  }

  return null;
};

const Title = ({
  editable,
  color,
  placeholder,
  hashtag,
  allowedCharacters,
  fieldName = 'title',
}: TitleProps) => (
  <Consumer>
    {({
      article,
      onChangeArticle,
      validationParameters: {
        [fieldName]: { maxLength },
      },
      showInitialError,
    }) => {
      if (!editable || editable(article)) {
        return (
          <Text
            wrapper={{
              element: 'h1',
              className: 'editable-title',
            }}
            placeholder={placeholder}
            onBlur={(value: any) => onChangeArticle({ [fieldName]: value })}
            validators={[fieldValidator]}
            showInitialError={showInitialError}
            content={article[fieldName]}
            maxLength={maxLength}
            allowedCharacters={allowedCharacters}
            color={color}
            hashtag={hashtag}
          />
        );
      }

      return <_Title>{article[fieldName]}</_Title>;
    }}
  </Consumer>
);

export default Title;
