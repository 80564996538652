import WhitelistUrlModal from 'components/WhitelistUrlModal';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import settings from 'settings';
const { redirectUri } = settings.auth;

const getHtmlFormattedItem = (
  item: string,
  urlRegex: RegExp,
  index: number
): ReactElement => {
  if (item.match(urlRegex)) {
    const lastCharInItem = item.slice(-1);
    let showLastCharInItem = false;
    if (lastCharInItem.match(/(^[.,!?:;"']*$)/)) {
      item = item.slice(0, -1);
      showLastCharInItem = true;
    }

    if (item.includes(redirectUri)) {
      return (
        <WhitelistUrlModal linkUrl={item} openInNewWindow="false">
          <>
            <Link key={index} to={item.replace(redirectUri, '')}>
              {item}
            </Link>
            {showLastCharInItem && <span>{lastCharInItem}</span>}
          </>
        </WhitelistUrlModal>
      );
    } else {
      return (
        <WhitelistUrlModal linkUrl={item} openInNewWindow="true">
          <>
            <a key={index} href={item} target="_blank" rel="noreferrer">
              {item}
            </a>
            {showLastCharInItem && <span>{lastCharInItem}</span>}
          </>
        </WhitelistUrlModal>
      );
    }
  } else {
    return <span key={index}>{item}</span>;
  }
};

export const formatCommentBody = (
  body: string,
  maxLength: number | null = null,
  removeNewLines = false
): ReactElement[] => {
  const urlRegex = /(https?:\/\/[^ \\\n]*)/gi;
  const replacedNewLines = removeNewLines ? body.replace(/\n/g, ' ') : body;
  const bodyArr = replacedNewLines.split(urlRegex);
  let counter = 0;

  return bodyArr.map((item, index) => {
    if (!maxLength) {
      return getHtmlFormattedItem(item, urlRegex, index);
    }

    if (counter + item.length < maxLength) {
      counter = counter + item.length;

      return getHtmlFormattedItem(item, urlRegex, index);
    } else {
      const diff = maxLength - counter;

      if (counter >= maxLength) {
        return <></>;
      } else {
        counter = maxLength;
        const strippedItem = item.substring(0, diff - 3);
        return (
          <>
            {getHtmlFormattedItem(strippedItem, urlRegex, index)}
            <span>...</span>
          </>
        );
      }
    }
  });
};
