import { Component } from 'react';
import settings from 'settings';

const { hotjarKey } = settings.misc;

class Hotjar extends Component {
  componentDidMount() {
    if (!hotjarKey) return;

    const script = document.createElement('script');
    script.setAttribute('id', 'hotjar');
    script.async = true;
    script.innerHTML = `
      (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: ${hotjarKey}, hjsv: 6
        };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      }) (window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    `;
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    if (!hotjarKey) return;

    const script = document.getElementById('hotjar');
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  render() {
    return null;
  }
}

export default Hotjar;
