export const MONTHS_BEFORE_UNPUBLISH = 24;
export const MONTHS_BEFORE_DELETE = 30;

export const isExpired = (article: any) => {
  const earliestValidDate = new Date();
  earliestValidDate.setMonth(
    earliestValidDate.getMonth() - MONTHS_BEFORE_UNPUBLISH
  );

  if (article.lastModifiedBy) {
    return article.lastModifiedBy.date_ms < earliestValidDate.getTime();
  }
  if (article.createdOn) {
    const createdOnDate = new Date(article.createdOn);
    return createdOnDate.getTime() < earliestValidDate.getTime();
  }
  return false;
};
