import RichText from 'components/Editable/RichText';

import { Consumer } from '..';

const Body = ({
  placeholder,
  fieldName = 'body',
  showSideButtons = true,
  form = '',
}) => (
  <Consumer>
    {({ article: { [fieldName]: body }, onChangeArticle }) => (
      <RichText
        wrapper={{ element: 'div', className: 'body' }}
        onBlur={(value) => onChangeArticle({ [fieldName]: value })}
        content={body}
        placeholder={placeholder}
        showSideButtons={showSideButtons}
        form={form}
      />
    )}
  </Consumer>
);

export default Body;
