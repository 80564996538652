import './ChannelSelector.scss';

import { getChannels } from 'api/cms/topics';
import { UserConsumer } from 'components/Context/User';
import Fetcher from 'components/Fetcher';
import SelectButton from 'components/SelectButton';
import PropTypes from 'prop-types';
import { Component } from 'react';
import makeQueryString from 'utils/misc/makeQueryString';

class ChannelSelector extends Component {
  constructor(props) {
    super(props);

    const { defaultSelected, isCreating, onChange } = props;
    if (!isCreating && (!defaultSelected || defaultSelected.length === 0)) {
      onChange([]);

      this.state = {
        selected: [],
        noTopic: true,
        maxSelected: false,
      };
    } else {
      this.state = {
        selected: defaultSelected
          ? defaultSelected.map((r) => ({
              value: r,
              name: r,
            }))
          : [],
        noTopic: false,
        maxSelected: defaultSelected && defaultSelected.length >= 2,
      };
    }
  }

  addRemoveSelected = (selectedTopic) => {
    const { onChange } = this.props;
    const selectedTopics = [...this.state.selected];

    if (selectedTopic.value === 'No topic' && !this.state.noTopic) {
      this.setState({
        selected: [],
        noTopic: !this.state.noTopic,
        maxSelected: false,
      });
      onChange([]);
      return;
    } else if (selectedTopic.value === 'No topic' && this.state.noTopic) {
      this.setState({
        selected: [],
        noTopic: false,
        maxSelected: selectedTopics.length >= 2,
      });
      onChange(null);
      return;
    } else {
      this.setState({ noTopic: false });

      const index = selectedTopics.findIndex(
        (topic) => topic.value === selectedTopic.value
      );

      if (index === -1) {
        selectedTopics.push(selectedTopic);
      } else {
        selectedTopics.splice(index, 1);
      }

      this.setState({ selected: selectedTopics });
      if (!selectedTopics.length) {
        onChange(null);
      } else {
        onChange(selectedTopics.map((r) => r.name));
      }

      this.setState({ maxSelected: selectedTopics.length >= 2 });
    }
  };

  isSelected = (title) => {
    const { selected } = this.state;

    return selected.some((sel) => sel.name === title);
  };

  createDataProp = (response) => {
    const data = {
      value: response.title,
      name: response.title,
    };
    return data;
  };

  render() {
    const { noTopic } = this.state;

    return (
      <>
        <div className="channel-selector">
          <UserConsumer>
            {({ divisionId }) => (
              <Fetcher
                source={getChannels}
                param={makeQueryString({ divisionId })}
              >
                {({ response }) =>
                  response.map((res, index) => {
                    return (
                      <SelectButton
                        key={index}
                        selected={this.isSelected(res.title)}
                        data={this.createDataProp(res)}
                        addRemoveSelected={this.addRemoveSelected}
                        disabled={
                          !this.isSelected(res.title) && this.state.maxSelected
                        }
                      >
                        #{res.title}
                      </SelectButton>
                    );
                  })
                }
              </Fetcher>
            )}
          </UserConsumer>
        </div>
        <div className="channel-selector__no-topic">
          <SelectButton
            selected={noTopic}
            data={{ value: 'No topic', name: 'No topic' }}
            addRemoveSelected={this.addRemoveSelected}
          >
            No thanks, no Topic
          </SelectButton>
        </div>
      </>
    );
  }
}

ChannelSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultSelected: PropTypes.arrayOf(PropTypes.string),
};

export default ChannelSelector;
