import './Column.scss';

import classNames from 'classnames';
import Title from 'components/Title';
import { ReactNode } from 'react';

interface ColumnProps {
  children?: ReactNode;
  className?: string;
  title?: string;
  align?: string;
  span?: number;
  count?: number;
  mobileFullWidth?: boolean;
  width?: string;
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
}

export const Column = ({
  children,
  className,
  title,
  align,
  span = 1,
  count,
  mobileFullWidth,
  width,
  noPaddingLeft,
  noPaddingRight,
}: ColumnProps) => {
  const columnClassName = classNames('column', {
    [`column--${align}`]: align,
    [`column--span-${span}`]: span && span > 1,
    [`column--count-${count}`]: count,
    [`column--mobile-full-width`]: mobileFullWidth,
    [`column--no-padding-left`]: noPaddingLeft,
    [`column--no-padding-right`]: noPaddingRight,
  });

  const columnInnerClassName = classNames(className, 'column-inner', {
    [`column-inner--${width}`]: width,
  });

  return (
    <div className={columnClassName}>
      {title && <Title>{title}</Title>}
      <div className={columnInnerClassName}>{children}</div>
    </div>
  );
};
