import './HeaderBox.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import { Column, Container } from 'components/Grid';
import PropTypes from 'prop-types';
import { Component } from 'react';

import CodeOfConductBackground from './codeofconduct-portal-background.jpg';
import DefaultBackground from './innovation-portal-background.jpg';
import InnovationBackground from './innovation-portal-background.jpg';
import StrategyBackground from './strategy-portal-background.jpg';

const PortalBackgrounds = {
  innovation: InnovationBackground,
  strategy: StrategyBackground,
  codeofconduct: CodeOfConductBackground,
};

class HeaderBox extends Component {
  state = { stretchBg: false };

  backgroundLoaded = () => {
    this.setState({
      stretchBg:
        this.imageElement.offsetHeight > this.headerboxElement.offsetHeight,
    });
  };

  getGradientClassName = (portalType) => {
    return portalType === 'innovation'
      ? 'header-box__background-pink-gradient'
      : portalType === 'strategy'
      ? 'header-box__background-green-gradient'
      : 'header-box__background-red-gradient';
  };

  render() {
    const { page, children, preText, searching } = this.props;
    const bgInnerClasses = classNames('header-box__background-inner', {
      'header-box__background-inner--full-height': this.state.stretchBg,
    });
    const containerClassNames = classNames('header-box', {
      'header-box--searching': searching,
    });
    const backgroundImage =
      PortalBackgrounds[page.get('portalType')] || DefaultBackground;
    return (
      <div
        className={containerClassNames}
        ref={(elem) => (this.headerboxElement = elem)}
      >
        <div className="header-box__background-wrapper">
          <div className={bgInnerClasses}>
            <div
              className={this.getGradientClassName(page.attributes.portalType)}
            />
            <img
              src={backgroundImage}
              alt=""
              ref={(elem) => (this.imageElement = elem)}
              onLoad={this.backgroundLoaded}
            />
          </div>
        </div>

        {!searching ? (
          <div className="header-box__content">
            {preText && <p className="header-box__pre-text">{preText}</p>}{' '}
            <h1 className="header-box__title">{page.get('title')}</h1>
            <p className="header-box__preamble">{page.get('preamble')}</p>
          </div>
        ) : null}

        <Container className="header-box__container">
          <Column>{children}</Column>
        </Container>
      </div>
    );
  }
}

HeaderBox.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
  children: PropTypes.node,
  preText: PropTypes.string,
};

export default HeaderBox;
