import './Flex.scss';

import classNames from 'classnames';
import { ReactNode } from 'react';

interface FlexProps {
  tag?: 'div' | 'a' | 'span' | 'ul';
  children: ReactNode;
  type?: 'space-between' | 'space-around' | 'flex-end' | 'center';
  wrap?: 'wrap' | 'nowrap';
  className?: string;
  responsive?: any;
  direction?: 'row' | 'column';
  align?: 'start' | 'end' | 'center';
}

const Flex = ({
  tag = 'div',
  children,
  type,
  wrap = 'nowrap',
  className,
  responsive,
  direction,
  align = 'center',
  ...props
}: FlexProps) => {
  const Tag = tag;
  const flexClassName = classNames(className, 'flex', {
    [`flex__${type}`]: type,
    [`flex-wrap--${wrap}`]: wrap,
    [`flex__direction-${direction}`]: direction,
    [`flex__align-${align}`]: align,
    [`flex--responsive`]: responsive,
  });
  return (
    <Tag className={flexClassName} {...props}>
      {children}
    </Tag>
  );
};

export default Flex;
