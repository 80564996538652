import classNames from 'classnames';
import { ReactComponent as CancelIcon } from 'components/Icon/CancelSmall.svg';
import { ReactComponent as CommentsIcon } from 'components/Icon/Comments.svg';
import { ReactComponent as HappySmiley } from 'components/Icon/HappySmiley.svg';
import { ReactComponent as Info } from 'components/Icon/Info.svg';
import { ReactComponent as Polygon } from 'components/Icon/Polygon.svg';
import { ReactComponent as SadSmileyIcon } from 'components/Icon/SadSmiley.svg';
import Parser from 'components/Parser';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationDTO, NotificationType } from 'types/Portal';
import { formatCommentBody } from 'utils/misc/formatCommentBody';
import { getFormattedDate } from 'utils/misc/time';

const getNotificationTitle = (type: NotificationType) => {
  switch (type) {
    case NotificationType.Comment:
      return 'New comment';
    case NotificationType.Reply:
      return 'New Reply';
    case NotificationType.PositiveFeedback:
      return 'Feedback';
    case NotificationType.NegativeFeedback:
      return 'Feedback';
    case NotificationType.Warning:
      return 'Page expiry';
    case NotificationType.Information:
      return 'Content reminder';
    default:
      return 'Notification';
  }
};

const notificationTypeIcons = {
  [NotificationType.Comment]: <CommentsIcon />,
  [NotificationType.PositiveFeedback]: <HappySmiley />,
  [NotificationType.NegativeFeedback]: <SadSmileyIcon />,
  [NotificationType.Warning]: <Polygon />,
  [NotificationType.Information]: <Info />,
  [NotificationType.Reply]: <CommentsIcon />,
};

interface NotificationItemProps {
  notification: NotificationDTO;
  markAsRead: (notificationId: string) => void;
  deleteNotification: (notificationId: string) => void;
}
const NotificationItem: FC<NotificationItemProps> = ({
  notification,
  markAsRead,
  deleteNotification,
}) => {
  const [displayDelete, setDisplayDelete] = useState<boolean>(false);

  const className = classNames('notification-item', {
    'notification-item__not-yet-read': !notification.read,
  });

  // Clean url, in case the linkUrl contains the full url
  // Required due to the use of <Link />
  const linkUrl = notification.linkUrl;
  const cleanLinkUrl =
    linkUrl && linkUrl.startsWith(window.location.origin)
      ? linkUrl.replace(window.location.origin, '')
      : linkUrl;

  return (
    <li
      className={className}
      onMouseEnter={() => setDisplayDelete(true)}
      onMouseLeave={() => setDisplayDelete(false)}
    >
      {displayDelete ? (
        <div
          className="notification-item__delete"
          onClick={() => deleteNotification(notification.id)}
        >
          <CancelIcon />
        </div>
      ) : null}
      <div className="notification-item__top">
        <div className="notification-item__comment">
          {notificationTypeIcons[notification.type]}
        </div>
        <div className="notification-item__title-container">
          <span className="notification-item__type">
            {getNotificationTitle(notification.type)}
          </span>
          <div className="notification-item__timestamp">
            {getFormattedDate(notification.timestamp)}
          </div>
        </div>
      </div>
      <Link
        to={cleanLinkUrl}
        className="notification-item__title--link"
        onClick={() => markAsRead(notification.id)}
      >
        <div className="notification-item__title">
          <Parser html={notification.title} type="Notification" />
        </div>
        <div className="notification-item__body">
          {notification.body && (
            <>
              <span>"</span>
              {formatCommentBody(notification.body, 300, true).map(
                (bodyItem, index) => (
                  <span key={index}>{bodyItem}</span>
                )
              )}
              <span>"</span>
            </>
          )}
        </div>
      </Link>
      <div className="notification-item__footer">
        <span
          className="mark-as-read"
          onClick={() => markAsRead(notification.id)}
        >
          {notification.read ? '' : 'Mark as read'}
        </span>
      </div>
    </li>
  );
};

export default NotificationItem;
