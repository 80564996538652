import './Topics.scss';

import { getChannels } from 'api/cms/topics';
import { UserConsumer } from 'components/Context/User';
import Fetcher from 'components/Fetcher';
import { Column, Container } from 'components/Grid';
import { ReactComponent as ArrowRight } from 'components/Icon/ArrowRight.svg';
import { Link } from 'react-router-dom';
import { GAonTopicCardClick } from 'utils/analytics';
import { getImageSrc } from 'utils/cms';
import makeQueryString from 'utils/misc/makeQueryString';
import useWindowDimensions from 'utils/misc/useWindowDimensions';

const TopicsContent = () => {
  const { width } = useWindowDimensions();

  const truncateText = (title) => {
    return width > 1199 && title.length > 16
      ? `${title.substring(0, 16)}...`
      : title;
  };

  const backgroundImage = (heroImage) =>
    heroImage
      ? {
          'background-image': `linear-gradient(135deg, rgba(0, 160, 208, 0.6), rgba(3, 75, 112, 0.6)), url(${getImageSrc(
            heroImage
          )})`,
        }
      : {};

  return (
    <UserConsumer>
      {({ divisionId }) => (
        <Fetcher source={getChannels} param={makeQueryString({ divisionId })}>
          {({ response }) => (
            <Container>
              <Column>
                <div className="topics-container">
                  {response.map(({ title, heroImage, preamble }, i) => {
                    return (
                      <Link
                        key={`topics-content-${i}`}
                        to={`/topics/${title}`}
                        className="topic-item"
                        style={backgroundImage(heroImage)}
                        onClick={() => {
                          GAonTopicCardClick(`#${title}`);
                        }}
                      >
                        <div className="topic-item-title">
                          #{truncateText(title)}
                        </div>
                        <div className="topic-item-overlay">
                          <div className="topic-item-preamble">
                            {preamble
                              ? preamble.length >= 100
                                ? `${preamble.substring(0, 100)}...`
                                : preamble
                              : ''}
                          </div>
                          <div className="topic-item-explore">
                            Explore this topic{' '}
                            <span
                              style={{
                                margin: '0 10px',
                                position: 'absolute',
                                top: '15%',
                              }}
                            >
                              <ArrowRight />
                            </span>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </Column>
            </Container>
          )}
        </Fetcher>
      )}
    </UserConsumer>
  );
};

export default TopicsContent;
