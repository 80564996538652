import { Redirect } from 'react-router-dom';
import settings from 'settings';

const { portals } = settings;

const PortalsRedirect = ({ match }) => {
  let { portalId, pageId } = match.params;

  const portal = portals.find(({ id }) => id === portalId);

  if (!portal) {
    return <Redirect to="/404" />;
  }

  if (!pageId) {
    pageId = portalId;
  }

  return <Redirect to={`/portals/${portal.url}/${pageId}`} />;
};

export default PortalsRedirect;
