import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Job } from 'api/jobs/types';
import Parser from 'components/Parser';
import { useMemo } from 'react';

import { JobContentActions } from './JobContentActions';
import { JobContentError } from './JobContentError';
import { JobContentHiringManager } from './JobContentHiringManager';
import { JobContentInfoBox } from './JobContentInfoBox';
import { JobContentLocations } from './JobContentLocations';
import { JobContentMetaLabels } from './JobContentMetaLabels';
import { JobContentSkeleton } from './JobContentSkeleton';

const elementId = 'job-content';

type JobContentProps = {
  sx?: SxProps<Theme>;
  isLoading?: boolean;
  isError?: boolean;
  job?: Job;
};

const JobContent = ({ sx, isLoading, isError, job }: JobContentProps) => {
  const elements = useMemo(() => {
    if (isLoading) {
      return <JobContentSkeleton />;
    }
    if (isError) {
      return <JobContentError />;
    }
    if (!job) {
      return null;
    }
    return (
      <>
        <Typography
          component="h2"
          sx={(theme) => ({
            fontSize: {
              xs: theme.typography.h1.fontSize,
              md: theme.typography.display4.fontSize,
            },
            fontWeight: theme.typography.fontWeightRegular,
          })}
        >
          {job.title}
        </Typography>
        <JobContentMetaLabels
          elementId={`${elementId}-meta-labels`}
          job={job}
        />
        <Divider />
        <JobContentHiringManager
          elementId={`${elementId}-hiring-manager`}
          hiringManager={job.hiringManager}
        />
        <JobContentLocations
          elementId={`${elementId}-locations`}
          key={job.id}
          locations={job.locations}
          isExpandable
        />
        <Divider />
        <JobContentActions job={job} elementId={`${elementId}-actions-top`} />
        <Divider />
        <Parser html={job.description} type="Jobs" />
        <Divider />
        <JobContentActions
          job={job}
          elementId={`${elementId}-actions-bottom`}
        />
        <Divider />
        <JobContentInfoBox elementId={`${elementId}-info-box`} />
      </>
    );
  }, [isError, isLoading, job]);

  return (
    <Stack
      id={elementId}
      sx={[
        (theme) => ({
          width: '100%',
          gap: theme.spacing('xxs'),
          padding: { xs: 0, md: theme.spacing('md') },
          wordBreak: 'break-word',
          '> hr': { margin: `${theme.spacing('xxxxs')} 0` },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {elements}
    </Stack>
  );
};

export { JobContent };
