import './SortIndex.scss';

import Editable from 'components/Editable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

class SortIndex extends PureComponent {
  state = {
    sortIndex: this.props.sortIndex,
  };

  updateSortIndex = (e) => {
    const {
      value,
      validity: { valid },
    } = e.target;
    if (valid) {
      if (value <= this.props.max && value >= this.props.min) {
        this.setState({ sortIndex: Number(value) });
      } else if (value === '') {
        this.setState({ sortIndex: '' });
      }
    }
  };

  onSortIndexBlur = () => {
    const { onBlur } = this.props;

    onBlur(this.state.sortIndex);
  };

  render() {
    return (
      <div className="sort-order">
        <Editable>
          <p>Sort index: </p>
          <input
            type="text"
            pattern="\d*"
            onBlur={this.onSortIndexBlur}
            onChange={this.updateSortIndex}
            value={this.state.sortIndex}
          />
        </Editable>

        <span>{this.props.description}</span>
      </div>
    );
  }
}

SortIndex.propTypes = {
  sortIndex: PropTypes.number,
  min: PropTypes.number,
  onBlur: PropTypes.func.isRequired,
  max: PropTypes.number,
  description: PropTypes.string,
};

SortIndex.defaultProps = {
  sortIndex: 50,
  min: 0,
  max: 100,
  description:
    'The articles will be sorted in the page tree according to the value in the sort index field. From lowest to highest.',
};

export default SortIndex;
