export const AtomicSeparatorComponent = () => <hr />;

export const AtomicBlock = (props) => {
  const { blockProps, block } = props;
  const content = blockProps.getEditorState().getCurrentContent();

  const entity = content.getEntity(block.getEntityAt(0));
  const data = entity.getData();
  const type = entity.getType();

  if (blockProps.components[type]) {
    const AtComponent = blockProps.components[type];
    return (
      <AtComponent
        data={data}
        onSubmit={blockProps.onEditAccordion}
        disableEditor={blockProps.disableEditor}
        enableEditor={blockProps.enableEditor}
      />
    );
  }
};
