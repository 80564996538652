import './ContentLabels.scss';

import classNames from 'classnames';
import Label from 'components/Label';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getChannelUrl } from 'utils/api/urls';
import { getDateOrDaysAgo } from 'utils/misc/time';

const onLabelClick = (label) => {
  const itemCategoryByLinkType = {
    news: 'type',
    blog: 'type',
    press: 'type',
    story: 'type',
    global: 'localization',
    division: 'localization',
    segment: 'localization',
    country: 'localization',
    department: 'localization',
    site: 'localization',
    company: 'localization',
  };
  return `/feed?${itemCategoryByLinkType[label.linkType]}=${label.linkType}`;
};

const LabelTemplate = ({ label }) => (
  <Label
    color={label.color}
    display="inline"
    className={`content-labels__label m-r-2 ${
      label.linkType ? 'hover-underline' : null
    }`}
    ghost={label.ghost}
    transparent={label.transparent}
    boldness={label.transparent ? 'light' : 'bold'}
    shape={label.shape}
    topMargin={label.topMargin}
  >
    {label.name}
  </Label>
);

const ContentLabels = ({
  fileType,
  labels = [],
  publishedUtc,
  channels,
  className,
}) => {
  const contentMetaClassName = classNames('content-labels', {
    [className]: className,
  });

  return (
    <div className={contentMetaClassName}>
      <span className="sr-only">Category:</span>
      {labels.map((label) =>
        label.linkType ? (
          <Link key={label.name} to={onLabelClick(label)}>
            <LabelTemplate label={label} />
          </Link>
        ) : (
          <LabelTemplate key={label.name} label={label} />
        )
      )}

      {fileType && (
        <span className="content-labels__filetype text-label">{fileType}</span>
      )}

      {publishedUtc && (
        <span className="content-labels__time m-r-3">
          <span className="sr-only">Published: </span>
          {getDateOrDaysAgo(publishedUtc)}
        </span>
      )}

      {channels &&
        channels.map((c) => (
          <Link
            key={c}
            to={getChannelUrl({ title: c })}
            className="content-labels__channel m-r-3"
          >
            {`#${c}`}
          </Link>
        ))}
    </div>
  );
};

ContentLabels.propTypes = {
  labels: PropTypes.array.isRequired,
  publishedUtc: PropTypes.string,
  channels: PropTypes.array,
  className: PropTypes.string,
  fileType: PropTypes.string,
};

export default ContentLabels;
