import './BodyBox.scss';

import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import Box from 'components/Box';
import LikeButton from 'components/Button/CommentAndLike/LikeButton';
import ShareLink from 'components/Button/ShareLink';
import Comments from 'components/Comments';
import Documents from 'components/Documents';
import EnhancedLink from 'components/EnhancedLink';
import Img from 'components/Image';
import Parser from 'components/Parser';
import ArticleTags from 'components/Portal/InsightsListing/ArticleTags';
import SidebarBox from 'components/Portal/InsightsListing/SidebarBox';
import Title from 'components/Title';
import UserImage from 'components/UserImage';
import PropTypes from 'prop-types';
import { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { getDateOrDaysAgo } from 'utils/misc/time';

class InsightsListingBodyBox extends Component {
  state = { docCount: null, rerenderDocs: true };

  componentDidUpdate(prevProps) {
    const { page } = this.props;

    if (prevProps.page !== page) {
      this.setState({ rerenderDocs: false }, () => {
        this.setState({ rerenderDocs: true });
      });
    }
  }

  countChangeHandler = (docs) => {
    this.setState({ docCount: docs.count });
  };

  render() {
    const { page, color, hideDocuments, className } = this.props;

    const { docCount, rerenderDocs } = this.state;
    const {
      informationOwner,
      publishedDate,
      title,
      heroImage,
      heroAltText,
      source,
      keywords,
      geographicMarkets,
      languages,
      productGroups,
      targetGroups,
      topics,
      verticalMarkets,
      disableComments,
    } = page.attributes;

    const { id } = page;
    const preamble = page.get('preamble');
    const sharepointFolderName = page.get('sharepointFolderName');
    const parentId = page.get('parentId');

    const bodyBoxClassName = classNames('insights-listing-body-box', {
      [className]: className,
      [`insights-listing-body-box--${color}`]: typeof color !== 'undefined',
    });
    const documentWrapperClassName = classNames(
      'insights-listing-body-box__documents-wrapper',
      {
        loaded: docCount > 0,
      }
    );

    return (
      <div className={bodyBoxClassName}>
        <div className="content content--with-sidebar">
          <SidebarBox
            productGroups={productGroups}
            targetGroups={targetGroups}
            verticalMarkets={verticalMarkets}
            geographicMarkets={geographicMarkets}
            topics={topics}
            languages={languages}
            parentId={parentId}
          />
        </div>
        <div className="insights-listing-body-box__content content">
          <div className="insights-listing-body-box__top-part-wrapper">
            {informationOwner && (
              <div className="insights-listing-body-box__article-user-wrapper">
                <UserImage name={informationOwner.name} size="small" />
                <div className="insights-listing-body-box__article-user-text">
                  <span className="insights-listing-body-box__article-user-name">
                    <Link
                      className="insights-listing-body-box__article-user-link"
                      to={`/people/${informationOwner.oid}`}
                    >
                      {informationOwner.name}
                    </Link>
                  </span>
                  <span> - Information Owner</span>
                  <br />
                  <span className="insights-listing-body-box__article-date">
                    {getDateOrDaysAgo(publishedDate, false)}
                  </span>
                </div>
              </div>
            )}
            <div className="insights-listing-body-box__top-part-like-and-share-wrapper">
              <ShareLink
                articleTitle={title}
                contentType="insightsArticle"
                modalPlacement="bottom"
              />
              <LikeButton articleTitle={title} targetEntityId={id} />
            </div>
          </div>
          {heroImage && (
            <Img
              src={heroImage}
              alt={heroAltText}
              maxWidth={100}
              className="insights-listing-body-box__image"
            />
          )}
          {preamble && (
            <p className="insights-listing-body-box__preamble">{preamble}</p>
          )}
          <div className="insights-listing-body-box__main-text">
            <Parser html={page.get('body')} type="Portal" />
          </div>
          {!hideDocuments && sharepointFolderName && (
            <div className={documentWrapperClassName}>
              <Title size="small" border="portal-related">
                Documents
              </Title>
              {rerenderDocs && (
                <Box
                  className="m-b-6"
                  color="transparent"
                  noShadow={true}
                  padding={false}
                >
                  <Suspense fallback={<div />}>
                    <Documents
                      folderName={sharepointFolderName}
                      edit={false}
                      onChange={this.countChangeHandler}
                    />
                  </Suspense>
                </Box>
              )}
            </div>
          )}
          {source && (
            <div className="insights-listing-body-box__source-link-box">
              <span>Source: </span>
              <EnhancedLink to={source.url}>{source.name}</EnhancedLink>
            </div>
          )}
          <ArticleTags
            productGroups={productGroups}
            targetGroups={targetGroups}
            verticalMarkets={verticalMarkets}
            geographicMarkets={geographicMarkets}
            topics={topics}
            languages={languages}
            parentId={parentId}
          />
          {keywords && keywords.length > 0 && (
            <div className="insights-listing-body-box__keywords-box">
              <hr />
              <span className="insights-listing-body-box__keywords-title">
                Keywords
              </span>
              <div className="insights-listing-body-box__keywords-list">
                {keywords.map((obj, i) => (
                  <Link
                    className="insights-listing-body-box__keywords-link"
                    to={`${parentId}?search=${obj.title}`}
                    key={i}
                  >
                    {obj.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {!disableComments && (
            <div className="insights-listing-body-box__comments">
              <Comments
                articleId={page.id}
                articleTitle={title}
                author={informationOwner}
                itemSubType="innovation-portal"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

InsightsListingBodyBox.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
  color: PropTypes.oneOf(['white', 'blue']),
  hideDocuments: PropTypes.bool,
  className: PropTypes.string,
};

InsightsListingBodyBox.defaultProps = {
  color: 'white',
};

export default InsightsListingBodyBox;
