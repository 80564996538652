export type SubscriptionsForm = {
  subscribesToInsightsDatabase: boolean;
};

export type SubscriptionsFormField = keyof SubscriptionsForm;

const getInitialFormInput = (user?: any) => {
  const formInput: SubscriptionsForm = {
    subscribesToInsightsDatabase: false,
  };
  if (!user) {
    return formInput;
  }
  formInput.subscribesToInsightsDatabase =
    user.subscribesToInsightsDatabase || false;
  return formInput;
};

export { getInitialFormInput };
