import './FileActionConfirmModal.scss';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const FileActionConfirmModal = ({ data, onConfirm, onCancel }) => {
  const [open, setOpen] = useState(!!data);
  useEffect(() => {
    !!data !== open && setOpen(!!data);
  }, [open, data]);
  const onClickConfirm = (e) => {
    e.stopPropagation();
    onConfirm();
  };
  const onClickCancel = (e) => {
    e.stopPropagation();
    setOpen(false);
    onCancel();
  };
  const onClickOutside = onClickCancel;
  return (
    <>
      {open ? (
        <div
          className="file-action-confirm-modal__click-outside-handler"
          onClick={onClickOutside}
        />
      ) : null}
      {open ? (
        <div
          className="file-action-confirm-modal__wrapper"
          onClick={onClickOutside}
        >
          <div
            className="file-action-confirm-modal__container"
            onClick={(e) => e.stopPropagation()}
          >
            <p>{data}</p>
            <div className="file-action-confirm-modal__buttons-container">
              <button
                className="file-action-confirm-modal__button file-action-confirm-modal__button--light"
                onClick={onClickCancel}
              >
                Cancel
              </button>
              <button
                className="file-action-confirm-modal__button"
                onClick={onClickConfirm}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

FileActionConfirmModal.propTypes = {
  data: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FileActionConfirmModal;
