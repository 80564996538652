import './EmbedExpandableImageButton.scss';

import { createAttachment } from 'api/cms/attachment';
import ImageModal from 'components/Editable/Files/ImageModal';
import { ReactComponent as ClickableImageOptionIcon } from 'components/Icon/ClickableImageOption.svg';

const handleCroppedData = async ({ blob, width, height }) => {
  const data = new FormData();
  data.append('file', blob);
  data.append('width', width);
  data.append('height', height);

  const response = await createAttachment(data);

  return {
    url: response.data.data.url,
    width,
    height,
  };
};

const EmbedExpandableImageButton = ({ onAddImage, title }) => {
  return (
    <ImageModal
      onSubmit={onAddImage}
      minWidth={200}
      minHeight={200}
      handleCroppedData={handleCroppedData}
      useAltText={true}
    >
      <div className="embed-expandable-image-button__container">
        <button className="md-sb-button" type="button">
          <ClickableImageOptionIcon className="embed-expandable-image-button__icon" />
        </button>
        <p className="embed-expandable-image-button__label">{title}</p>
      </div>
    </ImageModal>
  );
};

export default EmbedExpandableImageButton;
