import './ContentRows.scss';

import { ReactComponent as PinIcon } from 'components/Icon/Pin.svg';
import PropTypes from 'prop-types';

import { Desktop } from '../../../components/Responsive';

const ContentRows = ({
  name,
  category,
  senioritylevel,
  locations,
  department,
  hiringManager,
}) => {
  return (
    <div className="content-rows">
      <h3 className="content-rows__title">{name}</h3>
      <div className="content-rows__info">
        {category && (
          <span className="content-rows__category">{category.name}</span>
        )}
        {senioritylevel && (
          <Desktop
            tag="span"
            className="content-rows__seniority desktop-only--inline"
          >
            {senioritylevel}
          </Desktop>
        )}
        {department && (
          <span className="content-rows__department">{department.name}</span>
        )}
      </div>
      {hiringManager ? (
        <div className="content-rows__hiring-manager">{`Hiring Manager: ${hiringManager?.firstName} ${hiringManager?.lastName}`}</div>
      ) : (
        <></>
      )}
      <span className="content-rows__location-wrapper">
        {locations.map(({ name }) => {
          if (name === 'USA') {
            return null;
          }

          return (
            <span key={name} className="content-rows__location">
              <PinIcon className="content-rows__icon" />
              <span>{name}</span>
            </span>
          );
        })}
      </span>
    </div>
  );
};

ContentRows.propTypes = {
  name: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
};

export default ContentRows;
