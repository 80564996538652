import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { PlaySymbol } from 'componentsNew';
import { ArticleContentType, PortalArticleContentType } from 'enums';
import { Link as ReactRouterLink } from 'react-router-dom';
import type { ImageSize } from 'utils/cms/articleImage';
import * as articleImageUtils from 'utils/cms/articleImage';

import articleFallbackImageBlog from './article-fallback-image-blog.jpg';
import articleFallbackImageNews from './article-fallback-image-news.jpg';

type ArticleImageProps = {
  to?: string;
  href?: string;
  src?: string;
  type: ArticleContentType | PortalArticleContentType;
  sx?: SxProps<Theme>;
  fetchSize?: ImageSize;
  isPortrait?: boolean;
  videoPlaySymbolSize?: number;
  altText?: string;
  onClick?: () => void;
};

const ArticleImage = ({
  to,
  href,
  src,
  type,
  sx,
  fetchSize,
  isPortrait = false,
  videoPlaySymbolSize,
  altText,
  onClick,
}: ArticleImageProps) => {
  const fallbackSrc =
    type === ArticleContentType.Blog
      ? articleFallbackImageBlog
      : articleFallbackImageNews;

  const isVideoSrc = src ? articleImageUtils.isVideoImageUrl(src) : false;

  const imageSrcOptions = fetchSize
    ? { isPortrait, imageSize: fetchSize }
    : undefined;

  const imageSrc = articleImageUtils.getFormattedArticleImageSrc(
    src || fallbackSrc,
    imageSrcOptions
  );

  return (
    <Link
      {...(to ? { to: to, component: ReactRouterLink } : { href: href })}
      onClick={onClick}
      sx={[
        (theme) => ({
          backgroundColor: theme.colors.surface.informative,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 0,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        component="img"
        sx={(theme) => ({
          display: 'block',
          objectFit: 'cover',
          ...(isPortrait
            ? {
                height: '85%',
                boxShadow: theme.shadows[5],
              }
            : {
                height: '100%',
                width: '100%',
              }),
        })}
        alt={altText}
        title={altText}
        src={imageSrc}
      />
      {isVideoSrc && (
        <PlaySymbol size={videoPlaySymbolSize} sx={{ position: 'absolute' }} />
      )}
    </Link>
  );
};

export { ArticleImage };
