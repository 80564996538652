import './RelatedContentViewer.scss';

import classNames from 'classnames';
import { ReactComponent as ChevronRight } from 'components/Icon/ChevronRight.svg';
import Title from 'components/Title';
import WhitelistUrlModal from 'components/WhitelistUrlModal';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import settings from 'settings';

import Item0 from './Images/RC-Item1.png';
import Item1 from './Images/RC-Item2.png';
const { redirectUri } = settings.auth;

class RelatedContentViewer extends Component {
  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUnMount() {
    this._isMounted = false;
  }

  onClick = (url) => {
    return <Link to={url} />;
  };

  getImageByIndex = (index) => {
    const imgArray = [Item0, Item1];
    return imgArray[index];
  };

  render() {
    const { contents, stackVertically } = this.props;
    const className = classNames('related-content-viewer', {
      'related-content-viewer--vertical': stackVertically,
    });

    return (
      <>
        <Title
          {...(stackVertically
            ? { color: 'white', spacing: 'no-top-margin' }
            : {})}
          size="small"
        >
          Related Content
        </Title>
        <div className={className}>
          {contents.map((content, index) => {
            return (
              <WhitelistUrlModal
                key={index}
                linkUrl={content.url}
                className="related-content-viewer__item"
                openInNewWindow={
                  content.url.includes(redirectUri) ? 'false' : 'true'
                }
              >
                <div className="related-content-viewer__image-wrapper">
                  <img
                    src={this.getImageByIndex(index)}
                    alt="Related Content Item"
                    className="related-content-viewer__image"
                  />
                </div>
                <div className="related-content-viewer__text-wrapper">
                  <div className="related-content-viewer__header">
                    <div className="related-content-viewer__title">
                      {content.title}
                    </div>
                    <ChevronRight className="chevron-right" />
                  </div>
                  <div className="related-content-viewer__description">
                    {content.description}
                  </div>
                </div>
              </WhitelistUrlModal>
            );
          })}
        </div>
      </>
    );
  }
}

RelatedContentViewer.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  ),
  stackVertically: PropTypes.bool,
};

export default RelatedContentViewer;
