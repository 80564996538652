import Stack from '@mui/material/Stack';
import { getChannel } from 'api/cms/topics';
import { getNewsFlashes } from 'api/news';
import classNames from 'classnames';
import Box from 'components/Box';
import { UserConsumer } from 'components/Context/User';
import Documents from 'components/Documents';
import Error from 'components/Error';
import Feed from 'components/Feed/List';
import Fetcher from 'components/Fetcher';
import { Column, Container } from 'components/Grid';
import Iframe from 'components/Iframe';
import Img from 'components/Image';
import ImagePlaceholder from 'components/ImagePlaceholder';
import PageTitle from 'components/PageTitle';
import Parser from 'components/Parser';
import Title from 'components/Title';
import { VideoList } from 'componentsNew';
import { Component, Suspense } from 'react';
import { translations } from 'translations';
import { GAitemNotFound } from 'utils/analytics';
import { createEmbedUrl } from 'utils/cms/parseVideoUrls';

import ContentLoader from '../ContentLoader';

const WrapperBox = ({ children }) => (
  <Box className="m-t-6 p-t-3 p-b-3">{children}</Box>
);

const onError = (status, message) => {
  GAitemNotFound(status, message, 'channel');

  return <Error message={message} status={status} redirectOn404={true} />;
};

class ViewChannels extends Component {
  state = { docCount: null };

  countChangeHandler = (docs) => {
    this.setState({ docCount: docs.count });
  };

  render() {
    const { match } = this.props;
    const { docCount } = this.state;
    const documentWrapperClassName = classNames('body-box__documents-wrapper', {
      loaded: docCount > 0,
    });
    const centerAlign = { margin: 'auto' };
    return (
      <Fetcher
        source={getChannel}
        param={match.params.id}
        onLoading={ContentLoader}
        onError={onError}
      >
        {({ response }) => (
          <>
            <PageTitle titles={['Topics', `#${response.title}`]} />
            <Container className="m-t-6" mobileColumnSpacing={true}>
              <Stack
                sx={() => ({
                  width: '100%',
                  flexDirection: { sx: 'column', lg: 'row' },
                  gap: { xs: '2rem', lg: '0' },
                })}
              >
                <Column
                  span={
                    response.sharepointFolderName || response.videoPlaylistId
                      ? 2
                      : 1
                  }
                >
                  <Box>
                    <div
                      className="content"
                      style={
                        response.sharepointFolderName ||
                        response.videoPlaylistId
                          ? null
                          : centerAlign
                      }
                    >
                      <Title>#{response.title}</Title>
                      {response.heroImage ? (
                        <ImagePlaceholder className="m-b-4">
                          <Img
                            src={response.heroImage}
                            alt={response.heroAltText}
                            maxWidth={Number(response.heroImageWidth)}
                            type="hero"
                          />
                        </ImagePlaceholder>
                      ) : response.heroVideo ? (
                        <div className="m-b-4">
                          <Iframe
                            src={createEmbedUrl(response.heroVideo)}
                            title="blog"
                          />
                        </div>
                      ) : null}
                      <p className="preamble">{response.preamble}</p>
                      <div className="body">
                        <Parser
                          html={response.body}
                          type="Feed"
                          country={response.country}
                          division={response.division}
                        />
                      </div>
                    </div>
                  </Box>
                </Column>
                {(response.sharepointFolderName ||
                  response.videoPlaylistId) && (
                  <Column>
                    {response.sharepointFolderName && (
                      <div className={documentWrapperClassName}>
                        <Box>
                          <Title>Documents</Title>
                          <Suspense fallback={<div />}>
                            <Documents
                              folderName={response.sharepointFolderName}
                              edit={false}
                              onChange={this.countChangeHandler}
                              canPreview="true"
                              size="small"
                              topic={true}
                            />
                          </Suspense>
                        </Box>
                      </div>
                    )}
                    {response.videoPlaylistId &&
                      !response.sharepointFolderName && (
                        <VideoList
                          id="topics-videos"
                          title={translations.videos}
                          videoListId={response.videoPlaylistId.value}
                          sx={() => ({
                            width: { xs: '100%', lg: '380px' },
                            maxHeight: '44rem',
                            flexShrink: 0,
                          })}
                        />
                      )}
                  </Column>
                )}
              </Stack>
            </Container>
            <Container>
              <Column>
                <UserConsumer>
                  {({
                    divisionId,
                    segmentId,
                    countryId,
                    departmentId,
                    siteId,
                    companyId,
                    isLoading,
                  }) =>
                    !isLoading && (
                      <Feed
                        limit={5}
                        showLargeFirstImage={false}
                        useLoadMore={true}
                        news={true}
                        blog={true}
                        story={true}
                        global={true}
                        onlyGlobalItems={false}
                        onlyMatchingDivision={false}
                        onlyMatchingSegment={false}
                        onlyMatchingCountry={false}
                        onlyMatchingDepartment={false}
                        onlyMatchingSite={false}
                        onlyMatchingCompany={false}
                        divisionId={divisionId}
                        segmentId={segmentId}
                        countryId={divisionId ? countryId : undefined}
                        departmentId={departmentId}
                        siteId={siteId}
                        companyId={companyId}
                        channel={response.title}
                        source={getNewsFlashes}
                        showNoResult={false}
                        wrapper={WrapperBox}
                        hideLastDivider={true}
                      />
                    )
                  }
                </UserConsumer>
              </Column>
            </Container>
          </>
        )}
      </Fetcher>
    );
  }
}

export default ViewChannels;
