import {
  getAutoSuggestions,
  getRecentSearches,
  getSearchResults,
} from 'api/find';
import { getCompanies, getSites } from 'api/organization';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { loadData } from 'utils/api/handleAPIData';
import { formatCompanies } from 'utils/misc/formatCompanies';
import { formatSites } from 'utils/misc/formatSites';
import makeQueryString from 'utils/misc/makeQueryString';

const getRecentSearchesResponse = async () => {
  const apiCall = getRecentSearches;

  try {
    const { response } = await loadData({
      apiCall,
      param: undefined,
      useModelFactory: false,
    }).promise;

    return response;
  } catch (e) {
    return null;
  }
};

export const splitSuggestion = (text, query) => {
  const matches = match(text, query);
  const parts = parse(text, matches);

  return parts;
};

export const formatSearchQuery = (query) => query.replace(/\s\s+/g, ' ').trim();

export const getSuggestions = async (
  value,
  filter,
  useFullSearch,
  isRecentSearch = false
) => {
  const searchQuery = formatSearchQuery(value);

  if (searchQuery.length > 1) {
    const query = makeQueryString({
      filter: { query: searchQuery, ...filter },
    });

    const apiCall = useFullSearch ? getSearchResults : getAutoSuggestions;

    try {
      const { response } = await loadData({
        apiCall,
        param: query,
        useModelFactory: true,
      }).promise;
      if (value.length > 0) {
        return response;
      }
    } catch (e) {
      return null;
    }
  }

  if (isRecentSearch) {
    const response = await getRecentSearchesResponse();
    return response;
  }

  return null;
};

export const getSiteSuggestions = async (value) => {
  if (value.length === 0) {
    return [];
  }
  const searchQuery = formatSearchQuery(value);
  if (searchQuery.length < 2) {
    return null;
  }
  const query = makeQueryString({ filter: { siteName: searchQuery } });
  try {
    const { data } = await getSites(query);
    const formattedData = formatSites(data);
    if (formattedData.length > 0) {
      return formattedData;
    }
  } catch (e) {
    return null;
  }

  return null;
};

export const getCompanySuggestions = async (value) => {
  if (value.length === 0) {
    return [];
  }
  const searchQuery = formatSearchQuery(value);
  if (searchQuery.length < 2) {
    return null;
  }
  const query = makeQueryString({ filter: { companyName: searchQuery } });
  try {
    const { data } = await getCompanies(query);
    const formattedData = formatCompanies(data);
    if (formattedData.length > 0) {
      return formattedData;
    }
  } catch (e) {
    return null;
  }

  return null;
};
