import './GatewayList.scss';

import { Column, Container } from 'components/Grid';

import GatewayItem from './GatewayItem';

const GatewayList = ({ gateways }) => {
  return (
    <Container className="gateway-block">
      <Column>
        <div className="gateway-block__list-wrapper">
          <h2>Managing your innovation projects</h2>
          <ul className="gateway-block__list">
            {gateways.map((gateway) => (
              <GatewayItem key={gateway.id} gateway={gateway} />
            ))}
          </ul>
        </div>
      </Column>
    </Container>
  );
};

export default GatewayList;
