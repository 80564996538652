import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const getFooterLinks = () =>
  createRequest({
    method: 'get',
    url: `${cms}/footerlinks`,
    errorMessages: {
      '*': 'Failed to get footer links, please contact support for assistance.',
    },
  });
