import './ArticleMeta.scss';

import Flex from 'components/Flex';
import Label from 'components/Label';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getDateOrDaysAgo } from 'utils/misc/time';

const RenderLabel = ({ user }) => {
  // Check if userId key is present, then it's a guestauthor
  let id;
  if (user && 'userId' in user) {
    id = user.userId;
  } else {
    id = user.oid;
  }

  if (user && typeof user === 'object') {
    return (
      <Link to={`/people/${id}`}>
        <Label display="inline" className="m-b-2 hover-underline" ghost={true}>
          {user.name}
        </Label>
      </Link>
    );
  } else {
    return (
      <Label display="inline" className="m-b-2" ghost={true}>
        {user.name}
      </Label>
    );
  }
};

const ArticleMeta = ({
  editor,
  author,
  created,
  updated,
  customCreateDateText,
}) => {
  return (
    <Flex className="article-meta m-t-4" wrap="wrap">
      {author && (created || customCreateDateText) && (
        <div className="article-meta__label-wrapper m-r-6">
          {customCreateDateText ? (
            <span className="article-meta__date">{customCreateDateText}</span>
          ) : (
            <span className="article-meta__date">
              {`Created ${getDateOrDaysAgo(created)} by:`}
            </span>
          )}
          {author && <RenderLabel user={author} />}
        </div>
      )}
      {editor && updated && (
        <div className="article-meta__label-wrapper">
          <span className="article-meta__date">
            {`Edited ${getDateOrDaysAgo(updated)} by:`}
          </span>
          {editor && <RenderLabel user={editor} />}
        </div>
      )}
    </Flex>
  );
};

const authorShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    oid: PropTypes.string.isRequired,
  }),
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }),
]);

ArticleMeta.propTypes = {
  author: authorShape,
  editor: authorShape,
  published: PropTypes.string,
  updated: PropTypes.string,
};

export default ArticleMeta;
