import './AuthorAdd.scss';

import { ReactComponent as Plus } from 'components/Icon/Plus.svg';
import PropTypes from 'prop-types';

const AuthorAdd = ({ openModal, showError }) => (
  <div className={`author-add ${showError ? 'author-add--error' : ''}`}>
    <span
      role="button"
      tabIndex="0"
      onClick={openModal}
      onKeyUp={(e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          openModal(e);
        }
      }}
      className="author-add__button"
    >
      <Plus /> Select author
    </span>
  </div>
);

export default AuthorAdd;

AuthorAdd.propTypes = {
  openModal: PropTypes.func.isRequired,
};
