import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUser } from 'components/Context/User';
import HideInProd from 'components/HideInProd';
import { AlertBar } from 'componentsNew';
import { AvenueRoute } from 'constants/routes';
import { Page } from 'layoutNew';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import { GAonClickProfileTab } from 'utils/analytics';

import { ManageAlerts } from './ManageAlerts/ManageAlerts';
import { ManageBanners } from './ManageBanners/ManageBanners';
import { ManageFeed } from './ManageFeed/ManageFeed';
import { ManageInsightsDatabase } from './ManageInsightsDatabase/ManageInsightsDatabase';
import { ManageLibrary } from './ManageLibrary/ManageLibrary';
import { Preferences } from './Preferences/Preferences';
import { ProfileActions } from './ProfileActions';
import { ProfileCard } from './ProfileCard/ProfileCard';
import { ProfileInformation } from './ProfileInformation/ProfileInformation';
import { ProfileTabItem, ProfileTabs } from './ProfileTabs';
import { Subscriptions } from './Subscriptions/Subscriptions';

const elementId = 'profile';

const Profile = () => {
  const user = useUser();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const tabItems = useMemo(() => {
    const _tabItems: ProfileTabItem[] = [
      {
        id: `${elementId}-preferences-tab`,
        label: translations.profilePreferences,
        content: <Preferences />,
        path: AvenueRoute.ProfilePreferences,
        gaName: 'Preferences',
      },
      {
        id: `${elementId}-subscriptions-tab`,
        label: translations.profileSubscriptions,
        content: <Subscriptions />,
        path: AvenueRoute.ProfileSubscriptions,
        gaName: 'Subscriptions',
      },
      ...(isDesktop
        ? [
            {
              id: `${elementId}-manage-library-tab`,
              label: translations.profileManageLibrary,
              content: <ManageLibrary />,
              path: AvenueRoute.ProfileManageLibrary,
              gaName: 'Library Pages',
            },
          ]
        : []),
      ...(isDesktop &&
      user.roles &&
      (user.roles.includes('AvenueGlobalEditor') ||
        user.roles.includes('AvenueBlogEditor') ||
        user.roles.includes('AvenueSuperuser'))
        ? [
            {
              id: `${elementId}-manage-feed-tab`,
              label: translations.profileManageFeed,
              content: <ManageFeed />,
              path: AvenueRoute.ProfileManageFeed,
              gaName: 'Feed Content',
            },
          ]
        : []),
      ...(isDesktop && user.roles && user.roles.includes('AvenuePortalEditor')
        ? [
            {
              id: `${elementId}-manage-insights-database-tab`,
              label: translations.profileManageInsightsDatabase,
              content: <ManageInsightsDatabase />,
              path: AvenueRoute.ProfileManageInsightsDatabase,
              gaName: 'Insights Database',
            },
          ]
        : []),
      ...(isDesktop &&
      user.roles &&
      (user.roles.includes('AvenueAdmin') ||
        user.roles.includes('AvenueSuperuser'))
        ? [
            {
              id: `${elementId}-manage-banners`,
              label: translations.profileManageBanners,
              content: <ManageBanners />,
              path: AvenueRoute.ProfileManageBanners,
              gaName: 'Banners',
            },
          ]
        : []),
      ...(isDesktop &&
      user.roles &&
      (user.roles.includes('AvenueSuperuser') ||
        user.roles.includes('AvenueNotificationEditor'))
        ? [
            {
              id: `${elementId}-manage-alerts`,
              label: translations.profileManageAlerts,
              content: <ManageAlerts />,
              path: AvenueRoute.ProfileManageAlerts,
              gaName: 'Alerts',
            },
          ]
        : []),
    ];
    return _tabItems;
  }, [isDesktop, user.roles]);

  const activeTabIndex = useMemo(() => {
    const index = tabItems.findIndex(
      (tabItem) => tabItem.path === location.pathname
    );
    return index === -1 ? 0 : index;
  }, [location.pathname, tabItems]);

  if (user.isProfileError) {
    return (
      <Page title={[translations.profile, tabItems[activeTabIndex].label]}>
        <AlertBar
          open
          type="critical"
          text={translations.profileFetchError}
          sx={(theme) => ({ margin: `${theme.spacing('md')} auto` })}
        />
      </Page>
    );
  }

  return (
    <Page title={[translations.profile, tabItems[activeTabIndex].label]}>
      <Stack
        sx={(theme) => ({
          backgroundColor: theme.colors.surface.informative,
          borderTopLeftRadius: theme.border.radius.md,
          borderTopRightRadius: theme.border.radius.md,
        })}
      >
        <Stack
          sx={(theme) => ({
            gap: theme.spacing('md'),
            flexDirection: { xs: 'column', md: 'row' },
            padding: `${theme.spacing('md')} ${theme.spacing('lg')}`,
            alignItems: 'center',
            position: 'relative',
          })}
        >
          <ProfileCard />
          <ProfileInformation />
          <HideInProd>
            <ProfileActions />
          </HideInProd>
        </Stack>

        <ProfileTabs
          items={tabItems}
          value={activeTabIndex}
          onChange={(
            _e: React.SyntheticEvent<Element, Event>,
            tabIndex: number
          ) => {
            const tab = tabItems[tabIndex];
            GAonClickProfileTab(tab.gaName);
            history.replace(tab.path);
          }}
        />
      </Stack>
      {tabItems.map((item, index) => (
        <Box
          key={item.id}
          role="tabpanel"
          id={`${item.id}-content`}
          aria-labelledby={item.id}
          hidden={index !== activeTabIndex}
          minHeight="50vh"
        >
          {index === activeTabIndex && item.content}
        </Box>
      ))}
    </Page>
  );
};

export { Profile };
