interface ReturnData {
  name: string;
  linkType: string;
  shape: string;
  color: string;
}

export const getMyDivisionLabels = (
  publishedInDivisions: { id: string; name: string }[],
  divisionId: string
): ReturnData[] => {
  const findDivisionName = publishedInDivisions.find(
    (division) => division.id === divisionId
  )?.name;

  if (findDivisionName) {
    return [
      {
        name: findDivisionName,
        linkType: 'division',
        shape: 'square',
        color: 'white',
      },
    ];
  } else {
    return publishedInDivisions.map((division) => {
      return {
        name: division.name,
        linkType: 'division',
        shape: 'square',
        color: 'white',
      };
    });
  }
};

export const getMyDivisionName = (
  publishedInDivisions: { id: string; name: string }[],
  divisionId: string
): string => {
  const findDivisionName = publishedInDivisions.find(
    (division) => division.id === divisionId
  )?.name;

  return findDivisionName || publishedInDivisions[0]?.name;
};
