import { PromptResult, PromptResultResponseData } from './types';

export const transformPromptResult = (data: PromptResultResponseData) => {
  if (!data.text) {
    return null;
  }
  const validSources = (data.sources || [])
    .map((source) =>
      source.title && source.linkUrl
        ? { title: source.title, linkUrl: source.linkUrl }
        : null
    )
    .filter(Boolean) as { title: string; linkUrl: string }[];

  const result: PromptResult = {
    text: data.text,
    sources: validSources,
  };
  return result;
};
