import './LibraryMenu.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const LibraryMenu = ({ children, className }) => {
  const menuClassnames = classNames('library-menu', {
    [className]: className,
  });
  return (
    <ul className={menuClassnames}>
      {children.map((child) => (
        <li className="library-menu__item" key={child.key}>
          {child}
        </li>
      ))}
    </ul>
  );
};

LibraryMenu.propTypes = {
  children: PropTypes.array.isRequired,
};

export default LibraryMenu;
