import {
  createAttachment,
  createAttachments,
  deleteAttachment,
} from 'api/cms/attachment';

import extractImageId from './extractImageId';

export const deleteRemovedAttachments = async (newBody, oldBody) => {
  const oldIds = extractImageId(oldBody);
  const newIds = extractImageId(newBody);

  const IdsToDelete = oldIds.filter((id) => !newIds.includes(id));
  if (IdsToDelete.length === 0) return;

  for (const id of IdsToDelete) {
    await deleteAttachment(id);
  }
  return true;
};

export const replaceImage = async (oldImage, newImageData) => {
  const { blob, width, height } = newImageData;

  if (blob) {
    const data = new FormData();
    data.append('file', blob);
    data.append('width', width);
    data.append('height', height);

    const response = await createAttachment(data);

    if (oldImage) {
      const oldHeroID = oldImage.replace('/static/node/', '');
      deleteAttachment(oldHeroID);
    }

    return {
      url: response.data.data.url,
      width,
      height,
    };
  }
};

const fileToBlob = async (file, fileType) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], {
    type: fileType,
  });

export const saveImageGalleryFiles = async (files, currentHighestSortIndex) => {
  const data = new FormData();
  const filesMetadata = {};

  for (let i = 0; i < files.length; i++) {
    const blob = await fileToBlob(files[i], files[i].type);
    const fileName = files[i].name;
    const sortIndex = currentHighestSortIndex + i + 1;

    const temporaryFileIdentifier = `${fileName}-${sortIndex}`;

    filesMetadata[temporaryFileIdentifier] = {
      sortIndex,
      fileName,
    };

    data.append('file', blob, temporaryFileIdentifier);
  }

  data.set('filesMetadata', JSON.stringify(filesMetadata));

  const response = await createAttachments(data);
  return response.data.data;
};

export const deleteImageGalleryImages = async (images) => {
  for (const image of images) {
    const trimmedPath = image.path.replace('/static/node/', '');
    await deleteAttachment(trimmedPath);
  }
};

export const deleteAllAttachments = async (body, heroImage) => {
  let imagesToDelete = extractImageId(body);

  if (heroImage) {
    const heroImageId = heroImage.replace('/static/node/', '');
    imagesToDelete.push(heroImageId);
  }

  for (const id of imagesToDelete) {
    await deleteAttachment(id);
  }
};
