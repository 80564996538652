import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const getSome = () =>
  createRequest({
    method: 'get',
    url: `${cms}/some`,
    errorMessages: {
      '*': 'Failed to get some post, please contact support for assistance.',
    },
  });

export const updateSome = (id: any, data: any) =>
  createRequest({
    method: 'put',
    url: `${cms}/some/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update some post, please contact support for assistance.',
    },
  });

export const createSome = (data: any) =>
  createRequest({
    method: 'post',
    url: `${cms}/some`,
    data,
    errorMessages: {
      '*': 'Failed to create some post, please contact support for assistance.',
    },
  });

export const deleteSome = (id: any) =>
  createRequest({
    method: 'delete',
    url: `${cms}/some/${id}`,
    errorMessages: {
      '*': 'Failed to delete some post, please contact support for assistance.',
    },
  });
