import './Label.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

const Label = ({
  children,
  color = 'blue',
  display = 'block',
  boldness = 'bold',
  className,
  size = 'small',
  shape = 'round',
  ghost,
  onClick,
  transparent,
  topMargin,
}) => {
  const labelClassName = classNames('text-label', {
    [`text-label--${color}`]: typeof color !== 'undefined',
    [`text-label--${display}`]: display,
    [`text-label--${boldness}`]: boldness,
    [`text-label--${size}`]: size,
    [`text-label--${shape}`]: shape,
    [`text-label--${topMargin}`]: topMargin,
    'text-label--ghost': ghost,
    'text-label--transparent': transparent,
    [className]: className,
  });

  return (
    <div className={labelClassName} onClick={onClick}>
      {children}
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'white',
    'black',
    'gray',
    'dusty-gray',
    'transparent-gray',
    'blue',
    'deep-blue',
    'orange',
    'transparent',
    'search-blue',
  ]),
  display: PropTypes.oneOf(['block', 'inline']),
  boldness: PropTypes.oneOf(['bold', 'light']),
  size: PropTypes.oneOf(['big', 'small']),
  shape: PropTypes.oneOf(['square', 'round']),
  ghost: PropTypes.bool,
};

export default memo(Label);
