import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Loader = ({ width = 1100 }) => (
  <ContentLoader
    className="profile--contentloader"
    width={width}
    height={323}
    preserveAspectRatio="none"
    style={{ height: '323px' }}
  >
    <circle r={32} cx={32} cy={32} />
    <rect x={85} y={20} width={getRandomNumberBetween(100, 200)} height={17} />

    <rect x={0} y={90} width={68} height={17} />
    <rect
      x={width > 648 ? 172 : 150}
      y={90}
      width={getRandomNumberBetween(100, 250)}
      height={17}
    />
    <rect x={0} y={131} width={104} height={17} />
    <rect x={width > 648 ? 172 : 150} y={131} width={70} height={17} />
    <rect x={0} y={173} width={71} height={17} />
    <rect
      x={width > 648 ? 172 : 150}
      y={173}
      width={getRandomNumberBetween(100, 200)}
      height={17}
    />
    <rect x={0} y={213} width={71} height={17} />
    <rect
      x={width > 648 ? 172 : 150}
      y={213}
      width={getRandomNumberBetween(100, 200)}
      height={17}
    />
    <rect x={0} y={255} width={67} height={17} />
    <rect
      x={width > 648 ? 172 : 150}
      y={255}
      width={getRandomNumberBetween(100, 200)}
      height={17}
    />
    <rect x={0} y={293} width={130} height={17} />
    <rect
      x={width > 648 ? 172 : 150}
      y={293}
      width={getRandomNumberBetween(100, 200)}
      height={17}
    />
  </ContentLoader>
);

export default Loader;
