import {
  NewsletterBannerCreate,
  NewsletterBannerGetResponseData,
} from 'api/cms/newsletterBanner/types';

import { FormValues } from './NewsletterBannerForm';

const transformFormValuesToCreateOrEditPayload = (data: FormValues) => {
  const transformed: NewsletterBannerCreate = {
    title: data.title,
    description: data.description,
    linkUrl: data.linkUrl || undefined,
    heroImage: data.heroImage.uploaded || undefined,
    heroAltText: data.heroImage.altText || undefined,
    publishedDate: (data.publishedDate || new Date()).toISOString(),
  };
  return transformed;
};

const transformGetResponseToFormValues = (
  fetchData: NewsletterBannerGetResponseData
) => {
  const transformed: FormValues = {
    title: fetchData.title,
    description: fetchData.description,
    linkUrl: fetchData.linkUrl || '',
    heroImage: {
      uploaded: fetchData.heroImage,
      altText: fetchData.heroAltText,
    },
    publishedDate: fetchData.publishedDate
      ? new Date(fetchData.publishedDate)
      : null,
  };
  return transformed;
};

export {
  transformFormValuesToCreateOrEditPayload,
  transformGetResponseToFormValues,
};
