import './UploadEntry.scss';

import { ReactComponent as Cancel } from 'components/Icon/Cancel.svg';
import filesize from 'file-size';
import PropTypes from 'prop-types';

import getIconAsset from '../../getIconAsset';
import ProgressBar from '../ProgressBar';

const renderIcon = ({ type, name }) => {
  let altText;
  let iconAsset;
  if (type === 'file') {
    altText = `Icon for file ${name}`;
    iconAsset = getIconAsset(name);
  } else if (type === 'folder') {
    altText = `Icon for folder ${name}`;
    iconAsset = getIconAsset(`${name}/`);
  }
  return <img className="upload-entry__icon" alt={altText} src={iconAsset} />;
};

const UploadEntry = ({
  item,
  item: { name, size },
  progressValue,
  onClick,
  onCancel,
}) => {
  const onClickUploadEntry = (e) => {
    e.stopPropagation();
    onClick(e, { type: 'entry', item });
  };
  return (
    <div className="upload-entry__container" onClick={onClickUploadEntry}>
      <div className="upload-entry__column">{renderIcon(item)}</div>
      <div className="upload-entry__column">
        <p className="upload-entry__filename">{name}</p>
      </div>
      <div className="upload-entry__column">
        <p className="upload-entry__size">
          {filesize(size, { fixed: 0 }).human('si')}
        </p>
      </div>
      {progressValue === null ? (
        <div className="upload-entry__column">
          <Cancel className="upload-entry__close-icon" onClick={onCancel} />
        </div>
      ) : (
        <div className="upload-entry__column upload-entry__column--progress-bar">
          <ProgressBar value={progressValue / 100} />
        </div>
      )}
    </div>
  );
};

const itemShape = {
  id: PropTypes.string,
  name: PropTypes.string,
};

UploadEntry.propTypes = {
  item: PropTypes.shape(itemShape).isRequired,
  progressValue: PropTypes.oneOfType([PropTypes.number, PropTypes.exact(null)]),
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UploadEntry;
