import '../EventsListLink/EventsListLink.scss';

import { Desktop, Mobile } from 'components/Responsive';
// TODO: Should not use untyped packages in typescript files
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TextClamp from 'react-string-clamp';

const noEventsMessage =
  'No webinars are added here right now, but please keep an eye on this calendar for updated dates and times for coming webinars.';

const NoEventsListLink = () => {
  return (
    <div className="events-list-link">
      <div className="events-list-link__wrapper--noevents">
        <div className="events-list-link__text-wrapper--noevents">
          <Desktop className="events-list-link__desktop">
            <div className="events-list-link__description">
              <TextClamp lines={3} text={noEventsMessage} />
            </div>
          </Desktop>
          <Mobile className="events-list-link__mobile">
            <div className="events-list-link__description">
              <TextClamp lines={4} text={noEventsMessage} />
            </div>
          </Mobile>
        </div>
      </div>
    </div>
  );
};

export default NoEventsListLink;
