import './Center.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const Center = ({
  horizontal = false,
  children,
  className,
  tag: Tag = 'div',
  ...rest
}) => {
  const centerClassNames = classNames('center', {
    'center--horizontal': horizontal,
    [className]: className,
  });
  return (
    <Tag className={centerClassNames} {...rest}>
      {children}
    </Tag>
  );
};

Center.propTypes = {
  horizontal: PropTypes.bool,
};

export default Center;
