import { useCallback, useMemo } from 'react';
import { getSiteSuggestions } from 'utils/misc/autoSuggest';

import {
  SearchSelect,
  SearchSelectOption,
  SearchSelectProps,
} from './SearchSelect';

type Site = {
  name: string;
  value: string;
  country?: string;
};

export type SiteSearchSelectProps = {
  selectedSites: Site[];
  onChange: (selectedSites: Site[]) => void;
} & Omit<SearchSelectProps, 'search' | 'selectedOptions' | 'onChange'>;

const SiteSearchSelect = ({
  selectedSites,
  onChange,
  ...searchSelectProps
}: SiteSearchSelectProps) => {
  const search = async (value: string) => {
    const suggestions = await getSiteSuggestions(value);

    if (!Array.isArray(suggestions)) {
      return [];
    }

    const sites = suggestions
      .map((suggestion: any) => {
        if (!suggestion.name || !suggestion.value) {
          return null;
        }
        const site: Site = {
          name: suggestion.name,
          value: suggestion.value,
          country: suggestion.country,
        };
        return site;
      })
      .filter(Boolean) as Site[];

    return transformSitesToSearchSelectOptions(sites);
  };

  const transformSitesToSearchSelectOptions = useCallback((sites: Site[]) => {
    return sites.map((site) => {
      const option: SearchSelectOption<Site> = {
        name: site.name,
        value: site.value,
        data: site,
      };
      return option;
    });
  }, []);

  const transformSearchSelectOptionsToSites = useCallback(
    (options: SearchSelectOption<Site>[]) =>
      options.map((option) => option.data).filter(Boolean) as Site[],
    []
  );

  const selectedOptions = useMemo(
    () => transformSitesToSearchSelectOptions(selectedSites),
    [selectedSites, transformSitesToSearchSelectOptions]
  );

  return (
    <SearchSelect
      selectedOptions={selectedOptions}
      search={search}
      onChange={(selectedOptions: SearchSelectOption<Site>[]) => {
        const selectedSites =
          transformSearchSelectOptionsToSites(selectedOptions);
        onChange(selectedSites);
      }}
      {...searchSelectProps}
    />
  );
};

export { SiteSearchSelect };
