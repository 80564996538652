import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';
import { ArticleContentType } from 'enums';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';

const ARTICLE_CONTENT_TYPE_SETTINGS: Record<
  ArticleContentType,
  {
    label: string;
    linkPath: string;
    borderColor: 'blue' | 'green' | 'yellow' | 'red';
  }
> = {
  [ArticleContentType.News]: {
    label: translations.articleContentTypeNews,
    linkPath: '/feed?type=news',
    borderColor: 'blue',
  },
  [ArticleContentType.Story]: {
    label: translations.articleContentTypeStory,
    linkPath: '/feed?type=story',
    borderColor: 'green',
  },
  [ArticleContentType.Blog]: {
    label: translations.articleContentTypeBlog,
    linkPath: '/feed?type=blog',
    borderColor: 'yellow',
  },
  [ArticleContentType.Press]: {
    label: translations.articleContentTypePress,
    linkPath: '/feed?type=press',
    borderColor: 'red',
  },
};

type ArticleTypeProps = {
  type: ArticleContentType;
  color?: string;
  sx?: SxProps<Theme>;
};

const ArticleType = ({ type, color, sx }: ArticleTypeProps) => {
  const { label, linkPath, borderColor } = ARTICLE_CONTENT_TYPE_SETTINGS[type];
  return (
    <Link
      variant="caption"
      component={ReactRouterLink}
      to={linkPath}
      sx={[
        (theme) => ({
          lineHeight: 1.2,
          fontWeight: 'bold',
          textDecoration: 'none',
          color: color || theme.palette.neutral[500],
          padding: `0 ${theme.spacing('xxxxs')}`,
          marginBottom: theme.spacing('xs'),
          borderBottom: `${theme.border.thickness[3]} solid ${
            borderColor === 'yellow'
              ? theme.colors.border.surfaceWarning
              : borderColor === 'green'
              ? theme.colors.border.surfaceSuccess
              : borderColor === 'red'
              ? theme.colors.border.surfaceCritical
              : theme.colors.border.surfaceInformative
          }`,
          '&:hover': {
            color: color || theme.palette.neutral[600],
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {label.toUpperCase()}
    </Link>
  );
};
export { ArticleType };
