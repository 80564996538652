import './Breadcrumbs.scss';

import classNames from 'classnames';
import { Desktop } from 'components/Responsive';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({
  items = [],
  path,
  includeHome = false,
  className,
  ghost,
}) => {
  const breadcrumbsClassName = classNames('breadcrumbs', {
    [className]: className,
  });
  if (ghost) {
    return (
      <Desktop>
        <ul className={breadcrumbsClassName}>
          <li>...</li>
        </ul>
      </Desktop>
    );
  }

  return (
    <Desktop>
      <ul className={breadcrumbsClassName}>
        {includeHome && (
          <li>
            <Link to="/">Home</Link>
          </li>
        )}
        {items.map(({ title, id }, i) => {
          return (
            <li key={i}>
              <Link to={id ? `${path}/${id}` : path}>{title.trim()}</Link>
            </li>
          );
        })}
      </ul>
    </Desktop>
  );
};

Breadcrumbs.propTypes = {
  ghost: PropTypes.bool,
  path: PropTypes.string,
  includeHome: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
    })
  ),
};

export { Breadcrumbs };
export default memo(Breadcrumbs);
