import './Profile.scss';

import { getProfile } from 'api/organization';
import Error from 'components/Error';
import Fetcher from 'components/Fetcher';
import { Column, Container } from 'components/Grid';
import { GAitemNotFound } from 'utils/analytics';

import ProfileTable from './ProfileTable';

const onError = (status, message) => {
  GAitemNotFound(status, message, 'person');
  return <Error message={message} status={status} redirectOn404={true} />;
};

const Profile = ({ match }) => (
  <>
    <Container>
      <Column>
        <Fetcher
          source={getProfile}
          param={match.params.id}
          key={match.url}
          returnLoading={true}
          onError={onError}
        >
          {(props) => (
            <ProfileTable {...props} isProfile={match.path === '/profile/'} />
          )}
        </Fetcher>
      </Column>
    </Container>
  </>
);

export default Profile;
