import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';

import { Consumer } from '..';

const StoryDisplayOnHome = () => (
  <Consumer>
    {({
      onToggleArticleValue,
      article: { storyDisplayOnHome, storyDisplayOnHomeDate },
    }) => {
      // Disable this checkbox if storyDisplayOnHomeDate is older than 30 days.
      let disabled = false;
      if (storyDisplayOnHomeDate) {
        const startDate = new Date(storyDisplayOnHomeDate);
        startDate.setDate(startDate.getDate() + 30);
        disabled = startDate < new Date();
      }

      return (
        <Flex>
          <Checkbox
            defaultChecked={storyDisplayOnHome}
            id="storyDisplayOnHome"
            onChange={() => onToggleArticleValue('storyDisplayOnHome')}
            disabled={disabled}
            color={disabled ? 'disabled' : ''}
          />
          <label
            htmlFor="storyDisplayOnHome"
            className="m-l-3"
            style={disabled ? { color: '#d6d1d1' } : { color: '' }}
          >
            Display on home
          </label>
        </Flex>
      );
    }}
  </Consumer>
);

export default StoryDisplayOnHome;
