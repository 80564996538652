import './FileUploadModal.scss';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import FileUploader from '../FileUploader';

const FileUploadModal = ({ data, onConfirm, onCancel }) => {
  const [open, setOpen] = useState(!!data);
  useEffect(() => {
    !!data !== open && setOpen(!!data);
  }, [open, data]);
  const onClosed = (value) => {
    setOpen(false);
    if (value) {
      onConfirm(null);
    } else {
      onCancel();
    }
  };
  const onClickOutside = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      {open ? (
        <div
          className="file-upload-modal__click-outside-handler"
          onClick={onClickOutside}
        />
      ) : null}
      {open ? (
        <div className="file-upload-modal__wrapper">
          <div className="file-upload-modal__container">
            <FileUploader
              folderName={data?.folderName}
              path={data?.path}
              onClosed={onClosed}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

FileUploadModal.propTypes = {
  data: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FileUploadModal;
