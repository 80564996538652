import { getCategories } from 'api/video';
import Box from 'components/Box';
import Fetcher from 'components/Fetcher';
import Select from 'components/Select';

import { Consumer } from '..';

const Playlist = () => (
  <Consumer>
    {({ onChangeArticle, updatedArticle: { videoPlaylistId } }) => (
      <Box>
        <div className="m-b-4">
          <p>Select video playlist (optional)</p>
          <Fetcher source={getCategories}>
            {({ response }) => (
              <Select
                data={response.map((r) => ({
                  id: r.oid,
                  value: r.id,
                  name: r.title,
                }))}
                placeholder="Select video playlist"
                selected={videoPlaylistId}
                includeNone={true}
                onChange={(value) =>
                  onChangeArticle({ videoPlaylistId: value })
                }
              />
            )}
          </Fetcher>
        </div>
      </Box>
    )}
  </Consumer>
);

export default Playlist;
