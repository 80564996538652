import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React from 'react';

class Resize extends React.Component {
  state = {
    width: null,
  };

  componentDidMount() {
    this._isMounted = true;
    this.calculate();
    this.onResize = window.addEventListener(
      'resize',
      debounce(this.calculate, this.props.debounceRate)
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.onResize);
  }

  calculate = () => {
    if (this._isMounted) {
      const { width } = this.el.getBoundingClientRect();
      const { maxWidth, allowActualWidth } = this.props;

      if (allowActualWidth) {
        this.setState({
          width: width,
        });
      } else {
        this.setState({
          width: width < maxWidth ? width : maxWidth,
        });
      }
    }
  };

  render() {
    const { tag: Tag, maxWidth } = this.props;

    return (
      <Tag ref={(el) => (this.el = el)}>
        {this.props.children({ width: this.state.width || maxWidth })}
      </Tag>
    );
  }
}

Resize.propTypes = {
  debounceRate: PropTypes.number,
  maxWidth: PropTypes.number,
  allowActualWidth: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Resize.defaultProps = {
  debounceRate: 300,
  maxWidth: 1100,
  allowActualWidth: false,
  tag: 'div',
};

export const withResize = (WrappedComponent) => {
  const WrappedComponentWithRezise = (inputProps) => (
    <Resize>
      {(props) => <WrappedComponent {...inputProps} {...props} />}
    </Resize>
  );
  return WrappedComponentWithRezise;
};

export default Resize;
