import './RecommendedItem.scss';

import { ReactComponent as StarIcon } from 'components/Icon/Star.svg';
import { Link } from 'react-router-dom';

import ContentRows from '../ContentRows';

const RecommendedListItem = ({
  name,
  id,
  locations,
  category,
  department,
  trackingEvent,
}) => {
  return (
    <li className="recommended-list-item">
      <Link
        to={`/jobs/${id}`}
        onClick={() => {
          if (trackingEvent) {
            trackingEvent(id, name);
          }
        }}
        className="recommended-list-item__link"
      >
        <div className="recommended-list-item__description">
          <div className="recommended-list-item__wrapper">
            <StarIcon className="recommended-list-item__icon" />
            <span className="recommended-list-item__text">Recommended</span>
          </div>

          <ContentRows
            name={name}
            category={category}
            locations={locations}
            department={department}
            hiringManager={false}
          />
        </div>
      </Link>
    </li>
  );
};

export default RecommendedListItem;
