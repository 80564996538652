enum FeatureToggle {
  NewDesignJobsPage = 'new_design_jobs_page',
  AssaFont = 'assa_font',
  ThirtyYearCeletebrationSkin = 'thirty_year_celebration_skin',
}

function featureToggle(key: FeatureToggle) {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  return params.get(key) === 'true';
}

export { FeatureToggle, featureToggle };
