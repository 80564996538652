import './Filter.scss';

import { typeToLabel } from 'api/models/SearchHit';
import classNames from 'classnames';
import Flex from 'components/Flex';
import { memo, useState } from 'react';

const SuggestionFilter = ({ onClick }) => {
  const [active, setActive] = useState(0);

  const selectedClassName = (index) =>
    classNames('filter__list-items', {
      'filter__list-items--active': active === index,
    });

  return (
    <div>
      <div className="filter__btn-group">
        <Flex wrap="wrap">
          <ul className="filter__list-style">
            {[['all', 'All'], ...Object.entries(typeToLabel)]
              .filter(
                ([_enumerable, label]) =>
                  label !== typeToLabel.innovationportal &&
                  label !== typeToLabel.strategyportal &&
                  label !== typeToLabel.codeofconductportal
              )
              .map(([enumerable, label], i) => (
                <li
                  key={i}
                  onClick={() => onClick(enumerable)}
                  className={selectedClassName(i)}
                >
                  <label
                    onClick={() => setActive(i)}
                    className="filter__list-items--labelActive"
                  >
                    {label}
                  </label>
                </li>
              ))}
          </ul>
        </Flex>
      </div>
    </div>
  );
};

export default memo(SuggestionFilter);
