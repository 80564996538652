import './HeaderBox.scss';

import PortalNode from 'api/models/PortalNode';
import classnames from 'classnames';
import OnlyWithRole from 'components/OnlyWithRole';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import DefaultBackground from './innovation-portal-background.jpg';
import InnovationBackground from './innovation-portal-background.jpg';
import StrategyBackground from './strategy-portal-background.jpg';

const PortalBackgrounds = {
  innovation: InnovationBackground,
  strategy: StrategyBackground,
};

class InsightsListingHeaderBox extends Component {
  state = { stretchBg: false };

  backgroundLoaded = () => {
    this.setState({
      stretchBg:
        this.imageElement.offsetHeight > this.headerboxElement.offsetHeight,
    });
  };

  render() {
    const { page, align } = this.props;
    const { types } = page.attributes;
    const bgInnerClasses = classnames(
      'insights-listing-header-box__background-inner',
      {
        'insights-listing-header-box__background-inner--full-height':
          this.state.stretchBg,
      }
    );
    const backgroundImage =
      PortalBackgrounds[page.get('portalType')] || DefaultBackground;
    const italicText = types && types[0] && types[0].title;

    let mainClass = null;
    switch (align) {
      case 'left':
        mainClass =
          'insights-listing-header-box insights-listing-header-box--left';
        break;
      case 'right':
        mainClass =
          'insights-listing-header-box insights-listing-header-box--right';
        break;
      default:
        mainClass = 'insights-listing-header-box';
    }

    return (
      <div className={mainClass} ref={(elem) => (this.headerboxElement = elem)}>
        <div className="insights-listing-header-box__background-wrapper">
          <div className={bgInnerClasses}>
            <div className="insights-listing-header-box__background-gradient" />
            <img
              src={backgroundImage}
              alt=""
              ref={(elem) => (this.imageElement = elem)}
              onLoad={this.backgroundLoaded}
            />
          </div>
        </div>
        <div className="insights-listing-header-box__content">
          {italicText && (
            <p className="insights-listing-header-box__italic-text">
              {italicText}
            </p>
          )}
          <h1 className="insights-listing-header-box__title">
            {page.get('title')}
          </h1>
        </div>
        <OnlyWithRole viewableByRole="AvenuePortalEditor">
          <div className="insights-listing-header-box__menu">
            <Link
              className="insights-listing-header-box__menu-link"
              to={page.getEditPath()}
            >
              Edit
            </Link>
          </div>
        </OnlyWithRole>
      </div>
    );
  }
}

InsightsListingHeaderBox.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
  align: PropTypes.string,
};

export default InsightsListingHeaderBox;
