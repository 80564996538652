import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as organizationApi from 'api/organization';
import { Icon } from 'componentsNew';
import { useSnackbar } from 'contextNew/Snackbar';
import { useCallback, useMemo, useState } from 'react';

const elementId = 'profile-actions';

const ProfileActions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const { showSnackbar } = useSnackbar();

  const deleteProfileSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      await organizationApi.removeConsent();
      showSnackbar({
        type: 'success',
        text: 'Profile settings were successfully deleted',
      });
    } catch {
      showSnackbar({
        type: 'error',
        text: '"Failed to delete profile settings"',
      });
    } finally {
      setIsLoading(false);
      setAnchorEl(null);
    }
  }, [showSnackbar]);

  const actions: {
    title: string;
    text: string;
    icon: React.ReactNode;
    disabled: boolean;
    onClick: () => void;
  }[] = useMemo(() => {
    return [
      {
        title: 'Remove profile settings',
        text: 'Click here to remove all profile settings from user. This is strictly for testing and only visible in non production environments.',
        icon: <Icon type="exclamationTriangleFilled" color="critical" />,
        disabled: isLoading,
        onClick: deleteProfileSettings,
      },
    ];
  }, [deleteProfileSettings, isLoading]);

  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        top: theme.spacing('xs'),
        right: theme.spacing('xs'),
      })}
    >
      <Tooltip title="Profile actions">
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          size="small"
          aria-controls={`${elementId}-menu`}
          aria-haspopup="true"
          aria-expanded={open}
        >
          <Icon type="cogTooth" color="brandBase" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id={`${elementId}-menu`}
        open={open}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {actions.map((action, index) => (
          <MenuItem
            sx={(theme) => ({
              maxWidth: '20rem',
              whiteSpace: 'normal',
              padding: `${theme.spacing('xs')} ${theme.spacing('sm')}`,
              alignItems: 'flex-start',
              gap: theme.spacing('xxs'),
              p: {
                wordBreak: 'break-word',
                color: theme.colors.text.primary,
              },
            })}
            key={`${elementId}-${index}`}
            disabled={action.disabled}
            onClick={action.onClick}
          >
            {action.icon}
            <Stack>
              <Typography variant="body2" fontWeight="bold">
                {action.title}
              </Typography>
              <Typography variant="caption">{action.text}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export { ProfileActions };
