import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew';
import { translations } from 'translations';

const elementId = 'ai-chat-action-dislike-disclaimer';

type AIChatActionDislikeDisclaimerProps = {
  sx?: SxProps<Theme>;
};

const AIChatActionDislikeDisclaimer = ({
  sx,
}: AIChatActionDislikeDisclaimerProps) => {
  return (
    <Stack
      id={elementId}
      flexDirection="row"
      sx={[
        (theme) => ({
          border: `1px solid ${theme.colors.border.surfaceInformative}`,
          borderRadius: theme.border.radius.sm,
          padding: `${theme.spacing('xxxs')} ${theme.spacing('xxs')}`,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Icon type="informationCircleFilled" color="brandBase" size={20} />
      <Typography
        variant="caption"
        sx={(theme) => ({
          color: theme.colors.text.secondary,
          marginLeft: theme.spacing('xxs'),
          display: 'flex',
          alignItems: 'center',
        })}
      >
        {translations.aiChatActionDislikeDisclaimer}
      </Typography>
    </Stack>
  );
};

export { AIChatActionDislikeDisclaimer };
