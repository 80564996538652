import Box from 'components/Box';
import Select from 'components/Select';

import { Consumer } from '../';

const blueBackgroundColors = ['#007cab', '#034b70'];

const grayBackgroundColors = ['#7e7e7e', '#4c4c4c'];

const colorOptions = [
  { id: 0, value: blueBackgroundColors[1], name: 'Blue' },
  { id: 1, value: grayBackgroundColors[1], name: 'Gray' },
];

const defaultColorOption = colorOptions[0];

const onChange =
  (onChangeArticle) =>
  ({ value: backgroundColor }) => {
    onChangeArticle({ backgroundColor });
  };

const ColorSelect = () => (
  <Consumer>
    {({ onChangeArticle, article, updatedArticle: { backgroundColor } }) => {
      const selected =
        colorOptions.find((option) => option.value === backgroundColor) ||
        colorOptions.find((option) => option.value === article.backgroundColor);
      return (
        <Box>
          <div className="m-b-4">
            <p>Select color</p>
            <Select
              data={colorOptions}
              placeholder="Select color"
              selected={selected || defaultColorOption}
              onChange={onChange(onChangeArticle)}
            />
          </div>
        </Box>
      );
    }}
  </Consumer>
);

export default ColorSelect;
