const prod = {
  portals: [
    {
      id: '5723537e501f84604193',
      name: 'Code of Conduct',
      url: 'codeofconduct',
      homePath: '/portals/codeofconduct',
      roleRestriction: { isRestricted: false, role: '' },
    },
    {
      id: '932c902e5e179717e2cd',
      name: 'Our Strategy',
      url: 'strategy',
      homePath: '/portals/strategy',
      roleRestriction: { isRestricted: false, role: '' },
    },
    {
      id: '7d4d07fd728341e03547',
      name: 'Innovation',
      url: 'innovation',
      homePath: '/portals/innovation',
      roleRestriction: { isRestricted: false, role: '' },
      insightsDatabaseId: '81e12ab13a6e746f8c03',
    },
  ],
};

export default prod;
