import './SocialItem.scss';

import PropTypes from 'prop-types';
import { GAonSocialItemClick } from 'utils/analytics';

const SocialItem = ({ href, name, children }) => {
  return (
    <li className="social-item">
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={GAonSocialItemClick(name)}
      >
        {children}
      </a>
    </li>
  );
};

SocialItem.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SocialItem;
