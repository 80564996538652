import Error from 'components/Error';
import { Component } from 'react';
import { GAreportError } from 'utils/analytics';

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorObject: null,
  };

  componentDidCatch(errorObject) {
    if (errorObject.report) {
      GAreportError(errorObject);
    }
  }

  static getDerivedStateFromError(errorObject) {
    return { hasError: true, errorObject };
  }

  render() {
    const { hasError, errorObject } = this.state;
    if (hasError) {
      return (
        <Error title={errorObject.statusCode} message={errorObject.message} />
      );
    }
    return this.props.children;
  }
}

export function withErrorBoundary(WrappedComponent) {
  class WrappedComponentWithErrorBoundary extends Component {
    render() {
      return (
        <ErrorBoundary>
          <WrappedComponent {...this.props} />
        </ErrorBoundary>
      );
    }
  }
  return WrappedComponentWithErrorBoundary;
}
