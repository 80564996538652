import './nouser.scss';

import Img from 'components/Image';
import PageTitle from 'components/PageTitle';

import noUserImage from './warning.png';

const NoUser = () => {
  return (
    <>
      <PageTitle titles={['User not found']} />
      <div className="no-user__container">
        <div className="no-user__wrapper">
          <div className="no-user__image-wrapper">
            <Img
              alt="We are unable to get your profile from the Group Directory, please contact support for assistance."
              src={noUserImage}
              animate={true}
            />
          </div>
          <div className="no-user__text-wrapper">
            <div className="no-user__title">Something went wrong</div>
            <div className="no-user__text">
              We are unable to get your profile from the Group Directory, please
              contact{' '}
              <a
                className="no-user__mail-link"
                href="mailto:avenue@assaabloy.com"
              >
                support
              </a>{' '}
              for assistance.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoUser;
