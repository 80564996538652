import './Navigation.scss';

import classnames from 'classnames';
import { Column, Container } from 'components/Grid';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import NavigationItem from './NavigationItem';

const PublicMenuContent = ({ pages, showNavItems }) => {
  const filteredPages = showNavItems
    ? []
    : pages.filter((item) => !item.hideFromNavigation);

  const navClasses = classnames('navigation__inner', {
    'navigation__inner--empty': showNavItems,
  });

  return (
    <div className={navClasses}>
      <Container>
        <Column>
          <ul className="navigation__items">
            {!showNavItems && (
              <NavigationItem to="/public/faq" exact>
                Frequently Asked Questions
              </NavigationItem>
            )}

            {filteredPages.map((page) => (
              <NavigationItem key={page._doc} to={`/public/${page.url}`} exact>
                {page.title}
              </NavigationItem>
            ))}
          </ul>
        </Column>
      </Container>
    </div>
  );
};

PublicMenuContent.propTypes = {
  pages: PropTypes.array.isRequired,
};

export default withRouter(PublicMenuContent);
