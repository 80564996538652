import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { translations } from 'translations';
import { getSuggestions } from 'utils/misc/autoSuggest';

import Suggestion from '../api/models/Suggestion';
import {
  SearchSelect,
  SearchSelectOption,
  SearchSelectProps,
} from './SearchSelect';

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type UserSearchSelectProps = {
  selectedUsers: User[];
  onChange: (selectedUsers: User[]) => void;
} & Omit<SearchSelectProps<User>, 'search' | 'selectedOptions' | 'onChange'>;

const UserSearchSelect = ({
  selectedUsers,
  onChange,
  ...searchSelectProps
}: UserSearchSelectProps) => {
  const search = async (value: string) => {
    const filter = { type: 'person' };

    const suggestions = ((await getSuggestions(value, filter, false)) ||
      []) as Suggestion[];

    const users = suggestions
      .map((suggestion) => {
        if (!suggestion.id || !suggestion.attributes) {
          return null;
        }
        const user: User = {
          id: suggestion.id.substring(suggestion.id.indexOf('-') + 1),
          email: suggestion.attributes.email || '',
          firstName: suggestion.attributes.firstName || '',
          lastName: suggestion.attributes.lastName || '',
        };
        return user;
      })
      .filter(Boolean) as User[];

    return transformUsersToSearchSelectOptions(users);
  };

  const transformUsersToSearchSelectOptions = useCallback((users: User[]) => {
    return users.map((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.trim();
      const option: SearchSelectOption<User> = {
        name: fullName,
        value: user.id,
        data: user,
        element: (
          <Stack sx={{ wordBreak: 'break-word' }}>
            <Typography variant="body1">{fullName}</Typography>
            <Typography variant="body2">{user.email}</Typography>
          </Stack>
        ),
      };
      return option;
    });
  }, []);

  const transformSearchSelectOptionsToUsers = useCallback(
    (options: SearchSelectOption<User>[]) =>
      options.map((option) => option.data).filter(Boolean) as User[],
    []
  );

  const selectedOptions = useMemo(
    () => transformUsersToSearchSelectOptions(selectedUsers),
    [selectedUsers, transformUsersToSearchSelectOptions]
  );

  return (
    <SearchSelect<User>
      placeholder={translations.searchEmployees}
      selectedOptions={selectedOptions}
      search={search}
      onChange={(selectedOptions: SearchSelectOption<User>[]) => {
        const selectedUsers =
          transformSearchSelectOptionsToUsers(selectedOptions);
        onChange(selectedUsers);
      }}
      {...searchSelectProps}
    />
  );
};

export { UserSearchSelect };
