import './CrosswordInput.scss';

import classNames from 'classnames';
import { Component } from 'react';

const inputRefs = {};
let inputId = 0;

class CrosswordInput extends Component {
  constructor(props) {
    super(props);

    const { hidden, showRowNum } = props;

    if (!hidden && !showRowNum) {
      this.inputId = inputId;
      inputId++;
    }
  }
  onKeyDown = (e) => {
    const { keyCode, target } = e;
    // tab, shift, ctrl, etc. do nothing or standard behavior
    if (keyCode < 30 && keyCode !== 8) {
      return;
    }

    // javascript is weird, using this to get actual character from keycode
    // https://stackoverflow.com/questions/1772179/get-character-value-from-keycode-in-javascript-then-trim
    const chrCode = keyCode - 48 * Math.floor(keyCode / 48);
    const chr = String.fromCharCode(96 <= keyCode ? chrCode : keyCode);

    let nextInput;
    // back-space
    if (keyCode === 8) {
      if (!target.value) {
        nextInput = inputRefs[this.inputId - 1];
      }
    } else if (!/[a-zA-Z0-9]+/.test(chr)) {
      e.preventDefault();
    } else {
      nextInput = inputRefs[this.inputId + 1];
    }

    if (nextInput) {
      setTimeout(() => nextInput.focus(), 100);
    }
  };

  onFocus = () => {
    this.props.onFocus(this.props.rowNum);
  };

  render() {
    const { center, hidden, rowNum, showRowNum, focused } = this.props;

    if (hidden) {
      return <div className="crossword-input__hidden" />;
    }

    if (showRowNum) {
      return <span className="crossword-input__rownum">{rowNum + 1}</span>;
    }

    const inputClassNames = classNames('crossword-input__text', {
      'crossword-input__text--center': center,
      'crossword-input__text--focused': focused,
    });

    return (
      <input
        maxLength={1}
        className={inputClassNames}
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
        ref={(ref) => (inputRefs[this.inputId] = ref)}
      />
    );
  }
}

export default CrosswordInput;
