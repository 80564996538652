import Resize from 'components/Resize';
import { Desktop, Mobile } from 'components/Responsive';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const styleDesktop = {
  height: '248px',
};

const styleMobile = {
  height: '91px',
};

const DesktopContent = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={248}
    preserveAspectRatio="none"
    style={styleDesktop}
  >
    <rect x={(width - 120) / 2} y={0} width={120} height={120} />
    <rect x={(width - 180) / 2} y={132} width={180} height={24} />

    <rect x={0} y={200} width={getRandomNumberBetween(150, 300)} height={18} />
    <rect x={0} y={230} width={getRandomNumberBetween(150, 300)} height={18} />
  </ContentLoader>
);

const MobileContent = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={91}
    preserveAspectRatio="none"
    style={styleMobile}
  >
    <rect x={51} y={0} width={60} height={60} />
    <rect x={0} y={68} width={160} height={24} />

    <rect x={183} y={10} width={getRandomNumberBetween(150, 300)} height={18} />
    <rect x={183} y={40} width={getRandomNumberBetween(150, 300)} height={18} />
  </ContentLoader>
);

const Loader = () => (
  <Resize>
    {({ width }) => (
      <>
        <Desktop>
          <DesktopContent width={width} />
        </Desktop>
        <Mobile>
          <MobileContent width={width} />
        </Mobile>
      </>
    )}
  </Resize>
);

export default Loader;
