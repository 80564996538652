import './ActionMenu.scss';

import classNames from 'classnames';

interface ActionMenuProps {
  id?: string;
  className?: string;
  ariaLabel?: string;
  items: {
    label: string;
    type?: 'default' | 'critical';
    onClick: () => void;
  }[];
}

const ActionMenu = ({ id, className, ariaLabel, items }: ActionMenuProps) => {
  return (
    <ul
      id={id}
      role="menu"
      aria-label={ariaLabel}
      className={classNames('action-menu', className)}
    >
      {items.map((item, i) => (
        <li
          role="menuitem"
          key={`action-menu-item-${i}`}
          className={classNames('action-menu-item', {
            [`action-menu-item--${item.type}`]: Boolean(item.type),
          })}
          onClick={item.onClick}
        >
          <button>{item.label}</button>
        </li>
      ))}
    </ul>
  );
};

export { ActionMenu };
