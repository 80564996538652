import Select from 'components/Select';

import { Consumer } from '..';

const PortalTopicSelector = ({ data, selectedTopic, onChangeTopic }) => {
  return (
    <Consumer>
      {({ onChangeArticle }) => {
        return (
          <>
            <p>Select Insights database topic</p>
            <Select
              elementId="portal--topics--selector"
              data={data.map((o) => ({
                name: o.attributes.title,
                value: o.id,
              }))}
              placeholder="Select topic"
              selected={selectedTopic}
              multiple={false}
              onChange={(value) => {
                onChangeArticle({ insightsDatabaseTopic: value || null });
                onChangeTopic(value);
              }}
              deSelectEnabled={true}
            />
            <div className="m-t-6 m-b-6" />
          </>
        );
      }}
    </Consumer>
  );
};

export default PortalTopicSelector;
