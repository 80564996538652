import { useState } from 'react';

import FileActionConfirmModal from './components/FileActionConfirmModal';
import FileNameEntryModal from './components/FileNameEntryModal';
import FileUploadModal from './components/FileUploadModal';

const initialModalState = {
  confirmFileAction: null,
  fileNameEntry: null,
  fileUpload: null,
};

const getModalRenderData = (modalState, hideModal) =>
  Object.entries(modalState)
    .filter(([_, entry]) => entry !== null)
    .map(([name, { data }]) => {
      const onConfirm = (result) => {
        hideModal(name, name === 'fileNameEntry' ? result : null);
      };
      const onCancel = () => {
        hideModal(name);
      };
      return {
        component: {
          confirmFileAction: FileActionConfirmModal,
          fileNameEntry: FileNameEntryModal,
          fileUpload: FileUploadModal,
        }[name],
        props: { data, onConfirm, onCancel },
      };
    });

const useFileManagerModals = () => {
  const [modalState, setModalState] = useState(initialModalState);
  const showModal = (type, data, onConfirm) => {
    if (initialModalState[type] !== null) {
      throw new Error(`Invalid modal type selected ${type}`);
    }
    setModalState({ ...modalState, [type]: { data, onConfirm } });
  };
  const hideModal = (modalType, result) => {
    result !== undefined && modalState[modalType].onConfirm(result);
    setModalState({ ...modalState, [modalType]: null });
  };
  return {
    modalState,
    setModalState,
    showModal,
    hideModal,
    modalRenderData: getModalRenderData(modalState, hideModal),
  };
};

export default useFileManagerModals;
