import './ArticleTags.scss';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';

class ArticleTags extends Component {
  render() {
    const {
      productGroups,
      targetGroups,
      verticalMarkets,
      geographicMarkets,
      topics,
      languages,
      parentId,
    } = this.props;

    return (
      <div className="article-tags">
        <hr />
        <div className="article-tags__list">
          {productGroups && productGroups.length > 0 && (
            <div className="article-tags__item">
              <div className="article-tags__item-label">Product group</div>
              <div className="article-tags__item-value">
                {productGroups.map((obj, i) => (
                  <Link
                    className="article-tags__item-link"
                    to={`${parentId}?productgroup=${obj.id}`}
                    key={i}
                  >
                    {obj.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {
            // remove this category for now
            targetGroups && targetGroups.length > 0 && false && (
              <div className="article-tags__item">
                <div className="article-tags__item-label">Target group</div>
                <div className="article-tags__item-value">
                  {targetGroups.map((obj, i) => (
                    <Link
                      className="article-tags__item-link"
                      to={`${parentId}?verticalmarket=${obj.id}`}
                      key={i}
                    >
                      {obj.title}
                    </Link>
                  ))}
                </div>
              </div>
            )
          }
          {verticalMarkets && verticalMarkets.length > 0 && (
            <div className="article-tags__item">
              <div className="article-tags__item-label">Vertical market</div>
              <div className="article-tags__item-value">
                {verticalMarkets.map((obj, i) => (
                  <Link
                    className="article-tags__item-link"
                    to={`${parentId}?verticalmarket=${obj.id}`}
                    key={i}
                  >
                    {obj.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {geographicMarkets && geographicMarkets.length > 0 && (
            <div className="article-tags__item">
              <div className="article-tags__item-label">Geographic market</div>
              <div className="article-tags__item-value">
                {geographicMarkets.map((obj, i) => (
                  <Link
                    className="article-tags__item-link"
                    to={`${parentId}?geographicmarket=${obj.id}`}
                    key={i}
                  >
                    {obj.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {topics && topics.length > 0 && (
            <div className="article-tags__item">
              <div className="article-tags__item-label">Topics</div>
              <div className="article-tags__item-value">
                {topics.map((obj, i) => (
                  <Link
                    className="sidebar-box__item-link"
                    to={`${parentId}?topic=${obj.id}`}
                    key={i}
                  >
                    {obj.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {
            // remove this category for now
            languages && languages.length > 0 && false && (
              <div className="article-tags__item">
                <div className="article-tags__item-label">Language</div>
                <div className="article-tags__item-value">
                  {languages.map((obj, i) => (
                    <span key={i}>{obj.title}</span>
                  ))}
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

ArticleTags.propTypes = {
  productGroups: PropTypes.array,
  targetGroups: PropTypes.array,
  verticalMarkets: PropTypes.array,
  geographicMarkets: PropTypes.array,
  topics: PropTypes.array,
  languages: PropTypes.array,
  parentId: PropTypes.string,
};

export default ArticleTags;
