import Text from 'components/Editable/Text';

import { Consumer } from '../';

const onChange = (onChangeArticle, fieldName) => (linkButtonText) => {
  onChangeArticle({ [fieldName]: linkButtonText });
};

const LinkButtonText = ({
  editable,
  color,
  placeholder,
  fieldName = 'linkButtonText',
}) => (
  <Consumer>
    {({
      article,
      onChangeArticle,
      validationParameters: {
        [fieldName]: { maxLength },
      },
    }) => {
      if (!editable || editable(article)) {
        return (
          <div className="m-t-6">
            <Text
              wrapper={{
                element: 'h1',
                className: 'preamble',
              }}
              placeholder={placeholder}
              onBlur={onChange(onChangeArticle, fieldName)}
              content={article[fieldName]}
              maxLength={maxLength}
              color={color}
            />
          </div>
        );
      }

      return <div>{article[fieldName]}</div>;
    }}
  </Consumer>
);

export default LinkButtonText;
