import Box from 'components/Box';
import _Documents from 'components/Documents';
import Title from 'components/Title';
import { Suspense } from 'react';

import { Consumer } from '..';

const Documents = () => (
  <Consumer>
    {({
      article,
      updatedArticle: { sharepointFolderName },
      onChangeArticle,
    }) => {
      return (
        <>
          <Title size="small">Documents</Title>
          <Box>
            <Suspense fallback={<div />}>
              <_Documents
                folderName={
                  article.sharepointFolderName || sharepointFolderName
                }
                edit={true}
                onChange={onChangeArticle}
              />
            </Suspense>
          </Box>
        </>
      );
    }}
  </Consumer>
);

export default Documents;
