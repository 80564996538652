import { WhitelistedUrlsConsumer } from 'components/Context/WhitelistedUrls';
import Modal from 'components/Modal';
import { FC, ReactChild, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import settings from 'settings';

const { redirectUri } = settings.auth;

interface WhitelistUrlModalProps {
  linkUrl: string;
  openInNewWindow?: string;
  className?: string;
  onClick?: () => void;
  children: ReactChild;
}

const WhitelistUrlModal: FC<WhitelistUrlModalProps> = ({
  linkUrl,
  openInNewWindow,
  className,
  onClick,
  children,
}) => {
  const history = useHistory();

  const onCancelClick = () => {
    setShowModal(false);
  };

  const onYesClick = () => {
    setShowModal(false);
    setRedirect(true);
  };

  const buttons = [
    { color: 'white', title: 'Cancel', onClick: onCancelClick },
    { color: 'black', title: 'Yes', awaitTitle: '...', onClick: onYesClick },
  ];

  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const onClickShowModal = (e: any, urls: string[]) => {
    setRedirect(false);
    const found = !urls.some((url) => linkUrl.indexOf(url) > -1);

    if (found) {
      e.preventDefault();
      setShowModal(true);
    }
  };

  return (
    <WhitelistedUrlsConsumer>
      {({ ...whitelistedUrls }) => {
        const toUrl = linkUrl.replace(redirectUri, '');

        const outbound =
          toUrl.includes('https://') ||
          toUrl.includes('http://') ||
          toUrl.includes('mailto:');

        if (redirect) {
          if (outbound) {
            window.open(
              linkUrl,
              openInNewWindow === 'true' ? '_blank' : '_self'
            );
          } else {
            history.push(toUrl);
          }
        }

        return (
          <>
            {showModal && (
              <Modal buttons={buttons} title="You are leaving Avenue">
                <>
                  You are about to leave Avenue for:
                  <br />
                  {linkUrl.length > 50
                    ? `${linkUrl.substring(0, 50)}...`
                    : linkUrl}
                  <br /> <br />
                  Are you sure?
                </>
              </Modal>
            )}
            {outbound ? (
              <a
                href={linkUrl}
                target={openInNewWindow === 'true' ? '_blank' : '_self'}
                className={className}
                rel="noopener noreferrer"
                onClick={(e) => {
                  onClickShowModal(e, whitelistedUrls.urls);
                  if (onClick) onClick();
                }}
              >
                {children}
              </a>
            ) : (
              <Link to={toUrl} className={className}>
                {children}
              </Link>
            )}
          </>
        );
      }}
    </WhitelistedUrlsConsumer>
  );
};

export default WhitelistUrlModal;
