import './Edit.scss';

import { useTheme } from '@mui/material/styles';
import * as API from 'api/cms/blog';
import classNames from 'classnames';
import { AdditionalFeedbackReceivers } from 'components/AdditionalFeedbackReceivers/AdditionalFeedbackReceivers';
import Box from 'components/Box';
import CreateOrEdit, { Consumer } from 'components/CreateOrEditArticle';
import ArticleMeta from 'components/CreateOrEditArticle/components/ArticleMeta';
import BlogAuthor from 'components/CreateOrEditArticle/components/BlogAuthor';
import Body from 'components/CreateOrEditArticle/components/Body';
import Breadcrumbs from 'components/CreateOrEditArticle/components/Breadcrumbs';
import Channels from 'components/CreateOrEditArticle/components/Channels';
import ContentLabels from 'components/CreateOrEditArticle/components/ContentLabels';
import DisableComments from 'components/CreateOrEditArticle/components/DisableComments';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import { ImageGallery } from 'components/CreateOrEditArticle/components/ImageGallery';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import PriorityField from 'components/CreateOrEditArticle/components/PriorityField';
import PublishOptions from 'components/CreateOrEditArticle/components/PublishOptions';
import PublishSelector from 'components/CreateOrEditArticle/components/PublishSelector';
import ScreenSelector from 'components/CreateOrEditArticle/components/ScreenSelector';
import TargetingOptions from 'components/CreateOrEditArticle/components/TargetingOptions';
import Title from 'components/CreateOrEditArticle/components/Title';
import { PublishDateFifteenMinutesStep } from 'components/CreateOrEditArticle/PublishDateFifteenMinutesStep';
import { maxLengthValidator } from 'components/CreateOrEditArticle/validators';
import { Column, Container } from 'components/Grid';
import { gaOnPublishNewsBlogStory } from 'utils/analytics';
import { getIsPortraitHeroImage } from 'utils/cms';

import ContentLoader from '../ContentLoader';

const validateBlogAuthor = ({ guestAuthor, blogAuthor }) => {
  if (!blogAuthor && !guestAuthor) {
    return 'Blog author or guest author';
  }

  if (guestAuthor && !guestAuthor.description) {
    return 'Guest author description';
  }

  return null;
};

const validateDivisions = ({ publishedInDivisions, publishedInGlobal }) => {
  if (publishedInDivisions || publishedInGlobal) {
    return null;
  }

  return 'Published in divisions';
};

const afterBlogCreated = (updatedArticle, article, isCreating, history) => {
  if (article.isDraft && !updatedArticle.isDraft) {
    gaOnPublishNewsBlogStory(updatedArticle.id);
  } else if (isCreating && !updatedArticle.isDraft) {
    gaOnPublishNewsBlogStory(updatedArticle.id);
  }

  return history.push(`/feed/blog/${updatedArticle.id}`);
};

const fields = [
  { key: 'title', required: true, displayName: 'Title', maxLength: 90 },
  { key: 'preamble', fieldValidator: maxLengthValidator(500), maxLength: 500 },
  { key: 'body' },
  { key: 'imageGalleryItems' },
  { key: 'publishedDate', defaultValue: new Date().toISOString() },
  { key: 'heroAltText' },
  { key: 'heroImage' },
  { key: 'heroImageWidth' },
  { key: 'heroImageHeight' },
  { key: 'heroVideo' },
  {
    key: 'blogAuthor',
    validator: validateBlogAuthor,
    maxLength: 10000,
  },
  {
    key: 'guestAuthor',
    validator: validateBlogAuthor,
    maxLength: 10000,
  },
  {
    key: 'publishedInGlobal',
    intermediate: true,
    defaultValue: false,
    getIntermediateDefault: ({ publishedInDivisions }) => !publishedInDivisions,
  },
  {
    key: 'publishedInDivisions',
    validator: validateDivisions,
  },
  { key: 'publishedInSegments' },
  { key: 'publishedInCountries' },
  { key: 'publishedInDepartments' },
  { key: 'publishedInSites' },
  { key: 'publishedInCompanies' },
  { key: 'channels', required: true, displayName: 'Topics' },
  { key: 'disableComments', defaultValue: false },
  { key: 'isPriority' },
  { key: 'isDraft', defaultValue: false },
  { key: 'screenEnabled', defaultValue: false },
  { key: 'screenSummary' },
  { key: 'additionalFeedbackReceivers' },
];

const BlogEdit = ({ match, history, location }) => {
  const isCreating = match.params.type === 'create';
  const theme = useTheme();

  return (
    <CreateOrEdit
      isCreating={isCreating}
      viewableByRole="AvenueBlogEditor"
      pageTitle="Blog"
      location={location}
      fields={fields}
      ContentLoader={ContentLoader}
      onFetch={() => API.getBlogPost(match.params.id)}
      onDelete={API.deleteBlogPost}
      onCreate={API.createBlogPost}
      onEdit={API.updateBlogPost}
      onAfterCreate={(updatedArticle, article) =>
        afterBlogCreated(updatedArticle, article, isCreating, history)
      }
      onAfterDelete={() => history.push(`/feed`)}
      onAfterCancel={(blog) =>
        isCreating
          ? history.push(`/feed`)
          : history.push(`/feed/blog/${blog._doc}`)
      }
      deleteLoadingText="Unpublishing article..."
      deleteSuccessText="Article has been unpublished"
      deleteFailureText="Could not unpublish article"
    >
      <Breadcrumbs subject="blog" />
      <Container className="ie-flex-fix">
        <Column span={2}>
          <Box className="m-t-6 lg-m-t-0">
            <Title placeholder="Blog title (Max 90 characters)" />
            <Consumer>
              {({ updatedArticle }) => {
                let articleEditorClassNames = classNames(
                  'article-editor__content-container',
                  {
                    'article-editor__content-container--portrait-mode':
                      getIsPortraitHeroImage(updatedArticle),
                  }
                );
                return (
                  <div className={articleEditorClassNames}>
                    <div className="article-editor__hero-content">
                      <Hero
                        useVideo={true}
                        allowPortraitMode={true}
                        useAltText={true}
                      />
                      <ContentLabels />
                    </div>
                    <div className="max-width article-editor__text-content">
                      <Preamble placeholder="Blog preamble (max 500 characters)" />
                      <ImageGallery />
                      <Body placeholder="Blog body" form="blog" />
                    </div>
                  </div>
                );
              }}
            </Consumer>
            <ArticleMeta />
          </Box>
        </Column>
        <BlogAuthor />
      </Container>

      <Container>
        <Column>
          <TargetingOptions>
            <PublishSelector publishWithSegments={true} />
          </TargetingOptions>
        </Column>
      </Container>

      <Container>
        <Column>
          <Consumer>
            {({ updatedArticle }) => {
              const isDraft = updatedArticle.isDraft;
              return (
                <PublishOptions
                  onCancel="/feed"
                  subject="blog"
                  showDraftButton={isCreating || isDraft}
                  deleteButtonText="Unpublish"
                >
                  <Channels isCreating={isCreating} />
                  <hr className="m-t-6 m-b-6" />
                  <ScreenSelector />
                  <hr className="m-t-6 m-b-6" />
                  <DisableComments />
                  <hr className="m-t-6 m-b-6" />
                  <PriorityField />
                  <hr className="m-t-6 m-b-6" />
                  <AdditionalFeedbackReceivers />
                  <hr className="m-t-6 m-b-6" />
                  <PublishDateFifteenMinutesStep
                    sx={{ marginBottom: theme.spacing('sm') }}
                  />
                </PublishOptions>
              );
            }}
          </Consumer>
        </Column>
      </Container>
    </CreateOrEdit>
  );
};

export default BlogEdit;
