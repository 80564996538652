import './Checkbox.scss';

import classNames from 'classnames';
import { Component } from 'react';

class Checkbox extends Component {
  componentDidMount() {
    if (this.props.indeterminate === true) {
      this._setIndeterminate(true);
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.indeterminate !== this.props.indeterminate) {
      this._setIndeterminate(this.props.indeterminate);
    }
  }

  _setIndeterminate(indeterminate) {
    if (typeof indeterminate) {
      this.input.indeterminate = indeterminate;
    }
  }

  render() {
    const { indeterminate, color, children, ...props } = this.props;
    const checkboxClassName = classNames('checkbox', {
      [`checkbox--color-${color}`]: color,
    });
    return (
      <label className={checkboxClassName}>
        <input
          {...props}
          className="checkbox__input"
          ref={(input) => (this.input = input)}
        />{' '}
        {children}
      </label>
    );
  }
}

Checkbox.defaultProps = {
  type: 'checkbox',
};

export default Checkbox;
