import Resize from 'components/Resize';
import { Desktop, Mobile } from 'components/Responsive';
import ContentLoader from 'react-content-loader';

const styleDesktop = {
  height: '500px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const styleMobile = {
  height: '500px',
};

const DesktopContent = ({ width }) => {
  return (
    <ContentLoader
      className=""
      width="100%"
      height={900}
      preserveAspectRatio="none"
      style={styleDesktop}
    >
      <rect x={(width - 350) / 2} y={40} width={550} height={150} />
      <rect x={(width + 880) / 2} y={40} width={550} height={150} />
      <rect x={(width - 350) / 2} y={250} width={550} height={150} />
      <rect x={(width + 880) / 2} y={250} width={550} height={150} />
    </ContentLoader>
  );
};

const MobileContent = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={91}
    preserveAspectRatio="none"
    style={styleMobile}
  >
    <rect x={(width - 450) / 2} y={40} width={450} height={150} />
    <rect x={(width - 450) / 2} y={250} width={450} height={150} />
  </ContentLoader>
);

const Loader = () => (
  <Resize>
    {({ width }) => (
      <>
        <Desktop>
          <DesktopContent width={width} />
        </Desktop>
        <Mobile>
          <MobileContent width={width} />
        </Mobile>
      </>
    )}
  </Resize>
);

export default Loader;
