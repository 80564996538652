import { keyframes } from '@emotion/react';
import Box from '@mui/material/Box';

// Source: https://codepen.io/nzbin/pen/GGrXbp

const dotPulseBefore = keyframes`
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
`;

const dotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
`;

const dotPulseAfter = keyframes`
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
`;

const AIChatLoadingDots = () => {
  return (
    <Box sx={{ padding: '2px 16px' }}>
      <Box
        sx={(theme) => ({
          position: 'relative',
          left: '-9999px',
          width: '10px',
          height: '10px',
          borderRadius: '5px',
          backgroundColor: theme.colors.icon.brandBase,
          color: theme.colors.icon.brandBase,
          boxShadow: '9999px 0 0 -5px',
          animation: `${dotPulse} 1.5s infinite linear`,
          animationDelay: '0.25s',
          '&::before, &::after': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            width: '10px',
            height: '10px',
            borderRadius: '5px',
            backgroundColor: theme.colors.icon.brandBase,
            color: theme.colors.icon.brandBase,
          },
          '&::before': {
            boxShadow: '9984px 0 0 -5px',
            animation: `${dotPulseBefore} 1.5s infinite linear`,
            animationDelay: '0s',
          },
          '&::after': {
            boxShadow: '10014px 0 0 -5px',
            animation: `${dotPulseAfter} 1.5s infinite linear`,
            animationDelay: '0.5s',
          },
        })}
      />
    </Box>
  );
};

export { AIChatLoadingDots };
