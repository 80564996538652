import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { translations } from 'translations';
import { getSuggestions } from 'utils/misc/autoSuggest';

import Suggestion from '../api/models/Suggestion';
import {
  SearchSelect,
  SearchSelectOption,
  SearchSelectProps,
} from './SearchSelect';

export type UserMini = {
  id: string;
  name: string;
};

export type UserMiniSearchSelectProps = {
  selectedUsers: UserMini[];
  onChange: (selectedUsers: UserMini[]) => void;
} & Omit<
  SearchSelectProps<UserMini>,
  'search' | 'selectedOptions' | 'onChange'
>;

const UserMiniSearchSelect = ({
  selectedUsers,
  onChange,
  ...searchSelectProps
}: UserMiniSearchSelectProps) => {
  const search = async (value: string) => {
    const filter = { type: 'person' };

    const suggestions = ((await getSuggestions(value, filter, false)) ||
      []) as Suggestion[];

    const selectOptions = suggestions
      .map((suggestion) => {
        if (!suggestion.id || !suggestion.attributes) {
          return null;
        }
        const user: UserMini = {
          name: `${suggestion.attributes.firstName || ''} ${
            suggestion.attributes.lastName || ''
          }`.trim(),
          id: suggestion.id.substring(suggestion.id.indexOf('-') + 1),
        };
        const selectOption: SearchSelectOption<UserMini> = {
          name: user.name,
          value: user.id,
          data: user,
          element: (
            <Stack sx={{ wordBreak: 'break-word' }}>
              <Typography variant="body1">{user.name}</Typography>
              <Typography variant="body2">
                {suggestion.attributes.email || ''}
              </Typography>
            </Stack>
          ),
        };
        return selectOption;
      })
      .filter(Boolean) as SearchSelectOption<UserMini>[];

    return selectOptions;
  };

  const selectedOptions = useMemo(
    () =>
      selectedUsers.map((user) => {
        const selectOption: SearchSelectOption<UserMini> = {
          name: user.name,
          value: user.id,
          data: user,
        };
        return selectOption;
      }),
    [selectedUsers]
  );

  return (
    <SearchSelect<UserMini>
      placeholder={translations.searchEmployees}
      selectedOptions={selectedOptions}
      search={search}
      onChange={(selectedOptions: SearchSelectOption<UserMini>[]) => {
        const selectedUsers = selectedOptions
          .map((option) => option.data)
          .filter(Boolean) as UserMini[];
        onChange(selectedUsers);
      }}
      {...searchSelectProps}
    />
  );
};

export { UserMiniSearchSelect };
