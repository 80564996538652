import { AlertDialog } from 'componentsNew';
import { translations } from 'translations';

type DialogDeleteProps = {
  id: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DialogDelete = ({
  id,
  open,
  loading,
  onClose,
  onConfirm,
}: DialogDeleteProps) => {
  return (
    <AlertDialog
      id={id}
      open={open}
      type="critical"
      title={translations.manageContentActionsDeleteDialogTitle}
      paragraphs={[translations.manageContentActionsDeleteDialogText]}
      primaryButton={{
        loading: loading,
        disabled: loading,
        text: translations.manageContentActionsDelete,
        onClick: onConfirm,
      }}
      secondaryButton={{
        disabled: loading,
        text: translations.cancel,
        onClick: onClose,
      }}
      disableClose={loading}
      onClose={onClose}
    />
  );
};
export { DialogDelete };
