import './Image.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import {
  getImageSrc,
  isCMSImage,
  LANDSCAPE_IMAGE_SIZES,
  PORTRAIT_IMAGE_SIZES,
} from 'utils/cms';

import ExpandableImage from './ExpandableImage';

const imageTypes = {
  hero: {
    sizes: '(min-width: 1050px) 1050px, 100vw',
    defaultWidth: 1000,
    orientation: 'landscape',
  },
  heroPortrait: {
    sizes: '(min-width: 670px) 670px, 100vw',
    defaultHeight: 450,
    orientation: 'portrait',
  },
  contentLandscape: {
    sizes: '(min-width: 670px) 670px, 100vw',
    defaultWidth: 768,
    orientation: 'landscape',
  },
  contentPortrait: {
    sizes: '(min-width: 670px) 670px, 100vw',
    defaultHeight: 610,
    orientation: 'portrait',
  },
  feed: {
    sizes: '(min-width: 576px) 480px, 100vw',
    defaultWidth: 480,
    orientation: 'landscape',
  },
};

class Image extends PureComponent {
  state = {
    isLoading: true,
    isExpanded: false,
  };

  onLoad = (_) => {
    this.setState({ isLoading: false });
  };

  onError = () => {
    this.setState({ error: true });
  };

  getClassNames = () => {
    const { type, className, animate, expandable } = this.props;
    const { isLoading, error } = this.state;
    const imgType = imageTypes[type];

    let orientation = false;

    if (imgType) {
      orientation = imgType.orientation;
    }

    return classNames('image', {
      [className]: className,
      [`image--orientation-${orientation}`]: orientation,
      'image--is-loading': isLoading && !error,
      'image--loaded': isLoading === false || error,
      'image--animate': animate,
      'image--expandable': expandable,
    });
  };

  renderCMSImage = (src, imageClassNames) => {
    const { maxHeight, maxWidth, measureContentWidth, alt, type, expandable } =
      this.props;
    const imgType = imageTypes[type];
    const isLandscape = imgType.orientation === 'landscape';

    const landscapeSizes = (s) => s <= imgType.defaultWidth && s <= maxWidth;
    const portraitSizes = (s) => s <= imgType.defaultHeight && s <= maxHeight;

    const sizes = isLandscape
      ? LANDSCAPE_IMAGE_SIZES.filter(landscapeSizes)
      : PORTRAIT_IMAGE_SIZES.filter(portraitSizes);

    const defaultSuffix = imgType.defaultWidth || imgType.defaultHeight;
    const biggestSize = sizes[sizes.length - 1];
    let suffix;
    if (
      !(isLandscape && maxWidth < imgType.defaultWidth) &&
      !(!isLandscape && maxHeight < imgType.defaultHeight)
    ) {
      suffix = biggestSize >= defaultSuffix ? biggestSize : defaultSuffix;
    }

    const imageSrc = getImageSrc(src, suffix);
    // Ideally would need handlers for images with multiple srcSets.
    // Images with multiple scrSets now only use their source size values.
    const imageSrcSet = sizes
      .map((w, _i, self) => {
        if (self.includes(300 && 480) && type === 'contentLandscape') {
          return imageSrc;
        }
        return w <= 300 && type === 'contentLandscape'
          ? `${getImageSrc(src, w)}`
          : `${getImageSrc(src, w)} ${w}w`;
      })
      .join(', ');

    if (expandable) {
      return (
        <ExpandableImage
          onLoad={this.onLoad}
          onError={this.onError}
          alt={alt}
          title={alt}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          measureContentWidth={measureContentWidth}
          src={getImageSrc(src)}
          srcSet={imageSrcSet}
          sizes={imgType.sizes}
          className={imageClassNames}
        />
      );
    }
    return (
      <img
        onLoad={this.onLoad}
        onError={this.onError}
        src={imageSrc}
        srcSet={imageSrcSet}
        alt={alt}
        title={alt}
        sizes={imgType.sizes}
        className={imageClassNames}
      />
    );
  };

  render() {
    const {
      alt,
      src,
      maxWidth,
      maxHeight,
      measureContentWidth,
      expandable,
      type,
    } = this.props;
    const { error } = this.state;

    const imageClassNames = this.getClassNames();

    const imgType = imageTypes[type];
    if (isCMSImage(src) && imgType !== undefined) {
      return this.renderCMSImage(src, imageClassNames);
    }
    if (expandable) {
      return (
        <ExpandableImage
          onLoad={this.onLoad}
          onError={error ? this.onError : undefined}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          measureContentWidth={measureContentWidth}
          alt={alt}
          title={alt}
          src={getImageSrc(src)}
          className={imageClassNames}
        />
      );
    }

    return (
      <img
        onLoad={this.onLoad}
        onError={error ? this.onError : undefined}
        src={getImageSrc(src)}
        alt={alt}
        title={alt}
        className={imageClassNames}
      />
    );
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(imageTypes)),
  animate: PropTypes.bool,
};

Image.defaultProps = {
  animate: false,
  alt: '',
};

export default Image;
