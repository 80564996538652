import Box from 'components/Box';
import { FeedConsumer } from 'components/Context/Feed';
import ListWrapper from 'components/Feed/ListWrapper';
import Resize from 'components/Resize';
import { GAonFeedContentTypeClick } from 'utils/analytics';

import TabHeader from '../FeedTab';
import TabContent from '../TabContent';
import FeedLanguageSelector from './FeedLanguageSelector';

const contentTabs = [
  { text: 'All', value: 'all' },
  { text: 'Featured', value: 'topItem' },
  { text: 'Press', value: 'press' },
  { text: 'News', value: 'news' },
  { text: 'Blogs', value: 'blog' },
  { text: 'Stories', value: 'story' },
];

const getSelectedTab = (active) => {
  let currentTab = contentTabs[0];
  contentTabs.forEach((item) => {
    if (active.includes(item.value)) {
      currentTab = item;
    }
  });
  return currentTab;
};

const setSelectedTab = (nextTab, onFilterUpdated) => {
  const currentTab = contentTabs.find((item) => item.value === nextTab.value);
  GAonFeedContentTypeClick(currentTab.text);
  if (currentTab) {
    onFilterUpdated({
      type: currentTab.value,
      selected: false,
      value: true,
      increaseCounter: false,
    });
  }
};

const FeedContent = ({ isGlobalFeed }) => {
  return (
    <FeedConsumer>
      {({ active, onFilterUpdated, isLoading }) => (
        <Box className="m-t-5" wide={true}>
          {!isLoading && (
            <Resize>
              {({ width }) => {
                return width > 820 ? (
                  <TabHeader
                    color="grey"
                    tabs={contentTabs}
                    selected={getSelectedTab(active)}
                    setSelected={(nextTab) =>
                      setSelectedTab(nextTab, onFilterUpdated)
                    }
                  >
                    <TabContent isSelected={true}>
                      <ListWrapper
                        isGlobalFeed={isGlobalFeed}
                        isGlobalForMyFeed={
                          active.length === 1 && active.includes('global')
                        }
                      />
                    </TabContent>
                  </TabHeader>
                ) : (
                  <>
                    <select
                      className="content-dropdown"
                      defaultValue={getSelectedTab(active).value}
                      onChange={(e) =>
                        setSelectedTab(
                          { value: e.target.value },
                          onFilterUpdated
                        )
                      }
                    >
                      {contentTabs.map((tab, i) => {
                        return (
                          <option key={i} value={tab.value}>
                            {tab.text}
                          </option>
                        );
                      })}
                    </select>
                    <div className="language-select-feed-container">
                      <FeedLanguageSelector languageSelectorColor="black" />
                    </div>
                    <ListWrapper
                      isGlobalFeed={isGlobalFeed}
                      isGlobalForMyFeed={
                        active.length === 1 && active.includes('global')
                      }
                    />
                  </>
                );
              }}
            </Resize>
          )}
        </Box>
      )}
    </FeedConsumer>
  );
};

export default FeedContent;
