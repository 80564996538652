import './FAQ.scss';

import { getFAQ } from 'api/cms/public';
import Box from 'components/Box';
import Fetcher from 'components/Fetcher';
import Footer from 'components/Footer';
import { Column, Container } from 'components/Grid';
import PageTitle from 'components/PageTitle';
import Resize from 'components/Resize';
import Title from 'components/Title';

import Loader from './components/ContentLoader';
import Group from './components/Group';

const sortCategories = (a, b) => {
  if (a.sortIndex > b.sortIndex) {
    return 1;
  } else if (a.sortIndex < b.sortIndex) {
    return -1;
  } else {
    return 0;
  }
};

const getSortIndex = (nodes) => {
  const node = nodes.find(
    (node) => typeof node.parentSortIndex !== 'undefined'
  );
  if (node && node.parentSortIndex) {
    return node.parentSortIndex;
  } else {
    return 50;
  }
};

const formatData = (data) => {
  return Object.keys(data)
    .map((key) => data[key])
    .reduce((prev, curr) => {
      if (curr.category && curr.category.length) {
        curr.category.forEach((cat) => {
          prev[cat.title] = prev[cat.title] || [];
          prev[cat.title].push({
            question: curr.title,
            answer: curr.answer,
            sortIndex: curr.sortIndex || 50,
            parentSortIndex:
              curr.category.length === 1
                ? curr.parentSortIndex
                  ? curr.parentSortIndex
                  : undefined
                : undefined,
          });
        });
      }
      return prev;
    }, {});
};

const onLoading = () => (
  <>
    <Title color="white">Loading...</Title>
    <Resize>
      {({ width }) => [
        Array(4)
          .fill()
          .map((_, i) => (
            <Box className="faq__contentloader-box" key={i}>
              <Loader key={i} width={width} />
            </Box>
          )),
      ]}
    </Resize>
  </>
);

const FAQ = () => (
  <>
    <PageTitle titles={['FAQ']} />
    <div className="main-content">
      <Container className="faq" width="thin">
        <Column>
          <Fetcher source={getFAQ} reducer={formatData} onLoading={onLoading}>
            {({ response }) => (
              <>
                {Object.keys(response)
                  .map((key) => ({
                    questions: response[key],
                    sortIndex: getSortIndex(response[key]),
                    name: key,
                  }))
                  .sort(sortCategories)
                  .map((category, i) => (
                    <Group key={i} isFirstItem={i === 0} {...category} />
                  ))}
              </>
            )}
          </Fetcher>
        </Column>
      </Container>
    </div>
    <Footer />
  </>
);

export default FAQ;
