import './FileNameEntryModal.scss';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import EditText from '../EditText';

const FileNameEntryModal = ({ data, onConfirm, onCancel }) => {
  const { message, fileName, buttonLabel } = data || {};
  const [open, setOpen] = useState(!!message);
  const [textValue, setTextValue] = useState('');
  const [extension, setExtension] = useState('');

  useEffect(() => {
    !!message !== open && setOpen(!!message);
    if (!message) {
      setTextValue('');
    }

    setTextValue(fileName || '');
  }, [open, message, fileName]);

  useEffect(() => {
    if (buttonLabel !== 'Rename' || message.includes('folder')) {
      return;
    }
    const findLastDotIndex = fileName.lastIndexOf('.');
    if (findLastDotIndex === -1) {
      setTextValue(fileName);
      return;
    }
    const findExtensionText = fileName.substring(findLastDotIndex);
    const val = fileName.substring(0, findLastDotIndex);
    setExtension(findExtensionText);
    setTextValue(val);
  }, [buttonLabel, fileName, message]);

  const onClickConfirm = (e) => {
    e.stopPropagation();
    onConfirm(`${textValue}${extension}`);
  };
  const onClickCancel = (e) => {
    e.stopPropagation();
    setOpen(false);
    onCancel();
  };

  const changeTextValue = (value) => {
    setTextValue(value);
  };

  const onClickOutside = onClickCancel;
  return (
    <>
      {open ? (
        <div
          className="file-name-entry-modal__click-outside-handler"
          onClick={onClickOutside}
        />
      ) : null}
      {open ? (
        <div
          className="file-name-entry-modal__wrapper"
          onClick={onClickOutside}
        >
          <div
            className="file-name-entry-modal__container"
            onClick={(e) => e.stopPropagation()}
          >
            <p>{message}</p>
            <EditText
              autoFocus={!!message}
              value={textValue}
              onChange={changeTextValue}
              placeholder="Enter name"
            />
            {` ${extension}`}
            <div className="file-name-entry-modal__buttons-container">
              <button
                className="file-name-entry-modal__button file-name-entry-modal__button--light"
                onClick={onClickCancel}
              >
                Cancel
              </button>
              <button
                className="file-name-entry-modal__button"
                onClick={onClickConfirm}
              >
                {buttonLabel || 'Save'}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

FileNameEntryModal.propTypes = {
  data: PropTypes.shape({
    buttonLabel: PropTypes.string,
    fileName: PropTypes.string,
    message: PropTypes.string,
  }),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FileNameEntryModal;
