import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { organization } = settings.api;

export const getDivision = (id) =>
  createRequest({
    method: 'get',
    url: `${organization}/divisions/tree/${id}`,
    errorMessages: {
      '*': 'Failed to get division, please contact support for assistance.',
    },
  });

export const getDivisions = () =>
  createRequest({
    method: 'get',
    url: `${organization}/divisions/tree`,
    errorMessages: {
      '*': 'Failed to get divisions, please contact support for assistance.',
    },
  });

export const getDivisionsTree = () =>
  createRequest({
    method: 'get',
    url: `${organization}/divisions/divisions-tree`,
    errorMessages: {
      '*': 'Failed to get divisions, please contact support for assistance.',
    },
  });

export const getCountries = () =>
  createRequest({
    method: 'get',
    url: `${organization}/countries`,
    errorMessages: {
      '*': 'Failed to get countries, please contact support for assistance.',
    },
  });

export const getDepartments = () =>
  createRequest({
    method: 'get',
    url: `${organization}/departments`,
    errorMessages: {
      '*': 'Failed to get departments, please contact support for assistance.',
    },
  });

export const getSegments = () =>
  createRequest({
    method: 'get',
    url: `${organization}/segments`,
    errorMessages: {
      '*': 'Failed to get segments, please contact support for assistance.',
    },
  });

export const getSites = (query) =>
  createRequest({
    method: 'get',
    url: `${organization}/sites${query}`,
    errorMessages: {
      '*': 'Failed to get sites, please contact support for assistance.',
    },
  });

export const getCompanies = (query) =>
  createRequest({
    method: 'get',
    url: `${organization}/companies${query}`,
    errorMessages: {
      '*': 'Failed to get companies, please contact support for assistance.',
    },
  });

export const getProfiles = (query) =>
  createRequest({
    method: 'get',
    url: `${organization}/persons${query}`,
    errorMessages: {
      '*': 'Failed to get profiles, please contact support for assistance.',
    },
  });

export const getProfile = (id = 'me') =>
  createRequest({
    method: 'get',
    url: `${organization}/persons/${id}`,
    errorMessages: {
      '*': 'Failed to get profile, please contact support for assistance.',
    },
  });

export const getProfileImage = (id) =>
  createRequest({
    method: 'get',
    url: `${organization}/profilephoto/${id}`,
    errorMessages: {
      '*': 'Failed to get profile image, please contact support for assistance.',
    },
  });

export const getShortcuts = () =>
  createRequest({
    method: 'get',
    url: `${organization}/persons/me/shortcuts`,
    errorMessages: {
      '*': 'Failed to get shortcuts, please contact support for assistance.',
    },
  });

export const getWhitelistedUrls = () =>
  createRequest({
    method: 'get',
    url: `${organization}/whitelistedurls`,
    errorMessages: {
      '*': 'Failed to get Whitelisted urls, please contact support for assistance.',
    },
  });

export const setShortcuts = (shortcuts) =>
  createRequest({
    method: 'post',
    url: `${organization}/persons/me/profile-settings/shortcuts`,
    errorMessages: {
      '*': 'Failed to set shortcut order, please contact support for assistance.',
    },
    data: {
      data: shortcuts,
    },
  });

export const getProfileSettings = () =>
  createRequest({
    method: 'get',
    url: `${organization}/persons/me/profile-settings`,
    errorMessages: {
      '*': 'Failed to get settings, please contact support for assistance.',
    },
  });

export const updateProfileSettings = (
  id,
  relationships,
  subscribesToInsightsDatabase
) =>
  createRequest({
    method: 'put',
    url: `${organization}/persons/me/profile-settings`,
    data: {
      data: {
        type: 'profile-settings',
        id,
        relationships,
        subscribesToInsightsDatabase,
      },
    },
    errorMessages: {
      '*': 'Failed to update settings, please contact support for assistance.',
    },
  });

export const removeConsent = () =>
  createRequest({
    method: 'delete',
    url: `${organization}/persons/me/profile-settings`,
    errorMessages: {
      '*': 'Failed to remove consent, please contact support for assistance.',
    },
  });

export const addDefaultProfileSettingsShortcuts = () =>
  createRequest({
    method: 'post',
    url: `${organization}/persons/me/Shortcuts`,
    errorMessages: {
      '*': 'Failed to add default Profile shortcuts, please contact support for assistance.',
    },
  });
