import * as organizationApi from 'api/organization';
import * as organizationTransformers from 'api/organization/transformers';
import { Consumer } from 'components/CreateOrEditArticle';
import DivisionSelect from 'components/DivisionSelect/DivisionSelect';
import { useCallback, useEffect, useState } from 'react';
import { Division } from 'types';

interface ArticleDivisionProps {
  title?: string;
  multiSelect?: boolean;
  deSelectEnabled?: boolean;
}

const ArticleDivision = ({
  title,
  multiSelect,
  deSelectEnabled,
}: ArticleDivisionProps) => {
  const [divisions, setDivisions] = useState<Division[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchDivisions = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await organizationApi.getDivisions();
      const divisions =
        organizationTransformers.divisionsResponseToDivisions(response);
      setDivisions(divisions);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDivisions();
  }, [fetchDivisions]);

  if (isError) {
    return (
      <small>
        <i>Divisions could not be loaded right now. Please, try again later.</i>
      </small>
    );
  }

  return (
    <Consumer>
      {({ article, showInitialError, onChangeArticle }: any) => {
        return (
          <DivisionSelect
            title={title}
            divisions={divisions || []}
            multiSelect={multiSelect}
            placeholder={isLoading ? 'Loading...' : 'Select division'}
            deSelectEnabled={deSelectEnabled}
            showError={
              showInitialError &&
              !article.publishedInGlobal &&
              !article.publishedInDivision &&
              !article.publishedInDivisions
            }
            defaultSelected={{
              divisions: article.publishedInDivision
                ? [article.publishedInDivision]
                : article.publishedInDivisions || [],
              global: article.publishedInGlobal,
            }}
            onChange={(selected) => {
              onChangeArticle({
                publishedInGlobal: selected.global,
                publishedInDivisions: selected.divisions,
              });
            }}
          />
        );
      }}
    </Consumer>
  );
};

export default ArticleDivision;
