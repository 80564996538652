import PortalNode from 'api/models/PortalNode';
import classNames from 'classnames';
import ChildPageMenu from 'components/Portal/ChildPageMenu';
import InsightsDatabaseBodyBox from 'components/Portal/InsightsDatabase/BodyBox';
import InsightsDatabaseHeaderBox from 'components/Portal/InsightsDatabase/HeaderBox';
import PropTypes from 'prop-types';
import { Component } from 'react';
import getSortOrder from 'utils/misc/getSortOrder';
import sortOptions from 'utils/misc/sortOptions';

class InsightsListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSortOption: sortOptions[0],
      requestDetails: {
        search: '',
        sort: '-_score',
        limit: '5',
        filter: {
          geographicMarkets: '',
          productGroups: '',
          topics: '',
          types: '',
          verticalMarkets: '',
          yearsRange: '',
        },
      },
      searchRequest: null,
      searchDetails: null,
      filterDetails: null,
      isInitialState: true,
      isSearchUsed: false,
      isFilterUsed: false,
      isSearchFilterNotUsed: false,
      internetOfThingsRequest: '',
      smartHomesRequest: '',
      filter: '',
    };
  }

  getInitialState = (value) => {
    this.setState({ isInitialState: true }, () =>
      this.updateSearchState(value)
    );
  };

  changeHandler = (value) => {
    this.setState({ isInitialState: false }, () =>
      this.updateSearchState(value)
    );
  };

  updateSearchState = (data) => {
    let {
      requestDetails,
      searchDetails,
      filterDetails,
      isInitialState,
      isSearchUsed,
      isFilterUsed,
    } = this.state;

    if (data && data.type === 'sort') {
      requestDetails.sort = data.value;
    }

    if (data && data.type === 'search') {
      requestDetails.search = data.value;
      searchDetails = true;
      isSearchUsed = data.value !== '';
    }
    if (data && data.type === 'filter') {
      let hasAnyFilter = false;
      for (let key in requestDetails.filter) {
        if (data.value === 'resetFilters') {
          requestDetails.filter = {
            geographicMarkets: '',
            productGroups: '',
            topics: '',
            types: '',
            verticalMarkets: '',
            yearsRange: '',
          };
          this.setState(
            (prevState) => ({
              selectedSortOption: prevState.selectedSortOption,
              isSearchUsed: isSearchUsed,
              isFilterUsed: false,
              isInitialState: false,
              requestDetails: {
                ...prevState.requestDetails,
                search: requestDetails.search,
                filter: requestDetails.filter,
                limit: '10',
              },
            }),
            () => this.getArticles()
          );
          return;
        } else if (data.value[key] && data.value[key].length > 0) {
          requestDetails.filter[key] = data.value[key][0].value;
          hasAnyFilter = true;
        } else {
          requestDetails.filter[key] = [];
        }
      }
      isFilterUsed = hasAnyFilter;
      filterDetails = true;
    }
    const isSearchFilterNotUsed =
      isInitialState && !isFilterUsed && !isSearchUsed;
    const isFilterAndSearchDataGot =
      (filterDetails && data.type === 'search') ||
      (searchDetails && data.type === 'filter') ||
      data.type === 'sort';
    if (isSearchFilterNotUsed) {
      requestDetails.limit = '5';
    } else {
      requestDetails.limit = '10';
    }
    this.setState(
      (prevState) => ({
        searchDetails: searchDetails,
        filterDetails: filterDetails,
        isInitialState: isInitialState,
        isSearchUsed: isSearchUsed,
        isFilterUsed: isFilterUsed,
        isSearchFilterNotUsed: isSearchFilterNotUsed,
        requestDetails: {
          ...prevState.requestDetails,
          search: requestDetails.search,
          filter: requestDetails.filter,
          limit: requestDetails.limit,
          sort: this.getSortValue(
            data,
            isInitialState,
            prevState.requestDetails.sort,
            isSearchUsed
          ),
        },
      }),
      () => {
        if (isFilterAndSearchDataGot) {
          this.getArticles();
        }
      }
    );
  };

  getSortValue = (data, isInitialState, sort, isSearchUsed) => {
    if (data && data.type === 'sort') {
      return data.value;
    }

    if (isInitialState) {
      return '-published';
    }

    if (isSearchUsed && data.type !== 'filter') {
      return '-_score';
    }

    return sort;
  };

  getArticles = () => {
    const searchString = `/search?filter[query]=${encodeURIComponent(
      this.state.requestDetails.search
    )}${
      this.state.requestDetails.sort
        ? `&sort=${this.state.requestDetails.sort}`
        : ''
    }&page[limit]=${
      this.state.requestDetails.limit
    }&filter[filtertypes]=[portalarticle]&filter[portaltypes]=[innovation]&filter[template]=insights-content&filter[insightstype.id]=${
      this.state.requestDetails.filter.types
    }&filter[verticalmarket.id]=${
      this.state.requestDetails.filter.verticalMarkets
    }&filter[publishedyear]=${
      this.state.requestDetails.filter.yearsRange
    }&filter[topic.id]=${
      this.state.requestDetails.filter.topics
    }&filter[productgroup.id]=${
      this.state.requestDetails.filter.productGroups
    }&filter[geographicmarket.id]=${
      this.state.requestDetails.filter.geographicMarkets
    }`;
    this.setState({ searchRequest: searchString });
  };

  getAdditionalLists = (value) => {
    const searchStringSH = `/search?filter[query]=&sort=-published&page[limit]=3&filter[filtertypes]=[portalarticle]&filter[portaltypes]=[innovation]&filter[template]=insights-content&filter[topic.id]=${value.sh}`;
    const searchStringIOT = `/search?filter[query]=&sort=-published&page[limit]=3&filter[filtertypes]=[portalarticle]&filter[portaltypes]=[innovation]&filter[template]=insights-content&filter[topic.id]=${value.iot}`;
    this.setState({
      smartHomesRequest: searchStringSH,
      internetOfThingsRequest: searchStringIOT,
    });
  };

  filterHandler = (value) => {
    this.setState({ filter: value });
  };

  handleSortChange = (selectedSortOption) => {
    const sort = getSortOrder(selectedSortOption);

    this.changeHandler({ type: 'sort', value: sort });

    this.setState({ selectedSortOption });
  };

  resetSortOptions = () => {
    this.setState({ selectedSortOption: sortOptions[0] });
  };

  render() {
    const { page } = this.props;
    const {
      isFilterUsed,
      searchRequest,
      internetOfThingsRequest,
      smartHomesRequest,
      isSearchFilterNotUsed,
      filter,
      selectedSortOption,
    } = this.state;
    const { template } = page.attributes;
    const isInsightsDatabase = template === 'insights-listing';
    const insightsDatabaseClassName = classNames('insights-database', {
      [`insights-database--filtered`]: isFilterUsed,
    });
    return (
      <>
        {isInsightsDatabase && (
          <div className={insightsDatabaseClassName}>
            <InsightsDatabaseHeaderBox
              page={page}
              useFilter={filter}
              onChange={this.changeHandler}
              onMount={this.getInitialState}
              onAdditionalLists={this.getAdditionalLists}
              resetSortOptions={this.resetSortOptions}
            >
              <ChildPageMenu page={page} />
            </InsightsDatabaseHeaderBox>
            <InsightsDatabaseBodyBox
              page={page}
              setFilter={this.filterHandler}
              showAll={this.changeHandler}
              searchRequest={searchRequest}
              internetOfThingsRequest={internetOfThingsRequest}
              smartHomesRequest={smartHomesRequest}
              isInitialState={isSearchFilterNotUsed}
              sortOptions={sortOptions}
              handleSortChange={this.handleSortChange}
              selectedSortOption={selectedSortOption}
            />
          </div>
        )}
      </>
    );
  }
}

InsightsListing.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
};

export default InsightsListing;
