import { Consumer } from 'components/CreateOrEditArticle';
import Body from 'components/CreateOrEditArticle/components/Body';
import ColorSelect from 'components/CreateOrEditArticle/components/ColorSelect';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import Title from 'components/CreateOrEditArticle/components/Title';

const DividerBlockEditor = () => (
  <Consumer>
    {({
      validationParameters: {
        title: { maxLength },
      },
    }) => {
      const titlePlaceholder = `Title (max ${maxLength} characters)`;
      return (
        <>
          <Title placeholder={titlePlaceholder} />
          <Body placeholder="Body" />
          <ColorSelect />
          <SortIndex />
        </>
      );
    }}
  </Consumer>
);

export default DividerBlockEditor;
