import 'rc-slider/assets/index.css';
import './Range.scss';

import PropTypes from 'prop-types';
import Slider from 'rc-slider';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const RCRange = createSliderWithTooltip(Slider.Range);

const formatMarks = (items) =>
  items.reduce((obj, { name, value }) => {
    obj[value] = {
      label: name,
    };
    return obj;
  }, {});

const Range = ({ steps, selected, onChange }) => {
  const marks = formatMarks(steps);
  return (
    <div className="range">
      <div className="range__container">
        <RCRange
          key={selected.join('')}
          allowCross={false}
          marks={marks}
          defaultValue={selected}
          dots={false}
          tipFormatter={(value) => marks[value].label}
          onAfterChange={([from, to]) => {
            const values = steps.slice(from, to + 1);
            const names = steps.map((x) => x.name).slice(from, to + 1);
            if (onChange) {
              onChange(values, names);
            }
          }}
          max={steps.length - 1}
        />
      </div>
    </div>
  );
};

Range.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Range;
