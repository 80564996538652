import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { WhitelistValidationLink } from 'componentsNew';

export type LinkListItem = {
  id: string;
  title?: string;
  descriptions?: string[];
  subDescriptions?: string[];
  icon?: React.ReactNode;
  element?: React.ReactNode;
  elementExcludedFromLink?: boolean;
  href?: string;
  to?: string;
  target?: React.HTMLAttributeAnchorTarget;
  tooltip?: string;
  divider?: boolean;
};

type LinkListProps = {
  id?: string;
  items: LinkListItem[];
  sx?: SxProps<Theme>;
  onClick?: (item: LinkListItem) => void;
};

const LinkList = ({ id, items, sx, onClick }: LinkListProps) => {
  return (
    <List id={id} sx={sx}>
      {items.map((item, index) => (
        <ListItem
          key={item.id}
          id={id && `${id}-item-${index}`}
          divider={item.divider}
          sx={(theme) => ({
            padding: 0,
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: theme.colors.surface.actionTertiaryHover,
            },
          })}
        >
          <WhitelistValidationLink
            href={item.href || item.to || ''}
            target={item.target}
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: theme.spacing('sm'),
              borderRadius: 0,
              textDecoration: 'none',
              '&:hover': {
                '.MuiTypography-root': {
                  color: theme.colors.text.actionHover,
                },
              },
            })}
            onClick={() => onClick && onClick(item)}
          >
            <Box>
              <Typography
                variant="body1"
                title={item.tooltip}
                sx={(theme) => ({
                  color: theme.colors.text.secondary,
                  fontWeight: 'bold',
                  wordBreak: 'break-word',
                  lineHeight: 1.4,
                })}
              >
                {item.title}
              </Typography>

              {item.descriptions &&
                item.descriptions.map((description, index) => (
                  <Typography
                    key={`link-list-item-description-${index}`}
                    variant="body2"
                    sx={(theme) => ({
                      color: theme.colors.text.secondary,
                      wordBreak: 'break-word',
                      lineHeight: 1.225,
                    })}
                  >
                    {description}
                  </Typography>
                ))}
              {item.subDescriptions &&
                item.subDescriptions.map((subDescription, index) => (
                  <Typography
                    key={`link-list-item-sub-description-${index}`}
                    variant="body2"
                    sx={(theme) => ({
                      color: theme.colors.text.tertiary,
                      wordBreak: 'break-word',
                      lineHeight: 1.225,
                    })}
                  >
                    {subDescription}
                  </Typography>
                ))}
            </Box>
            {item.icon && (
              <Box sx={() => ({ flexShrink: 0, marginLeft: 1 })}>
                {item.icon}
              </Box>
            )}
            {item.element && !item.elementExcludedFromLink && (
              <Box
                sx={(theme) => ({
                  margin: `0 0 0 ${theme.spacing('sm')}`,
                  flexShrink: 0,
                })}
              >
                {item.element}
              </Box>
            )}
          </WhitelistValidationLink>
          {item.element && item.elementExcludedFromLink && (
            <Box
              sx={(theme) => ({
                margin: `0 ${theme.spacing('sm')}`,
                flexShrink: 0,
              })}
            >
              {item.element}
            </Box>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export { LinkList };
