import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { videos } = settings.api;

export const getCategories = () =>
  createRequest({
    method: 'get',
    url: `${videos}/categories`,
    errorMessages: {
      '*': 'Failed to get video categories, please contact support for assistance.',
    },
  });

export const getPlaylist = (id) =>
  createRequest({
    method: 'get',
    url: `${videos}/video-items/${id}?page[limit]=5`,
    errorMessages: {
      '*': 'Failed to get playlist, please contact support for assistance.',
    },
  });

export const getAuthenticatedUrlFromApi = (id) =>
  createRequest({
    method: 'get',
    url: `${videos}/video-items/${id}/url`,
    errorMessages: {
      '*': 'Failed to get video, please contact support for assistance.',
    },
  });
