import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class HistoryListener extends Component {
  componentDidMount() {
    //on page load
    this.addPortalsClasses(this.props.history.location.pathname);

    //on route change
    this.unlisten = this.props.history.listen(() => {
      this.addPortalsClasses(this.props.history.location.pathname);
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  addPortalsClasses = (path) => {
    const classList = document.body.classList;

    //remove old classes with aadw- prefix
    let classesToRemove = [];
    for (const classItem of classList) {
      if (classItem.includes('aadw-')) {
        classesToRemove.push(classItem);
      }
    }

    for (const item of classesToRemove) {
      classList.remove(item);
    }

    //add new page specific classes
    if (path.includes('/portals/innovation')) {
      classList.add('aadw-innovation-portal');
    }
    if (path.includes('/portals/strategy')) {
      classList.add('aadw-strategy-portal');
    }
    if (path.includes('/portals/codeofconduct')) {
      classList.add('aadw-codeofconduct-portal');
    }
  };

  render() {
    return <></>;
  }
}

export default withRouter(HistoryListener);
