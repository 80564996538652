import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Link as ReactRouterLink } from 'react-router-dom';

/**
 * TypographyWithLink takes a string as "children" and considers anything
 * wrapped in double curly brackets as a link placeholder. The text inside
 * the curly brackets is used as link label for a ReactRouterLink to "to".
 *
 * For example: If the "children" prop is "Read more news {{here}}!"
 * and the "to" prop is "/feed", the text "Read more news here!" will
 * be rendered and the "here" part will be a link to "/feed".
 *
 */

type TypographyWithLinkProps = {
  to?: string;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  sx?: SxProps<Theme>;
  variant?: TypographyTypeMap['props']['variant'];
  children: string;
  onClick?: () => void;
};

const TypographyWithLink = ({
  to,
  href,
  target,
  sx,
  variant,
  children,
  onClick,
}: TypographyWithLinkProps) => {
  const linkRegex = new RegExp(/{{(.*?)}}/g);
  const linkPlaceholder = (children.match(linkRegex) || [])[0];

  if (!linkPlaceholder) {
    return (
      <Typography variant={variant} sx={sx}>
        {children}
      </Typography>
    );
  }

  const textLink = linkPlaceholder.slice(2, -2);
  const [textBefore, textAfter] = children.split(linkPlaceholder);

  return (
    <Typography variant={variant} sx={sx}>
      {textBefore}
      <Link
        {...(to ? { to: to, component: ReactRouterLink } : { href: href })}
        onClick={onClick}
        target={target}
      >
        {textLink}
      </Link>
      {textAfter}
    </Typography>
  );
};

export { TypographyWithLink };
