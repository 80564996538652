import URL from 'url';

const isMSFormsEmbedUrl = (url: string) => {
  const urlObj = URL.parse(url, true);
  return urlObj.hostname === 'forms.office.com' && Boolean(urlObj.query['id']);
};

const isPowerBIEmbedUrl = (url: string) => {
  const urlObj = URL.parse(url, true);
  return (
    urlObj.hostname === 'app.powerbi.com' &&
    urlObj.pathname === '/reportEmbed' &&
    Boolean(urlObj.query['reportId'])
  );
};

const isPlayerenceEmbedUrl = (url: string) => {
  const urlObj = URL.parse(url, false);
  return urlObj.hostname === 'app.playerence.com';
};

const getReportIdFromPowerBIEmbedUrl = (url: string) => {
  const urlObj = URL.parse(url, true);
  const isValid =
    urlObj.hostname === 'app.powerbi.com' &&
    urlObj.pathname === '/reportEmbed' &&
    Boolean(urlObj.query['reportId']);
  if (!isValid) {
    return null;
  }
  const reportId = urlObj.query['reportId'];
  return Array.isArray(reportId) ? reportId[0] : reportId;
};

export {
  getReportIdFromPowerBIEmbedUrl,
  isMSFormsEmbedUrl,
  isPlayerenceEmbedUrl,
  isPowerBIEmbedUrl,
};
