import './RelatedContentEditor.scss';

import Box from 'components/Box';
import Text from 'components/Editable/Text';
import { ReactComponent as Plus } from 'components/Icon/Plus.svg';
import PropTypes from 'prop-types';
import { Component } from 'react';

const ITEM_ONE = 1;
const ITEM_TWO = 2;

class RelatedContentEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      relatedContentOne: { title: '', description: '', url: '' },
      relatedContentTwo: { title: '', description: '', url: '' },
      hasMoreContent: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadContent();
  }

  componentDidUnMount() {
    this._isMounted = false;
  }

  loadContent = () => {
    const { contents } = this.props;
    if (this._isMounted) {
      this.setState({ isLoaded: true });
    }
    if (contents && contents.length) {
      if (this._isMounted) {
        this.setState({ relatedContentOne: contents[0] });
      }
      if (contents.length > 1) {
        if (this._isMounted) {
          this.setState({ relatedContentTwo: contents[1] });
        }
        if (contents[1].title) {
          if (this._isMounted) {
            this.setState({ hasMoreContent: true });
          }
        }
      }
    }
  };

  addMore = () => {
    if (this._isMounted) {
      this.setState({ hasMoreContent: true });
    }
  };

  onBlur = (fieldName, output, itemNumber) => {
    const { onChange } = this.props;
    const { relatedContentOne, relatedContentTwo } = this.state;
    if (itemNumber === ITEM_ONE) {
      relatedContentOne[fieldName] = output;
      if (this._isMounted) {
        this.setState({ relatedContentOne });
      }
    }
    if (itemNumber === ITEM_TWO) {
      relatedContentTwo[fieldName] = output;
      if (this._isMounted) {
        this.setState({ relatedContentTwo });
      }
    }
    let relatedContent = [];
    if (!this.isEmptyContent(relatedContentOne)) {
      relatedContent.push(relatedContentOne);
    }
    if (!this.isEmptyContent(relatedContentTwo)) {
      relatedContent.push(relatedContentTwo);
    }
    onChange(relatedContent);
  };

  isEmptyContent = (content) => {
    const emptyContent = { title: '', description: '', url: '' };
    if (
      JSON.stringify(content) === JSON.stringify(emptyContent) ||
      JSON.stringify(content) === {}
    ) {
      return true;
    }
    return false;
  };

  validate = (content) => {
    const { validators } = this.props;

    if (!validators) {
      return null;
    }

    for (let i = 0; i < validators.length; ++i) {
      const validator = validators[i];
      if (validator) {
        const error = validator(content);

        if (error) {
          return error;
        }
      }
    }

    return null;
  };

  render() {
    const { isLoaded, hasMoreContent, relatedContentOne, relatedContentTwo } =
      this.state;

    return (
      <>
        {isLoaded && (
          <Box className="p-b-2">
            <Text
              placeholder="Content title (max 40 characters)"
              maxLength={40}
              content={relatedContentOne.title}
              onBlur={(output) => this.onBlur('title', output, ITEM_ONE)}
            />
            <Text
              placeholder="Content description (max 90 characters)"
              maxLength={90}
              content={relatedContentOne.description}
              onBlur={(output) => this.onBlur('description', output, ITEM_ONE)}
            />
            <Text
              placeholder="URL"
              maxLength={999}
              content={relatedContentOne.url}
              onBlur={(output) => this.onBlur('url', output, ITEM_ONE)}
            />
          </Box>
        )}
        {!hasMoreContent && (
          <div onClick={this.addMore}>
            <Box className="related-content-editor__add">
              <Plus />
              <p>Add more Related Content</p>
            </Box>
          </div>
        )}
        {hasMoreContent && (
          <Box className="m-t-5 p-b-2">
            <Text
              placeholder="Content title (max 40 characters)"
              maxLength={40}
              content={relatedContentTwo.title}
              onBlur={(output) => this.onBlur('title', output, ITEM_TWO)}
            />
            <Text
              placeholder="Content description (max 90 characters)"
              maxLength={90}
              content={relatedContentTwo.description}
              onBlur={(output) => this.onBlur('description', output, ITEM_TWO)}
            />
            <Text
              placeholder="URL"
              maxLength={999}
              content={relatedContentTwo.url}
              onBlur={(output) => this.onBlur('url', output, ITEM_TWO)}
            />
          </Box>
        )}
      </>
    );
  }
}

RelatedContentEditor.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  validators: PropTypes.arrayOf(PropTypes.func),
};

export default RelatedContentEditor;
