import './SortingControl.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import { ReactComponent as SortIcon } from 'components/Icon/Sort.svg';
import PropTypes from 'prop-types';
import { Component } from 'react';

class SortingControl extends Component {
  state = {
    isOptionsShown: false,
    options: this.props.options,
  };

  hideOptions = () => {
    this.setState({ isOptionsShown: false });
  };

  selectOption = (option) => {
    this.props.handleSortChange(Object.assign({}, option));
    this.hideOptions();
  };

  isActiveOption = (option, selectedSortOption) => {
    return classNames({
      active: option.label === selectedSortOption.label,
    });
  };

  render() {
    const { isOptionsShown } = this.state;
    const { sortOptions = [], className, selectedSortOption } = this.props;

    const controlClasses = classNames('sorting-control', className);

    return (
      <>
        <div className={controlClasses}>
          <span className="sorting-control__label">Sort&nbsp;by:</span>
          <span className="sorting-control__type">
            {selectedSortOption.label}
          </span>
          <span
            className="sorting-control__order"
            onClick={() => this.setState({ isOptionsShown: true })}
          >
            <SortIcon />
            {isOptionsShown && (
              <ClickAwayListener onClickAway={this.hideOptions}>
                <ul className="sorting-control__options">
                  {sortOptions.map((option) => (
                    <li
                      key={option.label}
                      onClick={() => this.selectOption(option)}
                      className={this.isActiveOption(
                        option,
                        selectedSortOption
                      )}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              </ClickAwayListener>
            )}
          </span>
        </div>
      </>
    );
  }
}

const optionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  sortType: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']).isRequired,
});

SortingControl.propTypes = {
  className: PropTypes.string,
  sortOptions: PropTypes.arrayOf(optionShape),
  selectedSortOption: optionShape,
  handleSortChange: PropTypes.func.isRequired,
};

export default SortingControl;
