import './NavigationItem.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const NavigationItem = ({
  to,
  type = 'inbound',
  children,
  color = '',
  exact,
  activePath,
  onClick,
  disabled = false,
}) => {
  const navigationClassName = classNames('navigationitem', {
    [`navigationitem--${color}`]: color,
  });
  return (
    <li className={navigationClassName}>
      {type === 'outbound' ? (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          className="navigationitem__link"
          onClick={onClick}
        >
          {children}
        </a>
      ) : (
        <NavLink
          isActive={() =>
            exact
              ? window.location.pathname === to
              : window.location.pathname.indexOf(activePath || to) === 0
          }
          activeClassName="navigationitem__link--active"
          disabled={disabled}
          className="navigationitem__link"
          to={to}
          exact={exact}
          onClick={onClick}
        >
          {children}
        </NavLink>
      )}
    </li>
  );
};

NavigationItem.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  activePath: PropTypes.string,
  exact: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default NavigationItem;
