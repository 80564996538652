import * as API from 'api/cms/some';
import Box from 'components/Box';
import CreateOrEdit from 'components/CreateOrEditArticle';
import Body from 'components/CreateOrEditArticle/components/Body';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import LinkUrl from 'components/CreateOrEditArticle/components/LinkUrl';
import PublishOptions from 'components/CreateOrEditArticle/components/PublishOptions';
import Title from 'components/CreateOrEditArticle/components/Title';
import { Column, Container } from 'components/Grid';
import { RouteComponentProps } from 'react-router-dom';

import ContentLoader from '../ContentLoader';

interface MatchParams {
  id: string;
  type: string;
}

type SomeEditProps = RouteComponentProps<MatchParams>;

const SomeEdit = ({ match, history, location }: SomeEditProps) => {
  const isCreating = match.params.type === 'create';

  const fields = [
    { key: 'title', required: true, displayName: 'Title', maxLength: 90 },
    { key: 'heroImage' },
    { key: 'authorLinkUrl', maxLength: 300 },
    { key: 'postLinkUrl', maxLength: 300 },
    { key: 'body' },
    { key: 'publishedDate' },
  ];

  return (
    <CreateOrEdit
      isCreating={isCreating}
      viewableByRole="AvenueAdmin"
      pageTitle="Some"
      location={location}
      fields={fields}
      ContentLoader={ContentLoader}
      onFetch={() => API.getSome()}
      onDelete={API.deleteSome}
      onCreate={API.createSome}
      onEdit={API.updateSome}
      onAfterCreate={() => {
        history.push(`/`);
      }}
      onAfterDelete={() => {
        history.push('/');
      }}
      onAfterCancel={() => {
        history.push('/');
      }}
    >
      <Container className="m-t-6 ie-flex-fix">
        <Column span={2}>
          <Box className="m-t-6 lg-m-t-0">
            <Title placeholder="Title (max 90 characters, will not display)" />
            <Hero useVideo={false} useAltText={true} />
            <div className="max-width">
              <Body placeholder="Some text" showSideButtons={false} />
            </div>
            <LinkUrl
              editable={false}
              placeholder="Post link url"
              fieldName="postLinkUrl"
            />
            <LinkUrl
              editable={false}
              placeholder="Author link url"
              fieldName="authorLinkUrl"
            />
          </Box>
        </Column>
      </Container>
      <Container>
        <Column span={2}>
          <PublishOptions
            onCancel="/"
            subject="some"
            hideDelete={false}
          ></PublishOptions>
        </Column>
      </Container>
    </CreateOrEdit>
  );
};

export default SomeEdit;
