import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import * as videoApi from 'api/video';
import { VideoItemResponse } from 'api/video/types';
import Iframe from 'components/Iframe';
import Modal from 'components/Modal';
import { useEffect, useState } from 'react';

import { VideoItem } from './VideoItem';

export interface ModalState {
  isVisible: boolean;
  iframeUrl: string | null;
}

interface VideoListProps {
  id: string;
  title?: string;
  videoListId: string;
  sx?: SxProps<Theme>;
}

const VideoList = ({ id, title, videoListId, sx }: VideoListProps) => {
  const [videoList, setVideoList] = useState<VideoItemResponse[]>([]);
  const [modalState, setModalState] = useState<ModalState>({
    isVisible: false,
    iframeUrl: null,
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await videoApi.getPlaylist(videoListId);
        if (response?.data?.data) {
          const result: VideoItemResponse[] = response.data.data;
          setVideoList(result);
        }
      } catch {}
    })();
  }, [videoListId]);

  if (videoList.length === 0) {
    return null;
  }

  return (
    <Stack
      id={id}
      sx={[
        (theme) => ({
          width: '100%',
          padding: theme.spacing('md'),
          paddingRight: theme.spacing('xs'),
          gap: theme.spacing('xs'),
          backgroundColor: theme.colors.surface.primary,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {title && (
        <Typography variant="h2" fontWeight="normal" component="h2">
          {title}
        </Typography>
      )}
      {modalState.isVisible && modalState.iframeUrl && (
        <Modal
          onClose={() => setModalState({ isVisible: false, iframeUrl: null })}
          closeButton={true}
          size="md"
          verticalAlign={true}
          noBorderRadius={true}
        >
          <Iframe title="Title" src={modalState.iframeUrl} />
        </Modal>
      )}
      <List
        id={`${id}-list`}
        sx={() => ({
          overflow: 'auto',
        })}
      >
        {videoList.map((video, index) => (
          <ListItem
            id={`${id}-list-item-${index}`}
            sx={{ padding: '2px' }}
            key={`video-item-${index}`}
            divider={index < videoList.length - 1}
          >
            <VideoItem
              id={video.id}
              title={video.attributes.title}
              thumbUrl={video.attributes.thumbUrl}
              setModalState={setModalState}
            />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export { VideoList };
