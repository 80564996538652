import { Theme } from '@mui/material/styles';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import React from 'react';

type TypographyWithMaxLinesProps = {
  maxLines: number;
  variant?: TypographyTypeMap['props']['variant'];
  component?: React.ElementType;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

const TypographyWithMaxLines = ({
  maxLines,
  variant,
  component,
  sx,
  children,
}: TypographyWithMaxLinesProps) => {
  return (
    <Typography
      variant={variant}
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: maxLines,
          wordBreak: 'break-word',
        },
      ]}
      {...(component && { component: component })}
    >
      {children}
    </Typography>
  );
};

export { TypographyWithMaxLines };
