import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';
import OnlyWithRole from 'components/OnlyWithRole';

import { Consumer } from '..';

const PinnedTabs = () => (
  <Consumer>
    {({ onToggleArticleValue, article: { isPreselected, depth } }) =>
      depth === 0 && (
        <OnlyWithRole viewableByRole="AvenueSuperuser">
          <Flex>
            <Checkbox
              defaultChecked={isPreselected}
              id="preselectedTab"
              onChange={() => onToggleArticleValue('isPreselected')}
            />
            <label htmlFor="preselectedTab" className="m-l-3">
              Preselected
            </label>
          </Flex>
          <hr className="m-t-6 m-b-6" />
        </OnlyWithRole>
      )
    }
  </Consumer>
);

export default PinnedTabs;
