import * as myMomentApi from 'api/cms/MyMoment';
import { MyMomentResponse } from 'api/cms/MyMoment/types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const MyMomentContext = React.createContext<{
  myMoment: MyMomentResponse | null;
  isLoading: boolean;
  isError: boolean;
  fetchMyMoment: () => void;
} | null>(null);

const { Consumer: MyMomentConsumer } = MyMomentContext;

type MyMomentProviderProps = {
  children: React.ReactNode;
};

export function MyMomentProvider({ children }: MyMomentProviderProps) {
  const [myMoment, setMyMoment] = useState<MyMomentResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchMyMoment = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await myMomentApi.getMyMoment();
      const result = response?.data?.data;
      if (result) {
        setMyMoment(result as MyMomentResponse);
      }
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMyMoment();
  }, [fetchMyMoment]);

  const contextValue = useMemo(
    () => ({
      myMoment,
      isLoading,
      isError,
      fetchMyMoment,
    }),
    [myMoment, isLoading, isError, fetchMyMoment]
  );

  return (
    <MyMomentContext.Provider value={contextValue}>
      {children}
    </MyMomentContext.Provider>
  );
}

export const useMyMoment = () => {
  const context = useContext(MyMomentContext);
  if (!context) {
    throw new Error('MyMomentContext is not defined');
  }
  return context;
};

export { MyMomentConsumer };
