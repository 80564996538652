import Box from 'components/Box';
import { Consumer } from 'components/CreateOrEditArticle';
import BackgroundColor from 'components/CreateOrEditArticle/components/BackgroundColor';
import Body from 'components/CreateOrEditArticle/components/Body';
import ContainerChildBlockSelector from 'components/CreateOrEditArticle/components/ContainerChildBlockSelector';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import ImageContainedSwitch from 'components/CreateOrEditArticle/components/ImageContainedSwitch';
import LinkButtonText from 'components/CreateOrEditArticle/components/LinkButtonText';
import LinkUrl from 'components/CreateOrEditArticle/components/LinkUrl';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import ReverseBlockSwitch from 'components/CreateOrEditArticle/components/ReverseBlockSwitch';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import TextColor from 'components/CreateOrEditArticle/components/TextColor';
import Title from 'components/CreateOrEditArticle/components/Title';
import { Column, Container } from 'components/Grid';

const selectString = (side, leftOption, rightOption) =>
  side === 'left' ? leftOption : side === 'right' ? rightOption : leftOption;

const shouldClearBackgroundColorField = (side, updatedArticle) => {
  const { backgroundColorLeft, backgroundColorRight } = updatedArticle;
  return (
    (side === 'left' &&
      updatedArticle.displayLeft === 'media' &&
      !updatedArticle.isImageFullyVisibleLeft &&
      backgroundColorLeft) ||
    (side === 'right' &&
      updatedArticle.displayRight === 'media' &&
      !updatedArticle.isImageFullyVisibleRight &&
      backgroundColorRight)
  );
};

const shouldRenderBackgroundColorField = (side, updatedArticle) => {
  return (
    (side === 'left' &&
      updatedArticle.displayLeft === 'media' &&
      updatedArticle.isImageFullyVisibleLeft) ||
    (side === 'right' &&
      updatedArticle.displayRight === 'media' &&
      updatedArticle.isImageFullyVisibleRight)
  );
};

const renderBodyField = (side) => {
  return (
    <Body
      placeholder="Body"
      fieldName={selectString(side, 'bodyLeft', 'bodyRight')}
    />
  );
};

const renderBackgroundColorField = (side) => {
  return (
    <BackgroundColor
      placeholder="Background color (#hex)"
      fieldName={selectString(
        side,
        'backgroundColorLeft',
        'backgroundColorRight'
      )}
    />
  );
};

const renderPreambleField = (side) => {
  return (
    <Preamble
      placeholder="Preamble"
      fieldName={selectString(side, 'preambleLeft', 'preambleRight')}
    />
  );
};

const renderTextColorField = (side) => {
  return (
    <TextColor
      placeholder="Text color (#hex)"
      fieldName={selectString(side, 'textColorLeft', 'textColorRight')}
    />
  );
};

const renderTitleField = (side, validationParameters) => {
  const fieldName = selectString(side, 'titleLeft', 'titleRight');
  const {
    [fieldName]: { maxLength },
  } = validationParameters;
  const placeholder = `Title (max ${maxLength} characters)`;
  return <Title placeholder={placeholder} fieldName={fieldName} />;
};

const renderLinkButtonTextField = (side) => {
  return (
    <LinkButtonText
      placeholder="Link Button Text"
      fieldName={selectString(
        side,
        'linkButtonTextLeft',
        'linkButtonTextRight'
      )}
    />
  );
};

const renderHeroField = (side, isHero) => {
  return (
    <Hero
      useVideo={true}
      useAltText={true}
      minWidth={1050}
      imageFieldName={selectString(side, 'heroImageLeft', 'heroImageRight')}
      videoFieldName={selectString(side, 'heroVideoLeft', 'heroVideoRight')}
      altTextFieldName={selectString(
        side,
        'heroAltTextLeft',
        'heroAltTextRight'
      )}
      imageWidthFieldName={selectString(
        side,
        'heroImageWidthLeft',
        'heroImageWidthRight'
      )}
      isHero={isHero}
    />
  );
};

const renderLinkUrlField = (side) => {
  return (
    <LinkUrl
      placeholder="Link URL"
      fieldName={selectString(side, 'linkUrlLeft', 'linkUrlRight')}
    />
  );
};

const renderImageContainedSwitchField = (side) => {
  return (
    <ImageContainedSwitch
      fieldName={selectString(
        side,
        'isImageFullyVisibleLeft',
        'isImageFullyVisibleRight'
      )}
    />
  );
};

const clearBackgroundColorIfNeeded = (onChangeArticle, updatedArticle) => {
  const updatedArticleState = {};
  if (shouldClearBackgroundColorField('left', updatedArticle)) {
    updatedArticleState.backgroundColorLeft = '';
  }
  if (shouldClearBackgroundColorField('right', updatedArticle)) {
    updatedArticleState.backgroundColorRight = '';
  }
  if (
    Boolean(updatedArticleState.backgroundColorLeft) ||
    Boolean(updatedArticleState.backgroundColorRight)
  ) {
    setTimeout(
      // It is not permitted to call this function during render.
      () => onChangeArticle(updatedArticleState),
      0
    );
  }
};

const renderFields = (
  side,
  customContent,
  updatedArticle,
  validationParameters
) => {
  switch (customContent) {
    case 'text':
      return (
        <>
          {renderTitleField(side, validationParameters)}
          {renderPreambleField(side)}
          {renderBodyField(side)}
          {renderBackgroundColorField(side)}
          {renderTextColorField(side)}
        </>
      );
    case 'media':
      return (
        <>
          {renderHeroField(side, false)}
          {renderImageContainedSwitchField(side)}
          {shouldRenderBackgroundColorField(side, updatedArticle) &&
            renderBackgroundColorField(side)}
        </>
      );
    case 'infographic':
      return (
        <>
          {renderTitleField(side, validationParameters)}
          {renderBodyField(side)}
          {renderLinkUrlField(side)}
          {renderBackgroundColorField(side)}
          {renderTextColorField(side)}
          {renderLinkButtonTextField(side)}
        </>
      );
    default:
      return 'Please select an option';
  }
};

const CustomBlockEditor = () => {
  return (
    <>
      <Box className="m-b-4">
        <Consumer>
          {({
            onChangeArticle,
            updatedArticle,
            validationParameters,
            updatedArticle: {
              title,
              titleLeft,
              titleRight,
              displayLeft,
              displayRight,
            },
          }) => {
            const updatedArticleTitle = (titleLeft || '') + (titleRight || '');
            if (updatedArticleTitle !== title) {
              setTimeout(
                // It is not permitted to call this function during render.
                () => onChangeArticle({ title: updatedArticleTitle }),
                0
              );
            }
            clearBackgroundColorIfNeeded(onChangeArticle, updatedArticle);
            return (
              <Container padding={false} forceColumns={2}>
                <Column noPadding>
                  <p>Select left-side content</p>
                  <ContainerChildBlockSelector side="left">
                    {renderFields(
                      'left',
                      displayLeft,
                      updatedArticle,
                      validationParameters
                    )}
                  </ContainerChildBlockSelector>
                </Column>
                <Column noPadding>
                  <p>Select right-side content</p>
                  <ContainerChildBlockSelector side="right">
                    {renderFields(
                      'right',
                      displayRight,
                      updatedArticle,
                      validationParameters
                    )}
                  </ContainerChildBlockSelector>
                </Column>
              </Container>
            );
          }}
        </Consumer>
      </Box>
      <ReverseBlockSwitch />
      <SortIndex />
    </>
  );
};

export default CustomBlockEditor;
