import ContentLoader from 'react-content-loader';

const Loader = ({ width = '1050' }) => {
  return (
    <ContentLoader
      className="hero--contentloader"
      width={width}
      height={160}
      preserveAspectRatio="none"
      style={{ height: '160px' }}
    >
      <rect x={0} y={0} width={width} height={160} />
    </ContentLoader>
  );
};

export default Loader;
