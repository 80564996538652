import Typography from '@mui/material/Typography';

type PageTitleProps = { children: React.ReactNode };

const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <Typography
      variant="h1"
      sx={(theme) => ({ color: theme.colors.text.brand })}
    >
      {children}
    </Typography>
  );
};

export { PageTitle };
