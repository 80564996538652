import './View.scss';

import { getStory } from 'api/cms/story/';
import { ArticleFooterAndComments } from 'components/ArticleFooterAndComments';
import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import Error from 'components/Error';
import Fetcher from 'components/Fetcher';
import { Column, Container } from 'components/Grid';
import Iframe from 'components/Iframe';
import Img from 'components/Image';
import LanguageMessage from 'components/LanguageMessage';
import SameTopic from 'components/sameTopic/SameTopic';
import EditLink from 'components/ViewArticle/EditLink';
import { isArticlePublishDateBeforeFaultyGADataDate } from 'componentsNew/PageViews';
import { FC, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Language, Translation } from 'types';
import { GAitemNotFound } from 'utils/analytics';
import { createEmbedUrl } from 'utils/cms/parseVideoUrls';

import ContentLoader from '../ContentLoader';
import StoryContent from './storyContent/StoryContent';

interface MatchParams {
  id: string;
  url: string;
}

const onError = (status: number, message: string) => {
  GAitemNotFound(status, message, 'story');
  return <Error message={message} status={status} redirectOn404={true} />;
};

const Story: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const [isAutoTranslateMessageVisible, setIsAutoTranslateMessgeVisible] =
    useState<boolean>(true);

  const history = useHistory();

  const resetLanguage = (response: any) => {
    const updatedLanguage = { locale: 'en', text: 'English' };
    onLanguageChange(updatedLanguage, response);
  };

  const onLanguageChange = (language: Language, response: any) => {
    const translations =
      typeof response.translations === 'object'
        ? (response.translations as Translation[])
        : [];

    const selectedTranslation = translations.find(
      (translation) => translation.locale === language.locale
    );
    if (selectedTranslation) {
      history.push(`/feed/story/${selectedTranslation.id}`);
      setIsAutoTranslateMessgeVisible(true);
      return true;
    }
    return false;
  };

  const closeAutoTranslateMessage = () =>
    setIsAutoTranslateMessgeVisible(false);

  const getArticleIdForComments = (
    response: any,
    originalArticleId: string
  ) => {
    const translations =
      typeof response.translations === 'object'
        ? (response.translations as Translation[])
        : [];
    const englishArticle = translations.find(
      (translation) => translation.locale === 'en'
    );
    return englishArticle ? englishArticle.id : originalArticleId;
  };

  const renderImageOrVideo = (response: any) => {
    return response.heroImage ? (
      <>
        <div className="story__hero-image">
          <div className="story__hero-image-gradient"></div>
          <Img
            src={response.heroImage}
            alt={response.heroAltText}
            className="story-hero"
          />
        </div>
      </>
    ) : response.heroVideo ? (
      <>
        <div className="story__hero-image-background"></div>

        <div className="story__hero-video">
          <Iframe src={createEmbedUrl(response.heroVideo)} title="story" />
        </div>
      </>
    ) : null;
  };

  return (
    <Fetcher
      source={getStory}
      param={match.params.id}
      onLoading={ContentLoader}
      onError={onError}
    >
      {({ response }: any) => {
        const channelsExists =
          response.channels && response.channels.length > 0;

        return (
          <>
            {response.currentLocale !== 'en' &&
              isAutoTranslateMessageVisible && (
                <LanguageMessage
                  currentLocale={response.currentLocale}
                  onLanguageReset={() => resetLanguage(response)}
                  onClose={closeAutoTranslateMessage}
                />
              )}

            <div className="story">
              <Container>
                <Column className="story__header">
                  <Breadcrumbs
                    className="m-t-0 m-b-0"
                    path="/feed"
                    includeHome={true}
                    items={[{ title: 'Feed' }, { title: response.title }]}
                  />
                  <EditLink
                    viewableByRole="AvenueSuperuser"
                    url={match.url}
                    className="story-edit-link"
                  >
                    Edit page
                  </EditLink>
                </Column>
              </Container>

              {renderImageOrVideo(response)}

              <Container>
                <Column>
                  <Box className="story">
                    <div className="story__box-container">
                      <StoryContent
                        response={response}
                        title={response.title}
                        translations={response.translations}
                        currentLocale={response.currentLocale}
                        onLanguageChange={(updatedLanguage) =>
                          onLanguageChange(updatedLanguage, response)
                        }
                        channels={response.channels}
                        preamble={response.preamble}
                        body={response.body}
                        subTitle={response.subTitle}
                        createdOn={response.publishedDate || response.createdOn}
                        readingTime={response.readingTime}
                        heroVideo={response.heroVideo}
                        publishedInDivisions={response.publishedInDivisions}
                        publishedInSegments={response.publishedInSegments}
                        publishedInCountries={response.publishedInCountries}
                        imageGalleryItems={response.imageGalleryItems}
                      />
                    </div>
                    <div className="story__comments-wrapper">
                      <div className="story__comments">
                        <ArticleFooterAndComments
                          disableComments={response.disableComments}
                          created={response.publishedDate || response.createdOn}
                          informationOwner={response.informationOwner}
                          author={response.author}
                          editor={response.lastModifiedBy}
                          updated={response.modifiedOn}
                          articleId={response._doc}
                          articleIdForLikesCommentsAndViews={getArticleIdForComments(
                            response,
                            match.params.id
                          )}
                          title={response.title}
                          type={response.type}
                          additionalFeedbackReceivers={
                            response.additionalFeedbackReceivers
                          }
                          disablePageViews={
                            !isArticlePublishDateBeforeFaultyGADataDate(
                              response.publishedDate || response.createdOn
                            )
                          }
                        />
                      </div>
                    </div>
                  </Box>
                  {channelsExists && (
                    <SameTopic
                      className="story__same-topic-container"
                      locale={response.currentLocale}
                      channel={response.channels[0]}
                    />
                  )}
                </Column>
              </Container>
            </div>
          </>
        );
      }}
    </Fetcher>
  );
};

export default Story;
