import './FolderView.scss';

import SortingControl from 'components/SortingControl';
import PropTypes from 'prop-types';
import { Fragment as ReactFragment, useState } from 'react';

import FolderEntry from '../FolderEntry';
import ContentLoader from './ContentLoader';

const sortOptions = [
  {
    label: 'A-Z',
    sortType: 'byName',
    sortOrder: 'asc',
  },
  {
    label: 'Z-A',
    sortType: 'byName',
    sortOrder: 'desc',
  },
  {
    label: 'Newest',
    sortType: 'byModifiedDate',
    sortOrder: 'desc',
  },
  {
    label: 'Oldest',
    sortType: 'byModifiedDate',
    sortOrder: 'asc',
  },
];

const getCompareFn = (type, asc) => {
  const fieldName = {
    byModifiedDate: 'lastModified',
    byName: 'name',
  }[type];
  const order = asc ? 1 : -1;
  return ({ [fieldName]: a }, { [fieldName]: b }) =>
    order * (a === b ? 0 : a >= b ? 1 : -1);
};

const getFileSortHandler =
  ({ sortType, sortOrder }) =>
  (items) => {
    const isAscending = sortOrder === 'asc';
    const compareFn = getCompareFn(sortType, isAscending);
    // Group folders & files in the listing.
    // Show folders last in reversed sorts.
    const { folders, files } = items.reduce(
      (acc, item) =>
        (item.type === 'folder'
          ? acc.folders.push(item)
          : acc.files.push(item)) && acc,
      { folders: [], files: [] }
    );
    files.sort(compareFn);
    folders.sort(compareFn);
    if (sortType === 'byModifiedDate') {
      return isAscending ? [...files, ...folders] : [...folders, ...files];
    }
    return isAscending ? [...folders, ...files] : [...files, ...folders];
  };

const FolderView = ({
  isEditing,
  items,
  folderPath,
  loadingFailed = false,
  onClick,
  rootName,
  topic,
}) => {
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
  const fileSortHandler = getFileSortHandler(selectedSortOption);
  const onClickFolderEntry = (e, item) => {
    onClick(e, item);
  };
  return (
    <div className="folder-view__container">
      <div className="folder-view__path">
        <button
          className="folder-view__path-segment"
          onClick={(e) =>
            onClick(e, {
              type: 'root',
            })
          }
        >
          {rootName}
        </button>
        {folderPath.map((pathItem) => (
          <ReactFragment key={pathItem.id}>
            <span className="folder-view__path-separator">&gt;</span>
            <button
              className="folder-view__path-segment"
              onClick={(e) =>
                onClick(e, {
                  type: 'folder',
                  item: pathItem,
                })
              }
            >
              {pathItem.name}
            </button>
          </ReactFragment>
        ))}
        <SortingControl
          className="folder-view__sorting-control"
          sortOptions={sortOptions}
          selectedSortOption={selectedSortOption}
          handleSortChange={setSelectedSortOption}
        />
      </div>
      {loadingFailed ? (
        <p className="folder-view__message">Could not load folder.</p>
      ) : items === null ? (
        <ContentLoader width="100%" />
      ) : items.length === 0 ? (
        <p className="folder-view__message">This folder is empty.</p>
      ) : (
        fileSortHandler(items).map((item) => (
          <FolderEntry
            key={item.id}
            isEditing={isEditing}
            item={item}
            onClick={onClickFolderEntry}
            topic={topic}
          />
        ))
      )}
    </div>
  );
};

const itemShape = PropTypes.shape({
  id: PropTypes.string,
  filename: PropTypes.string,
  modifiedDate: PropTypes.string,
});

FolderView.propTypes = {
  isEditing: PropTypes.bool,
  folderPath: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(itemShape),
    PropTypes.exact(null),
  ]),
  loadingFailed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  rootName: PropTypes.string.isRequired,
};

export default FolderView;
