export interface Language {
  locale: string;
  text: string;
  isAvailable?: boolean;
}

export interface Translation {
  locale: string;
  id: string;
}

export interface Division {
  id: string;
  name: string;
}

export interface Segment {
  id: string;
  name: string;
}

export interface Department {
  id: string;
  name: string;
}

export interface Site {
  id: string;
  name: string;
}

export interface Company {
  id: string;
  name: string;
}

export interface Country {
  id: string;
  name: string;
}

export enum RequestStatus {
  Idle,
  Loading,
  Success,
  Failure,
}
