export const isThirtyYearCelebrationBannerActive = () => {
  const CELEBRATION_YEAR = 2024;
  const CELEBRATION_MONTH = 10;
  const currentDate = new Date();
  if (
    currentDate.getFullYear() === CELEBRATION_YEAR &&
    currentDate.getMonth() === CELEBRATION_MONTH
  ) {
    return true;
  }
  return false;
};
