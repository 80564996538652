import './SHHouse.scss';

import { ReactComponent as FilledRoof } from 'components/Portal/StrategyHouse/SHHouse/RoofFilled.svg';
import { ReactComponent as BorderRoof } from 'components/Portal/StrategyHouse/SHHouse/RoofLines.svg';
import { ReactComponent as MobileFilledRoof } from 'components/Portal/StrategyHouse/SHHouse/RoofMobileFilled.svg';
import { ReactComponent as MobileBorderRoof } from 'components/Portal/StrategyHouse/SHHouse/RoofMobileLines.svg';
import { Dispatch, FC, SetStateAction, useRef } from 'react';

interface SHHouseProps {
  changeActiveState: (activeBox: number) => void;
  activeSHButton: number;
  content: {
    title: string;
    textContent: string;
    button: string;
    prevButton: string;
    nextButton: string;
  }[];
  isRoofFilled: boolean;
  setIsRoofFilled: Dispatch<SetStateAction<boolean>>;
  width: number;
}

const SHHouse: FC<SHHouseProps> = ({
  changeActiveState,
  activeSHButton,
  content,
  isRoofFilled,
  setIsRoofFilled,
  width,
}) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);

  const roofFilled = (isFilled: boolean) => {
    if (!textRef.current) {
      return;
    }
    if (isFilled) {
      textRef.current.className =
        'sh-house__vision-filled-active sh-house__house-box-vision';
    } else {
      textRef.current.className =
        'sh-house__vision-border-active sh-house__house-box-vision';
    }

    setIsRoofFilled(isFilled);
  };

  const changeRoofColor = () => {
    const white = (
      <div onClick={() => changeActiveState(1)}>
        <p className="sh-house__vision-filled-active sh-house__house-box-vision">
          {content[1].button}
        </p>
        <div className="sh-house__house-box-roof-filled">
          <FilledRoof />
        </div>
      </div>
    );

    const mobileWhite = (
      <div onClick={() => changeActiveState(1)}>
        <p className="sh-house__vision-filled-active sh-house__house-box-vision">
          {content[1].button}
        </p>
        <div className="sh-house__house-box-roof-filled">
          <MobileFilledRoof />
        </div>
      </div>
    );

    const transparent = (
      <div
        onMouseEnter={() => roofFilled(true)}
        onMouseLeave={() => roofFilled(false)}
        onClick={() => changeActiveState(1)}
      >
        <p
          ref={textRef}
          className=" sh-house__vision-border-active sh-house__house-box-vision"
        >
          {content[1].button}
        </p>

        {isRoofFilled !== true ? (
          <div className="sh-house__house-box-roof-border">
            <BorderRoof />
          </div>
        ) : (
          <div className="sh-house__house-box-roof-filled">
            <FilledRoof />
          </div>
        )}
      </div>
    );

    const mobileTransparent = (
      <div
        onMouseEnter={() => roofFilled(true)}
        onMouseLeave={() => roofFilled(false)}
        onClick={() => changeActiveState(1)}
      >
        <p
          ref={textRef}
          className=" sh-house__vision-border-active sh-house__house-box-vision"
        >
          {content[1].button}
        </p>

        {isRoofFilled !== true ? (
          <div className="sh-house__house-box-roof-border">
            <MobileBorderRoof />
          </div>
        ) : (
          <div className="sh-house__house-box-roof-filled">
            <MobileFilledRoof />
          </div>
        )}
      </div>
    );

    if (activeSHButton !== 1) {
      if (width < 768) {
        return mobileTransparent;
      }
      return transparent;
    }

    if (width < 768) {
      return mobileWhite;
    }
    return white;
  };

  return (
    <div className="sh-house">
      <div className="sh-house__container">
        <h1 className="sh-house__title">The ASSA ABLOY Group strategy</h1>

        <div className="sh-house__roof-wrapper">{changeRoofColor()}</div>

        <div
          onClick={() => {
            changeActiveState(2);
            setIsRoofFilled(false);
          }}
          className={`sh-house__house-box-full-width${
            activeSHButton === 2 ? ' sh-house__active' : ''
          }`}
        >
          <p className="sh-house__house-box-text">{content[2].button}</p>
        </div>
        <div className="sh-house__house-box-part-width-container">
          <div
            onClick={() => {
              changeActiveState(3);
              setIsRoofFilled(false);
            }}
            className={`sh-house__house-box-part-width${
              activeSHButton === 3 ? ' sh-house__active' : ''
            }`}
          >
            <p
              className="sh-house__house-box-part-width-text"
              dangerouslySetInnerHTML={{ __html: content[3].button }}
            ></p>
          </div>
          <div
            onClick={() => {
              changeActiveState(4);
              setIsRoofFilled(false);
            }}
            className={`sh-house__house-box-part-width${
              activeSHButton === 4 ? ' sh-house__active' : ''
            }`}
          >
            <p
              className="sh-house__house-box-part-width-text"
              dangerouslySetInnerHTML={{ __html: content[4].button }}
            ></p>
          </div>
          <div
            onClick={() => {
              changeActiveState(5);
              setIsRoofFilled(false);
            }}
            className={`sh-house__house-box-part-width${
              activeSHButton === 5 ? ' sh-house__active' : ''
            }`}
          >
            <p
              className="sh-house__house-box-part-width-text"
              dangerouslySetInnerHTML={{ __html: content[5].button }}
            ></p>
          </div>
        </div>
        <div
          onClick={() => {
            changeActiveState(6);
            setIsRoofFilled(false);
          }}
          className={`sh-house__house-box-full-width${
            activeSHButton === 6 ? ' sh-house__active' : ''
          }`}
        >
          <p className="sh-house__house-box-text">{content[6].button}</p>
        </div>
        <div
          onClick={() => {
            changeActiveState(7);
            setIsRoofFilled(false);
          }}
          className={`sh-house__house-box-full-width${
            activeSHButton === 7 ? ' sh-house__active' : ''
          }`}
        >
          <p className="sh-house__house-box-text">{content[7].button}</p>
        </div>
      </div>
    </div>
  );
};

export default SHHouse;
