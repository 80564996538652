const extractImageId = (body) => {
  let attachmentIds = [];

  let m;
  const regex = /<img src=".*?\/static\/node\/([a-zA-Z0-9]*)("|\/\d{3,4}")/g;

  do {
    m = regex.exec(body);
    if (m) {
      attachmentIds.push(m[1]);
    }
  } while (m);
  return attachmentIds;
};

export default extractImageId;
