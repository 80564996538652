import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as jobsApi from 'api/jobs';
import { Job, JobListingFilter, JobListingItem } from 'api/jobs/types';
import { useUser } from 'components/Context/User';
import { Icon } from 'componentsNew';
import { useSnackbar } from 'contextNew/Snackbar';
import { useSearchParams } from 'hooks/useSearchParams';
import { Page, PageTitle } from 'layoutNew';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'translations';

import * as helpers from './helpers';
import { JobsRequestParams } from './helpers';
import { JobContent } from './JobContent/JobContent';
import { FilterParam, JobFilter } from './JobFilter/JobFilter';
import { JobList } from './JobList/JobList';

const INITIAL_JOB_LIST: {
  items: JobListingItem[];
  total: number;
} = {
  items: [],
  total: 0,
};

const INITIAL_PAGINATION = {
  page: 1,
  rowsPerPage: 15,
};

const Jobs = () => {
  const [isJobContentLoading, setIsJobContentLoading] =
    useState<boolean>(false);
  const [jobContentById, setJobContentById] = useState<Record<string, Job>>({});
  const [isJobContentError, setIsJobContentError] = useState<boolean>(false);

  const [isJobListLoading, setIsJobListLoading] = useState<boolean>(true);
  const [isJobListError, setIsJobListError] = useState<boolean>(false);

  const [jobList, setJobList] =
    useState<typeof INITIAL_JOB_LIST>(INITIAL_JOB_LIST);

  const [availableFilter, setAvailableFilter] =
    useState<JobListingFilter | null>(null);

  const [pagination, setPagination] =
    useState<typeof INITIAL_PAGINATION>(INITIAL_PAGINATION);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const user = useUser();
  const urlSearchParams = useSearchParams();
  const { showSnackbar } = useSnackbar();

  const idParam = useMemo(() => {
    const id = urlSearchParams.get('id');
    return id ? Number(id) : null;
  }, [urlSearchParams]);

  const filterParam = useMemo(() => {
    const filter = urlSearchParams.get('filter');
    return filter ? (JSON.parse(filter) as FilterParam) : null;
  }, [urlSearchParams]);

  const userParam = useMemo(() => {
    const departmentName = user.departmentName as string | undefined;
    const countryName = user.countryName as string | undefined;
    return { departmentName, countryName };
  }, [user.departmentName, user.countryName]);

  const fetchJobList = useCallback(async (params: JobsRequestParams) => {
    const jobList = INITIAL_JOB_LIST;
    try {
      setIsJobListLoading(true);
      setIsJobListError(false);
      const queryString = helpers.getRequestQueryString(params);
      const response = await jobsApi.getJobs(queryString);
      if (!response) {
        throw Error();
      }
      jobList.total = response.data.meta.page.total;
      jobList.items = response.data.data as JobListingItem[];
    } catch {
      setIsJobListError(true);
    } finally {
      setIsJobListLoading(false);
      return jobList;
    }
  }, []);

  const fetchJobContent = useCallback(async (params: { id: number }) => {
    let jobContent: Job | null = null;
    try {
      setIsJobContentLoading(true);
      setIsJobContentError(false);
      const response = await jobsApi.getJob(params.id);
      if (!response) {
        throw Error();
      }
      jobContent = response.data.data as Job;
    } catch {
      setIsJobContentError(true);
    } finally {
      setIsJobContentLoading(false);
      return jobContent;
    }
  }, []);

  const fetchAvailableFilter = useCallback(async () => {
    try {
      const response = await jobsApi.getJobsFilter();
      if (!response?.data?.data) {
        throw Error();
      }
      return response.data.data as JobListingFilter;
    } catch {
      showSnackbar({ type: 'error', text: translations.jobsFilterFetchError });
      return null;
    }
  }, [showSnackbar]);

  const handleJobListItemClick = useCallback(
    (id: number) => {
      urlSearchParams.addOrUpdate('id', `${id}`);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    [urlSearchParams]
  );

  const handlePaginationChange = useCallback(
    async (newPagination: { page: number; rowsPerPage: number }) => {
      setPagination(newPagination);
      const newListContent = await fetchJobList({
        user: userParam,
        filter: filterParam || {},
        pagination: newPagination,
      });
      setJobList(newListContent);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    [fetchJobList, filterParam, userParam]
  );

  const handleFilterChange = useCallback(
    async (filter: FilterParam) => {
      const newListContent = await fetchJobList({
        user: userParam,
        filter: filter || {},
        pagination: pagination,
      });
      setJobList(newListContent);
      setPagination(INITIAL_PAGINATION);
    },
    [fetchJobList, pagination, userParam]
  );

  useEffect(() => {
    async function fetchInitialJobList() {
      const isAlreadyLoaded = jobList.items.length > 0;
      const isNotVisible = !isDesktop && Boolean(idParam);

      if (user.isLoading || isAlreadyLoaded || isNotVisible) {
        return;
      }
      const newJobList = await fetchJobList({
        user: userParam,
        filter: filterParam || {},
        pagination: INITIAL_PAGINATION,
      });
      setJobList(newJobList);
      if (
        isDesktop &&
        !idParam &&
        newJobList.items &&
        newJobList.items.length
      ) {
        const defaultJobId = newJobList.items[0].id;
        urlSearchParams.addOrUpdate('id', `${defaultJobId}`);
      }
    }
    fetchInitialJobList();
  }, [
    fetchJobList,
    filterParam,
    isDesktop,
    jobList.items.length,
    idParam,
    urlSearchParams,
    user.isLoading,
    userParam,
  ]);

  useEffect(() => {
    async function fetchInitialJobContent() {
      if (!idParam || jobContentById[idParam]) {
        return;
      }
      const newJobContent = await fetchJobContent({ id: idParam });
      if (!newJobContent) {
        return;
      }
      setJobContentById((prevJobContentById) => ({
        ...prevJobContentById,
        [idParam]: newJobContent,
      }));
    }
    fetchInitialJobContent();
  }, [fetchJobContent, jobContentById, idParam]);

  useEffect(() => {
    async function fetchInitialAvailableFilter() {
      const newAvailableFilter = await fetchAvailableFilter();
      if (!newAvailableFilter) {
        return;
      }
      setAvailableFilter(newAvailableFilter);
    }
    fetchInitialAvailableFilter();
  }, [fetchAvailableFilter]);

  if (isDesktop) {
    return (
      <Page title={[translations.jobs]}>
        <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
          <PageTitle>{translations.jobs}</PageTitle>
          <Divider />
          {availableFilter && (
            <>
              <JobFilter
                availableFilter={availableFilter}
                onChange={handleFilterChange}
              />
              <Divider />
            </>
          )}
          <Stack sx={() => ({ flexDirection: 'row' })}>
            <JobList
              sx={{ width: '30%', flexShrink: 0 }}
              selectedItemId={idParam || undefined}
              items={jobList.items}
              total={jobList.total}
              pagination={pagination}
              isLoading={isJobListLoading}
              isError={isJobListError}
              onPaginationChange={handlePaginationChange}
              onItemClick={handleJobListItemClick}
            />
            <JobContent
              sx={{ flexGrow: 1 }}
              job={idParam ? jobContentById[idParam] : undefined}
              isLoading={isJobContentLoading || (!idParam && isJobListLoading)}
              isError={
                isJobContentError && !isJobListLoading && !isJobListError
              }
            />
          </Stack>
        </Stack>
      </Page>
    );
  }

  if (idParam) {
    return (
      <Page title={[translations.jobs]}>
        <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
          <PageTitle>{translations.jobs}</PageTitle>
          <Divider />
          <Button
            variant="text"
            sx={{ alignSelf: 'baseline' }}
            startIcon={<Icon type="arrowLongLeft" color="brandBase" />}
            onClick={() => urlSearchParams.remove('id')}
          >
            {translations.back}
          </Button>
          <Divider />
          <JobContent
            job={jobContentById[idParam]}
            isLoading={isJobContentLoading}
            isError={isJobContentError}
          />
        </Stack>
      </Page>
    );
  }

  return (
    <Page title={[translations.jobs]}>
      <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
        <PageTitle>{translations.jobs}</PageTitle>
        <Divider />
        {availableFilter && (
          <>
            <JobFilter
              availableFilter={availableFilter}
              onChange={handleFilterChange}
            />
            <Divider />
          </>
        )}
        <JobList
          items={jobList.items}
          total={jobList.total}
          pagination={pagination}
          isLoading={isJobListLoading}
          isError={isJobListError}
          onPaginationChange={handlePaginationChange}
          onItemClick={handleJobListItemClick}
        />
      </Stack>
    </Page>
  );
};

export { Jobs };
