import { mapRelationships } from 'utils/misc/mapRelationships';

export const createObject = (
  DataModel,
  { id, attributes, relationships, type },
  included
) => {
  const includes = mapRelationships(relationships, included);

  // create a copy of the input object with new data
  return new DataModel(id, attributes, includes, type);
};

export const convertResponse = (DataModel, response) => {
  const { data, included, meta, links } = response.data;

  const dataIsArray = Array.isArray(data);

  const dataObjects = dataIsArray
    ? data.map((item) => createObject(DataModel, item, included))
    : createObject(DataModel, data, included);

  return {
    data: dataObjects,
    meta,
    links,
  };
};
