import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';

class ButtonModalWrapper extends Component {
  state = {
    showModal: false,
    awaitingYes: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showModal = () => {
    this.setState({
      ...this.state,
      showModal: true,
    });
  };

  hideModal = () => {
    if (!this.state.awaitingYes) {
      this.setState({
        ...this.state,
        showModal: false,
      });

      if (this.props.onNoButtonClick) {
        this.props.onNoButtonClick();
      }
    }
  };

  onYes = async (e) => {
    const { children, awaitYes } = this.props;
    if (!children.props.onClick) return;

    if (awaitYes) {
      this.setState({
        ...this.state,
        awaitingYes: true,
      });

      const result = await children.props.onClick(e);

      if (result && this._isMounted) {
        this.setState({
          ...this.state,
          showModal: false,
          awaitingYes: false,
        });
      } else {
        this.setState({
          ...this.state,
          showModal: true,
          awaitingYes: false,
        });
      }
      return;
    }

    const result = children.props.onClick(e);
    if (result !== false) {
      this.setState({
        ...this.state,
        showModal: false,
      });
    }
  };

  render() {
    const { showModal, awaitingYes } = this.state;
    const { children, yesButton, noButton, modalChildren, ...modalProps } =
      this.props;

    // overwrites click with show modal and add the onClick from the button to
    // the modals yes button
    const childElement = cloneElement(children, {
      onClick: this.showModal,
    });

    const buttons = [];

    if (noButton) {
      buttons.push({
        ...noButton,
        disabled: awaitingYes,
        onClick: this.hideModal,
      });
    }

    buttons.push({
      ...yesButton,
      disabled: awaitingYes,
      title: awaitingYes ? yesButton.awaitTitle : yesButton.title,
      onClick: this.onYes,
    });

    return (
      <>
        {showModal && (
          <Modal onClose={this.hideModal} buttons={buttons} {...modalProps}>
            {modalChildren}
          </Modal>
        )}
        {childElement}
      </>
    );
  }
}

const buttonShape = PropTypes.shape({
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

ButtonModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  modalChildren: PropTypes.node,
  yesButton: buttonShape.isRequired,
  noButton: buttonShape,
  onNoButtonClick: PropTypes.func,

  // additional props are passed on to a modal
  // modal props onClose and buttons will be overwritten
};

export default ButtonModalWrapper;
