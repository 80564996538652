import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';

import { ReactComponent as CheckedIcon } from './Checked.svg';
import { ReactComponent as UncheckedIcon } from './Unchecked.svg';

type RadioProps = MuiRadioProps;

const Radio = (props: RadioProps) => {
  return (
    <MuiRadio
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      {...props}
    />
  );
};

export { Radio };
