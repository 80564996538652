import { ImageGalleryEdit } from 'componentsNew';

import { Consumer } from '../index';

type ImageGalleryProps = {
  fieldName?: string;
};

export const ImageGallery = ({
  fieldName = 'imageGalleryItems',
}: ImageGalleryProps) => {
  return (
    <Consumer>
      {({ onChangeArticle, article }) => (
        <ImageGalleryEdit
          initialImages={article[fieldName]}
          onChange={(imageFiles) =>
            onChangeArticle({ [fieldName]: imageFiles })
          }
        />
      )}
    </Consumer>
  );
};
