import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

const DEFAULT_WORKSPACE_ID = settings.misc.powerBiDefaultWorkspaceId;

export const getEmbedDetails = (args: {
  reportId: string;
  workspaceId?: string;
}) =>
  createRequest({
    method: 'get',
    url: `${cms}/power-bi/report-embed-details/${
      args.workspaceId || DEFAULT_WORKSPACE_ID
    }/${args.reportId}`,
    errorMessages: {
      '*': 'Failed to get Power BI embed details, please contact support for assistance.',
    },
  });
