import AuthorContentLoader from 'components/Author/ContentLoader';
import Box from 'components/Box';
import Breadcrumbs from 'components/Breadcrumbs';
import { Column, Container } from 'components/Grid';
import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Content = ({ width, height = 492 }) => (
  <ContentLoader
    className=""
    width={width}
    height={height}
    preserveAspectRatio="none"
    style={{ height: `${height}px` }}
  >
    <rect x={0} y={6} width={getRandomNumberBetween(350, 500)} height={36} />

    <rect x={0} y={72} width={52} height={28} rx={4} ry={4} />
    <rect x={65} y={80} width={290} height={12} />
    <rect x={600} y={80} width={60} height={12} />

    <rect x={0} y={140} width={getRandomNumberBetween(550, 670)} height={18} />
    <rect x={0} y={170} width={getRandomNumberBetween(550, 670)} height={18} />
    <rect x={0} y={200} width={getRandomNumberBetween(400, 670)} height={18} />

    <rect x={0} y={240} width={getRandomNumberBetween(500, 670)} height={12} />
    <rect x={0} y={260} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={280} width={getRandomNumberBetween(400, 670)} height={12} />

    <rect x={0} y={320} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={340} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={360} width={getRandomNumberBetween(400, 670)} height={12} />

    <rect x={0} y={400} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={420} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={440} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={460} width={getRandomNumberBetween(550, 670)} height={12} />
    <rect x={0} y={480} width={getRandomNumberBetween(400, 670)} height={12} />
  </ContentLoader>
);

const Loader = () => (
  <>
    <Container>
      <Column>
        <Breadcrumbs ghost={true} />
      </Column>
    </Container>
    <Container>
      <Column span={2}>
        <Box className="m-t-6 lg-m-t-0">
          <Resize>{({ width }) => <Content width={width} />}</Resize>
        </Box>
      </Column>
      <Column>
        <Box>
          <AuthorContentLoader />
        </Box>
      </Column>
    </Container>
  </>
);

export default Loader;
