import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const getBlogPost = (id) =>
  createRequest({
    method: 'get',
    url: `${cms}/blog/post/${id}`,
    errorMessages: {
      '*': 'Failed to get blog post, please contact support for assistance.',
    },
  });

export const updateBlogPost = (id, data) =>
  createRequest({
    method: 'put',
    url: `${cms}/blog/post/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update blog post, please contact support for assistance.',
    },
  });

export const createBlogPost = (data) =>
  createRequest({
    method: 'post',
    url: `${cms}/blog/post`,
    data,
    errorMessages: {
      '*': 'Failed to create blog post, please contact support for assistance.',
    },
  });

export const deleteBlogPost = (id) =>
  createRequest({
    method: 'delete',
    url: `${cms}/blog/post/${id}`,
    errorMessages: {
      '*': 'Failed to delete blog post, please contact support for assistance.',
    },
  });

export const getAuthor = (id) =>
  createRequest({
    method: 'get',
    url: `${cms}/blog/author/${id}`,
    errorMessages: {
      '*': 'Failed to get author, please contact support for assistance.',
    },
  });

export const getAuthors = () =>
  createRequest({
    method: 'get',
    url: `${cms}/blog/author`,
    errorMessages: {
      '*': 'Failed to get authors, please contact support for assistance.',
    },
  });
