import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Loader = ({ width = 1100 }) => (
  <ContentLoader
    className="profile--contentloader"
    width={width}
    height={640}
    style={{ height: '640px' }}
    preserveAspectRatio="none"
  >
    <circle r={50} cx={width > 648 ? 325 : 130} cy={100} />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={190} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 200 : 120}
      y={190}
    />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={230} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 200 : 120}
      y={230}
    />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={270} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 200 : 120}
      y={270}
    />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={310} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 200 : 120}
      y={310}
    />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={350} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 200 : 120}
      y={350}
    />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={390} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 200 : 120}
      y={390}
    />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={430} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 100 : 20}
      y={460}
    />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={500} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 100 : 20}
      y={530}
    />

    <rect width={68} height={17} x={width > 648 ? 100 : 20} y={570} />
    <rect
      width={getRandomNumberBetween(100, 250)}
      height={17}
      x={width > 648 ? 100 : 20}
      y={600}
    />
  </ContentLoader>
);

export default Loader;
