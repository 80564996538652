import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import notFoundImage from 'assets/images/not-found.png';
import Parser from 'components/Parser';
import { Badge, Checkbox, TableCellWithSort } from 'componentsNew';
import { useCallback } from 'react';
import { translations } from 'translations';

import * as helpers from './helpers';
import { ManageAlertsTableSkeleton } from './ManageAlertsTableSkeleton';

const elementId = 'profile-manage-alerts-table';

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum ManageAlertsTableField {
  Title = 'title',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Divisions = 'divisions',
}

export type ManageAlertsTableItem = {
  id: string;
  title?: string;
  notification?: string;
  startDate?: string;
  endDate?: string;
  divisions?: string;
  countries?: string;
};

type ManageAlertsTableProps = {
  sx?: SxProps<Theme>;
  isLoading: boolean;
  items: ManageAlertsTableItem[];
  sort: {
    field: ManageAlertsTableField;
    order: SortOrder;
  };
  selection: {
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  };
  onSortChange: (sort: {
    field: ManageAlertsTableField;
    order: SortOrder;
  }) => void;
  onSelectionChange: (selection: {
    byId: Record<string, boolean>;
    isAllSelected: boolean;
    isSomeSelected: boolean;
  }) => void;
};

const ManageAlertsTable = ({
  sx,
  isLoading,
  items,
  sort,
  selection,
  onSortChange,
  onSelectionChange,
}: ManageAlertsTableProps) => {
  const handleSortClick = useCallback(
    (field: ManageAlertsTableField) => {
      onSortChange({
        field: field,
        order:
          sort?.field === field && sort.order === SortOrder.Asc
            ? SortOrder.Desc
            : SortOrder.Asc,
      });
    },
    [onSortChange, sort]
  );

  const handleItemSelectionChange = useCallback(
    (id: string, checked: boolean) => {
      const selectionById = { ...selection.byId, [id]: checked };
      onSelectionChange({
        byId: selectionById,
        isSomeSelected: items.some((item) => selectionById[item.id]),
        isAllSelected: !items.some((item) => !selectionById[item.id]),
      });
    },
    [selection.byId, items, onSelectionChange]
  );

  const handleBulkSelectionChange = useCallback(
    (checked: boolean) => {
      const byId = items.reduce((selectionById, item) => {
        selectionById[item.id] = checked;
        return selectionById;
      }, {} as Record<string, boolean>);

      onSelectionChange({
        byId,
        isSomeSelected: checked,
        isAllSelected: checked,
      });
    },
    [items, onSelectionChange]
  );

  if (isLoading) {
    return <ManageAlertsTableSkeleton />;
  }

  if (!items.length) {
    return (
      <Stack alignItems="center" padding="1rem">
        <Box component="img" src={notFoundImage} maxWidth="20rem" />
        <Typography marginTop={1}>{translations.manageContentEmpty}</Typography>
      </Stack>
    );
  }

  return (
    <TableContainer sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Table id={elementId} aria-label={translations.profileManageAlerts}>
        <TableHead>
          <TableRow>
            <TableCell width="3.5rem">
              <Checkbox
                checked={selection.isAllSelected || false}
                indeterminate={
                  selection.isSomeSelected && !selection.isAllSelected
                }
                onChange={(
                  _e: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => handleBulkSelectionChange(checked)}
              />
            </TableCell>
            <TableCellWithSort
              sorted={sort?.field === ManageAlertsTableField.Title}
              onSort={() => handleSortClick(ManageAlertsTableField.Title)}
            >
              {translations.manageContentContent}
            </TableCellWithSort>
            <TableCellWithSort
              sx={{ width: '8rem' }}
              sorted={sort?.field === ManageAlertsTableField.StartDate}
              onSort={() => handleSortClick(ManageAlertsTableField.StartDate)}
            >
              {translations.manageContentStartDate}
            </TableCellWithSort>
            <TableCellWithSort
              sx={{ width: '8rem' }}
              sorted={sort?.field === ManageAlertsTableField.EndDate}
              onSort={() => handleSortClick(ManageAlertsTableField.EndDate)}
            >
              {translations.manageContentEndDate}
            </TableCellWithSort>
            <TableCell>{translations.manageContentDivisions}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow
              key={`${item.id}-${index}`}
              id={`${elementId}-row-${index}`}
            >
              <TableCell width="3.5rem">
                <Checkbox
                  checked={selection.byId[item.id] || false}
                  onChange={(
                    _e: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => handleItemSelectionChange(item.id, checked)}
                />
              </TableCell>
              <TableCell>
                <Stack>
                  <Typography variant="body2" fontWeight="bold">
                    {item.title || translations.notAvailable}
                  </Typography>
                  <Parser html={item.notification} type="Alert" />
                </Stack>
              </TableCell>
              <TableCell>
                <Badge
                  size="small"
                  color="default"
                  variant="outlined"
                  label={
                    item.startDate
                      ? helpers.getFormattedDate(item.startDate)
                      : translations.notAvailable
                  }
                />
              </TableCell>
              <TableCell>
                <Badge
                  size="small"
                  color="default"
                  variant="outlined"
                  label={
                    item.endDate
                      ? helpers.getFormattedDate(item.endDate)
                      : translations.notAvailable
                  }
                />
              </TableCell>
              <TableCell>{`${item.divisions || translations.global} ${
                item.countries ? `(${item.countries})` : ''
              }`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { ManageAlertsTable };
