import { getNewsFlashes } from 'api/news';
import { UserConsumer } from 'components/Context/User';
import Error from 'components/Error';
import Fetcher from 'components/Fetcher';
import Title from 'components/Title';
import { History } from 'history';
import { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import { GAitemNotFound } from 'utils/analytics';
import makeQueryString from 'utils/misc/makeQueryString';

import ContentLoader from './ContentLoader';
import SameTopicItem from './sameTopicItem/SameTopicItem';

interface SameTopicProps {
  className?: string;
  channel: string;
  locale: string;
}

const onError = (status: number, message: string): ReactNode => {
  GAitemNotFound(status, message, 'channel');

  return <Error message={message} status={status} redirectOn404={true} />;
};

const removeSameIdFromResponse = (response: any, history: History) => {
  let pathId = '';

  if (history.location.pathname.includes('/feed/news')) {
    pathId = history.location.pathname.replace('/feed/news/', '');
  }
  if (history.location.pathname.includes('/feed/blog')) {
    pathId = history.location.pathname.replace('/feed/blog/', '');
  }
  if (history.location.pathname.includes('/feed/story')) {
    pathId = history.location.pathname.replace('/feed/story/', '');
  }

  const filterSameId = response.filter(
    (res: any) => res.id.replace('cms-', '') !== pathId
  );

  if (filterSameId.length > 2) {
    filterSameId.pop();
  }

  return filterSameId;
};

const SameTopic: FC<SameTopicProps> = ({ className, channel, locale }) => {
  const history = useHistory();

  return (
    <UserConsumer>
      {({ divisionId, countryId, departmentId, siteId, companyId }) => {
        return (
          <Fetcher
            source={getNewsFlashes}
            onLoading={ContentLoader}
            onError={onError}
            param={makeQueryString({
              filter: {
                division: divisionId,
                country: countryId,
                department: departmentId,
                site: siteId,
                company: companyId,
                type: {
                  newsarticle: true,
                  blogpost: true,
                  storyarticle: true,
                },
                localization: {
                  global: false,
                  division: false,
                  country: false,
                  department: false,
                  site: false,
                  company: false,
                },
                tag: channel,
                PublishedStartDate: undefined,
                PublishedEndDate: undefined,
                topNewsOnly: undefined,
                isGlobalFeed: undefined,
                locale,
                // locale // Disabling filter till BE data is ready
              },
              page: {
                limit: 3,
                offset: 0,
              },
            })}
          >
            {({ response }: any) => {
              const removeMoreThanTwo = removeSameIdFromResponse(
                response,
                history
              );

              return (
                removeMoreThanTwo.length > 1 && (
                  <div className={className}>
                    <Title>On the same topic</Title>
                    <SameTopicItem
                      response={removeMoreThanTwo}
                      channel={channel}
                    />
                  </div>
                )
              );
            }}
          </Fetcher>
        );
      }}
    </UserConsumer>
  );
};

export default SameTopic;
