import Flex from 'components/Flex';
import { Column, Container } from 'components/Grid';
import OnlyWithRole from 'components/OnlyWithRole';
import PageTitle from 'components/PageTitle';
import Title from 'components/Title';
import { Component } from 'react';

import DocumentsIndex from './components/DocumentsIndex';
class Administration extends Component {
  render() {
    return (
      <>
        <OnlyWithRole viewableByRole="AvenueAdmin" redirectTo="/404">
          <PageTitle titles={['Administration Page']} />
          <Container>
            <Column>
              <Flex type="space-between">
                <Title inline="true" color="white">
                  Administration Page
                </Title>
              </Flex>
              <DocumentsIndex />
            </Column>
          </Container>
        </OnlyWithRole>
      </>
    );
  }
}

export default Administration;
