import './Result.scss';

import { getSearchResults } from 'api/find/';
import LoadMoreButton from 'components/Button/LoadMoreButton';
import { UserConsumer } from 'components/Context/User';
import Error from 'components/Error';
import { Fetcher } from 'components/Fetcher';
import SortingControl from 'components/SortingControl';
import { useState } from 'react';
import { ArticleSortOption, InnovationArticleResult } from 'types/Portal';
import { GAitemNotFound } from 'utils/analytics';
import getSortOrder from 'utils/misc/getSortOrder';
import makeQueryString from 'utils/misc/makeQueryString';
import sortOptions from 'utils/misc/sortOptions';

import ContentLoader from './ContentLoader';
import NoSearchResults from './NoSearchResult';
import ResultList from './ResultList';

const makeSearchQueryString = (
  searchQuery: string,
  userContext: any,
  sortQuery: string
) => {
  const { departmentId, divisionId, countryId, regionId } = userContext;
  return makeQueryString({
    filter: {
      query: searchQuery,
      division: divisionId,
      country: countryId,
      region: regionId,
      department: departmentId,
      filtertypes: {
        portalarticle: true,
        document: true,
      },
      portalTypes: ['innovation'],
      innovationSearch: true,
    },
    sort: sortQuery,
    limit: 10,
  });
};

const onError = (status: number, message: string) => {
  GAitemNotFound(status, message, 'news');
  return <Error message={message} status={status} redirectOn404={true} />;
};

const NumberOfResults = ({
  response,
  searchQuery,
}: {
  response: any;
  searchQuery: string;
}) => {
  const resultLength = response && response.length > 0 ? response.length : 0;
  return (
    <span className="innovation-portal-results__results-length">
      {resultLength} results found for {decodeURIComponent(searchQuery)}
    </span>
  );
};

interface SortBySelectorProps {
  selectedSortBy: ArticleSortOption;
  setSelectedSortBy: (selectedSortBy: ArticleSortOption) => void;
}

const SortBySelector = ({
  selectedSortBy,
  setSelectedSortBy,
}: SortBySelectorProps) => {
  return (
    <SortingControl
      sortOptions={sortOptions}
      selectedSortOption={selectedSortBy}
      handleSortChange={setSelectedSortBy}
    />
  );
};
const formatResponseBody = (response: any) =>
  response.map((res: any) => {
    const { linkUrl, published, title, summary, imageUrl, imageAltText } =
      res.attributes;
    const types = res.getArticleLabels();
    return {
      linkUrl,
      published,
      title,
      summary,
      imageUrl,
      imageAltText,
      types,
    } as InnovationArticleResult;
  });

interface SearchResultsProps {
  searchQuery: string | null;
  portalType: string;
}

const SearchResults = ({ searchQuery, portalType }: SearchResultsProps) => {
  const [selectedSortBy, setSelectedSortBy] = useState<ArticleSortOption>(
    sortOptions[0]
  );
  const sort = getSortOrder(selectedSortBy);
  const resultLength = 10;

  if (!searchQuery || searchQuery.length < 2) return null;

  return (
    <UserConsumer>
      {(userContext) => (
        <Fetcher
          source={getSearchResults}
          param={makeSearchQueryString(searchQuery, userContext, sort)}
          onLoading={ContentLoader}
          onError={onError}
          useModelFactory={true}
          useLoadMore={true}
        >
          {({
            response,
            loadMore,
          }: {
            response: any;
            loadMore: () => void;
          }) => (
            <div className="innovation-portal-results">
              <div className="innovation-portal-results__sorting">
                <NumberOfResults
                  response={response}
                  searchQuery={searchQuery}
                />
                <SortBySelector
                  selectedSortBy={selectedSortBy}
                  setSelectedSortBy={setSelectedSortBy}
                />
              </div>
              <ul className="innovation-portal-results__list">
                {response && response.length > 0 ? (
                  <ResultList
                    articles={formatResponseBody(response)}
                    portalType={portalType}
                  />
                ) : (
                  <NoSearchResults />
                )}
              </ul>
              {Boolean(loadMore) && response.length >= resultLength ? (
                <LoadMoreButton
                  shouldDisplay={Boolean(loadMore)}
                  onLoadMore={loadMore}
                />
              ) : null}
            </div>
          )}
        </Fetcher>
      )}
    </UserConsumer>
  );
};

export default SearchResults;
