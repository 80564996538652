import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

import { NewsletterBannerCreate, NewsletterBannerEdit } from './types';

const { cms } = settings.api;
const route = `${cms}/newsletter-banner`;

export const getNewsletterBanner = (id: string) =>
  createRequest({
    method: 'get',
    url: `${route}/${id}`,
    errorMessages: {
      '*': 'Failed to get Newsletter banner, please contact support for assistance.',
    },
  });

export const createNewsletterBanner = (data: NewsletterBannerCreate) => {
  return createRequest({
    method: 'post',
    url: route,
    data,
    errorMessages: {
      '*': 'Failed to create Newsletter banner, please contact support for assistance.',
    },
  });
};

export const updateNewsletterBanner = (data: NewsletterBannerEdit) => {
  return createRequest({
    method: 'put',
    url: `${route}/${data.id}`,
    data,
    errorMessages: {
      '*': 'Failed to create Newsletter banner, please contact support for assistance.',
    },
  });
};

export const deleteNewsletterBanner = (id: string) =>
  createRequest({
    method: 'delete',
    url: `${route}/${id}`,
    errorMessages: {
      '*': 'Failed to delete Newsletter banner, please contact support for assistance.',
    },
  });
