import Text from 'components/Editable/Text';
import Title from 'components/Title';

import { Consumer } from '../';

interface SubtitleProps {
  editable?: (article: string) => boolean;
  color?: string;
  placeholder?: string;
  hashtag?: string;
  allowedCharacters?: boolean;
  fieldName?: string;
}

const SubTitle = ({
  editable,
  color,
  placeholder,
  hashtag,
  allowedCharacters,
  fieldName = 'subTitle',
}: SubtitleProps) => (
  <Consumer>
    {({
      article,
      onChangeArticle,
      validationParameters: {
        [fieldName]: { maxLength },
      },
      showInitialError,
    }) => {
      if (!editable || editable(article)) {
        return (
          <Text
            wrapper={{
              element: 'h3',
              className: 'editable-subtitle',
            }}
            placeholder={placeholder}
            onBlur={(value: any) => onChangeArticle({ [fieldName]: value })}
            showInitialError={showInitialError}
            content={article[fieldName]}
            maxLength={maxLength}
            allowedCharacters={allowedCharacters}
            color={color}
            hashtag={hashtag}
            className="story-subtitle"
          />
        );
      }

      return <Title tag="h2">{article[fieldName]}</Title>;
    }}
  </Consumer>
);

export default SubTitle;
