import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Loader = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={570}
    preserveAspectRatio="none"
    style={{ height: '570px' }}
  >
    <rect x={0} y={0} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={20} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={40} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={60} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={80} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={100} width={getRandomNumberBetween(400, 1050)} height={12} />
    <rect x={0} y={120} width={getRandomNumberBetween(400, 1050)} height={12} />
    <rect x={0} y={140} width={getRandomNumberBetween(400, 1050)} height={12} />

    <rect x={0} y={160} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={180} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={200} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={220} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={240} width={getRandomNumberBetween(400, 1050)} height={12} />

    <rect x={0} y={280} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={300} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={320} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={340} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={360} width={getRandomNumberBetween(400, 1050)} height={12} />

    <rect x={0} y={400} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={420} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={440} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={460} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={480} width={getRandomNumberBetween(400, 1050)} height={12} />

    <rect x={0} y={440} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={440} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={460} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={480} width={getRandomNumberBetween(550, 1050)} height={12} />
    <rect x={0} y={500} width={getRandomNumberBetween(400, 1050)} height={12} />
  </ContentLoader>
);

export default Loader;
