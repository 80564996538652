import './FolderEntry.scss';

import {
  differenceInBusinessDays,
  format as formatDateTime,
  formatDistanceToNow,
  isPast as dateTimeIsPast,
  parseISO,
} from 'date-fns';
import filesize from 'file-size';
import PropTypes from 'prop-types';

import getIconAsset from '../../getIconAsset';
import EllipsisMenu from '../EllipsisMenu';

const renderIcon = ({ type, name }) => {
  let altText;
  let iconAsset;
  if (type === 'file') {
    altText = `Icon for file ${name}`;
    iconAsset = getIconAsset(name);
  } else if (type === 'folder') {
    altText = `Icon for folder ${name}`;
    iconAsset = getIconAsset(`${name}/`);
  }
  return <img className="folder-entry__icon" alt={altText} src={iconAsset} />;
};

const getEllipsisMenuOptions = (entryType, isEditing) => {
  const isFile = entryType === 'file';
  if (isFile) {
    return [
      {
        id: 1,
        action: 'download',
        title: 'Download',
      },
      {
        id: 2,
        action: 'preview',
        title: 'Preview',
      },
      isEditing && {
        id: 3,
        action: 'rename',
        title: 'Rename',
      },
      isEditing && {
        id: 4,
        action: 'delete',
        title: 'Delete',
      },
    ].filter((item) => item !== false);
  } else if (entryType === 'folder' && isEditing) {
    return [
      {
        id: 3,
        action: 'rename',
        title: 'Rename',
      },
      {
        id: 4,
        action: 'delete',
        title: 'Delete',
      },
    ];
  } else return [];
};

const FolderEntry = ({
  isEditing,
  item,
  item: { lastModified, name, size, type },
  onClick,
  topic,
}) => {
  const onClickMenuOption = (e, { action }) => {
    e.stopPropagation();
    onClick(e, { type: 'menu-item', action, item });
  };
  const onClickFolderEntry = (e) => {
    e.stopPropagation();
    onClick(e, { type: 'entry', item });
  };

  const truncTitle = (title) => {
    const truncatedTitle = title.substring(0, 25);
    if (truncatedTitle.length < 25) return title;
    return `${truncatedTitle.trim()}...`;
  };

  const itemDate = parseISO(lastModified);
  const now = new Date();
  const fullFormattedDate = formatDateTime(itemDate, 'Pp');
  const formattedDate =
    dateTimeIsPast(itemDate) && differenceInBusinessDays(now, itemDate) < 30
      ? `${formatDistanceToNow(itemDate)} ago`
      : fullFormattedDate;
  const ellipsisMenuOptions = getEllipsisMenuOptions(type, isEditing);
  const isTopic = topic;
  return (
    <div className="folder-entry__container" onClick={onClickFolderEntry}>
      <div className="folder-entry__column">{renderIcon(item)}</div>
      <div className="folder-entry__column">
        <p className="folder-entry__filename">
          {!isTopic ? name : truncTitle(name)}
        </p>
      </div>
      {!isTopic ? (
        <>
          <div className="folder-entry__column">
            <p className="folder-entry__size">
              {filesize(size, { fixed: 0 }).human('si')}
            </p>
          </div>
          <div className="folder-entry__column">
            <p className="folder-entry__date" title={fullFormattedDate}>
              {formattedDate}
            </p>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className={!isTopic && 'folder-entry__column'}>
        {ellipsisMenuOptions.length === 0 ? (
          <div className="folder-entry__empty"></div>
        ) : (
          <EllipsisMenu
            options={ellipsisMenuOptions}
            onClick={onClickMenuOption}
          />
        )}
      </div>
    </div>
  );
};

const itemShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  modifiedDate: PropTypes.string,
};

FolderEntry.propTypes = {
  isEditing: PropTypes.bool,
  item: PropTypes.shape(itemShape).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FolderEntry;
