import './GatewayItem.scss';

import { Link } from 'react-router-dom';

const GatewayItem = ({ gateway }) => {
  return (
    <li className="gateway-block-item">
      <Link className="gateway-block-item__link" to={gateway.getPath()}>
        <div className="gateway-block-item__markline">
          <span className="gateway-block-item__circle" />
          <span className="gateway-block-item__line" />
        </div>
        <div className="gateway-block-item__content">
          <p className="gateway-block-item__title">{gateway.get('title')}</p>
          <p className="gateway-block-item__preamble">
            {gateway.get('preamble')}
          </p>
        </div>
      </Link>
    </li>
  );
};

export default GatewayItem;
