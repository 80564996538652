import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useUser } from 'components/Context/User';
import { ProfileAvatar } from 'componentsNew';

type AIChatBubblePromptProps = {
  id: string;
  prompt: string;
};

const AIChatBubblePrompt = ({ id, prompt }: AIChatBubblePromptProps) => {
  const user = useUser();

  return (
    <Stack
      id={id}
      sx={(theme) => ({
        flexDirection: 'row',
        gap: theme.spacing('xs'),
        '> .MuiAvatar-root': { flexShrink: 0 },
      })}
    >
      <ProfileAvatar size="sm" src={user.imageUrl} />
      <Box
        sx={(theme) => ({
          padding: theme.spacing('sm'),
          boxShadow: theme.elevation.sm,
          borderRadius: theme.border.radius.lg,
          border: `1px solid ${theme.colors.border.surfacePrimary}`,
          backgroundColor: theme.colors.surface.primary,
          typography: theme.typography.body1,
          wordBreak: 'break-word',
        })}
      >
        {prompt}
      </Box>
    </Stack>
  );
};

export { AIChatBubblePrompt };
