import './AppSwitchSelect.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// On <Link> we use 'target={'_self'}' to trigger a refresh otherwise the routing will sometimes throw a 404, async problem maybe?

const AppSwitchSelect = ({ item, isNavOpen, openPortalList }) => {
  const selectClasses = classnames('app-switch-select', {
    'app-switch-select--nav-opened': isNavOpen,
  });
  const linkUrl =
    item.id !== 'avenue-home-page'
      ? `${item.homePath}/${item.id}`
      : item.homePath;

  return (
    <div className={selectClasses}>
      <Link to={linkUrl} className="app-switch-select__link" target="_self">
        {item.name}
      </Link>
      <span className="app-switch-select__text" onClick={openPortalList}>
        {item.name}
      </span>
    </div>
  );
};

AppSwitchSelect.propTypes = {
  isNavOpen: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    homePath: PropTypes.string,
    icon: PropTypes.object,
  }),
  openPortalList: PropTypes.func,
};

export default AppSwitchSelect;
