import './BlockedUser.scss';

import Box from 'components/Box';
import { Container } from 'components/Grid';
import { ReactComponent as Logo } from 'components/Icon/Logo.svg';

const BlockedUser = () => {
  return (
    <div className="blocked-user">
      <header className="pageheader">
        <Container width="full" type="space-around" mobileRow={true}>
          <h1 className="blocked-user__logo">
            <a className="blocked-user__logo__link" href="/">
              <Logo />
              <span className="logo__text">Avenue</span>
            </a>
          </h1>
        </Container>
      </header>
      <Box className="blocked-user--content">
        <h3>Oh no!</h3>
        <p>
          It seems like you don't have access to Avenue. Please contact your
          local IT for assistance or raise a service request in the{' '}
          <a href="https://assaabloy.service-now.com/ess?sysparm_stack=no">
            Service Portal.
          </a>
        </p>
      </Box>
    </div>
  );
};

export default BlockedUser;
