import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AlertDialog, UserSearchSelect } from 'componentsNew';
import { User } from 'componentsNew/UserSearchSelect';
import { FormFieldWrapper } from 'layoutNew';
import { useEffect, useState } from 'react';
import { translations } from 'translations';

type DialogReassignProps = {
  id: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: (user: User) => void;
};

const DialogReassign = ({
  id,
  open,
  loading,
  onClose,
  onConfirm,
}: DialogReassignProps) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userInputError, setUserInputError] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setSelectedUser(null);
      setUserInputError(false);
    }
  }, [open]);

  return (
    <AlertDialog
      id={id}
      open={open}
      type="information"
      title={translations.manageContentActionsReassignDialogTitle}
      primaryButton={{
        loading: loading,
        disabled: loading,
        text: translations.manageContentActionsReassign,
        onClick: () => {
          if (!selectedUser) {
            setUserInputError(true);
            return;
          }
          onConfirm(selectedUser);
        },
      }}
      secondaryButton={{
        disabled: loading,
        text: translations.cancel,
        onClick: onClose,
      }}
      disableClose={loading}
      onClose={onClose}
    >
      <Stack sx={(theme) => ({ gap: theme.spacing('xxs') })}>
        <FormFieldWrapper
          id={`${id}-user`}
          label={translations.searchEmployees}
          hideLabel
          error={
            userInputError
              ? [translations.manageContentActionsReassignUserInputError]
              : []
          }
        >
          <UserSearchSelect
            selectedUsers={selectedUser ? [selectedUser] : []}
            onChange={(users) =>
              setSelectedUser(users.length ? users[0] : null)
            }
          />
        </FormFieldWrapper>
        <Typography
          variant="caption"
          sx={(theme) => ({ color: theme.colors.text.secondary })}
        >
          {translations.manageContentActionsReassignDialogText}
        </Typography>
      </Stack>
    </AlertDialog>
  );
};
export { DialogReassign };
