import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import * as cmsApi from 'api/cms/blog';
import * as organizationApi from 'api/organization';
import { Badge, Icon } from 'componentsNew';
import { ArticleContentType } from 'enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import { getImageSrc } from 'utils/cms';
import { cleanSegmentLabelName } from 'utils/misc/cleanSegmentLabelName';
import { getDateOrDaysAgo } from 'utils/misc/time';

interface ArticleMetaLabelsProps {
  type?: ArticleContentType;
  publishedDate?: string;
  containsImageGallery: boolean;
  modifiedDate?: string;
  countries?: { name: string; id: string }[];
  divisions?: { name: string; id: string }[];
  departments?: { name: string; id: string }[];
  segments?: { name: string; id: string }[];
  sites?: { name: string; id: string }[];
  companies?: { name: string; id: string }[];
  author?: { name: string; id: string };
  color?: 'primary' | 'inversePrimary';
  sx?: SxProps<Theme>;
}

const ArticleMetaLabels = ({
  type,
  publishedDate,
  containsImageGallery,
  modifiedDate,
  countries = [],
  divisions = [],
  departments = [],
  segments = [],
  sites = [],
  companies = [],
  author,
  color = 'primary',
  sx,
}: ArticleMetaLabelsProps) => {
  const [authorImageUrl, setAuthorImageUrl] = useState<string | null>(null);

  const formattedPublishedDate =
    publishedDate && getDateOrDaysAgo(publishedDate);

  const formattedModifiedDate = modifiedDate && getDateOrDaysAgo(modifiedDate);

  const linkLabels = useMemo(() => {
    const labels: {
      name: string;
      path: string;
    }[] = [];

    if (type === ArticleContentType.Press) {
      labels.push({
        name: 'Global',
        path: '/feed?localization=global',
      });
      return labels;
    }
    if (!divisions.length) {
      labels.push({
        name: 'Global',
        path: '/feed?localization=global',
      });
    }
    if (divisions.length && !countries.length) {
      divisions.forEach((division) =>
        labels.push({
          name: division.name,
          path: '/feed?localization=division',
        })
      );
    }
    if (divisions.length && countries.length) {
      countries.forEach((country) =>
        labels.push({
          name: country.name,
          path: '/feed?localization=country',
        })
      );
    }
    if (segments.length) {
      segments.forEach((segment) =>
        labels.push({
          name: cleanSegmentLabelName(segment.name),
          path: '/feed?localization=segment',
        })
      );
    }
    if (departments.length) {
      departments.forEach((department) =>
        labels.push({
          name: department.name,
          path: '/feed?localization=department',
        })
      );
    }
    if (sites.length) {
      sites.forEach((site) =>
        labels.push({
          name: site.name,
          path: '/feed?localization=site',
        })
      );
    }
    if (companies.length) {
      companies.forEach((company) =>
        labels.push({
          name: company.name,
          path: '/feed?localization=company',
        })
      );
    }
    return labels;
  }, [type, companies, countries, departments, divisions, segments, sites]);

  const fetchAuthor = useCallback(async (id: string) => {
    try {
      const isGuestAuthor = !id.includes('cms-');

      if (isGuestAuthor) {
        const response = await organizationApi.getProfileImage(id);
        const imageUrl = JSON.parse(response.data.data);
        setAuthorImageUrl(imageUrl);
        return;
      }
      const formattedId = id.replace('cms-', '');
      const response = await cmsApi.getAuthor(formattedId);
      const imageUrl = getImageSrc(response.data.data.attributes.imageUrl);
      setAuthorImageUrl(imageUrl);
    } catch {}
  }, []);

  useEffect(() => {
    if (!author) return;
    fetchAuthor(author.id);
  }, [author, fetchAuthor]);

  if (
    !author &&
    !formattedPublishedDate &&
    !formattedModifiedDate &&
    !linkLabels.length
  ) {
    return null;
  }

  return (
    <Stack
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        (theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          rowGap: theme.spacing('xxs'),
        }),
      ]}
      divider={
        <Divider
          orientation="vertical"
          sx={(theme) => ({
            margin: `auto ${theme.spacing('xxs')}`,
            height: theme.typography.body2.fontSize,
            borderColor:
              color === 'primary'
                ? theme.colors.border.surfacePrimary
                : theme.colors.border.surfaceInversePrimary,
            opacity: 0.7,
          })}
        />
      }
    >
      {author && (
        <Stack direction="row" alignItems="center">
          {authorImageUrl ? (
            <Avatar
              alt={author.name}
              src={authorImageUrl}
              sx={{ height: '1.5rem', width: '1.5rem' }}
            />
          ) : (
            <Icon type="userCircle" color="secondary" />
          )}
          <Typography
            variant="body2"
            sx={(theme) => ({
              color:
                color === 'primary'
                  ? theme.colors.text.tertiary
                  : theme.colors.text.inversePrimary,
              marginLeft: theme.spacing('xxs'),
              lineHeight: 1.2,
            })}
          >
            {author.name}
          </Typography>
        </Stack>
      )}
      {containsImageGallery && (
        <Badge
          size="small"
          variant="outlined"
          color={color === 'primary' ? 'primary' : 'default'}
          icon={<Icon type="photoFilled" />}
          label={translations.imageGalleryBadgeTitle}
          sx={() => ({
            '&.MuiChip-sizeSmall': {
              '.MuiChip-icon svg': {
                height: '14px',
                width: '14px',
              },
            },
          })}
        />
      )}
      {formattedPublishedDate && (
        <Typography
          variant="body2"
          sx={(theme) => ({
            color:
              color === 'primary'
                ? theme.colors.text.tertiary
                : theme.colors.text.inversePrimary,
            lineHeight: 1.2,
          })}
        >
          {formattedPublishedDate}
        </Typography>
      )}
      {formattedModifiedDate && (
        <Typography
          variant="body2"
          sx={(theme) => ({
            color:
              color === 'primary'
                ? theme.colors.text.tertiary
                : theme.colors.text.inversePrimary,
            lineHeight: 1.2,
          })}
        >
          {`${translations.lastEdit} ${formattedModifiedDate}`}
        </Typography>
      )}

      {linkLabels.map((linkLabel) => (
        <Link
          key={linkLabel.name}
          to={linkLabel.path}
          variant="body2"
          component={ReactRouterLink}
          sx={(theme) => ({
            lineHeight: 1.2,
            color:
              color === 'primary'
                ? theme.colors.text.tertiary
                : theme.colors.text.inversePrimary,
            textDecoration: 'none',
            '&:hover': {
              color:
                color === 'primary'
                  ? theme.colors.text.tertiary
                  : theme.colors.text.inversePrimary,
              textDecoration: 'underline',
            },
          })}
        >
          {linkLabel.name}
        </Link>
      ))}
    </Stack>
  );
};

export { ArticleMetaLabels };
