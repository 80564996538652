import SearchSelector from 'components/SearchSelector';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { getSiteSuggestions } from 'utils/misc/autoSuggest';

class SiteSelector extends Component {
  render() {
    const {
      alwaysDoubleLine,
      className,
      disabled,
      multiple,
      onChange,
      showError,
      selectedSites,
      defaultLabel,
      identifier,
    } = this.props;
    return (
      <SearchSelector
        alwaysDoubleLine={alwaysDoubleLine}
        className={className}
        defaultLabel={defaultLabel}
        disabled={disabled}
        fetchSearchSuggestions={getSiteSuggestions}
        id="siteSelector"
        multiple={multiple}
        onChange={onChange}
        placeholder={multiple ? 'Search sites' : 'Search site'}
        selectedOptions={selectedSites}
        showError={showError}
        identifier={identifier}
      />
    );
  }
}

SiteSelector.propTypes = {
  alwaysDoubleLine: PropTypes.bool,
  className: PropTypes.string,
  defaultLabel: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  selectedSites: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ]),
  showError: PropTypes.bool,
};

SiteSelector.defaultProps = {
  disabled: false,
  multiple: false,
};

export default SiteSelector;
