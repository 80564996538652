const animateScroll = (amount, delay, fromTop = false) => {
  const distanceFromTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  const newScrollPosition = distanceFromTop + amount;

  return setTimeout(() => {
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({
        left: 0,
        top: fromTop ? amount : newScrollPosition,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo(0, fromTop ? amount : newScrollPosition);
    }
  }, delay);
};

export default animateScroll;
