import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

const SCROLL_KEY = '_scrollPosition';

export const saveScrollPosition = (key) =>
  sessionStorage.setItem(
    key + SCROLL_KEY,
    window.scrollY || window.pageYOffset
  );

class ScrollRestoration extends Component {
  componentDidMount() {
    const {
      history: { action },
      storageKey,
    } = this.props;

    if (action === 'POP') {
      const key = `${storageKey}${SCROLL_KEY}`;
      const y = sessionStorage.getItem(key);
      if (y && isNaN(y) === false) {
        setTimeout(() => {
          window.scrollTo(0, Number(y));
        });

        sessionStorage.setItem(key, null);
      }
    }
  }

  render() {
    return null;
  }
}

ScrollRestoration.propTypes = {
  storageKey: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ScrollRestoration);
