import { PopperProps } from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { DateTimePickerToolbarProps } from '@mui/x-date-pickers/DateTimePicker';
import { useUtils } from '@mui/x-date-pickers/internals';
import { useMemo, useState } from 'react';
import { translations } from 'translations';

export type DateTimePickerProps = {
  id?: string;
  size?: TextFieldProps['size'];
  error?: TextFieldProps['error'];
  fullWidth?: TextFieldProps['fullWidth'];
  inputProps?: TextFieldProps['inputProps'];
  placement?: PopperProps['placement'];
} & Omit<MuiDateTimePickerProps<Date>, 'slots' | 'slotProps'>;

const DateTimePicker = ({
  id,
  size = 'small',
  error = false,
  fullWidth = false,
  inputProps = {},
  placement = 'bottom-start',
  value = null,
  disabled = false,
  ...rest
}: DateTimePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);

  // "popperProps" needs to be defined here as an object because of this issue
  // https://github.com/mui/mui-x/issues/8432
  const popperProps = useMemo(
    () => ({
      placement: placement,
      disablePortal: true,
      modifiers: [
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ],
    }),
    [placement]
  );

  return (
    <MuiDateTimePicker
      open={open}
      value={value}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      {...rest}
      slots={{ toolbar: Toolbar }}
      slotProps={{
        popper: popperProps,
        textField: {
          size: size,
          error: error,
          fullWidth: fullWidth,
          inputProps: {
            id: id,
            readOnly: true,
            sx: { cursor: disabled ? 'default' : 'pointer' },
            ...inputProps,
            onClick: (e) => {
              setOpen(!open);
              if (inputProps.onClick) {
                inputProps.onClick(e);
              }
            },
          },
        },
      }}
    />
  );
};

const Toolbar = ({
  value,
  toolbarVariant,
}: DateTimePickerToolbarProps<Date>) => {
  const dateUtils = useUtils();

  if (toolbarVariant === 'desktop') {
    return null;
  }

  return (
    <Stack
      sx={(theme) => ({
        justifyContent: 'end',
        gridArea: '1 / 2 / auto / 4',
        padding: '1rem 1.5rem',
        borderBottom: `1px solid ${theme.colors.border.surfacePrimary}`,
        letterSpacing: '0.5px',
      })}
    >
      <Typography
        variant="caption"
        component="p"
        sx={(theme) => ({
          color: theme.colors.text.tertiary,
          marginBottom: theme.spacing('xxs'),
        })}
      >
        {translations.dateTimePickerPlaceholder.toUpperCase()}
      </Typography>
      <Typography
        variant="h2"
        component="p"
        sx={(theme) => ({
          color: theme.colors.text.brand,
          lineHeight: 1.2,
        })}
      >
        {value ? dateUtils.format(value, 'keyboardDate') : '--'}
      </Typography>
      <Typography
        variant="h1"
        component="p"
        sx={(theme) => ({
          color: theme.colors.text.brand,
          lineHeight: 1.2,
        })}
      >
        {value ? dateUtils.format(value, 'fullTime24h') : '--:--'}
      </Typography>
    </Stack>
  );
};

export { DateTimePicker };
