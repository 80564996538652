import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import React, { useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

type ArticleCreatedByProps = {
  id?: string;
  authorId: string;
  authorName: string;
  sx?: SxProps<Theme>;
};

const ArticleCreatedBy = ({
  id,
  authorId,
  authorName,
  sx,
}: ArticleCreatedByProps) => {
  const elements = useMemo(
    () =>
      textUtils.replaceTranslationAliasesWithElements(
        translations.articlePageCreatedBy,
        {
          name: (
            <Link
              variant="caption"
              to={`/people/${authorId}`}
              component={ReactRouterLink}
              sx={(theme) => ({
                color: theme.colors.text.secondary,
                textDecorationColor: theme.colors.text.secondary,
              })}
            >
              {authorName}
            </Link>
          ),
        }
      ),
    [authorId, authorName]
  );

  return (
    <Typography
      id={id}
      variant="caption"
      sx={[
        (theme) => ({ color: theme.colors.text.secondary }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {elements.map((element, index) => [
        <React.Fragment key={`article-created-by-${index}`}>
          {element}
        </React.Fragment>,
        ' ',
      ])}
    </Typography>
  );
};

export { ArticleCreatedBy };
