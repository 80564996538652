import { BreakpointsOptions } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

const createMuiBreakpoints = () => {
  const breakpointsOptions: BreakpointsOptions = {
    values: {
      xs: 0,
      sm: 512, // 32rem
      md: 768, // 48rem
      lg: 1024, // 64rem
      xl: 1280, // 80rem
      xxl: 1440, // 90rem
    },
  };
  return createBreakpoints(breakpointsOptions);
};

export { createMuiBreakpoints };
