class EntryNode {
  constructor({
    id,
    name,
    type,
    path,
    splitPath,
    pathObjects,
    url,
    downloadUrl,
    lastModified,
    size,
  }) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.path = path;
    this.splitPath = splitPath;
    this.pathObjects = pathObjects;
    this.url = url;
    this.downloadUrl = downloadUrl;
    this.lastModified = lastModified;
    this.size = size;
  }

  createAppendedPathName(newSegment) {
    return this.path ? `${this.path}/${newSegment}` : newSegment;
  }

  getNodePathNames() {
    return this.splitPath;
  }

  getNodePath() {
    return this.path;
  }

  getInclusiveNodePathNames() {
    if (this.type === 'root') {
      return [];
    }
    return [...this.splitPath, this.name];
  }

  getInclusiveNodePath() {
    if (this.type === 'root') {
      return '';
    }
    return this.createAppendedPathName(this.name);
  }

  getInclusiveNodePathObjects() {
    if (this.type === 'root') {
      return [];
    }
    const appendedPath = this.createAppendedPathName(this.name);
    return [
      ...this.pathObjects,
      {
        id: appendedPath,
        name: this.name,
        path: this.path,
        splitPath: this.splitPath,
      },
    ];
  }
}

export const createEmptyFolderEntry = () =>
  new EntryNode({
    id: '',
    name: null,
    type: 'root',
    path: '',
    splitPath: [],
    pathObjects: [],
    url: null,
    downloadUrl: null,
    lastModified: null,
    size: null,
  });

export const createFolderEntryFromPathObject = ({ id, name, splitPath }) => {
  return new EntryNode({
    id,
    name,
    type: 'folder',
    path: splitPath.join('/'),
    splitPath: splitPath,
    pathObjects: createPathObjects(splitPath),
    url: null,
    downloadUrl: null,
    lastModified: null,
    size: null,
  });
};

export const parseFolderEntries = (
  entries,
  currentFolder,
  rootFolderName,
  decodePathSegments
) => {
  const map = entries.map((entry) => {
    const { id, downloadUrl, lastModified, name, size, type, url } = entry;
    let splitPath;
    if (type === 'folder') {
      const decodedUrl = decodePathSegments ? decodeURIComponent(url) : url;
      const index = decodedUrl.indexOf(rootFolderName);
      const pathAndName = decodedUrl.substring(
        index + rootFolderName.length + 1
      );
      splitPath = pathAndName.split('/').slice(0, -1);
    } else {
      splitPath = currentFolder.getInclusiveNodePathNames();
    }

    return new EntryNode({
      id,
      name,
      type: type === 'folder' ? type : 'file',
      mimeType: type === 'folder' ? null : type,
      path: splitPath.join('/'),
      splitPath,
      pathObjects: createPathObjects(splitPath),
      url,
      downloadUrl,
      lastModified,
      size,
    });
  });
  return map;
};

const createPathObjects = (splitPath) => {
  let inclusivePath = [];
  return splitPath.map((segment) => {
    const pathToSegment = inclusivePath;
    inclusivePath = [...inclusivePath, segment];
    const pathItem = {
      id: inclusivePath.join('/'),
      name: segment,
      path: pathToSegment.join('/'),
      splitPath: pathToSegment,
    };
    return pathItem;
  });
};
