import './Feed.scss';

import { Container } from 'components/Grid';
import OnlyWithRole from 'components/OnlyWithRole';
import { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';

import { FeedContext } from '../../components/Context/Feed';
import FeedTab from './components/FeedTab';
import GlobalFeed from './components/GlobalFeed';
import MyFeed from './components/MyFeed';
import TabContent from './components/TabContent';
import Topics from './components/Topics';

const headerTabs = [
  { text: 'My Feed', value: 'myfeed', path: '/feed' },
  { text: 'All', value: 'globalfeed', path: '/feed/all' },
  { text: 'Topics', value: 'topics', path: '/feed/topics' },
];

const FeedHome = () => {
  const history = useHistory();
  const location = useLocation();

  const activeTabIndex = useMemo(() => {
    const index = headerTabs.findIndex((tab) => tab.path === location.pathname);
    return index === -1 ? 0 : index;
  }, [location.pathname]);

  const { resetFilters, onChangeLocale } = useContext(FeedContext);

  return (
    <>
      <Container>
        <div className="add-links">
          <OnlyWithRole
            viewableByRole={[
              'AvenueAdmin',
              'AvenueGlobalEditor',
              'AvenueBlogEditor',
              'AvenueSuperuser',
            ]}
            isAllRolesRequired={false}
          >
            <div className="add-links--add">Add</div>
          </OnlyWithRole>
          <div className="add-links--wrapper">
            <OnlyWithRole viewableByRole="AvenueAdmin">
              <NavLink to="/some/create">
                <div className="add-links--buttons">SoMe</div>
              </NavLink>
            </OnlyWithRole>
            <OnlyWithRole viewableByRole="AvenueGlobalEditor">
              <NavLink to="/feed/news/create">
                <div className="add-links--buttons">News</div>
              </NavLink>
            </OnlyWithRole>
            <OnlyWithRole viewableByRole="AvenueBlogEditor">
              <NavLink to="/feed/blog/create">
                <div className="add-links--buttons">Blog</div>
              </NavLink>
            </OnlyWithRole>
            <OnlyWithRole viewableByRole="AvenueSuperuser">
              <NavLink to="/feed/story/create">
                <div className="add-links--buttons">Story</div>
              </NavLink>
            </OnlyWithRole>
          </div>
        </div>

        <FeedTab
          tabs={headerTabs}
          selected={headerTabs[activeTabIndex]}
          setSelected={(tab) => {
            const activeTab = headerTabs[activeTabIndex];
            if (tab.value === activeTab.value) return;
            resetFilters({ includeType: true });
            onChangeLocale('en');
            history.replace(tab.path);
          }}
        >
          <TabContent isSelected={activeTabIndex === 0}>
            <MyFeed />
          </TabContent>
          <TabContent isSelected={activeTabIndex === 1}>
            <GlobalFeed />
          </TabContent>
          <TabContent isSelected={activeTabIndex === 2}>
            <Topics />
          </TabContent>
        </FeedTab>
      </Container>
    </>
  );
};

export default FeedHome;
