import Text from 'components/Editable/Text';

import { Consumer } from '../';

const fieldValidator = (value) => {
  const lcValue = (value ? value : '').toLowerCase();
  const match = lcValue.match(
    /^((0x){0,1}|#{0,1})([0-9A-Fa-f]{8}|[0-9A-Fa-f]{6})$/gm
  );
  if (lcValue && !match) {
    return 'Color is invalid';
  }
  return null;
};

const BackgroundColor = ({
  editable,
  color,
  placeholder,
  fieldName = 'backgroundColor',
}) => (
  <Consumer>
    {({
      article,
      updatedArticle,
      validationParameters: {
        [fieldName]: { maxLength },
      },
      onChangeArticle,
      showInitialError,
    }) => {
      if (!editable || editable(article)) {
        let value = article[fieldName];
        if (!updatedArticle[fieldName]) {
          value = updatedArticle[fieldName];
        }
        return (
          <Text
            wrapper={{
              element: 'h1',
              className: 'preamble',
            }}
            placeholder={placeholder}
            onBlur={(value) => onChangeArticle({ [fieldName]: value })}
            validators={[fieldValidator]}
            showInitialError={showInitialError}
            content={value}
            maxLength={maxLength}
            color={color}
          />
        );
      }

      return <div>{article[fieldName]}</div>;
    }}
  </Consumer>
);

export default BackgroundColor;
