import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { AvenueRoute } from 'constants/routes';
import { translations } from 'translations';

export type ProfileTabItem = {
  id: string;
  label: string;
  content: React.ReactNode;
  path: AvenueRoute;
  gaName: string;
};

type ProfileTabsProps = { items: ProfileTabItem[] } & TabsProps;

const ProfileTabs = ({ items, value, onChange }: ProfileTabsProps) => {
  return (
    <Tabs
      variant="scrollable"
      value={value}
      aria-label={translations.profileSettings}
      sx={(theme) => ({
        borderTop: `${theme.border.thickness[1]} solid ${theme.colors.border.surfaceInformative}`,
        paddingLeft: { sm: theme.spacing('sm') },
        paddingTop: theme.spacing('xxxs'),
      })}
      onChange={onChange}
    >
      {items.map((item) => (
        <Tab
          id={item.id}
          key={item.id}
          aria-label={item.label}
          aria-controls={`${item.id}-content`}
          label={item.label}
          sx={(theme) => ({
            borderTopLeftRadius: theme.border.radius.sm,
            borderTopRightRadius: theme.border.radius.sm,
            marginLeft: theme.spacing('xxxs'),
            '&:hover': {
              backgroundColor: theme.colors.surface.primary,
            },
            '&.Mui-focusVisible': {
              boxShadow: `inset 0 0 0 1px ${theme.colors.border.focus}`,
            },
          })}
        />
      ))}
    </Tabs>
  );
};

export { ProfileTabs };
