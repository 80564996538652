import { Desktop } from 'components/Responsive';

import BoxHeader from '../../common/BoxHeader';
import FeedLanguageSelector from '../../common/FeedLanguageSelector';

const GlobalFeedHeader = () => {
  const description = `Explore what is in the news feed for your colleagues in the ASSA ABLOY Group. 
  Here you can find content for all divisions in your country as well as all global content.`;
  return (
    <>
      <Desktop>
        <BoxHeader description={description} isGlobalFeed={true} />
        <FeedLanguageSelector languageSelectorColor="white" />
      </Desktop>
    </>
  );
};

export default GlobalFeedHeader;
