import { UserConsumer } from 'components/Context/User';
import { ONBOARDING_GUID, PROFILE_GUID, TERMS_GUID } from 'constants/consents';
import { memo } from 'react';

import Onboarding from './Onboarding';
import Profile from './Profile';
import Terms from './Terms';

const Modals = () => (
  <UserConsumer>
    {(ctx) => {
      const { consents = [], profileUpdated } = ctx;
      const checkConsent = (guid) =>
        consents.some((c) => c.id === guid) === false;

      // Show the Onboarding and Profile modals only during load
      // Not again after Profile data is updated
      if (!profileUpdated && checkConsent(ONBOARDING_GUID)) {
        return <Onboarding {...ctx} />;
      } else if (!profileUpdated && checkConsent(PROFILE_GUID)) {
        return <Profile {...ctx} />;
      } else if (checkConsent(TERMS_GUID)) {
        return <Terms {...ctx} />;
      } else {
        return null;
      }
    }}
  </UserConsumer>
);

export default memo(Modals);
