import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const getFAQ = () =>
  createRequest({
    method: 'get',
    url: `${cms}/public/faq`,
    errorMessages: {
      '*': 'Failed to get FAQ, please contact support for assistance.',
    },
  });

export const getMenu = () =>
  createRequest({
    method: 'get',
    url: `${cms}/public/menu`,
    errorMessages: {
      '*': 'Failed to get menu, please contact support for assistance.',
    },
  });

export const getPage = (name) =>
  createRequest({
    method: 'get',
    url: `${cms}/public/${name}`,
    errorMessages: {
      '*': 'Failed to get page, please contact support for assistance.',
    },
  });
