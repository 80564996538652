import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as aiSearchApi from 'api/aiSearch';
import * as aiSearchTransformers from 'api/aiSearch/transformers';
import { PromptResult, PromptResultResponseData } from 'api/aiSearch/types';
import { useUser } from 'components/Context/User';
import { AlertBar, Badge, Icon, Tooltip } from 'componentsNew';
import { ReactComponent as RobotIcon } from 'componentsNew/Icon/featured/Robot.svg';
import { useSnackbar } from 'contextNew/Snackbar';
import { Sheet } from 'layoutNew';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { translations } from 'translations';
import { GAonAIClearClick, GAonAISendPromptClick } from 'utils/analytics';

import { AIChatBubblePrompt } from './AIChatBubblePrompt';
import { AIChatBubbleResult } from './AIChatBubbleResult';
import { AIChatDisclaimer } from './AIChatDisclaimer';
import { AIChatInput } from './AIChatInput';
import { AIChatIntroduction } from './AIChatIntroduction';

const elementId = 'ai-chat-sheet';

export type ChatItemResult = {
  type: 'result';
  result: PromptResult;
  prompt: string;
  isDisliked: boolean;
  isLiked: boolean;
};

export type ChatItem = { type: 'prompt'; prompt: string } | ChatItemResult;

type AIChatSheetProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const AIChatSheet = ({ open, setOpen }: AIChatSheetProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chatInput, setChatInput] = useState<string>('');
  const [chatItems, setChatItems] = useState<ChatItem[]>([]);

  const user = useUser();
  const chatBottomRef = useRef<HTMLDivElement | null>(null);
  const { showSnackbar } = useSnackbar();

  const isUserProfileComplete = useMemo(
    () => Boolean(user.divisionId) && Boolean(user.countryId),
    [user.countryId, user.divisionId]
  );

  const fetchPromptResult = useCallback(
    async (text: string) => {
      try {
        const response = await aiSearchApi.sendPrompt({
          objectId: user.userId || '',
          prompt: text,
          dateTime: new Date().toString(),
          countryId: user.countryId || '',
          divisionId: user.divisionId || '',
          divisionName: user.divisionName || '',
          departmentId: user.departmentId || '',
          siteId: user.siteId || '',
          companyId: user.companyId || '',
          regionId: user.regionId || '',
        });
        const data = response.data.data as PromptResultResponseData;
        return aiSearchTransformers.transformPromptResult(data);
      } catch (e) {
        showSnackbar({
          type: 'error',
          text: translations.aiChatErrorGeneric,
        });
        return null;
      }
    },
    [
      user.userId,
      user.countryId,
      user.divisionId,
      user.divisionName,
      user.departmentId,
      user.siteId,
      user.companyId,
      user.regionId,
      showSnackbar,
    ]
  );

  const handlePromptSubmit = useCallback(async () => {
    if (isLoading || !chatInput || !isUserProfileComplete) {
      return;
    }
    GAonAISendPromptClick(chatInput);

    const chatItemPrompt: ChatItem = {
      type: 'prompt',
      prompt: chatInput,
    };
    const chatItemsWithPrompt = [...chatItems, chatItemPrompt];
    setChatItems(chatItemsWithPrompt);
    setChatInput('');

    setIsLoading(true);
    const result = await fetchPromptResult(chatItemPrompt.prompt);
    setIsLoading(false);

    if (!result) {
      return;
    }
    const chatItemResult: ChatItem = {
      type: 'result',
      prompt: chatInput,
      result,
      isDisliked: false,
      isLiked: false,
    };
    const chatItemsWithResult = [...chatItemsWithPrompt, chatItemResult];
    setChatItems(chatItemsWithResult);
  }, [
    chatInput,
    isLoading,
    isUserProfileComplete,
    chatItems,
    fetchPromptResult,
  ]);

  const onDisliked = useCallback(
    (chatItemIndex: number) => {
      const newChatItems = chatItems.map((chatItem, index) => {
        if (index === chatItemIndex && chatItem.type === 'result') {
          return {
            ...chatItem,
            isDisliked: true,
          };
        }
        return chatItem;
      });
      setChatItems(newChatItems);
    },
    [chatItems]
  );

  const onLiked = useCallback(
    (chatItemIndex: number) => {
      const newChatItems = chatItems.map((chatItem, index) => {
        if (index === chatItemIndex && chatItem.type === 'result') {
          return {
            ...chatItem,
            isLiked: true,
          };
        }
        return chatItem;
      });
      setChatItems(newChatItems);
    },
    [chatItems]
  );

  const setChatBottomRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      chatBottomRef.current = node;
      node.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  useEffect(() => {
    if (chatBottomRef.current && chatItems.length) {
      chatBottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatItems.length]);

  return (
    <Sheet
      id={elementId}
      open={open}
      width="33.125rem"
      showCloseIcon={false}
      onClose={() => setOpen(false)}
      sx={() => ({
        backgroundColor: '#FFFFFFDD',
        backdropFilter: 'blur(4px)',
        padding: 0,
      })}
    >
      <Stack
        sx={(theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          color: theme.colors.text.brand,
          backgroundColor: theme.colors.surface.primary,
          padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
          gap: theme.spacing(0.5),
        })}
      >
        <RobotIcon />
        <Typography variant="h3" component="h2">
          {translations.aiChatTitle}
        </Typography>
        <Badge
          size="small"
          color="default"
          variant="filled"
          label={translations.aiChatSubtitle}
          sx={{ marginRight: 'auto' }}
        />
        <Tooltip title={translations.aiChatClear}>
          <Box>
            <Button
              size="small"
              variant="text"
              disabled={isLoading}
              startIcon={<Icon type="eraser" color="brandBase" />}
              sx={(theme) => ({
                '&.MuiButton-text:not(.Mui-disabled)': {
                  border: `1px solid ${theme.colors.surface.actionTertiaryDefault}`,
                },
              })}
              onClick={() => {
                setChatItems([]);
                GAonAIClearClick();
              }}
            >
              {translations.clear}
            </Button>
          </Box>
        </Tooltip>
        <IconButton
          id={`${elementId}-close`}
          size="small"
          aria-label={translations.close}
          onClick={() => setOpen(false)}
        >
          <Icon type="xMark" color="secondary" />
        </IconButton>
      </Stack>
      <TransitionGroup>
        {!chatItems.length && (
          <Collapse>
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                justifyContent: 'center',
                padding: theme.spacing('md'),
              })}
            >
              <AIChatIntroduction />
            </Stack>
          </Collapse>
        )}
      </TransitionGroup>

      {chatItems.length > 0 && (
        <Stack
          sx={(theme) => ({
            flexDirection: 'column',
            gap: theme.spacing('xs'),
            padding: `${theme.spacing('sm')} ${theme.spacing('md')}`,
            overflowY: 'auto',
          })}
        >
          {chatItems.map((chatItem, index) =>
            chatItem.type === 'prompt' ? (
              <AIChatBubblePrompt
                key={`${elementId}-item-${index}`}
                id={`${elementId}-item-${index}`}
                prompt={chatItem.prompt}
              />
            ) : (
              <AIChatBubbleResult
                key={`${elementId}-item-${index}`}
                id={`${elementId}-item-${index}`}
                chatItem={chatItem}
                onDisliked={() => onDisliked(index)}
                onLiked={() => onLiked(index)}
              />
            )
          )}
          {isLoading && (
            <AIChatBubbleResult id={`${elementId}-item-loading`} loading />
          )}
          <Box ref={setChatBottomRef} />
        </Stack>
      )}

      <Stack
        sx={(theme) => ({
          gap: theme.spacing('xs'),
          padding: theme.spacing('md'),
          marginTop: 'auto',
        })}
      >
        {!user.isLoading && !isUserProfileComplete && (
          <AlertBar
            open
            fullWidth
            type="warning"
            title={translations.aiChatAlertIncompleteProfileTitle}
            text={translations.aiChatAlertIncompleteProfileText}
          />
        )}
        <AIChatInput
          loading={isLoading}
          disabled={!isUserProfileComplete}
          value={chatInput}
          onChange={setChatInput}
          onSubmit={handlePromptSubmit}
        />
        <AIChatDisclaimer />
      </Stack>
    </Sheet>
  );
};

export { AIChatSheet };
