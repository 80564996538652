import './UserImage.scss';

import classNames from 'classnames';
import { ReactComponent as Icon } from 'components/Icon/User.svg';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { getImageSrc } from 'utils/cms';

const UserImage = ({
  initials,
  src,
  size = 'small',
  className,
  desktopSize,
  name,
}) => {
  const userImageClassNames = classNames('user-image', {
    [`user-image--${size}`]: size,
    [`user-image--desktop-${desktopSize}`]: desktopSize,
    [className]: className,
  });

  const imageSrc = getImageSrc(src);

  const style = {
    backgroundImage: `url(${imageSrc})`,
  };

  return (
    <div className={userImageClassNames}>
      {src ? (
        <div
          className="user-image__circle user-image__circle--portrait"
          style={style}
          alt={name}
        />
      ) : initials ? (
        <figure className="user-image__circle user-image__circle--initials">
          {initials}
        </figure>
      ) : (
        <div className="user-image__circle user-image__circle--icon">
          <Icon />
        </div>
      )}
    </div>
  );
};

UserImage.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  initials: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  desktopSize: PropTypes.oneOf(['x-large', 'huge']),
  displayBlock: PropTypes.bool,
};

export { UserImage };
export default memo(UserImage);
