import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactDOM from 'react-dom';

class RelatedContentWrapper extends Component {
  state = {
    isVisible: false,
  };

  componentDidMount() {
    const { loadingDelay } = this.props;
    // Set a delay to wait for the debounce under resize
    setTimeout(() => {
      this.setState({ isVisible: true });
    }, loadingDelay);
  }

  render() {
    const { isVisible } = this.state;
    const { children, destinationElementId } = this.props;
    const targetElement = document.getElementById(destinationElementId);
    return isVisible && ReactDOM.createPortal(children, targetElement);
  }
}

RelatedContentWrapper.propTypes = {
  loadingDelay: PropTypes.number,
  destinationElementId: PropTypes.string.isRequired,
  children: PropTypes.any,
};

RelatedContentWrapper.defaultProps = {
  loadingDelay: 500,
};

export default RelatedContentWrapper;
