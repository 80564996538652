import { Consumer } from 'components/CreateOrEditArticle';
import BackgroundColor from 'components/CreateOrEditArticle/components/BackgroundColor';
import Body from 'components/CreateOrEditArticle/components/Body';
import Preamble from 'components/CreateOrEditArticle/components/Preamble';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import TextColor from 'components/CreateOrEditArticle/components/TextColor';
import Title from 'components/CreateOrEditArticle/components/Title';

const BodyBlockEditor = () => (
  <Consumer>
    {({
      validationParameters: {
        title: { maxLength },
      },
    }) => {
      const titlePlaceholder = `Title (max ${maxLength} characters)`;
      return (
        <>
          <Title placeholder={titlePlaceholder} />
          <Preamble placeholder="Preamble" />
          <Body placeholder="Body" />
          <BackgroundColor placeholder="Background color (#hex)" />
          <TextColor placeholder="Text color (#hex)" />
          <SortIndex />
        </>
      );
    }}
  </Consumer>
);

export default BodyBlockEditor;
