import { Elevation } from './theme';

const elevationOptions: Elevation = {
  xs: '0 0 0 1px rgba(26, 26, 26, 0.02), 0 1px 3px -1px rgba(26, 26, 26, 0.2)',
  sm: '0 0.3px 0.4px rgba(26, 26, 26, 0.02), 0 0.9px 1.5px rgba(26, 26, 26, .045), 0 3.5px 6px rgba(26, 26, 26, 0.09)',
  md: '0 0.9px 1.25px rgba(26, 26, 26, 0.025), 0 3px 5px rgba(26, 26, 26, 0.05), 0 12px 20px rgba(26, 26, 26, 0.09)',
  lg: '0 1.2px 1.9px -1px rgba(26, 26, 26, 0.01), 0 3px 5px -1px rgba(26, 26, 26, 0.05), 0 12px 20px rgba(26, 26, 26, 0.015), 0 8px 15px -1px rgba(26, 26, 26, 0.015), 0 28px 40px -1px rgba(26, 26, 26, 0.1)',
  xl: '0 1.5px 2.1px -6px rgba(26, 26, 26, 0.009), 0 3.6px 5.2px -6px rgba(26, 26, 26, 0.0115), 0 7.3px 10.6px -6px rgba(26, 26, 26, 0.0125), 0 16.2px 21.9px -6px rgba(26, 26, 26, 0.025), 0 46px 60px -6px rgba(26, 26, 26, 0.15)',
  ring: '0 0 0 1px rgba(26, 26, 26, 0.05)',
};

const createMuiElevation = () => {
  return elevationOptions;
};

export { createMuiElevation };
