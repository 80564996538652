import { AlertBar } from 'componentsNew';
import { translations } from 'translations';

const JobListError = () => (
  <AlertBar
    open
    type="critical"
    text={translations.jobListFetchError}
    sx={(theme) => ({ marginTop: theme.spacing('md') })}
  />
);

export { JobListError };
