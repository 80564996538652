import './Modal.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import Button from 'components/Button';
import Center from 'components/Center';
import Flex from 'components/Flex';
import { ReactComponent as Cancel } from 'components/Icon/Cancel.svg';
import { ReactComponent as Logo } from 'components/Icon/Logo.svg';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

const modalRoot = document.getElementById('modal');

class Modal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.state = {
      in: !props.animate,
    };
  }

  static Header = () => (
    <Center className="modal__header">
      <Logo />
    </Center>
  );

  static Footer = ({ children }) => (
    <Flex className="modal__footer p--4" direction="column" type="flex-end">
      {children}
    </Flex>
  );

  componentDidMount() {
    modalRoot.appendChild(this.el);
    document.body.classList.add('modal-open');
    if (this.props.blur) {
      document.body.classList.add('modal-open--overlay-blur');
    }
    if (this.props.showMenu) {
      document.body.classList.add('modal-open--show-menu');
    }
    if (this.props.focus) {
      this.focusFirstInput();
    }
    this.setState({ in: true });
    window.addEventListener('scroll', this.noscroll);
  }

  focusFirstInput() {
    const focusableEls = this.modal.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    );
    if (focusableEls.length === 0) {
      return false;
    }
    this.focusableEls = Array.prototype.slice.call(focusableEls);
    this.firstFocusableEl = focusableEls[0];
    this.firstFocusableEl.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.noscroll);
    modalRoot.removeChild(this.el);
    document.body.classList.remove(
      'modal-open',
      'modal-open--overlay-blur',
      'modal-open--show-header',
      'modal-open--show-menu'
    );
  }

  noscroll = () => {
    window.scrollTo(0, 0);
  };

  onKeyDown = (e) => {
    const escapeKey = 27;
    if (e.keyCode === escapeKey && this.props.onClose) {
      this.props.onClose();
    }
  };

  onClose = (e) => {
    e.preventDefault();

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const {
      size,
      children,
      title,
      errorMessage,
      buttons,
      animation,
      showMenu,
      transparency,
      centerButtons,
      disableClickOutside,
      blur,
      expandedMobile,
      closeButton,
      verticalAlign,
      noBorderRadius,
      isMobileSearch,
      accordionPadding,
    } = this.props;
    const modalClassName = classNames('modal', {
      [`modal--${size}`]: size,
      [`modal--show-menu`]: showMenu,
      'modal--transparency': transparency,
      'modal--blur': blur,
      'modal--expanded-mobile': expandedMobile === true,
      [`modal--animation-${animation}`]: animation,
      'modal--vertical-align': verticalAlign,
      'modal--no-border-radius': noBorderRadius,
      'modal--transparency--mobile-search': isMobileSearch,
    });

    const buttonClassName = classNames('m-t-2', {
      'modal__button-wrapper-center': centerButtons,
      'modal__button-wrapper': !centerButtons,
    });

    return ReactDOM.createPortal(
      <Transition in={this.state.in} timeout={100}>
        {(status) => (
          <div
            className={`${modalClassName} ${status}`}
            ref={(modal) => {
              this.modal = modal;
            }}
            aria-modal="true"
            role="dialog"
            onKeyDown={this.onKeyDown}
          >
            <ClickAwayListener
              onClickAway={disableClickOutside ? () => {} : this.onClose}
            >
              <div className={`modal__body ${accordionPadding}`}>
                {title && (
                  <h4
                    className="modal__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h4>
                )}
                {closeButton && <Cancel onClick={this.onClose} />}
                {children}
                {errorMessage && (
                  <p className="modal__error-message">{errorMessage}</p>
                )}

                {buttons && buttons.length && (
                  <div className={buttonClassName}>
                    {buttons.map((button, i) => (
                      <Button
                        color={button.color}
                        size="small"
                        onClick={button.onClick}
                        key={i}
                        disabled={button.disabled}
                        width="narrow"
                      >
                        {button.title}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </div>
        )}
      </Transition>,
      this.el
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'full']),
  transparency: PropTypes.bool,
  blur: PropTypes.bool,
  centerButtons: PropTypes.bool,
  title: PropTypes.string,
  errorMessage: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf(['black', 'white']).isRequired,
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  animate: PropTypes.bool,
  disableClickOutside: PropTypes.bool,
  animation: PropTypes.oneOf(['slow', 'fast']),
  expandedMobile: PropTypes.bool,
  focus: PropTypes.bool,
  closeButton: PropTypes.bool,
  verticalAlign: PropTypes.bool,
  noBorderRadius: PropTypes.bool,
  isMobileSearch: PropTypes.bool,
  accordionPadding: PropTypes.string,
};

Modal.defaultProps = {
  size: 'sm',
  animate: true,
  blur: false,
  animation: 'fast',
  showMenu: false,
  transparency: true,
  expandedMobile: false,
  focus: true,
  closeButton: false,
  accordionPadding: '',
};

export default Modal;
