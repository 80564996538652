import Skeleton from '@mui/material/Skeleton';

const JobListSkeleton = () => (
  <>
    {Array(15)
      .fill(0)
      .map((_, i) => (
        <Skeleton
          key={`job-list-skeleton-${i}`}
          variant="rounded"
          animation="wave"
          sx={(theme) => ({
            height: '5.5rem',
            '&:first-of-type': { marginTop: theme.spacing('md') },
          })}
        />
      ))}
  </>
);

export { JobListSkeleton };
