import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import 'abortcontroller-polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { initializeGA } from 'utils/analytics';
import configureAuth, { configureAxios } from 'utils/auth';
import { msalGetActiveAccount } from 'utils/auth';

import PublicRoot from './pages/Public/Root';
import Root from './pages/Root';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) =>
    registration.unregister()
  );
}
const isPublicPage = /^\/public/.test(window.location.pathname);

const container = document.getElementById('root');
const root = createRoot(container);

//TODO: would be great to create forceredirect component to manage those
if (window.location.pathname === '/faq') {
  window.location.href = `${window.location.origin}/public/faq`;
} else if (window.location.pathname === '/employee-information') {
  window.location.href = `${window.location.origin}/public/employee-information`;
} else if (isPublicPage) {
  configureAxios();
  initializeGA();
  root.render(
    <React.StrictMode>
      <Router>
        <PublicRoot />
      </Router>
    </React.StrictMode>
  );
} else {
  configureAuth().then(() => {
    const account = msalGetActiveAccount();
    if (!account) return;
    const userId = account.idTokenClaims.oid;
    initializeGA(userId);
    root.render(
      <React.StrictMode>
        <Root />
      </React.StrictMode>
    );
  });
}
