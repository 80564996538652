import { TabName } from './TabArticles/TabArticles';

type NewsFlashesFilterParams = {
  division: string | undefined;
  country: string | undefined;
  department: string | undefined;
  segment: string | undefined;
  site: string | undefined;
  company: string | undefined;
  isGlobalFeed?: boolean;
  storyDisplayOnHome?: boolean;
  locale: string;
  type: {
    pressrelease: boolean;
    newsarticle: boolean;
    blogpost: boolean;
    storyarticle: boolean;
  };
  localization: {
    global: boolean;
    division: boolean;
    segment: boolean;
    country: boolean;
    department: boolean;
    site: boolean;
    company: boolean;
  };
};

type NextArticleFilter = {
  countryId?: string;
  divisionId?: string;
  departmentId?: string;
  segmentId?: string;
  siteId?: string;
  companyId?: string;
  locale: string;
  blog: boolean;
  news: boolean;
  press: boolean;
  story: boolean;
  onlyGlobalItems: boolean;
  onlyMatchingCountry: boolean;
  onlyMatchingCompany: boolean;
  onlyMatchingDepartment: boolean;
  onlyMatchingDivision: boolean;
  onlyMatchingSegment: boolean;
  onlyMatchingSite: boolean;
  topNewsOnly: boolean;
  isGlobalFeed?: boolean;
  isGlobalForMyFeed?: boolean;
  publishedEndDate?: string;
  publishedStartDate?: string;
};

const getNextInYourFeedFilter = (
  filter: TabName,
  filterData: {
    countryId: string;
    divisionId: string;
    segmentId: string;
    departmentId: string;
    siteId: string;
    companyId: string;
    locale: string;
  }
): NextArticleFilter => {
  const defaultParams: NextArticleFilter = {
    countryId: filterData.divisionId ? filterData.countryId : undefined,
    divisionId: filterData.divisionId,
    departmentId: filterData.departmentId,
    segmentId: filterData.segmentId,
    siteId: filterData.siteId,
    companyId: filterData.companyId,
    locale: filterData.locale,
    blog: false,
    news: false,
    press: false,
    story: false,
    onlyGlobalItems: false,
    onlyMatchingCountry: false,
    onlyMatchingCompany: false,
    onlyMatchingDepartment: false,
    onlyMatchingDivision: false,
    onlyMatchingSegment: false,
    onlyMatchingSite: false,
    topNewsOnly: false,
    isGlobalFeed: false,
    isGlobalForMyFeed: false,
    publishedEndDate: '',
    publishedStartDate: '',
  };
  switch (filter) {
    case TabName.Division:
      return {
        ...defaultParams,
        onlyMatchingDivision: true,
      };
    case TabName.Department:
      return {
        ...defaultParams,
        onlyMatchingDepartment: true,
      };
    case TabName.Site:
      return {
        ...defaultParams,
        onlyMatchingSite: true,
      };
    case TabName.Global:
      return {
        ...defaultParams,
        isGlobalFeed: true,
      };
    default:
      return defaultParams;
  }
};

const getNewsFlashesFilter = (
  filter: TabName | 'stories',
  filterData: {
    countryId: string;
    divisionId: string;
    segmentId: string;
    departmentId: string;
    siteId: string;
    companyId: string;
    locale: string;
  }
): NewsFlashesFilterParams => {
  const defaultParams: NewsFlashesFilterParams = {
    country: filterData.divisionId ? filterData.countryId : undefined,
    division: filterData.divisionId,
    department: filterData.departmentId,
    site: filterData.siteId,
    segment: filterData.segmentId,
    company: filterData.companyId,
    locale: filterData.locale,
    type: {
      newsarticle: true,
      blogpost: true,
      storyarticle: true,
      pressrelease: true,
    },
    localization: {
      global: false,
      country: false,
      division: false,
      segment: false,
      department: false,
      site: false,
      company: false,
    },
  };
  switch (filter) {
    case TabName.Division:
      return {
        ...defaultParams,
        localization: { ...defaultParams.localization, division: true },
      };
    case TabName.Department:
      return {
        ...defaultParams,
        localization: { ...defaultParams.localization, department: true },
      };
    case TabName.Site:
      return {
        ...defaultParams,
        localization: { ...defaultParams.localization, site: true },
      };
    case TabName.Global:
      return {
        ...defaultParams,
        isGlobalFeed: true,
      };
    case 'stories':
      return {
        ...defaultParams,
        type: {
          newsarticle: false,
          blogpost: false,
          storyarticle: true,
          pressrelease: false,
        },
        storyDisplayOnHome: true,
      };
    default:
      return defaultParams;
  }
};

export { getNewsFlashesFilter, getNextInYourFeedFilter };
