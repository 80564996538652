import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import settings from 'settings';
const { tenant, clientId, authorityUri, redirectUri } = settings.auth;

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: `${authorityUri}${tenant}`,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ['openid'],
};

export const accessTokenRequest = {
  scopes: [`${clientId}/user_impersonation`],
};

export const logout = () => {
  const account = msalInstance.getActiveAccount();

  const logoutRequest = {
    account: account,
  };

  msalInstance.logoutRedirect(logoutRequest);
};

export const configureAxios = (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  axios.interceptors.response.use(
    (config) => {
      delete config.data.error;
      return config;
    },
    async (err) => {
      if (!(err && err.response)) {
        throw err;
      }
      if (
        err.response.status === 401 &&
        err.response.config &&
        !err.response.config.__isRetryRequest
      ) {
        err.response.config.__isRetryRequest = true;

        let accessToken = '';

        try {
          const silentResponse = await msalInstance.acquireTokenSilent(
            accessTokenRequest
          );
          accessToken = silentResponse.accessToken;
        } catch (err) {
          const redirectResponse = await msalInstance.acquireTokenRedirect(
            accessTokenRequest
          );

          accessToken = redirectResponse.accessToken;
        }

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${accessToken}`;
        err.config.headers['Authorization'] = `Bearer ${accessToken}`;

        return await axios(err.config);
      }
      if (err.response.data && typeof err.response.data.error === 'string') {
        err.message = err.response.data.error;
      } else if (err.response.data && err.response.data.error) {
        err.message =
          err.response.data.error[err.response.status] ||
          err.response.data.error['*'];
      }

      throw err;
    }
  );
};

const getTokenRedirect = () => {
  return msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then((request) => {
      configureAxios(request.accessToken);
      return request;
    })

    .catch(async (error) => {
      if (error) {
        await msalInstance
          .acquireTokenRedirect(accessTokenRequest)
          .then((request) => {
            configureAxios(request.accessToken);
            return request;
          });

        return;
      } else {
        console.warn(error);
      }
    });
};

const configureAuth = async () => {
  await msalInstance
    .handleRedirectPromise()
    .then(setAccountAndGetToken)
    .catch((error) => console.error(error));
};

const setAccountAndGetToken = async (response) => {
  if (response !== null) {
    msalInstance.setActiveAccount(response.account);

    return new Promise((resolve, reject) => {
      getTokenRedirect()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.error(error);
          return reject();
        });
    });
  } else {
    await msalInstance.loginRedirect(loginRequest);
  }
};

export const msalGetActiveAccount = () => {
  return msalInstance.getActiveAccount();
};

export default configureAuth;
