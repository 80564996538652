import { useState } from 'react';
import Measure from 'react-measure';

const withContentMeasurer = (WrappedComponent) => {
  const WrappedComponentWithContentMeasurer = (props) => {
    const [contentSize, setContentSize] = useState(null);
    const onElementResize = ({ entry }) => entry && setContentSize(entry.width);
    return (
      <>
        <Measure onResize={onElementResize}>
          {({ measureRef }) => (
            <div className="content-measurer__element" ref={measureRef}></div>
          )}
        </Measure>
        <WrappedComponent
          contentSize={contentSize /* Trigger component update */}
          measureContentWidth={() => contentSize}
          {...props}
        >
          {props.children}
        </WrappedComponent>
      </>
    );
  };
  return WrappedComponentWithContentMeasurer;
};

export default withContentMeasurer;
