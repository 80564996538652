import { listCategories } from 'api/cms/portals';
import Error from 'components/Error';
import Select from 'components/Select';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Consumer } from '..';

class SelectFilter extends Component {
  render() {
    const {
      onChange,
      placeholder,
      selected,
      mandatory,
      multiple,
      showInitialError,
      options = [],
      deSelectEnabled,
    } = this.props;
    const noSelectedValue = selected?.length === 0;

    return (
      <Select
        data={options.map((o) => ({
          name: o.name,
          value: o.value,
        }))}
        onChange={onChange}
        placeholder={placeholder}
        selected={selected}
        showError={showInitialError && noSelectedValue && mandatory}
        multiple={multiple}
        deSelectEnabled={deSelectEnabled}
      />
    );
  }
}

SelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
  mandatory: PropTypes.bool,
  multiple: PropTypes.bool.isRequired,
  showInitialError: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
};

class TagListEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insightsCategories: null,
      insightsCategoriesLogicalNames: null,
      insightsCategoriesMandatoryFields: null,
      filterValues: null,
      filterTypes: null,
      error: false,
      errorCode: null,
    };
  }

  async componentDidMount() {
    const categoriesResponse = await this.getInsightsCategories();

    if (categoriesResponse) {
      let categories = categoriesResponse.data.data.attributes.children;
      this.setInsightCategoriesTypeState(categories);
      this.setInsightCategoriesState(categories);
      this.setInsightCategoriesLogicalNamesAndSelectedState(categories);
      this.setMandatoryFieldsState(categories);
      this.setInitialState();
    }
  }

  getInsightsCategories = async () => {
    try {
      const categories = await listCategories('insights-categories');
      return categories;
    } catch (e) {
      return this.setState({
        error: true,
        errorMessage: e.message,
      });
    }
  };

  getInsightsCategoriesSelected = (logicalNames) => {
    const response = this.props.attributes;
    let selectedCategories = {};
    const categoriesKeys = Object.keys(logicalNames || {});
    categoriesKeys.forEach((category) => {
      selectedCategories[category] = response[category] || [];
    });
    return selectedCategories;
  };

  setInsightCategoriesTypeState = (categories) => {
    let obj = {};

    categories.forEach((category) => {
      const { logicalName, isMultiple } = category.attributes;
      obj[logicalName] = {
        isMultiple: isMultiple ? true : false,
      };
    });

    this.setState({ filterTypes: obj });
  };

  setInsightCategoriesState = (categories) => {
    let unorderedObj = {};
    let orderedObj = new Map();

    categories.forEach((category) => {
      const { logicalName, sortIndex } = category.attributes;
      const children = (category.attributes.children || []).map((child) => {
        return {
          name: child.attributes.title,
          value: child.id,
        };
      });
      unorderedObj[logicalName] = {
        sortIndex: sortIndex,
        children: children,
      };
    });

    Object.keys(unorderedObj)
      .sort((a, b) => {
        return unorderedObj[a].sortIndex - unorderedObj[b].sortIndex;
      })
      .forEach((key) => {
        orderedObj.set(key, unorderedObj[key].children);
      });

    this.setState({ insightsCategories: orderedObj });
  };

  setInsightCategoriesSelectedState = (logicalNames) => {
    const selectedCategories = this.getInsightsCategoriesSelected(logicalNames);
    let obj = {};
    for (let key in selectedCategories) {
      let selectedCategory = selectedCategories[key];
      // if it has values
      if (selectedCategory) {
        obj[key] = [];
        selectedCategory.forEach((child) => {
          let childData = {
            name: child.title,
            value: child.id,
          };
          obj[key].push(childData);
        });
      }
    }
    this.setState({ insightsCategoriesSelected: obj });
  };

  setInsightCategoriesLogicalNamesAndSelectedState = (categories) => {
    let obj = {};
    let emptyObj = {};

    categories.forEach((category) => {
      const { logicalName, title } = category.attributes;
      obj[logicalName] = { id: category.id, title };
      emptyObj[logicalName] = [];
    });

    if (this.props.isCreating) {
      this.setState({
        insightsCategoriesLogicalNames: obj,
        insightsCategoriesSelected: emptyObj,
      });
    } else {
      // we set categories selected state only after we set logical names state
      this.setState({ insightsCategoriesLogicalNames: obj }, () => {
        this.setInsightCategoriesSelectedState(obj);
      });
    }
  };

  setMandatoryFieldsState = (categories) => {
    let obj = {};

    categories.forEach((category) => {
      const { logicalName, isMandatory } = category.attributes;
      obj[logicalName] = { isMandatory };
    });

    this.setState({ insightsCategoriesMandatoryFields: obj });
  };

  setInitialState = () => {
    const filterValues = {
      ...this.state.insightsCategoriesSelected,
    };

    this.setState({ filterValues });
  };

  setFilters = (filterKey, selected) => {
    let selectedOptions = [];
    if (!Array.isArray(selected)) {
      // if we have single select
      selectedOptions.push(selected);
    } else {
      // if multiple
      selectedOptions = selected;
    }
    const filterValues = {
      ...this.state.filterValues,
      [filterKey]: selectedOptions.map((s) => ({
        value: s.value,
        name: s.name.replace(/ \([0-9]*\)/g, ''),
      })),
    };

    this.setState({ filterValues });
  };

  updateSelectFilters(onChangeArticle, { key, selected }) {
    selected = selected ? selected : [];
    this.setFilters(key, selected);
    let selectedOptions = [];
    if (!Array.isArray(selected)) {
      // if we have single select
      selectedOptions.push(selected);
    } else {
      // if multiple
      selectedOptions = selected;
    }
    const payload = {
      [key]: selectedOptions.map((item) => {
        return {
          id: item.value,
        };
      }),
    };

    onChangeArticle(payload);
  }

  render() {
    const filterOptions = this.state.insightsCategories;
    const filterPlaceholders = this.state.insightsCategoriesLogicalNames;
    const filterValues = this.state.filterValues;
    const filterTypes = this.state.filterTypes;
    const filterMandatory = this.state.insightsCategoriesMandatoryFields;
    const { error, errorMessage } = this.state;
    const isLoading = !filterOptions;
    const divStyle = {
      maxWidth: '300px',
    };

    if (error) {
      return <Error message={errorMessage} />;
    }

    if (!filterOptions || !filterValues) {
      return null;
    }

    return (
      <Consumer>
        {({ onChangeArticle, showInitialError }) => {
          return (
            filterOptions &&
            Array.from(filterOptions.keys()).map((key) => (
              <div key={key}>
                <p>Select {filterPlaceholders[key].title}</p>
                <div style={divStyle}>
                  <SelectFilter
                    isLoading={isLoading}
                    options={filterOptions && filterOptions.get(key)}
                    placeholder={`Select ${filterPlaceholders[key].title}`}
                    mandatory={filterMandatory[key].isMandatory}
                    multiple={filterTypes[key].isMultiple}
                    deSelectEnabled={!filterTypes[key].isMultiple}
                    showInitialError={showInitialError}
                    selected={filterValues[key]}
                    onChange={(selected) =>
                      this.updateSelectFilters(onChangeArticle, {
                        key,
                        selected,
                      })
                    }
                  />
                </div>
                <hr className="m-t-6 m-b-5" />
              </div>
            ))
          );
        }}
      </Consumer>
    );
  }
}

TagListEditor.propTypes = {
  isCreating: PropTypes.bool.isRequired,
  attributes: PropTypes.object.isRequired,
};

export default TagListEditor;
