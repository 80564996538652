import './ArticleItem.scss';

import SearchHit from 'api/models/SearchHit';
import Img from 'components/Image';
import { saveScrollPosition } from 'components/ScrollRestoration';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  GAonClickAvenueOverlaySearchResult,
  GAonSearchResultClick,
} from 'utils/analytics';
import { mediumImageWidth } from 'utils/cms';
import { getDateOrDaysAgo } from 'utils/misc/time';

const LinkWrapper = ({ children, label, url, isFullList, title }) => (
  <Link
    to={url}
    onClick={() => {
      GAonSearchResultClick(label);
      isFullList && saveScrollPosition('find');
      GAonClickAvenueOverlaySearchResult(title);
    }}
    className="article-item__link"
  >
    {children}
  </Link>
);

const ArticleItem = ({ searchHit, isFullList }) => {
  const url = searchHit.getUrl();

  if (!url) {
    return null;
  }

  const label = searchHit.getTypeLabel();
  const { imageUrl, summary, title, published } = searchHit.getAttributes();

  return (
    <div className="article-item">
      <div className="article-item__body">
        <LinkWrapper
          label={label}
          url={url}
          isFullList={isFullList}
          title={title}
        >
          <div className="article-item__image-container">
            {imageUrl && (
              <Img
                className="article-item__image"
                src={imageUrl}
                type="feed"
                maxWidth={mediumImageWidth}
              />
            )}
          </div>
        </LinkWrapper>
        <div className="article-item__inner-wrapper">
          <div className="article-item__label-wrapper">
            {searchHit.getArticleLabels().map((l, i) => {
              return i === 0 ? (
                <div className="article-item__label"> {l.name} </div>
              ) : (
                <div className="article-item__label">| {l.name} </div>
              );
            })}
          </div>

          <h2 className="article-item__header">
            <LinkWrapper
              label={label}
              url={url}
              isFullList={isFullList}
              title={title}
            >
              <span className="article-item__header-title">{title}</span>
            </LinkWrapper>
          </h2>
          <p className="article-item__preamble">{summary && Parser(summary)}</p>
          <div className="article-item__published">
            {published && getDateOrDaysAgo(published)}
          </div>
        </div>
      </div>
    </div>
  );
};

ArticleItem.propTypes = {
  searchHit: PropTypes.instanceOf(SearchHit).isRequired,
  isFullList: PropTypes.bool,
};

export default ArticleItem;
