import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as cmsPortalsApi from 'api/cms/portals/';
import * as cmsPortalsTransformers from 'api/cms/portals/transformers';
import { PortalSearchArticle } from 'api/cms/portals/transformers';
import * as feedbackApi from 'api/feedback';
import * as feedbackTransformers from 'api/feedback/transformers';
import {
  LikesAndCommentsMeta,
  LikesAndCommentsResponse,
  UpdateLikeResponse,
} from 'api/feedback/transformers';
import { useUser } from 'components/Context/User';
import {
  ArticleImage,
  ArticleTitle,
  CommentButton,
  LikeButton,
} from 'componentsNew';
import { PageContentHeader } from 'layoutNew';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { translations } from 'translations';
import {
  GAonClickHomeInsightsDatabaseItem,
  GAonClickHomeInsightsDatabaseReadMore,
  GAonCommentFeedClick,
  GAonLikeSent,
} from 'utils/analytics';
import * as timeUtils from 'utils/misc/time';

import * as helpers from './helpers';
import { InsightsDatabaseSkeleton } from './InsightsDatabaseSkeleton';
import { NoInsightsDatabaseArticles } from './NoInsightsDatabaseArticles';

const elementId = 'home-insights-database';

export type InsightsDatabaseArticlePreview = PortalSearchArticle & {
  likesAndCommentsMeta?: LikesAndCommentsMeta;
};

const InsightsDatabase = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [articles, setArticles] = useState<
    InsightsDatabaseArticlePreview[] | null
  >(null);

  const user = useUser();
  const theme = useTheme();

  const fetchArticles = useCallback(async (userId: string) => {
    setIsLoading(true);
    let articles: InsightsDatabaseArticlePreview[] = [];
    let likesAndCommentsMetaByArticleId: Record<string, LikesAndCommentsMeta> =
      {};

    // Fetch portal search articles
    try {
      const query = helpers.getQueryForLatestInsightsDatabaseArticles();
      const response = await cmsPortalsApi.getInsightsArticles(query);
      articles =
        cmsPortalsTransformers.portalSearchResponseToPortalSearchArticles(
          response
        );
      if (!articles.length) {
        setIsLoading(false);
        return [];
      }
    } catch {
      setIsLoading(false);
      return [];
    }

    // Fetch likes and comments
    try {
      const articleIds = articles.map((article) =>
        article.id.replace('cms-', '')
      );
      const likesAndCommentsResponse = (await feedbackApi.getLikesAndComments(
        articleIds
      )) as LikesAndCommentsResponse;

      likesAndCommentsMetaByArticleId =
        feedbackTransformers.likesAndCommentsResponseToLikesAndCommentsMetaByArticleId(
          likesAndCommentsResponse,
          userId
        );

      articles = articles.map((article) => {
        const likesAndCommentsMeta =
          likesAndCommentsMetaByArticleId[article.id];
        return likesAndCommentsMeta
          ? { ...article, likesAndCommentsMeta }
          : article;
      });
    } catch {
    } finally {
      setIsLoading(false);
      return articles;
    }
  }, []);

  const updateLike = useCallback(async (articleId: string, like: boolean) => {
    let result: { isLikedByMe: boolean; likesCount: number } | null = null;
    try {
      const response = like
        ? ((await feedbackApi.sendLike(
            articleId.replace('cms-', '')
          )) as UpdateLikeResponse)
        : ((await feedbackApi.deleteLike(
            articleId.replace('cms-', '')
          )) as UpdateLikeResponse);

      result = {
        isLikedByMe: response.data.meta.likedByMe,
        likesCount: response.data.meta.total,
      };
    } catch {}
    return result;
  }, []);

  const handleLikeChange = useCallback(
    async (articleId: string, like: boolean) => {
      const result = await updateLike(articleId, like);
      if (!result) return;
      if (!articles) return;

      const newArticles = articles.map((article) => {
        if (article.id !== articleId) {
          return article;
        }
        if (!article.likesAndCommentsMeta) {
          return article;
        }
        const newArticle: InsightsDatabaseArticlePreview = {
          ...article,
          likesAndCommentsMeta: {
            ...article.likesAndCommentsMeta,
            isLikedByMe: result.isLikedByMe,
            likesCount: result.likesCount,
          },
        };
        return newArticle;
      });
      setArticles(newArticles);
    },
    [articles, updateLike]
  );

  useEffect(() => {
    async function fetchInitialData() {
      const articles = await fetchArticles(user.userId);
      if (!articles) return;
      setArticles(articles);
    }
    fetchInitialData();
  }, [fetchArticles, user]);

  const titleAndSubtitle = useMemo(
    () => (
      <PageContentHeader text={translations.insightsDatabaseTitle}>
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: theme.colors.text.tertiary,
            paddingTop: theme.spacing('xxxs'),
          })}
        >
          {translations.insightsDatabaseSubtitle}
        </Typography>
      </PageContentHeader>
    ),
    []
  );

  if (isLoading || !articles) {
    return (
      <Stack gap={theme.spacing('md')}>
        {titleAndSubtitle}
        <InsightsDatabaseSkeleton />
      </Stack>
    );
  }

  if (!articles.length) {
    return (
      <Stack gap={theme.spacing('sm')}>
        {titleAndSubtitle}
        <NoInsightsDatabaseArticles />
      </Stack>
    );
  }

  return (
    <Stack gap={theme.spacing('md')}>
      {titleAndSubtitle}
      <Stack
        id={elementId}
        sx={(theme) => ({
          gap: theme.spacing('md'),
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        })}
      >
        {articles.map((article, index) => {
          const formattedPublishedDate =
            article.published && timeUtils.getDateOrDaysAgo(article.published);

          const category = article.categories.length && article.categories[0];

          return (
            <Stack
              key={article.id}
              id={`${elementId}-item-${index}`}
              sx={(theme) => ({
                flex: {
                  xs: '1 1 1',
                  md: '1 1 0',
                },
                overflow: 'hidden',
                borderRadius: theme.border.radius.md,
                maxWidth: {
                  md: `calc((100% - (${theme.spacing('md')} * 2)) / 3)`,
                },
              })}
            >
              <ArticleImage
                src={article.imageUrl}
                altText={article.imageAltText}
                type={article.type}
                href={article.linkUrl || ''}
                fetchSize={articles.length === 1 ? 'xl' : 'md'}
                sx={{ aspectRatio: 16 / 9 }}
                onClick={() => GAonClickHomeInsightsDatabaseItem(article.title)}
              />
              <Stack
                id={`insights-database-item-${index}`}
                sx={(theme) => ({
                  flexGrow: 1,
                  padding: theme.spacing('xs'),
                  backgroundColor: theme.colors.surface.secondary,
                })}
              >
                {category && (
                  <Typography
                    variant="caption"
                    sx={(theme) => ({
                      alignSelf: 'start',
                      lineHeight: 1.2,
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      color: theme.palette.neutral[500],
                      padding: `0 ${theme.spacing('xxxxs')}`,
                      marginBottom: theme.spacing('xs'),
                      borderBottom: `${theme.border.thickness[3]} solid ${theme.colors.icon.brandBase}`,
                    })}
                  >
                    {category.title.toUpperCase()}
                  </Typography>
                )}
                <ArticleTitle
                  title={article.title}
                  href={article.linkUrl || ''}
                  color={theme.colors.text.secondary}
                  variant="h3"
                  onClick={() =>
                    GAonClickHomeInsightsDatabaseItem(article.title)
                  }
                />
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.colors.text.tertiary,
                  })}
                >
                  {formattedPublishedDate}
                </Typography>

                {article.likesAndCommentsMeta && (
                  <Stack
                    sx={() => ({
                      flexDirection: 'row',
                      marginTop: 'auto',
                      width: '100%',
                    })}
                  >
                    <LikeButton
                      id={`${elementId}-item-${index}-like`}
                      isLikedByMe={article.likesAndCommentsMeta.isLikedByMe}
                      likesCount={article.likesAndCommentsMeta.likesCount}
                      sx={(theme) => ({
                        marginLeft: 'auto',
                        marginRight: theme.spacing('xs'),
                      })}
                      onClick={() => {
                        const like = !article.likesAndCommentsMeta?.isLikedByMe;
                        handleLikeChange(article.id, like);
                        if (like) GAonLikeSent(article.title, true);
                      }}
                    />
                    <CommentButton
                      id={`${elementId}-item-${index}-comment`}
                      isCommentedByMe={
                        article.likesAndCommentsMeta.isCommentedByMe
                      }
                      commentsCount={article.likesAndCommentsMeta.commentsCount}
                      href={article.linkUrl}
                      onClick={() => GAonCommentFeedClick(article.title)}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      <Button
        id={`${elementId}-read-more`}
        to={helpers.INSIGHTS_DATABASE_LINK_URL}
        variant="linkButton"
        component={ReactRouterLink}
        onClick={GAonClickHomeInsightsDatabaseReadMore}
        sx={{ alignSelf: 'end' }}
      >
        {translations.insightsDatabaseReadMore}
      </Button>
    </Stack>
  );
};

export { InsightsDatabase };
