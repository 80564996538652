import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const getChannels = (queryParam = '') =>
  createRequest({
    method: 'get',
    url: `${cms}/topic/${queryParam}`,
    errorMessages: {
      '*': 'Failed to get topics, please contact support for assistance.',
    },
  });

export const getPinnedChannels = () =>
  createRequest({
    method: 'get',
    url: `${cms}/topic/pinned`,
    errorMessages: {
      '*': 'Failed to get topics, please contact support for assistance.',
    },
  });

export const getChannel = (name) =>
  createRequest({
    method: 'get',
    url: `${cms}/topic/${name}`,
    errorMessages: {
      '*': 'Failed to get topic, please contact support for assistance.',
    },
  });

export const updateChannel = (id, data) =>
  createRequest({
    method: 'put',
    url: `${cms}/topic/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update topic, please contact support for assistance.',
    },
  });

export const createChannel = (data) =>
  createRequest({
    method: 'post',
    url: `${cms}/topic`,
    data,
    errorMessages: {
      '*': 'Failed to create topic, please contact support for assistance.',
    },
  });

export const deleteChannel = (id) =>
  createRequest({
    method: 'delete',
    url: `${cms}/topic/${id}`,
    errorMessages: {
      '*': 'Failed to delete topic, please contact support for assistance.',
    },
  });
