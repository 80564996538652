import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const RedirectAll = ({ from, to }) => (
  <Route
    path={from}
    render={(props) => {
      const fromRegExp = new RegExp(`^${from}`);
      const redirectUrl = props.location.pathname.replace(fromRegExp, to);
      return <Redirect to={redirectUrl} {...props} />;
    }}
  />
);

RedirectAll.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default RedirectAll;
