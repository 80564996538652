import './GlossaryItem.scss';

import SearchHit from 'api/models/SearchHit';
import Parser from 'components/Parser';
import PropTypes from 'prop-types';

import ContentLabels from '../../../../components/ContentLabels';

const GlossaryItem = ({ searchHit }) => {
  const { title, body } = searchHit.getAttributes();

  return (
    <div className="glossary-box">
      <div className="glossary-box__item">
        <div className="glossary-box__body">
          <ContentLabels
            className="glossary-box__label"
            labels={searchHit.getGlossaryLabels()}
          />
          <h2 className="glossary-box__header">{title}</h2>
          <p className="glossary-box__abbreviation">
            <Parser html={body} type="Feed" />
          </p>
        </div>
      </div>
    </div>
  );
};

GlossaryItem.propTypes = {
  searchHit: PropTypes.instanceOf(SearchHit).isRequired,
};

export default GlossaryItem;
