import {
  createNode,
  deleteNode,
  listDescendants,
  updateNode,
} from 'api/cms/portals';
import fallBackImageBlog from 'componentsNew/ArticleList/article-fallback-image-blog.jpg';
import settings from 'settings';
import { getImageSrc } from 'utils/cms';

import DataModel from './DataModel';

const { portals } = settings;

export const TEMPLATES = {
  ITEM: 'item',
  LISTING: 'listing',
  CONTENT: 'content',
  PORTAL: 'portal',
  PORTAL_BLOCK: 'heroblock',
  BODY_BLOCK: 'bodyblock',
  DIVIDER_BLOCK: 'dividerblock',
  CUSTOM_BLOCK: 'customblock',
  ARTICLE_LIST_BLOCK: 'article-list-block',
  GRADIENT_BLOCK: 'gradientblock',
  INNOVATION_START: 'innovation-start',
  STRATEGY_START: 'strategy-start',
  INSIGHTS_LISTING: 'insights-listing',
  INSIGHTS_CONTENT: 'insights-content',
  CODEOFCONDUCT_START: 'strategy-start',
  EVENTS_LIST_BLOCK: 'events-list-block',
  AVENUE_FEED_LISTING_BLOCK: 'avenue-feed-listing-block',
  VIVA_ENGAGE_EMBED_BLOCK: 'viva-engage-embed-block',
};

const allowedChildPages = {
  [TEMPLATES.ITEM]: null,
  [TEMPLATES.LISTING]: [TEMPLATES.LISTING, TEMPLATES.CONTENT],
  [TEMPLATES.CONTENT]: [TEMPLATES.ITEM],
  [TEMPLATES.PORTAL]: [
    TEMPLATES.LISTING,
    TEMPLATES.CONTENT,
    TEMPLATES.INSIGHTS_LISTING,
    TEMPLATES.INSIGHTS_CONTENT,
  ],
  [TEMPLATES.PORTAL_BLOCK]: null,
  [TEMPLATES.INNOVATION_START]: [TEMPLATES.LISTING, TEMPLATES.CONTENT],
  [TEMPLATES.CODEOFCONDUCT_START]: [TEMPLATES.LISTING, TEMPLATES.CONTENT],
  [TEMPLATES.STRATEGY_START]: [TEMPLATES.LISTING, TEMPLATES.CONTENT],
  [TEMPLATES.INSIGHTS_LISTING]: [TEMPLATES.INSIGHTS_CONTENT],
};

const blockTypes = [
  TEMPLATES.PORTAL_BLOCK,
  TEMPLATES.BODY_BLOCK,
  TEMPLATES.DIVIDER_BLOCK,
  TEMPLATES.CUSTOM_BLOCK,
  TEMPLATES.ARTICLE_LIST_BLOCK,
  TEMPLATES.GRADIENT_BLOCK,
  TEMPLATES.EVENTS_LIST_BLOCK,
  TEMPLATES.AVENUE_FEED_LISTING_BLOCK,
  TEMPLATES.VIVA_ENGAGE_EMBED_BLOCK,
];

const allowedChildBlocks = {
  [TEMPLATES.INNOVATION_START]: blockTypes,
  [TEMPLATES.CODEOFCONDUCT_START]: blockTypes,
  [TEMPLATES.STRATEGY_START]: blockTypes,
  [TEMPLATES.CONTENT]: blockTypes,
  [TEMPLATES.LISTING]: blockTypes,
  [TEMPLATES.INSIGHTS_LISTING]: blockTypes,
};

// aadw:portal-listing , aadw:portal-article

const pageTypes = {
  [TEMPLATES.ITEM]: 'aadw:portal-article',
  [TEMPLATES.LISTING]: 'aadw:portal-listing',
  [TEMPLATES.CONTENT]: 'aadw:portal-listing',
  [TEMPLATES.PORTAL]: 'aadw:portal-listing',
  [TEMPLATES.PORTAL_BLOCK]: 'aadw:portal-block',
  [TEMPLATES.BODY_BLOCK]: 'aadw:portal-block',
  [TEMPLATES.DIVIDER_BLOCK]: 'aadw:portal-block',
  [TEMPLATES.CUSTOM_BLOCK]: 'aadw:portal-custom-block',
  [TEMPLATES.ARTICLE_LIST_BLOCK]: 'aadw:portal-article-list-block',
  [TEMPLATES.INNOVATION_START]: 'aadw:innovation-start',
  [TEMPLATES.CODEOFCONDUCT_START]: 'aadw:codeofconduct-start',
  [TEMPLATES.STRATEGY_START]: 'aadw:strategy-start',
  [TEMPLATES.INSIGHTS_LISTING]: 'aadw:portal-listing',
  [TEMPLATES.INSIGHTS_CONTENT]: 'aadw:portal-article',
  [TEMPLATES.GRADIENT_BLOCK]: 'aadw:portal-block',
  [TEMPLATES.EVENTS_LIST_BLOCK]: 'aadw:portal-events-list-block',
  [TEMPLATES.AVENUE_FEED_LISTING_BLOCK]:
    'aadw:portal-avenue-feed-listing-block',
  [TEMPLATES.VIVA_ENGAGE_EMBED_BLOCK]: 'aadw:portal-viva-engage-embed-block',
};

class PortalNode extends DataModel {
  constructor(id, attributes, portal) {
    super('PortalNode', id, attributes);

    this.isRoot = portal.id === this.id;
    this.portal = portal;

    const { children } = attributes;

    if (children) {
      this.children = children.map((childData) => {
        const child = new PortalNode(
          childData.id,
          childData.attributes,
          portal
        );
        child.parent = this;

        return child;
      });

      this.sortChildren();
    } else {
      this.children = [];
    }
  }

  isPortalListing() {
    const { template } = this.attributes;

    return pageTypes[template] === 'aadw:portal-listing';
  }

  shouldHideFromNavigation() {
    return (
      !this.isRoot &&
      this.isPortalListing() &&
      this.get('hidePageFromNavigation')
    );
  }

  isItemTemplate() {
    const { template } = this.attributes;

    return template === TEMPLATES.ITEM;
  }

  getAllowedChildPages() {
    const { template } = this.attributes;

    return allowedChildPages[template];
  }

  getAllowedChildBlocks() {
    const { template } = this.attributes;

    return allowedChildBlocks[template];
  }

  getUseCompactChildPageListing() {
    const { useCompactChildPageListing } = this.attributes;

    return useCompactChildPageListing;
  }

  allowDelete() {
    return !this.isRoot;
  }

  // returns url to view, edit and create a child node
  // mode can be blank or /edit /create
  _getPath(mode = '') {
    const { portal } = this;

    if (this.isItemTemplate()) {
      if (mode === '/create') {
        throw new Error('Item templates can not have children');
      }
    }

    return `/portals/${portal.url}/${this.id}${mode}`;
  }

  matchBlockType(type) {
    const { template } = this.attributes;
    if (type instanceof Array) {
      return Boolean(~type.indexOf(template));
    }
    return template === type;
  }

  isBlockType() {
    return this.matchBlockType(blockTypes);
  }

  getPath() {
    return this._getPath();
  }

  getEditPath() {
    return this._getPath('/edit');
  }

  getCreateChildPath() {
    return this._getPath('/create');
  }

  // recursively traverses the tree of nodes till it finds the node
  deepFindChild(pageId) {
    if (this.id === pageId) {
      return this;
    }

    const { children } = this;

    for (let i = 0; i < children.length; ++i) {
      const child = children[i];
      const match = child.deepFindChild(pageId);

      if (match) {
        return match;
      }
    }

    return null;
  }

  // lists all nodes from the root node to the current node (in that order)
  getParentList() {
    let parents = [this];

    let parent = this.parent;

    while (parent) {
      parents.unshift(parent);

      parent = parent.parent;
    }

    return parents;
  }

  // This method is used for concatenating arguments to methods
  // which accept either string or array-of-string interchangeably.
  toArray(item) {
    if (item instanceof Array) {
      return item;
    }
    if (typeof item === 'string' || item instanceof String) {
      return [item];
    }
    return [];
  }

  getChildPages(blockType, excludePages) {
    return this.getChildren(
      blockType,
      blockTypes.concat(this.toArray(excludePages))
    );
  }

  getChildBlocks(blockType) {
    return this.getChildren(blockTypes.concat(this.toArray(blockType)));
  }

  getChildren(blockType, excludeBlockType) {
    let result = this.children;
    if (blockType) {
      result = result.filter((child) => child.matchBlockType(blockType));
    }
    if (excludeBlockType) {
      result = result.filter(
        (child) => !child.matchBlockType(excludeBlockType)
      );
    }
    return result;
  }

  getParent() {
    return this.parent;
  }

  addChild(child) {
    this.children.push(child);

    this.sortChildren();
  }

  sortChildren() {
    this.children.sort(
      (a, b) => a.attributes.sortIndex - b.attributes.sortIndex
    );
  }

  removeChild(child) {
    const { children } = this;

    this.children = children.filter((ch) => ch !== child);
  }

  getChildPageCount(filterForNavigation) {
    const childPages = this.getChildPages();
    if (filterForNavigation) {
      return childPages.filter((page) => !page.shouldHideFromNavigation())
        .length;
    }
    return childPages.length;
  }

  getSubChildPageCount(filterForNavigation) {
    return this.children.reduce(
      (acc, child) => child.getChildPageCount(filterForNavigation) + acc,
      0
    );
  }

  // Create and edit functionality
  getEditData = () => {
    // since the page is already loaded we just respond with a faked copy what
    // the cms would have returned
    return Promise.resolve({
      data: {
        data: {
          // _doc required for createOrEditArticle, temporary fix
          // in future all use of _doc should be removed
          _doc: this.id,
          ...this.attributes,
        },
      },
    });
  };

  updateNode = (nodeData) => {
    return updateNode(this.id, nodeData).then(({ data }) => {
      const { attributes } = data.data;

      this.attributes = { ...attributes };

      if (this.parent) {
        this.parent.sortChildren();
      }

      return this;
    });
  };

  createChildNode = (childData) => {
    const { portal } = this;

    const type = pageTypes[childData.template];

    childData.portalType = this.getAttributes().portalType;

    return createNode(type, this.id, childData).then(({ data }) => {
      const { id, attributes } = data.data;

      const child = new PortalNode(id, attributes, portal);
      child.parent = this;

      this.addChild(child);

      return child;
    });
  };

  deleteNode = () => {
    const { parent } = this;

    parent.removeChild(this);

    return deleteNode(this.id).then(() => parent);
  };

  getHeroImage = (size, side) => {
    let { heroImage } = this.attributes;
    if (side === 'left') {
      heroImage = this.attributes.heroImageLeft;
    } else if (side === 'right') {
      heroImage = this.attributes.heroImageRight;
    }
    return heroImage ? getImageSrc(heroImage, size) : fallBackImageBlog;
  };

  static async loadPortal(portalUrl) {
    const portal = portals.find(({ url }) => url === portalUrl);

    if (!portal) {
      throw new Error(`Portal with url ${portalUrl} not found`);
    }

    const { data } = await listDescendants(portal.id);

    const { id, attributes } = data.data;

    return new PortalNode(
      id,
      { template: TEMPLATES.PORTAL, ...attributes },
      portal
    );
  }
}

export default PortalNode;
