import './DocumentIcon.scss';

const DocumentIcon = ({ icon, color }) => {
  const getIconOrText = (icon) => {
    if (icon.includes('/static/media/')) {
      return (
        <img src={icon} alt="fileIcon" className={`document-icon__center `} />
      );
    }

    return (
      <div className={`document-icon__center document-icon__text `}>{icon}</div>
    );
  };

  return (
    <div className={`document-icon document-icon__color--${color}`}>
      {getIconOrText(icon)}
    </div>
  );
};
export default DocumentIcon;
