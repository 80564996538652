/* eslint-disable no-restricted-imports */
import createSpacing, {
  SpacingOptions,
} from '@mui/system/createTheme/createSpacing';

export type AvenueSpacingSize =
  | 'xxxxs'
  | 'xxxs'
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl';

const avenueSpacing: Record<AvenueSpacingSize, string> = {
  // ~2px
  xxxxs: 'clamp(.125rem, calc(.125rem + 0vw), .125rem)',

  // ~4px
  xxxs: 'clamp(.25rem, calc(.25rem + 0vw), .25rem)',

  // ~8px
  xxs: 'clamp(.5rem, calc(.5rem + 0vw), .5rem)',

  // ~12px
  xs: 'clamp(.75rem, calc(.75rem + 0vw), .75rem)',

  // ~16px
  sm: 'clamp(1rem, calc(1rem + 0vw), 1rem)',

  // ~24px
  md: 'clamp(1.5rem, calc(1.125rem + .78125vw), 1.75rem)',

  // ~32px
  lg: 'clamp(2rem, calc(.5rem + 3.125vw), 3rem)',

  // ~56px
  xl: 'clamp(3.5rem, calc(1.25rem + 4.6875vw), 5rem)',

  // ~92px
  xxl: 'clamp(5.75rem, calc(2.375rem + 7.03125vw), 8rem)',

  // ~148px
  xxxl: 'clamp(9.25rem, calc(4.75rem + 9.375vw), 12.25rem)',

  // ~240px
  xxxxl: 'clamp(15rem, calc(7.5rem + 15.625vw), 20rem)',
};

const spacingOptions: SpacingOptions = (size: number | AvenueSpacingSize) => {
  return typeof size === 'number' ? `${size}rem` : avenueSpacing[size];
};

const createMuiSpacing = () => {
  return createSpacing(spacingOptions);
};

export { createMuiSpacing };
