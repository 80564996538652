import {
  LikesAndCommentsAndViewsCount,
  LikesAndCommentsAndViewsCountResponse,
} from './types';

export enum FeedbackType {
  Like = 'likes',
  Comment = 'comments',
  Feedback = 'feedback',
}

export enum FeedbackRankingValue {
  NotHelpful = 0,
  Neutral = 1,
  Helpful = 2,
}

export type ArticleFeedbackResponse = {
  data: {
    data: {
      id: string;
      type: FeedbackType;
      attributes: {
        date: string;
        helpful: number;
      };
    }[];
    meta: {
      percentageHelpful: number;
      percentageNotHelpful: number;
      percentageNeutral: number;
    };
  };
};

export type ArticleFeedbackSummary = {
  rankingPercentage: Record<FeedbackRankingValue, number>;
};

export type UpdateLikeResponse = {
  data: {
    meta: {
      total: number;
      likedByMe: boolean;
    };
  };
};

export type LikesAndCommentsResponse = {
  data: {
    data: {
      type: string;
      attributes: {
        articleId: string;
      };
    }[];
    included: {
      id: string;
      type: FeedbackType;
      attributes: {
        date: string;
        personId: string;
        targetEntityId: string;
        published: boolean;
      };
    }[];
  };
};

export type CommentsResponse = {
  data: {
    data: {
      id: string;
      type: FeedbackType;
      attributes: {
        date: string;
        itemType: string;
        personId: string;
        published: boolean;
        targetEntityId: string;
        text: string;
      };
    }[];
  };
};

export type Comment = {
  id: string;
  date: string;
  text: string;
  personId: string;
  published: boolean;
};

export type LikesAndCommentsMeta = {
  targetEntityId: string;
  isLikedByMe: boolean;
  isCommentedByMe: boolean;
  likesCount: number;
  commentsCount: number;
};

const articleFeedbackResponseToArticleFeedbackSummary = (
  response: ArticleFeedbackResponse
) => {
  const articleFeedback: ArticleFeedbackSummary = {
    rankingPercentage: {
      [FeedbackRankingValue.Helpful]: response.data.meta.percentageHelpful,
      [FeedbackRankingValue.Neutral]: response.data.meta.percentageNeutral,
      [FeedbackRankingValue.NotHelpful]:
        response.data.meta.percentageNotHelpful,
    },
  };
  return articleFeedback;
};

const likesAndCommentsResponseToLikesAndCommentsMetaByArticleId = (
  response: LikesAndCommentsResponse,
  userId: string
) => {
  const responseArticleIds = response.data.data.map(
    (articleData) => articleData.attributes.articleId
  );
  const likesAndCommentsMetaByArticleId = responseArticleIds.reduce(
    (_likesAndCommentsMetaByArticleId, responseArticleId) => {
      const cmsArticleId = `cms-${responseArticleId}`;
      const feedbackItems = response.data.included;

      if (!feedbackItems) {
        _likesAndCommentsMetaByArticleId[cmsArticleId] = {
          targetEntityId: responseArticleId,
          isLikedByMe: false,
          isCommentedByMe: false,
          likesCount: 0,
          commentsCount: 0,
        };
        return _likesAndCommentsMetaByArticleId;
      }

      const likes = feedbackItems.filter(
        (item) =>
          item.attributes.targetEntityId === responseArticleId &&
          item.type === FeedbackType.Like
      );

      const isLikedByMe = likes.some(
        (like) => like.attributes.personId === userId
      );

      const comments = feedbackItems.filter(
        (comment) =>
          comment.attributes.targetEntityId === responseArticleId &&
          comment.type === FeedbackType.Comment
      );

      const commentIds = comments.map((comment) => comment.id);

      const replies = feedbackItems.filter(
        (item) =>
          commentIds.includes(item.attributes.targetEntityId) &&
          item.type === FeedbackType.Comment
      );

      const publishedComments = comments.filter(
        (comment) => comment.attributes?.published
      );
      const publishedReplies = replies.filter(
        (reply) => reply.attributes?.published
      );

      const publishedCommentsAndReplies = [
        ...publishedComments,
        ...publishedReplies,
      ];

      const isCommentedByMe = publishedCommentsAndReplies.some(
        (commentOrReply) => commentOrReply.attributes.personId === userId
      );

      _likesAndCommentsMetaByArticleId[cmsArticleId] = {
        targetEntityId: responseArticleId,
        isLikedByMe,
        isCommentedByMe,
        likesCount: likes.length,
        commentsCount: publishedComments.length + publishedReplies.length,
      };
      return _likesAndCommentsMetaByArticleId;
    },
    {} as Record<string, LikesAndCommentsMeta>
  );
  return likesAndCommentsMetaByArticleId;
};

const likesAndCommentsResponseToLikesAndCommentsMeta = (
  response: LikesAndCommentsResponse,
  userId: string,
  articleId: string
) => {
  return likesAndCommentsResponseToLikesAndCommentsMetaByArticleId(
    response,
    userId
  )[`cms-${articleId}`];
};

const likesCommentsAndViewsResponseToLikesCommentsAndViewsByArticleId = (
  response: LikesAndCommentsAndViewsCountResponse
) => {
  const result = response.data.data.reduce((prev, current) => {
    prev[current.articleId] = current;
    return prev;
  }, {} as Record<string, LikesAndCommentsAndViewsCount>);
  return result;
};

const commentsResponseToComments = (response: CommentsResponse) => {
  const result = response.data.data.map((item) => {
    const comment: Comment = {
      id: item.id,
      date: item.attributes.date,
      personId: item.attributes.personId,
      text: item.attributes.text,
      published: item.attributes.published,
    };
    return comment;
  });
  return result;
};

export {
  articleFeedbackResponseToArticleFeedbackSummary,
  commentsResponseToComments,
  likesAndCommentsResponseToLikesAndCommentsMeta,
  likesAndCommentsResponseToLikesAndCommentsMetaByArticleId,
  likesCommentsAndViewsResponseToLikesCommentsAndViewsByArticleId,
};
