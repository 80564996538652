import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { ArticleListSkeleton } from 'componentsNew';

const LatestArticlesSkeleton = () => (
  <>
    <Stack
      sx={() => ({
        display: {
          xs: 'none',
          sm: 'flex',
        },
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        gap: '0.5rem',
        height: '25rem',
        marginBottom: '1rem',
      })}
    >
      {[...Array(2)].map((_, index) => (
        <Skeleton
          key={`latest-articles-featured-${index}`}
          variant="rounded"
          animation="wave"
          sx={{
            height: '100%',
            maxWidth: 'unset',
            flexGrow: 1,
          }}
        />
      ))}
    </Stack>
    <ArticleListSkeleton />
  </>
);

export { LatestArticlesSkeleton };
