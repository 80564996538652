import * as newsletterBannerApi from 'api/cms/newsletterBanner';
import { AvenueRoute } from 'constants/routes';
import { useSnackbar } from 'contextNew/Snackbar';
import { Page } from 'layoutNew';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { translations } from 'translations';
import * as attachmentUtils from 'utils/cms/attachment';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';
import {
  DEFAULT_VALUES,
  FormValues,
  NewsletterBannerForm,
} from './NewsletterBannerForm';

const NewsletterBannerCreate = () => {
  const history = useHistory();
  const { showSnackbar } = useSnackbar();

  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    shouldFocusError: true,
  });

  const { setError, setValue, getValues } = methods;

  const setFormError = useCallback(
    (message: string) => {
      showSnackbar({
        type: 'error',
        text: message,
      });
      setError('root', {});
    },
    [setError, showSnackbar]
  );

  const beforeSubmit = useCallback(() => {
    const formValues = getValues();
    const trimmedValues = formUtils.trimValues(formValues);
    const fieldNames = Object.keys(formValues) as (keyof FormValues)[];

    for (const fieldName of fieldNames) {
      const formValue = formValues[fieldName];
      const trimmedValue = trimmedValues[fieldName];
      if (trimmedValue !== formValue) {
        setValue(fieldName, trimmedValue, { shouldValidate: true });
      }
    }
  }, [getValues, setValue]);

  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    async (formValues: FormValues) => {
      if (formValues.heroImage.raw) {
        try {
          formValues.heroImage.uploaded = await attachmentUtils.uploadImage(
            formValues.heroImage.raw
          );
        } catch {
          setFormError(translations.imageUploadError);
          return;
        }
      }
      try {
        const payload =
          helpers.transformFormValuesToCreateOrEditPayload(formValues);

        await newsletterBannerApi.createNewsletterBanner(payload);
        showSnackbar({
          type: 'success',
          text: translations.newsletterBannerCreateSuccess,
        });
        history.push(AvenueRoute.ProfileManageBanners);
      } catch {
        if (formValues.heroImage.uploaded) {
          try {
            await attachmentUtils.deleteImage(formValues.heroImage.uploaded);
          } catch {}
        }
        setFormError(translations.newsletterBannerCreateError);
        return;
      }
    },
    [history, setFormError, showSnackbar]
  );

  return (
    <Page
      title={[translations.newsletterBanner, translations.create]}
      breadcrumbs={[
        {
          text: translations.pageTitleHome,
          to: AvenueRoute.Home,
        },
        {
          text: translations.newsletterBanner,
          to: AvenueRoute.ProfileManageBanners,
        },
        { text: translations.create },
      ]}
    >
      <FormProvider {...methods}>
        <NewsletterBannerForm
          beforeSubmit={beforeSubmit}
          onSubmit={handleSubmit}
        />
      </FormProvider>
    </Page>
  );
};

export { NewsletterBannerCreate };
