import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import settings from 'settings';
const { applicationInsightsConnectionString } = settings.misc;

let appInsights = null;

if (
  applicationInsightsConnectionString &&
  process.env.NODE_ENV !== 'development'
) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: applicationInsightsConnectionString,
    },
  });
}

class AppInsights extends Component {
  componentDidMount() {
    if (
      applicationInsightsConnectionString &&
      process.env.NODE_ENV !== 'development'
    ) {
      appInsights.loadAppInsights();
    }
  }

  componentDidUpdate({ location }) {
    if (
      applicationInsightsConnectionString &&
      location &&
      this.props.location !== location &&
      process.env.NODE_ENV !== 'development'
    ) {
      appInsights.trackPageView({ uri: location.pathname });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(AppInsights);
