export enum ArticleContentType {
  Story = 'StoryArticle',
  News = 'NewsArticle',
  Blog = 'BlogPost',
  Press = 'PressRelease',
}

export enum PortalArticleContentType {
  PortalSearchHit = 'portalarticlesearchhits',
}

export enum CommentDataType {
  Comment = 'comments',
  Reply = 'replies',
}
