import './FooterContentContainer.scss';

import { getFooterLinks } from 'api/cms/footerLinks';
import { ReactComponent as SearchIcon } from 'components/Icon/Search.svg';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GAonFooterSearchIconClick } from 'utils/analytics';

import LinkItem from '../LinkItem';

const FooterDesktopLinksContainer = () => {
  const [footerHeaders, setFooterHeaders] = useState<any>([]);
  const [footerLinks, setFooterLinks] = useState([]);

  useEffect(() => {
    const fetchFooterLinks = async () => {
      const response = await getFooterLinks();

      setFooterHeaders(
        response.data.data ? Object.keys(response.data.data) : []
      );
      setFooterLinks(Object.values(response.data.data));
    };
    if (!window.location.pathname.includes('/public')) fetchFooterLinks();
  }, []);

  const renderLinks = (header: any, links: any) => {
    if (links) {
      return (
        <div className="footer-desktop__link-list">
          <h4 className="footer-desktop__link-list__header">{header}</h4>
          <ul>
            {links.map((link: any) => (
              <LinkItem
                href={link.linkUrl}
                text={link.title}
                key={link.sortIndex}
                outbound={
                  link.linkUrl.includes('https://') ||
                  link.linkUrl.includes('mailto:')
                }
              />
            ))}
          </ul>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="footer-desktop">
      {renderLinks(footerHeaders[0], footerLinks[0])}
      {renderLinks(footerHeaders[1], footerLinks[1])}
      {renderLinks(footerHeaders[2], footerLinks[2])}

      <div className="footer-desktop__search">
        <h4 className="footer-desktop__search__header">
          Didn’t find what you were looking for?
        </h4>
        <Link
          className="footer-desktop__search__link"
          onClick={GAonFooterSearchIconClick()}
          to="/find"
        >
          <div className="footer-desktop__search__icon-container">
            <p>Go to search page</p>
            <SearchIcon />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default FooterDesktopLinksContainer;
