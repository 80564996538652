import { Consumer } from 'components/CreateOrEditArticle';
import Body from 'components/CreateOrEditArticle/components/Body';
import Hero from 'components/CreateOrEditArticle/components/Hero';
import LinkButtonText from 'components/CreateOrEditArticle/components/LinkButtonText';
import LinkUrl from 'components/CreateOrEditArticle/components/LinkUrl';
import SortIndex from 'components/CreateOrEditArticle/components/SortIndex';
import Title from 'components/CreateOrEditArticle/components/Title';

const GradientBlockEditor = () => (
  <Consumer>
    {({
      validationParameters: {
        title: { maxLength },
      },
    }) => {
      const titlePlaceholder = `Title (max ${maxLength} characters)`;
      return (
        <>
          <Title placeholder={titlePlaceholder} />
          <Body placeholder="Body" showSideButtons={false} />
          <Hero useVideo={false} useAltText={true} minWidth={1050} />
          <LinkUrl placeholder="Link URL" />
          <LinkButtonText placeholder="Link Button Text" />
          <SortIndex />
        </>
      );
    }}
  </Consumer>
);

export default GradientBlockEditor;
