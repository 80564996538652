import './LanguageSelectItem.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

const LanguageSelectItem = ({ item, selectedLanguage, onChange }) => {
  const selectClassNames = classNames('language-select-item', {
    'language-select-item--disabled': !item.isAvailable,
    [`language-select-item--${item.locale}`]: item.locale,
  });

  return (
    <li
      key={item.locale}
      className={selectClassNames}
      onClick={() => onChange(item)}
    >
      {item.text}
      {selectedLanguage.locale === item.locale && (
        <span className="language-select-item__checkmark"></span>
      )}
    </li>
  );
};

LanguageSelectItem.propTypes = {
  item: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LanguageSelectItem;
