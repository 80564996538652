import { useEffect, useRef } from 'react';

const useComponentDidMount = () => {
  const componentDidMountRef = useRef(true);
  useEffect(() => {
    componentDidMountRef.current = false;
  }, []);
  return componentDidMountRef.current;
};

export default useComponentDidMount;
