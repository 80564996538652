import './Listing.scss';

import PortalNode from 'api/models/PortalNode';
import BodyBox from 'components/Portal/BodyBox';
import ChildPageMenu from 'components/Portal/ChildPageMenu';
import HeaderBox from 'components/Portal/HeaderBox';
import TextMediaBoxList from 'components/Portal/TextMediaBox/List';
import PropTypes from 'prop-types';

const Listing = ({ page }) => {
  return (
    <>
      <HeaderBox page={page}>
        <ChildPageMenu page={page} />
      </HeaderBox>
      <BodyBox page={page} />
      <TextMediaBoxList page={page} />
    </>
  );
};

Listing.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
};

export default Listing;
