import { getAuthenticatedVideo } from 'api/cms/creoAuthenticatedVideo';
import ButtonModalWrapper from 'components/Button/ButtonModalWrapper';
import PropTypes from 'prop-types';
import { cloneElement, Component } from 'react';
import URL from 'url';
import { getService } from 'utils/cms/parseVideoUrls';

const yesButton = {
  color: 'black',
  title: 'Submit',
  awaitTitle: 'Submit',
};

const noButton = {
  color: 'white',
  title: 'Close',
};

class VideoModal extends Component {
  state = {
    enteredVideoUrl: null,
    modalMessage: null,
  };

  onSubmit = async () => {
    const { enteredVideoUrl } = this.state;
    const { onSubmit } = this.props;

    if (enteredVideoUrl) {
      let url = URL.parse(enteredVideoUrl, true);
      if (url.hostname && !!getService(url)) {
        if (url.href.indexOf('cvm3.se') > -1) {
          const requestObj = {
            url: url.href,
          };
          const response = await getAuthenticatedVideo(requestObj);

          if (response.data.data.url) {
            url = URL.parse(response.data.data.url);
          } else {
            this.setState({
              modalMessage: 'Not a valid Creo video. Check url and try again.',
            });

            return false;
          }
        }

        onSubmit(url);

        this.setState({ enteredVideoUrl: null, modalMessage: null });

        return true;
      } else {
        this.setState({
          modalMessage:
            'Not a URL to Microsoft Stream, YouTube, Vimeo or Creo.',
        });
      }
    } else {
      this.setState({
        modalMessage: 'Please enter a URL.',
      });
    }

    return false;
  };

  onNoButtonClick = () => {
    this.setState({
      enteredVideoUrl: null,
      modalMessage: null,
    });
  };

  updateInputValue = (event) => {
    this.setState({
      enteredVideoUrl: event.target.value,
    });
  };

  render() {
    const { modalMessage } = this.state;
    const { children } = this.props;

    const childElement = cloneElement(children, {
      onClick: this.onSubmit,
      'aria-controls': 'videoupload',
    });

    return (
      <ButtonModalWrapper
        title="Enter a URL to Microsoft Stream, YouTube, Vimeo or Creo"
        yesButton={yesButton}
        noButton={noButton}
        errorMessage={modalMessage}
        modalChildren={
          <input className="modal__input" onChange={this.updateInputValue} />
        }
        awaitYes={true}
        onNoButtonClick={this.onNoButtonClick}
      >
        {childElement}
      </ButtonModalWrapper>
    );
  }
}

VideoModal.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default VideoModal;
