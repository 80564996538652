import './SelectButton.scss';

import classNames from 'classnames';
import { ReactNode } from 'react';

interface SelectButtonProps {
  selected: boolean;
  data: { value: string; name: string };
  children: ReactNode;
  addRemoveSelected: (selectedTopic: { value: string; name: string }) => void;
  disabled: boolean;
}

const SelectButton = ({
  selected,
  data,
  children,
  addRemoveSelected,
  disabled,
}: SelectButtonProps) => {
  const selectedButton = classNames('select-button', {
    'select-button--selected': selected,
    'select-button--disabled': disabled,
  });

  const disabledTextButton = classNames('select-button__text', {
    'select-button--disabled': disabled,
  });

  return (
    <button
      onClick={() => addRemoveSelected(data)}
      className={selectedButton}
      disabled={disabled}
    >
      <div className="select-button__check"></div>
      <span className={disabledTextButton}>{children}</span>
    </button>
  );
};

export default SelectButton;
