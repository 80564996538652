import Box from 'components/Box';
import Resize from 'components/Resize';
import ContentLoader from 'react-content-loader';
import { getRandomNumberBetween } from 'utils/misc/random';

const Content = ({ width }) => (
  <ContentLoader
    className=""
    width={width}
    height={47}
    preserveAspectRatio="none"
  >
    <rect x={0} y={6} width={getRandomNumberBetween(350, 1200)} height={36} />
  </ContentLoader>
);

const Loader = () => (
  <Box>
    <Resize>{({ width }) => <Content width={width} />}</Resize>
  </Box>
);

export default Loader;
