import Suggestion from 'api/models/Suggestion';
import { History } from 'history';

export type InnovationArticleResult = {
  linkUrl: string;
  published: string;
  title: string;
  summary: string;
  imageUrl: string;
  imageAltText?: string;
  types: Tag[];
  portalType: string;
};

export type Tag = {
  id: string;
  title: string;
  type: string;
};

export type ArticleSortOption = {
  label: string;
  sortType: string;
  sortOrder: '' | 'desc' | 'asc';
};

export interface GradientBlockProps {
  textColor: string;
  title: string;
  body: string;
  linkUrl: string;
  linkButtonText: string;
  id: string;
  getHeroImage: (size: number, side?: string) => string;
}

export interface InnovationSearchSuggestionsListProps {
  suggestions: Suggestion[];
  searchQuery: string;
  justSelectedSuggestionIndex: number | null;
  history: History;
  hoverSearchResult: (index: number) => void;
}

export interface InnovationSearchSuggestionsItemProps {
  suggestion: Suggestion;
  searchQuery: string;
  justSelectedSuggestionIndex: number | null;
  history: History;
  index: number;
  hoverSearchResult: (index: number) => void;
}

export interface NotificationDTO {
  id: string;
  type: NotificationType;
  title: string;
  body: string;
  linkUrl: string;
  timestamp: string;
  read: boolean;
  personId: string;
}

export enum NotificationType {
  Comment,
  PositiveFeedback,
  NegativeFeedback,
  Warning,
  Information,
  Reply,
}
