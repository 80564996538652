import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system/styleFunctionSx';

type PlaySymbolProps = {
  size?: number;
  sx?: SxProps<Theme>;
};

const PlaySymbol = ({ size = 3.75, sx }: PlaySymbolProps) => {
  const playSize = size / 5;

  return (
    <Stack
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        () => ({
          height: `${size}rem`,
          width: `${size}rem`,
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px solid rgb(250, 250, 250)',
          borderRadius: '50%',
          backgroundColor: 'rgba(63, 63, 63, 0.5)',
        }),
      ]}
    >
      <Box
        sx={() => ({
          position: 'relative',
          width: 0,
          height: 0,
          margin: `0 auto 0 ${playSize * 1.5}rem`,
          borderWidth: `${playSize}rem 0 ${playSize}rem ${playSize * 2}rem`,
          borderStyle: 'solid',
          borderColor: 'transparent transparent transparent rgb(250, 250, 250)',
        })}
      />
    </Stack>
  );
};

export { PlaySymbol };
