import Typography from '@mui/material/Typography';

type FieldValueProps = {
  id?: string;
  children: string;
};

const FieldValue = ({ id, children }: FieldValueProps) => {
  return (
    <Typography
      id={id}
      sx={(theme) => ({
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: theme.colors.text.secondary,
      })}
    >
      {children}
    </Typography>
  );
};

export { FieldValue };
