import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';

type ArticleListSkeletonProps = {
  count?: number;
};

const ArticleListSkeleton = ({ count = 6 }: ArticleListSkeletonProps) => (
  <List>
    {[...Array(count)].map((_, index) => (
      <ListItem
        key={`article-list-skeleton-${index}`}
        divider
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing('sm'),
          padding: `${theme.spacing('sm')} 0`,
          height: 'fit-content',
        })}
      >
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{
            width: '30%',
            flexShrink: 0,
            aspectRatio: 16 / 9,
            maxWidth: 'unset',
            height: 'unset',
          }}
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{
            width: '100% ',
            flexGrow: 1,
            maxWidth: 'unset',
            height: 'unset',
            alignSelf: 'stretch',
          }}
        />
      </ListItem>
    ))}
  </List>
);

export { ArticleListSkeleton };
