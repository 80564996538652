import settings from 'settings';
import createRequest from 'utils/api/requestFactory';
const { cms } = settings.api;

export const createAttachment = (data) =>
  createRequest({
    method: 'post',
    url: `${cms}/attachment`,
    data,
    errorMessages: {
      '*': 'Failed to create image, please contact support for assistance.',
    },
  });

export const createAttachments = (data) =>
  createRequest({
    method: 'post',
    url: `${cms}/attachment/list`,
    data,
    errorMessages: {
      '*': 'Failed to save images in image gallery, please contact support for assistance.',
    },
  });

export const deleteAttachment = (id) =>
  createRequest({
    method: 'delete',
    url: `${cms}/attachment/${id}`,
    errorMessages: {
      '*': 'Failed to delete image, please contact support for assistance.',
    },
  });
