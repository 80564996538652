import './LoadMoreButton.scss';

import Button from 'components/Button';
import { ReactComponent as Arrow } from 'components/Icon/ThinArrow.svg';
import { GAonShowMoreClick } from 'utils/analytics';
import animateScroll from 'utils/misc/animateScroll';

interface LoadMoreButtonProps {
  onClick: () => void;
}

interface LoadMoreButtonContainerProps {
  shouldDisplay?: boolean;
  gaLabel?: string;
  onLoadMore: () => void;
  insideBox?: boolean;
}

const LoadMoreButton = ({ onClick }: LoadMoreButtonProps) => (
  <button onClick={onClick} className="load-more-button">
    Load more
    <Arrow />
  </button>
);

const LoadMoreButtonContainer = ({
  gaLabel,
  onLoadMore,
  shouldDisplay,
  insideBox,
}: LoadMoreButtonContainerProps) => {
  const onClick = () => {
    onLoadMore();
    if (gaLabel) {
      GAonShowMoreClick(gaLabel);
    }
    animateScroll(220, 50);
  };

  if (!shouldDisplay) return null;
  if (insideBox) return <LoadMoreButton onClick={onClick} />;
  return (
    <Button className="load-more-button-black" onClick={onClick}>
      Load more
    </Button>
  );
};

export default LoadMoreButtonContainer;
