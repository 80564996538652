import './Onboarding.scss';

import {
  addDefaultProfileSettingsShortcuts,
  updateProfileSettings,
} from 'api/organization';
import classNames from 'classnames';
import Button from 'components/Button';
import Carousel from 'components/Carousel';
import Checkbox from 'components/Checkbox';
import Flex from 'components/Flex';
import Iframe from 'components/Iframe';
import Modal from 'components/Modal';
import { ONBOARDING_GUID } from 'constants/consents';
import { Component } from 'react';
import {
  GAonOnboardingTakeMeToAvenueClick,
  GAonSetOnboardingCarouselIndex,
  GAonSetOnboardingDontShowAgain,
} from 'utils/analytics';

import * as images from './images';

const Picture = ({ imageName, alt }) => {
  const sources = [
    {
      srcSet: `${images[`${imageName}Mobile2x`]}`,
      media: '(max-width: 768px)',
    },
    {
      srcSet: `${images[`${imageName}`]} 1x, ${images[`${imageName}2x`]} 2x`,
    },
  ];
  return (
    <picture>
      {sources.map((source, index) => (
        <source
          key={`sources-${index}`}
          srcSet={source.srcSet}
          media={source.media}
          type={source.type}
        />
      ))}
      <img alt={alt} />
    </picture>
  );
};

class OnBoarding extends Component {
  state = {
    notShowAgain: true,
    carouselIndex: 0,
    onSubmitLoading: false,
  };

  componentDidMount() {
    this.props.setShowSurvey(false);
  }
  setCarouselIndex = (carouselIndex) => {
    this.setState({ carouselIndex });

    const { divisionName, countryName } = this.props;

    GAonSetOnboardingCarouselIndex(carouselIndex, divisionName, countryName);
  };

  onSubmit = async () => {
    const { notShowAgain } = this.state;
    const { userId, updateUser } = this.props;
    try {
      if (notShowAgain) {
        await updateProfileSettings(userId, {
          consents: {
            data: [
              {
                type: 'consents',
                id: ONBOARDING_GUID,
              },
            ],
          },
        });
      }
      updateUser(({ consents }) => ({
        consents: consents
          ? consents.concat({ id: ONBOARDING_GUID })
          : [{ id: ONBOARDING_GUID }],
      }));
      addDefaultProfileSettingsShortcuts();
      this.setState({ onSubmitLoading: true });
    } catch (e) {
      return this.setState({
        error: true,
        errorMessage: e.message,
      });
    }
  };

  render() {
    const { notShowAgain, carouselIndex, onSubmitLoading } = this.state;
    const { firstName, divisionName, countryName } = this.props;
    const isOnLastItem = carouselIndex === 6;
    const isOnFirstItem = carouselIndex === 0;

    const buttonWrapperClassname = classNames('onboarding__button-wrapper', {
      'onboarding__button-wrapper--first-item': isOnFirstItem,
      'onboarding__button-wrapper--last-item': isOnLastItem,
    });

    return (
      <Modal
        expandedMobile={true}
        size="md"
        transparency={false}
        animate={false}
        focus={false}
      >
        <Modal.Header />
        <div className="onboarding__container">
          <Flex
            type="space-between"
            direction="column"
            className={`p-t-8 center onboarding ${
              isOnLastItem ? 'onboarding--done' : ''
            }`}
          >
            <>
              <Carousel
                currentIndex={carouselIndex}
                useButtons={false}
                onChange={(index) => this.setCarouselIndex(index)}
              >
                <div>
                  <div>
                    {firstName ? <h1>Hello {firstName}!</h1> : <h1>Hello!</h1>}
                    <p className="onboarding__text">
                      Welcome to <strong>Avenue</strong>, the intranet of ASSA
                      ABLOY. Check out this <strong>short video</strong> to get
                      a jump start.
                    </p>
                  </div>
                  <Iframe
                    title="onboarding"
                    width="542"
                    height="304"
                    src="https://assa-abloy-internal.creo.se/i/LsZb5Bwi-RJ0Zif4j_rfWw"
                    frameBorder="0"
                    allowFullScreen
                  />
                </div>

                <div>
                  <p className="onboarding__text">
                    Find the latest <strong>global</strong>,{' '}
                    <strong>divisional</strong> and <strong>local</strong> news
                    in the Feed section. Or use the filters to find older ones.
                  </p>
                  <Picture
                    alt="Find the latest global, divisional and local news in the feed section. Or use the filters to find older ones."
                    imageName="feed"
                  />
                </div>

                <div>
                  <p className="onboarding__text">
                    Get <strong>job recommendations</strong> based on your
                    current function. Or look for all opportunities within the
                    organization.
                  </p>
                  <Picture
                    alt="Get job recommendations based on your current function. Or look for all opportunities within the organization."
                    imageName="jobs"
                  />
                </div>

                <div>
                  <p className="onboarding__text">
                    Find useful employment information posted in the{' '}
                    <strong>global</strong>, <strong>divisional</strong> and{' '}
                    <strong>local</strong> sections of the Library.
                  </p>
                  <Picture
                    alt="Find useful employment information posted in the global, divisional and local sections of the Library."
                    imageName="library"
                  />
                </div>

                <div>
                  <p className="onboarding__text">
                    The <strong>tools</strong> you have access to are listed
                    under <strong>My Tools.</strong>
                  </p>
                  <Picture
                    alt="Links to the Tools and Projects that you have access to are listed on your personal profile page."
                    imageName="tools"
                  />
                </div>

                <div>
                  <p className="onboarding__text">
                    Use the <strong>search</strong> if you haven't found what
                    you were looking for. The search works for{' '}
                    <strong>News</strong>, <strong>Library</strong>,{' '}
                    <strong>Press</strong>, <strong>Blog </strong>,{' '}
                    <strong>Portals</strong> and <strong>People</strong>.
                  </p>
                  <Picture
                    alt="Use the search if you haven't found what you were looking for. The search works for News, Library, Press, Blog, Portals and People."
                    imageName="search"
                  />
                </div>

                <div>
                  <p className="onboarding__text">
                    Construction along the <strong>Avenue</strong> is a
                    continuous process. We encourage you to give{' '}
                    <strong>feedback</strong> about your needs to help shape our{' '}
                    <strong>digital future</strong>.
                  </p>
                  <Picture
                    alt="Avenue is still work in progress"
                    imageName="wip"
                  />
                </div>
              </Carousel>

              <Modal.Footer>
                <Flex
                  type={isOnFirstItem ? 'center' : 'flex-end'}
                  className="size-small sm-m-b-2"
                >
                  <label htmlFor="noShowCheckbox" className="m-r-3">
                    Don't show this again{' '}
                  </label>
                  <Checkbox
                    color="gray"
                    checked={notShowAgain}
                    id="noShowCheckbox"
                    onChange={(e) => {
                      const { checked } = e.target;
                      if (checked) {
                        GAonSetOnboardingDontShowAgain(
                          divisionName,
                          countryName
                        );
                      }
                      this.setState({ notShowAgain: checked });
                    }}
                  />
                </Flex>
                <Flex
                  className={buttonWrapperClassname}
                  type={isOnFirstItem ? 'center' : 'space-between'}
                >
                  <Button
                    width="block"
                    size="medium"
                    style={{
                      display: isOnFirstItem ? 'none' : 'block',
                    }}
                    className="onboarding__prev-button lg-m-t-4 lg-m-b-4 "
                    color="white"
                    onClick={() => {
                      this.setCarouselIndex(carouselIndex - 1);
                    }}
                  >
                    Previous
                  </Button>
                  {isOnLastItem ? (
                    <Button
                      width="narrow"
                      size="medium"
                      className="onboarding__done-button lg-m-t-4 lg-m-b-4 m-l-4"
                      color="blue"
                      disabled={onSubmitLoading}
                      onClick={() => {
                        GAonOnboardingTakeMeToAvenueClick(
                          divisionName,
                          countryName
                        );
                        this.onSubmit();
                      }}
                    >
                      Take me to Avenue!
                    </Button>
                  ) : (
                    <Button
                      width="block"
                      size="medium"
                      style={{}}
                      className="onboarding__next-button lg-m-t-4 lg-m-b-4"
                      color="blue"
                      onClick={() => {
                        this.setCarouselIndex(carouselIndex + 1);
                      }}
                    >
                      Next
                    </Button>
                  )}
                </Flex>
              </Modal.Footer>
            </>
          </Flex>
        </div>
      </Modal>
    );
  }
}

export default OnBoarding;
