import RichText from 'components/Editable/RichText';

const ScreenSummary = ({
  onChangeSummary,
  screenSummary,
}: ScreenSelectorProps) => {
  const handleOnChangeSummary = (summary: string) => {
    onChangeSummary(summary);
  };

  return (
    <div className="screen-selector__text-box">
      <RichText
        onChange={handleOnChangeSummary}
        content={screenSummary}
        placeholder="Introduction for internal screens"
      />
    </div>
  );
};

export default ScreenSummary;
