import Text from 'components/Editable/Text';

import { Consumer } from '../';

const fieldValidator = (value) => {
  const lcValue = (value ? value : '').toLowerCase();
  const match = lcValue.match(
    /^((0x){0,1}|#{0,1})([0-9A-Fa-f]{8}|[0-9A-Fa-f]{6})$/gm
  );
  if (lcValue && !match) {
    return 'Color is invalid';
  }

  return null;
};

const onChange = (onChangeArticle, fieldName) => (textColor) => {
  onChangeArticle({ [fieldName]: textColor });
};

const TextColor = ({
  editable,
  color,
  placeholder,
  fieldName = 'textColor',
}) => (
  <Consumer>
    {({
      article,
      onChangeArticle,
      validationParameters: {
        [fieldName]: { maxLength },
      },
      showInitialError,
    }) => {
      if (!editable || editable(article)) {
        return (
          <Text
            wrapper={{
              element: 'h1',
              className: 'preamble',
            }}
            placeholder={placeholder}
            onBlur={onChange(onChangeArticle, fieldName)}
            validators={[fieldValidator]}
            showInitialError={showInitialError}
            content={article[fieldName]}
            maxLength={maxLength}
            color={color}
          />
        );
      }

      return <div>{article[fieldName]}</div>;
    }}
  </Consumer>
);

export default TextColor;
