import { AxiosResponse } from 'axios';
import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

import { MyMomentRequest } from './types';

const { cms } = settings.api;

export const getMyMoment = async (): Promise<AxiosResponse> =>
  await createRequest({
    method: 'get',
    url: `${cms}/my-moment`,
    errorMessages: {
      '*': 'Failed to get my moment, please contact support for assistance.',
    },
  });

export const updateMyMoment = (id: string, data: MyMomentRequest) =>
  createRequest({
    method: 'put',
    url: `${cms}/my-moment/${id}`,
    data,
    errorMessages: {
      '*': 'Failed to update my moment, please contact support for assistance.',
    },
  });

export const createMyMoment = (data: MyMomentRequest) =>
  createRequest({
    method: 'post',
    url: `${cms}/my-moment`,
    data,
    errorMessages: {
      '*': 'Failed to create my moment, please contact support for assistance.',
    },
  });
