import './HeaderBox.scss';

import PortalNode from 'api/models/PortalNode';
import classnames from 'classnames';
import { UserConsumer } from 'components/Context/User';
import { ReactComponent as ArrowIcon } from 'components/Icon/Arrow.svg';
import OnlyWithRole from 'components/OnlyWithRole';
import FilterBox from 'components/Portal/InsightsDatabase/FilterBox';
import SearchBox from 'components/Portal/InsightsDatabase/SearchBox';
import { AvenueRoute } from 'constants/routes';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import settings from 'settings';

import DefaultBackground from './innovation-portal-background.jpg';
import InnovationBackground from './innovation-portal-background.jpg';
import StrategyBackground from './strategy-portal-background.jpg';

const PortalBackgrounds = {
  innovation: InnovationBackground,
  strategy: StrategyBackground,
};

const { insightsDbPageId } = settings.misc;

class InsightsDatabaseHeaderBox extends Component {
  state = {
    stretchBg: false,
    search: false,
    filter: false,
    filterLabelText: 'Open advanced filters',
    filterHolderClass: 'insights-database-header-box__filter-holder',
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search !== this.props.location.search &&
      this.props.location.search === ''
    ) {
      this.setState({
        stretchBg: false,
        search: false,
      });
    }
  }

  backgroundLoaded = () => {
    this.setState({
      stretchBg:
        this.imageElement.offsetHeight > this.headerboxElement.offsetHeight,
    });
  };

  toggleFilters = () => {
    this.setState(({ filter }) => ({ filter: !filter }));
    this.updateFilterLabelText();
    this.toggleFilterHolderClass();
  };

  updateFilterLabelText = () => {
    this.setState({
      filterLabelText: this.state.filter
        ? 'Open advanced filter'
        : 'Close advanced filters',
    });
  };

  toggleFilterHolderClass = () => {
    const isOpenedClass = !this.state.filter
      ? 'insights-database-header-box__filter-holder--opened'
      : '';
    this.setState({
      filterHolderClass: `insights-database-header-box__filter-holder ${isOpenedClass}`,
    });
  };

  getInitialState = (value) => {
    this.props.onMount(value);
  };

  changeHandler = (value) => {
    this.props.onChange(value);
  };

  setAdditionalLists = (value) => {
    this.props.onAdditionalLists(value);
  };

  naviagteToInsightsDb = () => {
    this.props.history.push(`/portals/innovation/${insightsDbPageId}`);
  };

  render() {
    const { page, useFilter, resetSortOptions } = this.props;
    const bgInnerClasses = classnames(
      'insights-database-header-box__background-inner',
      {
        'insights-database-header-box__background-inner--full-height':
          this.state.stretchBg,
      }
    );
    const backgroundImage =
      PortalBackgrounds[page.get('portalType')] || DefaultBackground;

    return (
      <div
        className="insights-database-header-box"
        ref={(elem) => (this.headerboxElement = elem)}
      >
        <div className="insights-database-header-box__background-wrapper">
          <div className={bgInnerClasses}>
            <div className="insights-database-header-box__background-pink-gradient" />
            <img
              src={backgroundImage}
              alt=""
              ref={(elem) => (this.imageElement = elem)}
              onLoad={this.backgroundLoaded}
            />
          </div>
        </div>
        <div className="insights-database-header-box__content">
          <h1
            className="insights-database-header-box__title"
            onClick={this.naviagteToInsightsDb}
          >
            {page.get('title')}
          </h1>
          <p className="insights-database-header-box__preamble">
            {page.get('preamble')}
          </p>
          <SearchBox
            resetSortOptions={resetSortOptions}
            onChange={this.changeHandler}
            onMount={this.getInitialState}
          />
          <div className={this.state.filterHolderClass}>
            <div
              className="insights-database-header-box__filter-label-mobile"
              onClick={this.toggleFilters}
            >
              <span>{this.state.filterLabelText}</span>
              <ArrowIcon className="insights-database-header-box__arrow-icon" />
            </div>
            <CSSTransition
              in={this.state.filter}
              timeout={300}
              classNames="insights-database-header-box__filter-container"
            >
              <div className="insights-database-header-box__filter-container">
                <div className="insights-database-header-box__filter-container-inner">
                  <FilterBox
                    useFilter={useFilter}
                    onChange={this.changeHandler}
                    onMount={this.getInitialState}
                    onAdditionalLists={this.setAdditionalLists}
                  />
                </div>
              </div>
            </CSSTransition>
            <div
              className="insights-database-header-box__filter-label"
              onClick={this.toggleFilters}
            >
              <span>{this.state.filterLabelText}</span>
              <ArrowIcon className="insights-database-header-box__arrow-icon" />
            </div>
          </div>
        </div>
        <OnlyWithRole viewableByRole="AvenuePortalEditor">
          <div className="insights-database-header-box__menu">
            <Link
              className="insights-database-header-box__menu-link"
              to={page.getEditPath()}
            >
              Edit
            </Link>
            <Link
              className="insights-database-header-box__menu-link"
              to={page.getCreateChildPath()}
            >
              Add article
            </Link>
            <Link
              className="insights-database-header-box__menu-link"
              to={`${page.getCreateChildPath()}-block`}
            >
              Add block
            </Link>
          </div>
        </OnlyWithRole>
        <div className="insights-database-header-box__subscription">
          <UserConsumer>
            {(userContext) => {
              return (
                <div className="insights-database-header-box__subscription-text-wrapper">
                  {userContext.subscribesToInsightsDatabase ? (
                    <>
                      <span>
                        You subscribe to the latest insights articles.
                      </span>
                      <Link
                        className="insights-database-header-box__subscription-link"
                        to={AvenueRoute.ProfileSubscriptions}
                      >
                        Manage your subscriptions here.
                      </Link>
                    </>
                  ) : (
                    <Link
                      className="insights-database-header-box__subscription-link"
                      to={AvenueRoute.ProfileSubscriptions}
                    >
                      Subscribe to the latest insights
                    </Link>
                  )}
                </div>
              );
            }}
          </UserConsumer>
        </div>
      </div>
    );
  }
}

InsightsDatabaseHeaderBox.propTypes = {
  page: PropTypes.instanceOf(PortalNode).isRequired,
  useFilter: PropTypes.string,
};

export default withRouter(InsightsDatabaseHeaderBox);
