import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Link as ReactRouterLink } from 'react-router-dom';
import { getChannelUrl } from 'utils/api/urls';

type ArticleTagsProps = {
  tags: string[];
  color?: string;
};

const ArticleTags = ({ tags, color }: ArticleTagsProps) => {
  return (
    <Stack
      sx={() => ({
        flexDirection: 'row',
        flexWrap: 'wrap',
        wordBreak: 'break-all',
      })}
    >
      {tags.map((tag) => (
        <Link
          key={tag}
          variant="body2"
          component={ReactRouterLink}
          to={getChannelUrl({ title: tag })}
          sx={(theme) => ({
            color: color || theme.colors.text.tertiary,
            marginRight: theme.spacing('xxs'),
            textDecoration: 'none',
            lineHeight: 1.2,
            '&:last-of-type': {
              marginRight: 0,
            },
            '&:hover': {
              color: color || theme.colors.text.tertiary,
              textDecoration: 'underline',
            },
          })}
        >{`#${tag}`}</Link>
      ))}
    </Stack>
  );
};

export { ArticleTags };
