import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ManageFeedTableSkeleton = () => {
  return (
    <Stack sx={() => ({ gap: '0.5rem', marginTop: '0.5rem' })}>
      {Array(10)
        .fill(0)
        .map((_, index) => (
          <Skeleton
            key={`manage-feed-table-skeleton-${index}`}
            variant="rounded"
            animation="pulse"
            sx={{ height: '3.25rem' }}
          />
        ))}
    </Stack>
  );
};

export { ManageFeedTableSkeleton };
