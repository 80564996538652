import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import * as cmsContentManagementApi from 'api/cms/contentManagement';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { translations } from 'translations';
import * as textUtils from 'utils/misc/text';

import { DialogDelete } from './DialogDelete';

const elementId = 'profile-manage-banners-actions';

export enum BannersAction {
  Delete = 'Delete',
}

type ManageBannersActionsProps = {
  sx?: SxProps<Theme>;
  selectedIds: string[];
  onComplete: (result: { message: string; isSuccess: boolean }) => void;
  onCancel: () => void;
};

const ManageBannersActions = ({
  sx,
  selectedIds,
  onComplete,
  onCancel,
}: ManageBannersActionsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeAction, setActiveAction] = useState<BannersAction | null>(null);

  const makeActionRequest = useCallback(
    async (action: {
      request: () => Promise<AxiosResponse>;
      successMessage: string;
      errorMessage: string;
    }) => {
      let isSuccess = true;
      try {
        setIsLoading(true);
        await action.request();
      } catch {
        isSuccess = false;
      } finally {
        onComplete({
          isSuccess: isSuccess,
          message: isSuccess ? action.successMessage : action.errorMessage,
        });
        setActiveAction(null);
        setIsLoading(false);
      }
    },
    [onComplete]
  );

  const handleDelete = useCallback(() => {
    makeActionRequest({
      request: () => cmsContentManagementApi.deleteBanners(selectedIds),
      successMessage: translations.manageBannersActionsDeleteSuccess,
      errorMessage: translations.manageBannersActionsDeleteError,
    });
  }, [makeActionRequest, selectedIds]);

  return (
    <>
      <Stack
        id={elementId}
        sx={[
          (theme) => ({
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: `${theme.spacing('xs')} ${theme.spacing('md')}`,
            backgroundColor: theme.colors.surface.actionPrimaryDefault,
            color: theme.colors.text.inversePrimary,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Typography variant="body2">
          {textUtils.replaceTranslationAliasesWithElements(
            selectedIds.length === 1
              ? translations.manageContentActionsSelectedOne
              : translations.manageContentActionsSelectedMany,
            { count: <b>{selectedIds.length}</b> },
            true
          )}
        </Typography>
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            gap: theme.spacing('sm'),
            '> button': {
              color: theme.colors.text.inversePrimary,
              textDecorationColor: theme.colors.text.inversePrimary,
            },
          })}
        >
          <ManageBannersActionButton
            id={`${elementId}-button-delete`}
            visible={true}
            text={translations.manageContentActionsDelete}
            onClick={() => setActiveAction(BannersAction.Delete)}
          />
          <Divider orientation="vertical" />
          <ManageBannersActionButton
            id={`${elementId}-button-cancel`}
            visible
            text={translations.cancel}
            onClick={onCancel}
          />
        </Stack>
      </Stack>
      <DialogDelete
        id={`${elementId}-dialog-delete`}
        loading={isLoading}
        open={activeAction === BannersAction.Delete}
        onClose={() => setActiveAction(null)}
        onConfirm={handleDelete}
      />
    </>
  );
};

type ManageBannersActionButtonProps = {
  id: string;
  text: string;
  visible: boolean;
  onClick: () => void;
};

const ManageBannersActionButton = ({
  id,
  text,
  visible,
  onClick,
}: ManageBannersActionButtonProps) => {
  if (!visible) {
    return null;
  }
  return (
    <Link id={id} variant="body2" component="button" onClick={onClick}>
      {text}
    </Link>
  );
};

export { ManageBannersActions };
