import { withErrorBoundary } from 'components/ErrorBoundary';
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  getDefaultState,
  loadData,
  loadMoreData,
} from 'utils/api/handleAPIData';

class FetchError extends Error {
  constructor(statusCode, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
    this.statusCode = statusCode;
    this.date = new Date();
  }
}

class Fetcher extends Component {
  state = {
    ...getDefaultState(),
    isLoading: true,
  };

  componentDidMount() {
    this._isMounted = true;
    const { param, pagination, useLoadMore, onMount } = this.props;

    const doReset =
      param &&
      typeof param === 'string' &&
      param.includes('page[limit]') &&
      pagination &&
      useLoadMore;

    if (doReset) {
      this.fetchPrevious();
    } else {
      this.fetch(param);
    }

    if (onMount) onMount();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  asyncSetState = (newState) => {
    if (this._isMounted) {
      this.setState(newState);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.param !== prevProps.param) {
      this.fetch(this.props.param);
    }
  }

  fetchPrevious = async () => {
    const { source, headers, reducer, param, pagination, useModelFactory } =
      this.props;

    let newState = await loadData({
      apiCall: source,
      headers,
      dataState: this.state,
      param,
      reducer,
      useModelFactory,
    }).promise;

    for (let i = 1; i <= pagination; ++i) {
      newState = await loadMoreData({
        headers,
        dataState: newState,
        reducer,
        useModelFactory,
      }).promise;
    }

    this.asyncSetState(newState);
  };

  fetch = (param) => {
    const { source, headers, reducer, useModelFactory } = this.props;

    const { newState, promise } = loadData({
      apiCall: source,
      headers,
      dataState: this.state,
      param,
      reducer,
      useModelFactory,
    });

    this.setState(newState);

    promise.then((newDataState) => {
      this.asyncSetState(newDataState);
    });
  };

  loadMore = () => {
    const { setPagination, headers, reducer, useModelFactory } = this.props;

    const { newState, promise } = loadMoreData({
      headers,
      dataState: this.state,
      reducer,
      useModelFactory,
    });

    this.setState(newState);

    if (promise) {
      promise.then((newDataState) => {
        this.asyncSetState(newDataState);

        if (this._isMounted && setPagination) {
          setPagination(newDataState.meta.page.offset);
        }
      });
    }
  };

  handleError = () => {
    const {
      errorStatus: status,
      errorMessage: message,
      errorFromAPI,
    } = this.state;
    const { onError } = this.props;
    if (onError) {
      return onError(status, message, errorFromAPI);
    }

    throw new FetchError(status, message);
  };

  handleLoading = () => {
    const { onLoading } = this.props;
    if (onLoading) {
      return onLoading();
    }
    return null;
  };

  render() {
    const { error, isLoading } = this.state;
    const { children, returnLoading } = this.props;

    if (error) {
      return this.handleError();
    }

    if (isLoading && returnLoading === false) {
      return this.handleLoading();
    }

    return children({
      ...this.state,
      loadMore: this.loadMore,
    });
  }
}

Fetcher.propTypes = {
  children: PropTypes.func.isRequired,
  source: PropTypes.func.isRequired,
  useModelFactory: PropTypes.bool,
  reducer: PropTypes.func,
  returnLoading: PropTypes.bool.isRequired,
  param: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headers: PropTypes.object,
  useLoadMore: PropTypes.bool,
};

Fetcher.defaultProps = {
  returnLoading: false,
  useLoadMore: false,
};

export { Fetcher };
export default withErrorBoundary(Fetcher);
