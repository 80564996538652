import { getProfile, getProfiles } from 'api/organization';
import makeQueryString from 'utils/misc/makeQueryString';

import { convertResponse } from './convertResponse';
import DataModel from './DataModel';

class User extends DataModel {
  constructor(id, attributes, includes) {
    super('User', id, attributes, includes);
  }

  getPersonName() {
    const { firstName, lastName } = this.attributes;
    return `${firstName} ${lastName}`;
  }

  getUrl() {
    return `/people/${this.id}`;
  }

  static createFromContext(userContext) {
    const user = new User(userContext.userId, {
      firstName: userContext.firstName,
      lastName: userContext.lastName,
      imageUrl: userContext.imageUrl,
      title: userContext.title,
    });

    return user;
  }

  static async getUser(personId) {
    const response = await getProfile(personId);
    const { data: user } = convertResponse(User, response);

    return user;
  }

  static async getUsersFromList(personIds) {
    const query = makeQueryString({ filter: { 'person.id': personIds } });

    if (query) {
      const response = await getProfiles(query);
      const { data: users } = convertResponse(User, response);
      return users;
    } else {
      return [];
    }
  }
}

export default User;
