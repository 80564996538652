import './Crossword.scss';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from 'components/Box';
import Button from 'components/Button';
import { Column, Container } from 'components/Grid';
import PageTitle from 'components/PageTitle';
import Title from 'components/Title';
import { Component } from 'react';

import CrosswordInput from './CrosswordInput';

const crossword = [
  {
    question: 'Country in which Erik Pieder works.',
    length: 6,
    left: 1,
  },
  {
    question: 'Person who wrote the blog post about a strong identity.',
    length: 4,
    left: 3,
  },
  {
    question:
      'Hashtag used to find the themes from the latest leadership summit.',
    length: 14,
    left: 7,
  },
  {
    question: 'Irish company acquired by ASSA ABLOY in June 2018.',
    length: 3,
    left: 1,
  },
  {
    question:
      'Name of the page on Avenue used to edit your personal information.',
    length: 9,
    left: 2,
  },
  {
    question: 'Name given to the top level of information in Library.',
    length: 6,
    left: 5,
  },
  {
    question:
      'Name of the page in the Avenue footer where you can find questions and answers.',
    length: 3,
    left: 1,
  },
  {
    question:
      'Name of the middle level of the seven seniority levels in the job section.',
    length: 9,
    left: 4,
  },
  {
    question: 'Raised in the IT support portal when something is broken.',
    length: 8,
    left: 5,
  },
];

const answerCol = 8;

const InputRow = ({ length, left, rowNum, focusRow, onFocus }) => {
  const out = [];

  const startPos = answerCol - left - 1;
  const endPos = startPos + length + 1;

  for (let i = 0; i < endPos; ++i) {
    out.push(
      <CrosswordInput
        key={i}
        center={i === answerCol}
        hidden={i < startPos}
        rowNum={rowNum}
        focused={focusRow === rowNum}
        onFocus={onFocus}
        showRowNum={i === startPos}
      />
    );
  }

  return <div className="crossword__content-row">{out}</div>;
};

class CrossWordCampaign extends Component {
  state = {
    focusRow: null,
  };

  onFocus = (focusRow) => {
    this.setState({ focusRow });
  };

  handleAnswer = () => {};

  render() {
    const { focusRow } = this.state;

    return (
      <div className="crossword m-b-6">
        <Container className="crossword__container">
          <Column>
            <PageTitle titles={['Avenue Crossword Challenge']} />
            <Title color="white">Avenue Crossword Challenge</Title>
            <Box>
              <div className="crossword__columns">
                <div className="crossword__content">
                  <p className="crossword__content-headline">
                    Can you find the secret password hidden in the yellow boxes?
                  </p>
                  <ClickAwayListener onClickAway={this.onFocus}>
                    <div>
                      {crossword.map((row, i) => (
                        <InputRow
                          {...row}
                          rowNum={i}
                          onFocus={this.onFocus}
                          focusRow={focusRow}
                          key={`row${i}`}
                        />
                      ))}
                    </div>
                  </ClickAwayListener>
                  <Button
                    className="crossword__content-button"
                    color="black"
                    width="narrow"
                    size="medium"
                    to="https://surveys.hotjar.com/s?siteId=869724&surveyId=127082"
                    outbound={true}
                  >
                    I know the answer!
                  </Button>
                </div>
                <ul className="crossword__list">
                  {crossword.map((row, i) => (
                    <li
                      key={`question${i}`}
                      className={
                        i === focusRow
                          ? 'crossword__list-item--focused'
                          : 'crossword__list-item'
                      }
                    >
                      <div className="crossword__question">
                        <span className="crossword__question-num">{`${
                          i + 1
                        }.`}</span>
                        <span>{row.question}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Box>
          </Column>
        </Container>
      </div>
    );
  }
}

export default CrossWordCampaign;
