import { getArticleUrl } from 'api/cms/library';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';

const loadArticleById = (id) => {
  return getArticleUrl(id);
};

class IdRedirect extends Component {
  state = {
    isLoading: true,
    redirectPath: null,
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    loadArticleById(id).then((response) => {
      let redirectPath = '/404';

      if (response && response.data) {
        redirectPath = response.data.data.attributes.url;
      }

      this.setState({ redirectPath, isLoading: false });
    });
  }

  render() {
    const { isLoading, redirectPath } = this.state;

    if (isLoading) {
      return null;
    }

    return <Redirect to={redirectPath} />;
  }
}

export default IdRedirect;
