import './EllipsisMenu.scss';

import PropTypes from 'prop-types';
import { useState } from 'react';

const EllipsisMenu = ({ options, onClick }) => {
  const [open, setOpen] = useState(false);

  const onClickMenuItem = (e, option) => {
    e.stopPropagation();
    setOpen(false);
    onClick(e, option);
  };

  const onClickMenuButton = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onClickOutside = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      {open ? (
        <div
          className="ellipsis-menu__click-outside-handler"
          onClick={onClickOutside}
        />
      ) : null}
      <div className="ellipsis-menu__wrapper" onClick={onClickOutside}>
        <button className="ellipsis-menu__button" onClick={onClickMenuButton}>
          ...
        </button>
        {open ? (
          <div className="ellipsis-menu__container">
            {options.map((option) => (
              <div
                key={option.id}
                className="ellipsis-menu__item"
                onClick={(e) => onClickMenuItem(e, option)}
              >
                {option.title}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

const optionsShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
};

EllipsisMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(optionsShape).isRequired),
  onClick: PropTypes.func.isRequired,
};

export default EllipsisMenu;
