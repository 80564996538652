import settings from 'settings';
import createRequest from 'utils/api/requestFactory';

const { cms } = settings.api;
const route = `${cms}/admin`;

export const indexDocuments = () =>
  createRequest({
    method: 'get',
    url: `${route}/indexDocuments`,
    errorMessages: {
      '*': 'Failed to index documents, please contact support for assistance.',
    },
  });

export const getNotificationCenterPermission = (userId: string) =>
  createRequest({
    method: 'get',
    url: `${route}/permission/notifications/${userId}`,
    errorMessages: {
      '*': 'Failed to perform notifications status check, please contact support for assistance.',
    },
  }).then(mapNotificationPermissionResponse);

const mapNotificationPermissionResponse = (response: any) =>
  ({
    hasNotificationCenter: response.data.data.hasNotificationCenter,
  } as {
    hasNotificationCenter: boolean;
  });
